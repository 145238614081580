import React from 'react'
import { TouchableOpacity, View, ScrollView, Linking } from 'react-native'

import _ from 'lodash'

import { STYLES, COLORS } from 'x/config/styles'
import Segment from 'xui/components/Segment'
import BaseReportPayment from 'x/components/report/BaseReportPayment'
import { IAccount } from 'x/index'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import XIcon from '../XIcon'
import XDateRangePicker from '../XDateRangePicker'
import XText from '../XText'
import XButton from '../XButton'
import VStack from '../VStack'

export default class ReportPayment extends BaseReportPayment {
  _handleOnDownloadFile = async (url: string, fileName: string): Promise<void> => {
    this.props.handleOnDownloadFile(url, fileName, 'xlsx')
  }

  _renderHeader = () => (
    <HStack w='full' alignItems='center' justifyContent='space-between'>
      <XText bold variant='active'>
        รายงาน การรับชำระตามบัญชี
      </XText>
      <XButton
        onPress={() => Linking.openURL('https://xsf.sgp1.cdn.digitaloceanspaces.com/x/samples/rp/Payments.xlsx')}
        // size='sm'
        h='7'
        p='0'
        variant='outline'>
        <XText color={COLORS.APP_MAIN} fontSize='xs' p='1'>
          ดูตัวอย่าง
        </XText>
      </XButton>
    </HStack>
  )

  _renderCreateAt = () => {
    const { selectedOptSegment } = this.state
    return (
      <Box>
        <HStack alignItems='center'>
          <XText variant='active' fontSize='xs'>
            เลือกช่วงวันที่รับชำระ...
          </XText>
        </HStack>
        <HStack alignItems='center'>
          <Segment
            onSegmentChange={(newIndex) => this._onChangeSegmentBaseReportPayment(newIndex)}
            options={this.DATE_RANGE_REPORT_PAYMENT}
            selectedIndex={selectedOptSegment}
          />
        </HStack>
      </Box>
    )
  }

  _renderDateRangePicker = () => {
    const { allowOnlyDaysFromToday } = this.props
    // if (_.isNil(dateRangePicker)) {
    //   return null
    // }
    const { selectedDateRange, warningDateRangeText } = this.state
    // const DateRangePickerComponent = dateRangePicker
    return (
      <Box mt='2' minH='24'>
        <XDateRangePicker
          oneLineDateTime={false}
          allowOnlyDaysFromToday={allowOnlyDaysFromToday}
          // allowDateRangeLimitDays={limitDay}
          selectedDates={selectedDateRange}
          onChangeDate={this._onChangeDateRange}
          // showTime={true}
          // showTimeSelect={showTimeSelect}
        />
        <XText variant='primary' fontSize='xs' textAlign='center'>
          {warningDateRangeText}
        </XText>
      </Box>
    )
  }

  _renderBankAccounts = () => {
    const { bankAccounts, loading } = this.state
    const noData = _.isNil(bankAccounts) || bankAccounts.length < 1
    // console.log('bankAccounts => ', bankAccounts)
    return (
      <Box>
        <XText variant='active'>เลือกบัญชี (เลือกได้มากกว่า 1 บัญชี)</XText>
        <Box flexWrap='nowrap' p='2' mt='2' h='56' borderRadius='sm' borderWidth='1px' borderColor='primary.500'>
          <ScrollView>
            {noData ? null : (
              <Box>
                {bankAccounts.map((data: IAccount, index: number) => {
                  const select = _.isNil(data.selected) || !data.selected ? false : data.selected
                  const bankName = this._getBankName(data)
                  const name = this._getUserBankName(data)
                  const bankAccountNumber = this._getBankAccountNumber(data)
                  return (
                    <HStack p='2' h='16'>
                      <TouchableOpacity style={{ justifyContent: 'center', alignSelf: 'center' }} onPress={() => this._onSeleted(data)}>
                        <XIcon
                          style={{
                            flex: 0,
                            minWidth: 30,
                            fontSize: STYLES.FONT_ICON_NORMAL,
                            color: select ? COLORS.APP_MAIN : COLORS.TEXT_INACTIVE,
                          }}
                          family='FontAwesome'
                          name={select ? 'check-square-o' : 'square-o'}
                        />
                      </TouchableOpacity>
                      <View style={{ flex: 1 }}>
                        <TouchableOpacity
                          onPress={() => this._onSeleted(data)}
                          style={{
                            // borderWidth: 1,
                            borderLeftWidth: 1,
                            borderLeftColor: select ? COLORS.APP_MAIN : COLORS.TEXT_INACTIVE,
                            borderRightWidth: 1,
                            borderRightColor: select ? COLORS.APP_MAIN : COLORS.TEXT_INACTIVE,
                            borderTopWidth: 1,
                            borderTopColor: select ? COLORS.APP_MAIN : COLORS.TEXT_INACTIVE,
                            borderBottomWidth: 1,
                            borderBottomColor: select ? COLORS.APP_MAIN : COLORS.TEXT_INACTIVE,
                            borderRadius: 7,
                            // borderColor: select ? COLORS.APP_MAIN : COLORS.TEXT_INACTIVE,
                            // backgroundColor: select ? COLORS.APP_MAIN : COLORS.TEXT_INACTIVE,
                            flex: 1,
                            // flexWrap: 'wrap',
                          }}>
                          <Box _light={{ bg: 'white' }} _dark={{ bg: 'gray.800' }} m='1'>
                            <HStack alignItems='center' justifyContent='space-between'>
                              <XText
                                // w='30'
                                numberOfLines={1}
                                variant='active'
                                style={{
                                  textAlign: 'left',
                                }}>
                                {bankName}
                              </XText>
                              <XText
                                numberOfLines={1}
                                variant='active'
                                style={{
                                  textAlign: 'right',
                                }}>
                                {name}
                              </XText>
                            </HStack>
                            <HStack alignItems='center' justifyContent='space-between'>
                              <Box />
                              <XText variant='active' textAlign='right' numberOfLines={1}>
                                {bankAccountNumber}
                              </XText>
                            </HStack>
                          </Box>
                        </TouchableOpacity>
                      </View>
                    </HStack>
                  )
                })}
              </Box>
            )}
          </ScrollView>
        </Box>
        {/* {loading ? (
          <Box
            style={{
              flex: 1,
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: 'rgba(52, 52, 52, 0.7)',
              width: 298,
              height: 170,
              zIndex: 999,
            }}>
            <Spinner
              color={COLORS.APP_MAIN}
              size='sm'
              style={{
                alignSelf: 'center',
                justifyContent: 'center',
              }}
            />
          </Box>
        ) : null} */}
      </Box>
    )
  }

  _renderFooter = () => {
    const { onRequestClose } = this.props
    const { downloadingReport } = this.state
    return (
      <HStack w='full' alignItems='center' mt='2' space='1'>
        <XButton
          flex={1}
          // size='8'
          leftIcon={<XIcon family='FontAwesome' name='file-excel-o' size='xs' />}
          isLoading={downloadingReport}
          onPress={() => {
            this._downloadHistoryReport()
          }}>
          ดาวน์โหลดรายงาน
        </XButton>
        <XButton
          variant='outline'
          // size='8'
          minW='10'
          onPress={_.isFunction(onRequestClose) ? onRequestClose() : null}>
          ปิด
        </XButton>
      </HStack>
    )
  }

  render() {
    // const { visible, onRequestClose } = this.props
    return (
      <VStack w='full' _light={{ bg: 'white' }} _dark={{ bg: 'gray.800' }} p='2' borderRadius='xl'>
        {this._renderHeader()}
        {this._renderCreateAt()}
        {this._renderDateRangePicker()}
        {this._renderBankAccounts()}
        {this._renderFooter()}
      </VStack>
    )
  }
}
