/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React from 'react'
import { ActionApiParams, IERPChannel, ISelectedStoreMap, IXScreenProps } from 'x/types'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import _ from 'lodash'
import * as acl from 'x/utils/acl'
import CONS from 'x/config/constants'

interface IBaseERPListViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  getErpChannels: (params: ActionApiParams) => void
}

interface IBaseERPListViewState {
  erpChannelList: IERPChannel[]
}

export default abstract class BaseERPListView extends React.Component<IBaseERPListViewProps, IBaseERPListViewState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      erpChannelList: null,
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
  }

  async componentDidMount() {
    await this._getErpChannelList()
  }

  _getErpChannelList = async () => {
    const { selectedStore, getErpChannels } = this.props
    const {} = this.state
    const store_id = selectedStore.get('id')
    const body = {
      store_id,
    }
    const isHelperUser = util.isHelper()
    const havePermListErp = acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ERP_CHANNEL_LIST)
    // console.log('havePermListErp => ', havePermListErp)
    if (isHelperUser && !havePermListErp) {
      return
    }
    const res: { erp_channels: IERPChannel[] } = await new Promise((resolveSave) => {
      getErpChannels({
        body,
        // @ts-ignore successCallback แดง
        successCallback: resolveSave,
        failedCallback: () => resolveSave(null),
      })
    })
    // console.log('storesApiOptions => ', res)
    if (res.erp_channels) {
      this.setState({
        erpChannelList: res.erp_channels,
      })
    }
    // const res = await api.postV2('erp/channel/lists', reqBody, apiOptions)
    // console.log('res => ', res)

    // p.op.showToast('ผูกรหัสบัญชีเรียบร้อยแล้ว', 'success')
    // const params = util.getNavParams(this.props)
    // const { callBack } = params
  }

  goBack = () => {
    util.navGoBack(this.props)
  }

  _navToErpCreateView = () => {
    const { navigation, selectedStore } = this.props
    navigation.dispatch(
      NavActions.navToERPCreateView({
        store_id: selectedStore.get('id'),
        callBack: (newErpChannel: IERPChannel) => this._callBackFromCreateErpChannel(newErpChannel),
      })
    )
  }

  _callBackFromCreateErpChannel = (newErpChannel: IERPChannel) => {
    // console.log('_callBackFromCreateErpChannel Main => ', newErpChannel)
    const { erpChannelList } = this.state
    const newErpChannelList: IERPChannel[] = [newErpChannel]
    if (!_.isNil(erpChannelList) && erpChannelList.length > 0) {
      erpChannelList.forEach((erpChannel) => {
        newErpChannelList.push(erpChannel)
      })
    }
    this.setState({ erpChannelList: newErpChannelList })
  }

  callBaclFromUpdateErpChannel = (newErpChannel: IERPChannel) => {
    const { erpChannelList } = this.state
    const newErpChannelList: IERPChannel[] = []
    if (!_.isNil(erpChannelList) && erpChannelList.length > 0) {
      erpChannelList.forEach((erpChannel) => {
        if (erpChannel.id === newErpChannel.id) {
          newErpChannelList.push(newErpChannel)
        } else {
          newErpChannelList.push(erpChannel)
        }
      })
    }
    this.setState({ erpChannelList: newErpChannelList })
  }

  _navToErpDetailView = (erp_ch_id: number) => {
    const { navigation, selectedStore } = this.props
    navigation.dispatch(
      NavActions.navToERPDetailView({
        store_id: selectedStore.get('id'),
        erp_ch_id,
        callBackUpdateNewErp: (newErpChannel: IERPChannel) => this.callBaclFromUpdateErpChannel(newErpChannel),
        callBackUpdateNewErpList: (newErpChannels: IERPChannel[]) => this.setState({ erpChannelList: newErpChannels }),
      })
    )
  }
}
