import React, { createRef, RefObject } from 'react'
import { View, Clipboard, RefreshControl, ScrollView } from 'react-native'

import _ from 'lodash'
import * as util from 'x/utils/util'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import { STYLES, COLORS, S } from 'x/config/styles'
import XIcon, { XIconFamily } from 'xui/components/XIcon'
import p from 'x/config/platform-specific'
import * as fmt from 'x/utils/formatter'

import ImgManagerTypeObject from 'x/components/BaseImgManager'
import {
  IMkpProductRequestBody,
  IMkpProductDetailProps,
  IMkpProductDetailState,
  IMkpProductDetailVariant,
  IMkpPairedProductTemplate,
} from 'x/index'

import { IMkpProductContextProps } from 'x/modules/marketplace/MkpProductContext'
import XText from 'xui/components/XText'
import XContent from 'xui/components/XContent'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XCard from 'xui/components/XCard'
import XButton from 'xui/components/XButton'
import * as NavActions from 'x/utils/navigation'
import XSellyLogo from 'xui/components/XSellyLogo'
import Box from 'xui/components/Box'
import XImage from 'xui/components/XImage'
import MkpLogo from 'xui/components/MkpLogo'
import XIconButton from 'xui/components/XIconButton'
import ImgManager from '../../components/ImgManager'

import ErrorListItem from '../../components/ErrorListItem'

export interface IMkpProductVariantItemInfo {
  index: number
  // variant: IMkpProductDetailVariantItem
  variant: IMkpProductDetailVariant
  mkpId: number
}

type IMkpProductDetailWithContextProps = IMkpProductDetailProps & IMkpProductContextProps
// type IProps<T = { [key: string]: any }> = T & IMkpProductDetailWithContextProps
// type IState<T = { [key: string]: any }> = T & IMkpProductDetailState

export default class BaseUIMkpProductDetail<
  IProps extends IMkpProductDetailWithContextProps,
  IState extends IMkpProductDetailState
> extends React.Component<IProps, IState> {
  // export default class BaseUIMkpProductDetail<PT, ST> extends React.Component<IProps<PT>, IState<ST>> {

  imgManagerRef: RefObject<ImgManagerTypeObject>

  contentScrollViewRef: RefObject<ScrollView>

  static initialState

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,
      inNavProcess: false,
      editingMkpProduct: null,
      pairedPtVisibleMap: {},
    } as IState

    this.imgManagerRef = createRef<ImgManagerTypeObject>()
    this.contentScrollViewRef = createRef()
  }

  async componentDidMount() {
    await util.delay(200)
    await this.initMkpProductContextByKey()
  }

  componentWillUnmount() {
    const { navigation, mkpProductContext } = this.props
    const isPreventDestroyWhenNavBack = util.getNavParam(this.props, 'isPreventDestroyWhenNavBack', false)
    if (!isPreventDestroyWhenNavBack) {
      mkpProductContext.destroy(this.getContextViewKey())
    }
  }

  getContextViewKey = () => {
    const params = util.getNavParams(this.props)
    // @ts-ignore
    const { item_uuid, pt_id, viewKey } = params
    if (viewKey) {
      return viewKey
    }
    return pt_id ? pt_id.toString() : item_uuid
  }

  initMkpProductContextByKey = async () => {
    const { mkpProductContext } = this.props
    // console.log('mkpProductContext => ', mkpProductContext)

    const viewKey = this.getContextViewKey()
    const initedMkpProduct = mkpProductContext.getMkpProduct(viewKey)
    // console.log('initMkpProductContextByKey initedMkpProduct => ', initedMkpProduct)

    if (!initedMkpProduct) {
      // mkpProductContext.init(viewKey)
      await this.doRefresh()
    } else {
      await this.reloadEditingMkpProduct()
    }
  }

  doRefresh = async () => {
    const { navigation, mkpProductContext } = this.props
    // const { store_id, mkp_channel_id, mkp_id, item_uuid, pt_id } = navigation.state.params
    const params = util.getNavParams(this.props)
    const { store_id, mkp_ch_id, item_uuid } = params
    const reqBody: IMkpProductRequestBody = {
      store_id,
      // mkp_channel_id,
      mkp_ch_id,
      // mkp_id,
      item_uuid,
      // product_template_id: pt_id,
    }
    // console.log('fetchMkpProduct reqBody ===> ', reqBody)
    try {
      const mkpProduct = await mkpProductContext.fetchMkpProduct(this.getContextViewKey(), reqBody)
      // console.log('fetchMkpProduct mkpProduct ===> ', mkpProduct)
      await this.reloadEditingMkpProduct()
    } catch (err) {
      // console.log('fetchMkpProduct err ===> ', err)
    }
  }

  reloadEditingMkpProduct = async () => {
    const { mkpProductContext } = this.props
    const mkpProduct = mkpProductContext.getMkpProduct(this.getContextViewKey())
    const coreProduct = mkpProductContext.getCoreProduct(this.getContextViewKey())
    // console.log('getMkpProduct ', mkpProduct)
    if (mkpProduct) {
      await util.setStatePromise(this, {
        editingMkpProduct: mkpProduct,
        // coreProduct: selectedProduct.toJS(), // Convert at fist-time use all-time
        coreProduct,
        isInitialized: true,
      })
      await util.delay(50)
      await this.afterReloadEditingMkpProduct()
    }
    await util.delay(100)
    await this._loadImagesToImgMgr()
  }

  initPairedPtVisibleMap = async () => {
    const { editingMkpProduct } = this.state
    const pairedPtVisibleMap = {}

    for (let i = 0; i < editingMkpProduct.paired_pts.length; i++) {
      const ppt = editingMkpProduct.paired_pts[i]
      const pairedPtId = ppt.paired_pt_id
      pairedPtVisibleMap[pairedPtId] = true
    }

    await util.setStatePromise(this, { pairedPtVisibleMap })
  }

  afterReloadEditingMkpProduct = async () => {
    // do nothing (for override only)
    await this.initPairedPtVisibleMap()
  }

  goBack = () => {
    util.navGoBack(this.props)
  }

  renderCustomHeader = () => {
    const { navigation } = this.props
    const mkpId = util.getNavParam(this.props, 'mkp_id')
    const mkpName = util.getMKPName(mkpId)
    const leftBtn = {
      backIcon: true,
      onPressItem: this.goBack,
    }
    return (
      <XCustomHeader
        title={`รายละเอียดสินค้าจาก ${mkpName}`}
        headerLeftProps={leftBtn}
        // renderHeaderRight={this.renderDropdown}
      />
    )
  }

  renderHeaderStoreName = () => {
    const { selectedStore } = this.props
    const { editingMkpProduct } = this.state
    if (!editingMkpProduct || _.isEmpty(editingMkpProduct)) {
      return null
    }
    // const mkpChId = editingMkpProduct.mkp_channel_id
    const mkpChId = editingMkpProduct.mkp_ch_id
    const channels = selectedStore.get('channels')
    if (!channels || channels.size === 0) {
      return null
    }
    // const updateAt = moment(editingMkpProduct.updated_at).fromNow()
    const channel = channels.find((ch) => ch.get('id') === mkpChId)
    if (!channel) {
      console.log('renderHeaderStoreName editingMkpProduct => ', editingMkpProduct)
      return null
    }
    return (
      <HStack
        bg={COLORS.BLUE_TAB}
        alignItems='center'
        p='1'
        mb='1'
        // style={[
        //   S.FLEX,
        //   S.ROW_MIDDLE_START,
        //   S.PADDING_HORIZONTAL_4,
        //   S.PADDING_VERTICAL_4,
        //   {
        //     // backgroundColor: '#ffeebc',
        //     backgroundColor: COLORS.BLUE_TAB,
        //     // height: 50,
        //     justifyContent: 'center',
        //     alignItems: 'center',
        //     borderWidth: 0.8,
        //     borderColor: COLORS.TEXT_INACTIVE,
        //   },
        // ]}
      >
        <MkpLogo mkpId={channel.get('mkp_id')} width={STYLES.MKP_ICON_SIZE_NORMAL} height={STYLES.MKP_ICON_SIZE_NORMAL} />
        {/* <ShopeeLogo height={STYLES.MKP_ICON_SIZE_NORMAL} width={STYLES.MKP_ICON_SIZE_NORMAL} /> */}
        <View style={[S.FLEX, S.PADDING_HORIZONTAL_8]}>
          <XText numberOfLines={1} bold>
            {channel.get('name')}
          </XText>
          <XText variant='inactive'>{`อัพเดตเมื่อ ${editingMkpProduct.updated_at}`}</XText>
        </View>
        {/* <View style={{ width: 165, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <XIcon
            name={'link'}
            family={'FontAwesome'}
            style={{ minWidth: 25, flex: 0, fontSize: STYLES.FONT_ICON_SMALLER, color: COLORS.APP_MAIN }}
          />
          <XText style={{ color: COLORS.TEXT_INACTIVE, fontSize: STYLES.FONT_SIZE_NORMAL }}>{'เชื่อมแล้ว 20 จาก 30 ตัวเลือก'}</XText>
        </View> */}
      </HStack>
    )
  }

  renderProductImages = (): JSX.Element => (
    <ImgManager
      // fileNamePrefix={config.s3_prefix_type_product}
      ref={this.imgManagerRef}
      uploading={false}
      showUploaded={false}
      // shareClipboardText={txtShareContent}
      // shareClipboardToastMessage={shareClipboardToastMessage}
      readonly
    />
  )

  getImgManagerRef = () => this.imgManagerRef.current

  _loadImagesToImgMgr = async (): Promise<void> => {
    const imgMgrRef = this.getImgManagerRef()
    if (imgMgrRef && imgMgrRef.loadImageUrls) {
      // const { img_uris, thumbnail_uris, tiny_img_uris } = this.state.editingMkpProduct
      const { img_uris, thumbnail_uris } = this.state.editingMkpProduct
      await imgMgrRef.loadImageUrls({
        p: img_uris,
        t: thumbnail_uris,
        // y: tiny_img_uris,
      })
    }
  }

  _clearImagesInImgMgr = async (): Promise<void> => {
    const imgMgrRef = this.getImgManagerRef()
    if (imgMgrRef && imgMgrRef.clearImages) {
      await imgMgrRef.clearImages()
    }
  }

  // getFocusProduct = () => {
  //   const { viewMode, loadedProduct, editingMkpProduct } = this.state
  //   if (viewMode === 'LINK') {
  //     return editingMkpProduct
  //   }
  //   return loadedProduct
  // }

  renderProductName = () => {
    const { navigation } = this.props
    const { editingMkpProduct } = this.state
    if (!editingMkpProduct) {
      return null
    }
    // const x = util.getNavParam(this.props, 'x', null)
    // const selectedProduct = this.getFocusProduct()
    const txtProductName = editingMkpProduct.name || ''
    const handleCopyProductName = () => {
      Clipboard.setString(txtProductName)
      p.op.showToast('คัดลอกชื่อสินค้าแล้ว', 'success')
    }
    return (
      <HStack w='full' alignItems='center'>
        <XText w='70px' variant='inactive'>
          ชื่อ
        </XText>
        {/* <XText style={S.TEXT_PRIMARY}>{`x = ${x}`}</XText> */}
        <XText onPress={handleCopyProductName} style={S.TEXT_ACTIVE}>
          {txtProductName}
        </XText>
      </HStack>
    )
  }

  // getPairedCoreProductLabelText = () => 'ผูกอยู่กับ'

  // renderPairedCoreProductItem = () => {
  //   const { coreProduct } = this.state
  //   if (!coreProduct) {
  //     return null
  //   }
  //   return (
  //     <HStack w='full' alignItems='center'>
  //       <XText w='70px' variant='inactive'>
  //         {this.getPairedCoreProductLabelText()}
  //       </XText>
  //       <HStack flex={1}>
  //         <CoreProductInfo product={coreProduct} />
  //       </HStack>
  //     </HStack>
  //   )
  // }

  renderProductDescription = () => {
    const { editingMkpProduct } = this.state
    if (!editingMkpProduct) {
      return null
    }
    // const selectedProduct = this.getFocusProduct()
    const txtProducDescription = editingMkpProduct.description || ''
    const handleCopyProductDescription = () => {
      Clipboard.setString(txtProducDescription)
      p.op.showToast('คัดลอกรายละเอียดสินค้าแล้ว', 'success')
    }
    return (
      <VStack w='full'>
        <XText variant='inactive'>คำอธิบาย</XText>
        <XText w='full' minH='50px' onPress={handleCopyProductDescription}>
          {txtProducDescription}
        </XText>
      </VStack>
    )
  }

  renderProductItemId = () => {
    const { editingMkpProduct } = this.state
    if (!editingMkpProduct) {
      return null
    }
    const id = editingMkpProduct.variants[0].item_id

    if (!id) {
      return null
    }

    return (
      <HStack w='full'>
        <XText
          variant='inactive'
          fontSize='2xs'
          numberOfLines={1}
          onPress={() => {
            // @ts-ignore
            Clipboard.setString(id.toString())
            p.op.showToast(`คัดลอก ${id} แล้ว`, 'success')
          }}>
          {`ID:${id}`}
        </XText>
      </HStack>
    )
  }

  getVariantCount = () => {
    const { editingMkpProduct } = this.state
    const vCount = editingMkpProduct.variants.length
    return vCount
  }

  getVisibleVariantCount = () => {
    const { editingMkpProduct, pairedPtVisibleMap } = this.state
    const { paired_pts } = editingMkpProduct
    const visiblePptIds = Object.keys(pairedPtVisibleMap)
      .filter((pptId) => pairedPtVisibleMap[pptId])
      .map((pptIdStr) => parseInt(pptIdStr))
    let vCount = 0
    for (let i = 0; i < paired_pts.length; i++) {
      const ppt = paired_pts[i]
      const isVisiblePpt = visiblePptIds.includes(ppt.paired_pt_id)
      if (isVisiblePpt) {
        vCount += ppt.pairedVariantCount
      }
    }
    return vCount
  }

  renderProductPairedProductTemplates = () => {
    const { editingMkpProduct } = this.state
    if (!editingMkpProduct || _.isEmpty(editingMkpProduct)) {
      return null
    }

    const { paired_pts } = editingMkpProduct
    if (!paired_pts || paired_pts.length === 0) {
      return null
    }

    const visibleVariantCount = this.getVisibleVariantCount()
    const variantCount = this.getVariantCount()
    const pairedPtCount = paired_pts.length || 0
    const isVariantVisibleTextShow = pairedPtCount > 1

    return (
      <VStack w='full' space='0.5'>
        <XCard>
          <VStack w='full' p='2' space='1' bg='primary.100'>
            <XText variant='inactive'>{`${pairedPtCount} สินค้าใน XSelly ที่ผูกอยู่`}</XText>
            {paired_pts.map(this.renderProductTemplate)}
          </VStack>
        </XCard>
        {isVariantVisibleTextShow ? (
          <HStack w='full'>
            <XText variant='inactive'>แสดง</XText>
            <XText variant='active'>{` ${visibleVariantCount} `}</XText>
            <XText variant='inactive'>จาก</XText>
            <XText variant='active'>{` ${variantCount} `}</XText>
            <XText variant='inactive'>ตัวเลือกสินค้าทั้งหมด</XText>
          </HStack>
        ) : null}
      </VStack>
    )
  }

  toggleVisiblePairedPt = (pairedPtId: number) => {
    const { pairedPtVisibleMap: pphm = {} } = this.state

    const pairedPtVisibleMap = _.cloneDeep(pphm)
    pairedPtVisibleMap[pairedPtId] = !pairedPtVisibleMap[pairedPtId]

    const pairtedPtIds = Object.keys(pairedPtVisibleMap)
    let hasAtLeastOneTrue = false

    for (let i = 0; i < pairtedPtIds.length; i++) {
      const pptId = pairtedPtIds[i]
      hasAtLeastOneTrue = pairedPtVisibleMap[pptId]
      if (hasAtLeastOneTrue) {
        break
      }
    }

    if (!hasAtLeastOneTrue) {
      return
    }

    this.setState({ pairedPtVisibleMap })
  }

  renderProductTemplate = (pairedPt: IMkpPairedProductTemplate, index: number, pairedPts: IMkpPairedProductTemplate[]) => {
    const { pairedPtVisibleMap = {} } = this.state
    const { paired_pt_id, paired_pt_name, paired_pt_thumbnail_uri, pairedVariantCount } = pairedPt
    const isVisible = !!pairedPtVisibleMap[paired_pt_id]

    const pptCount = pairedPts.length
    const isVisibilityControlShow = pptCount > 1

    // console.log(`${index}::renderProductTemplate pairedPt => `, pairedPt)

    return (
      <XCard key={`PairedPtCard_${index}_${paired_pt_id}`}>
        <HStack w='full' p='1.5' space='1' bg={!isVisible ? 'muted.100' : undefined}>
          <VStack w='16' h='16'>
            <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: paired_pt_thumbnail_uri }} />

            <Box position='absolute' top='1' left='1'>
              <XSellyLogo width={24} height={24} />
            </Box>
          </VStack>

          <VStack flex={1}>
            <HStack w='full'>
              <VStack flex={1}>
                <XText w='full' numberOfLines={2}>
                  {paired_pt_name}
                </XText>
              </VStack>

              {/* <VStack _web={{ w: '70px' }} w='90px' alignItems='flex-end'>
                  <XText w='full' textAlign='right' numberOfLines={3}>
                    {pairedPpName}
                  </XText>
                </VStack> */}
            </HStack>

            <HStack w='full'>
              <VStack flex={1}>
                <XText w='full' variant='inactive' numberOfLines={2}>
                  {`ผูกอยู่ ${pairedVariantCount} ตัวเลือกสินค้า`}
                </XText>
              </VStack>
            </HStack>

            {/* {this.isFromProductDetail() ? null : (
                <HStack w='full' pt='1' justifyContent='flex-end'>
                  <XButton
                    w='90px'
                    size='xs'
                    leftIcon={<XIcon name='magnify' family='MaterialCommunityIcons' />}
                    variant='outline'
                    onPress={() => this.onPressNavToProductView(paired_pt_id)}>
                    ดูสินค้า
                  </XButton>
                </HStack>
              )} */}
          </VStack>

          {isVisibilityControlShow ? (
            <XIconButton name={isVisible ? 'eye' : 'eye-off'} onPress={() => this.toggleVisiblePairedPt(paired_pt_id)} />
          ) : null}
        </HStack>
      </XCard>
    )
  }

  renderProductVariants = () => {
    const { editingMkpProduct } = this.state
    if (!editingMkpProduct || _.isEmpty(editingMkpProduct)) {
      return null
    }

    const { variants } = editingMkpProduct
    if (!variants || variants.length === 0) {
      return null
    }

    return variants.map(this.renderProductVariant)
  }

  // renderProductVariant = (variant: IMkpProductDetailVariantItem, index: number) => this.renderProductVariantItemContainer(variant, index)
  renderProductVariant = (variant: IMkpProductDetailVariant, index: number) => this.renderProductVariantItemContainer(variant, index)

  // renderProductVariantItemContent = (variant: IMkpProductDetailVariantItem, index: number) => {
  renderProductVariantItemContent = (variant: IMkpProductDetailVariant, index: number) => {
    const mkpId = this.state.editingMkpProduct.mkp_id
    return (
      <VStack key={`${index}_${mkpId}`} w='full' space='1.5'>
        {this.renderVariantName({ index, variant, mkpId })}
        {this.renderVariantSalePrice({ index, variant, mkpId })}
        {this.renderVariantPromotionPrice({ index, variant, mkpId })}

        {this.renderPairedXSellyProductVariantInfo({ index, variant, mkpId })}
      </VStack>
    )
  }

  renderPairedXSellyProductVariantInfo = (info: IMkpProductVariantItemInfo) => {
    const { index, variant } = info
    const isUnpaired = _.isNil(variant.paired_pp_id)
    return (
      <VStack w='full' key={`PairedInfo_${index}`}>
        <HStack w='full' px='3' pb='1' alignItems='center' space='1'>
          {isUnpaired ? null : <XIcon name='link' family='FontAwesome' inactive minW='28px' />}
          {isUnpaired ? (
            <XText flex={1} textAlign='center' variant='inactive'>
              {this.isFromProductDetail()
                ? 'ยังไม่ได้ผูกกับสินค้าใดๆ'
                : 'ยังไม่ได้ผูกกับสินค้าใดๆ\nสามารถกดปุ่ม "ผูกสินค้าใหม่" เพื่อทำการผูกกับสินค้าใน XSelly'}
            </XText>
          ) : (
            <XText variant='inactive'>ผูกอยู่กับ:</XText>
          )}
        </HStack>
        {isUnpaired ? null : this.renderPairedXSellyProductVariantInfoCard(info)}
      </VStack>
    )
  }

  renderPairedXSellyProductVariantInfoCard = (info: IMkpProductVariantItemInfo) => {
    const { index, variant } = info
    // const { editingMkpProduct } = this.state
    // const { variants } = editingMkpProduct
    // const variant = variants[index]

    if (!variant) {
      return null
    }

    // const { paired_pt_id, paired_pp_id, paired_pt_name, paired_pp_name, thumbnail_uri } = variant
    const { paired_pt_id, paired_pp_id, paired_pt_name, paired_pp_name, paired_pt_thumbnail_uri, paired_pp_thumbnail_uri } = variant

    const pairedPtName = paired_pt_name
    // const pairedPpName = !paired_pp_name || paired_pp_name === '' ? '(ไม่มีชื่อ)' : paired_pp_name
    const pairedPpName = !paired_pp_name || paired_pp_name === '' ? '' : paired_pp_name

    let pairedImgUri = paired_pt_thumbnail_uri
    if (paired_pp_thumbnail_uri && paired_pp_thumbnail_uri !== '') {
      pairedImgUri = paired_pp_thumbnail_uri
    }

    return (
      <XCard key={`PairedInfoCard_${index}`}>
        <XCard.Body>
          <VStack w='full'>
            <HStack w='full' space='1'>
              <VStack w='16' h='16'>
                <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: pairedImgUri }} />

                <Box position='absolute' top='1' left='1'>
                  <XSellyLogo width={24} height={24} />
                </Box>
              </VStack>

              <VStack flex={1}>
                <HStack w='full'>
                  <VStack flex={1}>
                    <XText w='full' numberOfLines={2}>
                      {pairedPtName}
                    </XText>
                  </VStack>
                  <VStack _web={{ w: '70px' }} w='90px' alignItems='flex-end'>
                    <XText w='full' textAlign='right' bold numberOfLines={3}>
                      {pairedPpName}
                    </XText>
                  </VStack>
                </HStack>

                {this.isFromProductDetail() ? null : (
                  <HStack w='full' pt='1' justifyContent='flex-end'>
                    <XButton
                      w='90px'
                      size='xs'
                      leftIcon={<XIcon name='magnify' family='MaterialCommunityIcons' />}
                      variant='outline'
                      onPress={() => this.onPressNavToProductView(paired_pt_id)}>
                      ดูสินค้า
                    </XButton>
                  </HStack>
                )}
              </VStack>
            </HStack>
            {paired_pp_id ? (
              <HStack w='full'>
                <XText
                  variant='inactive'
                  fontSize='2xs'
                  numberOfLines={1}
                  onPress={() => {
                    // @ts-ignore
                    Clipboard.setString(id.toString())
                    p.op.showToast(`คัดลอก ${paired_pp_id} แล้ว`, 'success')
                  }}>
                  {`ID:${paired_pp_id}`}
                </XText>
              </HStack>
            ) : null}
          </VStack>
        </XCard.Body>
      </XCard>
    )
  }

  onPressNavToProductView = (paired_pt_id: number) => {
    const { navigation } = this.props
    const store_id = util.getNavParam(this.props, 'store_id')

    navigation.dispatch(
      NavActions.navToProductViewMyStore({
        store_id,
        product_id: paired_pt_id,

        isFromMkpProductView: true,
      })
    )
  }

  renderMkpIcon = () => {
    const { editingMkpProduct } = this.state

    return <MkpLogo mkpId={editingMkpProduct.mkp_id} height={24} width={24} />
  }

  // renderProductVariantItemContainer = (variant: IMkpProductDetailVariantItem, index: number) => {
  renderProductVariantItemContainer = (variant: IMkpProductDetailVariant, index: number) => {
    const { editingMkpProduct } = this.state
    const pairedPtId = variant.paired_pt_id || 0
    const isVisible = pairedPtId === 0 ? true : !!this.state.pairedPtVisibleMap[pairedPtId]

    if (!isVisible) {
      return null
    }

    let thumbnailImgUri = editingMkpProduct.thumbnail_uris[0]
    if (variant.img_uri && variant.img_uri !== '') {
      thumbnailImgUri = variant.img_uri
    }

    const isEven = index % 2 === 0
    return (
      <XCard
        key={`ProductVariantItem_${index.toString()}`}
        w='full'
        style={{ backgroundColor: isEven ? COLORS.BG_LIGHT_GREY : COLORS.WHITE }}>
        {/* <XCard.Body> */}
        <VStack w='full' px='1' pt='2' py='4'>
          {/* <View>
              <XText style={S.TEXT_DANGER}>{`DEBUG:: Index = ${index}`}</XText>
            </View> */}
          <HStack w='full' px='2' py='1' space='1' alignItems='center' justifyContent='center'>
            {thumbnailImgUri && thumbnailImgUri !== '' && (
              <VStack w='16' h='16'>
                <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: thumbnailImgUri }} />

                <Box position='absolute' top='1' left='1'>
                  {this.renderMkpIcon()}
                </Box>
              </VStack>
            )}

            <HStack flex={1} space='1' alignItems='center' justifyContent='center'>
              {this.renderMkpIcon()}
              <XText variant='inactive' bold>{`ตัวเลือกที่ ${index + 1}`}</XText>
              <Box h='24px' w='24px' />
            </HStack>

            {thumbnailImgUri && thumbnailImgUri !== '' && <Box w='16' h='16' />}
          </HStack>
          {this.renderProductVariantItemContent(variant, index)}

          {this._renderVariantIdLabel(variant, index)}
        </VStack>
        {/* </XCard.Body> */}
      </XCard>
    )
  }

  _renderVariantIdLabel = (v: IMkpProductDetailVariant, index: number) => {
    if (!v || !v.item_variant_id) {
      return null
    }

    const id = v.item_variant_id
    return (
      <HStack w='full' position='absolute' left='0' right='0' bottom='0' px='1'>
        <XText
          variant='inactive'
          fontSize='2xs'
          numberOfLines={1}
          // @ts-ignore
          onPress={() => {
            Clipboard.setString(id.toString())
            p.op.showToast(`คัดลอก ${id} แล้ว`, 'success')
          }}>
          {`ID:${id}`}
        </XText>
      </HStack>
    )
  }

  renderVariantName = (info: IMkpProductVariantItemInfo) => {
    const vCount = this.getVariantCount()
    const v = info.variant
    return this.renderGenericVariantItem({
      label: 'ชื่อ',
      value: vCount === 1 ? '-' : v.name,
      iconName: 'create',
      iconFamily: 'Ionicons',
      isValueBold: true,
    })
  }

  // renderVariantCost = (info: IMkpProductVariantItemInfo) => {
  //   const v = info.variant
  //   return this.renderGenericVariantItem({ label: 'ต้นทุน', value: v.cost })
  // }

  renderVariantSalePrice = (info: IMkpProductVariantItemInfo) => {
    const v = info.variant
    return this.renderGenericVariantItem({
      label: 'ราคาขาย',
      value: fmt.formatCurrency(v.original_price),
      iconName: 'pricetag',
      iconFamily: 'Ionicons',
    })
  }

  renderVariantPromotionPrice = (info: IMkpProductVariantItemInfo) => {
    const v = info.variant
    return this.renderGenericVariantItem({
      label: 'ราคาโปรโมชั่น',
      value: fmt.formatCurrency(v.price),
      iconName: 'pricetag',
      iconFamily: 'Ionicons',
    })
  }

  renderGenericVariantItem = (info: {
    label: string
    value: string
    iconName?: string
    iconFamily?: XIconFamily
    isValueBold?: boolean
  }) => (
    <HStack w='full' px='3' space='1'>
      <HStack minW='80px' alignItems='center' space='1'>
        <XIcon minW='28px' name={info.iconName} family={info.iconFamily} inactive />
        <XText variant='inactive'>{info.label}</XText>
      </HStack>
      <HStack flex={1} alignItems='center' justifyContent='flex-end'>
        <XText bold={info.isValueBold || undefined}>{info.value}</XText>
      </HStack>
    </HStack>
  )

  renderErrorBox = (fetchError) => {
    const handleshowAlertFetchError = () => util.showAlertFetchError(fetchError)
    return (
      <ErrorListItem containerStyle={S.WIDTH_FULL} onPressShowAlertInfo={handleshowAlertFetchError} onPressDoRefresh={this.doRefresh} />
    )
  }

  renderContent = () => {
    const { editingMkpProduct, isInitialized, coreProduct } = this.state
    if (!isInitialized || !editingMkpProduct) {
      return null
    }
    return (
      <XCard w='full'>
        <XCard.Body>
          <VStack w='full'>
            {this.renderHeaderStoreName()}
            <VStack w='full' space='2'>
              {/* <CoreProductInfo product={coreProduct} /> */}
              {this.renderProductImages()}
              {this.renderProductName()}
              {this.renderProductItemId()}
              {/* {this.renderPairedCoreProductItem()} */}
              {this.renderProductDescription()}
              {this.renderProductPairedProductTemplates()}
              {this.renderProductVariants()}
            </VStack>
          </VStack>
        </XCard.Body>
      </XCard>
    )
  }

  renderRefreshControl = () => {
    if (p.op.isWeb()) {
      return null
    }
    return <RefreshControl refreshing={this.isLoading()} onRefresh={this.doRefresh} />
  }

  renderContentContainer = () => {
    const fetchError = this.props.mkpProductContext.getError(this.getContextViewKey())
    if (fetchError) {
      return this.renderErrorBox(fetchError)
    }
    return (
      <XContent ref={this.contentScrollViewRef} refreshControl={this.renderRefreshControl()}>
        <VStack p='1' w='full'>
          {this.renderContent()}
        </VStack>
      </XContent>
    )
  }

  _onMappingSuccess = async () => {
    // await this.reloadEditingMkpProduct()
    await this.doRefresh()
  }

  navToXSellyProductMappingList = () => {
    const { editingMkpProduct } = this.state
    const { navigation } = this.props
    const store_id = util.getNavParam(this.props, 'store_id')
    // console.log('navToXSellyProductMappingList editingMkpProduct => ', editingMkpProduct)
    navigation.dispatch(
      NavActions.navToProductListViewMkpMapping({
        mkp_ch_id: editingMkpProduct.mkp_ch_id,
        mkp_id: editingMkpProduct.mkp_id,
        // product_id: 1,
        store_id,
        // @ts-ignore
        // mkp_product_uuid: editingMkpProduct.uuid,
        mkpProduct: editingMkpProduct,
        onMappingSuccess: this._onMappingSuccess,
      })
    )
  }

  _showSuggestionToMappingFlow = () => {
    p.op.alert(
      'ต้องการผูกสินค้า?',
      'สามารถทำได้ดังนี้\nเริ่มต้นที่เมนูหลัก > กดปุ่ม "ช่องทางขาย" > กดปุ่ม "รายการสินค้า" (รูปรถเข็น) > เลือกสินค้าที่ต้องการผูก > กดปุ่ม "ผูกสินค้าใหม่'
    )
  }

  renderFooter = () => {
    if (this.isFromProductDetail()) {
      return (
        <HStack w='full' minH='34px' p='1'>
          <XButton flex={1} variant='outline' onPress={this._showSuggestionToMappingFlow}>
            ต้องการผูกสินค้า?
          </XButton>
        </HStack>
      )
    }
    return (
      <HStack w='full' minH='34px' p='1'>
        <XButton flex={1} leftIcon={<XIcon name='link' />} onPress={this.navToXSellyProductMappingList}>
          ผูกสินค้าใหม่
        </XButton>
      </HStack>
    )
  }

  isLoading = () => this.props.mkpProductContext.getIsLoading(this.getContextViewKey())

  isFromProductDetail = (): boolean => {
    const { navigation } = this.props
    return util.getNavParam(this.props, 'fromProductDetail', false)
  }

  renderMain = () => (
    <XContainer>
      {this.renderCustomHeader()}
      {this.renderContentContainer()}
      {this.renderFooter()}
    </XContainer>
  )

  render() {
    return this.renderMain()
  }
}

// const MkpProductViewContainer = withMkpProductContextPropsAndRef(MkpProductView)
// // @ts-ignore
// MkpProductViewContainer.navigationOptions = { header: null }
// export default MkpProductViewContainer
