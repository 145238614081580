import React from 'react'

// import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import BaseERPCreatePeakView from 'x/modules/erp/BaseERPCreatePeakView'
import XCustomHeader from 'xui/components/XCustomHeader'
// import { Input } from 'react-native-elements'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XFaqModal from 'xui/components/XFaqModal'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'
import { TouchableOpacity } from 'react-native'
import XInput from 'xui/components/XInput'

// const VIEW_HEIGHT_TEN = <View style={{ height: 10 }} />

export default abstract class BaseUIERPCreatePeakView extends BaseERPCreatePeakView {
  renderCustomHeader = () => {
    const isGoBackButtonHidden = util.getNavParam(this.props, 'isGoBackButtonHidden', false)
    // const { isRenderWebView } = this.state
    return (
      <XCustomHeader
        title='สร้างบัญชี PEAK'
        headerLeftProps={{ label: 'สร้าง', onPressItem: () => this._saveBtn() }}
        headerRightProps={{ label: 'ยกเลิก', onPressItem: () => this.goBack() }}
      />
    )
  }

  _renderCreateView = () => {
    const { userToken } = this.state
    const _renderTextFAQ = (onPressOpenOverlay: () => void) => (
      <TouchableOpacity style={{ paddingLeft: 4 }} onPress={() => onPressOpenOverlay()}>
        <XText color='blue.800' textDecorationLine='underline'>
          ดูคลิปแนะนำการเตรียมและเชื่อมช่องทางขาย
        </XText>
      </TouchableOpacity>
    )
    return (
      <XCard p='2' m='2'>
        <VStack flex={1} alignItems='center' justifyContent='center'>
          <XText>กรุณาระบุ โค้ดสำหรับการเชื่อมต่อ PEAK</XText>
          <HStack mt='2'>
            <XFaqModal
              key='สร้างบัญชี PEAK'
              headerTitle='สร้างบัญชี PEAK'
              // @ts-ignore
              FAQ={FAQ_CONSTANTS.FAQ_CONNECT_MKP_CHANNELS}
              initiateOpenIndex={[0, 1]}
              renderButton={(onPressOpenOverlay: () => void) => _renderTextFAQ(onPressOpenOverlay)}
            />
          </HStack>

          <HStack w='full' h='20' mt='2'>
            <XInput
              value={userToken}
              multiline
              style={{ flex: 1 }}
              placeholder='วาง "โค้ด สำหรับเชื่อมต่อ PEAK " ได้ที่นี่'
              onChangeText={(text) => this.setState({ userToken: text.trim() })}
            />
          </HStack>
        </VStack>
      </XCard>
    )
  }

  _renderMain = () => {
    const {} = this.state
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent>{this._renderCreateView()}</XContent>
      </XContainer>
    )
  }

  render() {
    return this._renderMain()
  }
}
