/* eslint-disable react/no-unused-state */
import React from 'react'
import { IPackageDetails, IProfile, IProfileMap, ISubscriptionPackageList, SubscriptionPackageListItems } from 'x/index'
import * as util from 'x/utils/util'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import api from 'x/utils/api'
import { Platform, Alert, EmitterSubscription } from 'react-native'

interface IBaseInAppPurchaseViewProps {
  profile: IProfileMap
  subscription: any
}

interface IBaseInAppPurchaseViewState {
  isVertical: boolean
  autoPlay: boolean
  pagingEnabled: boolean
  snapEnabled: boolean
  progressValue: number

  openPackageIndex: number

  subscriptionPackageList: ISubscriptionPackageList

  receipt: string
  // productList: (Product | Subscription)[]
  productList: any
  availableItemsMessage: string

  profile: IProfile
  packageDetails: IPackageDetails

  contentHight: number

  isLoading: boolean
}

export default abstract class BaseInAppPurchaseView extends React.Component<IBaseInAppPurchaseViewProps, IBaseInAppPurchaseViewState> {
  abstract _fetchSubcriptionIap: () => void
  abstract _endConnection: () => void

  purchaseUpdate: EmitterSubscription
  purchaseError: EmitterSubscription
  promotedProduct: EmitterSubscription

  // eslint-disable-next-line react/no-unused-class-component-methods
  // @ts-ignore
  carouselRef: React.useRef<any>
  PACKAGE_SKU: string[]

  constructor(props) {
    super(props)
    this.state = {
      isVertical: false,
      autoPlay: false,
      pagingEnabled: true,
      snapEnabled: true,
      progressValue: 0,
      openPackageIndex: 0,
      receipt: '',
      productList: [],
      availableItemsMessage: '',
      subscriptionPackageList: null,
      profile: null,
      packageDetails: null,
      contentHight: 0,
      isLoading: true,
    }
    this.purchaseUpdate = null
    this.purchaseError = null
    this.promotedProduct = null
    this.carouselRef = React.createRef()
    this.PACKAGE_SKU = ['PACKAGE-BRONZE-CYCLE-12', 'PACKAGE-SILVER-CYCLE-12', 'PACKAGE-GOLD-CYCLE-12', 'PACKAGE-PLATINUM-CYCLE-12']
    // @ts-ignore
    // eslint-disable-next-line react/no-unused-class-component-methods
    // this.mainPackageItems = xCONS.SUBSCRIPTION_MAIN_PACKAGE_ITEMS.map((item) => ({
    //   ...item,
    //   label: p.op.t(`Subscription.main.${item.key}`),
    // }))
  }

  async componentDidMount() {
    // const params = this._getParams()
    // const {} = params
    await this._setProfile()
    // await this._setPackageDetails()
    await this._fetchSubcriptionIap()
    await this._fetchSubscriptionPackageList()
    // await this._getPackageDetailJson()
    await util.delay(1000)
  }

  _getCountPackageByKey = (keyInput: string) => {
    const { openPackageIndex, subscriptionPackageList } = this.state

    if (_.isNil(subscriptionPackageList)) {
      return '-'
    }

    const { items } = subscriptionPackageList
    const { PACKAGE_SKU } = this
    const sku = PACKAGE_SKU[openPackageIndex]
    let dataJson = null
    items.forEach((item) => {
      if (item.sku === sku) {
        dataJson = item.data_json
      }
    })
    // console.log('dataJson => ', dataJson)
    if (_.isNil(dataJson)) {
      return '-'
    }
    return dataJson[keyInput]
  }

  // _getPackageDetailJson = async () => {
  //   await util.setStatePromise(this, {
  //     packageDetails: JSON_PACKAGE_DETAIL_DATA,
  //   })
  // }

  _setProfile = async () => {
    const { profile } = this.props
    const profileJS = _.isNil(profile) ? null : profile.toJS()
    await util.setStatePromise(this, {
      profile: profileJS,
    })
  }

  // _setPackageDetails = async () => {
  //   console.log('_setPackageDetails 01 ')
  //   const packageDetailsData = {
  //     package_detail: {
  //       sections: [
  //         {
  //           section_name: 'ระบบจัดการคำสั่งซื้อ',
  //           item_keys: ['m_o_count', 'online_bill'],
  //         },
  //         {
  //           section_name: 'ระบบจัดการช่องทางขาย',
  //           item_keys: ['mkp_quota', 'mkp', 'real_time_warehouse'],
  //         },
  //       ],
  //       item_details: {
  //         m_o_count: {
  //           name: 'จำนวนคำสั่งซื้อ',
  //           // desc: 'ออเดอร์ขายที่ฉันเปิดขาย+ออเดอร์ขายที่ตัวแทนเปิดขายให้ฉัน',
  //           unit: 'ออเดอร์',
  //         },
  //         online_bill: {
  //           name: 'ลิงก์บิลออนไลน์ ให้ลูกค้าชำระและติดตามสถานะจัดส่ง',
  //           available_types: [1, 2, 3, 4, 5],
  //         },
  //         auto_tracking_number: {
  //           name: 'ขอเลขพัสดุอัตโนมัติ',
  //           desc: 'สามารถขอเลขพัสดุอัตโนมัติจากขนส่งได้ทันที',
  //           available_types: [2, 3, 4, 5],
  //         },
  //         mkp_quota: {
  //           name: 'จำนวนการเชื่อมต่อช่องทางขายและระบบบัญชี',
  //           unit: 'ช่องทาง',
  //         },
  //         mkp: {
  //           name: 'เชื่อมต่อ Shopee Lazada TikTok และ LINE Shopping และอื่นๆ',
  //           available_types: [2, 3, 4, 5],
  //         },
  //         real_time_warehouse: {
  //           name: 'แชร์สต๊อก คลังเดียว ทุกช่องทาง แบบเรียลไทม์',
  //           available_types: [2, 3, 4, 5],
  //         },
  //       },
  //     },
  //   }

  //   await util.setStatePromise(this, { packageDetails: packageDetailsData })
  //   console.log('_setPackageDetails 02 => ', packageDetailsData)
  // }

  _fetchSubscriptionPackageList = async () => {
    const apiOptions = {
      showSpinner: true,
    }
    const body = {
      lang: 'th',
      purchase_channel_id: 1,
    }
    try {
      const res = await api.postV2(api.POST_SUBSCRIPTION_PACKAGE_LIST, body, apiOptions)
      // console.log('_fetchSubscriptionPackageList res => ', res)
      await util.setStatePromise(this, {
        subscriptionPackageList: res,
        isLoading: false,
      })
      // if (!res || _.isEmpty(res)) {
      // }
    } catch (err) {}
  }

  // initConnection().catch(() => {
  //   console.log('error connecting to store')
  // }).then(() => {
  //   console.log('contected to store ...')
  //   // getSubscriptions({ skus: subscriptionSkus })
  //   getSubscriptions(subscriptionSkus).catch(() => {
  //     console.log('error finding Subscriptions')
  //   }).then((res) => {
  //     console.log('res Subscriptions => ', res)
  //   })
  // })
  // console.log('connected => ', connected)

  componentWillUnmount = () => {
    // this.purchaseUpdate?.remove()
    // this.purchaseError?.remove()
    // this.promotedProduct?.remove()

    this._endConnection()
  }

  goNext = () => {
    Alert.alert('Receipt', this.state.receipt)
  }

  _getParams = () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    return params
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  goBack = () => {
    // @ts-ignore
    util.navGoBack(this.props)
  }

  _setAutoPlay = (newAutoPlay: boolean) => {
    this.setState({ autoPlay: newAutoPlay })
  }

  _setIsVertical = (newVertical: boolean) => {
    this.setState({ isVertical: newVertical })
  }

  _setPagingEnabled = (newPagingEnabled: boolean) => {
    this.setState({ pagingEnabled: newPagingEnabled })
  }

  _setSnapEnabled = (newSnapEnabled: boolean) => {
    this.setState({ snapEnabled: newSnapEnabled })
  }

  _getItemPackage = (): SubscriptionPackageListItems => {
    const { subscriptionPackageList, openPackageIndex } = this.state
    let packageSku = 'PACKAGE-BRONZE-CYCLE-1'
    if (openPackageIndex === 1) {
      packageSku = 'PACKAGE-SILVER-CYCLE-1'
    }
    if (openPackageIndex === 2) {
      packageSku = 'PACKAGE-GOLD-CYCLE-1'
    }
    if (openPackageIndex === 2) {
      packageSku = 'PACKAGE-PLATINUM-CYCLE-1'
    }
    if (_.isNil(subscriptionPackageList)) {
      return null
    }
    const items = subscriptionPackageList.items
    let SelectedPackage = null

    items.forEach((itam) => {
      if (itam.sku === packageSku) {
        SelectedPackage = itam
      }
    })

    if (_.isNil(SelectedPackage)) {
      return null
    }

    return SelectedPackage
  }
}
