import React from 'react'
import BaseContactView from 'x/modules/contact/BaseContactView'
import { TouchableOpacity } from 'react-native'

import { COLORS } from 'x/config/styles'
import * as util from 'x/utils/util'

import _ from 'lodash'
import XCustomHeader from 'xui/components/XCustomHeader'
import { IAddress } from 'x/index'
import XIcon from 'xui/components/XIcon'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import p from 'x/config/platform-specific'
import XCard from 'xui/components/XCard'
import { VStack } from 'native-base'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import HStack from 'xui/components/HStack'
import XAddressCard from 'xui/components/XAddressCard'

export default abstract class BaseUIContactView extends BaseContactView {
  goBack = () => {
    util.navGoBack(this.props)
  }

  renderCustomHeader = () => {
    const leftBtn = { backIcon: true, onPressItem: () => this.goBack() }
    return <XCustomHeader title='ข้อมูลลูกค้า' headerLeftProps={leftBtn} />
  }

  _renderProfile = () => {
    const { profile, mode } = this.state
    // console.log('mode => ', mode)
    if (_.isNil(profile) || mode === 'PICK_ADDRESS') {
      return null
    }
    let name = `${profile.first_name}`
    if (profile.last_name) {
      name += ` ${profile.last_name}`
    }
    let phone = null
    if (profile.phone1) {
      phone = `โทรศัพท์ ${profile.phone1}`
    }
    let email = null
    if (profile.contact_email) {
      email = `${profile.contact_email}`
    }
    return (
      <XCard w='full' p='2'>
        <HStack alignItems='center' justifyContent='center'>
          <XText variant='active' bold>
            ข้อมูลทั่วไป
          </XText>
        </HStack>
        <HStack>
          <VStack flex={1}>
            <XText variant='inactive'>{name}</XText>
            {phone ? <XText variant='inactive'>{phone}</XText> : null}
            {email ? <XText variant='inactive'>{email}</XText> : null}
          </VStack>
          <TouchableOpacity
            onPress={() => this._navToEditingCustomerView()}
            style={{ alignItems: 'center', justifyContent: 'center', width: 40, height: 40 }}>
            <XIcon name='edit' family='FontAwesome5' />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this._deletedCustomer()}
            style={{ alignItems: 'center', justifyContent: 'center', width: 40, height: 40 }}>
            <XIcon name='delete' family='AntDesign' color={COLORS.APP_MAIN} />
          </TouchableOpacity>
        </HStack>
      </XCard>
    )
  }

  _renderAddresses = () => {
    const { profile } = this.state
    if (_.isNil(profile)) {
      return null
    }
    const { addresses } = profile
    if (_.isNil(addresses) || addresses.length < 1) {
      return null
    }
    // const { navigation } = this.props
    // const { state } = navigation
    // const { params } = state
    // const { mode, callBack } = params
    const mode = util.getNavParam(this.props, 'mode')
    const callBack = util.getNavParam(this.props, 'callBack')
    return (
      <XCard w='full' p='2' mt='2'>
        <HStack alignItems='center' justifyContent='center'>
          <XText variant='active' bold>
            ที่อยู่จัดส่ง
          </XText>
        </HStack>
        {addresses
          ? addresses.map((address: IAddress) => (
              <XAddressCard
                address={address}
                onPressCard={() => {
                  if (mode === 'PICK_ADDRESS') {
                    if (_.isFunction(callBack)) {
                      callBack(address)
                      this.goBack()
                    }
                  }
                }}
                onPressDelete={() => {
                  p.op.showConfirmation(
                    'คำเตือน',
                    'กรุณายืนยันการลบที่อยู่นี้\n\nที่อยู่ที่ลบไปแล้วจะไม่สามารถกู้คืนมาได้อีก คุณยืนยันหรือไม่',
                    () => {
                      this._deletedAddress(address)
                    },
                    null,
                    'ยืนยัน',
                    'ยกเลิก'
                  )
                }}
                onPressEdit={() => this._editAddress(address)}
              />
            ))
          : null}
      </XCard>
    )
  }

  _renderBtnAddAddress = () => (
    <XButton mt='2' onPress={() => this._addAddress()}>
      <XIcon name='plus' family='AntDesign' variant='primary' />
    </XButton>
  )

  render() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent p='2'>
          {this._renderProfile()}
          {this._renderAddresses()}
          {this._renderBtnAddAddress()}
        </XContent>
      </XContainer>
    )
  }
}
