import React from 'react'
import { Pressable } from 'native-base'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import { View, Text, TouchableOpacity } from 'react-native'
// import * as NavActions from '../../services/navigation'
import FAIcon from 'react-native-vector-icons/FontAwesome'

import { COLORS, STYLES } from 'x/config/styles'
import Segment from 'xui/components/Segment'

import p from 'x/config/platform-specific'

import BaseCompleteSalesReportView from 'x/modules/report/BaseCompleteSalesReportView'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import XButton from 'xui/components/XButton'
import XIcon from 'xui/components/XIcon'
import * as util from 'x/utils/util'
import VStack from 'xui/components/VStack'
import MkpLogo from 'xui/components/MkpLogo'
import { IMKPChannelDetail } from 'x/index'

require('moment-timezone')

const { NO_MARGIN_PADDING, FONT_SIZE_NORMAL } = STYLES

const { TEXT_ACTIVE } = COLORS

export default abstract class CompleteSalesReportView extends BaseCompleteSalesReportView {
  static displayName = 'ReportShippingView'

  abstract _renderShippingDateRange: () => void

  abstract _handleOnDownloadFileByPlatform(url: string, fileName: string): Promise<void>

  renderCustomHeader = () => {
    const leftBtn = { backIcon: true, onPressItem: () => util.navGoBack(this.props) }
    return (
      <XCustomHeader
        title='ยอดขายอย่างละเอียด'
        headerLeftProps={leftBtn}
        // headerRightProps={{ label: `แก้ไข`, onPressItem: () => util.setStatePromise(this, { mode: EDIT }) }}
      />
    )
  }

  _getParams(): { store_id: number } {
    // return this.props.navigation.state.params
    const params = util.getNavParams(this.props)
    return params
  }

  async _handleOnDownloadFile(url: string, fileName: string): Promise<void> {
    await this._handleOnDownloadFileByPlatform(url, fileName)
  }

  _renderSegmentCard(optKey) {
    const txtTitle = this.TXT_TITLES[optKey]
    const options = this.OPTIONS[optKey]
    const selectedValue = this.state.selectedOpts[optKey]
    return (
      <Box w='full'>
        <XText allowFontScaling={false} bold variant='active'>
          {txtTitle}
        </XText>
        <Segment onChange={(newValue) => this._onChangeSegment(optKey, newValue)} options={options} selectedValue={selectedValue} />
      </Box>
    )
  }

  _renderSegmentCardNoUseTitle(optKey) {
    const options = this.OPTIONS[optKey]
    const selectedValue = this.state.selectedOpts[optKey]
    return (
      <Box
        style={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginTop: 12,
          paddingHorizontal: 12,
        }}>
        <Segment onChange={(newValue) => this._onChangeSegment(optKey, newValue)} options={options} selectedValue={selectedValue} />
      </Box>
    )
  }

  _renderMoreInfoTitleCard(optKey) {
    const txtTitle = this.TXT_CHECKBOX[optKey]
    return (
      <Box
        style={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginTop: 12,
          paddingHorizontal: 12,
        }}>
        <Text
          allowFontScaling={false}
          style={{
            fontSize: FONT_SIZE_NORMAL,
            color: TEXT_ACTIVE,
            fontWeight: 'bold',
          }}>
          {txtTitle}
        </Text>
      </Box>
    )
  }

  _renderMoreInfoCard(optKey) {
    const txtDetail = this.TXT_CHECKBOX[optKey]
    return (
      <Pressable
        onPress={() => {
          if (!this._canRequestMoreColumns()) {
            p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
            return
          }
          // @ts-ignore
          this.setState({
            [optKey]: !this.state[optKey],
          })
        }}
        style={[
          STYLES.NO_MARGIN,
          {
            paddingTop: 11,
            paddingBottom: 11,
            paddingRight: 14,
            borderColor: '#cacaca',
          },
        ]}
        key={optKey}>
        <HStack
          style={{
            flex: 1,
            borderBottomWidth: 0,
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 10,
          }}>
          <FAIcon
            style={{
              flex: 1,
              fontSize: STYLES.FONT_ICON_NORMAL,
              color: COLORS.APP_MAIN,
            }}
            name={this.state[optKey] ? 'check-square-o' : 'square-o'}
          />
        </HStack>
        <HStack style={{ flex: 0, borderBottomWidth: 0 }} />
        <HStack style={{ flex: 8, alignItems: 'flex-start' }}>
          <Text
            allowFontScaling={false}
            style={{
              textAlign: 'left',
              fontSize: STYLES.FONT_SIZE_NORMAL,
              color: COLORS.TEXT_ACTIVE,
            }}>
            {txtDetail}
          </Text>
        </HStack>
      </Pressable>
    )
  }

  _renderFooter() {
    const { loading } = this.state
    return (
      <HStack w='full' p='1'>
        <XButton
          leftIcon={<XIcon family='FontAwesome' name='file-excel-o' size='xs' />}
          w='full'
          py='3'
          disabled={loading}
          onPress={this._downloadReportShippingUrl}>
          <XText color='white'>ดาวน์โหลด Excel</XText>
        </XButton>
        {/* <Button disabled={loading} style={btnOnMode(xCONS.BTN_MODE.ACTIVE_PRIMARY)} onPress={this._downloadReportShippingUrl}>
            <Text style={textOnBtnMode(xCONS.BTN_MODE.ACTIVE_PRIMARY)}>ดาวน์โหลด Excel</Text>
          </Button> */}
      </HStack>
    )
  }

  _renderSegmentPayment = (optKey: string) => {
    const txtTitle = this.TXT_TITLES[optKey]
    return (
      <Box mt='2'>
        <XText allowFontScaling={false} bold variant='active'>
          {txtTitle}
        </XText>
        <Segment
          selectedIndex={this.state.optionsPaymentNumber}
          options={this.OPTIONS.PAYMENT}
          onSegmentChange={this._onChangeSegmentPayment}
        />
      </Box>
    )
  }

  _renderSegmentShipping = (optKey: string) => {
    const txtTitle = this.TXT_TITLES[optKey]
    return (
      <Box mt='2'>
        <XText allowFontScaling={false} bold variant='active'>
          {txtTitle}
        </XText>
        <Segment
          selectedIndex={this.state.optionsShippingNumber}
          options={this.OPTIONS.SHIPPING}
          onSegmentChange={this._onChangeSegmentShipping}
        />
      </Box>
    )
  }

  _renderMkpChannelSelectorItem = (ch: IMKPChannelDetail, index: number) => {
    const { mkpChannelActiveMap = {} } = this.state
    const checked = mkpChannelActiveMap[ch.id] || false

    return (
      <HStack key={`mkp-item-${ch.id}_${index}`} pb='1'>
        <TouchableOpacity onPress={() => this._onMkpChannelSelectorItemPress(ch, index)}>
          <HStack
            px='2'
            py='1'
            space='1'
            alignItems='center'
            borderWidth='1'
            borderColor={checked ? 'gray.400' : 'gray.200'}
            borderRadius='lg'>
            {checked ? <XIcon name='checkmark' /> : <XIcon name='checkmark' color='transparent' />}
            <MkpLogo mkpId={ch.mkp_id} width={24} height={24} />
            <XText variant={checked ? 'active' : 'inactive'} numberOfLines={1}>
              {ch.name}
            </XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  _renderMkpChannelSelector = () => {
    const mkpChannels = util.getMKPChannels()
    return mkpChannels.map(this._renderMkpChannelSelectorItem)
  }

  renderFilterOrderOrigin = () => {
    const { segmentOrderOriginIndex = 0 } = this.state

    return (
      <VStack w='full' pt='2' space='1'>
        <HStack alignItems='center' space='1'>
          {/* <XIcon variant='active' name='local-shipping' family='MaterialIcons' /> */}
          <XText variant='active' bold>
            เฉพาะออเดอร์ในระบบ...
          </XText>
        </HStack>

        <Segment
          selectedIndex={segmentOrderOriginIndex}
          options={this.OPTIONS.MKP_CHANNELS}
          onSegmentChange={this.onChangeSegmentOrderOriginAtIndex}
        />

        {segmentOrderOriginIndex === 2 ? (
          <VStack w='full'>
            <XText variant='inactive'>แสดงเฉพาะช่องทางเหล่านี้...</XText>
            <HStack w='full' flexWrap='wrap' space='1.5'>
              {this._renderMkpChannelSelector()}
            </HStack>
          </VStack>
        ) : null}
      </VStack>
    )
  }

  _renderOrderChannelItem = (oCh, index) => {
    const { orderChannelActiveMap = {} } = this.state
    const checked = orderChannelActiveMap[oCh.id] || false

    return (
      <HStack key={`order-item-${oCh.id}_${index}`} pb='1'>
        <TouchableOpacity onPress={() => this._onOrderChannelSelectorItemPress(oCh, index)}>
          <HStack
            px='2'
            py='1'
            space='1'
            alignItems='center'
            borderWidth='1'
            borderColor={checked ? 'gray.400' : 'gray.200'}
            borderRadius='lg'>
            {checked ? <XIcon name='checkmark' /> : <XIcon name='checkmark' color='transparent' />}
            <XText variant={checked ? 'active' : 'inactive'} numberOfLines={1}>
              {oCh.label}
            </XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  renderFilterOrderChannels = () => {
    const { segmentOrderChannelsIndex = 0 } = this.state

    return (
      <VStack w='full' pt='2' space='1'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='locate' family='Ionicons' />
          <XText variant='active' bold>
            ช่องทางการขาย
          </XText>
        </HStack>

        <Segment
          selectedIndex={segmentOrderChannelsIndex}
          options={this.OPTIONS.ORDER_CHANNELS}
          onSegmentChange={this.onChangeSegmentOrderChannelsAtIndex}
        />

        {segmentOrderChannelsIndex === 1 ? (
          <VStack w='full'>
            <XText variant='inactive'>แสดงเฉพาะช่องทางการขายเหล่านี้...</XText>
            <HStack w='full' flexWrap='wrap' space='1.5'>
              {this.OrderChannelItems.map(this._renderOrderChannelItem)}
            </HStack>
          </VStack>
        ) : null}
      </VStack>
    )
  }

  render() {
    // util.logRender(this)
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent p='2'>
          {/* <View
            onLayout={(event) => {
              const { x, y, width, height } = event.nativeEvent.layout
              util.setStatePromise(this, { loadingWidth: width, loadingHeight: height })
            }}> */}
          <XCard w='full' p='2'>
            {this._renderSegmentCard('DATE_RANGE_ORDER')}
            <HStack h='2' />
            {this._renderSegmentCard('DATE_RANGE')}

            {this._renderShippingDateRange()}
            {this.state.showOrderOptions === true ? (
              // return (
              <View>
                {this._renderSegmentPayment('PAYMENT')}
                {this._renderSegmentShipping('SHIPPING')}
                {/* {this._renderMoreInfoTitleCard('TITLE_PAYMENT_STATUS')} */}
                {/* {this._renderMoreInfoCard('PAYMENT_STATUS')} */}

                {/* {this._renderMoreInfoTitleCard('TITLE_ORDER_STATUS')} */}
                {/* {this._renderMoreInfoCard('ORDER_STATUS')} */}
              </View>
            ) : // )
            null}

            {this.renderFilterOrderOrigin()}
            {this.renderFilterOrderChannels()}
          </XCard>
          {/* </View> */}
          {/* {this._renderLoading()} */}
        </XContent>
        {this._renderFooter()}
      </XContainer>
    )
  }
}
