import React from 'react'
import _ from 'lodash'
import { View } from 'react-native'

import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { S } from 'x/config/styles'
import CONS from 'x/config/constants'

import { IBarcodeScannerOutput, IShippingConfirmationCardProps, IShippingType, IShippingConfirmationFormData } from 'x/index'

import { FAQ_KEYS } from 'x/config/FAQ'
import CollapsableCard from '../CollapsableCard'
import BarcodeScanner from '../BarcodeScanner'
import XInput from '../XInput'
import XSwitch from '../XSwitch'
import ShippingTypePickerButton from './ShippingTypePickerButton'
import XSegment from '../XSegment'

import FaqHelpButton from '../FaqHelpButton'
import Sound from '../../utils/sound'
import XText from '../XText'
import HStack from '../HStack'

interface ISavedState {
  selectedFormTypeSegmentIndex?: number
}

const ShippingConfirmationCard: React.FC<IShippingConfirmationCardProps> = (props) => {
  // const [isShippingNoteSwitchOpen, setIsShippingNoteSwitchOpen] = React.useState(false)
  const [shippingNoteText, setShippingNoteText] = React.useState('')
  // const [isShippingTypeSwitchOpen, setIsShippingTypeSwitchOpen] = React.useState(false)
  const [selectedShippingTypeId, setSelectedShippingTypeId] = React.useState<number>(0)
  const [isForceCreateShippingSwitchOpen, setIsForceCreateShippingSwitchOpen] = React.useState(false)

  const [selectedFormTypeSegmentIndex, setSelectedFormTypeSegmentIndex] = React.useState<number>(0)

  const [isForceExpanded, setIsForceExpanded] = React.useState<boolean>(false)
  const [isBarcodeRead, setIsBarcodeRead] = React.useState<boolean>(false)

  // async function _onBarcodeScanned(barcode: IBarcodeScannerOutput) {
  //   if (barcode.data) {
  //     console.log('_onBarcodeScanned barcode => ', barcode)
  //     // await props.onFinish({ trackingNumber: barcode.data })
  //   }
  // }

  React.useEffect(() => {
    if (!props.disabled && props.expanded) {
      setIsForceExpanded(true)
    } else if (props.disabled && !props.expanded) {
      setIsForceExpanded(false)
    }
  }, [props.disabled, props.expanded])

  React.useEffect(() => {
    if (props.order && props.order.shipping_type_id) {
      const stiFromProps = props.order.shipping_type_id
      if (stiFromProps && stiFromProps !== selectedShippingTypeId) {
        setSelectedShippingTypeId(stiFromProps)
      }
    }
  }, [props.order])

  React.useEffect(() => {
    // set form on initialize
    _initialize()
  }, [props.expanded])

  React.useEffect(() => {
    // set form on data change
    if (!props.disabled && props.expanded) {
      _onChangeFormData()
    }
  }, [shippingNoteText, selectedShippingTypeId, isForceCreateShippingSwitchOpen])

  async function _initialize() {
    if (!props.disabled && props.expanded) {
      // Init form data
      await _onChangeFormData()

      // Load saved state
      const savedState = (await util.loadDefaultState(CONS.STATE_SAVE_KEYS.SHIPPING_CONFIRMATION_CARD)) as ISavedState
      if (savedState && _.isNumber(savedState.selectedFormTypeSegmentIndex) && savedState.selectedFormTypeSegmentIndex > -1) {
        setSelectedFormTypeSegmentIndex(savedState.selectedFormTypeSegmentIndex)
      }
    }
  }
  // function _getPreparedOrderProductsRequest(): Array<{ pp_id: number; qty: number }> {
  //   const preparedOrderProducts = []
  //   props.confirmedShippingOrderProducts.forEach((csp) => {
  //     const qty = csp.scannedQty
  //     if (qty > 0) {
  //       preparedOrderProducts.push({ pp_id: csp.pp_id, qty })
  //     }
  //   })
  //   return preparedOrderProducts
  // }
  // function _getPreparedTrackingNumberRequestObject(): { tracking_number: string } | { tracking_numbers: string[] } {
  //   if (props.confirmedTrackingNumbers.length > 1) {
  //     return { tracking_numbers: props.confirmedTrackingNumbers }
  //   }
  //   return { tracking_number: props.confirmedTrackingNumbers[0] }
  // }

  async function _onChangeFormData() {
    const od = props.order

    // let shippingTypeId = od.shipping_type_id
    // if (isShippingTypeSwitchOpen) {
    //   shippingTypeId = selectedShippingTypeId
    // }

    // const trackingNumberObj = _getPreparedTrackingNumberRequestObject()
    const formData: IShippingConfirmationFormData = {
      // shipping_type_id: shippingTypeId,
      shipping_type_id: selectedShippingTypeId,
      // ...trackingNumberObj,
    }

    // if (isShippingNoteSwitchOpen) {
    //   formData.note = shippingNoteText
    // }
    if (shippingNoteText && shippingNoteText.length > 0) {
      formData.note = shippingNoteText
    }

    if (isForceCreateShippingSwitchOpen) {
      formData.force_create = true
    }

    await props.onFormChange(formData)
  }

  // async function _onPressSubmitOrderShippingForm() {
  //   const od = props.order

  //   let shippingTypeId = od.shipping_type_id
  //   if (isShippingTypeSwitchOpen) {
  //     shippingTypeId = selectedShippingTypeId
  //   }

  //   const trackingNumberObj = _getPreparedTrackingNumberRequestObject()
  //   const reqBody: ICreateOrderShippingRequestBody = {
  //     order_id: od.id,
  //     store_id: od.store_id,
  //     shipping_type_id: shippingTypeId,
  //     products: _getPreparedOrderProductsRequest(),
  //     ...trackingNumberObj,
  //   }

  //   if (isShippingNoteSwitchOpen) {
  //     reqBody.note = shippingNoteText
  //   }

  //   await props.onSubmit(reqBody)
  // }

  function _onShippingTypePickerButtonChange(selectedShippingTypeItem: IShippingType) {
    setSelectedShippingTypeId(selectedShippingTypeItem.value)
  }

  // function _onChangeShippingTypeSwitch(newIsOpen: boolean) {
  //   setIsShippingTypeSwitchOpen(newIsOpen)
  //   if (!newIsOpen) {
  //     setSelectedShippingTypeId(props.order.shipping_type_id)
  //   }
  //   _onChangeFormData()
  // }

  // function _onChangeShippingNoteSwitch(newIsOpen: boolean) {
  //   setIsShippingNoteSwitchOpen(newIsOpen)
  //   if (!newIsOpen) {
  //     setShippingNoteText('')
  //   }
  //   _onChangeFormData()
  // }

  function _onChangeForceCreateShippingSwitch(nextIsOpen: boolean) {
    setIsForceCreateShippingSwitchOpen(nextIsOpen)
  }

  function _onChangeShippingNoteText(newText: string) {
    setShippingNoteText(newText)
  }

  async function _onBarcodeScanned(barcode: IBarcodeScannerOutput) {
    if (isBarcodeRead) {
      return
    }
    setIsBarcodeRead(true)
    if (barcode.data) {
      // console.log('_onBarcodeScanned barcode => ', barcode)
      const bd = barcode.data
      const xOrderName = props.order.id.toString()
      const mkpOrderName = props.order.mkp_order_id
      if (bd === xOrderName || (mkpOrderName && bd === mkpOrderName)) {
        props.onRequestSubmit()
      } else {
        let msgText = `สแกนพบ "${bd}" กรุณาณายืนยันออเดอร์ "${xOrderName}"`
        if (mkpOrderName) {
          msgText += ` หรือ "${mkpOrderName}"`
        }
        msgText += ' เพื่อยืนยันการจัดส่ง'
        p.op.showToast(msgText, 'warning', 5000)
        Sound.badBeep()
        await util.delay(1500)
      }
    }

    await util.delay(1000)
    setIsBarcodeRead(false)
  }

  const _renderBarcodeScanner = () => {
    if (props.disabled || !props.expanded || !props.order) {
      return null
    }
    return (
      <View style={[S.WIDTH_FULL, S.COLUMN_CENTER_TOP]}>
        <View style={[S.WIDTH_FULL, S.COLUMN_CENTER_TOP, S.MARGIN_TOP_16]}>
          <XText
            style={[
              S.TEXT_INACTIVE,
              S.TEXT_LARGER,
              S.TEXT_ALIGN_CENTER,
            ]}>{`สแกนเลขออเดอร์ "${props.order.id}" อีกครั้ง หรือกดปุ่ม "จัดส่ง" เพื่อทำการบันทึกจัดส่ง`}</XText>
          {/* <XText style={[S.TEXT_INACTIVE, S.TEXT_LARGER]}>{'สแกนเลขออเดอร์ #'}</XText>
          <XText style={[S.TEXT_ACTIVE, S.TEXT_LARGER, S.TEXT_BOLD]}>{props.order.id}</XText>
          <XText style={[S.TEXT_INACTIVE, S.TEXT_LARGER]}>{'อีกครั้ง หรือ กดปุ่ม'}</XText>
          <XText style={[S.TEXT_ACTIVE, S.TEXT_LARGER, S.TEXT_BOLD]}>{' จัดส่ง '}</XText>
          <XText style={[S.TEXT_INACTIVE, S.TEXT_LARGER]}>{'เพื่อทำการบันทึกจัดส่ง'}</XText> */}
        </View>
        <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 8 }]}>
          <BarcodeScanner
            saveStateKey={CONS.STATE_SAVE_KEYS.BARCODE_SCANNER_SHIPPING_CONFIRMATION}
            disabled={isBarcodeRead}
            onBarcodeScanned={_onBarcodeScanned}
          />
        </View>

        {/* จองพื้นที่ว่างไว้เฉยๆ  */}
        <View style={[S.WIDTH_FULL, S.HEIGHT_24]} />
      </View>
    )
  }

  async function _onFormTypeSegmentChange(opt, idx: number) {
    setSelectedFormTypeSegmentIndex(idx)
    // const savedState = (await util.loadDefaultState(CONS.STATE_SAVE_KEYS.SHIPPING_CONFIRMATION_CARD)) as ISavedState
    await util.saveDefaultState(CONS.STATE_SAVE_KEYS.SHIPPING_CONFIRMATION_CARD, { selectedFormTypeSegmentIndex: idx })
  }

  const _renderFormTypeSegment = () => (
    <View style={[S.WIDTH_FULL, S.ROW_CENTER]}>
      <XSegment
        segmentContainerStyle={[
          S.FLEX,
          // S.HEIGHT_28,
          // {
          //   width: 320,
          // },
          // props.cameraContainerWidth ? { width: props.cameraContainerWidth } : {},
        ]}
        segmentTextStyle={[S.TEXT_LARGER]}
        options={[
          { label: 'ระบุข้อมูลเพิ่มเติม', value: 0 },
          { label: 'สแกนยืนยัน', value: 1 },
        ]}
        selectedIndex={selectedFormTypeSegmentIndex}
        onSegmentChange={_onFormTypeSegmentChange}
      />
    </View>
  )

  const _renderFormData = () => (
    <View style={[S.WIDTH_FULL, S.COLUMN_CENTER_TOP]}>
      <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, S.MARGIN_TOP_16]}>
        <XText
          style={[
            // isShippingTypeSwitchOpen ? S.TEXT_ACTIVE : S.TEXT_INACTIVE,
            S.TEXT_ACTIVE,
            S.TEXT_LARGER,
          ]}>
          เปลี่ยนรูปแบบจัดส่ง
        </XText>
        {/* <XSwitch value={isShippingTypeSwitchOpen} onValueChange={_onChangeShippingTypeSwitch} /> */}
      </View>
      <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_START, { marginTop: 8 }]}>
        <ShippingTypePickerButton
          // disabled={!isShippingTypeSwitchOpen}
          onChange={_onShippingTypePickerButtonChange}
          selectedShippingTypeId={selectedShippingTypeId}
        />
      </View>

      <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, S.MARGIN_TOP_16]}>
        <XText
          style={[
            // isShippingNoteSwitchOpen ? S.TEXT_ACTIVE : S.TEXT_INACTIVE,
            S.TEXT_ACTIVE,
            S.TEXT_LARGER,
          ]}>
          ระบุหมายเหตุ
        </XText>
        {/* <XSwitch value={isShippingNoteSwitchOpen} onValueChange={_onChangeShippingNoteSwitch} /> */}
      </View>
      <View
        style={[
          S.WIDTH_FULL,
          S.COLUMN_LEFT_TOP,
          // isShippingNoteSwitchOpen ? {} : S.BG_LIGHT_GREY,
          { marginTop: 8 },
        ]}>
        <XInput
          w='full'
          // editable={isShippingNoteSwitchOpen}
          // disabled={!isShippingNoteSwitchOpen}
          placeholder='พิมพ์หมายเหตุของเลขพัสดุ...'
          style={{ minHeight: 80 }}
          value={shippingNoteText}
          multiline
          onChangeText={_onChangeShippingNoteText}
        />
      </View>
      <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, S.MARGIN_TOP_16]}>
        <HStack flex={1} alignItems='center'>
          <XText variant={isForceCreateShippingSwitchOpen ? 'active' : 'inactive'}>บันทึกจัดส่งแม้จำนวนสินค้าในคลังไม่พอ</XText>
          <FaqHelpButton
            faqKey={FAQ_KEYS.FORCE_CREATE_SHIPPING}
            headerTitle='บันทึกจัดส่งแม้จำนวนสินค้าในคลังไม่พอ'
            // containerStyle={S.PADDING_4}
          />
        </HStack>
        <XSwitch value={isForceCreateShippingSwitchOpen} onValueChange={_onChangeForceCreateShippingSwitch} />
      </View>

      {/* <View style={[S.WIDTH_FULL, S.COLUMN_CENTER_TOP, S.MARGIN_TOP_16]}>
        <TouchableOpacity style={[S.BUTTON_PRIMARY]} onPress={_onPressSubmitOrderShippingForm}>
          <XText style={[S.TEXT_ACTIVE_DARK, S.TEXT_LARGER, S.TEXT_BOLD]}>{'ยืนยันจัดส่ง'}</XText>
        </TouchableOpacity>
      </View> */}
    </View>
  )

  function renderContent() {
    // if (!visibleShippingTypeItems) {
    //   return null
    // }

    return (
      <View style={[S.WIDTH_FULL, S.PADDING_HORIZONTAL_12, S.PADDING_VERTICAL_8, S.COLUMN_CENTER_TOP, S.MARGIN_BOTTOM_16]}>
        {_renderFormTypeSegment()}
        {selectedFormTypeSegmentIndex === 0 ? _renderFormData() : _renderBarcodeScanner()}
        <View style={{ height: 1 }} />
      </View>
    )
  }

  return (
    <CollapsableCard
      disabledAnimation
      disabled={isForceExpanded}
      expanded={props.expanded || isForceExpanded}
      onPressToggle={props.onPressToggle}
      renderContent={renderContent}
      contentContainerStyle={[props.expanded || isForceExpanded ? { minHeight: 346 } : {}]}>
      <View style={[S.HEIGHT_44, S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, S.PADDING_8, props.disabled ? S.BG_GREY : S.BG_SECONDARY]}>
        <View style={[S.FLEX, S.ROW_MIDDLE_START]}>
          <XText variant={props.expanded && !props.disabled ? 'active' : 'inactive'} bold>
            4. ยืนยันข้อมูลจัดส่ง หรือ ระบุข้อมูลเพิ่มเติม
          </XText>
        </View>
      </View>
    </CollapsableCard>
  )
}

ShippingConfirmationCard.displayName = 'ShippingConfirmationCard'
// ShippingConfirmationCard.defaultProps = {}

export default ShippingConfirmationCard
