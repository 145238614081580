/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react'

import BaseERPDetailView from 'x/modules/erp/BaseERPDetailView'
import XCustomHeader from 'xui/components/XCustomHeader'
// import { Input } from 'react-native-elements'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import Box from 'xui/components/Box'
import { View, TouchableOpacity } from 'react-native'
import XIconButton from 'xui/components/XIconButton'
import * as util from 'x/utils/util'
import XInput from 'xui/components/XInput'
import CONS from 'x/config/constants'
import HStack from 'xui/components/HStack'
import { COLORS, STYLES } from 'x/config/styles'
import XFaqModal from 'xui/components/XFaqModal'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'
import HelpButton from 'xui/components/HelpButton'
import XRadioList from 'xui/components/XRadioList'
import VStack from 'xui/components/VStack'
import XSwitch from 'xui/components/XSwitch'
import Segment from 'xui/components/Segment'
import _ from 'lodash'
import ErpLogo from 'xui/components/ErpLogo'
import XDropdown from 'xui/components/XDropdown'
import DotDotDotVerticalIcon from 'xui/components/DotDotDotVerticalIcon'
import XIcon from 'xui/components/XIcon'
import DeleteErpChannel from 'xui/modules/erp/DeleteErpChannel'

const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />
const VIEW_WIDTH_TEN = <View style={{ width: 10 }} />
const PATTERN = /^[a-zA-Z0-9_-]+$/

export default abstract class BaseUIERPDetailView extends BaseERPDetailView {
  renderCustomHeader = () => {
    const {} = this.state
    return (
      <XCustomHeader
        title='ระบบบัญชี'
        headerLeftProps={{ backIcon: true, onPressItem: () => this.goBack() }}
        renderHeaderRight={this._renderHamburgerButton}
      />
    )
  }

  _renderInputErpName = () => {
    const { erpName, mode, isEditErpName, erpNameBeforeEdit } = this.state
    const isViewMode = mode === 'VIEW'
    // console.log('erpNameBeforeEdit => ', erpNameBeforeEdit)
    return (
      <Box mt='2' w='full'>
        {/* {VIEW_HEIGHT_TEN} */}
        <XText variant={isViewMode ? 'inactive' : 'active'}>ชื่อการเชื่อมต่อ</XText>
        {isViewMode && !isEditErpName ? (
          <Box w='full' style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Box style={{ flex: 1, alignItems: 'flex-start' }}>
              <XText variant='active' bold fontSize='lg' numberOfLines={1}>
                {erpName}
              </XText>
            </Box>
            {VIEW_WIDTH_TEN}
            <Box
              style={{
                // width: 80,
                // height: STYLES.BTN_HEIGHT_SMALL,
                // alignItems: 'center',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}>
              <XIconButton
                variant='outline'
                name='edit'
                family='FontAwesome5'
                onPress={() => {
                  this.setState({
                    isEditErpName: true,
                    erpNameBeforeEdit: erpName,
                  })
                  // util.setStatePromise(this, { isEditErpName: true, erpNameBeforeEdit: erpName })
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box w='full'>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <XInput
                onChangeText={(txt: string) => this._updateErpName(txt)}
                value={erpName}
                placeholder='ระบุชื่อการเชื่อมต่อ เช่น ชื่อบริษัท'
                maxLength={40}
                style={{ height: 30, flex: 1 }}
              />

              {isEditErpName ? (
                <Box style={{ flexDirection: 'row' }}>
                  <Box w='2' />
                  <XIconButton
                    variant='outline'
                    name='closecircleo'
                    family='AntDesign'
                    onPress={() => {
                      util.setStatePromise(this, { isEditErpName: false, erpName: erpNameBeforeEdit })
                    }}
                  />
                  <Box w='2' />
                  <XIconButton variant='outline' name='save' family='FontAwesome5' onPress={() => this._submitNewErpName()} />
                </Box>
              ) : null}
            </View>
          </Box>
        )}
      </Box>
    )
  }

  _renderLogo = () => {
    const { erpChannelDetail } = this.state
    if (_.isNil(erpChannelDetail)) {
      return null
    }
    const erpSystemName = util.getErpChannelSystemName(erpChannelDetail.erp_system_id)
    return (
      <Box mt='2'>
        <XText variant='inactive'>ช่องทางการเชื่อมต่อ</XText>
        <XCard p='2'>
          <TouchableOpacity disabled style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            <ErpLogo erpSystemId={erpChannelDetail.erp_system_id} />
            <Box pl='4'>
              <XText fontSize='md' bold variant='active'>
                {erpSystemName}
              </XText>
            </Box>
          </TouchableOpacity>
        </XCard>
      </Box>
    )
  }

  _renderCreateAt = () => {
    const { erpChannelDetail } = this.state
    if (_.isNil(erpChannelDetail)) {
      return null
    }

    return (
      <HStack flex={1} mt='1'>
        <HStack flex={1} alignItems='center'>
          <XText variant='inactive'>เชื่อมต่อเมื่อ</XText>
        </HStack>
        <HStack flex={1} alignItems='center' justifyContent='flex-end'>
          <XText variant='inactive'>{erpChannelDetail.created_at}</XText>
        </HStack>
      </HStack>
    )
  }

  _renderRegisterForVat = () => {
    const { erpChannelDetail } = this.state
    if (_.isNil(erpChannelDetail)) {
      return null
    }

    // console.log('CHACK !! => ', _.has(erpChannelDetail, 'is_vat'))
    if (!_.has(erpChannelDetail, 'is_vat')) {
      return null
    }
    const register = erpChannelDetail.is_vat ? 'จดทะเบียน' : 'ไม่จดทะเบียน'
    return (
      <HStack w='full' mb='1'>
        <HStack flex={1}>
          <XText variant='inactive'>จดทะเบียนภาษีมูลค่าเพิ่ม</XText>
        </HStack>
        <HStack flex={1} justifyContent='flex-end'>
          <XText variant='inactive'>{register}</XText>
        </HStack>
      </HStack>
    )
  }

  _renderAccount = () => {
    const { erpChannelDetail } = this.state
    if (_.isNil(erpChannelDetail)) {
      return null
    }
    const nowUseAccount = erpChannelDetail.erp_payment_account_count
    const totalAccount = erpChannelDetail.payment_account_count
    return (
      <XCard p='2'>
        <HStack alignItems='center' w='full'>
          <XText bold>รหัสบัญชี</XText>
          <XFaqModal
            key='รหัสผู้ติดต่อ'
            headerTitle='รหัสผู้ติดต่อ'
            // @ts-ignore
            FAQ={FAQ_CONSTANTS.FAQ_PEAK_ACCOUNT}
            initiateOpenIndex={[0]}
            renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
          />
        </HStack>
        <HStack>
          {nowUseAccount > 0 ? null : (
            <XText color={COLORS.RED}>*ตั้งค่าระบุ รหัสบัญชี หากไม่ระบุบัญชีรับชำระ XSelly จะไม่สามารถสร้างเอกสารไปที่ PEAK ได้</XText>
          )}
        </HStack>

        {/* // ยังไม่ได้ตั้งค่าเชื่อมบัญชี PEAK */}
        <HStack w='100%' mt='2'>
          <HStack flex={1} alignItems='center'>
            <XText>ยังไม่ได้ตั้งค่าเชื่อมบัญชี PEAK</XText>
          </HStack>
          <HStack flex={1} alignItems='center' justifyContent='flex-end'>
            <XText>{`${nowUseAccount}/${totalAccount}`}</XText>
          </HStack>
        </HStack>
        {/* // ตั้งค่าสำหรับบัญชีรับชำระของร้าน */}
        <HStack flex={1}>
          <HStack flex={1} alignItems='center'>
            <XText>ตั้งค่าสำหรับบัญชีรับชำระของร้าน</XText>
          </HStack>
          <HStack flex={1} alignItems='center' justifyContent='flex-end'>
            <TouchableOpacity
              onPress={() => this._navToERPPaymentAccountView()}
              style={{ backgroundColor: COLORS.APP_MAIN, borderRadius: 90, padding: 4 }}>
              <XText px='1' color={COLORS.WHITE}>
                ตั้งค่าบัญชีรับชำระ
              </XText>
            </TouchableOpacity>
          </HStack>
        </HStack>
      </XCard>
    )
  }

  _renderContactId = () => {
    const { contactIdRadioIndex } = this.state

    return (
      <XCard mt='2' p='2' w='full'>
        <HStack alignItems='center' w='full'>
          <XText bold>รหัสผู้ติดต่อ</XText>
          <XFaqModal
            key='รหัสผู้ติดต่อ'
            headerTitle='รหัสผู้ติดต่อ'
            // @ts-ignore
            FAQ={FAQ_CONSTANTS.FAQ_ERP_CONTACT}
            initiateOpenIndex={[0, 1, 2]}
            renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
          />
        </HStack>

        <HStack w='full'>
          <XText>เมื่อสร้างเอกสารไปที่ PEAK ให้ XSelly ใช้ข้อมูลนี้เป็นรหัสผู้ติดต่อของ PEAK</XText>
        </HStack>

        <XRadioList
          options={[
            {
              label: 'เลขประจำตัวผู้เสียภาษี',
              value: 0,
            },

            // {
            //   label: 'อ้างอิง 1 จาก ที่อยู่ลูกค้า',
            //   value: 1,
            // },
            // {
            //   label: 'อ้างอิง 2 จาก ที่อยู่ลูกค้า',
            //   value: 2,
            // },
          ]}
          onRadioChange={(newVal) => this.contactIdRadioOnChange(newVal.value)}
          selectedIndex={contactIdRadioIndex}
        />
        <HStack w='full' alignItems='center' mt='2'>
          <HStack flex={1}>
            <XText>{`สำหรับออเดอร์ที่ไม่มีรหัสผู้ติดต่อที่เลือกไว้ข้างต้น XSelly จะส่งเป็น "รหัสผู้ติดต่อเฉพาะช่องทางขาย"`}</XText>
          </HStack>
          <HStack w='8'>
            <XFaqModal
              key='รหัสผู้ติดต่อเฉพาะช่องทางขาย'
              headerTitle='รหัสผู้ติดต่อเฉพาะช่องทางขาย'
              // @ts-ignore
              FAQ={FAQ_CONSTANTS.FAQ_ERP_CONTACT_ONLY}
              initiateOpenIndex={[0]}
              renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
            />
          </HStack>
        </HStack>
        <HStack>
          <TouchableOpacity
            onPress={() => this._navToERPChannelContact()}
            style={{ backgroundColor: COLORS.APP_MAIN, borderRadius: 90, padding: 4 }}>
            <XText px='1' color={COLORS.WHITE}>
              ตั้งค่าบัญชี รหัสผู้ติดต่อเฉพาะช่องทางขาย
            </XText>
          </TouchableOpacity>
        </HStack>
      </XCard>
    )
  }

  _renderProductNumber = () => {
    const { productNumberRadioIndex } = this.state
    return (
      <XCard mt='2' p='2'>
        <VStack w='full'>
          <HStack alignItems='center' w='full'>
            <XText bold>เลขที่สินค้า</XText>
            <XFaqModal
              key='เลขที่สินค้า'
              headerTitle='เลขที่สินค้า'
              // @ts-ignore
              FAQ={FAQ_CONSTANTS.FAQ_ERP_PRODUCT_NUMBER}
              initiateOpenIndex={[0]}
              renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
            />
          </HStack>
          <VStack w='full'>
            <HStack w='full'>
              <XText>เมื่อสร้างเอกสารไปที่ PEAK ให้ XSelly ใช้ข้อมูลนี้เป็นเลขที่สินค้า ของ PEAK</XText>
            </HStack>

            <XRadioList
              options={[
                {
                  label: 'SKU',
                  value: 0,
                },

                {
                  label: 'UPC',
                  value: 1,
                },
              ]}
              onRadioChange={(newVal) => this.productNumberadioOnChange(newVal.value)}
              selectedIndex={productNumberRadioIndex}
            />
          </VStack>
        </VStack>
      </XCard>
    )
  }

  _renderDocumentType = () => {
    const {
      documentBillingTypeRadioIndex,
      isDocumentBillingType,
      documentReceiptTypeRadioIndex,
      isDocumentReceiptType,
      documentQuotationTypeRadioIndex,
      isDocumentQuotationType,
      invoiceDueDays,
      isEditinvoiceDueDays,
      erpBeforeInvoiceDueDays,
    } = this.state

    const documentBillingTypeRadioOnChange = async (newVal) => {
      // console.log('newVal => ', newVal)
      const { value } = newVal
      const confirm = await this._confirmChangeMkpSetting()
      if (!confirm) {
        return
      }
      if (value === 0) {
        this._UpdateChannel('invoice_issue_date', 'PEAK_CREATE_DATE')
      } else {
        this._UpdateChannel('invoice_issue_date', 'XSELLY_CREATE_DATE')
      }
    }

    const documentBillingTypeSwitchOnChange = async (switchVal: boolean) => {
      const confirm = await this._confirmChangeMkpSetting()
      if (!confirm) {
        return
      }
      if (switchVal) {
        this._UpdateChannel('invoice_issue_date', 'PEAK_CREATE_DATE')
      } else {
        this._UpdateChannel('invoice_issue_date', '')
      }
    }

    const documentReceiptTypeRadioOnChange = async (newVal) => {
      const { value } = newVal
      const confirm = await this._confirmChangeMkpSetting()
      if (!confirm) {
        return
      }
      if (value === 0) {
        this._UpdateChannel('receipt_issue_date', 'PAYMENT_COMPLETE_DATE')
      } else if (value === 1) {
        this._UpdateChannel('receipt_issue_date', 'PEAK_CREATE_DATE')
      } else {
        this._UpdateChannel('receipt_issue_date', 'XSELLY_CREATE_DATE')
      }
    }

    const documentReceiptTypeSwitchOnChange = async (switchVal: boolean) => {
      const confirm = await this._confirmChangeMkpSetting()
      if (!confirm) {
        return
      }
      if (switchVal) {
        this._UpdateChannel('receipt_issue_date', 'PAYMENT_COMPLETE_DATE')
      } else {
        this._UpdateChannel('receipt_issue_date', '')
      }
    }

    const documentQuotationTypeRadioOnChange = async (newVal) => {
      // console.log('newVal => ', newVal)
      const { value } = newVal
      const confirm = await this._confirmChangeMkpSetting()
      if (!confirm) {
        return
      }
      if (value === 0) {
        this._UpdateChannel('quotation_issue_date', 'PEAK_CREATE_DATE')
      } else {
        this._UpdateChannel('quotation_issue_date', 'XSELLY_CREATE_DATE')
      }
    }

    const documentQuotationTypewitchOnChange = async (switchVal: boolean) => {
      const confirm = await this._confirmChangeMkpSetting()
      if (!confirm) {
        return
      }
      if (switchVal) {
        this._UpdateChannel('quotation_issue_date', 'PEAK_CREATE_DATE')
      } else {
        this._UpdateChannel('quotation_issue_date', '')
      }
    }

    return (
      <XCard mt='2' p='2'>
        <HStack alignItems='center'>
          <XText bold>ประเภทเอกสาร</XText>
          <XFaqModal
            key='ประเภทเอกสาร'
            headerTitle='ประเภทเอกสาร'
            // @ts-ignore
            FAQ={FAQ_CONSTANTS.FAQ_ERP_DOCUMENT_TYPE}
            initiateOpenIndex={[0]}
            renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
          />
        </HStack>
        <XText>ประเภทเอกสารที่คุณต้องการให้สร้างไปที่ PEAK ได้</XText>

        {/* // ใบเสนอราคา */}
        {/* <HStack w='100%' mt='2'>
          <HStack flex={1} alignItems='center'>
            <XText bold>ใบเสนอราคา</XText>
          </HStack>
          <HStack flex={1} alignItems='center' justifyContent='flex-end'>
            <XSwitch
              value={isDocumentQuotationType}
              onValueChange={(switchVal) => {
                documentQuotationTypewitchOnChange(switchVal)
              }}
            />
          </HStack>
        </HStack> */}
        {/* {isDocumentQuotationType ? (
          <VStack>
            <XText>เลือกวันที่ออกเอกสาร</XText>
            <XRadioList
              options={[
                {
                  label: 'วันที่สร้างเอกสารใน PEAK',
                  value: 0,
                },

                {
                  label: 'วันที่สร้างออเดอร์ใน XSelly',
                  value: 1,
                },
              ]}
              onRadioChange={documentQuotationTypeRadioOnChange}
              selectedIndex={documentQuotationTypeRadioIndex}
            />
          </VStack>
        ) : null} */}
        {/* <HStack borderBottomWidth='1' mx={-8} borderBottomColor={COLORS.TEXT_INACTIVE} mt='2' flex={1} /> */}

        {/* // ใบแจ้งหนี้ */}
        <HStack w='100%' mt='2'>
          <HStack flex={1} alignItems='center'>
            <XText bold>ใบแจ้งหนี้</XText>
          </HStack>
          <HStack flex={1} alignItems='center' justifyContent='flex-end'>
            <XSwitch
              value={isDocumentBillingType}
              onValueChange={(switchVal) => {
                documentBillingTypeSwitchOnChange(switchVal)
              }}
            />
          </HStack>
        </HStack>
        {isDocumentBillingType ? (
          <VStack>
            <XText>เลือกวันที่ออกเอกสาร</XText>
            <XRadioList
              options={[
                {
                  label: 'วันที่สร้างเอกสารใน PEAK',
                  value: 0,
                },

                {
                  label: 'วันที่สร้างออเดอร์ใน XSelly',
                  value: 1,
                },
              ]}
              onRadioChange={documentBillingTypeRadioOnChange}
              selectedIndex={documentBillingTypeRadioIndex}
            />
          </VStack>
        ) : null}
        {isDocumentBillingType ? (
          <VStack w='full' mt='2'>
            <HStack flex={1} alignItems='center'>
              <XText>จำนวนวันที่ครบกำหนดนับจากวันที่สร้างเอกสาร</XText>
            </HStack>

            {!isEditinvoiceDueDays ? (
              <HStack w='full'>
                <HStack flex={1}>
                  <XText bold>{`${invoiceDueDays} วัน`}</XText>
                </HStack>
                <HStack flex={1} justifyContent='flex-end'>
                  <XIconButton
                    variant='outline'
                    name='edit'
                    family='FontAwesome5'
                    onPress={() => {
                      this.setState({
                        isEditinvoiceDueDays: true,
                        erpBeforeInvoiceDueDays: invoiceDueDays,
                      })
                      // util.setStatePromise(this, { isEditErpName: true, erpNameBeforeEdit: erpName })
                    }}
                  />
                </HStack>
              </HStack>
            ) : null}

            {isEditinvoiceDueDays ? (
              <HStack w='full'>
                <HStack flex={1}>
                  <XInput
                    value={`${invoiceDueDays}`}
                    placeholder='เช่น 1'
                    w='40'
                    // maxLength={20}
                    textAlign='left'
                    isNumber
                    onChangeText={(newText) =>
                      this.setState({
                        invoiceDueDays: +newText,
                      })
                    }
                  />
                </HStack>
                <HStack flex={1} justifyContent='flex-end'>
                  {isEditinvoiceDueDays ? (
                    <Box style={{ flexDirection: 'row' }}>
                      <Box w='2' />
                      <XIconButton
                        variant='outline'
                        name='closecircleo'
                        family='AntDesign'
                        onPress={() => {
                          util.setStatePromise(this, { isEditinvoiceDueDays: false, invoiceDueDays: erpBeforeInvoiceDueDays })
                        }}
                      />
                      <Box w='2' />
                      <XIconButton variant='outline' name='save' family='FontAwesome5' onPress={() => this._submitInvoiceDueDays()} />
                    </Box>
                  ) : null}
                </HStack>
              </HStack>
            ) : null}
          </VStack>
        ) : null}

        <HStack borderBottomWidth='1' mx={-8} borderBottomColor={COLORS.TEXT_INACTIVE} mt='4' flex={1} />

        {/* // ใบเสร็จรับเงิน */}
        <HStack w='100%' mt='2'>
          <HStack flex={1} alignItems='center'>
            <XText bold>ใบเสร็จรับเงิน</XText>
          </HStack>
          <HStack flex={1} alignItems='center' justifyContent='flex-end'>
            <XSwitch
              value={isDocumentReceiptType}
              onValueChange={(switchVal) => {
                documentReceiptTypeSwitchOnChange(switchVal)
              }}
            />
          </HStack>
        </HStack>
        {isDocumentReceiptType ? (
          <VStack>
            <XText>เลือกวันที่ออกเอกสาร</XText>
            <XRadioList
              options={[
                {
                  label: 'วันที่ชำระครบถ้วน',
                  value: 0,
                },

                {
                  label: 'วันที่สร้างเอกสารใน PEAK',
                  value: 1,
                },
                {
                  label: 'วันที่สร้างออเดอร์ใน XSelly',
                  value: 2,
                },
              ]}
              onRadioChange={documentReceiptTypeRadioOnChange}
              selectedIndex={documentReceiptTypeRadioIndex}
            />
          </VStack>
        ) : null}

        {/* <HStack borderBottomWidth='1' mx={-8} borderBottomColor={COLORS.TEXT_INACTIVE} mt='2' flex={1} /> */}

        {/* // ใบกำกับภาษีขาย  */}
        {/* <HStack w='100%' mt='2'>
          <HStack flex={1} alignItems='center'>
            <XText bold>ใบกำกับภาษีขาย </XText>
          </HStack>
          <HStack flex={1} alignItems='center' justifyContent='flex-end'>
            <XSwitch
              value={isDocumentTaxType}
              onValueChange={(switchVal) =>
                this.setState({
                  isDocumentTaxType: switchVal,
                })
              }
            />
          </HStack>
        </HStack>
        {isDocumentTaxType ? (
          <VStack>
            <XText>เลือกวันที่ออกเอกสาร</XText>
            <XRadioList
              options={[
                {
                  label: 'วันที่ชำระครบถ้วน',
                  value: 0,
                },

                {
                  label: 'วันที่สร้างเอกสารใน PEAK',
                  value: 1,
                },
                {
                  label: 'วันที่สร้างออเดอร์ใน XSelly',
                  value: 2,
                },
              ]}
              onRadioChange={documentTaxTypeRadioOnChange}
              selectedIndex={documentTaxTypeRadioIndex}
            />
          </VStack>
        ) : null} */}
      </XCard>
    )
  }

  _renderVat = () => {
    const { vatSegmentIndex, erpChannelDetail } = this.state

    if (_.isNil(erpChannelDetail)) {
      return null
    }
    if (_.has(erpChannelDetail, 'is_vat') && !erpChannelDetail.is_vat) {
      return null
    }
    return (
      <XCard p='2' mt='2' w='full'>
        <HStack alignItems='center' w='full'>
          <XText bold>ภาษีมูลค่าเพิ่ม (VAT)</XText>
          <XFaqModal
            key='ภาษีมูลค่าเพิ่ม (VAT)'
            headerTitle='ภาษีมูลค่าเพิ่ม (VAT)'
            // @ts-ignore
            FAQ={FAQ_CONSTANTS.FAQ_ERP_VAT}
            initiateOpenIndex={[0]}
            renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
          />
        </HStack>

        <XText>ราคาสินค้าใน XSelly เป็นราคาที่..</XText>
        <Segment
          options={['รวม VAT แล้ว', 'VAT 0%', 'ไม่มี VAT']}
          selectedIndex={vatSegmentIndex}
          onSegmentChange={(newIndex) => {
            this._vatChange(newIndex, 'vat_type')
          }}
        />
      </XCard>
    )
  }

  _renderShipping = () => {
    const { erpShippingFeeIndex, erpChannelDetail } = this.state

    let renderVat = (
      <VStack>
        <HStack>
          <XText>ค่าจัดส่งที่คิดผู้ซื้อ ในแต่ละออเดอร์ของ XSelly เป็น ยอดที่...</XText>
        </HStack>
        <Segment
          options={['รวม VAT แล้ว', 'VAT 0%', 'ไม่มี VAT']}
          selectedIndex={erpShippingFeeIndex}
          onSegmentChange={(newIndex) => {
            this._vatChange(newIndex, 'erp_shipping_fee_vat_type')
          }}
        />
      </VStack>
    )
    if (_.isNil(erpChannelDetail)) {
      renderVat = null
    }
    if (_.has(erpChannelDetail, 'is_vat') && !erpChannelDetail.is_vat) {
      renderVat = null
    }

    return (
      <XCard p='2' mt='2'>
        <VStack pb='2'>
          <HStack alignItems='center' w='full'>
            <XText bold>ค่าจัดส่ง</XText>
            <XFaqModal
              key='ค่าจัดส่ง'
              headerTitle='ค่าจัดส่ง'
              // @ts-ignore
              FAQ={FAQ_CONSTANTS.FAQ_ERP_SHIPPING}
              initiateOpenIndex={[0]}
              renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
            />
          </HStack>

          {renderVat}
          {this._renderInputProductNumberCode()}
        </VStack>
      </XCard>
    )
  }

  _renderInputProductNumberCode = () => {
    const { erpProductCodeText, mode, isEditErpProductCode, erpBeforeProductCodeText } = this.state
    const isViewMode = mode === 'VIEW'
    const name = erpProductCodeText === '' ? 'ยังไม่ได้ระบุ' : erpProductCodeText
    return (
      <Box mt='2'>
        {/* {VIEW_HEIGHT_TEN} */}
        <HStack alignItems='center' w='full'>
          <XText variant={isViewMode ? 'inactive' : 'active'}>รหัสบัญชีสำหรับค่าจัดส่ง</XText>
          <XFaqModal
            key='รหัสบัญชีสำหรับค่าจัดส่ง'
            headerTitle='รหัสบัญชีสำหรับค่าจัดส่ง'
            // @ts-ignore
            FAQ={FAQ_CONSTANTS.FAQ_ERP_PAYMENT_ACCOUNT_SHIPPING}
            initiateOpenIndex={[0]}
            renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
          />
        </HStack>

        {isViewMode && !isEditErpProductCode ? (
          <Box w='full' style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Box style={{ flex: 1, alignItems: 'flex-start' }}>
              <XText variant='active' bold fontSize='lg' numberOfLines={1}>
                {name}
              </XText>
            </Box>
            {VIEW_WIDTH_TEN}
            <Box
              style={{
                // width: 80,
                // height: STYLES.BTN_HEIGHT_SMALL,
                // alignItems: 'center',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}>
              <XIconButton
                variant='outline'
                name='edit'
                family='FontAwesome5'
                onPress={() => {
                  this.setState({
                    isEditErpProductCode: true,
                    erpBeforeProductCodeText: erpProductCodeText,
                  })
                  // util.setStatePromise(this, { isEditErpName: true, erpNameBeforeEdit: erpName })
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box w='full'>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <XInput
                onChangeText={(txt: string) => {
                  if (PATTERN.test(txt)) {
                    this.setState({
                      erpProductCodeText: txt,
                    })
                  }
                }}
                value={erpProductCodeText}
                placeholder='ระบุเลขที่สินค้าสำหรับค่าจัดส่ง'
                maxLength={20}
                style={{ height: 30, flex: 1 }}
              />

              {isEditErpProductCode ? (
                <Box style={{ flexDirection: 'row' }}>
                  <Box w='2' />
                  <XIconButton
                    variant='outline'
                    name='closecircleo'
                    family='AntDesign'
                    onPress={() => {
                      util.setStatePromise(this, { isEditErpProductCode: false, erpProductCodeText: erpBeforeProductCodeText })
                    }}
                  />
                  <Box w='2' />
                  <XIconButton variant='outline' name='save' family='FontAwesome5' onPress={() => this._submitNewErpProductCode()} />
                </Box>
              ) : null}
            </View>
          </Box>
        )}
      </Box>
    )
  }

  _renderErpOtherFee = () => {
    const { erpOtherFeeIndex, erpChannelDetail } = this.state

    let renderVat = (
      <VStack>
        <XText>{`"ค่าอื่นๆ" ในแต่ละออเดอร์ของ XSelly เป็นยอดที่...`}</XText>
        <Segment
          options={['รวม VAT แล้ว', 'VAT 0%', 'ไม่มี VAT']}
          selectedIndex={erpOtherFeeIndex}
          onSegmentChange={(newIndex) => {
            this._vatChange(newIndex, 'erp_other_fee_vat_type')
          }}
        />
      </VStack>
    )
    if (_.isNil(erpChannelDetail)) {
      renderVat = null
    }
    if (_.has(erpChannelDetail, 'is_vat') && !erpChannelDetail.is_vat) {
      renderVat = null
    }
    return (
      <XCard p='2' mt='2'>
        <VStack pb='2' w='full'>
          <HStack alignItems='center' w='full'>
            <XText bold>ค่าอื่นๆ</XText>
            <XFaqModal
              key='ค่าอื่นๆ'
              headerTitle='ค่าอื่นๆ'
              // @ts-ignore
              FAQ={FAQ_CONSTANTS.FAQ_ERP_OTHER}
              initiateOpenIndex={[0]}
              renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
            />
          </HStack>
          {renderVat}
          {this._renderInputOtherFee()}
        </VStack>
      </XCard>
    )
  }

  _renderInputOtherFee = () => {
    const { erpOtherFeeText, mode, isEditErpOtherFee, erpBeforeOtherFeeText } = this.state
    const isViewMode = mode === 'VIEW'
    const name = erpOtherFeeText === '' ? 'ยังไม่ได้ระบุ' : erpOtherFeeText
    return (
      <Box mt='2'>
        {/* {VIEW_HEIGHT_TEN} */}
        <HStack alignItems='center' w='full'>
          <XText variant={isViewMode ? 'inactive' : 'active'}>รหัสบัญชีสำหรับค่าอื่นๆ</XText>
          <XFaqModal
            key='รหัสบัญชีสำหรับค่าอื่นๆ'
            headerTitle='รหัสบัญชีสำหรับค่าอื่นๆ'
            // @ts-ignore
            FAQ={FAQ_CONSTANTS.FAQ_ERP_PAYMENT_ACCOUNT_OTHER}
            initiateOpenIndex={[0]}
            renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
          />
        </HStack>

        {isViewMode && !isEditErpOtherFee ? (
          <Box w='full' style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Box style={{ flex: 1, alignItems: 'flex-start' }}>
              <XText variant='active' bold fontSize='lg' numberOfLines={1}>
                {name}
              </XText>
            </Box>
            {VIEW_WIDTH_TEN}
            <Box
              style={{
                // width: 80,
                // height: STYLES.BTN_HEIGHT_SMALL,
                // alignItems: 'center',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}>
              <XIconButton
                variant='outline'
                name='edit'
                family='FontAwesome5'
                onPress={() => {
                  this.setState({
                    isEditErpOtherFee: true,
                    erpBeforeOtherFeeText: erpOtherFeeText,
                  })
                  // util.setStatePromise(this, { isEditErpName: true, erpNameBeforeEdit: erpName })
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box w='full'>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <XInput
                onChangeText={(txt: string) => {
                  if (PATTERN.test(txt)) {
                    this.setState({
                      erpOtherFeeText: txt,
                    })
                  }
                }}
                value={erpOtherFeeText}
                placeholder='ระบุเลขที่สินค้าสำหรับค่าอื่นๆ'
                maxLength={20}
                style={{ height: 30, flex: 1 }}
              />

              {isEditErpOtherFee ? (
                <Box style={{ flexDirection: 'row' }}>
                  <Box w='2' />
                  <XIconButton
                    variant='outline'
                    name='closecircleo'
                    family='AntDesign'
                    onPress={() => {
                      util.setStatePromise(this, { isEditErpOtherFee: false, erpOtherFeeText: erpBeforeOtherFeeText })
                    }}
                  />
                  <Box w='2' />
                  <XIconButton variant='outline' name='save' family='FontAwesome5' onPress={() => this._submitNewErpOtherFee()} />
                </Box>
              ) : null}
            </View>
          </Box>
        )}
      </Box>
    )
  }

  _renderErpDiscount = () => {
    const { erpDiscountIndex, erpChannelDetail } = this.state

    let renderVat = (
      <VStack>
        <XText>{`"ส่วนลดท้ายบิล" ในแต่ละออเดอร์ของ XSelly เป็นยอดที่...`}</XText>
        <Segment
          options={['รวม VAT แล้ว', 'VAT 0%', 'ไม่มี VAT']}
          selectedIndex={erpDiscountIndex}
          onSegmentChange={(newIndex) => {
            this._vatChange(newIndex, 'discount_vat_type')
          }}
        />
      </VStack>
    )
    if (_.isNil(erpChannelDetail)) {
      renderVat = null
    }
    if (_.has(erpChannelDetail, 'is_vat') && !erpChannelDetail.is_vat) {
      renderVat = null
    }

    return (
      <XCard p='2' mt='2'>
        <VStack pb='2'>
          <XText bold>ส่วนลดท้ายบิล</XText>
          {renderVat}
          {this._renderInputErpDiscount()}
          <HStack mt='2' w='full'>
            <XText variant='inactive'>
              หมายเหตุ: การตั้งค่าส่วนลดท้ายบิลนี้จะถูกนำไปใช้ในการสร้างเอกสารเฉพาะออเดอร์ที่มีประเภทของ VAT ไม่ตรงกันสำหรับ ราคาสินค้า
              ค่าจัดส่ง ค่าอื่นๆ โดยหากทั้ง 3 ค่านี้มีประเภทของ VAT ตรงกัน ระบบจะส่งเป็น "มูลค่าส่วนลดรวม" ของ PEAK ให้ตามปกติ
            </XText>
          </HStack>
        </VStack>
      </XCard>
    )
  }

  _renderInputErpDiscount = () => {
    const { erpDiscountText, mode, isEditErpDiscount, erpBeforeDiscountText } = this.state
    const isViewMode = mode === 'VIEW'
    const name = erpDiscountText === '' ? 'ยังไม่ได้ระบุ' : erpDiscountText
    return (
      <Box mt='2'>
        {/* {VIEW_HEIGHT_TEN} */}
        <XText variant={isViewMode ? 'inactive' : 'active'}>รหัสบัญชีสำหรับส่วนลดท้ายบิล</XText>
        {isViewMode && !isEditErpDiscount ? (
          <Box w='full' style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Box style={{ flex: 1, alignItems: 'flex-start' }}>
              <XText variant='active' bold fontSize='lg' numberOfLines={1}>
                {name}
              </XText>
            </Box>
            {VIEW_WIDTH_TEN}
            <Box
              style={{
                // width: 80,
                // height: STYLES.BTN_HEIGHT_SMALL,
                // alignItems: 'center',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}>
              <XIconButton
                variant='outline'
                name='edit'
                family='FontAwesome5'
                onPress={() => {
                  this.setState({
                    isEditErpDiscount: true,
                    erpBeforeDiscountText: erpDiscountText,
                  })
                  // util.setStatePromise(this, { isEditErpName: true, erpNameBeforeEdit: erpName })
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box w='full'>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <XInput
                onChangeText={(txt: string) => {
                  if (PATTERN.test(txt)) {
                    this.setState({
                      erpDiscountText: txt,
                    })
                  }
                }}
                value={erpDiscountText}
                placeholder='ระบุเลขที่สินค้าสำหรับค่าอื่นๆ'
                maxLength={20}
                style={{ height: 30, flex: 1 }}
              />

              {isEditErpDiscount ? (
                <Box style={{ flexDirection: 'row' }}>
                  <Box w='2' />
                  <XIconButton
                    variant='outline'
                    name='closecircleo'
                    family='AntDesign'
                    onPress={() => {
                      util.setStatePromise(this, { isEditErpDiscount: false, erpDiscountText: erpBeforeDiscountText })
                    }}
                  />
                  <Box w='2' />
                  <XIconButton variant='outline' name='save' family='FontAwesome5' onPress={() => this._submitNewErpDiscount()} />
                </Box>
              ) : null}
            </View>
          </Box>
        )}
      </Box>
    )
  }

  _renderHamburgerButton = () => {
    const { hamburgerMenuOptions, mode } = this.state
    const isCreateMode = mode === CONS.MODE.CREATE
    if (isCreateMode) {
      return <Box w='8' />
    }
    // console.log('hamburgerMenuOptions => ', hamburgerMenuOptions)
    if (_.isNil(hamburgerMenuOptions) || hamburgerMenuOptions.length < 1) {
      return null
    }
    const isDisabled = false
    // const opts = [{ label: 'สลับการผูกตัวเลือกสินค้า' }]
    // console.log('_renderHamburgerButton => ')
    return (
      <VStack>
        <XDropdown
          disabled={isDisabled}
          key='Hamburger_erp'
          // ref={r => r && (this.hamburgerMenuRef = r)}
          // ref={this.hamburgerMenuRef}
          // dropdownStyle={{
          //   width: 220,
          //   height: 40 * hamburgerMenuOptions.length,
          // }}
          // @ts-ignore
          // showsVerticalScrollIndicator={false}
          // onDropdownWillShow={this._onHamburgerWillShow}
          // onDropdownWillHide={this._onHamburgerWillHide}
          renderRow={this._renderModalDropdownRow}
          onSelect={this._onHamburgerMenuSelect}
          options={hamburgerMenuOptions}>
          <DotDotDotVerticalIcon disabled={isDisabled} />
        </XDropdown>
      </VStack>
    )
  }

  _renderModalDropdownRow = (data: { label: string; isDanger?: boolean; icon?: any; onPress: () => void }): JSX.Element => {
    // log('ModalDropdown renderRow::data => ', data)
    const isDanger = _.has(data, 'isDanger') ? data.isDanger : false
    const icon = _.has(data, 'icon') ? data.icon : null
    let textColor = COLORS.TEXT_ACTIVE
    if (isDanger) {
      textColor = COLORS.FORM_ERROR
    }

    // let textPaddingLeft = -2
    // if (_.isNil(icon)) {
    //   textPaddingLeft = 10
    // }

    return (
      <Box flexDirection='row' py='2' pr='2' justifyContent='center'>
        {_.isNil(icon) ? null : (
          <XIcon
            name={icon.name}
            family={icon.family}
            style={{
              color: textColor,
            }}
          />
        )}
        <XText
          allowFontScaling={false}
          pl='1'
          pt='1'
          style={{
            fontSize: STYLES.FONT_SIZE_LARGER,
            color: textColor,
          }}>
          {data.label}
        </XText>
      </Box>
    )
  }

  _renderErpRoundAdjustmentAccountCode = () => {
    const { erpRoundAdjustmentVatType, erpChannelDetail } = this.state
    let renderVat = (
      <VStack>
        <XText>{`"ปัดเศษ" ในแต่ละออเดอร์ของ XSelly เป็นยอดที่...`}</XText>
        <Segment
          options={['รวม VAT แล้ว', 'VAT 0%', 'ไม่มี VAT']}
          selectedIndex={erpRoundAdjustmentVatType}
          onSegmentChange={(newIndex) => {
            this._vatChange(newIndex, 'erp_round_adjustment_vat_type')
          }}
        />
      </VStack>
    )
    if (_.isNil(erpChannelDetail)) {
      renderVat = null
    }
    if (_.has(erpChannelDetail, 'is_vat') && !erpChannelDetail.is_vat) {
      renderVat = null
    }
    // console.log('renderVat => ', renderVat)
    return (
      <XCard p='2' mt='2'>
        <XText bold>ปัดเศษ</XText>
        {renderVat}
        {this._renderInputErpRoundAdjustmentAccountCode()}
      </XCard>
    )
  }

  _renderInputErpRoundAdjustmentAccountCode = () => {
    const {
      erpRoundAdjustmentAccountCodeText,
      mode,
      isEditingRoundAdjustmentAccountCode,
      erpBeforeRoundAdjustmentAccountCodeText,
    } = this.state
    const isViewMode = mode === 'VIEW'
    const name = erpRoundAdjustmentAccountCodeText === '' ? 'ยังไม่ได้ระบุ' : erpRoundAdjustmentAccountCodeText
    return (
      <Box mt='2' w='full'>
        {/* {VIEW_HEIGHT_TEN} */}
        <HStack alignItems='center' w='full'>
          <XText variant={isViewMode ? 'inactive' : 'active'}>รหัสบัญชีสำหรับยอดปัดเศษ</XText>
          <XFaqModal
            key='รหัสบัญชีสำหรับยอดปัดเศษ'
            headerTitle='รหัสบัญชีสำหรับยอดปัดเศษ'
            // @ts-ignore
            FAQ={FAQ_CONSTANTS.FAQ_ERP_ROUND_ADJUSTMENT}
            initiateOpenIndex={[0]}
            renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
          />
        </HStack>
        {isViewMode && !isEditingRoundAdjustmentAccountCode ? (
          <Box w='full' style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Box style={{ flex: 1, alignItems: 'flex-start' }}>
              <XText variant='active' bold fontSize='lg' numberOfLines={1}>
                {name}
              </XText>
            </Box>
            {VIEW_WIDTH_TEN}
            <Box
              style={{
                // width: 80,
                // height: STYLES.BTN_HEIGHT_SMALL,
                // alignItems: 'center',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}>
              <XIconButton
                variant='outline'
                name='edit'
                family='FontAwesome5'
                onPress={() => {
                  this.setState({
                    isEditingRoundAdjustmentAccountCode: true,
                    erpBeforeRoundAdjustmentAccountCodeText: erpRoundAdjustmentAccountCodeText,
                  })
                  // util.setStatePromise(this, { isEditErpName: true, erpNameBeforeEdit: erpName })
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box w='full'>
            <HStack w='full'>
              <XInput
                onChangeText={(txt: string) => {
                  if (PATTERN.test(txt)) {
                    this.setState({
                      erpRoundAdjustmentAccountCodeText: txt,
                    })
                  }
                }}
                value={erpRoundAdjustmentAccountCodeText}
                placeholder='ระบุเลขที่สินค้าสำหรับค่าอื่นๆ'
                maxLength={20}
                style={{ height: 30, flex: 1 }}
              />

              {isEditingRoundAdjustmentAccountCode ? (
                <Box style={{ flexDirection: 'row' }}>
                  <Box w='2' />
                  <XIconButton
                    variant='outline'
                    name='closecircleo'
                    family='AntDesign'
                    onPress={() => {
                      util.setStatePromise(this, {
                        isEditingRoundAdjustmentAccountCode: false,
                        erpRoundAdjustmentAccountCodeText: erpBeforeRoundAdjustmentAccountCodeText,
                      })
                    }}
                  />
                  <Box w='2' />
                  <XIconButton
                    variant='outline'
                    name='save'
                    family='FontAwesome5'
                    onPress={() => this._submitNewErpRoundAdjustmentAccountCodeText()}
                  />
                </Box>
              ) : null}
            </HStack>
          </Box>
        )}
      </Box>
    )
  }

  _onHamburgerMenuSelect = async (index: number, rowData: any) => {
    // if (this.inSelectHamburger) {
    //   return
    // }
    // this.inSelectHamburger = true
    // log('ModalDropdown:: onSelect index => ', index)
    // log('_onHamburgerMenuSelect ModalDropdown:: onSelect index => ', index)
    const selectedItem = this.state.hamburgerMenuOptions[index]
    // log('ModalDropdown:: onSelect selectedItem => ', selectedItem)
    if (selectedItem && _.isFunction(selectedItem.onPress)) {
      // MUST!! delay for finished render dropdown before call method
      await new Promise((delayOnPress) => {
        setTimeout(delayOnPress, 200)
      })
      // if (!_.isNil(selectedItem.perm)) {
      //   const perm = xAcl.canDoAtSelectedStore(selectedItem.perm)
      //   if (!perm) {
      //     p.op.showConfirmationOkOnly(``, `คุณไม่ได้รับสิทธิ์ในการใช้งานฟีเจอร์นี้`)
      //     // this.inSelectHamburger = false
      //     await util.setStatePromise(this, { isHamburgerMenuOpened: false })
      //     return
      //   }
      // }
      selectedItem.onPress()
      this._afterHamburgerMenuSelectedSafetyClose()
    }
    // this.inSelectHamburger = false
    await util.setStatePromise(this, { isHamburgerMenuOpened: false })
  }

  _afterHamburgerMenuSelectedSafetyClose = () => {
    setTimeout(() => {
      if (this.state.isHamburgerMenuOpened) {
        this.setState({ isHamburgerMenuOpened: false })
      }
    }, 200)
  }

  _renderWarningIsActive = () => {
    const { erpChannelDetail } = this.state
    if (_.isNil(erpChannelDetail)) {
      return null
    }
    if (erpChannelDetail.is_active) {
      return null
    }
    const systemName = util.getErpChannelSystemName(erpChannelDetail.erp_system_id)
    const warningText = `บัญชีนี้ปิดการใช้งานกับ ${systemName} แล้ว`
    return (
      <HStack bgColor={COLORS.RED} h='16' py='2' alignItems='center' justifyContent='center' style={{ marginLeft: -10, marginRight: -10 }}>
        <XText color='white' px='2'>
          {warningText}
        </XText>
      </HStack>
    )
  }

  _renderBody = () => {
    const { erpChannelDetail } = this.state
    if (_.isNil(erpChannelDetail)) {
      return null
    }
    return (
      <XCard w='full' p='2'>
        {this._renderWarningIsActive()}

        {this._renderInputErpName()}
        {this._renderLogo()}
        {this._renderCreateAt()}
        {this._renderRegisterForVat()}
        {this._renderAccount()}
        {this._renderContactId()}
        {this._renderProductNumber()}
        {this._renderDocumentType()}
        {this._renderVat()}
        {this._renderShipping()}
        {this._renderErpOtherFee()}
        {this._renderErpDiscount()}
        {this._renderErpRoundAdjustmentAccountCode()}
      </XCard>
    )
  }

  _renderDeleteOverlay = () => {
    const { erpChannelDetail, isOverlayDeleteVisible } = this.state
    if (_.isNil(erpChannelDetail)) {
      return null
    }
    return (
      <DeleteErpChannel
        erpChannel={erpChannelDetail}
        selectedStore={this.props.selectedStore}
        isOverlayVisible={isOverlayDeleteVisible}
        onDeleted={() => this._deleteErpChannel()}
        closeOverlay={() => this.setState({ isOverlayDeleteVisible: false })}
      />
    )
  }

  _renderMain = () => {
    const {} = this.state
    return (
      <XContainer>
        {this.renderCustomHeader()}
        {this._renderDeleteOverlay()}
        <XContent w='full' p='2'>
          {this._renderBody()}
          <HStack h='2' />
        </XContent>
      </XContainer>
    )
  }

  render() {
    return this._renderMain()
  }
}
