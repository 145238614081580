import React from 'react'

import _ from 'lodash'
// import { COLORS, STYLES } from '../../config/styles'
import { BaseStoreSettingStickerProps, BaseStoreSettingStickerState } from 'x/index'
import { COLORS } from '../../config/styles'
import p from '../../config/platform-specific'
import * as util from '../../utils/util'

const {
  BG_LIGHT_GREY_ALTERNATIVE,
  TEXT_PRIMARY,
  TEXT_ACTIVE,
  TEXT_INACTIVE,
  APP_MAIN,
  APP_SECONDARY,
  FORM_SUCCESS,
  FORM_ERROR,
  TEXT_ACTIVE_DARK,
  BRAND_Info,
  BRAND_Danger,
  BRAND_Primary,
  BRAND_Success,
  BRAND_Warning,
} = COLORS

const checkBox = 'checkBox'
const defaultPrintingTypeId = 1001501 // Must match the value from Laravel DEFAULT_PRINTING_SHIPPING_LABEL_STICKER.p.i

const defaultPaperrangType = [
  {
    id: 801001,
    url: 'https://xsf.sgp1.cdn.digitaloceanspaces.com/x/samples/sl/801001.pdf',
    txt: `กว้าง 80mm x ยาว 100mm`,
  },
  {
    id: 1001001,
    url: 'https://xsf.sgp1.cdn.digitaloceanspaces.com/x/samples/sl/1001001.pdf',
    txt: `กว้าง 100mm x ยาว 100mm`,
  },
  {
    id: 1001501,
    url: 'https://xsf.sgp1.cdn.digitaloceanspaces.com/x/samples/sl/1001501.pdf',
    txt: `กว้าง 100mm x ยาว 150mm`,
  },
]

export default abstract class BaseStoreSettingSticker extends React.Component<BaseStoreSettingStickerProps, BaseStoreSettingStickerState> {
  inProcess: boolean

  OPTIONS_LIST_COVER_SHEET: any[]

  PAPERRANG_TYPE: { id: number; url: string; txt: string }[]

  abstract _goBack(): any

  protected constructor(props) {
    super(props)

    this.state = {
      printSetting: null,
      isActive: false,
      showSenderNameAndAddress: true, // s
      showProductListWhenNoShippingLabelImage: true, // p
      // autoSaveSticker: false,
      printingTypeId: defaultPrintingTypeId,
      showProductListWhenHasShippingLabelImage: false, // q
    }

    this.inProcess = false
    this.OPTIONS_LIST_COVER_SHEET = [
      { type: checkBox, text: 'ชื่อและที่อยู่ผู้ส่ง', value: false, key: 0 },
      { type: checkBox, text: 'รายการสินค้า', value: false, key: 1 },
    ]
    this.PAPERRANG_TYPE = defaultPaperrangType
    // this._goBack = this._goBack.bind(this)
    // this._onChangeDateRange = this._onChangeDateRange.bind(this)
    // this._isVisibleDateRangePicker = this._isVisibleDateRangePicker.bind(this)
    // this._downloadReportRequisitionUrl = this._downloadReportRequisitionUrl.bind(this)
    // this._computeAllowDays = this._computeAllowDays.bind(this)
    // this._getTxtDateRangeDescription = this._getTxtDateRangeDescription.bind(this)
  }

  async componentDidMount() {
    const { selectedStore } = this.props
    const sticker = selectedStore.get('printing_sticker_pdf')
    await this.config(sticker)
  }

  // isTrueOr1 = (testParam) => {
  //   return testParam === true || testParam === 1
  // }

  config = async (printSetting) => {
    if (_.isNil(printSetting)) {
      p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'กรุณาติดต่อทีมงาน Xselly')
      this._goBack()
      return
    }
    const sticker = printSetting.toJS()
    // console.log(`sticker `, sticker)
    const isActive = sticker.a
    // const showSenderNameAndAddress = sticker.p.s === 0 ? false : true // Wrong check as it will be TRUE if null
    const showSenderNameAndAddress = util.isTrueOr1(sticker.p.s) // Wrong check as it will be TRUE if null
    // const showProductListWhenNoShippingLabelImage = sticker.p.p === 0 ? false : true // Wrong check as it will be TRUE if null
    const showProductListWhenNoShippingLabelImage = util.isTrueOr1(sticker.p.p) // Wrong check as it will be TRUE if null
    // const showProductListWhenHasShippingLabelImage = sticker.p.q === 0 ? false : true // Wrong check as it will be TRUE if null
    const showProductListWhenHasShippingLabelImage = util.isTrueOr1(sticker.p.q)
    // let autoSaveSticker = sticker.p.i === 0 ? false : true
    const printingTypeId = defaultPrintingTypeId

    this.props.navigation.setParams({
      cancelBtn: () => this.cancelButton(),
      onChange: false,
      saveBtn: () => this.saveButton(),
    })

    util.setStatePromise(this, {
      isActive,
      showSenderNameAndAddress,
      showProductListWhenNoShippingLabelImage,
      printSetting: sticker,
      printingTypeId,
      showProductListWhenHasShippingLabelImage,
    })
  }

  onChangeValue = (key, value) => {
    this.props.navigation.setParams({
      onChange: true,
    })
    util.setStatePromise(this, {
      [key]: value,
    })
  }

  onChangeSelectedValue = (paperangType: { id: number; url: string; txt: string }) => {
    const { printSetting } = this.state
    const newPaperangData = printSetting
    newPaperangData.p.i = paperangType.id
    util.setStatePromise(this, {
      paperangData: newPaperangData,
      printingTypeId: paperangType.id,
    })
    this.props.navigation.setParams({
      onChange: true,
    })
  }

  cancelButton = () => {
    // const { navigation } = this.props
    // const { state } = navigation
    // const { onChange } = state.params
    const onChange = util.getNavParam(this.props, 'onChange')
    if (onChange) {
      p.op.showConfirmation(
        'ยกเลิกการแก้ไข',
        'ยกเลิกการแก้ไขใช่หรือไม่',
        () => this._goBack(),
        () => {},
        'ใช่',
        'แก้ไขต่อ'
      )
    } else {
      this._goBack()
    }
  }

  saveButton = () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { addPrinting, selectedStore, editPrinting } = this.props
    // log('BaseStoreSettingPaperang', this.state)
    const {
      showSenderNameAndAddress,
      isActive,
      showProductListWhenNoShippingLabelImage,
      printSetting,
      printingTypeId,
      showProductListWhenHasShippingLabelImage,
    } = this.state
    const store_id = selectedStore.get('id')

    const isActiveA4 = selectedStore.has('s_use_printing_pdf') ? selectedStore.get('s_use_printing_pdf') : true
    const isActivePaperang = selectedStore.has('s_use_printing_paperang') ? selectedStore.get('s_use_printing_paperang') : true

    if (!isActiveA4 && !isActivePaperang && !isActive) {
      p.op.showConfirmationOkOnly(
        p.op.t('printing.warning.WARNING_CANT_CLOSS_LAST_PRINTING.title'),
        p.op.t('printing.warning.WARNING_CANT_CLOSS_LAST_PRINTING.message')
      )
      return
    }

    const payload = {
      body: {
        store_id,
        printing_type_id: 1,
        size: 3,
        is_active: isActive,
        pref: {
          s: util.isTrueOr1(showSenderNameAndAddress) ? 1 : 0,
          p: util.isTrueOr1(showProductListWhenNoShippingLabelImage) ? 1 : 0,
          l: 'th',
          i: printingTypeId,
          q: util.isTrueOr1(showProductListWhenHasShippingLabelImage) ? 1 : 0,
        },
      },
      successCallback: (res) => this.successCallback(res),
      failedCallback: (err) => this.failedCallback(),
    }
    if (_.isNil(printSetting.id)) {
      addPrinting(payload)
    } else {
      // @ts-ignore
      payload.body.printing_id = printSetting.id
      editPrinting(payload)
    }
  }

  successCallback = (res) => {
    this.props.navigation.setParams({
      onChange: false,
    })
    const printing = res.store_printing
    util.setStatePromise(this, {
      printSetting: printing,
      isActive: printing.a,
      showSenderNameAndAddress: printing.p.s,
      showProductListWhenNoShippingLabelImage: printing.p.p,
      showProductListWhenHasShippingLabelImage: printing.p.q,
      printingTypeId: printing.p.i,
    })
    p.op.showToast('บันทึกข้อมูลเรียบร้อยแล้ว', 'success')
    this.inProcess = false
  }

  // TODO for Artid: Should take error as param and display the error message
  failedCallback = () => {
    p.op.showToast('เกิดข้อผิดพลาดกรุณาทำรายการใหม่ภายหลัง', 'warning')
    this.props.navigation.setParams({
      onChange: false,
    })
    this.inProcess = false
  }
}
