/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react'
import { canDoAtSelectedStore } from 'x/utils/acl'
import CONS from 'x/config/constants'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import {
  ISelectedStoreMap,
  UserGroupMap,
  UserInUserGroups,
  IImportExcelMenuItem,
  UserGroup,
  // IBackgroungJob,
  IXScreenProps,
} from 'x/index'
import { List } from 'immutable'
import * as NavActions from 'x/utils/navigation'
import { COLORS } from 'x/config/styles'

interface IBaseResellerManageViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  selectedUserGroups: List<UserGroupMap>
  kickUser?: (ActionApiParams) => void
}

interface IBaseResellerManageViewState {
  reseller: UserInUserGroups
  userGroup: UserGroup
}

export default abstract class BaseResellerManageView extends React.Component<IBaseResellerManageViewProps, IBaseResellerManageViewState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  MENU_ITRMS: IImportExcelMenuItem[]

  constructor(props) {
    super(props)
    this.state = {
      reseller: null,
      userGroup: null,
    }
    this.MENU_ITRMS = [
      {
        label: 'รายงาน',
        isHeader: true,
        isShowMenu: () => true,
      },
      {
        label: 'การขายสินค้ารายวัน',
        iconName: 'calendar',
        iconFamily: 'FontAwesome',
        onclick: () => this._navToReportProductDailySales(),
        isShowMenu: () => true,
      },
      /// ///
      {
        label: 'จัดการสมาชิก',
        isHeader: true,
        isShowMenu: () => true,
      },
      {
        label: 'ย้ายสมาชิก',
        iconName: 'retweet',
        iconFamily: 'AntDesign',
        onclick: () => this._handleOnChangeUserGroup(),
        isShowMenu: () => true,
      },
      {
        label: 'ไล่ออกจากร้าน',
        labelStyle: { color: COLORS.BRAND_Danger },
        iconStyle: { color: COLORS.BRAND_Danger },
        iconName: 'exit-run',
        iconFamily: 'MaterialCommunityIcons',
        onclick: () => this._handleOnKickUserFromStore(),
        isShowMenu: () => true,
      },
    ]
  }

  async componentDidMount() {
    // Destructure props and params from the component's props and navigation objects
    const { navigation, selectedUserGroups } = this.props
    // const { state } = navigation
    // const { params } = state
    const params = util.getNavParams(this.props)
    const { ug_id, selectedReseller } = params

    // Convert selectedUserGroups to a plain JavaScript object or null if it is undefined or null
    const selectedUserGroupsJS = !_.isNil(selectedUserGroups) ? selectedUserGroups.toJS() : null
    // Find the user group in selectedUserGroupsJS that has an id matching the value of ug_id
    const userGroup = selectedUserGroupsJS.find((ug) => ug.id === ug_id)
    // Set the component's state with the reseller and userGroup values
    await util.setStatePromise(this, {
      reseller: selectedReseller,
      userGroup,
    })
  }

  _handleOnChangeUserGroup = (): void => {
    if (!canDoAtSelectedStore(CONS.PERM_STORE_HELPER.MEMBER_EDIT)) {
      p.op.alert('คุณไม่มีสิทธิ์ในการแก้ไขสมาชิกในกลุ่ม')
      return
    }
    const { userGroup, reseller } = this.state
    const store_id = this.props.selectedStore.get('id')
    if (_.isNil(userGroup) || _.isNil(reseller) || _.isNil(store_id)) {
      return
    }
    // @ts-ignore
    this.props.navigation.dispatch(
      NavActions.navToResellerChangeGroupView({
        store_id,
        ug_id: userGroup.id,
        selectedReseller: reseller, // โดน navigation แปลงเป็น .toJS() เอง
        callBack: () => util.navGoBack(this.props),
      })
    )
  }

  _handleOnKickUserFromStore = async (): Promise<void> => {
    if (!canDoAtSelectedStore(CONS.PERM_STORE_HELPER.MEMBER_DELETE)) {
      p.op.alert('คุณไม่มีสิทธิ์ในการลบสมาชิกออกจากกลุ่ม')
      return
    }
    const { selectedStore, kickUser } = this.props
    const { reseller } = this.state
    const profile_id = reseller.i
    const displayName = reseller.d
    if (!profile_id || !displayName) {
      // this._inProcess = false
      return
    }
    const isUserConfirmed = await new Promise((passConfirm) => {
      p.op.showConfirmation(
        'ไล่ตัวแทนออกจากร้าน',
        `กรุณายืนยันว่าคุณต้องการไล่ตัวแทน "${displayName}" ออกจากร้านฉันใช่หรือไม่` +
          '\n\nหากดำเนินการผู้ใช้คนนี้จะไม่เห็นสิ้นค้าจากร้านฉันอีกต่อไป แต่สินค้าที่ถูกสั่งออเดอร์ไปแล้วจะสามารถดำเนินการได้ตามปกติ',
        () => passConfirm(true),
        () => passConfirm(false)
      )
    })
    if (!isUserConfirmed) {
      // this._inProcess = false
      return
    }

    const body = {
      store_id: selectedStore.get('id'),
      profile_id,
    }
    const isUserKicked = await new Promise((isKick) => {
      kickUser({
        body,
        successCallback: () => isKick(true),
        failedCallback: () => isKick(false),
      })
    })
    const txtSummary = `ดำเนินการไล่สมาชิก "${displayName}" ออกจากร้าน`
    if (isUserKicked) {
      p.op.showToast(`${txtSummary}สำเร็จ`, 'success')
      util.navGoBack(this.props)
    } else {
      p.op.showToast(`${txtSummary}ล้มเหลว`, 'success')
    }
    // this._inProcess = false
  }

  _navToReportProductDailySales = () => {
    const { selectedStore, navigation } = this.props
    const { reseller, userGroup } = this.state
    const userGroupName = userGroup.name ? userGroup.name : null
    const store_id = selectedStore.get(`id`)
    navigation.dispatch(NavActions.navToReportProductDailySalesView({ store_id, reseller, userGroupName }))
  }
}
