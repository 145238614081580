import React from 'react'

// import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import BaseERPListView from 'x/modules/erp/BaseERPListView'
import XCustomHeader from 'xui/components/XCustomHeader'
// import { Input } from 'react-native-elements'
import XIcon from 'xui/components/XIcon'
import p from 'x/config/platform-specific'
import HelpButton from 'xui/components/HelpButton'
import HStack from 'xui/components/HStack'
import XButton from 'xui/components/XButton'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import VStack from 'xui/components/VStack'
import XText from 'xui/components/XText'
import _ from 'lodash'
import Box from 'xui/components/Box'
import { TouchableOpacity } from 'react-native'
import { COLORS, STYLES } from 'x/config/styles'
import XCard from 'xui/components/XCard'
import * as NavActions from 'x/utils/navigation'
import { IERPChannel } from 'x/index'
import * as acl from 'x/utils/acl'
import CONS from 'x/config/constants'
import ErpLogo from 'xui/components/ErpLogo'
import XFaqModal from 'xui/components/XFaqModal'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'

// const VIEW_HEIGHT_TEN = <View style={{ height: 10 }} />

export default abstract class BaseUIERPListView extends BaseERPListView {
  _renderHeaderRight = () => {
    // const { isRenderWebView } = this.state
    // if (isRenderWebView && !p.op.isWeb()) {
    //   return null
    // }
    if (!acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ERP_CHANNEL_ADD)) {
      return null
    }
    const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />
    // return <XButton variant='ghost' leftIcon={<XIcon family='AntDesign' name='plus' />} onPress={() => this._testABC()} />
    return (
      <HStack>
        <XFaqModal
          key='XHelpModal'
          headerTitle='เชื่อมต่อระบบ PEAK'
          FAQ={FAQ_CONSTANTS.FAQ_PEAK_IN_LIST_VIEW}
          initiateOpenIndex={0}
          renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
        />
        <XButton variant='ghost' leftIcon={<XIcon family='AntDesign' name='plus' />} onPress={() => this._navToErpCreateView()} />
      </HStack>
    )
  }

  renderCustomHeader = () => {
    const isGoBackButtonHidden = util.getNavParam(this.props, 'isGoBackButtonHidden', false)
    // const { isRenderWebView } = this.state
    return (
      <XCustomHeader
        title='การเชื่อมต่อระบบบัญชี'
        headerLeftProps={{ backIcon: p.op.isWeb() ? null : true, onPressItem: () => this.goBack() }}
        headerRight={this._renderHeaderRight()}
      />
    )
  }

  _renderHeaderCard = (erpChannel: IERPChannel) => {
    // console.log(`channel => `, channel)
    const ICON_SIZE = STYLES.MKP_ICON_SIZE_NORMAL
    const isHasConnection = true
    const canEditErp = acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ERP_CHANNEL_EDIT)
    return (
      <Box flexDirection='row' justifyContent='center' alignItems='center' px='2' w='full'>
        {/* VIEW IMG */}
        <Box>
          {isHasConnection ? (
            <ErpLogo erpSystemId={erpChannel.erp_system_id} width={ICON_SIZE} height={ICON_SIZE} />
          ) : (
            <Box w='10' h='10' />
          )}
        </Box>
        {/* VIEW TEXT  */}
        <Box flex={1} justifyContent='center' pl='2'>
          <XText fontSize='md' bold variant='active' numberOfLines={1}>
            {erpChannel.name}
          </XText>
          {/* <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>{``}</Text> */}
        </Box>
        {/* // TouchableOpacity SETTING ICON */}
        {/* <TouchableOpacity
          onPress={() => null}
          style={{ width: 40, height: 50, alignItems: 'center', justifyContent: 'center', paddingBottom: 0, marginRight: -8 }}>
          <XIcon family='FontAwesome' name='shopping-cart' style={{ flex: 0, color: COLORS.APP_MAIN }} />
        </TouchableOpacity>
        <View style={{ width: 10 }} /> */}
        {!canEditErp ? null : (
          <TouchableOpacity
            onPress={() => this._navToErpDetailView(erpChannel.id)}
            style={{ width: 40, height: 50, alignItems: 'flex-end', justifyContent: 'center', paddingBottom: 0, marginRight: -8 }}>
            <XIcon family='Ionicons' name='settings' style={{ flex: 0, color: COLORS.APP_MAIN }} />
          </TouchableOpacity>
        )}
      </Box>
    )
  }

  _navToERPSubmitDocumentView = (erpChannel: IERPChannel) => {
    const { navigation, selectedStore } = this.props
    navigation.dispatch(
      NavActions.navToERPSubmitDocumentView({
        store_id: selectedStore.get('id'),
        erpChannel,
      })
    )
  }

  _renderBody = (erpChannel: IERPChannel) => {
    const systemName = util.getErpChannelSystemName(erpChannel.erp_system_id)
    let textActive = `บัญชีนี้ปิดการใช้งานกับ ${systemName} แล้ว`
    const isActive = erpChannel.is_active
    if (isActive) {
      textActive = `เชื่อมต่อกับ ${systemName} แล้ว`
    }
    return (
      <VStack>
        <HStack flex={1} justifyContent='flex-end' alignItems='center'>
          <XText bold>{erpChannel.name}</XText>
        </HStack>
        <HStack flex={1} justifyContent='flex-end' alignItems='center'>
          <XText bold color={isActive ? COLORS.GREEN : COLORS.RED}>
            {textActive}
          </XText>
        </HStack>
      </VStack>
    )
  }

  _renderFooterBtn = (erpChannel: IERPChannel) => {
    const isActive = erpChannel.is_active
    if (!isActive) {
      return null
    }
    if (!acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ERP_DOC_CREATE)) {
      return null
    }
    return (
      <HStack flex={1} mt='2' alignItems='center' justifyContent='center'>
        {/* <XButton>
          <XIcon name='download' family='AntDesign' color={COLORS.WHITE} />
          <XText color={COLORS.WHITE}>ดึงเอกสาร</XText>
        </XButton> */}
        <HStack w='8' />
        <XButton h='10' onPress={() => this._navToERPSubmitDocumentView(erpChannel)}>
          <XIcon name='upload' family='AntDesign' color={COLORS.WHITE} />
          <XText color={COLORS.WHITE}>สร้างเอกสาร</XText>
        </XButton>
      </HStack>
    )
  }

  _renderItemCrad = (erpChannel: IERPChannel, index: number) => {
    const {} = this.state
    return (
      <VStack key={`ItemCrad_${index}`}>
        <XCard p='2' m='2'>
          {this._renderHeaderCard(erpChannel)}
          {this._renderBody(erpChannel)}
          {this._renderFooterBtn(erpChannel)}
        </XCard>
      </VStack>
    )
  }

  _renderErpList = () => {
    const { erpChannelList } = this.state
    const isHelperUser = util.isHelper()
    const havePermListErp = acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ERP_CHANNEL_LIST)
    if (isHelperUser && !havePermListErp) {
      return (
        <VStack w='full' alignItems='center' justifyContent='center' mt='2'>
          {/* <XText>ไม่พบรายการบัญชี หากยังไม่เคยสร้าง</XText> */}
          <XText variant='inactive' textAlign='center'>
            คุณไม่ได้รับสิทธิ์ในการใช้งาน
          </XText>
        </VStack>
      )
    }
    // console.log('erpChannelList => ', erpChannelList)
    if (_.isNil(erpChannelList) || erpChannelList.length === 0) {
      return (
        <VStack w='full' alignItems='center' justifyContent='center' mt='2'>
          {/* <XText>ไม่พบรายการบัญชี หากยังไม่เคยสร้าง</XText> */}
          <XText
            variant='inactive'
            textAlign='center'>{`คุณยังไม่มีระบบบัญชี หากยังต้องการสร้าง\nกรุณากดปุ่ม '+' มุมขวาบน เพื่อสร้างระบบบัญชี `}</XText>
        </VStack>
      )
    }
    return <VStack>{erpChannelList.map((erp, idx) => this._renderItemCrad(erp, idx))}</VStack>
  }

  _renderMain = () => {
    const { erpChannelList } = this.state
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent>{this._renderErpList()}</XContent>
      </XContainer>
    )
  }

  render() {
    return this._renderMain()
  }
}
