import React from 'react'
import { TouchableOpacity, View, ScrollView, Linking } from 'react-native'

import _ from 'lodash'

import { COLORS } from 'x/config/styles'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import Segment from 'xui/components/Segment'
import BaseReportHelperSales from 'x/components/report/BaseReportHelperSales'
// import * as utilN from '../../utils/util-native'
import { IMemberHelperList, IChannel, IProfile } from 'x/index'
import { Center } from 'native-base'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import * as util from 'x/utils/util'
import XDateRangePicker from 'xui/components/XDateRangePicker'
import XIcon from 'xui/components/XIcon'
import XButton from 'xui/components/XButton'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'

// @ts-ignore
export default class BaseUIReportHelperSales extends BaseReportHelperSales {
  _renderNoVariantsOrOneVariants = () => <View style={{ paddingTop: 8 }} />

  // _handleOnDownloadFile = async (url: string, fileName: string): Promise<void> => {
  //   // await utilN.openFileFromUrl(url, fileName, 'xlsx')
  //   this.props.handleOnDownloadFile(url, fileName, 'xlsx')
  // }

  _renderNoHelper = () => (
    <Center flex={1} pt='2' pb='2'>
      <XText variant='inactive' fontSize='xs'>
        ไม่พบผู้ช่วย
      </XText>
    </Center>
  )

  _renderHeader = () => (
    <HStack alignItems='center' justifyContent='space-between'>
      <XText bold variant='active'>
        รายงาน ยอดขายผู้ใช้งานและผู้ช่วย
      </XText>
      <XButton
        onPress={() => Linking.openURL('https://xsf.sgp1.cdn.digitaloceanspaces.com/x/samples/rp/HelperSales.xlsx')}
        h='7'
        p='0'
        variant='outline'>
        <XText color={COLORS.APP_MAIN} fontSize='xs' p='1'>
          ดูตัวอย่าง
        </XText>
      </XButton>
    </HStack>
  )

  _renderCreateAt = () => {
    const { selectedOptSegment } = this.state
    return (
      <Box>
        <HStack alignItems='center'>
          <XText variant='active' fontSize='xs'>
            ดูออเดอร์ที่...
          </XText>
        </HStack>
        <HStack alignItems='center'>
          <Segment
            onSegmentChange={(newIndex) => this._onChangeSegmentBaseReportPayment(newIndex)}
            options={this.DATE_RANGE_REPORT_PAYMENT}
            selectedIndex={selectedOptSegment}
          />
        </HStack>
      </Box>
    )
  }

  _renderDateRangePicker = () => {
    const { subscription } = this.props
    const r_shpg_day = subscription.get('r_shpg_day')
    const { selectedDateRange, warningDateRangeText } = this.state
    let limitDay = r_shpg_day
    if (r_shpg_day > 31) {
      limitDay = 31
    }
    return (
      <Box mt='2' minH='24'>
        <XDateRangePicker
          oneLineDateTime={false}
          allowOnlyDaysFromToday={r_shpg_day}
          allowDateRangeLimitDays={limitDay}
          selectedDates={selectedDateRange}
          onChangeDate={this._onChangeDateRange}
          showTime
        />
        <XText variant='primary' fontSize='xs' textAlign='center'>
          {warningDateRangeText}
        </XText>
      </Box>
    )
  }

  _renderSalesChannel = () => {
    const { selectedChannelOtp, channelData } = this.state
    const channelName = _.isNil(channelData) ? 'กรุณาระบุช่องทางการขาย' : p.op.t(`Order.channelItems.${channelData.key}`)
    return (
      <Box>
        <HStack alignItems='center' mt='2'>
          <Box w='20'>
            <XText variant='active'>ช่องการขาย</XText>
          </Box>
          <Box flex={1}>
            <Segment
              onSegmentChange={(newIndex) => this._showSeletedChannel(newIndex)}
              options={this.CHANNEL_OTP}
              selectedIndex={selectedChannelOtp}
            />
          </Box>
        </HStack>
        {selectedChannelOtp === 1 ? (
          <HStack>
            <Box w='20' />
            <XButton
              onPress={() => {
                this._showSeletedChannel(1)
              }}
              variant='outline'
              pt='1'
              pb='1'
              size='sm'
              mt='1'
              flex={1}
              endIcon={<XIcon family='AntDesign' name='arrowright' size='xs' />}>
              {channelName}
            </XButton>
          </HStack>
        ) : null}
      </Box>
    )
  }

  _renderCreateAtBy = () => {
    const { viewReportIndex, visibleOnlyProfileId } = this.state
    // @ts-ignore
    const profile: IProfile = util.getProfile()
    let name = null
    let lastName = null
    if (!_.isNil(profile)) {
      name = profile.first_name
      lastName = profile.last_name
    }
    return (
      <Box mt='2'>
        <HStack alignItems='center'>
          <XText variant='active' fontSize='xs'>
            สร้างออเดอร์โดย...
          </XText>
        </HStack>
        {visibleOnlyProfileId === true ? (
          <Center alignItems='center'>
            <XText variant='active' fontSize='sm' pl='2'>
              {`${name} ${lastName}`}
            </XText>
          </Center>
        ) : (
          <HStack alignItems='center'>
            <Segment
              onSegmentChange={(newIndex) => this._onChangeSegmentViewReportFrom(newIndex)}
              options={this.VIEW_REPORT_FROM}
              selectedIndex={viewReportIndex}
            />
          </HStack>
        )}
      </Box>
    )
  }

  _renderHelperList = () => {
    const { hlepers, loading, viewReportIndex } = this.state
    const hasHelper = !_.isNil(hlepers) && hlepers.length > 0
    // ถ้าผู้ช่วยไม่ถูกเลือก ไม่ต้องแสดงผล
    if (viewReportIndex !== 1) {
      return null
    }
    if (hasHelper && !loading) {
      return (
        <Box p='2' mt='2' h='40' borderRadius='sm' borderWidth='1px' borderColor='primary.500'>
          <ScrollView>
            {hlepers.map((data: IMemberHelperList, index: number) => {
              const select = _.isNil(data.seleted) || !data.seleted ? false : data.seleted
              // const select = true
              if (select) {
                return (
                  <HStack py='2' alignItems='center'>
                    <Box w='8'>
                      <XIcon family='AntDesign' name='caretright' />
                    </Box>
                    <XButton variant='solid' colorScheme='primary' flex={1} mr='1' onPress={() => this._onSeleted(data)}>
                      {data.d}
                    </XButton>
                  </HStack>
                )
              }
              return (
                <HStack py='2' alignItems='center'>
                  <Box w='8' />
                  <XButton variant='outline' flex={1} mr='1' onPress={() => this._onSeleted(data)}>
                    {data.d}
                  </XButton>
                </HStack>
              )
            })}
          </ScrollView>
        </Box>
      )
    }
    return this._renderNoHelper()
  }

  _renderFooter = () => (
    // const { onRequestClose } = this.props
    // const { downloadingReport } = this.state
    <HStack w='full' p='1'>
      <XButton
        w='full'
        py='2'
        alignItems='center'
        justifyContent='center'
        onPress={() => {
          this._downloadHistoryReport()
        }}>
        <XIcon family='FontAwesome' name='file-excel-o' size='xs' />
        <XText mt='2' color={COLORS.WHITE}>
          ดาวน์โหลดรายงาน
        </XText>
      </XButton>
    </HStack>
  )
  // return (
  //   <HStack alignItems='center' mt='3' space='1' w='full'>
  //     <XButton
  //       w='64'
  //       leftIcon={<XIcon family='FontAwesome' name='file-excel-o' size='xs' />}
  //       isLoading={downloadingReport}
  //       onPress={() => {
  //         this._downloadHistoryReport()
  //       }}>
  //       ดาวน์โหลดรายงาน
  //     </XButton>
  //     <XButton variant='outline' w='10' onPress={_.isFunction(onRequestClose) ? onRequestClose() : null}>
  //       ปิด
  //     </XButton>
  //   </HStack>
  // )

  _renderSellChannel = () => {
    const { showSeletedChannel } = this.state
    if (!showSeletedChannel) {
      return null
    }
    return (
      <Box
        borderRadius='xl'
        _light={{ bg: 'white' }}
        _dark={{ bg: 'gray.800' }}
        style={{
          flex: 1,
          position: 'absolute',
          top: 0,
          left: 0,
          // backgroundColor: COLORS.WHITE,
          width: 318,
          height: 450,
          zIndex: 999,
        }}>
        <HStack alignItems='center' py='1' mt='2'>
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              width: 50,
              paddingLeft: 8,
            }}
            onPress={() => {
              this._hideSeletedChannel()
            }}>
            <XIcon family='AntDesign' name='arrowleft' />
          </TouchableOpacity>
          <Box flex={1} mr='2'>
            <XText textAlign='center'>เลือกช่องทางการชำระ</XText>
          </Box>
        </HStack>
        {/* // BOX List Item */}
        <Box borderRadius='xl' p='2' h='330px' mt='2' m='2' borderWidth='1px' borderColor='primary.500'>
          <ScrollView>
            {CONS.ORDER_VIEW_CHANNEL_ITEMS.map((channel: IChannel) => (
              <TouchableOpacity
                onPress={() => this._onChangeChannel(channel)}
                key={channel.index}
                style={{
                  borderBottomColor: COLORS.TEXT_INACTIVE,
                  borderBottomWidth: 1,
                  paddingBottom: 8,
                  paddingTop: 8,
                }}>
                <HStack alignItems='center'>
                  <Box w='10'>
                    <XIcon family='AntDesign' name='arrowright' />
                  </Box>
                  <XText variant='active'>{p.op.t(`Order.channelItems.${channel.key}`)}</XText>
                </HStack>
              </TouchableOpacity>
            ))}
          </ScrollView>
        </Box>
      </Box>
    )
  }

  render() {
    return (
      <XContainer>
        <XCustomHeader
          title='ยอดขายผู้ใช้งานและผู้ช่วย'
          // @ts-ignore
          headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(this.props) }}
        />
        <XContent p='2'>
          <XCard p='2'>
            {this._renderHeader()}
            {this._renderCreateAt()}
            {this._renderDateRangePicker()}
            {this._renderSalesChannel()}
            {this._renderCreateAtBy()}
            {this._renderHelperList()}
            {/* {this._renderFooter()} */}
            {this._renderSellChannel()}
          </XCard>
        </XContent>
        {this._renderFooter()}
      </XContainer>
    )
  }
}
