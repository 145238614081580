// import { isImmutable, Map, List, fromJS } from 'immutable'
import { Map } from 'immutable'

import { ActionApiParams } from 'x/index'
import actions from 'x/config/actions'
// import CONS from 'x/config/constants'
// import moment from 'moment'

// Initial state
// interface in x/index :: ILoadingInProgress
const initialState = Map({
  warningReAuthMKP: null,
})

// Actions
// export const ACTION_INITIALIZE = 'AddressState/INITIALIZE'

export function setWarningReAuthMKPState(payload) {
  // console.log('setWarningReAuthMKPState => ', payload)
  // const newDate = new Date()
  // const DateTimeNow = moment(newDate).format(CONS.SERVER_DATETIME_FORMAT)
  return { type: actions.SET_MARKETPLACE_CHANNEL, payload }
}

// export function unSetLoadingChannels() {
//   return { type: actions.UN_SET_LOADING_CHANNEL }
// }

// Reducer
export default function MarketplaceChannelStateReducer(state = initialState, action: ActionApiParams = {}) {
  const { type, payload } = action
  const newState = state

  switch (type) {
    case actions.SET_MARKETPLACE_CHANNEL:
      // console.log('payload => ', payload)
      return newState.set('warningReAuthMKP', payload)

    // case actions.UN_SET_LOADING_CHANNEL:
    //   return newState.delete('channels')

    default:
      return state
  }
}
