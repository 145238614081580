/* eslint-disable react/no-children-prop */
import React from 'react'
import XCustomHeader from 'xui/components/XCustomHeader'
import BaseStoreAssistantMember, { IMember } from 'x/modules/store/Assistant/BaseStoreAssistantMember'
import XContainer from 'xui/components/XContainer'
import { Image } from 'native-base'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import Segment from 'xui/components/Segment'
import _ from 'lodash'
import { ScrollView, TouchableOpacity } from 'react-native'
import NB from 'x/config/nativebase'
import XIcon from 'xui/components/XIcon'
import ActionSheet from 'xui/components/singleton/ActionSheet'
import XInput from 'xui/components/XInput'
import XButton from 'xui/components/XButton'
import XOverlay from 'xui/components/XOverlay'
import { IPermission } from 'x/modules/store/Assistant/BaseAssistantGroupListView'
import VStack from 'xui/components/VStack'
import XContent from 'xui/components/XContent'
import * as util from 'x/utils/util'

export default abstract class BaseUIAssistantGroupListView extends BaseStoreAssistantMember {
  renderCustomHeader = () => (
    <XCustomHeader
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => util.navGoBack(this.props),
      }}
      title='ผู้ช่วย'
    />
  )

  _renderDescription = () => {
    const { permissionName, permissionDescription } = this.state
    return (
      <XCard p='2' m='2'>
        <Box>
          <XText variant='active' bold>
            {permissionName}
          </XText>
          <XText variant='inactive' numberOfLines={3}>
            {permissionDescription}
          </XText>
        </Box>
      </XCard>
    )
  }

  _renderNoMemberView = () => {
    const { loadingMember } = this.state
    let text = 'ไม่พบผู้ช่วยในบทบาทนี้ คุณสามารถเพิ่มผู้ช่วยได้โดยกดที่ปุ่ม เพิ่มผู้ช่วย'
    if (loadingMember) {
      text = 'กำลังโหลด...'
    }
    return (
      <HStack>
        <XText variant='inactive'>{text}</XText>
      </HStack>
    )
  }

  _showActionSheetFromMemberAction = async (data: IMember) => {
    const BTN_LABELS = ['เปลี่ยนสิทธิ์การใช้งาน', 'ถอดถอนออกจากการเป็นผู้ช่วย', 'ยกเลิก']
    ActionSheet.show(
      {
        options: BTN_LABELS,
        cancelButtonIndex: BTN_LABELS.length - 1,
        // destructiveButtonIndex: -1,
        title: 'จัดการผู้ช่วย...',
      },
      (buttonIndex: number) => {
        switch (buttonIndex) {
          case 0:
            this._handleOpenPermissionOverlay()
            this.helperProfileId = data.i
            break
          case 1:
            this._deleteHelperFromPermission(data)
            break

          default:
            break
        }
      }
    )
  }

  _renderHelperListView = () => {
    const { members, selectedSegmentIndex } = this.state
    if (selectedSegmentIndex !== 0) {
      return null
    }
    if (_.isNil(members) || members.length < 1) {
      return this._renderNoMemberView()
    }
    return (
      <VStack mt='2'>
        {members.map((data: IMember, index: number) => (
          // let backgroundColor = 'transparent'
          <TouchableOpacity
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}`}
            onPress={() => this._showActionSheetFromMemberAction(data)}
            // style={{
            //   height: 60,
            //   backgroundColor,
            // }}
          >
            <HStack
              h='12'
              // bgColor={backgroundColor}
              _light={index % 2 !== 0 ? NB.U.BG_L_LIGHT_GREY : NB.U.BG_L_CLEAR}
              _dark={index % 2 !== 0 ? NB.U.BG_D_LIGHT_GREY : NB.U.BG_D_CLEAR}>
              <Box>
                <XText variant='inactive' fontSize='xs'>
                  {index + 1}
                </XText>
              </Box>
              <Box alignItems='center' justifyContent='center' pl='4'>
                <Image src={data.t} w='9' h='9' borderRadius='full' />
              </Box>
              <Box alignItems='flex-start' justifyContent='center' pl='4' w='48'>
                <XText variant='inactive' numberOfLines={1}>
                  {data.d}
                </XText>
              </Box>
              <Box alignItems='flex-end' justifyContent='center' flex={1} pr='2'>
                <XIcon family='AntDesign' name='arrowright' variant='inactive' />
              </Box>
            </HStack>
          </TouchableOpacity>
        ))}
      </VStack>
    )
  }

  _renderAddHelper = () => {
    const { username, selectedSegmentIndex } = this.state
    if (selectedSegmentIndex !== 1) {
      return null
    }
    return (
      <Box mt='1'>
        <XText variant='inactive'>ระบุชื่อบัญชีผู้ใช้ของผู้ช่วย (username)</XText>
        <HStack>
          <XInput
            value={username}
            flex={1}
            placeholder='ระบุชื่อบัญชีผู้ใช้ของผู้ช่วย (username)'
            onChangeText={this._handleOnChangeUsername}
          />
          <XButton ml='2' disabled={username.length < 1} onPress={this._addHelper}>
            เพิ่มผู้ช่วย
          </XButton>
        </HStack>
        <Box h='6' />
        <XText variant='inactive'>วิธีการหา ชื่อบัญชีผู้ใช้ ของผู้ช่วย</XText>
        <XText variant='inactive'>1. เปิดแอป XSelly ที่เครื่องผู้ช่วย</XText>
        <XText variant='inactive'>2. ที่หน้าแรก (การขาย) กดทีเมนู tab ด้านล่างสุด เมนู ที่ 3 (รูป ...)</XText>
        <XText variant='inactive'>3. ชื่อบัญชีผู้ใช้จะข้างรูปโปรไฟล์ (คุณสามารถแตะที่ชื่อบัญชีผู้ใช้เพื่อคัดลอกได้)</XText>
        <Box h='6' />
        <XText variant='inactive'>หมายเหตุ</XText>
        <XText variant='inactive'>
          เมื่อเพิ่มผู้ช่วยแล้ว ที่เครื่องผู้ช่วยให้ปิดแอปแล้วเปิดใหม่ เมื่อเข้ามาที่หน้าแรก (การขาย)
          จะเห็นปุ่มรูปลูกศรด้านซ้ายบนใช้กดเพิ่อสลับร้านได้
        </XText>
      </Box>
    )
  }

  _renderTabCard = () => {
    const { selectedSegmentIndex } = this.state
    return (
      <XCard p='2' m='2' mt='0' flexBasis='auto'>
        <Segment
          options={['รายชื่อผู้ช่วย', 'เพิ่มผู้ช่วย']}
          selectedIndex={selectedSegmentIndex}
          onSegmentChange={(newInder: number) => this.setState({ selectedSegmentIndex: newInder })}
        />
        {this._renderHelperListView()}
        {this._renderAddHelper()}
      </XCard>
    )
  }

  _renderOverlay = () => {
    const { showOverlayChangePermission, permissions, selectedPermissionIndex } = this.state
    if (_.isNil(permissions)) {
      return null
    }
    let selectedId = null
    return (
      <XOverlay
        contentStyle={{ height: 260, width: 270, alignSelf: 'center', backgroundColor: '#fff', borderRadius: 7 }}
        visible={showOverlayChangePermission}
        onRequestClose={this._handleClosePermissionOverlay}
        children={
          <Box h='260px' alignItems='center' justifyContent='center' borderRadius='md'>
            <XText variant='active'>เปลี่ยนสิทธิ์การใช้งาน</XText>
            {/* BODY */}
            <XCard w='260px' h='190px' alignSelf='center' ml='1'>
              <ScrollView>
                {permissions.map((data: IPermission, index: number) => {
                  let select = false
                  if (selectedPermissionIndex < 0 && index === 0) {
                    select = true
                  } else if (selectedPermissionIndex === index) {
                    select = true
                  }
                  if (select) {
                    selectedId = data.id
                  }
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Box w='full' key={index}>
                      <TouchableOpacity onPress={() => this._onChangeSelectedPermissionIndex(index)} style={{ paddingRight: 4 }}>
                        <HStack h='10' pt='1' pb='1'>
                          <Box w='10' justifyContent='center' alignItems='center'>
                            {select ? <XIcon name='caretright' family='AntDesign' /> : null}
                          </Box>
                          <Box
                            // flex={1}
                            w='210px'
                            pl='2'
                            borderWidth='1px'
                            borderRadius='md'
                            justifyContent='center'
                            borderColor={select ? 'primary.500' : 'gray.500'}>
                            <XText variant={select ? 'primary' : 'inactive'}>{data.name}</XText>
                          </Box>
                        </HStack>
                      </TouchableOpacity>
                    </Box>
                  )
                })}
              </ScrollView>
            </XCard>
            <HStack mt='2' _light={{ bg: 'white' }} _dark={{ bg: 'gray.800' }}>
              <XButton onPress={() => this._submitPermissionChange(selectedId)} ml='1'>
                <HStack w='200px' justifyContent='center' alignItems='center'>
                  <XIcon family='FontAwesome' name='exchange' color='white' />
                  <XText ml='2' color='white'>
                    เปลี่ยนสิทธิ์
                  </XText>
                </HStack>
              </XButton>
              <XButton variant='outline' ml='2' onPress={() => this._handleClosePermissionOverlay()}>
                <XText>ปิด</XText>
              </XButton>
            </HStack>
          </Box>
        }
      />
    )
  }

  render() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent>
          <Box w='full'>
            {this._renderOverlay()}
            {this._renderDescription()}
            {this._renderTabCard()}
          </Box>
        </XContent>
      </XContainer>
    )
  }
}
