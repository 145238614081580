// import PropTypes from 'prop-types'
import React from 'react'

import { isImmutable, Map, List, fromJS } from 'immutable'

import _ from 'lodash'

import p from 'x/config/platform-specific'

import { E_MSG_TYPE } from 'x/utils/op-interface'
// import BaseImgManager from '../../components/BaseImgManager'
// import * as utilN from 'src/utils/util-native'
import {
  ICategory,
  IPullProductRequestBody,
  IAddProductResponse,
  IEditProductResponse,
  IVariantFilterOptions,
  IFetchOrdersRequestBody,
} from 'x/index'
import { detailedDiff } from 'deep-object-diff'
import dayjs, { Dayjs } from 'dayjs'

import { APP_CONFIG } from 'x/config/mode'
import { IProductDetailVariantMap } from 'x/index'
import { IMkpProductContextProps } from 'x/modules/marketplace/MkpProductContext'
import { IMKPChannelDetail } from 'x/types'
import * as xNavActions from 'x/utils/navigation'
import {
  IProductViewProps,
  IProductViewState,
  // IProductViewParams,
  // ISelectedStoreMap,
  IProductVariantProperty,
  IProductViewHamburgerItem,
} from '../../index'
import { getLocalConfigsInSelectedStore } from '../../redux/selectors'
import * as xAcl from '../../utils/acl'
import xCONS from '../../config/constants'
import { log, setStatePromise, isDiffAccuracy, getWarehouseDisplayName, getWarehousesDetail } from '../../utils/util'
import * as xUtil from '../../utils/util'
import * as NavActions from '../../utils/navigation'

const {
  ADD,
  VIEW_SELF,
  VIEW_SELLER,
  EDIT_SELF,
  EDIT_SELLER,
  PULL_MY_PRODUCT_TO_ORDER,
  PULL_SELLER_PRODUCT_TO_ORDER,
  PULL_PRODUCT,
  VIEW_VOLUME_DISCOUNT,
} = xCONS.PRODUCT_VIEW_MODE

const { NAME, COST, PRICE, PRICE_RETAIL, QTY, AVAILABLE_QTY, WEIGHT, H, SKU, UPC, CATEGORY } = xCONS.PRODUCT_ATTR
const { COMPUTED_USE_PRODUCT_UPC, COMPUTED_USE_PRODUCT_SKU } = xCONS.STORE_SETTINGS

// const PARENT_STORE = 'PARENT_STORE'
// const MY_PRODUCT = `MY_PRODUCT`

const {
  // PRODUCT_STOCK,
  REPORT_VIEW,
  PRODUCT_STOCK_VIEW,
  PRODUCT_STOCK_EDIT,
  PRODUCT_COST,
  PRODUCT_PRICE,
  PRODUCT_WEIGHT,
  PRODUCT_SHIPPING_QTY_RATE,
  PRODUCT_CODE_EDIT_SKU,
  PRODUCT_CODE_EDIT_UPC,
} = xCONS.PERM_STORE_HELPER

const reportProductDailySalesOtp = [
  {
    label: 'รหัสสินค้า UPC',
    id: 1,
    value: false,
  },
  {
    label: 'รหัสสินค้า SKU',
    id: 2,
    value: false,
  },
  {
    label: 'ยอดขายรวม',
    id: 3,
    value: false,
  },
  {
    label: 'ยอดกำไรรวม',
    id: 4,
    value: false,
  },
]

// interface ProductVariantItemProperty extends IProductVariantProperty {
//   idx: number // ลำดับ index ของ variant
// }

export default abstract class BaseProductView extends React.Component<IProductViewProps & IMkpProductContextProps, IProductViewState> {
  isUnmounting: boolean

  inProcess: boolean

  isInitProductGroup: boolean

  isInitProductWarehouses: boolean

  _shouldForceUpdateAfterComputed: boolean

  isEditableProductMap: Map<string, boolean>

  isEditableProductVariantsMap: Map<string, boolean>

  canDoAtSelectedStore: { [key: string]: boolean }

  canAutoCalcShippingRateByQty: boolean

  canAutoCalcShippingRateByWeight: boolean

  hamburgerMenuRef?: React.RefObject<any>

  inSelectHamburger?: boolean

  hamburgerMenuItems: IProductViewHamburgerItem[]

  DATE_RANGE: string[]

  DATE_RANGE_REPORT_PRODUCT_DAILY_SALES: string[]

  // s_use_retail_price: boolean | number
  // order_use_retail_price: boolean | number

  DEFAULT_PRODUCT_CATEGORY_KEY: string // Locally view's usage

  DEFAULT_PRODUCT_SELECTED_PGS_KEY: string // Locally view's usage

  DEFAULT_PRODUCT_SELECTED_WH_IDS_KEY: string // Locally view's usage

  PRODUCT_VARIANTS_TEMPLATE_INIT: { [key: string]: IProductVariantProperty }

  PRODUCT_VARIANTS_TEMPLATE: { [key: string]: IProductVariantProperty }

  OPTIONS_VOLUME_DISCOUNT_MENU_TYPES: string[]

  OPTIONS_VOLUME_DISCOUNT_COLUMN_TYPES: string[]

  OPTIONS_VOLUME_DISCOUNT_TITLES: { my: string; seller: string }

  OPTIONS_VOLUME_DISCOUNT_SUBTITLES: { my: string; seller: string }

  VOLUME_DISCOUNT_TEMPLATE: List<any>

  // imgManagerRef?: BaseImgManager
  imgManagerRef?: any

  imgManagerVariantRefs: { [variantIndex: number]: any }

  scrollViewRef?: any

  scrollToBottomY?: any // onContentSizeChange usage

  inputRefs?: any[] // onContentSizeChange usage

  VariantPageItemLimit: number

  variantPagePpIdToIndex: {
    // ppId: index
    [ppId: number]: number
  }

  // abstract _getParams(): IProductViewParams
  abstract _downloadBarcodeSUKAndUPC(): Promise<void>

  abstract _copyTextToClipboard(sourceName: string, txtSource: string): void

  abstract _renderReportHistory(): void

  abstract handleOnDownloadFile(url: string, fileName: string): Promise<void>

  // abstract _extendComponentWillReceiveProps(nextProps): void
  abstract _extendedWillUnmount(): void

  abstract _handleAfterAlertMissingProductFields(fieldKey: string): void

  abstract _handleAfterAlertMissingInitQty(shippingTypeIdInt: number): void

  abstract _handleAfterAlertMissingInitPrice(shippingTypeIdInt: number): void

  abstract _handleAfterAlertMissingNextPrice(shippingTypeIdInt: number): void

  abstract _handleAfterAlertMissingProductVariant(variantKey: string, variantIndex: number): void

  abstract _handleAfterAlertDuplicateVariantName(lastVariantIndex: number): void

  abstract _handleAfterAlertFormIsNotFullfill(): void

  abstract _handleAfterDeleteProduct(): Promise<void>

  abstract _handleAfterAddProduct(): Promise<void>

  abstract _handleAfterEditProduct(): Promise<void>

  abstract _handleAfterPullProductToMyStore(): Promise<void>

  abstract _handleAfterPullProductToOrder(res: Response, mode: string): void

  abstract _handleAfterFetchedProduct(): Promise<void>

  abstract _beginUploading(): Promise<void>

  abstract _endUploading(): Promise<void>

  abstract _beginSubmitting(): Promise<void>

  abstract _endSubmitting(): Promise<void>

  abstract _initializeComponentDidMountExtended(): Promise<void>

  abstract _cannotInitializeExtended(): Promise<void>

  // abstract async _getSubmittedImages(): Promise<{
  //   img_uris?: string[], thumbnail_uris?: string[],
  //   tiny_img_uris?: string[], isLoading?: boolean,
  //   allUploaded: boolean,
  //   unfinishedIndexes?: number[];
  //   isEmpty?: boolean;
  //   isError?: boolean;
  // }>

  constructor(props) {
    super(props)
    this.OPTIONS_VOLUME_DISCOUNT_TITLES = {
      my: 'ฉันให้ส่วนลดแก่ผู้ซื้อ',
      seller: 'ฉันได้ส่วนลดจากผู้ขายส่ง',
      // my: 'ส่วนลดที่ฉันให้ผู้ซื้อ',
      // seller: 'ส่วนลดที่ผู้ขายส่งให้ฉัน',
    }
    this.OPTIONS_VOLUME_DISCOUNT_SUBTITLES = {
      my: '(ลดกำไรของฉัน)',
      seller: '(เพิ่มกำไรให้ฉัน)',
    }
    this.OPTIONS_VOLUME_DISCOUNT_MENU_TYPES = ['ปิดใช้งาน', 'คิดเป็น %', 'คิดเป็นบาท']
    this.OPTIONS_VOLUME_DISCOUNT_COLUMN_TYPES = ['ปิด', '%', 'บาท']
    this.VOLUME_DISCOUNT_TEMPLATE = List([Map({ begin: 1, end: 5, discount: 0 }), Map({ begin: 6, end: 10, discount: 10 })])

    // @ts-ignore
    this.state = {
      // inputRefs: [],
      currentRef: null,
      // previewImageURI: '',
      // previewVisible: false,
      refreshing: false,
      uploading: false,
      submitting: false,
      showUploaded: false,

      isInitialized: false,
      hamburgerMenuOptions: [],
      isHamburgerMenuOpened: false,
      // PRODUCT_VARIANT_KEYS: [],
      PRODUCT_VARIANT_ITEMS: [],
      // VARIANT_ITEM_PROPERTIES: [],
      isReportHistory: false,
      selectedDateRange: { begin: dayjs(), end: dayjs() },
      selectedOptSegment: 0,
      isIndexSelectedItemHistoryReport: 0,
      historyReportVariants: null,
      historyWarningText: '',
      isReportProductDailySales: false,
      selectedDateRangeProductDailySales: { begin: dayjs(), end: dayjs() },
      productDailySalesOtp: reportProductDailySalesOtp,
      selectedOptSegmentProductDailySales: 0,
      variantsForProductDailySales: null,
      downloadingHistoryReport: false,
      visibleCategoryList: false,
      category: null,
    }

    // for app initialize
    this.scrollViewRef = null
    this.scrollToBottomY = null
    this.inputRefs = []
    this.imgManagerVariantRefs = {}
    for (let i = 0; i < 1000; i++) {
      this.imgManagerVariantRefs[i] = React.createRef()
    }
    // this.waitingUploadCount = 0 // for check uploading

    this.canDoAtSelectedStore = {}
    this.canAutoCalcShippingRateByQty = false
    this.canAutoCalcShippingRateByWeight = false
    this.isInitProductGroup = false
    this.isInitProductWarehouses = false

    // this.hamburgerMenuRef = null
    this.hamburgerMenuRef = React.createRef()
    this.inSelectHamburger = false
    this.DATE_RANGE = ['วันนี้', '7 วัน', '30 วัน', 'ระบุ']
    this.DATE_RANGE_REPORT_PRODUCT_DAILY_SALES = ['ถูกสร้าง', 'เสร็จสิ้น']

    // this.variantsInputs = []
    // this._isDoneInitDataLoading = this._isDoneInitDataLoading.bind(this)
    // this._isSellerProduct = this._isSellerProduct.bind(this)
    // this._shallRenderShippingRates = this._shallRenderShippingRates.bind(this)
    // this._pullProductRequest = this._pullProductRequest.bind(this)
    // this._getSubmittedImages = this._getSubmittedImages.bind(this)
    // this._cannotInitialize = this._cannotInitialize.bind(this)

    // this._computeEditable = this._computeEditable.bind(this)
    // this._handleSubmitAddProduct = this._handleSubmitAddProduct.bind(this)
    // this._handleSubmitEditProduct = this._handleSubmitEditProduct.bind(this)

    this.inProcess = false
    this._shouldForceUpdateAfterComputed = false
    // this.s_use_retail_price = false // for checking is opened retail price mode ?
    // this.order_use_retail_price = false // for checking is use retail price in order

    this.DEFAULT_PRODUCT_CATEGORY_KEY = 'product_category_id'
    this.DEFAULT_PRODUCT_SELECTED_PGS_KEY = 'product_pg_ids'
    this.DEFAULT_PRODUCT_SELECTED_WH_IDS_KEY = 'product_wh_ids'
    this.isEditableProductMap = Map({})
    this.isEditableProductVariantsMap = Map({})
    this.PRODUCT_VARIANTS_TEMPLATE_INIT = {
      name: {
        // 0
        key: 'name',
        label: 'ชื่อ',
        icon: 'create',
        placeholder: 'เช่น สีดำ',
        // isNumber: false,
      },
      cost: {
        // 1
        key: 'cost',
        icon: 'cash',
        label: 'ต้นทุน',
        placeholder: 'คุณเห็นเท่านั้น',
        // isNumber: true,
        isMoney: true,
      },
      price: {
        // 2
        key: 'price',
        icon: 'pricetag',
        label: 'ราคา',
        // placeholder: s_use_retail_price ? 'ระบุราคาขาย' : 'ระบุราคาขายส่ง/ตัวแทน',
        // isNumber: true,
        // isMoney: true,
        placeholder: 'ระบุราคาขาย',
        // isNumber: true,
        isMoney: true,
      },
      qty: {
        // 3
        key: 'qty',
        icon: 'albums',
        label: 'จำนวน',
        placeholder: 'จำนวนสินค้า',
        // isNumber: true,
        isInteger: true,
      },
      available_qty: {
        // 4
        key: 'available_qty',
        icon: 'albums',
        label: 'คลังพร้อมขาย',
        placeholder: 'จำนวนสินค้า',
        // isNumber: true,
        isInteger: true,
      },
      weight: {
        // 5
        key: 'weight',
        icon: 'scale',
        iconFamily: 'MaterialCommunityIcons',
        label: 'น้ำหนัก',
        placeholder: 'ระบุน้ำหนัก(กรัม)',
        // isNumber: true,
        isInteger: true,
      },
      price_retail: {
        // 6
        key: 'price_retail',
        icon: 'pricetag',
        label: 'ราคาขายปลีก',
        placeholder: 'ระบุราคาขายปลีก',
        // isNumber: true,
        isMoney: true,
      },
      // TODO:SKU::implement with store setting and _getProductVariantItems method.
      sku: {
        // 7
        key: 'sku',
        icon: 'barcode-scan',
        iconFamily: 'MaterialCommunityIcons',
        label: 'SKU',
        additionalLabel: '(ร้านฉันเท่านั้น สมาชิกไม่เห็น)',
        placeholder: 'สแกนหรือพิมพ์ SKU',
        // isNumber: true,
        // isMoney: true,
        isBarcode: true,
      },
      upc: {
        // 8
        key: 'upc',
        icon: 'barcode-scan',
        iconFamily: 'MaterialCommunityIcons',
        label: 'UPC',
        additionalLabel: '(สมาชิกทุกคนมองเห็น)',
        placeholder: 'สแกนหรือพิมพ์ UPC',
        // isNumber: true,
        // isMoney: true,
        isBarcode: true,
      },
    }

    this.PRODUCT_VARIANTS_TEMPLATE = _.cloneDeep(this.PRODUCT_VARIANTS_TEMPLATE_INIT)

    // bind all abstract method
    // this._getParams = this._getParams.bind(this)
    // this._downloadBarcodeSUKAndUPC = this._downloadBarcodeSUKAndUPC.bind(this)
    // this._extendComponentWillReceiveProps = this._extendComponentWillReceiveProps.bind(this)
    this._extendedWillUnmount = this._extendedWillUnmount.bind(this)
    this._handleAfterAlertMissingProductFields = this._handleAfterAlertMissingProductFields.bind(this)
    this._handleAfterAlertMissingInitQty = this._handleAfterAlertMissingInitQty.bind(this)
    this._handleAfterAlertMissingInitPrice = this._handleAfterAlertMissingInitPrice.bind(this)
    this._handleAfterAlertMissingNextPrice = this._handleAfterAlertMissingNextPrice.bind(this)
    this._handleAfterAlertMissingProductVariant = this._handleAfterAlertMissingProductVariant.bind(this)
    this._handleAfterAlertDuplicateVariantName = this._handleAfterAlertDuplicateVariantName.bind(this)
    this._handleAfterAlertFormIsNotFullfill = this._handleAfterAlertFormIsNotFullfill.bind(this)
    this._handleAfterDeleteProduct = this._handleAfterDeleteProduct.bind(this)
    this._handleAfterAddProduct = this._handleAfterAddProduct.bind(this)
    this._handleAfterEditProduct = this._handleAfterEditProduct.bind(this)
    this._handleAfterPullProductToMyStore = this._handleAfterPullProductToMyStore.bind(this)
    this._handleAfterPullProductToOrder = this._handleAfterPullProductToOrder.bind(this)
    this._handleAfterFetchedProduct = this._handleAfterFetchedProduct.bind(this)
    this._beginUploading = this._beginUploading.bind(this)
    this._endUploading = this._endUploading.bind(this)
    this._beginSubmitting = this._beginSubmitting.bind(this)
    this._endSubmitting = this._endSubmitting.bind(this)

    this._initializeComponentDidMountExtended = this._initializeComponentDidMountExtended.bind(this)
    this._cannotInitializeExtended = this._cannotInitializeExtended.bind(this)

    this.hamburgerMenuItems = []
    // this.VariantPageItemLimit = 10
    this.VariantPageItemLimit = 5
    this.variantPagePpIdToIndex = {}
  }

  async componentDidMount() {
    // await this._updateHamburgerMenuOptions()
    await this._configHamburgerMenuItems()
    await this._historyReportSetup()
    // setTimeout(this._initializeComponentDidMount, 200)
    await xUtil.delay(100)
    await this._initializeComponentDidMount()
  }

  _configHamburgerMenuItems = async () => {
    const menu = []
    const editProduct = {
      key: 'edit_product',
      label: 'แก้ไขสินค้า',
      onPress: () => this._editProduct(),
      icon: { name: 'edit', family: 'AntDesign' },
      perm: xCONS.PERM_STORE_HELPER.PRODUCT_EDIT,
      // visibleCondition: order => !this.isOrderInCancelled(order) && this._canAutoOperations(order),
      // visibleCondition: this._canAutoOperations,
    }
    const editProductStock = {
      key: 'edit_product_stock',
      label: 'แก้ไขคลัง',
      onPress: this._navToProductVariantListEditStock,
      icon: { name: 'edit', family: 'AntDesign' },
      perm: xCONS.PERM_STORE_HELPER.PRODUCT_EDIT,
      visibleCondition: this.isMyProduct,
    }
    const editProductPrice = {
      key: 'edit_product_price',
      label: 'แก้ไขราคา',
      onPress: this._navToProductVariantListEditPrice,
      icon: { name: 'edit', family: 'AntDesign' },
      perm: xCONS.PERM_STORE_HELPER.PRODUCT_EDIT,
    }
    const cloneProduct = {
      key: 'clone_product',
      label: 'สร้างสินค้าใหม่\nโดยใช้ข้อมูลสินค้าชิ้นนี้',
      onPress: this.onPressCloneProduct,
      icon: { name: 'duplicate-outline', family: 'Ionicons' },
      perm: xCONS.PERM_STORE_HELPER.PRODUCT_EDIT,
    }

    const copyNameAndDescription = {
      key: 'copy_name_and_description',
      label: 'คัดลอกชื่อและคำอธิบาย',
      onPress: () => this._renderCopyClipboardButton(),
      icon: { name: 'copy1', family: 'AntDesign' },
      // perm: PARENT_STORE, // TODO: Use better permission?
      // visibleCondition: order => !this.isOrderInCancelled(order) && this._canAutoOperations(order),
      // visibleCondition: this._canAutoOperations,
    }
    const productBarcode = {
      key: 'product_barcode',
      label: 'พิมพ์ป้ายบาร์โค้ดสินค้า\n(Barcode / QR)',
      onPress: () => this._downloadBarcodeSUKAndUPC(),
      icon: { name: 'printer', family: 'MaterialCommunityIcons' },
      // perm: PERM_STORE_HELPER.PAYMENT_ADD, // TODO: Use better permission?
      // visibleCondition: order => !this.isOrderInCancelled(order) && this._canAutoOperations(order),
      // visibleCondition: this._canAutoOperations,
    }
    const reservedProductInOrders = {
      key: 'reserved_product_in_orders',
      label: 'ดูออเดอร์\nที่จองสินค้าไว้',
      onPress: () => this.openReservedProductInOrdersOverlay(),
      icon: { name: 'profile', family: 'AntDesign' },
      // perm: PERM_STORE_HELPER.PAYMENT_ADD, // TODO: Use better permission?
      // visibleCondition: order => !this.isOrderInCancelled(order) && this._canAutoOperations(order),
      // visibleCondition: this._canAutoOperations,
    }
    const productHistory = {
      key: 'product_history',
      label: 'รายงาน ประวัติการ\nเคลื่อนไหวสินค้า',
      onPress: () => this._handleOpenReportHistory(),
      icon: { name: 'history', family: 'MaterialCommunityIcons' },
      // perm: xCONS.PERM_STORE_HELPER.REPROT_STOCK_CARD,
      mode: 'MY_PRODUCT',
      // visibleCondition: order => !this.isOrderInCancelled(order) && this._canAutoOperations(order),
      // visibleCondition: this._canAutoOperations,
      // visibleCondition: this._isNotWeb,
    }
    const productDailySales = {
      key: 'product_daily_sales',
      label: 'รายงาน การขาย\nสินค้ารายวัน',
      onPress: () => this._navToReportProductDailySales(),
      icon: { name: 'calendar', family: 'FontAwesome' },
      // mode: MY_PRODUCT,
      // perm: xCONS.PERM_STORE_HELPER.REPORT_PRODUCT_DAILY_SALES,
      // visibleCondition: order => !this.isOrderInCancelled(order) && this._canAutoOperations(order),
      // visibleCondition: this._canAutoOperations,
      // visibleCondition: this._isNotWeb,
    }
    const deleteProduct = {
      key: 'delete_product',
      label: 'ลบสินค้า',
      onPress: this._handleDeleteProduct,
      isDanger: true,
      icon: { name: 'trash', family: 'Ionicons' },
      // perm: PERM_STORE_HELPER.PAYMENT_ADD,
      // visibleCondition: this._orderCanAutoFinishPayment,
    }
    // const mkpPairedDetail = {
    //   key: 'mkp_paired_detail',
    //   label: 'รายละเอียดสินค้าที่ถูกผูกกับช่องทางขาย',
    //   onPress: this._navToProductDetailMkpInfoDetailView,
    //   icon: { name: 'link', family: 'FontAwesome' },
    // }

    if (APP_CONFIG.product.show_menu_edit_product) {
      menu.push(editProduct)

      menu.push(editProductStock)
      menu.push(editProductPrice)

      menu.push(cloneProduct)

      // if (MODE === 'dev') {
      //   menu.push(mkpPairedDetail)
      // }
    }
    menu.push(copyNameAndDescription)
    if (APP_CONFIG.product.show_menu_barcode) {
      menu.push(productBarcode)
    }
    menu.push(reservedProductInOrders)
    menu.push(productHistory)
    menu.push(productDailySales)

    if (APP_CONFIG.product.show_menu_delete_product) {
      menu.push(deleteProduct)
    }
    this.hamburgerMenuItems = menu
  }

  async componentDidUpdate(prevProps: IProductViewProps, prevState: IProductViewState): Promise<void> {
    if (!this.state.isInitialized || this.isUnmounting) {
      return
    }
    this._shouldForceUpdateAfterComputed = false
    if (isDiffAccuracy(prevState, this.state, ['mode'])) {
      this._computeEditable(this.state.mode)
    }

    await this._updateProductStaticConfigs(prevProps, prevState)
    await this._initProductGroupIdsOnAddOrPullProductMode(prevProps, prevState)
    // this._updateProductVariantKeysInDidUpdate(prevProps, prevState, prevMode, currMode)
    const updateVariantProperties = this._updateProductVariantKeysInDidUpdate(prevProps, prevState)
    // await this._updateProductVariantRenderCombinations(prevProps, prevState)
    const updateImgManager = this._updateImgManager(prevProps, prevState)

    await Promise.all([updateVariantProperties, updateImgManager])
    if (this._shouldForceUpdateAfterComputed && !this.isUnmounting) {
      this.forceUpdate()
    }
  }

  _isNotWeb = () => !p.op.isWeb()

  _historyReportSetup = async () => {
    const { subscription } = this.props
    const owner = xAcl.isSelectedStoreOwner()
    const r_shpg_day = subscription.get('r_shpg_day')
    let text = 'แพ็กเกจของคุณไม่สามารถใช้งานฟังก์ชันนี้ได้'
    if (owner) {
      if (!xAcl.packFree()) {
        text = `แพ็กเกจของคุณดูย้อนหลังได้ ${r_shpg_day} วัน`
      }
    } else {
      // FIXME :: ถ้าเป็นตัวแทนร้าน type 1 ล่ะ ?
      const canDo = xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.REPORT_INVENTORY, xCONS.REPORT_SET_BIT_BINARY.report_stock_card)
      if (canDo && !xAcl.packFree()) {
        text = `แพ็กเกจของคุณดูย้อนหลังได้ ${r_shpg_day} วัน`
      } else {
        text = `คุณไม่ได้รับสิทธิในการใช้งานฟังก์ชันนี้`
      }
    }

    xUtil.setStatePromise(this, {
      historyWarningText: text,
    })
  }

  // _updateProductVariantRenderCombinations = async(prevProps?: IProductViewProps, prevState?: IProductViewState): Promise<void> => {
  //   const isForceUpdate: boolean = (!prevProps && !prevState) || !this.state.VARIANT_ITEM_PROPERTIES
  //   if (isForceUpdate) {
  //     await this._doComputeProductVariantRenderCombinations()
  //     return
  //   }
  //
  //   const prevVariants = prevProps.editingProduct.get('variants') || List([])
  //   const currVariants = this.props.editingProduct.get('variants') || List([])
  //   const isChangedVariantSize = prevVariants.size !== currVariants.size
  //   if (isChangedVariantSize) {
  //     await this._doComputeProductVariantRenderCombinations()
  //     return
  //   }
  //
  //   const oldPgIds = prevProps.editingProduct.get('product_group_ids')
  //   const newPgIds = this.props.editingProduct.get('product_group_ids')
  //   const isChangeProductGroupIds = oldPgIds !== newPgIds
  //   if (isChangeProductGroupIds) {
  //     await this._doComputeProductVariantRenderCombinations()
  //     return
  //   }
  // }

  _downloadHistoryReport = async (data: IProductDetailVariantMap) => {
    // data = Variants 1 ก้อน
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const canDo = xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.REPORT_INVENTORY, xCONS.REPORT_SET_BIT_BINARY.report_stock_card)
    // const owner = xAcl.isSelectedStoreOwner()
    if (!canDo) {
      p.op.showConfirmationOkOnly('', 'คุณไม่ได้รับสิทธิในการใช้งานฟังก์ชันนี้')
      this.inProcess = false
      return
    }

    await xUtil.setStatePromise(this, { downloadingHistoryReport: true })

    const { subscription } = this.props
    if (subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.FREE) {
      await xUtil.setStatePromise(this, { downloadingHistoryReport: false })
      p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
      this.inProcess = false
      return
    }

    if (_.isNil(data)) {
      this.inProcess = false
      await xUtil.setStatePromise(this, { downloadingHistoryReport: false })
      return
    }
    const { selectedDateRange } = this.state
    // const { store_id } = this._getParams()
    const store_id = xUtil.getNavParam(this.props, 'store_id')

    const newData = isImmutable(data) ? data : fromJS(data)
    const xStartDate = selectedDateRange.begin
    const xEndDate = selectedDateRange.end

    // ถ้า user เลือก custom date range
    // if (isIndexSelectedItemHistoryReport === 1) {
    //   xStartDate = selectedDateRange.begin
    //   xEndDate = selectedDateRange.end
    // } else if (isIndexSelectedItemHistoryReport === 2) {
    //   xStartDate = selectedDateRange.begin
    //   xEndDate = selectedDateRange.end
    // }

    const startDate = xStartDate.format('YYYY-MM-DD')
    const endDate = xEndDate.format('YYYY-MM-DD')
    const pp_id = newData.get('pp_id')

    const url = xUtil.getHistorysReportViewExcelUrl({
      store_id,
      startDate,
      endDate,
      pp_id,
    })
    // console.log('url => ', url)
    let fileName = `history product report${startDate}-${endDate}`

    fileName = `${fileName}.xlsx`
    await this.handleOnDownloadFile(url, fileName)
    p.op.aLogEvent(xCONS.EVENT_NAME.REPROT_STOCK_CARD, { s: store_id })
    await setTimeout(() => {
      this.inProcess = false
    }, 500)
    await xUtil.setStatePromise(this, { downloadingHistoryReport: false })
  }

  _seleteCheckBoxBtn = (rVariants: any[], index: number) => {
    const newVariants = rVariants
    const newChange = newVariants[index]
    newChange.value = !newChange.value
    newVariants[index] = newChange
    xUtil.setStatePromise(this, {
      variantsForProductDailySales: newVariants,
    })
  }

  // _seletedRadioBtn = async (data: any, number: number) => {
  //   //   xUtil.setStatePromise(this, {
  //   //     historyReportVariants: data,
  //   //     isIndexSelectedItemHistoryReport: number,
  //   //   })
  //   // }

  _seletedRadioBtn = async (args: { data: any; index: number }) => {
    const { data, index } = args
    xUtil.setStatePromise(this, {
      historyReportVariants: _.isNil(data) ? null : data.toJS(),
      isIndexSelectedItemHistoryReport: index,
    })
  }

  _onChangeSegmentReportProductDailySales = async (newIndex) => {
    xUtil.setStatePromise(this, {
      selectedOptSegmentProductDailySales: newIndex,
    })
  }

  _onChangeSegment = async (newIndex) => {
    const { selectedDateRange } = this.state
    const newDateTime = selectedDateRange

    newDateTime.end = dayjs() // set to today
    switch (newIndex) {
      case 0: // today
        newDateTime.begin = newDateTime.end
        break
      case 1: // Within 7 days
        newDateTime.begin = dayjs().subtract(6, 'd')
        break
      case 2: // Within 30 days
        newDateTime.begin = dayjs().subtract(29, 'd')
        break
      default:
        // default to today
        newDateTime.begin = newDateTime.end
    }

    xUtil.setStatePromise(this, {
      selectedDateRange: newDateTime,
      selectedOptSegment: newIndex,
    })
  }

  _onChangeDateRange = async (newDates: { begin: Dayjs; end: Dayjs }) => {
    xUtil.setStatePromise(this, { selectedDateRange: newDates })
  }

  _onChangeDateRangeProductDailySales = async (newDates: { begin: Dayjs; end: Dayjs }) => {
    xUtil.setStatePromise(this, { selectedDateRangeProductDailySales: newDates })
  }

  _editProduct = () => {
    // // const { state } = this.props.navigation
    // // const { handleChangeToEditMode } = state.params
    // const handleChangeToEditMode = xUtil.getNavParam(this.props, 'handleChangeToEditMode')
    // handleChangeToEditMode()
    this._handleChangeToEditMode()
  }

  _handleChangeToEditMode = async (): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    // await this._syncStateAndParams({ mode: EDIT_SELF })
    this.inputRefs = []
    await xUtil.setStatePromise(this, { mode: EDIT_SELF })
    this.inProcess = false
  }

  _handleCancelUploading = async (newState: { [key: string]: any }): Promise<void> => {
    // await this._syncStateAndParams({ uploading: false })
    await xUtil.setStatePromise(this, { uploading: false })
    this.inProcess = false
  }

  _navToReportProductDailySales() {
    const { selectedStore, navigation, selectedProduct } = this.props
    const store_id = selectedStore.get(`id`)
    const variants = selectedProduct.get('variants')
    navigation.dispatch(xNavActions.navToReportProductDailySalesView({ store_id, variants }))
  }

  _navToProductVariantListEditStock = () => {
    const { navigation } = this.props
    const { store_id, product_id } = this.state
    navigation.dispatch(
      NavActions.navToProductVariantEditorView({
        storeId: store_id,
        productId: product_id,
        editableKeys: ['qty'],
        headerTitle: 'แก้ไขคลัง',
      })
    )
  }

  _navToProductVariantListEditPrice = () => {
    const { navigation } = this.props
    const { store_id, product_id } = this.state
    navigation.dispatch(
      NavActions.navToProductVariantEditorView({
        storeId: store_id,
        productId: product_id,
        editableKeys: ['cost', 'price'],
        headerTitle: 'แก้ไขราคา',
      })
    )
  }

  _onProductDetailMkpInfoDetailViewMappingSuccess = async (ppId?: number) => {
    await this._onRefresh()

    // xUtil.navGoBack(this.props)
    // await xUtil.delay(200)
    // this._navToProductDetailMkpInfoDetailView(ppId)
  }

  _onProductDetailMkpInfoDetailViewMappingSuccessAlternative = async (ppId?: number) => {
    await this._onRefresh()
    p.op.showToast('เพิ่มการผูกสินค้าเสร็จสิ้น', 'success')
  }

  _navToProductDetailMkpInfoDetailView = (ppId?: number) => {
    const { navigation } = this.props
    const { store_id, product_id } = this.state
    navigation.dispatch(
      NavActions.navToProductDetailMkpInfoDetailView({
        store_id,
        product_id,
        pp_id: ppId || undefined,
        onMappingSuccess: () => this._onProductDetailMkpInfoDetailViewMappingSuccess(ppId),
      })
    )
  }

  _renderCopyClipboardButton = () => {
    const { editingProduct } = this.props
    const txtProductName = editingProduct.get('name') || ''
    const txtProductDesc = editingProduct.get('description') || ''
    const txtClipboard = `${txtProductName}\n\n${txtProductDesc}`

    this._copyTextToClipboard('ชื่อและคำอธิบายสินค้า', txtClipboard)
  }

  _updateHamburgerMenuOptions = async (): Promise<void> => {
    await setStatePromise(this, { hamburgerMenuOptions: this._calculateHamburgerMenuOptions() })
    await new Promise((computeDelay) => setTimeout(computeDelay, 100))
  }

  _calculateHamburgerMenuOptions = (): IProductViewHamburgerItem[] => {
    // const { mode } = this.state
    const topMenuOptions = []
    this.hamburgerMenuItems.map((menu) => {
      const { mode = null } = menu
      let isVisibleMenu = true
      if (_.isFunction(menu.visibleCondition)) {
        isVisibleMenu = menu.visibleCondition()
      }

      if (isVisibleMenu && _.isNil(mode)) {
        topMenuOptions.push(menu)
      } else if (isVisibleMenu && mode === 'MY_PRODUCT') {
        const checker = this._checkParentStore()
        if (!checker) {
          topMenuOptions.push(menu)
        }
      }
    })
    return topMenuOptions
  }

  _hasManyVariants = () => {
    const { editingProduct } = this.props
    const variant = editingProduct.get('variants')
    const variantSize = variant ? variant.size : 0
    return variantSize > 1
  }

  _checkParentStore = () => {
    const { selectedProduct } = this.props
    const parentStore = selectedProduct.get('parent_store_name')
    if (_.isNil(parentStore)) {
      return false
    }
    return true
  }

  // _doComputeProductVariantRenderCombinations = async () => {
  //   const { PRODUCT_VARIANT_ITEMS } = this.state
  //   // log('_doComputeProductVariantRenderCombinations PRODUCT_VARIANT_ITEMS => ', PRODUCT_VARIANT_ITEMS)
  //   if (!_.isArray(PRODUCT_VARIANT_ITEMS)) {
  //     return
  //   }
  //   const variants = this.props.editingProduct.get('variants') || List([])
  //   const newCombinations = []
  //   for (let vIndex = 0; vIndex < variants.size; vIndex++) {
  //     PRODUCT_VARIANT_ITEMS.forEach((vProp, vPropIdx) => {
  //       newCombinations.push({ idx: vIndex, ...vProp })
  //     })
  //   }
  //   // log('PRODUCT_VARIANT_ITEMS => ', PRODUCT_VARIANT_ITEMS)
  //   if (variants.size === 1) {
  //     const foundNameIndex = newCombinations.findIndex(pvProp => pvProp.key === NAME)
  //     if (foundNameIndex > -1) {
  //       newCombinations.splice(foundNameIndex, 1)
  //     }
  //   }
  //
  //   await setStatePromise(this, { VARIANT_ITEM_PROPERTIES: newCombinations })
  // }

  _updateImgManager = async (prevProps?: IProductViewProps, prevState?: IProductViewState): Promise<void> => {
    // const prevMode = prevState && _.has(prevState, 'mode') ? prevState.mode : null
    // const currMode = this.state && _.has(this.state, 'mode') ? this.state.mode : null
    const isForceUpdate: boolean = !prevProps && !prevState
    if (isForceUpdate) {
      await this._doUpdateImgManager(this.state.mode)
      this._shouldForceUpdateAfterComputed = true
      return
    }

    let shouldUpdateImages: boolean
    const isChangedMode = isDiffAccuracy(prevState, this.state, ['mode'])
    shouldUpdateImages = isChangedMode

    if (!shouldUpdateImages) {
      const isChangeIsInitialized = isDiffAccuracy(prevState, this.state, ['isInitialized'])
      shouldUpdateImages = isChangeIsInitialized
    }

    if (!shouldUpdateImages) {
      const isChangedSelectedProduct = prevProps && isDiffAccuracy(prevProps, this.props, ['selectedProduct'])
      shouldUpdateImages = isChangedSelectedProduct
    }

    if (shouldUpdateImages) {
      await this._doUpdateImgManager(this.state.mode)
    }
  }

  _doUpdateImgManager = async (currentMode?: string): Promise<void> => {
    if (currentMode && currentMode === ADD) {
      await this._clearImagesInImgMgr()
    } else {
      await this._loadImagesToImgMgr()
    }
  }

  _loadImagesToImgMgr = async (): Promise<void> => {
    // log('In _loadImagesToImgMgr')

    if (this.imgManagerRef && this.imgManagerRef.loadImageUrls) {
      const { selectedProduct } = this.props
      let img_uris = selectedProduct.get('img_uris')
      let thumbnail_uris = selectedProduct.get('thumbnail_uris')
      let tiny_img_uris = selectedProduct.get('tiny_img_uris')

      img_uris = isImmutable(img_uris) ? img_uris.toJS() : img_uris
      thumbnail_uris = isImmutable(thumbnail_uris) ? thumbnail_uris.toJS() : img_uris
      tiny_img_uris = isImmutable(tiny_img_uris) ? tiny_img_uris.toJS() : thumbnail_uris

      // FIXME: for debug only
      // if (thumbnail_uris.length > 0) {
      //   const thumbCount = thumbnail_uris.length
      //   for (let i = 1; i < thumbCount; i++) {
      //     thumbnail_uris.pop()
      //   }
      // }
      // if (tiny_img_uris.length > 0) {
      //   const tinyCount = tiny_img_uris.length - 1
      //   // for (let i = 1; i < tinyCount; i++) {
      //   //   tiny_img_uris.pop()
      //   // }
      //   img_uris[tinyCount] = null
      //   thumbnail_uris[tinyCount] = null
      //   tiny_img_uris[tinyCount] = null
      // }
      // log('In _loadImagesToImgMgr loadImageUrls img_uris => ', img_uris)
      // log('In _loadImagesToImgMgr loadImageUrls thumbnail_uris => ', thumbnail_uris)
      // log('In _loadImagesToImgMgr loadImageUrls tiny_img_uris => ', tiny_img_uris)

      this.imgManagerRef.loadImageUrls({
        p: img_uris,
        t: thumbnail_uris,
        y: tiny_img_uris,
      })
    }
  }

  async _clearImagesInImgMgr(): Promise<void> {
    // log('In _clearImagesInImgMgr')
    if (this.imgManagerRef && this.imgManagerRef.clearImages) {
      await this.imgManagerRef.clearImages()
    }
  }

  _getSubmittedImages = async (): Promise<{
    img_uris?: string[]
    thumbnail_uris?: string[]
    tiny_img_uris?: string[]
    isLoading?: boolean
    allUploaded: boolean
    unfinishedIndexes?: number[]
    isEmpty?: boolean
    isError?: boolean
  }> => {
    // if (this.imgBlockRef && this.imgBlockRef.getSubmittedImages) {
    //   log('this.imgBlockRef => ', this.imgBlockRef)
    //   log('this.imgBlockRef.getSubmittedImages() => ', this.imgBlockRef.getSubmittedImages())
    //   return this.imgBlockRef.getSubmittedImages()
    // }
    // return { isLoading: true }

    if (this.imgManagerRef && this.imgManagerRef.submitUploadImages) {
      // const { p, t, y, allUploaded, unfinishedIndexes, isEmpty } = await this.imgManagerRef.submitUploadImages()
      const res = await this.imgManagerRef.submitUploadImages()
      const unfinishedIndexes = _.isArray(res.unfinishedIndexes) ? res.unfinishedIndexes.map((idx) => idx + 1) : []
      return {
        img_uris: res.p,
        thumbnail_uris: res.t,
        tiny_img_uris: res.y,
        ...res,
        // allUploaded,
        // isEmpty,
        unfinishedIndexes,
      }
    }

    // Must be impossible case
    p.op.alert('ไม่พบรูปภาพ', 'กรุณาลองใหม่อีกครั้ง')
    return { isError: true, allUploaded: false }
  }

  _updateProductVariantKeysInDidUpdate = async (prevProps?: IProductViewProps, prevState?: IProductViewState): Promise<void> => {
    const prevMode = prevState && _.has(prevState, 'mode') ? prevState.mode : null
    const currMode = this.state && _.has(this.state, 'mode') ? this.state.mode : null
    const isForceCompute = !prevProps && !prevState
    const doUpdatePVKeys = async (): Promise<void> => {
      // const params = this._getParams()
      // const params = this.state
      // const newProductVariantItems = this._getProductVariantItems(params)
      const newProductVariantItems = this._getProductVariantItems()
      // log('newProductVariantItems => ', newProductVariantItems)
      await setStatePromise(this, { PRODUCT_VARIANT_ITEMS: newProductVariantItems })
      this._shouldForceUpdateAfterComputed = true
    }

    if (isForceCompute) {
      await doUpdatePVKeys()
      return
    }

    const isChangeSelectedStore = prevProps.selectedStore !== this.props.selectedStore
    if (isChangeSelectedStore) {
      await doUpdatePVKeys()
      return
    }

    const isChangeProduct = prevProps.selectedProduct !== this.props.selectedProduct
    if (isChangeProduct) {
      await doUpdatePVKeys()
      return
    }

    const isChangeMode = prevMode !== currMode
    if (isChangeMode) {
      await doUpdatePVKeys()
      return
    }

    const oldPgIds = prevProps.editingProduct.get('product_group_ids')
    const newPgIds = this.props.editingProduct.get('product_group_ids')
    const isChangeProductGroupIds = oldPgIds !== newPgIds
    if (isChangeProductGroupIds) {
      await doUpdatePVKeys()
      return
    }

    const oldWhIds = prevProps.editingProduct.get('warehouse_ids')
    const newWhIds = this.props.editingProduct.get('warehouse_ids')
    const isChangeWarehouseIds = oldWhIds !== newWhIds
    if (isChangeWarehouseIds) {
      await doUpdatePVKeys()
    }

    // ...
  }

  _initProductGroupIdsOnAddOrPullProductMode = async (prevProps?: IProductViewProps, prevState?: IProductViewState): Promise<void> => {
    const { onChangeProduct, onChangeProductGroups, selectedStore, selectedProductGroups } = this.props
    const { mode } = this.state
    // log('In _initProductGroupIdsOnAddOrPullProductMode selectedStore.toJS() => ', selectedStore.toJS())
    if (this.isInitProductGroup || !selectedStore || !selectedStore.get('id') || !_.includes([ADD, PULL_PRODUCT], mode)) {
      return
    }

    const isForceUpdate = !prevProps && !prevState
    const isChangeSelectedStore = prevProps && this.props && prevProps.selectedStore !== this.props.selectedStore
    if (isForceUpdate || isChangeSelectedStore) {
      // const product_groups = List.isList(selectedStore.get('product_groups'))
      //   ? selectedStore.get('product_groups') : List([])
      const product_groups = List.isList(selectedProductGroups) ? selectedProductGroups : List([])
      // const defaultProductGroup = List.isList(product_groups)
      //   ? product_groups.find((pg) => Map.isMap(pg)
      //     && (
      //       (pg.has('is_default') && pg.get('is_default') === true)
      //       || (pg.has('default') && pg.get('default') === true)
      //     )
      //   )
      //   : null
      // let defaultProductGroups = List([])
      // product_groups.forEach((pg) => {
      //   if (Map.isMap(pg) && pg.get('id')) {
      //     defaultProductGroups = defaultProductGroups.push(pg.get('id'))
      //   }
      // })
      //
      // // log('_initProductGroupIdsOnAddOrPullProductMode selectedStore.toJS() => ', selectedStore.toJS())
      // // log('_initProductGroupIdsOnAddOrPullProductMode product_groups.toJS() => ', product_groups.toJS())
      // // log('_initProductGroupIdsOnAddOrPullProductMode defaultProductGroup. => ', defaultProductGroup)
      // // if (defaultProductGroup && defaultProductGroup.get('id')) {
      // //   this.isInitProductGroup = true
      // //   onChangeProduct({ key: 'product_group_ids', value: List([defaultProductGroup.get('id')]) })
      // // }
      // if (defaultProductGroups && defaultProductGroups.size > 0) {
      //   this.isInitProductGroup = true
      //   onChangeProduct({ key: 'product_group_ids', value: defaultProductGroups })
      // }

      const savedPgIds = await p.op.getAppDefaultValue(this.DEFAULT_PRODUCT_SELECTED_PGS_KEY)
      let isValidSavePgIds
      if (product_groups.size === 1) {
        // กรณีมีการเซฟ setting และ pg มีมากกว่า 1  ถ้ากรณี product_groups มีกลุ่มเดียวควรจะต้องโชว์ราคาเสมอ
        const onlyOnePgId = product_groups.getIn([0, 'id'])
        isValidSavePgIds = [onlyOnePgId]
      } else if (_.isArray(savedPgIds) && savedPgIds.length > 0 && product_groups.size > 0) {
        // กรณีมีการเซฟ setting และ pg มีมากกว่า 1  ถ้ากรณี product_groups มีกลุ่มเดียวควรจะต้องโชว์ราคาเสมอ
        isValidSavePgIds = []
        for (let i = 0; i < savedPgIds.length; i++) {
          const oldPgId = savedPgIds[i]
          const foundInPgGroupIndex = product_groups.findIndex((pg) => Map.isMap(pg) && pg.get('id') === oldPgId)
          if (foundInPgGroupIndex > -1) {
            isValidSavePgIds.push(oldPgId)
          }
        }
      } else {
        isValidSavePgIds = []
        product_groups.forEach((pg) => {
          if (Map.isMap(pg) && pg.get('id')) {
            isValidSavePgIds.push(pg.get('id'))
          }
        })
      }

      await this._onChangeProductGroups(isValidSavePgIds) // ค่าเริ่มต้น pg
      await xUtil.delay(100)

      // log(' this._onChangeProductGroups(isValidSavePgIds) => ', isValidSavePgIds)
      this.isInitProductGroup = true
      this._shouldForceUpdateAfterComputed = true
    }
  }

  _initWarehouseIdsOnAddProductMode = async (prevProps?: IProductViewProps, prevState?: IProductViewState): Promise<void> => {
    const { selectedStore } = this.props
    const { mode } = this.state
    if (this.isInitProductWarehouses || !selectedStore || !selectedStore.get('id') || !_.includes([ADD], mode)) {
      return
    }

    const isForceUpdate = !prevProps && !prevState
    const isChangeSelectedStore = prevProps && this.props && prevProps.selectedStore !== this.props.selectedStore

    if (isForceUpdate || isChangeSelectedStore) {
      // คิดค่า default ของ warehouse เฉพาะ ADD PRODUCT MODE เท่านั้น
      const storeWarehouses = selectedStore.get('warehouses') || List([])
      const savedWhIds = await p.op.getAppDefaultValue(this.DEFAULT_PRODUCT_SELECTED_WH_IDS_KEY)
      let isValidSaveWhIds
      if (storeWarehouses.size === 1) {
        // [Most case] คือมีกัน warehouse เดียว
        const onlyOneWhId = storeWarehouses.getIn([0, 'id'])
        isValidSaveWhIds = [onlyOneWhId]
      } else if (_.isArray(savedWhIds) && savedWhIds.length > 0 && storeWarehouses.size > 0) {
        // กรณีมีการเซฟ setting และ warehouse มีมากกว่า 1 ถ้ากรณี storeWarehouses มีกลุ่มเดียวควรจะต้องโชว์ warehouse
        isValidSaveWhIds = []
        for (let i = 0; i < savedWhIds.length; i++) {
          const oldWhId = savedWhIds[i]
          const foundInWhIdIndex = storeWarehouses.findIndex((wh) => Map.isMap(wh) && wh.get('id') === oldWhId)
          if (foundInWhIdIndex > -1) {
            isValidSaveWhIds.push(oldWhId)
          }
        }
      } else {
        isValidSaveWhIds = []
        storeWarehouses.forEach((wh) => {
          if (Map.isMap(wh) && wh.get('id')) {
            isValidSaveWhIds.push(wh.get('id'))
          }
        })
      }

      await this.onChangeProductWarehouseIds(isValidSaveWhIds) // ค่าเริ่มต้น wh

      this._shouldForceUpdateAfterComputed = true
    }
  }

  _updateProductStaticConfigs = async (prevProps?: IProductViewProps, prevState?: IProductViewState): Promise<void> => {
    const { selectedStore } = this.props
    if (!selectedStore || !selectedStore.get('id')) {
      return
    }

    // // Clear Input reference for support keyboard navigator
    // if (_.isArray(this.inputRefs) && this.inputRefs.length > 0) {
    //   this.inputRefs = []
    // }

    const isForceUpdate = !prevProps && !prevState
    const isChangeSelectedStore = (!prevProps && !prevState) || prevProps.selectedStore !== this.props.selectedStore
    if (isChangeSelectedStore || isForceUpdate) {
      // Initialize Current Helper Permissions
      this.canDoAtSelectedStore[PRODUCT_COST] = xAcl.canDoAtSelectedStore(PRODUCT_COST)
      this.canDoAtSelectedStore[PRODUCT_PRICE] = xAcl.canDoAtSelectedStore(PRODUCT_PRICE)
      // this.canDoAtSelectedStore[PRODUCT_STOCK] = xAcl.canDoAtSelectedStore(PRODUCT_STOCK)
      this.canDoAtSelectedStore[PRODUCT_STOCK_VIEW] = xAcl.canDoAtSelectedStore(PRODUCT_STOCK_VIEW)
      this.canDoAtSelectedStore[PRODUCT_STOCK_EDIT] = xAcl.canDoAtSelectedStore(PRODUCT_STOCK_EDIT)
      this.canDoAtSelectedStore[PRODUCT_WEIGHT] = xAcl.canDoAtSelectedStore(PRODUCT_WEIGHT)
      this.canDoAtSelectedStore[PRODUCT_SHIPPING_QTY_RATE] = xAcl.canDoAtSelectedStore(PRODUCT_SHIPPING_QTY_RATE)
      this.canDoAtSelectedStore[PRODUCT_CODE_EDIT_SKU] = xAcl.canDoAtSelectedStore(PRODUCT_CODE_EDIT_SKU)
      this.canDoAtSelectedStore[PRODUCT_CODE_EDIT_UPC] = xAcl.canDoAtSelectedStore(PRODUCT_CODE_EDIT_UPC)
      log('this.canDoAtSelectedStore => ', this.canDoAtSelectedStore)

      // Initialize ShippingRate
      this.canAutoCalcShippingRateByQty =
        getLocalConfigsInSelectedStore(selectedStore, xCONS.SELECTED_STORE_LOCAL_CONFIGS.CAN_AUTO_CALC_SHIPPING_RATE_BY_QTY) || false
      this.canAutoCalcShippingRateByWeight =
        getLocalConfigsInSelectedStore(selectedStore, xCONS.SELECTED_STORE_LOCAL_CONFIGS.CAN_AUTO_CALC_SHIPPING_RATE_BY_WEIGHT) || false
      // await new Promise(forceRerender => this.forceUpdate(forceRerender))
      // log('finish _updateProductStaticConfigs')
      this._shouldForceUpdateAfterComputed = true
    }
  }

  // _getProductVariantItems = (params: IGetProductVariantParams): IProductVariantProperty[] => {
  _getProductVariantItems = (): IProductVariantProperty[] => {
    // let usageVariants: Array<any> = []
    // const {
    //   mode,
    //   // parent_id = null,
    //   // s_use_retail_price = false,
    //   // order_use_retail_price = 0,
    // } = params
    const { mode } = this.state

    // log('_getProductVariantItems params => ', params)

    const { selectedStore, editingProduct, selectedProductGroups } = this.props
    // const s_use_retail_price = selectedStore.get('s_use_retail_price')
    // const s_use_retail_price = 0 // deprecated
    // const product_groups = List.isList(selectedStore.get('product_groups'))
    const product_groups = List.isList(selectedProductGroups) ? selectedProductGroups : List([])
    const product_group_ids = List.isList(editingProduct.get('product_group_ids')) ? editingProduct.get('product_group_ids') : List([])
    const parent_id = editingProduct.get('parent_id')

    const warehouses = selectedStore.get('warehouses')
    const wh_ids = editingProduct.get('warehouse_ids') || List([])
    log('wh_ids => ', wh_ids.toJS())
    log('warehouses => ', warehouses.toJS())

    let productVariantKeys = []
    const my_store_id = selectedStore.get('id') === editingProduct.get('store_id')
    // Set Text Label with Condition
    // this.PRODUCT_VARIANTS_TEMPLATE[PRICE].label = s_use_retail_price ? 'ราคาขายส่ง/ตัวแทน' : 'ราคา'
    this.PRODUCT_VARIANTS_TEMPLATE[PRICE].label = 'ราคา'
    this.PRODUCT_VARIANTS_TEMPLATE[QTY].label = _.includes([ADD, VIEW_SELF, EDIT_SELF, VIEW_SELLER, EDIT_SELLER], mode) ? 'คลัง' : 'จำนวน'
    // Generate variants array with Condition
    switch (mode) {
      case VIEW_SELF:
        productVariantKeys = [NAME, COST, PRICE]
        if (_.isNil(parent_id)) {
          productVariantKeys.push(WEIGHT)
          productVariantKeys.push(QTY)
        }
        productVariantKeys.push(AVAILABLE_QTY)
        break
      case VIEW_SELLER:
        productVariantKeys = [NAME, PRICE, AVAILABLE_QTY]
        break
      case EDIT_SELF:
        // ถ้าเป็น mode สำหรับดู และ มี parent_id แสดงว่าโดนดึงมา จะไม่เห็น qty, weight
        productVariantKeys = [NAME, COST, PRICE]

        if (!parent_id) {
          productVariantKeys = productVariantKeys.concat([WEIGHT])
        }
        if (xUtil.canHelperEditProductStock()) {
          productVariantKeys.push(QTY)
        }
        break
      // case EDIT_SELLER: // DO NOT USE FOR NOW
      //
      //   break
      case PULL_PRODUCT:
        productVariantKeys = [NAME, COST, PRICE, AVAILABLE_QTY]
        if (my_store_id) {
          productVariantKeys.push(WEIGHT)
        }
        break
      case PULL_MY_PRODUCT_TO_ORDER:
        productVariantKeys = [NAME, COST, PRICE]
        if (!parent_id) {
          // ถ้าเป็นสินค้าร้านฉันให้แสดงน้ำหนักและคลังด้วยด้วย
          productVariantKeys.push(WEIGHT)
        }
        if (APP_CONFIG.product.show_non_stock_qty) {
          productVariantKeys.push(AVAILABLE_QTY)
        }
        productVariantKeys.push(QTY)
        break
      case PULL_SELLER_PRODUCT_TO_ORDER:
        productVariantKeys = [NAME, PRICE, QTY]
        // ถ้าไม่ใช่สินค้าของเรา และ config ไว้ไม่ให้แสดง คลังพร้อมขาย
        if (APP_CONFIG.product.show_non_stock_qty) {
          productVariantKeys.push(AVAILABLE_QTY)
        }
        break
      case ADD:
        productVariantKeys = [NAME, COST, QTY, WEIGHT, PRICE]
        break
      case VIEW_VOLUME_DISCOUNT:
        productVariantKeys = [NAME, COST, PRICE, QTY, AVAILABLE_QTY]
        if (_.isNil(parent_id)) {
          productVariantKeys.push(WEIGHT)
        }
        break
      default:
        productVariantKeys = [NAME, COST, PRICE, QTY]
    }

    // SKU / UPC
    // const isVisibleUPC = !(mode === EDIT_SELF && !this.canDoAtSelectedStore[PRODUCT_CODE_EDIT_UPC])
    const isStoreUseUPC = selectedStore.get(COMPUTED_USE_PRODUCT_UPC) || false
    // const isVisibleUPC =
    //   (_.includes(
    //     [VIEW_SELF, EDIT_SELF, PULL_PRODUCT, PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER, VIEW_SELLER],
    //     mode,
    //   ) &&
    //     isStoreUseUPC) ||
    //   (isStoreUseUPC && mode === ADD && this.canDoAtSelectedStore[PRODUCT_CODE_EDIT_UPC])
    const isVisibleUPC = !(mode === ADD && !isStoreUseUPC)
    if (isVisibleUPC) {
      productVariantKeys.push(UPC)
    }

    // const isVisibleSKU = !(
    //   (mode === EDIT_SELF && !this.canDoAtSelectedStore[PRODUCT_CODE_EDIT_SKU]) ||
    //   _.includes([PULL_PRODUCT, PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER, VIEW_SELLER], mode)
    // )
    const isStoreUseSKU = selectedStore.get(COMPUTED_USE_PRODUCT_SKU) || false
    const isVisibleSKU =
      (isStoreUseSKU && _.includes([VIEW_SELF, EDIT_SELF, PULL_PRODUCT, PULL_MY_PRODUCT_TO_ORDER, VIEW_SELLER], mode)) ||
      (isStoreUseSKU && mode === ADD && this.canDoAtSelectedStore[PRODUCT_CODE_EDIT_SKU])
    if (isVisibleSKU) {
      productVariantKeys.push(SKU)
    }

    // Deprecated in pricing 2.0
    // if (s_use_retail_price) {
    //   // index of price to overwrite with retail price
    //   const foundIndex = productVariantKeys.findIndex(vKey => vKey === PRICE)
    //   if (_.includes([PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER], mode)) {
    //     // if (order_use_retail_price) {
    //     //   // productVariantKeys[foundIndex] = this.PRODUCT_VARIANTS_TEMPLATE[PRICE_RETAIL]
    //     //   productVariantKeys[foundIndex] = PRICE_RETAIL
    //     // }
    //   } else if (_.includes([VIEW_SELF, EDIT_SELF], mode)) {
    //       // productVariantKeys.splice(foundIndex + 1, 0, this.PRODUCT_VARIANTS_TEMPLATE[PRICE_RETAIL])
    //       productVariantKeys.splice(foundIndex + 1, 0, PRICE_RETAIL)
    //   } else {
    //     // View Seller's products case must be unseen retail price
    //   }
    // }

    if (this._isHelperUser() && !this.canDoAtSelectedStore[PRODUCT_COST]) {
      if (!this.canDoAtSelectedStore[PRODUCT_COST]) {
        const removedCostIndex = productVariantKeys.findIndex((vKey) => vKey === COST)
        if (removedCostIndex > -1) {
          productVariantKeys.splice(removedCostIndex, 1)
        }
      }

      if (!this.canDoAtSelectedStore[PRODUCT_WEIGHT]) {
        const removedWeightIndex = productVariantKeys.findIndex((vKey) => vKey === WEIGHT)
        if (removedWeightIndex > -1) {
          productVariantKeys.splice(removedWeightIndex, 1)
        }
      }

      if (_.includes([VIEW_SELF], mode) && !this.canDoAtSelectedStore[PRODUCT_STOCK_VIEW]) {
        const removedQtyIndex = productVariantKeys.findIndex((vKey) => vKey === QTY)
        if (removedQtyIndex > -1) {
          productVariantKeys.splice(removedQtyIndex, 1)
        }
      }

      if (_.includes([ADD, EDIT_SELF], mode) && !this.canDoAtSelectedStore[PRODUCT_STOCK_EDIT]) {
        const removedQtyIndex = productVariantKeys.findIndex((vKey) => vKey === QTY)
        if (removedQtyIndex > -1) {
          productVariantKeys.splice(removedQtyIndex, 1)
        }
      }
    }

    // เริ่มทำ dynamic รายการราคา / รายการคลัง
    if (_.includes([ADD, VIEW_SELF, EDIT_SELF, PULL_PRODUCT], mode)) {
      // log('productVariantKeys mode === ADD => ', productVariantKeys)
      if (List.isList(product_groups) && product_groups.size >= 1) {
        const removedPriceIndex = productVariantKeys.findIndex((vKey) => vKey === PRICE)
        if (removedPriceIndex > -1) {
          productVariantKeys.splice(removedPriceIndex, 1)
          // log('removeIndex => ', removeIndex)
          // log('productVariantKeys => ', productVariantKeys)
        }

        for (let i = 0; i < product_group_ids.size; i++) {
          const pgId = product_group_ids.get(i)
          if (pgId) {
            const foundProductGroup = product_groups.find((fpg) => Map.isMap(fpg) && fpg.get('id') === pgId)
            if (!foundProductGroup || !Map.isMap(foundProductGroup)) {
              continue
            }
            const pgName = foundProductGroup.get('name')
            // const pgName = product_groups.getIn([i, 'name'])
            // log('productVariantKeys i => ', i)
            // log('productVariantKeys pgId => ', pgId)
            // log('productVariantKeys pgName => ', pgName)

            const dynamicPriceKey = `price_${pgId}`
            if (!_.has(this.PRODUCT_VARIANTS_TEMPLATE, dynamicPriceKey)) {
              this.PRODUCT_VARIANTS_TEMPLATE[dynamicPriceKey] = _.cloneDeep(this.PRODUCT_VARIANTS_TEMPLATE[PRICE])
              this.PRODUCT_VARIANTS_TEMPLATE[dynamicPriceKey].key = dynamicPriceKey
              this.PRODUCT_VARIANTS_TEMPLATE[dynamicPriceKey].label = `${pgName}`
            }
            // productVariantKeys.push(dynamicPriceKey)
            if (removedPriceIndex > -1) {
              const newPriceIndex = removedPriceIndex + i
              productVariantKeys.splice(newPriceIndex, 0, dynamicPriceKey)
            } else {
              productVariantKeys.push(dynamicPriceKey)
            }
          }
        }

        // for (let i = 0; i < product_groups.size; i++) {
        //   // const pgId = parseInt(product_group_ids.get(i))
        //   // const pg = product_groups.find(pg => parseInt(pg.get('id')) === pgId)
        //   // const pgName = pg.get('name')
        //   const pgId = product_groups.getIn([i, 'id'])
        //   if (pgId) {
        //     const pgName = product_groups.getIn([i, 'name'])
        //     const dynamicPriceKey = `price_${pgId}`
        //     if (!_.has(this.PRODUCT_VARIANTS_TEMPLATE, dynamicPriceKey)) {
        //       this.PRODUCT_VARIANTS_TEMPLATE[dynamicPriceKey] = _.cloneDeep(this.PRODUCT_VARIANTS_TEMPLATE[PRICE])
        //       this.PRODUCT_VARIANTS_TEMPLATE[dynamicPriceKey].key = dynamicPriceKey
        //       this.PRODUCT_VARIANTS_TEMPLATE[dynamicPriceKey].label = `${pgName}`
        //     }
        //     productVariantKeys.push(dynamicPriceKey)
        //   }
        // }
      }
      const isCanSeeProductStock = xUtil.canHelperEditProductStock() && mode === EDIT_SELF
      // รายการคลัง
      if (List.isList(wh_ids) && wh_ids.size >= 0 && isCanSeeProductStock) {
        const removedQtyIndex = productVariantKeys.findIndex((vKey) => vKey === QTY)
        if (removedQtyIndex > -1) {
          productVariantKeys.splice(removedQtyIndex, 1)
        }

        for (let i = 0; i < wh_ids.size; i++) {
          const whId = wh_ids.get(i)
          if (whId) {
            const foundWarehouse = getWarehousesDetail(warehouses, whId)
            log('foundWarehouse => ', foundWarehouse)

            if (!foundWarehouse || !Map.isMap(foundWarehouse)) {
              continue
            }
            const whDisplayName = getWarehouseDisplayName(warehouses, foundWarehouse, i)

            const dynamicQtyKey = `qty_${whId}`
            log('dynamicQtyKey => ', dynamicQtyKey)
            if (!_.has(this.PRODUCT_VARIANTS_TEMPLATE, dynamicQtyKey)) {
              this.PRODUCT_VARIANTS_TEMPLATE[dynamicQtyKey] = _.cloneDeep(this.PRODUCT_VARIANTS_TEMPLATE[QTY])
              this.PRODUCT_VARIANTS_TEMPLATE[dynamicQtyKey].key = dynamicQtyKey
              this.PRODUCT_VARIANTS_TEMPLATE[dynamicQtyKey].label = whDisplayName
            }
            // productVariantKeys.push(dynamicPriceKey)
            if (removedQtyIndex > -1) {
              const newQtyIndex = removedQtyIndex + i
              productVariantKeys.splice(newQtyIndex, 0, dynamicQtyKey)
            } else {
              productVariantKeys.push(dynamicQtyKey)
            }
          }
        }
      }
    }

    const NEW_PRODUCT_VARIANT_ITEMS = []
    // log('NEW_PRODUCT_VARIANT_ITEMS => ', NEW_PRODUCT_VARIANT_ITEMS)
    for (let i = 0; i < productVariantKeys.length; i++) {
      const key = productVariantKeys[i]
      // log('NEW_PRODUCT_VARIANT_ITEMS key => ', key)
      const productVariantItem = this.PRODUCT_VARIANTS_TEMPLATE[key]
      // log('NEW_PRODUCT_VARIANT_ITEMS productVariantItem => ', productVariantItem)
      NEW_PRODUCT_VARIANT_ITEMS.push(productVariantItem)
    }
    log('NEW_PRODUCT_VARIANT_ITEMS => ', NEW_PRODUCT_VARIANT_ITEMS)
    return NEW_PRODUCT_VARIANT_ITEMS

    // // Old logic
    // // Set Text Label with Condition
    // this.PRODUCT_VARIANTS[2].label = s_use_retail_price ? 'ราคาขายส่ง/ตัวแทน' : 'ราคา',
    // this.PRODUCT_VARIANTS[3].label = _.includes([ADD, VIEW_SELF, EDIT_SELF, VIEW_SELLER, EDIT_SELLER], mode) ? 'คลัง' : 'จำนวน'
    //
    // // Generate variants array with Condition
    // if (_.includes([VIEW_SELF, EDIT_SELF, VIEW_SELLER, EDIT_SELLER], mode)) {
    //   // ถ้าเป็น mode สำหรับดู และ มี parent_id แสดงว่าโดนดึงมา จะไม่เห็น qty
    //   if (_.includes([VIEW_SELF], mode) && !parent_id) {
    //     pushArrayByArray(this.PRODUCT_VARIANTS, usageVariants, [0, 1, 2, 3, 4, 5])
    //   } else if (_.includes([EDIT_SELF], mode) && !parent_id) {
    //     pushArrayByArray(this.PRODUCT_VARIANTS, usageVariants, [0, 1, 2, 3, 5])
    //     // pushArrayByArray(this.PRODUCT_VARIANTS, usageVariants, [0, 1, 2, 3, 4])
    //   } else if (_.includes([VIEW_SELLER], mode)) {
    //     pushArrayByArray(this.PRODUCT_VARIANTS, usageVariants, [0, 2, 4])
    //   } else {
    //     pushArrayByArray(this.PRODUCT_VARIANTS, usageVariants, [0, 1, 2, 4])
    //   }
    // } else if (_.includes([PULL_PRODUCT], mode)) {
    //   pushArrayByArray(this.PRODUCT_VARIANTS, usageVariants, [0, 4, 1, 2])
    // } else if (_.includes([PULL_MY_PRODUCT_TO_ORDER], mode) && parent_id) {
    //   pushArrayByArray(this.PRODUCT_VARIANTS, usageVariants, [0, 1, 2, 4, 3])
    //   // pushArrayByArray(this.PRODUCT_VARIANTS, usageVariants, [0, 4, 1, 2, 3])
    // }  else if (_.includes([PULL_MY_PRODUCT_TO_ORDER], mode) && !parent_id) {
    //   // ถ้าเป็นสินค้าร้านฉันให้แสดงน้ำหนักด้วย
    //   pushArrayByArray(this.PRODUCT_VARIANTS, usageVariants, [0, 1, 2, 4, 3, 5])
    //   // pushArrayByArray(this.PRODUCT_VARIANTS, usageVariants, [0, 4, 1, 2, 3])
    // } else if (_.includes([PULL_SELLER_PRODUCT_TO_ORDER], mode)) {
    //   pushArrayByArray(this.PRODUCT_VARIANTS, usageVariants, [0, 2, 4, 3])
    //   // pushArrayByArray(this.PRODUCT_VARIANTS, usageVariants, [0, 4, 1, 2, 3])
    // } else if (_.includes([ADD], mode)) {
    //   pushArrayByArray(this.PRODUCT_VARIANTS, usageVariants, [0, 1, 2, 3, 5])
    // } else {
    //   pushArrayByArray(this.PRODUCT_VARIANTS, usageVariants, [0, 1, 2, 4, 3])
    // }
    //
    // if (s_use_retail_price) {
    //   // index of price to overwrite with retail price
    //   const foundIndex = usageVariants.findIndex(v => v.key === 'price')
    //   if (_.includes([PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER], mode)) {
    //     if (order_use_retail_price) {
    //       usageVariants[foundIndex] = this.PRODUCT_VARIANTS[6]
    //     }
    //   } else if (_.includes([VIEW_SELF, EDIT_SELF], mode)) {
    //     usageVariants.splice(foundIndex + 1, 0, this.PRODUCT_VARIANTS[6])
    //   } else {
    //     // View Seller's products case must be unseen retail price
    //   }
    // }

    // return usageVariants
  }

  _isHelperUser = (): boolean => {
    const { selectedStore } = this.props
    return Map.isMap(selectedStore) && selectedStore.has('role_id') ? selectedStore.get('role_id') === xCONS.STORE_ROLE.HELPER : false
  }

  _isEditableProduct = (newMode: string = null): Map<string, boolean> => {
    const { selectedProduct, selectedStore } = this.props
    const params = this.state
    const mode = newMode || params.mode

    if (!Map.isMap(selectedProduct) || !Map.isMap(selectedStore)) {
      return Map({})
    }

    const editableList = selectedProduct.get('editable') || List([])
    const isAddMode = mode === ADD
    const isEditMode = _.includes([EDIT_SELF, EDIT_SELLER], mode)
    const isViewMode = _.includes([VIEW_SELF, VIEW_SELLER], mode)

    if (!mode || isViewMode || (isEditMode && editableList.size === 0)) {
      return Map({
        name: false,
        description: false,
        img_uris: false,
        thumbnail_uris: false,
        tiny_img_uris: false,
        shipping_rates: false,
        category_id: false,
        product_group_ids: false,
      })
    }

    // let isEditMode = _.includes([EDIT_SELF, EDIT_SELLER], mode) || false
    let editableName = isAddMode
    let editableDesc = isAddMode
    let editableImg = isAddMode
    let editableThumbnail = isAddMode
    let editableTiny = isAddMode
    const editableCategory = isAddMode
    let editableProductGroupIds = false
    // , editableShippingRates

    const editableShippingRatesMy = this.canAutoCalcShippingRateByQty && _.includes([ADD, EDIT_SELF, EDIT_SELLER, PULL_PRODUCT], mode)

    if (editableList && isImmutable(editableList)) {
      if (_.includes([EDIT_SELF, EDIT_SELLER], mode)) {
        editableName = editableList.includes('name')
        editableDesc = editableList.includes('description')
        editableImg = editableList.includes('img_uris')
        editableThumbnail = editableImg
        editableTiny = editableImg
        // editableThumbnail = editableList.includes('thumbnail_uris')
        // editableTiny = editableList.includes('tiny_img_uris')
        // currently not allow editing shipping rates if it's a pulled product
      } else if (_.includes([PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER], mode)) {
        editableDesc = editableImg = false
      } else if (_.includes([PULL_PRODUCT], mode)) {
        editableName = editableDesc = editableImg = true
      }
    }

    const product_groups = selectedStore.get('product_groups') || List([])
    editableProductGroupIds = product_groups.size > 1 && _.includes([ADD, EDIT_SELF, PULL_PRODUCT], mode)

    return Map({
      name: editableName,
      description: editableDesc,
      img_uris: editableImg,
      thumbnail_uris: editableThumbnail,
      tiny_img_uris: editableTiny,
      shipping_rates: editableShippingRatesMy,
      category_id: editableCategory,
      product_group_ids: editableProductGroupIds,
    })
  }

  _isEditableProductVariants = (newMode: string = null): Map<string, boolean> => {
    const { selectedProduct } = this.props
    const params = this.state
    const mode = newMode || params.mode
    const editable = selectedProduct.has('editable') ? selectedProduct.get('editable') : List([])

    // const VARIANT_KEYS = ['name', 'cost', 'price', 'price_retail', 'qty', 'available_qty', 'weight']
    const VARIANT_KEYS = Object.keys(this.PRODUCT_VARIANTS_TEMPLATE_INIT)
    // log('_isEditableProductVariants VARIANT_KEYS => ', VARIANT_KEYS)
    const isEditableMap: { [key: string]: boolean } = {}

    const isAddMode = mode === ADD
    const isEditMode = _.includes([EDIT_SELLER, EDIT_SELF], mode)
    const isViewMode = _.includes([VIEW_SELF, VIEW_SELLER], mode)

    if (!mode || isViewMode || (isEditMode && editable.size === 0)) {
      VARIANT_KEYS.forEach((vKey) => {
        isEditableMap[vKey] = false
      })
      return Map(isEditableMap)
    }

    VARIANT_KEYS.forEach((vKey) => {
      isEditableMap[vKey] = isAddMode // ถ้าเป็นโหมด add init เป็น true เลย
    })

    if (editable && _.includes([EDIT_SELF, EDIT_SELLER], mode)) {
      VARIANT_KEYS.forEach((vKey) => {
        if (editable.includes(`v_${vKey}`)) {
          isEditableMap[vKey] = true
        }
      })
    }

    // FIXME: MOCK FOR DEMO SKU
    if (this.canDoAtSelectedStore[PRODUCT_CODE_EDIT_UPC]) {
      const parent_store_name = this.props.editingProduct.get('parent_store_name')
      const s_use_product_barcode = this.props.selectedStore.get('s_use_product_barcode')
      if (_.isNil(parent_store_name)) {
        if (s_use_product_barcode === 1 || s_use_product_barcode === 3) {
          isEditableMap[UPC] = true
        } else {
          isEditableMap[UPC] = false
        }
      } else {
        isEditableMap[UPC] = false
      }
    }
    if (this.canDoAtSelectedStore[PRODUCT_CODE_EDIT_SKU]) {
      isEditableMap[SKU] = true
    }

    // FIXME: Real code logic after extracted editable from backend
    // if (isEditMode &&  isEditableMap[UPC]) {
    //   isEditableMap[UPC] = isEditableMap[UPC] && this.canDoAtSelectedStore[PRODUCT_CODE_EDIT_UPC]
    // }
    // if (isEditMode &&  isEditableMap[SKU]) {
    //   isEditableMap[SKU] = isEditableMap[SKU] && this.canDoAtSelectedStore[PRODUCT_CODE_EDIT_SKU]
    // }

    if (_.includes([PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER], mode)) {
      // isEditableMap['qty'] = editable.includes('v_qty')
      isEditableMap.qty = true
    } else if (_.includes([PULL_PRODUCT], mode)) {
      // isEditableMap['price'] = editable.includes('v_price')
      isEditableMap.price = true
    }
    return Map(isEditableMap)
  }

  _computeEditable = (newMode: string = null): void => {
    this.isEditableProductMap = this._isEditableProduct(newMode)
    this.isEditableProductVariantsMap = this._isEditableProductVariants(newMode)
    this._shouldForceUpdateAfterComputed = true
    // await new Promise(computeDelay => setTimeout(computeDelay, 200))
    // log('_computeEditable this.isEditableProductMap.toJS() => ', this.isEditableProductMap.toJS())
    // log('_computeEditable this.isEditableProductVariantsMap.toJS() => ', this.isEditableProductVariantsMap.toJS())
  }

  _initializeComponentDidMount = async (): Promise<void> => {
    const { fetchMyStore, onChangeProduct, onChangeProductGroups, navigation } = this.props
    // const navParams = this._getParams()
    // const navParams = navigation.state.params
    const navParams = xUtil.getNavParams(this.props)
    if (!navParams || !navParams.store_id || !_.includes(xCONS.PRODUCT_VIEW_MODE, navParams.mode)) {
      await this._cannotInitialize()
      return
    }

    await setStatePromise(this, { ...navParams })
    await xUtil.delay(100)
    const { mode, store_id } = this.state
    if (store_id && !this.props.selectedStore.get('id')) {
      const body = { store_id }
      const isFetched = await new Promise<boolean>((resolveIsFetch) => {
        this.props.fetchMyStore({
          body,
          successCallback: () => resolveIsFetch(true),
          failedCallback: () => resolveIsFetch(false),
        })
      })

      if (!isFetched) {
        await this._cannotInitialize()
        return
      }
    }

    // Fetching Product if needed
    // await this._fetchProduct()
    // await this._onRefresh()

    // this.s_use_retail_price = this.props.selectedStore.get('s_use_retail_price') || false
    // this.order_use_retail_price = order_use_retail_price || 0

    // Initialize async default options
    if (mode === ADD) {
      await p.op.getAppDefaultValue(this.DEFAULT_PRODUCT_CATEGORY_KEY).then((value) => {
        if (value !== null) {
          onChangeProduct({ key: 'category_id', value })
        }
      })
    } else if (!_.includes([ADD, EDIT_SELF, EDIT_SELLER], mode)) {
      if (!this.state.skipInitFetchDetail) {
        await this._onRefresh()
      }
    }

    // Initialize ProductView Configs
    await this._computeEditable(mode)
    await this._updateProductStaticConfigs()
    await this._initProductGroupIdsOnAddOrPullProductMode()
    await this._initWarehouseIdsOnAddProductMode()
    await this._updateProductVariantKeysInDidUpdate()
    // await this._updateProductVariantRenderCombinations()

    await this._initializeComponentDidMountExtended()

    await this._updateHamburgerMenuOptions()
    await setStatePromise(this, { isInitialized: true })
  }

  _cannotInitialize = async (): Promise<void> => {
    p.op.alert('ไม่พบร้านค้า', 'กรุณาเลือกร้านค้าก่อนสร้างสินค้า')
    await this._cannotInitializeExtended()
  }

  _onRefresh = async (): Promise<void> => {
    await setStatePromise(this, { refreshing: true })
    await this._fetchProduct()
    await setStatePromise(this, { refreshing: false })
  }

  _fetchProduct = async (): Promise<void> => {
    const { fetchProduct, selectedStore } = this.props
    const { mode, store_id, product_id, seller_store_id, ug_id = null, pg_ids = null } = this.state

    if (product_id && (store_id || seller_store_id)) {
      let targetStoreID = store_id
      let role_id = null
      if (_.includes([PULL_PRODUCT, PULL_SELLER_PRODUCT_TO_ORDER, VIEW_SELLER], mode) && seller_store_id) {
        // TODO: Check if there are more mode to check
        targetStoreID = seller_store_id
      } else {
        // passing the role id, so backend can distinguish if it's store's owner or helper
        role_id = selectedStore.has('role_id') ? selectedStore.get('role_id') : null
      }

      const body: any = { store_id: targetStoreID, product_id }
      // if (role_id) {
      //   body.role_id = role_id
      // }

      // backend require if role_id === 2 (helper)
      if (role_id === 2) {
        // ถ้าเป็นการ fetch สินค้าของร้านฉันให้ส่ง role_id ของ selectedStore ของฉันไป
        body.role_id = role_id
      }

      this.variantPagePpIdToIndex = {}

      const isFetched = await new Promise<boolean>((resolveIsFetch) => {
        fetchProduct({
          mode,
          user_group_id: ug_id,
          product_group_ids: pg_ids,
          body,
          successCallback: () => resolveIsFetch(true),
          failedCallback: () => resolveIsFetch(false),
        })
      })

      if (isFetched) {
        await this._handleAfterFetchedProduct()
      } else {
        p.op.alert('ไม่สามารถโหลดข้อมูลสินค้าได้', 'กรุณาลองโหลดใหม่อีกครั้ง')
      }
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.isUnmounting) {
  //     return
  //   }
  //
  //   // update permission list
  //   // this.canDoAtSelectedStore[PRODUCT_COST] = xAcl.canDoAtSelectedStore(PRODUCT_COST)
  //   // this.canDoAtSelectedStore[PRODUCT_PRICE] = xAcl.canDoAtSelectedStore(PRODUCT_PRICE)
  //   // this.canDoAtSelectedStore[PRODUCT_STOCK] = xAcl.canDoAtSelectedStore(PRODUCT_STOCK)
  //   // this.canDoAtSelectedStore[PRODUCT_WEIGHT] = xAcl.canDoAtSelectedStore(PRODUCT_WEIGHT)
  //   // this.canDoAtSelectedStore[PRODUCT_SHIPPING_QTY_RATE] = xAcl.canDoAtSelectedStore(PRODUCT_SHIPPING_QTY_RATE)
  //   // this.canAutoCalcShippingRateByQty = xUtil.canSelectedStoreAutoCalcShippingRateBy(xCONS.STORE_SETTINGS.CALC_SHIPPING_MEASURE_QTY)
  //   // this.canAutoCalcShippingRateByWeight = xUtil.canSelectedStoreAutoCalcShippingRateBy(xCONS.STORE_SETTINGS.CALC_SHIPPING_MEASURE_WEIGHT)
  //
  //   // log('this.canAutoCalcShippingRateByQty => ', this.canAutoCalcShippingRateByQty)
  //   // log('this.canAutoCalcShippingRateByWeight => ', this.canAutoCalcShippingRateByWeight)
  //
  //   this._extendComponentWillReceiveProps(nextProps)
  // }

  componentWillUnmount() {
    if (!this.isUnmounting) {
      this.isUnmounting = true
      // this.props.bpActions.pickerDelete(xCONS.PRODUCT_CATEGORY_KEY)
      this.props.selectedProductClear()
      this._extendedWillUnmount()
    }
  }

  // async _handleSubmitEditProduct(callback: () => void): Promise<void> {
  _handleSubmitEditProduct = async (): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    await this._beginSubmitting()

    // == BEGIN IMAGE MANAGER ==
    await this._beginUploading()
    const formValid = await this._validationProductForm()
    // log('_handleSubmitEditProduct formValid => ', formValid)
    await this._endUploading()
    if (!formValid) {
      // await this._endUploading()
      // this.inProcess = false
      await this._endSubmitting()
      return
    }
    const { selectedProduct, editingProduct, editProduct, selectedStore } = this.props
    const { mode } = this.state
    const oldP = selectedProduct
    const newP = editingProduct
    const body: { [key: string]: any } = {}

    // FIXME: Mock thumbnail as the same as image uris
    // Image diff (Old Image is Immutable / New Image is Plain Array)
    const oldImgs = isImmutable(oldP.get('img_uris')) ? oldP.get('img_uris').toJS() : oldP.get('img_uris')
    const oldThns = isImmutable(oldP.get('thumbnail_uris')) ? oldP.get('thumbnail_uris').toJS() : oldP.get('thumbnail_uris')
    const oldTns = isImmutable(oldP.get('tiny_img_uris')) ? oldP.get('tiny_img_uris').toJS() : oldP.get('tiny_img_uris')
    // const newImgs = isImmutable(newP.get('img_uris')) ? newP.get('img_uris').toJS() : newP.get('img_uris')
    // const newThns = isImmutable(newP.get('thumbnail_uris')) ? newP.get('thumbnail_uris').toJS() : newP.get('thumbnail_uris')
    // const newTns = isImmutable(newP.get('tiny_img_uris')) ? newP.get('tiny_img_uris').toJS() : newP.get('tiny_img_uris')
    const newImgs = formValid.img_uris
    const newThns = formValid.thumbnail_uris
    const newTns = formValid.tiny_img_uris
    // log('_handleSubmitEditProduct Image diff oldImgs => ', oldImgs)
    // log('_handleSubmitEditProduct Image diff newImgs => ', newImgs)

    // log('_handleSubmitEditProduct Image diff oldThns => ', oldThns)
    // log('_handleSubmitEditProduct Image diff newThns => ', newThns)

    // log('_handleSubmitEditProduct Image diff oldTns => ', oldTns)
    // log('_handleSubmitEditProduct Image diff newTns => ', newTns)
    if (newImgs.length > 0) {
      // force must have big Images
      if (oldImgs.length === newImgs.length) {
        for (let i = 0; i < newImgs.length; i++) {
          // log('Image diff  oldImgs[i] => ', oldImgs[i])
          // log('Image diff  newImgs[i] => ', newImgs[i])
          if (oldImgs[i] !== newImgs[i] || oldThns[i] !== newThns[i] || oldTns[i] !== newTns[i]) {
            body.img_uris = newImgs
            body.thumbnail_uris = newThns
            body.tiny_img_uris = newTns
            break
          }
        }
      } else {
        body.img_uris = newImgs
        body.thumbnail_uris = newThns
        body.tiny_img_uris = newTns
      }
    } else {
      p.op.alert('คำเตือน', 'ระบบไม่อนุญาตให้เว้นว่าง "ภาพสินค้า"')
      this.inProcess = false
      await this._endSubmitting()
      return
    }

    const diffStringChecks = [NAME, 'description']
    const diffStringDisplays = ['ชื่อสินค้า', 'คำอธิบายสินค้า']

    for (let i = 0; i < diffStringChecks.length; i++) {
      const key = diffStringChecks[i]
      const oldValue = oldP.get(key).trim()
      const newValue = newP.get(key).trim() || ''
      const warningText = diffStringDisplays[i]
      if (newValue.length > 0) {
        if (oldValue !== newValue) {
          body[key] = newValue
        }
      } else {
        p.op.alert('คำเตือน', `ระบบไม่อนุญาตให้เว้นว่าง "${warningText}"`)
        this.inProcess = false
        await this._endSubmitting()
        return
      }
    }

    // log('_handleSubmitEditProduct after validate name/desc/imgs body => ', body)

    // For Shipping Rate
    // TODO: Keng Finish handling the flag

    const newSRStatuses = newP.get('shippingRateStatuses')
    const newSRList = newP.get('shipping_rates')
    const oldSRList = oldP.get('shipping_rates')

    // log('oldSRList', oldSRList.toJS())
    // log('newSRList', newSRList.toJS())
    if (newSRStatuses && newSRList && oldSRList) {
      const oldSRMap = oldSRList.reduce((result, item) => result.set(item.get('shipping_type_id').toString(), item), Map())
      const newSRMap = newSRList.reduce((result, item) => result.set(item.get('shipping_type_id').toString(), item), Map())

      // log('newSRList', newSRList.toJS())
      // log('newSRMap', newSRMap.toJS())

      const toBeSubmittedSRList = []
      // 1) Prepare the new shipping list. If found disabled on UI, set the DELETE flag (case-sensitive)
      newSRStatuses.mapEntries((entry) => {
        const shippingTypeIdInt = parseInt(entry[0])
        const shippingTypeIdStr = shippingTypeIdInt.toString()
        const newSRJSObj = newSRMap.get(shippingTypeIdStr).toJS()
        // log('newSRJSObj')
        // log(newSRJSObj)
        // delete newSRJSObj.id
        // delete newSRJSObj.product_template_id
        if (newSRJSObj) {
          delete newSRJSObj.created_at
          delete newSRJSObj.updated_at
          // log('newSRJSObj after clean')
          // log(newSRJSObj)
        }
        const newSRStatus = entry[1]
        if (newSRStatus) {
          // 0 = key, 1 = val (true = set)
          // 2) Check if the shipping rates have been modified
          let isRateCreatedOrModified = false
          const oldSRStatus = oldP.get('shippingRateStatuses').get(shippingTypeIdStr)
          // a) Check if the enable status is changed
          if (oldSRStatus !== newSRStatus) {
            isRateCreatedOrModified = true
          } else {
            const oldSRJSObj = oldSRMap.get(shippingTypeIdInt.toString()).toJS()
            // b) Check if the old one exists
            if (oldSRJSObj) {
              // old one exists. Compare field-by-field if it's been modifed
              isRateCreatedOrModified =
                oldSRJSObj.init_qty !== newSRJSObj.init_qty ||
                oldSRJSObj.init_price !== newSRJSObj.init_price ||
                oldSRJSObj.next_price !== newSRJSObj.next_price
            } else {
              // it's newly created
              isRateCreatedOrModified = true
            }
          }

          if (isRateCreatedOrModified) {
            // add to the list as it has been modified
            toBeSubmittedSRList.push(newSRJSObj)
          }
        } else {
          // val = 0, unset the rate (delete)
          newSRJSObj.DELETE = 1
          toBeSubmittedSRList.push(newSRJSObj)
        }
      })

      if (toBeSubmittedSRList.length > 0) {
        body.shipping_rates = toBeSubmittedSRList
      }
    }

    // TODO: Keng
    // 2) Remove the not change rate
    /*
    // let newSRList = newP.get('shipping_rates')
    for (let i = 0; i < newSRList.size; i++) {
      let isEnabled = newSRStatuses.get(newSRList.get(i).get('shipping_type_id').toString())
      if (!isEnabled) {
        // set the DELETE flag
      }
    }

    // 1. find the deleted one
    for (let i = 0; i < oldSRList.size; i++) {
      oldSRList.get(i)
    }

    // 2. find the modified one
    // for (let i = 0; i < oldSR.size; i++) {
    //
    // }
    // oldP.get('shipping_rates')
    // if (newP.get('shipping_rates').)
    body['shipping_rates'] = newP.get('shipping_rates')
*/

    // For Variants Checking
    const hasParent = oldP.get('parent_id') || false
    const variants = []
    // let diffVariantChecks = [NAME, COST, PRICE, WEIGHT]
    // let diffVariantDisplays = ['ชื่อตัวเลือก', 'ต้นทุนสินค้า', 'ราคาสินค้า', 'น้ำหนัก (กรัม)']

    // By pKeng on 29 Jul 2019: Removed WEIGHT out of checking criteria as helper WILL NOT be able to edit product
    // if she has no permission "product_weight". UI won't send out the request at all.
    // const diffVariantChecks = [NAME, COST, WEIGHT]
    // const diffVariantDisplays = ['ชื่อตัวเลือก', 'ต้นทุนสินค้า', 'น้ำหนัก (กรัม)']
    const diffVariantChecks = [NAME, COST]
    const diffVariantDisplays = ['ชื่อตัวเลือก', 'ต้นทุนสินค้า']
    // KEEP_PG_WARNING_TEXT เอสไว้กรณีตรวจสอบว่า ถ้าราคาเป็น 0 จะต้องให้ถามก่อนบันทึก
    const KEEP_PG_WARNING_TEXT = []

    // For compute new pg's price fields should have:
    // log('_handleSubmitEditProduct editingProduct.toJS() => ', editingProduct.toJS())
    // log('_handleSubmitEditProduct selectedStore.toJS() => ', selectedStore.toJS())
    const product_groups = selectedStore.get('product_groups')
    const newPgIds = editingProduct.get('product_group_ids') || List([])
    const oldPgIds = oldP.get('product_group_ids') || List([])
    // log('_handleSubmitEditProduct product_groups.toJS() => ', product_groups.toJS())
    // log('_handleSubmitEditProduct product_group_ids.toJS() => ', product_group_ids.toJS())

    const warehouses = selectedStore.get('warehouses')
    const newWhIds = editingProduct.get('warehouse_ids') || List([])
    const oldWhIds = oldP.get('warehouse_ids') || List([])

    const pgPriceFields = []
    if (List.isList(product_groups) && List.isList(newPgIds) && newPgIds.size > 0) {
      for (let i = 0; i < newPgIds.size; i++) {
        const pgId = newPgIds.get(i)
        const pg = pgId ? product_groups.find((pgItem) => pgItem.get('id') === pgId) : null
        const pgName = Map.isMap(pg) && pg.get('name') ? pg.get('name') : null
        pgPriceFields.push(`${PRICE}_${pgId}`)
        diffVariantChecks.push(`${PRICE}_${pgId}`)
        diffVariantDisplays.push(pgName ? `ราคาสินค้าของ${pgName}` : 'ราคาสินค้า')
        KEEP_PG_WARNING_TEXT.push(pgName ? `ราคาสินค้าของ${pgName}` : 'ราคาสินค้า')
      }
    }
    // log('_handleSubmitEditProduct diffVariantChecks => ', diffVariantChecks)

    // if (!hasParent) {
    //   diffVariantChecks.push(QTY)
    //   diffVariantDisplays.push('จำนวนสินค้า')
    // }

    const whQtyFields = []
    if (List.isList(warehouses) && List.isList(newWhIds) && newWhIds.size > 0) {
      for (let i = 0; i < newWhIds.size; i++) {
        const whId = newWhIds.get(i)
        const wh = whId ? warehouses.find((fwh) => fwh.get('id') === whId) : null
        const whDisplayName = getWarehouseDisplayName(warehouses, wh, i)
        whQtyFields.push(`${QTY}_${whId}`)
        diffVariantChecks.push(`${QTY}_${whId}`)
        diffVariantDisplays.push(whDisplayName)
      }
    }

    const isStoreUseUPC = selectedStore.get(COMPUTED_USE_PRODUCT_UPC) || false
    if (isStoreUseUPC) {
      diffVariantChecks.push(UPC)
      diffVariantDisplays.push('SKU')
    }

    const isStoreUseSKU = selectedStore.get(COMPUTED_USE_PRODUCT_SKU) || false
    if (isStoreUseSKU) {
      diffVariantChecks.push(SKU)
      diffVariantDisplays.push('รหัสสินค้าภายใน')
    }

    const oldVariants = oldP.get('variants')
    // const newVariants = newP.get('variants')
    const newVariants = newP.get('variants').filter((v) => !v.get('isDeleted'))
    const newVariantsSize = newVariants.size

    // log('oldVariants diff  oldVariants => ', oldVariants.toJS())
    // log('newVariants diff  newVariants => ', newVariants.toJS())

    // เพิ่ม key WEIGHT เพราะว่ามีเหตุว่า เวลาแก้น้ำหนักแล้วบันทึกไม่ได้ 02/09/2019
    diffVariantChecks.push(WEIGHT)

    // เพิ่ม key img_url เพราะว่าเวลาแก้รูปภาพแล้วบันทึกไม่ได้ 29/03/2024
    diffVariantChecks.push('img_url')

    let isDiffVariants = false

    if (oldVariants.size !== newVariantsSize) {
      isDiffVariants = true
    } else {
      for (let i = 0; i < newVariants.size; i++) {
        // newVariants.forEach((v, index) => {
        const v = newVariants.get(i)
        for (let j = 0; j < diffVariantChecks.length; j++) {
          const key = diffVariantChecks[j]
          if (key === COST && !this.canDoAtSelectedStore[PRODUCT_COST]) {
            continue
          } else if (key.startsWith(PRICE) && !this.canDoAtSelectedStore[PRODUCT_PRICE]) {
            continue
          } else if (key.startsWith(QTY) && !this.canDoAtSelectedStore[PRODUCT_STOCK_EDIT]) {
            continue
          } else if (key === UPC && !this.canDoAtSelectedStore[PRODUCT_CODE_EDIT_UPC]) {
            continue
          } else if (key === SKU && !this.canDoAtSelectedStore[PRODUCT_CODE_EDIT_SKU]) {
            continue
          } else if (key === WEIGHT && !this.canDoAtSelectedStore[PRODUCT_WEIGHT]) {
            continue
          }

          const warningText = diffVariantDisplays[j]
          if (
            ((newVariantsSize === 1 && j > 0) || newVariantsSize > 1) &&
            (_.isNil(v.get(key)) || (!_.includes([EDIT_SELF, EDIT_SELLER], mode) && v.get(key).length === 0))
          ) {
            // If here value of variant should be empty string
            // เพิ่ม key img_url เพราะว่าเวลาแก้รูปภาพแล้วบันทึกไม่ได้ 29/03/2024
            if (_.includes([UPC, SKU, 'img_url'], key)) {
              // ignore check empty if UPC/SKU
              isDiffVariants = true
              continue
            }

            if (_.includes(KEEP_PG_WARNING_TEXT, warningText)) {
              const isUserConfirmed = await p.op.isUserConfirm(
                `พบการตั้ง${warningText} ฿0`,
                `กรุณายืนยันว่าจะให้จะขายสินค้า ${v.get(`name`)} ราคา ฿0 ใช่หรือไม่`
              )
              if (!isUserConfirmed) {
                await this._endSubmitting()
                return
              }
            } else {
              p.op.alert('ข้อมูลไม่ครบถ้วน', `กรุณาระบุ "${warningText}"`)
              await this._endSubmitting()
              return
            }

            // this.inProcess = false
          } else if (oldVariants.getIn([i, key]) !== v.get(key)) {
            isDiffVariants = true
          }
        }
        // })
      }
      // // Optional field check diff
      // // if (selectedStore.get('s_use_retail_price')) {
      // diffVariantChecks = [PRICE_RETAIL]
      // // diffVariantDisplays = ['ราคาปลีก']
      // for (let i = 0; i < newVariants.size ; i++) {
      //   const v = newVariants.get(i)
      //   for (let j = 0; j < diffVariantChecks.length; j++) {
      //     const key = diffVariantChecks[j]
      //     // const warningText = diffVariantDisplays[j]
      //     // log('Optional field check i => ', i)
      //     // log('Optional field check key => ', key)
      //     // log('Optional field check old => ', oldVariants.getIn([i, key]))
      //     // log('Optional field check new => ', v.get(key))
      //
      //     if ( (oldVariants.getIn([i, key]) !== v.get(key)) ||
      //       (!oldVariants.getIn([i, key]) && v.get(key)) ) {
      //       isDiffVariants = true
      //       break
      //     }
      //   }
      // }
      // // }
    }

    let isDiffPrices = false
    let isDiffVariantWarehouses = false

    // ถ้าหากจำนวน variants ต่างจากเดิม
    if (isDiffVariants) {
      const delete_PPIDs = oldVariants.map((v) => v.get('pp_id')).toJS() || []
      let editCount = 0
      let addCount = 0
      // log('oldVariants diff delete_PPIDs => ', delete_PPIDs)

      for (let index = 0; index < newVariants.size; index++) {
        // newVariants.forEach((v, index) => {
        const v = newVariants.get(index)
        const pp_id = v.get('pp_id') || null
        const newVariant: any = {}

        if (!_.isNull(pp_id)) {
          // const isDeleted = v.get('isDeleted') || false
          // const deleteIndex = isDeleted ? delete_PPIDs.indexOf(pp_id) : -1
          // // เปลี่ยนเป็น logic ด้านบนเนื่องจากแปลงวิธีการ remove variant item กลายเป็น soft-deleted แทน
          const deleteIndex = delete_PPIDs.indexOf(pp_id)
          if (deleteIndex > -1) {
            delete_PPIDs.splice(deleteIndex, 1)
            const foundIndex = oldVariants.findIndex((ov) => ov.get('pp_id') === pp_id)
            // newVariant[COST] = parseFloat(v.get(COST))
            // newVariant[PRICE] = parseFloat(v.get(PRICE))
            if (foundIndex > -1) {
              xUtil.appendObjectIfImmutableDataChange(oldVariants.get(foundIndex), v, newVariant, NAME, 'string')
              xUtil.appendObjectIfImmutableDataChange(oldVariants.get(foundIndex), v, newVariant, COST, 'float')
              // xUtil.appendObjectIfImmutableDataChange(oldVariants.get(foundIndex), v, newVariant, PRICE, 'float')
              // xUtil.appendObjectIfImmutableDataChange(oldVariants.get(foundIndex), v, newVariant, PRICE_RETAIL, 'float')
              // xUtil.appendObjectIfImmutableDataChange(oldVariants.get(foundIndex), v, newVariant, QTY, 'int')
              xUtil.appendObjectIfImmutableDataChange(oldVariants.get(foundIndex), v, newVariant, WEIGHT, 'int')
              xUtil.appendObjectIfImmutableDataChange(oldVariants.get(foundIndex), v, newVariant, SKU, 'string')
              xUtil.appendObjectIfImmutableDataChange(oldVariants.get(foundIndex), v, newVariant, UPC, 'string')

              // xUtil.appendObjectIfImmutableDataChange(oldVariants.get(foundIndex), v, newVariant, 'img_url', 'string')
              // xUtil.appendObjectIfImmutableDataChange(oldVariants.get(foundIndex), v, newVariant, 'thumbnail_url', 'string')
              // xUtil.appendObjectIfImmutableDataChange(oldVariants.get(foundIndex), v, newVariant, 'tiny_img_url', 'string')

              if (oldVariants.getIn([foundIndex, 'img_url']) && _.isNil(v.get('img_url'))) {
                newVariant.img_url = null
                newVariant.thumbnail_url = null
                newVariant.tiny_img_url = null
              } else {
                xUtil.appendObjectIfImmutableDataChange(oldVariants.get(foundIndex), v, newVariant, 'img_url', 'string')
                xUtil.appendObjectIfImmutableDataChange(oldVariants.get(foundIndex), v, newVariant, 'thumbnail_url', 'string')
                xUtil.appendObjectIfImmutableDataChange(oldVariants.get(foundIndex), v, newVariant, 'tiny_img_url', 'string')
              }

              // เช็ค diff ของ pg - prices ในกรณีเกิด variants diff
              const prices = []
              for (let priceIndex = 0; priceIndex < pgPriceFields.length; priceIndex++) {
                let isDiffPrice = false
                const priceKey = pgPriceFields[priceIndex]
                const pricePgId = priceKey.split('_')[1]
                // xUtil.appendObjectIfImmutableDataChange(oldVariants.get(foundIndex), v, priceObj, priceKey, 'float')
                log(`_handleSubmitEditProduct priceKey=${priceKey} isDiffPrice => `, isDiffPrice)

                const oldPgPrice = oldVariants.getIn([foundIndex, priceKey])
                const newPgPrice = v.get(priceKey)
                if (
                  ((_.isNull(oldPgPrice) && newPgPrice) || (oldPgPrice && _.isNull(newPgPrice)) || oldPgPrice !== newPgPrice) &&
                  !_.isNaN(parseFloat(newPgPrice))
                ) {
                  isDiffPrice = true
                }

                if (isDiffPrice) {
                  prices.push({
                    // price: priceObj[priceDiffKey],
                    price: parseFloat(newPgPrice),
                    pg_id: pricePgId,
                  })
                }
              }

              if (prices.length > 0) {
                newVariant.prices = prices
                isDiffPrices = true
              }

              // เช็ค diff ของ wg - qty
              const variantWarehouses = []
              for (let qhQtyIndex = 0; qhQtyIndex < whQtyFields.length; qhQtyIndex++) {
                let isDiffVariantWarehouse = false
                const whQtyKey = whQtyFields[qhQtyIndex]
                const qtyWhId = whQtyKey.split('_')[1]
                // xUtil.appendObjectIfImmutableDataChange(oldVariants.get(foundIndex), v, priceObj, qhQtyKey, 'float')
                log(`_handleSubmitEditProduct qhQtyKey=${whQtyKey} isDiffVariantWarehouse => `, isDiffVariantWarehouse)

                const oldWhQty = oldVariants.getIn([foundIndex, whQtyKey])
                const newWhQty = v.get(whQtyKey)
                if (
                  ((_.isNull(oldWhQty) && newWhQty) || (oldWhQty && _.isNull(newWhQty)) || oldWhQty !== newWhQty) &&
                  !_.isNaN(parseInt(newWhQty))
                ) {
                  isDiffVariantWarehouse = true
                }

                if (isDiffVariantWarehouse) {
                  variantWarehouses.push({
                    qty: parseInt(newWhQty),
                    wh_id: qtyWhId,
                  })
                }
              }

              if (variantWarehouses.length > 0) {
                newVariant.warehouses = variantWarehouses
                isDiffVariantWarehouses = true
              }

              // console.log(`_handleSubmitEditProduct EDIT index=${index} newVariant => `, newVariant)
              if (!_.isEmpty(newVariant)) {
                newVariant.pp_id = pp_id
                newVariant.action = 'EDIT'
              }
              editCount += 1
            }
            // log('newVariant if: ', newVariant)
          } else {
            // Impossible Case (HACK CASE)
            p.op.alert('ไม่สามารถดำเนินการได้', 'ข้อมูลบางอย่างไม่ถูกต้อง กรุณากลับไปเลือกสินค้าแล้วลองแก้ไขใหม่อีกครั้ง')
            // this.inProcess = false
            await this._endSubmitting()
            return
          }
        } else {
          // if (v.get('isDeleted')) {
          //   // ข้ามไปถ้าเป็น new variant + soft delete
          //   continue
          // }

          newVariant[NAME] = v.get(NAME)
          newVariant[COST] = parseFloat(v.get(COST)) || 0 // MUST default to 0 for the case that the store's helper has no permission to see/edit it
          // newVariant[PRICE] = parseFloat(v.get(PRICE)) || 0 // MUST default to 0 for the case that the store's helper has no permission to see/edit it
          // let price_retail = v.get(PRICE_RETAIL)
          // if (price_retail) {
          //   newVariant[PRICE_RETAIL] = price_retail
          // }
          // newVariant[QTY] = parseInt(v.get(QTY)) || 0 // MUST default to 0 as UI render 0 but not updated in redux
          if (v.has(WEIGHT) && !_.isNil(v.get(WEIGHT))) {
            newVariant[WEIGHT] = parseInt(v.get(WEIGHT))
          }
          // newVariant[WEIGHT] = parseInt(v.get(WEIGHT))
          newVariant[SKU] = v.get(SKU) || ''
          newVariant[UPC] = v.get(UPC) || ''

          // if (v.has('img_url') && !_.isNil(v.get('img_url'))) {
          if (v.has('img_url')) {
            newVariant.img_url = v.get('img_url')
            newVariant.thumbnail_url = v.get('thumbnail_url')
            newVariant.tiny_img_url = v.get('tiny_img_url')
          }

          const prices = []
          for (let priceIndex = 0; priceIndex < pgPriceFields.length; priceIndex++) {
            const priceKey = pgPriceFields[priceIndex]
            const pricePgId = priceKey.split('_')[1]
            const newPgPrice = v.get(priceKey)
            prices.push({
              price: parseFloat(newPgPrice),
              pg_id: pricePgId,
            })
          }
          if (prices.length > 0) {
            newVariant.prices = prices
          }

          const variantWarehouses = []
          for (let whQtyIndex = 0; whQtyIndex < whQtyFields.length; whQtyIndex++) {
            const whQtyKey = whQtyFields[whQtyIndex]
            const qtyWhId = whQtyKey.split('_')[1]
            const newWhQty = v.get(whQtyKey)
            variantWarehouses.push({
              qty: parseFloat(newWhQty),
              wh_id: qtyWhId,
            })
          }
          if (variantWarehouses.length > 0) {
            newVariant.warehouses = variantWarehouses
          }

          // console.log(`_handleSubmitEditProduct ADD index=${index} newVariant => `, newVariant)

          newVariant.action = 'ADD'
          addCount += 1
          // log('newVariant else: ', newVariant)
        }
        // log('_handleSubmitEditProduct newVariant => ', newVariant)

        if (!_.isEmpty(newVariant)) {
          variants.push({ ...newVariant })
        }
        // })
      }

      delete_PPIDs.forEach((pp_id) => {
        variants.push({ pp_id, action: 'DELETE' })
      })

      if (variants.length > 0) {
        const total_variants = editCount + addCount
        if (total_variants === 1 && variants[0].action === 'ADD') {
          variants[0][NAME] = '' // หลังบ้านไม่รับค่า string ถ้าหากมีเพียง 1 variant
          // log('oldVariant: ', variants)
        }
        body.variants = variants
      }

      if (isDiffPrices) {
        //  ถ้ามีการแก้ไขราคาเกิดขึ้น จะต้องส่ง product_group_ids ให้ด้วย
        body.product_group_ids = newPgIds.toJS()
      }

      if (isDiffVariantWarehouses) {
        //  ถ้ามีการแก้ไขราคาเกิดขึ้น จะต้องส่ง product_group_ids ให้ด้วย
        body.warehouse_ids = newWhIds.toJS()
      }
    }

    if (!_.has(body, 'product_group_ids')) {
      if (oldPgIds.size > 0 && newPgIds.size === 0) {
        // ส่ง empty product_group_ids ไป clear ราคาออก
        body.product_group_ids = []
      } else if (newPgIds.size < oldPgIds.size) {
        // ถอดกลุ่มราคาออกโดยไม่แก้ราคาอื่นๆ
        body.product_group_ids = newPgIds.toJS()
      } else if (newPgIds.size > 0 && oldPgIds.size === newPgIds.size) {
        // กรณีเลือกรายการราคาใหม่ แต่ไม่ได้แก้ไขราคา จะไม่มี product_group_ids แนบมาด้วย
        const newPgIdsSorted = newPgIds.sort()
        const oldPgIdsSorted = oldPgIds.sort()
        let isEqualPgIds = true
        for (let i = 0; i < newPgIds.size; i++) {
          const newPgId = newPgIdsSorted.get(i)
          const oldPgId = newPgIdsSorted.get(i)
          if (newPgId !== oldPgId) {
            isEqualPgIds = false
            break
          }
        }
        if (!isEqualPgIds) {
          body.product_group_ids = newPgIds.toJS()
        }
      }
    }

    if (!_.has(body, 'warehouse_ids')) {
      if (oldWhIds.size > 0 && newWhIds.size === 0) {
        // ส่ง empty warehouse_ids ไป clear ราคาออก
        body.warehouse_ids = []
      } else if (newWhIds.size < oldWhIds.size) {
        // ถอด wh_ids ออกโดยไม่แก้ qty อื่นๆ
        body.warehouse_ids = newWhIds.toJS()
      } else if (newWhIds.size > 0 && oldWhIds.size === newWhIds.size) {
        // กรณีเลือก wh_ids ใหม่ แต่ไม่ได้แก้ไข wh qty จะไม่มี wh_ids แนบมาด้วย
        const newWhIdsSorted = newWhIds.sort()
        const oldWhIdsSorted = oldWhIds.sort()
        let isEqualWhIds = true
        for (let i = 0; i < newWhIds.size; i++) {
          const newWhId = newWhIdsSorted.get(i)
          const oldWhId = newWhIdsSorted.get(i)
          if (newWhId !== oldWhId) {
            isEqualWhIds = false
            break
          }
        }
        if (!isEqualWhIds) {
          body.warehouse_ids = newWhIds.toJS()
        }
      }
    }

    // const Opg_vd_ids = selectedProduct.get('pg_vd_ids')
    // const Npg_vd_ids = editingProduct.get('pg_vd_ids')
    // if (_.isEmpty(Opg_vd_ids)) {
    //   if (!_.isEmpty(Npg_vd_ids)) {
    //     body.pg_vd_ids = Npg_vd_ids.toJS()
    //   }
    // } else {
    //   if (_.isEmpty(Npg_vd_ids)) {
    //     body.pg_vd_ids = Npg_vd_ids.toJS()
    //   } else {
    //     const checkPg_vd_ids = detailedDiff(Opg_vd_ids.toJS(), Npg_vd_ids.toJS())
    //     // log(checkPg_vd_ids)
    //   }
    // }
    // ARTID
    if (!_.isNil(formValid.pg_vd_ids)) {
      body.pg_vd_ids = formValid.pg_vd_ids
    }

    // log('_handleSubmitEditProduct isDiffVariants => ', isDiffVariants)
    // log('_handleSubmitEditProduct body => ', body)

    // if (!_.isEmpty(body)) {
    //   editProduct(body, () => callback())
    // }
    // CATEGORY
    const newCategory = editingProduct.get(`category`)
    const oldCategory = selectedProduct.get(`category`)
    if (newCategory !== oldCategory) {
      if (!_.isNil(newCategory)) {
        body.category_id = newCategory.get(`id`)
      }
    }

    // log('_handleSubmitEditProduct body => ', body)
    // console.log('_handleSubmitEditProduct body => ', body)
    if (!_.isEmpty(body)) {
      const store_id = selectedProduct.get('store_id')
      const product_id = selectedProduct.get('id')
      body.store_id = store_id
      body.product_id = product_id

      const response: IEditProductResponse | null = await new Promise((resolveEdit) => {
        editProduct({
          body,
          successCallback: resolveEdit,
          failedCallback: () => resolveEdit(null),
        })
      })

      if (response && response.product) {
        this._handleOnSuccessEditProductCallback(response)
        await this._handleAfterEditProduct()
        await this.props.loadListVolumeDiscounts({
          store_id: this.state.store_id,
        })
      } else {
        p.op.showToast('การแก้ไขไม่สำเร็จ กรุณาลองใหม่อีกครั้ง', E_MSG_TYPE.DANGER)
      }
    } else {
      p.op.showToast('กรุณาเปลี่ยนแปลงข้อมูลก่อนบันทึกการแก้ไข', E_MSG_TYPE.WARNING)
    }

    this.inProcess = false
    await this._endSubmitting()
  }

  _handleOnSuccessEditProductCallback = async (response: IEditProductResponse) => {
    const { navigation } = this.props
    const onSuccessEditProductCallback = xUtil.getNavParam(this.props, 'onSuccessEditProductCallback')
    if (_.isFunction(onSuccessEditProductCallback)) {
      await onSuccessEditProductCallback(response.product)
    }
    await this._updateHamburgerMenuOptions()
  }

  _getMkpName = (mkpChannelIds: number[]) => {
    const { selectedStore } = this.props
    let hasShopee = false
    let hasLazada = false
    const channels = selectedStore.get('channels')
    mkpChannelIds.map((id: number) => {
      const channel = _.find(channels.toJS(), (ch: IMKPChannelDetail) => ch.id === id)
      if (!_.isNil(channel)) {
        if (channel.mkp_id === 1) {
          hasShopee = true
        } else if (channel.mkp_id === 2) {
          hasLazada = true
        }
      }
    })

    if (hasShopee && hasLazada) {
      return 'Shopee, Lazada'
    }
    if (!hasShopee && !hasLazada) {
      return ''
    }
    if (hasShopee && !hasLazada) {
      return 'Shopee'
    }
    if (!hasShopee && hasLazada) {
      return 'Lazada'
    }
  }

  _handleDeleteProduct = async (): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    await this._beginSubmitting()
    const { deleteProduct, selectedProduct } = this.props
    const { store_id, product_id } = this.state
    const mkpChannelIds = selectedProduct.has('mkp_channel_ids') ? selectedProduct.get('mkp_channel_ids') : null
    if (!_.isNil(mkpChannelIds) && mkpChannelIds.size > 0) {
      const mkpChannelNane = this._getMkpName(mkpChannelIds.toJS())
      p.op.showConfirmationOkOnly(
        'ลบสินค้าไม่ได้',
        `เนื่องจากสินค้านี้ได้ผูกกับช่องทางขาย ${mkpChannelNane} อยู่ คุณสามารถยกเลิกการผูกสินค้านี้ได้ หากสินค้าที่ผูกอยู่ไม่มีบนช่องทางขายแล้ว หรือ ย้ายไปผูกกับสินค้าตัวอื่น`
      )
      await this._endSubmitting()
      this.inProcess = false
      return
    }
    if (store_id && product_id) {
      // p.op if has ordered / has child
      // const variants = selectedProduct.get('variants') || List([])
      // for (let i = 0; i < variants.size; i++) {
      //   const has_ordered = variants.getIn([i, 'has_ordered']) || false
      //   if (has_ordered) {
      //     p.op.alert('ไม่สามารถลบสิ้นค้าได้', 'เนื่องจากสินค้าชิ้นนี้ถูกใช้ในออเดอร์แล้ว ระบบจึงไม่อนุญาตให้ลบสินค้าชิ้นนี้')
      //     this.inProcess = false
      //     return
      //   }
      // }
      //
      // const has_child = selectedProduct.get('has_child') || false
      // if (has_child) {
      //   p.op.alert('ไม่สามารถลบสิ้นค้าได้', 'เนื่องจากสินค้าชิ้นนี้ถูกตัวแทนดึงเข้าร้านของตัวแทนจำหน่ายแล้ว ระบบจึงไม่อนุญาตให้ลบสินค้าชิ้นนี้')
      //   this.inProcess = false
      //   return
      // }

      const isUserConfirmed = await new Promise<boolean>((passConfirm) => {
        p.op.showConfirmation(
          'คำเตือน',
          'สินค้าชิ้นนี้จะหายจากระบบและคุณจะกลับมาใช้งานหรือแก้ไขใดๆ ไม่ได้อีก คุณตกลงหรือไม่ว่าจะลบสิ้นค้าชิ้นนี้',
          () => passConfirm(true),
          () => passConfirm(false)
        )
      })

      if (isUserConfirmed) {
        const response = await new Promise((resolveDelete) => {
          deleteProduct({
            body: { product_id, store_id },
            successCallback: resolveDelete,
            failedCallback: () => resolveDelete(null),
          })
        })

        if (response) {
          // console.log('resolveDelete response => ', response)
          await this._handleOnSuccessDeleteProductCallback(product_id)
          await this._handleAfterDeleteProduct()
        }
      }
    } else {
      p.op.alert('ไม่พบข้อมูลร้าน หรือ รหัสสินค้า', 'กรุณากลับไปเลือกร้านของคุณใหม่อีกครั้ง ขออภัยในความไม่สะดวก')
    }

    await this._endSubmitting()
    this.inProcess = false
  }

  _handleOnSuccessDeleteProductCallback = async (requestDeletedProductId: number) => {
    const { navigation } = this.props
    const onSuccessDeleteProductCallback = xUtil.getNavParam(this.props, 'onSuccessDeleteProductCallback')
    if (_.isFunction(onSuccessDeleteProductCallback)) {
      await onSuccessDeleteProductCallback(requestDeletedProductId)
    }
  }

  _handleSubmitAddProduct = async (): Promise<void> => {
    // log('_handleSubmitAddProduct !!')
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { addProduct } = this.props

    // == BEGIN IMAGE MANAGER ==
    await this._beginSubmitting()

    await this._beginUploading()
    const body = await this._validationProductForm()
    // log('_handleSubmitAddProduct formValid => ', body)
    await this._endUploading()

    if (body && _.isArray(body.product_group_ids) && body.product_group_ids.length < 1) {
      p.op.alert('ข้อมูลไม่ครบถ้วน', `กรุณาเลือก "รายการราคา" สำหรับสินค้าชิ้นนี้ อย่างน้อย 1 กลุ่ม`)
      await this._endSubmitting()
      this.inProcess = false
      return
    }

    // alert('_handleSubmitAddProduct body => ' + JSON.stringify(body))
    if (body && !_.isEmpty(body)) {
      console.log('_handleSubmitAddProduct body => ', body)

      const response: IAddProductResponse | null = await new Promise((resolveAddProduct) => {
        addProduct({
          body,
          successCallback: resolveAddProduct,
          failedCallback: () => resolveAddProduct(null),
        })
      })

      if (response && response.product) {
        await this._handleOnSuccessAddProductCallback(response)
        await this._handleAfterAddProduct()
        await this.props.loadListVolumeDiscounts({
          store_id: this.state.store_id,
        })
      }

      // await this._endUploading()
      // addProduct({
      //   body,
      //   successCallback: (res) => {
      //     this._handleAfterAddProduct(res)
      //       .then(() => {
      //         this.inProcess = false
      //         this._endUploading()
      //       })
      //   },
      //   failedCallback: () => {
      //     this.inProcess = false
      //     this._endUploading()
      //   },
      // })
      // setTimeout(() => {
      //   if (this.inProcess) {
      //     this.inProcess = false
      //     this._endUploading()
      //   }
      // })
    }
    await this._endSubmitting()
    this.inProcess = false

    //   // Impossible timeout case
    //   setTimeout(() => {
    //     this.inProcess = false
    //     this._endUploading()
    //
    //
    // return new Promise(resolve => {
    //   if (body) {
    //     addProduct(body, (res) => {
    //       this._handleAfterAddProduct(res).then(() => {
    //         this._endUploading()
    //         this.inProcess = false
    //         resolve(null)
    //       })
    //     })
    //   } else {
    //     this.inProcess = false
    //     this._endUploading()
    //     resolve(null)
    //   }
    //
    //   // Impossible timeout case
    //   setTimeout(() => {
    //     this.inProcess = false
    //     this._endUploading()
    //     resolve(null)
    //   }, 15000)
    // })
  }

  _handleOnSuccessAddProductCallback = async (response: IAddProductResponse) => {
    const { navigation } = this.props
    const onSuccessAddProductCallback = xUtil.getNavParam(this.props, 'onSuccessAddProductCallback')
    if (_.isFunction(onSuccessAddProductCallback)) {
      await onSuccessAddProductCallback(response.product)
    }
  }

  _validationProductForm = async (): Promise<{ [key: string]: any } | false> => {
    const { selectedProduct, editingProduct, selectedStore, selectedProductGroups } = this.props
    const { mode, store_id, PRODUCT_VARIANT_ITEMS } = this.state
    const product_groups =
      Map.isMap(selectedStore) && List.isList(selectedStore.get('product_groups')) ? selectedStore.get('product_groups') : List([])
    const product_group_ids = editingProduct.has('product_group_ids') ? editingProduct.get('product_group_ids') : List([])

    const warehouses = selectedStore.get('warehouses') || List([])
    const wh_ids = editingProduct.has('warehouse_ids') ? editingProduct.get('warehouse_ids') : List([])

    // const myVds = editingProduct.has('my_vds') ? editingProduct.get('my_vds') : null
    // const myVdsType = editingProduct.has('my_vds_type') ? editingProduct.get('my_vds_type') : 0
    // const my_vds = []
    // if (_.isNumber(myVdsType) && myVdsType > 0 && List.isList(myVds) && myVds.size >= 2) {
    //   // ถ้ามีการเปิดใช้งาน Volume Discounts
    //   // log('myVds.toJS() => ', myVds.toJS())
    //   let discountKey
    //   switch (myVdsType) {
    //     case 1:
    //       discountKey = 'discount_percent'
    //       break
    //     case 2:
    //       discountKey = 'discount_amount'
    //       break
    //   }
    //   for (let i = 0; i < myVds.size; i++) {
    //     const begin = myVds.getIn([i, 'begin'])
    //     const end = myVds.getIn([i, 'end'])
    //     const discount = myVds.getIn([i, 'discount'])
    //     const vdRow = {
    //       min_qty: parseInt(begin),
    //       max_qty: parseInt(end),
    //     }
    //     vdRow[discountKey] = parseFloat(discount)
    //     my_vds.push(vdRow)
    //   }
    // }

    // VD ADD ARTID
    let new_pg_vd_ids = null
    let old_pg_vd_ids = null
    if (!_.isEmpty(editingProduct.get('pg_vd_ids'))) {
      new_pg_vd_ids = editingProduct.get('pg_vd_ids')
    }
    if (!_.isEmpty(selectedProduct.get('pg_vd_ids'))) {
      old_pg_vd_ids = selectedProduct.get('pg_vd_ids')
    }

    if (store_id) {
      const pvPriceKeys: string[] = []
      PRODUCT_VARIANT_ITEMS.forEach((vi) => {
        const pvKey = vi && vi.key ? vi.key : null
        if (_.isString(pvKey) && pvKey.startsWith(PRICE)) {
          pvPriceKeys.push(pvKey)
        }
      })

      // log('editingProduct', editingProduct)
      const body: { [key: string]: any } = { store_id }

      xUtil.appendToObjectIfImmutableKeyIsNotEmpty(NAME, editingProduct, body)
      xUtil.appendToObjectIfImmutableKeyIsNotEmpty('description', editingProduct, body)
      xUtil.appendToObjectIfImmutableKeyIsNotEmpty('category_id', editingProduct, body)

      // const img_uris = isImmutable(editingProduct.get('img_uris')) ? editingProduct.get('img_uris').toJS() : editingProduct.get('img_uris')
      // const thumbnail_uris = isImmutable(editingProduct.get('thumbnail_uris')) ? editingProduct.get('thumbnail_uris').toJS() : editingProduct.get('thumbnail_uris')
      // const tiny_img_uris = isImmutable(editingProduct.get('tiny_img_uris')) ? editingProduct.get('tiny_img_uris').toJS() : editingProduct.get('tiny_img_uris')

      // log('_validationProductForm img_uris =>', img_uris)
      // log('_validationProductForm img_uris =>', thumbnail_uris)
      // if (img_uris.length > 0 && thumbnail_uris.length > 0) {
      //   body['img_uris'] = img_uris
      //   body['thumbnail_uris'] = thumbnail_uris
      //   body['tiny_img_uris'] = tiny_img_uris
      // }

      // ============= Check Overall fields (not includes images) ==============
      const requiredFields = [NAME, 'description']
      const requiredDisplays = ['ชื่อสินค้า', 'คำอธิบายสินค้า']

      for (let i = 0; i < requiredFields.length; i++) {
        const key = requiredFields[i]
        const warningText = requiredDisplays[i]
        if (!_.has(body, key)) {
          // log(`check key = ${key} in body doesn't passed...`)
          // log('current body', body)
          p.op.alert('ข้อมูลไม่ครบถ้วน', `กรุณาระบุ "${warningText}"`, () => this._handleAfterAlertMissingProductFields(key))
          return false
        }
        // log(`check key = ${key} in body passed...`)
      }

      // let submittedImages = null
      //
      // const promiseUploading = new Promise(resolveUploaded => {
      //   this._getSubmittedImages(submitedImgs => {
      //     submittedImages = submitedImgs
      //     resolveUploaded()
      //   })
      // })
      //
      // const promiseUserCancelUploading = new Promise(resolveCancelled => {
      //   resolveCancelled()
      // })
      //
      // await this._beginUploading(promiseUserCancelUploading)

      // await Promise.race([promiseUploading, promiseUserCancelUploading])
      //
      // await this._endUploading()
      //
      // if (!submittedImages) {
      //   return false
      // }

      // const {
      //   img_uris,
      //   thumbnail_uris,
      //   tiny_img_uris,
      //   allUploaded,
      //   unfinishedIndexes,
      //   isEmpty,
      //   isError,
      // } = submittedImages

      // if (isLoading) {
      //   p.op.alert('กำลังเตรียมรูปภาพ รอสักครู่แล้วดำเนินการใหม่อีกครั้ง')
      //   return false
      // } else if (img_uris && img_uris.length > 0) {
      //   body['img_uris'] = img_uris
      //   body['thumbnail_uris'] = thumbnail_uris
      //   body['tiny_img_uris'] = tiny_img_uris
      // }

      // ============= Validate Shipping Rates ===============
      // Make sure that, if active, all three fields (initQty, initPrice, nextPrice) are filled correctly
      const shippingRates = editingProduct.get('shipping_rates')
      const shippingRateStatuses = editingProduct.get('shippingRateStatuses')
      // log('shippingRates', shippingRates.toJS())
      // log('shippingRateStatuses', shippingRateStatuses.toJS())
      if (shippingRateStatuses) {
        let hasValidationFailed = false
        shippingRateStatuses.mapEntries((entry) => {
          // log(entry) // array of 0 = key, 1 = val
          if (entry[1]) {
            // the rate for this shipping type id is set; therefore need validation
            // log(shippingRates.get(entry[0]).toJS())
            // let rate = shippingRates.get(entry[0])
            const shippingTypeIdInt = parseInt(entry[0])
            let rate
            for (let i = 0; i < shippingRates.size; i++) {
              // log('shippingRates.get(' + i + ') entry[0]:' + entry[0])
              // log(shippingRates.get(i).toJS())
              if (shippingTypeIdInt === shippingRates.get(i).get('shipping_type_id')) {
                rate = shippingRates.get(i)
                break
              }
            }
            // log('rate',rate)

            if (!rate.get('init_qty') || rate.get('init_qty') <= 0) {
              // must be at least one
              p.op.alert('กรุณาระบุจำนวนขั้นต่ำสำหรับค่าส่งชิ้นแรก', null, () => this._handleAfterAlertMissingInitQty(shippingTypeIdInt))
              hasValidationFailed = true
            }
            if (!hasValidationFailed && (!rate.get('init_price') || rate.get('init_price') < 0 || rate.get('init_price').trim() === '')) {
              // must be at least one
              p.op.alert('กรุณาระบุค่าส่งชิ้นแรก', null, () => this._handleAfterAlertMissingInitPrice(shippingTypeIdInt))
              hasValidationFailed = true
            }
            if (!hasValidationFailed && (!rate.get('next_price') || rate.get('next_price') < 0 || rate.get('next_price').trim() === '')) {
              // must be at least one
              p.op.alert('กรุณาระบุค่าส่งชิ้นต่อไป', null, () => this._handleAfterAlertMissingNextPrice(shippingTypeIdInt))
              hasValidationFailed = true
            }
            if (!hasValidationFailed) {
              if (!body.shipping_rates) {
                body.shipping_rates = []
              }
              // pass validation. Add the shipping rate to the create req body
              body.shipping_rates.push(rate)
            }
          }
        })
        if (hasValidationFailed) {
          return false
        }
      }
      // ============= Validate Variants =================
      const hasParent = selectedProduct.get('parent_id') || false
      // const variants = editingProduct.get('variants') || List([])
      const variants =
        editingProduct.get('variants').filter((v, i) => {
          const isDeleted = v.get('isDeleted') || false
          // console.log(`editingProduct filter i=${i} isDeleted => `, isDeleted)
          return !isDeleted
        }) || List([])

      const vCount = variants.size
      if (vCount === 0) {
        await p.op.alertPromise('ไม่มีตัวเลือกสินค้า', 'กรุณาระบุตัวเลือกสินค้าอย่างน้อย 1 ตัวเลือก')
        return false
      }

      // const vRequiredFields = [NAME, COST, PRICE, QTY]
      // const vRequiredDisplays = ['ชื่อตัวเลือก', 'ต้นทุนของสินค้า', 'ราคาสินค้า', 'จำนวนสินค้าในคลัง']
      // let vRequiredFields = [NAME, COST, PRICE]
      // let vRequiredDisplays = ['ชื่อตัวเลือก', 'ต้นทุนสินค้า', 'ราคาสินค้า']
      let vRequiredFields: string[] = [NAME, COST]
      let vRequiredDisplays: string[] = ['ชื่อตัวเลือก', 'ต้นทุนสินค้า']

      // log('vRequiredFields before => ', vRequiredFields)
      if (List.isList(product_groups) && product_groups.size > 1) {
        // validation dynamic prices
        // if (product_group_ids.size < 1) {
        //   p.op.alert('ข้อมูลไม่ครบถ้วน', `กรุณาเลือก "รายการราคา" สำหรับสินค้าชิ้นนี้ อย่างน้อย 1 กลุ่ม`)
        //   return false
        // }

        // Add required fields for price list (price by pg)
        for (let i = 0; i < product_group_ids.size; i++) {
          const pgId = product_group_ids.get(i)
          const pg = pgId ? product_groups.find((pgItem) => pgItem.get('id') === pgId) : null
          const pgName = Map.isMap(pg) && pg.get('name') ? pg.get('name') : null
          vRequiredFields.push(`${PRICE}_${pgId}`)
          vRequiredDisplays.push(pgName ? `ราคาสินค้าของ${pgName}` : 'ราคาสินค้า')
        }
        // for (let i = 0; i < pvPriceKeys.length; i++) {
        //   const PV_KEY = pvPriceKeys[i]
        //   const pgArr = PV_KEY.split('_')
        //   const pgId = pgArr.length > 1 ? parseInt(pgArr[1]) : null
        //   log('pgId => ', pgId)
        //   log('product_groups.find(pg => pg.get(\'id\') === pgId) => ', product_groups.find(pg => pg.get('id') === pgId))
        //   const pg = pgId ? product_groups.find(pg => pg.get('id') === pgId) : null
        //   const pgName = Map.isMap(pg) && pg.get('name') ? pg.get('name') : null
        //   vRequiredFields.push(PV_KEY)
        //   vRequiredDisplays.push(pgName ? `ราคาสินค้าของ${pgName}` : 'ราคาสินค้า')
        // }
      }
      // log('vRequiredFields after => ', vRequiredFields)

      // Add required fields for warehouses qty (wh-qty)
      if (List.isList(wh_ids) && wh_ids.size > 0) {
        for (let i = 0; i < wh_ids.size; i++) {
          const whId = wh_ids.get(i)
          const wh = whId ? warehouses.find((fwh) => fwh.get('id') === whId) : null
          const whDisplayName = getWarehouseDisplayName(warehouses, wh, i)
          vRequiredFields.push(`${QTY}_${whId}`)
          vRequiredDisplays.push(whDisplayName)
        }
      }

      if (_.includes([PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER], mode) && !hasParent) {
        vRequiredFields.push(QTY)
        vRequiredDisplays.push('จำนวนสินค้า')
      }

      if (_.includes([EDIT_SELF, EDIT_SELLER], mode)) {
        vRequiredFields = [NAME]
        vRequiredDisplays = ['ชื่อตัวเลือก']
      }

      const bodyVariants = []
      for (let i = 0; i < variants.size; i++) {
        const v = variants.get(i)

        const checkVariants = {}
        if (variants.size === 1 && i === 0) {
          checkVariants[NAME] = 'Bypass empty field validation'
        } else {
          xUtil.appendToObjectIfImmutableKeyIsNotEmpty(NAME, v, checkVariants)
        }
        xUtil.appendToObjectIfImmutableKeyIsNotEmpty(COST, v, checkVariants, 'float')
        // TODO: เดี๋ยวต้องปรับ QTY ใช้ได้เฉพาะโหมด ร้านขายส่ง กับ ดึงสินค้าเข้าออเดอร์
        xUtil.appendToObjectIfImmutableKeyIsNotEmpty(QTY, v, checkVariants, 'integer')
        // xUtil.appendToObjectIfImmutableKeyIsNotEmpty(PRICE, v, checkVariants, 'float')
        // xUtil.appendToObjectIfImmutableKeyIsNotEmpty(PRICE_RETAIL, v, checkVariants, 'float')

        product_group_ids.forEach((pgId) => {
          xUtil.appendToObjectIfImmutableKeyIsNotEmpty(`${PRICE}_${pgId}`, v, checkVariants, 'float')
        })

        wh_ids.forEach((whId) => {
          xUtil.appendToObjectIfImmutableKeyIsNotEmpty(`${QTY}_${whId}`, v, checkVariants, 'int')
        })
        // pvPriceKeys.forEach(pvKey => {
        //   if (pvKey.startsWith(PRICE)) {
        //     xUtil.appendToObjectIfImmutableKeyIsNotEmpty(pvKey, v, checkVariants, 'float')
        //   }
        // })
        xUtil.appendToObjectIfImmutableKeyIsNotEmpty(WEIGHT, v, checkVariants, 'integer')
        xUtil.appendToObjectIfImmutableKeyIsNotEmpty(SKU, v, checkVariants, 'string')
        xUtil.appendToObjectIfImmutableKeyIsNotEmpty(UPC, v, checkVariants, 'string')

        xUtil.appendToObjectIfImmutableKeyIsNotEmpty('img_url', v, checkVariants, 'string')
        xUtil.appendToObjectIfImmutableKeyIsNotEmpty('thumbnail_url', v, checkVariants, 'string')
        xUtil.appendToObjectIfImmutableKeyIsNotEmpty('tiny_img_url', v, checkVariants, 'string')

        // Set default values for required properties if the create is being done
        // by store helper whom does not have enough permission to do so on price, cost, qty
        if (!this.canDoAtSelectedStore[PRODUCT_COST]) {
          checkVariants[COST] = 0
        }
        if (!this.canDoAtSelectedStore[PRODUCT_PRICE]) {
          checkVariants[PRICE] = 0
        }
        // if (!this.canDoAtSelectedStore[PRODUCT_STOCK]) {
        if (!this.canDoAtSelectedStore[PRODUCT_STOCK_EDIT]) {
          checkVariants[QTY] = 0
        }

        // do validation
        const check = xUtil.checkRequireFields(vRequiredFields, vRequiredDisplays, checkVariants)

        if (check.status) {
          if (variants.size === 1 && i === 0) {
            // Clear value to ''
            checkVariants[NAME] = ''
          }
          bodyVariants.push(checkVariants)
        } else {
          p.op.alert('ข้อมูลไม่ครบถ้วน', `กรุณาระบุ "${check.display}"`, () => this._handleAfterAlertMissingProductVariant(check.key, i))
          return false
        }
      }

      // console.log('variants => ', variants.toJS())
      // console.log('bodyVariants => ', bodyVariants)

      if (bodyVariants.length > 0) {
        // Check duplicate name
        // ref => https://stackoverflow.com/questions/30735465/how-can-i-check-if-the-array-of-objects-have-duplicate-property-values
        const valueArr = bodyVariants.map((v) => v.name)
        let lastIdx = 0
        const isDuplicate = valueArr.some((item, idx) => {
          lastIdx = idx
          return valueArr.indexOf(item) !== idx
        })

        if (isDuplicate) {
          p.op.alert('ชื่อตัวเลือกซ้ำ', 'กรุณาแก้ไข ชื่อตัวเลือก ที่ซ้ำกัน', () => this._handleAfterAlertDuplicateVariantName(lastIdx))
          return false
        }

        // // Modify old price structure to prices

        // for (let i = 0; i < bodyVariants.length; i++) {
        //   bodyVariants[i].prices = []
        //   for (let j = 0; j < product_group_ids.size; j++) {
        //     const pgId = parseInt(product_group_ids.get(j))
        //     const priceKey = `${PRICE}_${pgId}`
        //     const pgPrice = bodyVariants[i][priceKey]
        //     delete bodyVariants[i][priceKey]
        //     bodyVariants[i].prices.push({ pg_id: pgId, price: pgPrice })
        //     if (pgPrice <= 0) {
        //       const productName = editingProduct.get(NAME)
        //       const variant = _.has(bodyVariants[i], 'name') ? bodyVariants[i].name : null
        //       const txtProductName = variant ? `${productName} ${variant}` : productName
        //       const pgData = selectedProductGroups.find((pg) => pg.get('id') === pgId)
        //       const pgName = pgData && Map.isMap(pgData) ? pgData.get('name') : null
        //       const isUserConfirmed = await p.op.isUserConfirm(
        //         'พบการตั้งราคา ฿0',
        //         `กรุณายืนยันว่าจะให้จะขายสินค้า "${txtProductName}" โดยกำหนด "${pgName}" ฿0 ใช่หรือไม่`
        //       )
        //       if (!isUserConfirmed) {
        //         this.inProcess = false
        //         return false
        //       }
        //     }
        //   }
        //   // for (let j = 0; j < pvPriceKeys.length; j++) {
        //   //   const priceKey = pvPriceKeys[j]
        //   //   const pgId = parseInt(priceKey.split('_')[1])
        //   //   const pgPrice = bodyVariants[i][priceKey]
        //   //   delete bodyVariants[i][priceKey]
        //   //   bodyVariants[i]['prices'].push({ product_group_ids: pgId, price: pgPrice })
        //   // }
        // }

        // string array contain pg name that zero price value
        const zeroVariantPrices: { [variantIndex: number]: string[] } = {}
        for (let i = 0; i < bodyVariants.length; i++) {
          bodyVariants[i].prices = []
          for (let j = 0; j < product_group_ids.size; j++) {
            const pgId = parseInt(product_group_ids.get(j))
            const priceKey = `${PRICE}_${pgId}`
            const pgPrice = bodyVariants[i][priceKey]
            delete bodyVariants[i][priceKey]
            bodyVariants[i].prices.push({ pg_id: pgId, price: pgPrice })
            if (pgPrice <= 0) {
              if (!_.isArray(zeroVariantPrices[i])) {
                zeroVariantPrices[i] = []
              }
              const pgData = selectedProductGroups.find((pg) => pg.get('id') === pgId)
              const pgName = pgData && Map.isMap(pgData) ? pgData.get('name') : null
              zeroVariantPrices[i].push(pgName)
            }
          }
        }

        const zeroVariantPricesKeys = Object.keys(zeroVariantPrices)
        if (zeroVariantPricesKeys.length > 0) {
          const zeroVariantPricesNames = []
          for (let i = 0; i < zeroVariantPricesKeys.length; i++) {
            const zeroKey = zeroVariantPricesKeys[i]
            const pgNames = zeroVariantPrices[zeroKey]
            for (let j = 0; j < pgNames.length; j++) {
              zeroVariantPricesNames.push(pgNames[j])
            }
          }
          const zeroVariantPricesCount = zeroVariantPricesKeys.length
          const zeroVariantPricesPgCount = zeroVariantPricesNames.length
          const isUserConfirmed = await p.op.isUserConfirm(
            'พบการตั้งราคา ฿0',
            `กรุณายืนยันว่าจะให้จะขายสินค้าโดยกำหนดราคา ฿0 ใน ${zeroVariantPricesCount} ตัวเลือกสินค้า รวมทั้งสิ้น ${zeroVariantPricesPgCount} รายการราคา ใช่หรือไม่`
          )

          if (!isUserConfirmed) {
            this.inProcess = false
            return false
          }
        }

        // log('bodyVariants after modify prices => ', bodyVariants)

        // Modify old qty structure to warehouses (wh-qty)
        for (let i = 0; i < bodyVariants.length; i++) {
          // TODO: by pKeng to pO: 1 of 2 line commented the line below to not including warehouses into
          // variants[i] as we haven't set the appropriate qty for the only-one, available warehouse yet
          // To fix this: Check if there is only one warehouse.
          // If so, set variants[0].warehoused[0].qty = variants[0].qty
          // bodyVariants[i].warehouses = []

          for (let j = 0; j < wh_ids.size; j++) {
            const whId = parseInt(wh_ids.get(j))
            const qtyKey = `${QTY}_${whId}`
            const whQty = bodyVariants[i][qtyKey]
            delete bodyVariants[i][qtyKey]

            // TODO: by pKeng to pO: 2 of 2 line commented the line
            // bodyVariants[i].warehouses.push({ wh_id: whId, qty: whQty })

            // if (whQty <= 0) {
            //   const productName = editingProduct.get(NAME)
            //   const variant = _.has(bodyVariants[i], 'name') ? bodyVariants[i].name : null
            //   const txtProductName = variant ? `${productName} ${variant}` : productName
            //   const pgData = selectedProductGroups.find(pg => pg.get('id') === whId)
            //   const pgName = pgData && Map.isMap(pgData) ? pgData.get('name') : null
            //   const isUserConfirmed = await p.op.isUserConfirm(
            //     'พบการตั้งราคา ฿0',
            //     `กรุณายืนยันว่าจะให้จะขายสินค้า "${txtProductName}" โดยกำหนด "${pgName}" ฿0 ใช่หรือไม่`
            //   )
            //   if (!isUserConfirmed) {
            //     this.inProcess = false
            //     return false
            //   }
            // }
          }
        }

        body.variants = bodyVariants
      } else if (_.includes([ADD], mode) && bodyVariants.length === 0) {
        p.op.alert('ข้อมูลไม่ครบถ้วน', 'กรุณาระบุข้อมูลตัวเลือกสินค้า', () => this._handleAfterAlertFormIsNotFullfill())
        return false
      }

      const { img_uris, thumbnail_uris, tiny_img_uris, allUploaded, unfinishedIndexes, isEmpty, isError } = await this._getSubmittedImages()

      // log('_getSubmittedImages img_uris => ', img_uris)
      // log('_getSubmittedImages thumbnail_uris => ', thumbnail_uris)
      // log('_getSubmittedImages tiny_img_uris => ', tiny_img_uris)
      // log('_getSubmittedImages allUploaded => ', allUploaded)
      // log('_getSubmittedImages unfinishedIndexes => ', unfinishedIndexes)
      // log('_getSubmittedImages isEmpty => ', isEmpty)
      // log('_getSubmittedImages isError => ', isError)

      if (!this.state.uploading) {
        // If Manual Cancelled
        // await this._endUploading()
        return false
      }

      if (isError) {
        p.op.alert('เกิดข้อผิกพลาดเกี่ยวกับโมดูลรูปภาพ', `กรุณาระบุลองใหม่อีกครั้ง"`)
        return false
      }

      let isUserConfirmEmpty = false
      if (isEmpty) {
        // p.op.alert('ข้อมูลไม่ครบถ้วน', `กรุณาระบุ "ภาพสินค้า"`)
        // return false
        isUserConfirmEmpty = await p.op.isUserConfirm('ไม่มีรูปสินค้า', 'กรุณายืนยันว่าต้องการใช้งานสินค้านี้โดยไม่มีรูปสินค้า')
        if (!isUserConfirmEmpty) {
          return false
        }
      }

      if (!allUploaded && !isUserConfirmEmpty) {
        const txtUnfinishedList = unfinishedIndexes && unfinishedIndexes.length > 0 ? unfinishedIndexes.join(', ') : null
        const txtErrorMessage = txtUnfinishedList
          ? `ไม่สามารถอัพโหลดรูปภาพที่ ${txtUnfinishedList} ได้ \nกรุณาอัพโหลดรูปภาพใหม่อีกครั้ง หรือลบรูปภาพที่ไม่สามารถอัพโหลดได้แล้วกดบันทึกสินค้าก่อน แล้วคุณสามารถมาแก้ไขรูปภาพใหม่ได้ภายหลัง`
          : 'กรุณาลองใหม่อีกครั้ง'
        p.op.alert('อัพโหลดรูปภาพไม่สำเร็จ', txtErrorMessage)
        return false
      }

      body.img_uris = img_uris
      body.thumbnail_uris = thumbnail_uris
      body.tiny_img_uris = tiny_img_uris

      if (isUserConfirmEmpty) {
        body.img_uris = [xCONS.IMG_PLACEHOLDER_URL]
        body.thumbnail_uris = [xCONS.IMG_PLACEHOLDER_URL]
        body.tiny_img_uris = [xCONS.IMG_PLACEHOLDER_URL]
      }

      // // @ts-ignore
      // if (body.variants && body.variants.length > 1) {
      //   // @ts-ignore
      //   for (let i = 0; i < body.variants.length; i++) {
      //     const variantImage = this.state[`variantImage${i}`]
      //     // @ts-ignore
      //     if (variantImage && variantImage.p && variantImage.t && variantImage.y) {
      //       // @ts-ignore
      //       body.variants[i].img_url = variantImage.p[0]
      //       // @ts-ignore
      //       body.variants[i].thumbnail_url = variantImage.t[0]
      //       // @ts-ignore
      //       body.variants[i].tiny_img_url = variantImage.y[0]
      //     }
      //   }
      // }

      // == END IMAGE MANAGER ==

      // แทรก product groups
      body.product_group_ids = product_group_ids.toArray()

      // แทรก volume discount ARTID
      // log('------------------------------------')
      let checkDetailedDiff = false
      if (_.isNil(old_pg_vd_ids)) {
        if (!_.isEmpty(new_pg_vd_ids)) {
          // log(new_pg_vd_ids.toJS())
          body.pg_vd_ids = new_pg_vd_ids.toJS()
        }
      } else {
        // if (_.isEmpty(new_pg_vd_ids)) {
        //   body.pg_vd_ids = new_pg_vd_ids.toJS()
        // } else {
        // FIXME: O: ลง types ของ any ให้ทีหลังด้วยครับ
        const checkPg_vd_ids: any = detailedDiff(old_pg_vd_ids.toJS(), new_pg_vd_ids.toJS())
        if (!_.isEmpty(checkPg_vd_ids.added)) {
          checkDetailedDiff = true
        }
        if (!_.isEmpty(checkPg_vd_ids.deleted)) {
          checkDetailedDiff = true
        }
        if (!_.isEmpty(checkPg_vd_ids.updated)) {
          checkDetailedDiff = true
        }
        // }
      }

      if (checkDetailedDiff) {
        body.pg_vd_ids = new_pg_vd_ids.toJS()
      }

      const newCategory = editingProduct.get(`category`)
      const oldCategory = selectedProduct.get(`category`)
      if (newCategory !== oldCategory) {
        if (!_.isNil(newCategory)) {
          body.category_id = newCategory.get(`id`)
        }
      }

      // log('_validationProductForm body => ', body)
      log('body => ', body)
      return body
    }
    p.op.alert('การเข้าถึงที่ไม่ถูกต้อง', 'กรุณาเลือกร้านค้าแล้วลองใหม่อีกครั้ง')
    return false
  }

  // _pullProductRequest = () => {
  _handlePullProductToMyStore = async (): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    await this._beginSubmitting()
    const { selectedStore, editingProduct, pullProductToMyStore } = this.props
    const { store_id, product_id } = this.state
    const product = editingProduct // Pointer create
    const product_groups = Map.isMap(selectedStore) && selectedStore.has('product_groups') ? selectedStore.get('product_groups') : List([])
    const product_group_ids = editingProduct.has('product_group_ids') ? editingProduct.get('product_group_ids') : List([])

    // FIXME: O => ถ้าเป็น สอง flag นี้เป็นจริง รอดู api หลังบ้าน แล้วเอา field product retail price มาใช้
    // this.s_use_retail_price === true
    // this.order_use_retail_price === 1

    // const img_uris = isImmutable(editingProduct.get('img_uris')) ? editingProduct.get('img_uris').toJS() : editingProduct.get('img_uris')
    // const thumbnail_uris = isImmutable(editingProduct.get('thumbnail_uris')) ? editingProduct.get('thumbnail_uris').toJS() : editingProduct.get('thumbnail_uris')
    // const img_uris = isImmutable(imgBlock_img_uris) ? imgBlock_img_uris.toJS() : imgBlock_img_uris
    // const thumbnail_uris = isImmutable(imgBlock_thumbnail_uris) ? imgBlock_thumbnail_uris.toJS() : imgBlock_thumbnail_uris
    const img_uris = product.get('img_uris') ? product.get('img_uris') : List([])
    const thumbnail_uris = product.get('thumbnail_uris') ? product.get('thumbnail_uris') : img_uris
    const tiny_img_uris = product.get('tiny_img_uris') ? product.get('tiny_img_uris') : thumbnail_uris

    // // Check Empty Price (OLD)
    // let variantsModified = product.get('variants').toJS()
    // // log('_variantsModified_: ', variantsModified)
    // for (let i = 0; i < variantsModified.length; i++) {
    //   const { price } = variantsModified[i]
    //   // log('_____Check Empty Price Index, price: ', i, price)
    //   if (_.isUndefined(price) || _.isNull(price) || price.length === 0) {
    //     p.op.alert('ข้อมูลไม่ครบถ้วน', 'กรุณาระบุราคา', () => this._handleAfterAlertMissingProductVariant('price', i))
    //     // util.showWarningToast('กรุณาระบุราคาให้กับสินค้าทุกๆ ตัวเลือก')
    //     return
    //   }
    // }

    const variantsModified = product.get('variants')
    if (List.isList(product_groups) && product_groups.size > 1) {
      // validation dynamic prices
      if (product_group_ids.size < 1) {
        p.op.alert('ข้อมูลไม่ครบถ้วน', `กรุณาเลือก "รายการราคา" สำหรับสินค้าชิ้นนี้ อย่างน้อย 1 กลุ่ม`)
        this.inProcess = false
        await this._endSubmitting()
        return
      }

      for (let i = 0; i < variantsModified.size; i++) {
        for (let j = 0; j < product_group_ids.size; j++) {
          const pgId = parseInt(product_group_ids.get(j))
          const pgData = product_groups.find((pg) => pg.get('id') === pgId)
          const pgName = pgData && Map.isMap(pgData) ? pgData.get('name') : null
          const priceKey = `${PRICE}_${pgId}`
          const priceValue = variantsModified.getIn([i, priceKey])
          if (_.isUndefined(priceValue) || _.isNull(priceValue) || priceValue.length === 0) {
            const txtAlert = pgName ? `กรุณาระบุราคาสำหรับ "${pgName}" ` : 'กรุณาระบุราคาให้ครบถ้วน'
            p.op.alert('ข้อมูลไม่ครบถ้วน', txtAlert)
            this.inProcess = false
            await this._endSubmitting()
            return
          }
        }
      }
    }

    // (OLD)
    // let variants = variantsModified.map(v => {
    //   return { pp_id: parseInt(v.pp_id), price: parseFloat(v.price) }
    // })

    // Modify old price structure to prices
    const bodyVariants = variantsModified.toJS()
    for (let i = 0; i < bodyVariants.length; i++) {
      log(this, 'bodyVariants => ', bodyVariants)
      const vName = bodyVariants.name && bodyVariants.name.length > 0 ? bodyVariants.name : ''
      bodyVariants[i].prices = []
      for (let j = 0; j < product_group_ids.size; j++) {
        const pgId = parseInt(product_group_ids.get(j))
        const priceKey = `${PRICE}_${pgId}`
        const pgPrice = parseFloat(bodyVariants[i][priceKey])
        if (pgPrice === 0) {
          const foundProductGroup = List.isList(product_groups) ? product_groups.find((pg) => Map.isMap(pg) && pg.get('id') === pgId) : null
          if (Map.isMap(foundProductGroup)) {
            const pgName = foundProductGroup.get('name')
            // เตือน user ถ้ามีราคาที่เป็น 0
            const isUserConfirmed = await new Promise<boolean>((passConfirm) => {
              p.op.showConfirmation(
                'มีราคาขายเป็นศูนย์',
                `คุณต้องการขายสินค้า${vName} โดยกำหนด${pgName} เป็น ฿0 ใช่หรือไม่`,
                () => passConfirm(true),
                () => passConfirm(false),
                'ใช่',
                'ไม่ใช่'
              )
            })
            if (!isUserConfirmed) {
              this.inProcess = false
              await this._endSubmitting()
              return
            }
          }
        }

        delete bodyVariants[i][priceKey]
        bodyVariants[i].prices.push({ pg_id: pgId, price: pgPrice })
      }
    }

    const variants = bodyVariants.map((v) => ({
      pp_id: parseInt(v.pp_id),
      prices: v.prices,
    }))

    let pg_vd_ids = editingProduct.get('pg_vd_ids')
    if (_.isNil(pg_vd_ids)) {
      pg_vd_ids = []
    }

    const body: IPullProductRequestBody = {
      store_id,
      product_id,
      name: product.get(NAME),
      description: product.get('description'),
      // category_id: parseInt(product.get('category_id')),
      img_uris: isImmutable(img_uris) ? img_uris.toJS() : img_uris,
      thumbnail_uris: isImmutable(thumbnail_uris) ? thumbnail_uris.toJS() : thumbnail_uris,
      tiny_img_uris: isImmutable(tiny_img_uris) ? tiny_img_uris.toJS() : tiny_img_uris,
      pg_vd_ids,
    }

    if (_.isArray(variants) && variants.length > 0) {
      body.variants = variants
    }

    if (List.isList(product_group_ids) && product_group_ids.size > 0) {
      body.product_group_ids = product_group_ids.toJS()
    }

    // pullProductToMyStore(body, (res) => this._handleAfterPullProductToMyStore(res))
    const response: any = await new Promise<boolean>((resolve) => {
      pullProductToMyStore({
        body,
        successCallback: resolve,
        failedCallback: () => resolve(null),
      })
      // log('pullProductToMyStore body => ', body)
      // setTimeout(() => success(true), 3500)
    })

    // console.log('pullProductToMyStore response => ', response)

    if (response && response.status === 'ok') {
      await this._handleOnSuccessPullSellersProductToMyStoreCallback(body)
      await this._handleAfterPullProductToMyStore()
    }

    await this._endSubmitting()
    this.inProcess = false
  }

  _handleOnSuccessPullSellersProductToMyStoreCallback = async (requestBody: IPullProductRequestBody) => {
    const { navigation } = this.props
    const onSuccessPullSellersProductToMyStoreCallback = xUtil.getNavParam(this.props, 'onSuccessPullSellersProductToMyStoreCallback')
    if (_.isFunction(onSuccessPullSellersProductToMyStoreCallback)) {
      await onSuccessPullSellersProductToMyStoreCallback(requestBody)
    }
  }

  // _pullProductToOrder = () => {
  _handlePullProductToOrder = async (): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { addProductToOrder, editingProduct, selectedStore, selectedUserGroups } = this.props
    const { mode, store_id, seller_store_id, ug_id, pg_ids } = this.state
    // const s_use_retail_price = selectedStore.get('s_use_retail_price') || false

    const orderProducts = []

    // Check Empty QTY
    let hasEmptyField = true
    const parent_id = editingProduct.get('parent_id')
    const variantsModified = editingProduct.get('variants') || List([])
    // log('_variantsModified_: ', variantsModified)
    for (let i = 0; i < variantsModified.size; i++) {
      const qty = variantsModified.getIn([i, QTY])
      // log('_pullProductToOrder i, qty: ', i, qty)
      if (!_.isUndefined(qty) && !_.isNull(qty) && qty.length !== 0 && parseInt(qty) !== 0) {
        hasEmptyField = false
      }
    }

    if (hasEmptyField) {
      p.op.alert('ข้อมูลไม่ครบถ้วน', 'กรุณาระบุจำนวนสินค้าอย่างน้อย 1 ชิ้นเพื่อเพิ่มลงในออเดอร์', () =>
        this._handleAfterAlertMissingProductVariant('qty', 0)
      )
      // util.showWarningToast('คุณยังไม่ได้เลือกจำนวนสินค้าที่จะเพิ่มลงในออเดอร์')
    } else {
      const variants = editingProduct.get('variants') || List([])

      // log('####### editingProduct => ', editingProduct.toJS())

      for (let i = 0; i < variants.size; i++) {
        const v = fromJS(variants.get(i))
        const qty = v.get(QTY) || 0
        const available_qty = v.get(AVAILABLE_QTY) ? v.get(AVAILABLE_QTY) : 0
        // ดึงสินค้าเข้ามาเฉพาะตัวที่มี qty > 0
        if (qty > 0) {
          if (available_qty < qty) {
            p.op.alert('สินค้าในคลังไม่เพียงพอ', 'กรุณาปรับจำนวนสินค้าให้อยู่ในช่วงจำนวนสินค้าที่เหลือในคลัง', () =>
              this._handleAfterAlertMissingProductVariant('qty', i)
            )
            this.inProcess = false
            return
          }

          const parent_store_id = editingProduct.has('parent_store_id') ? parseInt(editingProduct.get('parent_store_id')) : null
          let cost = parseFloat(v.get(COST))
          const product_id = editingProduct.get('id')
          const name = editingProduct.get(NAME)
          const variant = v.get(NAME) // variant name
          const pp_id = parseFloat(v.get('pp_id'))
          let thumbnail_uris = List.isList(editingProduct.get('thumbnail_uris'))
            ? editingProduct.get('thumbnail_uris').toJS()
            : editingProduct.get('thumbnail_uris')
          const h = v.has(H) ? parseInt(v.get(H)) : null

          const my_vds = editingProduct.get('my_vds')
          const my_vds_type = editingProduct.get('my_vds_type')
          const seller_vds = editingProduct.get('seller_vds')
          const seller_vds_type = editingProduct.get('seller_vds_type')

          let price = null // to be compute
          let pg_id = null // to be compute
          let ugpgSaleDiscountAmount = null // to be compute
          let ugpgSaleDiscountPercent = null // to be compute

          let hasEmptyPrice = false

          const productDiscount = editingProduct.get('pdc') || Map({})
          const ugpgPurchaseDiscountAmount = productDiscount.get('ua') ? parseFloat(productDiscount.get('ua')) : null
          const ugpgPurchaseDiscountPercent = productDiscount.get('up') ? parseFloat(productDiscount.get('up')) : null

          if (!_.isNil(v.get('thumbnail_url')) && v.get('thumbnail_url') !== '') {
            thumbnail_uris = [v.get('thumbnail_url')]
          }

          // TODO: Refactor to common usage in saga/reducer logic
          if (seller_store_id) {
            // ถ้าเปิดด้วยสินค้าของร้านขายส่ง
            price = v.get(PRICE) ? parseFloat(v.get(PRICE)) : null
            cost = price
          } else {
            // ถ้าเปิดด้วยสินค้าของร้านฉัน
            // const prices = v.get('prices')
            // if (!prices || !List.isList(prices) || prices.size < 1) { // should be impossible case
            //   p.op.alert('สินค้าชิ้นนี้ไม่มีราคา', 'กรุณาระบุราคาก่อนใช้เปิดออเดอร์')
            //   this.inProcess = false
            //   return
            // }
            log(this, '_pullProductToOrder v.toJS(),', v.toJS())

            // let prices = v.get('prices')
            // if (!prices || !List.isList(prices) || prices.size < 1) { // should be impossible case
            //   prices = List([])
            // }

            // price = prices.hasIn([0, 'price']) ? parseInt(prices.getIn([0, 'price'])) : null
            // pg_id = prices.hasIn([0, 'pg_id']) ? parseInt(prices.getIn([0, 'pg_id'])) : null
            price = v.has(PRICE) ? parseFloat(v.get(PRICE)) : null
            pg_id = v.get('pg_id')

            // if (price === 0 || _.isNull(price) || _.isUndefined(price) || !pg_id) {
            if (price === 0 || _.isNull(price) || _.isUndefined(price)) {
              // p.op.alert('ไม่พบราคาสินค้าชิ้นนี้', 'กรุณาระบุราคาของสินค้าชิ้นนี้ก่อนใช้เปิดออเดอร์')
              // this.inProcess = false
              // return
              const txtProductName = variant ? `${name} ${variant}` : name
              const isUserConfirmed = await p.op.isUserConfirm(
                'ไม่พบราคาสินค้า',
                `กรุณายืนยันว่าจะให้จะหยิบสินค้า "${txtProductName}" เข้าสู่ออเดอร์แล้วกำหนดราคาไว้ ฿0 โดยคุณสามารถปรับราคาได้ภายหลัง`
              )
              if (!isUserConfirmed) {
                this.inProcess = false
                return
              }
              price = 0
              hasEmptyPrice = true
            }

            if (ug_id && pg_id) {
              // ถ้ามีการเลือกเปิดโดย UG มา และมี pg_id สำหรับคิดส่วนลด
              const focusedUserGroup = selectedUserGroups.find((ug) => Map.isMap(ug) && ug.get('id') === ug_id)
              if (!Map.isMap(focusedUserGroup) || !focusedUserGroup.get('id')) {
                p.op.alert('ไม่พบข้อมูลกลุ่มสมาชิก', 'กรุณาลองใหม่อีกครั้ง') // should be impossible case
                this.inProcess = false
                return
              }
              // FIXME: O: ระบุ types ให้กับ any นี้ด้วย
              const discountRelations: any = focusedUserGroup.get('pgs') // discount relation
              const focusedDiscount = discountRelations.find((pgd) => Map.isMap(pgd) && pgd.get('id') === pg_id)
              if (Map.isMap(focusedDiscount)) {
                ugpgSaleDiscountAmount = focusedDiscount.get('discount_amount') ? parseFloat(focusedDiscount.get('discount_amount')) : null
                ugpgSaleDiscountPercent = focusedDiscount.get('discount_percent')
                  ? parseFloat(focusedDiscount.get('discount_percent'))
                  : null
              }
            } else if (_.isArray(pg_ids) && pg_ids.length > 0) {
              // TODO: ตอนนี้ยังไม่มีส่วนลดของ PG โดดๆ อนาคตมีก็มา compute ตรงนี้
            }
          }

          const pulledOrderProduct = {
            variant,
            cost,
            product_id,
            name,
            qty, // +n จากของเดิม
            available_qty,
            pp_id,
            price,
            price_rate_value: hasEmptyPrice ? -1.33 : price, // default price ถ้าถูก user เปลี่ยน จะทำให้หน้าบ้านส่ง custom price ไป
            price_rate_type: 1, // default mode
            thumbnail_uris,
            h,
            parent_store_id,

            // FIXME: Maybe use in when Enable Vds in version 2.1
            my_vds,
            my_vds_type,
            seller_vds,
            seller_vds_type,

            // for create api body with pg_id
            ug_id,
            pg_id,

            // for compute real-time discount
            ugpgPurchaseDiscountAmount, // discount per each
            ugpgPurchaseDiscountPercent, // discount per each
            ugpgSaleDiscountAmount, // discount per each
            ugpgSaleDiscountPercent, // discount per each
          }

          orderProducts.push(pulledOrderProduct)

          // // TODO: Refactor to common usage in saga/reducer logic
          // orderProducts.push({
          //   variant: v.get(NAME),
          //   price: order_use_retail_price ? parseFloat(v.get(PRICE_RETAIL)) : parseFloat(v.get(PRICE)),
          //   // price_retail: parseFloat(v.get(PRICE_RETAIL)),
          //   product_id: editingProduct.get('id'),
          //   name: editingProduct.get(NAME),
          //   cost: parseFloat(v.get(COST) || v.get(PRICE)),
          //   qty: parseInt(qty) || 0,
          //   weight: parseInt(v.get(WEIGHT)),
          //   available_qty: parseInt(available_qty) || 0,
          //   pp_id: parseFloat(v.get('pp_id')),
          //   thumbnail_uris: isImmutable(editingProduct.get('thumbnail_uris'))
          //     ? editingProduct.get('thumbnail_uris').toJS()
          //     : editingProduct.get('thumbnail_uris'),
          //   price_rate_value: order_use_retail_price ? parseFloat(v.get(PRICE_RETAIL)) : parseFloat(v.get(PRICE)),
          //   price_rate_type: s_use_retail_price && order_use_retail_price ? 2 : 1,
          //   h: parseInt(v.get(H)) || null,
          //   parent_store_id: parseInt(editingProduct.get('parent_store_id')),
          //
          //   // FIXME: Refactor me better than this
          //   my_vds: editingProduct.get('my_vds'),
          //   my_vds_type: editingProduct.get('my_vds_type'),
          //   seller_vds: editingProduct.get('seller_vds'),
          //   seller_vds_type: editingProduct.get('seller_vds_type'),
          // })
        }
      }

      const callback = (res) => {
        this._handleAfterPullProductToOrder(res, mode)
      }

      addProductToOrder({
        store_id,
        seller_store_id,
        orderProducts,
        callback,

        // for order create validation
        ug_id,
        pg_ids,
      })
    }

    setTimeout(() => {
      // Delay for next click about 2 seconds
      this.inProcess = false
    }, 2000)
  }

  _isDoneInitDataLoading = (): boolean => !!this.props.editingProduct.get(NAME)

  _onChangeProductName = (newText: string) => {
    const { onChangeProduct } = this.props
    if (!_.isFunction(onChangeProduct)) {
      return
    }
    onChangeProduct({ key: NAME, value: newText })
  }

  _onChangeProductDesc = (newText: string) => {
    const { onChangeProduct } = this.props
    if (!_.isFunction(onChangeProduct)) {
      return
    }
    onChangeProduct({ key: 'description', value: newText })
  }

  _onChangeProductCategory = async (arg: { index: number | null; item?: { id: number } }): Promise<void> => {
    const { onChangeProduct } = this.props
    const { index, item } = arg
    if (_.isNumber(index) && _.isObject(item) && _.has(item, 'id')) {
      const cat_id = item.id
      await p.op.setAppDefaultValue(this.DEFAULT_PRODUCT_CATEGORY_KEY, cat_id)
      onChangeProduct({ key: 'category_id', value: cat_id })
    }
  }

  _onChangeProductGroups = async (newPgIds: number[] = []): Promise<void> => {
    if (!newPgIds || (_.isArray(newPgIds) && newPgIds.length === 0)) {
      const isUserConfirmed = await p.op.isUserConfirm(
        'ไม่ระบุรายการราคา',
        'กรุณายืนยันว่าฉันจะไม่ระบุรายการราคาและสินค้าชิ้นนี้จะไม่สามารถขายได้'
      )
      if (!isUserConfirmed) {
        return
      }
    }
    const { mode } = this.state
    if (mode === ADD) {
      await p.op.setAppDefaultValue(this.DEFAULT_PRODUCT_SELECTED_PGS_KEY, newPgIds)
    }
    this.props.onChangeProductGroups(newPgIds)
  }

  onChangeProductWarehouseIds = async (newWhIds: number[] = []): Promise<void> => {
    const { mode } = this.state
    if (mode === ADD) {
      await p.op.setAppDefaultValue(this.DEFAULT_PRODUCT_SELECTED_WH_IDS_KEY, newWhIds)
    }
    this.props.onChangeProductWarehouseIds(newWhIds)
  }

  _onChangeVolumeDiscountType = async (newVdTypeIndex: number): Promise<void> => {
    log('_onChangeVolumeDiscount newVdTypeIndex => ', newVdTypeIndex)
    const { onChangeProduct } = this.props
    const { mode } = this.state
    const vdsKey = `my_vds`
    const vdsTypeKey = `${vdsKey}_type`
    // const newVdTypeIndex = this.OPTIONS_VOLUME_DISCOUNT_MENU_TYPES.findIndex(type => type === newVdType)
    if (_.isNull(newVdTypeIndex) || _.isUndefined(newVdTypeIndex) || !this.OPTIONS_VOLUME_DISCOUNT_MENU_TYPES[newVdTypeIndex]) {
      return
    }
    onChangeProduct({ key: vdsTypeKey, value: newVdTypeIndex })

    if (_.includes([1, 2], newVdTypeIndex) && _.includes([ADD, EDIT_SELF], mode)) {
      // Set To Default Value
      onChangeProduct({ key: vdsKey, value: this.VOLUME_DISCOUNT_TEMPLATE })
    }
  }

  _onChangeVolumeDiscount = async (newVds: List<any>): Promise<void> => {
    log('_onChangeVolumeDiscount newVds.toJS() => ', newVds.toJS())
    this.props.onChangeProduct({ key: 'my_vds', value: newVds })
  }

  _onRemoveProductVariant = async (variantIndex: number): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    this.props.removeProductVariant(variantIndex)
    // await new Promise((reduxDelay) => setTimeout(reduxDelay, 100))
    await xUtil.delay(100)

    await this._checkExceedVariantPage()

    this.inProcess = false
  }

  _onUndoRemoveProductVariant = async (variantIndex: number): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    this.props.undoRemoveProductVariant(variantIndex)
    // await new Promise((reduxDelay) => setTimeout(reduxDelay, 100))
    await xUtil.delay(100)

    await this._checkExceedVariantPage()

    this.inProcess = false
  }

  _shouldNamedProductInAddMode = (): boolean => {
    const { editingProduct } = this.props
    const { mode } = this.state
    if (!Map.isMap(editingProduct) || !_.includes(xCONS.PRODUCT_VIEW_MODE, mode)) {
      return false
    }
    const productName = editingProduct.get(NAME)
    return (_.isNull(productName) || productName.length === 0) && mode === ADD
  }

  isMyProduct = (): boolean => !this._isSellerProduct()

  _isSellerProduct = (): boolean => {
    // log('_isSellerProduct')
    // log(this.props.navigation.state.params.mode)
    // log(this.props.editingProduct.get('parent_id'))
    const { editingProduct } = this.props
    const { mode } = this.state
    return !!(mode === PULL_PRODUCT || editingProduct.get('parent_id'))
  }

  _getSellerShippingRateStatuses(sellerShippingRates) {
    if (!sellerShippingRates || sellerShippingRates.size === 0) {
      return Map({})
    }
    const sellerShippingRateStatuses = sellerShippingRates.reduce(
      (result, item) => result.set(item.get('shipping_type_id').toString(), true),
      Map()
    )
    // log('sellerShippingRateStatuses')
    // log(sellerShippingRateStatuses.toJS())
    return sellerShippingRateStatuses
  }

  _shallRenderShippingRates = ({ mode, isDoneInitDataLoading, eligibleModes, shippingRates, shippingRateStatuses }): boolean => {
    // log('_shallRenderShippingRates mode: ' + mode + ' eligibleModes: ' + eligibleModes + ' shippingRates: ' + shippingRates + ' shippingRateStatuses: ' + shippingRateStatuses)
    if (!isDoneInitDataLoading || !this.canDoAtSelectedStore[PRODUCT_SHIPPING_QTY_RATE]) {
      return false
    }

    // TODO: Check การ render ให้ดีเพราะ init shippingRateStatuses เป็น empty ทำให้เวลา EDIT Mode มันไม่ render ออกมา
    if (
      shippingRates &&
      shippingRates.size > 0 &&
      shippingRateStatuses &&
      shippingRateStatuses.size > 0 &&
      _.includes(eligibleModes, mode)
    ) {
      return true
    }
    return false
  }

  _shouldRenderShippingRatesMy = () => {
    const shippingRatesMy = this.props.editingProduct.get('shipping_rates')
    const shippingRateStatusesMy = this.props.editingProduct.get('shippingRateStatuses')
    return this._shouldRenderShippingRates({
      shippingRates: shippingRatesMy,
      shippingRateStatuses: shippingRateStatusesMy,
      eligibleModes: [ADD, VIEW_SELF, EDIT_SELF, PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER],
    })
  }

  _shouldRenderShippingRatesSeller = () => {
    const shippingRatesSeller = this.props.editingProduct.get('seller_shipping_rates')
    const shippingRateStatusesSeller = this._getSellerShippingRateStatuses(shippingRatesSeller)
    return this._shouldRenderShippingRates({
      shippingRates: shippingRatesSeller,
      shippingRateStatuses: shippingRateStatusesSeller,
      eligibleModes: [VIEW_SELF, VIEW_SELLER, EDIT_SELF, EDIT_SELLER, PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER, PULL_PRODUCT],
    })
  }

  _shouldRenderShippingRates = ({ eligibleModes, shippingRates, shippingRateStatuses }): boolean => {
    const isDoneInitDataLoading = this._isDoneInitDataLoading()
    if (!isDoneInitDataLoading || !this.canDoAtSelectedStore[PRODUCT_SHIPPING_QTY_RATE]) {
      return false
    }
    const { mode } = this.state
    if (
      shippingRates &&
      shippingRates.size > 0 &&
      shippingRateStatuses &&
      shippingRateStatuses.size > 0 &&
      _.includes(eligibleModes, mode)
    ) {
      return true
    }
    return false
  }

  // _getMainProductRenderProperties(product: Map<string, any>, mode: string): { [key: string]: any } {
  //   const { uploading } = this.state
  //   // const inputsEditable = mode
  //   //   ? xCONS.PRODUCT_VIEW_INPUTS_EDITABLE_BY_MODE[mode]
  //   //   : { name: false, desc: false, imgs: false, category: false }
  //   // // log('___inputReadonly_: ', inputsEditable)
  //   // log('_render__thumbnail_uris_: ', product.get('thumbnail_uris'))
  //   //   log('this.scrollViewRef : ', this.scrollViewRef)
  //
  //   // Refactor Editable logic 1/10/2560
  //   const editable = product.get('editable') || []
  //   let isAddMode = mode === ADD ? true : false
  //
  //   // const isPullProductMode = mode === PULL_PRODUCT
  //   const isSellerProduct = this._isSellerProduct()
  //   // Note: In PULL_PRODUCT mode, the seller's shipping rates are returned as 'shipping_rates'
  //
  //   // let isEditMode = _.includes([EDIT_SELF, EDIT_SELLER], mode) || false
  //   let editableName, editableDesc, editableImg, editableCategory // , editableShippingRates
  //
  //   const editableShippingRatesMy = this.canAutoCalcShippingRateByQty && _.includes([ADD, EDIT_SELF, EDIT_SELLER, PULL_PRODUCT], mode)
  //   // log('_getMainProductRenderProperties  mode =>', mode)
  //   // log('_getMainProductRenderProperties  this.canAutoCalcShippingRateByQty =>', this.canAutoCalcShippingRateByQty)
  //   // log('_getMainProductRenderProperties editableShippingRatesMy =>', editableShippingRatesMy)
  //
  //   if (editable) {
  //     if (_.includes([EDIT_SELF, EDIT_SELLER], mode)) {
  //       editableName = editable.includes(NAME)
  //       editableDesc = editable.includes('description')
  //       editableImg = editable.includes('img_uris')
  //       // currently not allow editing shipping rates if it's a pulled product
  //     } else if (_.includes([PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER], mode)) {
  //       editableDesc = editableImg = false
  //     } else if (_.includes([PULL_PRODUCT], mode)) {
  //       editableName = editableDesc = editableImg = true
  //     }
  //   } else {
  //     editableDesc = editableImg = false
  //   }
  //
  //   // force Readonly while uploading
  //   if (uploading) {
  //     editableName = editableDesc = editableImg = false
  //     isAddMode = false
  //   }
  //
  //   // log('ViewMode: ' + mode)
  //   // log('product: ' + product.toJS())
  //   // log('editable: ' + editable  )
  //   // log('editableName1: ' + editableName)
  //   // log('editableDesc: ' + editableDesc)
  //
  //   editableName = editableName || isAddMode
  //   editableDesc = editableDesc || isAddMode
  //   editableImg = editableImg || isAddMode
  //   editableCategory = isAddMode
  //   // editableShippingRates = editableShippingRates || isAddMode
  //
  //   const shippingRatesMy = product.get('shipping_rates')
  //   const shippingRatesSeller = product.get('seller_shipping_rates')
  //   const shippingRateStatusesMy = product.get('shippingRateStatuses')
  //   const shippingRateStatusesSeller = this._getSellerShippingRateStatuses(shippingRatesSeller)
  //
  //   // util.logPrettyObj('shippingRatesMy',shippingRatesMy)
  //   // util.logPrettyObj('shippingRatesSeller',shippingRatesSeller)
  //   // util.logPrettyObj('shippingRateStatusesMy',shippingRateStatusesMy)
  //   // util.logPrettyObj('shippingRateStatusesSeller',shippingRateStatusesSeller)
  //
  //   // TODO: Once backend support having own shipping rates on pulled product, remove the 'if' block
  //   // and, in the 'else' block, switch the eligibleModes with the commented below
  //   // eligibleModes: [ADD, VIEW_SELF, VIEW_SELLER, EDIT_SELF, EDIT_SELLER, PULL_MY_PRODUCT_TO_ORDER, PULL_PRODUCT] })
  //   const isDoneInitDataLoading = this._isDoneInitDataLoading()
  //   let shallRenderShippingRatesMy =
  //     !isSellerProduct && isDoneInitDataLoading && this.canAutoCalcShippingRateByQty && this.canDoAtSelectedStore[PRODUCT_SHIPPING_QTY_RATE] // quick check
  //   if (!shallRenderShippingRatesMy) {
  //     // more in-depth check as it's neccessory
  //     shallRenderShippingRatesMy = this._shallRenderShippingRates({
  //       mode,
  //       isDoneInitDataLoading,
  //       shippingRates: shippingRatesMy,
  //       shippingRateStatuses: shippingRateStatusesMy,
  //       eligibleModes: [ADD, VIEW_SELF, EDIT_SELF, PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER],
  //     })
  //     // log('!shallRenderShippingRatesMy _getMainProductRenderProperties shallRenderShippingRatesMy =>', shallRenderShippingRatesMy)
  //   }
  //   // log('_getMainProductRenderProperties isDoneInitDataLoading =>', isDoneInitDataLoading)
  //   // log('_getMainProductRenderProperties editableShippingRatesMy =>', editableShippingRatesMy)
  //   // log('_getMainProductRenderProperties isSellerProduct =>', isSellerProduct)
  //   // log('_getMainProductRenderProperties shallRenderShippingRatesMy =>', shallRenderShippingRatesMy)
  //
  //   const shallRenderShippingRatesSeller = this._shallRenderShippingRates({
  //     mode,
  //     isDoneInitDataLoading,
  //     shippingRates: shippingRatesSeller,
  //     shippingRateStatuses: shippingRateStatusesSeller,
  //     // eligibleModes: [VIEW_SELF, VIEW_SELLER, EDIT_SELF, EDIT_SELLER, PULL_MY_PRODUCT_TO_ORDER, PULL_PRODUCT] })
  //     eligibleModes: [VIEW_SELF, VIEW_SELLER, EDIT_SELF, EDIT_SELLER, PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER, PULL_PRODUCT],
  //   })
  //
  //   // VIEW_SELF: View product on my own stores regardless either my own or pulled products
  //   // EDIT_SELF: Edit product on my own stores regardless either my own or pulled products
  //
  //   // util.logPrettyObj('shallRenderShippingRatesMy',shallRenderShippingRatesMy)
  //   // util.logPrettyObj('shallRenderShippingRatesSeller',shallRenderShippingRatesSeller)
  //
  //   // log('product.get(\'img_uris\') => ', product.get('img_uris'))
  //   // const img_uris = isImmutable(product.get('img_uris')) ? product.get('img_uris').toJS() : product.get('img_uris')
  //   // const thumbnail_uris = isImmutable(product.get('thumbnail_uris')) ? product.get('thumbnail_uris').toJS() : product.get('thumbnail_uris')
  //
  //   const pName = product.get(NAME) || null
  //   const pDesc = product.get('description') || null
  //   const txtShareContent = pName && pDesc ? `${pName}\n${pDesc}\n` : null
  //
  //   return {
  //     editableImg,
  //     editableName,
  //     editableDesc,
  //     editableCategory,
  //     txtShareContent,
  //
  //     shallRenderShippingRatesSeller,
  //     shallRenderShippingRatesMy,
  //     shippingRatesMy,
  //     shippingRatesSeller,
  //     shippingRateStatusesMy,
  //     shippingRateStatusesSeller,
  //     editableShippingRatesMy,
  //   }
  // }

  _getShareClipboardProperties = (): { txtShareContent?: string; shareClipboardToastMessage?: string } => {
    const { editingProduct } = this.props
    if (!Map.isMap(editingProduct)) {
      return {
        txtShareContent: null,
        shareClipboardToastMessage: null,
      }
    }
    const pName = editingProduct.get(NAME) || ''
    const pDesc = editingProduct.get('description') || ''
    return {
      txtShareContent: `${pName}\n${pDesc}\n`,
      shareClipboardToastMessage: 'คัดลอกชื่อและรายละเอียดสินค้าแล้ว',
    }
  }

  // TODO: Move this logic to initialize compute variant key/properties
  _getComputeVariantItemProperties = ({
    idx,
    mode,
    key,
    variant,
    icon,
    label,
    placeholder,
    isNumber,
    isInteger,
    isMoney,
    hasOnlyOneVariant,
  }): {
    value: any
    editable: boolean
  } | null => {
    const { editingProduct } = this.props
    const product_group_ids = editingProduct.has('product_group_ids') ? editingProduct.get('product_group_ids') : List([])

    // hide if is not in selected groups ids
    if (_.isString(key) && key.startsWith(PRICE) && List.isList(product_group_ids)) {
      // log('_getComputeVariantItemProperties product_group_ids.toJS() => ', product_group_ids.toJS())
      const priceKeyArr = key.split('_')
      // log('_getComputeVariantItemProperties priceKeyArr => ', priceKeyArr)
      if (priceKeyArr.length > 1) {
        const pgId = parseInt(priceKeyArr[1])
        const isInSelectedGroupsIndex = product_group_ids.findIndex((selectedId) => selectedId === pgId)
        if (isInSelectedGroupsIndex === -1) {
          return null
        }
      }
    }

    if (hasOnlyOneVariant && key === NAME) {
      return null
    }

    // Safety logic to hide important variants
    if (key === WEIGHT && (this._isSellerProduct() || !this.canAutoCalcShippingRateByWeight)) {
      return null
    }
    if (_.isString(key) && key.startsWith(PRICE) && !this.canDoAtSelectedStore[PRODUCT_PRICE]) {
      // } else if ((key === PRICE || key === PRICE_RETAIL) && !this.canDoAtSelectedStore[PRODUCT_PRICE]) {
      return null
    }
    if (key === COST && !this.canDoAtSelectedStore[PRODUCT_COST]) {
      return null
    }
    if ((key === QTY || key === AVAILABLE_QTY) && !this.canDoAtSelectedStore[PRODUCT_STOCK_EDIT]) {
      return null
    }
    if (key === WEIGHT && !this.canDoAtSelectedStore[PRODUCT_WEIGHT]) {
      return null
    }

    let isEditable = _.has(this.isEditableProductVariantsMap, key) ? this.isEditableProductVariantsMap[key] : false

    // editable for dynamic price
    if (_.isString(key) && key.startsWith(PRICE)) {
      isEditable = _.has(this.isEditableProductVariantsMap, PRICE) ? this.isEditableProductVariantsMap[PRICE] : false
    }

    const value = Map.isMap(variant) && variant.has(key) ? variant.get(key) : null
    let theValue =
      (!isEditable || _.includes([EDIT_SELF, EDIT_SELLER], mode)) && (isNumber || isInteger) && !_.isNull(value) && value.length === 0
        ? '0'
        : value
    if (isMoney) {
      if (!isEditable) {
        theValue = !_.isNull(value) && !_.isUndefined(value) && value.length > 0 ? `฿${value}` : '฿0'
      } else {
        theValue = !_.isNull(value) && !_.isUndefined(value) && value.length > 0 ? `฿${value}` : ''
      }
    } else if (!isEditable && key === WEIGHT) {
      theValue += 'g'
    }

    // ถ้ากรณีไม่ใช่สินค้าของฉัน แล้วมี available qty < 0 ควรเห็นเป็น 0
    const parent_id = editingProduct.get('parent_id') ? editingProduct.get('parent_id') : null
    if (key === AVAILABLE_QTY && value < 0 && (mode === PULL_SELLER_PRODUCT_TO_ORDER || parent_id)) {
      theValue = 0
    }

    if (_.includes([PULL_SELLER_PRODUCT_TO_ORDER, PULL_MY_PRODUCT_TO_ORDER], mode) && key === QTY) {
      const currAvailableQty = editingProduct.getIn(['variants', idx, AVAILABLE_QTY])
      // log('idx: ' + idx + ' currAvailableQty => ', JSON.stringify(currAvailableQty))
      if (currAvailableQty <= 0) {
        isEditable = false
        theValue = 'สินค้าหมดสต็อก'
      }
    }

    if (this.state.uploading) {
      isEditable = false
    }

    return {
      value: theValue,
      editable: _.isBoolean(isEditable) ? isEditable : false,
    }
  }

  _isAddableVariants = (): boolean => {
    const { mode } = this.state
    const has_parent = this.props.editingProduct.get('parent_id') || false
    // const has_child = editingProduct.get('has_child') || false

    let isAddableVariants = false
    // if (mode === ADD || (!has_child && !has_parent && _.includes([EDIT_SELF, EDIT_SELLER], mode))) {
    if (mode === ADD || (!has_parent && _.includes([EDIT_SELF, EDIT_SELLER], mode))) {
      isAddableVariants = true
    }
    return isAddableVariants
  }

  _isRemoveableVariant = (variantIndex: number): boolean => {
    const { mode } = this.state
    const isAddableVariants = this._isAddableVariants()
    const has_ordered = this.props.editingProduct.getIn(['variants', variantIndex, 'has_ordered']) || false
    const variants = this.props.editingProduct.get('variants') || List([])
    let isRemoveableVariant = false
    if (variants.size > 1 && (mode === ADD || (isAddableVariants && _.includes([EDIT_SELF, EDIT_SELLER], mode)))) {
      isRemoveableVariant = true
    }
    return isRemoveableVariant
  }

  _isDeletedVariant = (variantIndex: number): boolean => this.props.editingProduct.getIn(['variants', variantIndex, 'isDeleted']) || false

  _handlePressRemoveVariant = (variantIndex: number) => {
    this.props.removeProductVariant(variantIndex)
  }

  _handleOnChangeVariant = (arg: { idx: number; key: string; value: any; isInteger?: boolean; isNumber?: boolean; isMoney?: boolean }) => {
    // log('_handleOnChangeVariant arg => ', arg)
    const { value, isInteger, isNumber, isMoney } = arg
    // let validatedParams = _.cloneDeep(arg)
    // if ((isNumber || isInteger || isMoney) && value === '') {
    //   validatedParams.value = 0
    // }
    // this.props.onChangeVariant(validatedParams)
    this.props.onChangeVariant(arg)
  }

  _handleOpenReportHistory = async () => {
    xUtil.setStatePromise(this, {
      isReportHistory: true,
    })
  }

  _handleCloseReportHistory = async () => {
    xUtil.setStatePromise(this, {
      isReportHistory: false,
      selectedOptSegment: 0,
    })
  }

  _handleOpenReportProductDailySales = async () => {
    // console.log('isReportProductDailySales ', this.state.isReportProductDailySales)
    xUtil.setStatePromise(this, {
      isReportProductDailySales: true,
    })
  }

  _handleCloseReportProductDailySales = async () => {
    xUtil.setStatePromise(this, {
      isReportProductDailySales: false,
    })
  }

  _openCategoryList = async () => {
    await xUtil.setStatePromise(this, {
      visibleCategoryList: true,
    })
  }

  _closeCategoryList = () => {
    xUtil.setStatePromise(this, {
      visibleCategoryList: false,
    })
  }

  _submitSeletedCategory = async (category: ICategory) => {
    const { onChangeProduct } = this.props
    const newCat = fromJS({ name: category.n, id: category.id, color: category.l })
    onChangeProduct({ key: CATEGORY, value: newCat })
    // await xUtil.setStatePromise(this, {
    // 	category,
    // })
  }

  getVariants = () => {
    const { editingProduct } = this.props
    if (!Map.isMap(editingProduct)) {
      return List([])
    }
    const variants: List<IProductDetailVariantMap> = editingProduct.get('variants') || List([])
    return variants
  }

  getVariantCount = () => {
    const variants: List<IProductDetailVariantMap> = this.getVariants()
    const variantCount = variants.size || 0
    return variantCount
  }

  onPressVariantPagePrev = () => {
    const { variantPageIndex = 0 } = this.state
    const nextPageIndex = variantPageIndex - 1

    if (nextPageIndex < 0) {
      this.setState({ variantPageIndex: 0 })
      return
    }

    this.setState({ variantPageIndex: nextPageIndex })
  }

  onPressVariantPageNext = () => {
    const { variantPageIndex = 0 } = this.state
    const nextPageIndex = variantPageIndex + 1

    if (nextPageIndex >= this.getVariantPageCount()) {
      return
    }

    this.setState({ variantPageIndex: nextPageIndex })
  }

  onPressVariantPagePrev5 = () => {
    const { variantPageIndex = 0 } = this.state
    const nextPageIndex = variantPageIndex - 5

    if (nextPageIndex < 0) {
      this.setState({ variantPageIndex: 0 })
      return
    }

    this.setState({ variantPageIndex: nextPageIndex })
  }

  onPressVariantPageNext5 = () => {
    const { variantPageIndex = 0 } = this.state
    const nextPageIndex = variantPageIndex + 5

    if (nextPageIndex >= this.getVariantPageCount()) {
      const lastPageIndex = this.getVariantPageCount() - 1
      this.setState({ variantPageIndex: lastPageIndex })
      return
    }

    this.setState({ variantPageIndex: nextPageIndex })
  }

  getVariantPageItems = () => {
    const { editingProduct } = this.props
    if (!Map.isMap(editingProduct)) {
      return List([])
    }

    const variants: List<IProductDetailVariantMap> = editingProduct.get('variants') || List([])

    if (this.isVariantPageFiltered()) {
      return variants.filter((v) => this.isInVariantPageFilter(v))
    }

    return variants
  }

  getVariantPageItemCount = () => {
    const variants = this.getVariantPageItems()
    const variantCount = variants.size || 0
    return variantCount
  }

  isVariantPageFiltered = () => {
    const { isVariantPageFiltered, variantPageFilterOptions } = this.state

    if (!isVariantPageFiltered) {
      return false
    }

    if (!_.isObject(variantPageFilterOptions) || _.isEmpty(variantPageFilterOptions)) {
      return false
    }

    return true
  }

  isInVariantPageRange = (variantIndex: number) => {
    const minIdx = this.getVariantPageMinIndex()
    const maxIdx = this.getVariantPageMaxIndex()
    return variantIndex >= minIdx && variantIndex <= maxIdx
  }

  isInVariantPageFilter = (variant: IProductDetailVariantMap): boolean => {
    // if (!this.isVariantPageFiltered()) {
    //   return true
    // }
    const { variantPageFilterOptions = { filterKeys: [], keyword: '' } } = this.state

    // no filter
    if (!variantPageFilterOptions || (!_.isString(variantPageFilterOptions.keyword) && variantPageFilterOptions.keyword === 0)) {
      return true // bypass
    }

    const { filterKeys, keyword } = variantPageFilterOptions

    // console.log('isInVariantPageFilter variant => ', variant.toJS())
    for (let i = 0; i < filterKeys.length; i++) {
      try {
        const vKey = filterKeys[i]
        // @ts-ignore
        const value: string = variant.get(vKey) || ''

        const keywordList = keyword.split(' ')

        let isIntersectKeywordsMatch = true
        for (let j = 0; j < keywordList.length; j++) {
          const kw = keywordList[j]
          const isKwMatch = value.toLowerCase().includes(kw.toLowerCase())
          // if (isKwMatch) {
          //   return true
          // }
          isIntersectKeywordsMatch = isIntersectKeywordsMatch && isKwMatch
        }

        if (isIntersectKeywordsMatch) {
          return true
        }
        // return isIntersectKeywordsMatch
      } catch (err) {
        console.log('isInVariantPageFilter err => ', err)
        // return true
      }
    }

    return false
  }

  _checkExceedVariantPage = async () => {
    const { variantPageIndex = 0 } = this.state
    const currentPage = variantPageIndex + 1

    if (currentPage >= this.getVariantPageCount()) {
      const lastPageIndex = this.getVariantPageCount() - 1
      await xUtil.setStatePromise(this, { variantPageIndex: lastPageIndex })
      await xUtil.delay(100)
    }

    if (variantPageIndex < 0) {
      await xUtil.setStatePromise(this, { variantPageIndex: 0 })
      await xUtil.delay(100)
    }
  }

  // getVariantCount = () => {
  //   const { editingProduct } = this.props
  //   if (!Map.isMap(editingProduct)) {
  //     return 0
  //   }
  //   const variants = editingProduct.get('variants') || List([])
  //   const variantCount = variants.size || 0
  //   return variantCount
  // }

  // getVariantPageItemLimit = () => this.VariantPageItemLimit

  getVariantPageCount = () => {
    const variantCount = this.getVariantPageItemCount()
    const pageCount = Math.ceil(variantCount / this.VariantPageItemLimit)
    return pageCount
  }

  getVariantPageMinIndex = () => {
    const { variantPageIndex = 0 } = this.state
    const minIdx = this.VariantPageItemLimit * variantPageIndex
    return minIdx
  }

  getVariantPageMaxIndex = () => {
    const minIdx = this.getVariantPageMinIndex()
    const maxIdx = minIdx + this.VariantPageItemLimit - 1
    return maxIdx
  }

  getVariantPageIndex = (ppId: number): number | null => {
    if (_.isEmpty(this.variantPagePpIdToIndex)) {
      const { selectedProduct } = this.props
      const selectedVariants = selectedProduct.get('variants') || List([])
      selectedVariants.forEach((v, i) => {
        const vPpId = v.get('pp_id')
        this.variantPagePpIdToIndex[vPpId] = i
      })
    }

    try {
      return this.variantPagePpIdToIndex[ppId]
    } catch (error) {
      return null
    }
  }

  submitVariantPageFilter = async (opt: IVariantFilterOptions) => {
    await xUtil.setStatePromise(this, { variantPageFilterOptions: opt })
    await xUtil.delay(100)

    const { editingProduct } = this.props
    const variants: List<IProductDetailVariantMap> = editingProduct.get('variants') || List([])
    const filteredVariantIndexes = []
    const filteredVariants = variants.filter((v, i) => {
      const isInFilter = this.isInVariantPageFilter(v)
      if (isInFilter) {
        filteredVariantIndexes.push(i)
      }
      return isInFilter
    })

    // console.log('submitVariantPageFilter filteredVariants => ', filteredVariants.toJS())
    // console.log('submitVariantPageFilter filteredVariantIndexes => ', filteredVariantIndexes)
    await xUtil.setStatePromise(this, {
      isVariantPageFilterVisible: false,
      isVariantPageFiltered: true,
      filteredVariantIndexes,
      filteredVariants,
      variantPageIndex: 0,
    })
  }

  openVariantFilter = () => {
    this.setState({ isVariantPageFilterVisible: true })
  }

  closeVariantFilter = () => {
    this.setState({ isVariantPageFilterVisible: false })
  }

  removeVariantFilter = () => {
    this.setState({ isVariantPageFiltered: false, variantPageFilterOptions: null })
  }

  // changeProductWarehouses = (warehousesIds: number[]) => {
  //   // log(`callBackEditWarehouses !! `, warehousesIds)
  //   const { onChangeProduct } = this.props
  //   if (!_.isFunction(onChangeProduct)) {
  //     return
  //   }
  //   onChangeProduct({ key: 'warehouse_ids', value: fromJS(warehousesIds) })
  // }

  isNavToReservedProductsOrderListViewDisabled = () => {
    const { reservedProductCheckMap = {} } = this.state
    if (_.isEmpty(reservedProductCheckMap)) {
      return true
    }

    const ppIds = Object.keys(reservedProductCheckMap)
    for (let i = 0; i < ppIds.length; i++) {
      const ppId = ppIds[i]
      // ถ้ามีอย่างน้อย 1 checked แสดงว่ากดได้
      if (reservedProductCheckMap[ppId]) {
        return false
      }
    }

    return true
  }

  navToReservedProductsOrderListView = () => {
    this.closeReservedProductInOrdersOverlay()

    const { reservedProductCheckMap = {} } = this.state
    const checkedPpIds = Object.keys(reservedProductCheckMap)
      .filter((ppId) => reservedProductCheckMap[ppId])
      .map((ppStr) => parseInt(ppStr))

    this.doNavToReservedProductsOrderListView(checkedPpIds)
  }

  doNavToReservedProductsOrderListView = (ppIds: number[]) => {
    const { navigation } = this.props
    const overrideRequestBody: Partial<IFetchOrdersRequestBody> = {
      unshipped_pp_ids: ppIds,
    }

    navigation.dispatch(
      NavActions.navToReservedProductsOrderListView({
        store_id: xUtil.getNavParam(this.props, 'store_id'),
        overrideRequestBody,

        // เนื่องจาก default ใหม่ของเรามัน 2 เดือน การ search เราเลยต้องปรับใหม่เป็น 5 ปีเพื่อให้มันค้นได้ทั่วถึงมากขึ้น
        ordersDefaultFilterSetting: {
          createOrderDateRangeOptionKey: xCONS.DATERANGE_SELECTOR.OPTION.Custom,
          createOrderFrom: dayjs().subtract(5, 'years').startOf('day'),
          createOrderTo: dayjs().endOf('day'),
        },
      })
    )
  }

  openReservedProductInOrdersOverlay = async () => {
    this.inProcess = true

    const variants = this.getVariants()
    const vCount = variants.size
    const reservedProductCheckMap = {}
    for (let i = 0; i < vCount; i++) {
      const ppId = variants.getIn([i, 'pp_id'])
      if (ppId) {
        reservedProductCheckMap[ppId] = true
      }
    }

    if (vCount === 1) {
      await xUtil.setStatePromise(this, { reservedProductCheckMap })
      await xUtil.delay(100)
      this.inProcess = false
      this.navToReservedProductsOrderListView()
      return
    }

    await xUtil.setStatePromise(this, { isReservedProductOverlayVisible: true, reservedProductCheckMap })

    this.inProcess = false
  }

  closeReservedProductInOrdersOverlay = async () => {
    await xUtil.setStatePromise(this, { isReservedProductOverlayVisible: false })
  }

  getOnPressToggleCheckReservedProductFn = (ppId: number) => () => {
    const { reservedProductCheckMap: rpcm = {} } = this.state
    const reservedProductCheckMap = _.cloneDeep(rpcm)
    reservedProductCheckMap[ppId] = !reservedProductCheckMap[ppId] || false
    this.setState({ reservedProductCheckMap })
  }

  onPressToggleAllCheckReservedProduct = () => {
    this.inProcess = true

    const isAllChecked = this.isReservedProductAllChecked()
    const { reservedProductCheckMap: rpcm = {} } = this.state
    const reservedProductCheckMap = _.cloneDeep(rpcm)
    const variants = this.getVariants()

    for (let i = 0; i < variants.size; i++) {
      const ppId = variants.getIn([i, 'pp_id'])
      if (ppId) {
        reservedProductCheckMap[ppId] = !isAllChecked
      }
    }

    this.setState({ reservedProductCheckMap })

    this.inProcess = false
  }

  isReservedProductChecked = (ppId: number) => {
    const { reservedProductCheckMap = {} } = this.state
    const isChecked = reservedProductCheckMap[ppId] || false
    return isChecked
  }

  isReservedProductAllChecked = () => {
    const variants = this.getVariants()
    const { reservedProductCheckMap = {} } = this.state

    for (let i = 0; i < variants.size; i++) {
      const ppId = variants.getIn([i, 'pp_id'])
      if (ppId && !reservedProductCheckMap[ppId]) {
        return false
      }
    }

    return true
  }

  getCheckedReservedProductVariantCount = () => {
    const variants = this.getVariants()
    const { reservedProductCheckMap = {} } = this.state
    let count = 0

    for (let i = 0; i < variants.size; i++) {
      const ppId = variants.getIn([i, 'pp_id'])
      if (ppId && reservedProductCheckMap[ppId]) {
        count += 1
      }
    }

    return count
  }

  onPressCloneProduct = async () => {
    const isUserConfirm = await p.op.isUserConfirm(
      'สร้างสินค้าใหม่โดยใช้ข้อมูลจากสินค้าชิ้นนี้',
      'กรุณายืนยันเพื่อเปลี่ยนไปสร้างสินค้าใหม่ โดยจะคัดลอกข้อมูลทั้งหมดจากสินค้าชิ้นนี้'
    )
    if (!isUserConfirm) {
      return
    }

    await xUtil.setStatePromise(this, { mode: ADD })
    await xUtil.delay(100)
    this.props.loadCloneProduct()
    await xUtil.delay(50)
    this._loadImagesToImgMgr()
  }
}
