import { IXAppConfig, IEndpointConfig } from './index'
import CONS from '../constants'

export const RUNTIME = {
  MODE: 'dev',
  IS_XSELLY_BUILD: true,
  PROD_TEST: true,
  CONSOLE_LOG: false,
  APP_NAME: CONS.APP_CONFIG_NAMES.XSELLY,
}

export const FLAVOUR_CONFIG = {
  dev_app_apiRoot: 'https://superdev.xselly.com/api',
  dev_app_goApiRoot: 'https://superdevgo.xselly.com/api',
  dev_s3_options_keyPrefix: 'tmp/',
  dev_s3_prefix_main: 'd/',

  prod_app_apiRoot: 'https://api.xselly.com/api',
  prod_app_goApiRoot: 'https://api2.xselly.com/api',
  prod_s3_options_keyPrefix: 'p/f/',
  prod_s3_prefix_main: 'x/',
}

export const RUNTIME_ENDPOINT_CONFIG: IEndpointConfig = {
  // Specific S3 config b/w dev and prod
  app_apiRoot: FLAVOUR_CONFIG.dev_app_apiRoot,
  app_goApiRoot: FLAVOUR_CONFIG.dev_app_goApiRoot,
  s3_options_keyPrefix: FLAVOUR_CONFIG.dev_s3_options_keyPrefix,
  s3_prefix_main: FLAVOUR_CONFIG.dev_s3_prefix_main,

  // Common S3 config for both dev and prod
  // s3_options_bucket: 'xsf',
  s3_prefix_type_shipping_label: 's/', // shipping labels
  s3_prefix_type_product: 'p/',
  s3_prefix_type_payslip: 'l/',
  s3_prefix_type_user_group: 'u/',
  s3_prefix_type_cover_store: 's/',
  s3_prefix_type_profile: 'm/',
  s3_prefix_type_org_register: 'o/',
}

export const RUNTIME_ORG_OWNER_APP_CONFIG: IXAppConfig = {
  app_name: RUNTIME.APP_NAME,
  // หน้าแรก ==>
  my_store: {
    // ส่วนต่างๆ ==>
    section: {
      // ส่วนหัว
      header: {
        show_add_store_btn: true,
        show_user_manual_btn: true,
      },
      // ออเดอร์ ==>
      order: {
        show_section: true,
        show_donut: true,
        show_order_list_btn: true,
        show_confirm_payments_btn: true,
        show_open_order_btn: true,
        show_print_shipping_label_btn: true,
        show_scan_shipping_btn: true,
      },
      // Xshipping ==>
      xshipping: {
        show_section: true,
        show_has_tracking_number_btn: true,
        show_requesting_tracking_number_btn: true,
        show_fail_tracking_number_btn: true,
      },
      // สินค้า ==>
      product: {
        show_section: true,
        show_product_list: true,
        show_pull_to_my_store: false,
        show_product_add: false,
        show_scan_product: true,
      },
      // อื่น ==>
      other: {
        show_section: true,
        show_user_group: true,
        show_product_group: false,
        show_report: true,
        show_volume_discount: true,
        show_org_register_member: false,
        show_reseller_list: false,
        show_pending_member_list: false,
        show_warehouse_list: true,
        show_mkp_channel_list: true,
        show_bgjob_list: true,
      },
    },
  },
  seller_stores: {
    show_reseller_registration_box: true,
    can_select_store: true,
  },
  setting_view: {
    show_menu_customer: true,
    show_menu_guidance: true,
  },
  // ออเดอร์ ==>
  order: {
    // สร้างออเดอร์ ==>
    create_order: {
      // แสดง เมนูจุดไข่ปลาด้านขวาบน
      show_3dots_menu: false,

      // ที่อยู่หลัก และ ที่อยู่รอง
      show_receiver_address_index_segment_btn: false,

      // ช่องใส่รหัสตัวแทน
      show_reseller_code_text_input: false,

      // จะเปิดออเดอร์ได้แค่ร้านฉัน
      show_select_products_from_my_store_only_btn: false,

      // ปุ่มเพิ่มสินค้าด้วยการสแกน sku upc
      show_add_product_by_sku_btn: false,
      show_add_product_by_upc_btn: true,
      // ดึงสินค้าเข้าออเดอร์
      quick_add_product_to_order_qty: 1,
    },
    view_order: {
      show_3dots_menu_item_custom_fields_setting_view: true,
      allow_edit_address_receiver: true,
      allow_edit_address_sender: true,
    },
  },
  product: {
    show_3dots_menu: true,
    show_menu_edit_product: true,
    show_menu_barcode: true,
    show_menu_delete_product: true,
    show_non_stock_qty: true,
  },
  volume_discount: {
    show_add_btn: false,
    show_edit_btn: false,
  },
  store_setting: {
    section: {
      my_store: {
        show_section: true,
        show_menu_edit_store: true,
        show_menu_store_address: true,
        show_menu_store_payment_accounts: true,
        show_menu_setting_helper: true,
        show_menu_print_document_settings: true,
      },
      order: {
        show_section: true,
        show_menu_sender_address: true,
        show_menu_link_order: true,
        show_menu_auto_confirm_order: true,
        show_menu_auto_lock_order: true,
        show_menu_auto_cancel_order: true,
        show_menu_ship_before_pay_and_cod: true,
        show_menu_order_note_settings: true,
      },
      product: {
        show_section: true,
        show_menu_category_manage: true,
        show_menu_volume_discount: true,
        show_menu_sku_upc: true,
      },
      warehouse: {
        show_section: true,
        // show_menu_warehouse_list: true,
      },
    },
  },
  my_address_list_view: {
    show_add_btn: true,
    show_edit_btn: true,
    show_delete_btn: true,
  },
  // หน้า รายการบัญชีทั้งหมด
  payment_account_list_view: {
    // บัญชัอื่นๆ
    can_edit_info_others_bank: true,
    // บัญชีเงินสด
    can_edit_info_cash: true,
  },
  about_view: {
    show_contact_info_section: true,
  },
  // หน้ากลุ่มสมาชิก
  user_group_list_view: {
    show_add_btn: true,
    show_setting_group_btn: true,
    show_pg_btn: true,
    show_copy_link_group_btn: true,
  },
  user_group_member_list_view: {
    show_add_reseller_tab: true,
    show_accept_member_btns: true,
    show_manage_member_menu: true,
  },
  templateImportResellerUrl: 'https://app.somsai.co.th/x/import_reseller_template.xlsx',
  report: {
    complete_sales_report: {
      limit_num_of_days_per_download: 5,
    },
  },
}

export const RUNTIME_DEFAULT_APP_CONFIG: IXAppConfig = {
  app_name: RUNTIME.APP_NAME,
  // หน้าแรก ==>
  my_store: {
    // ส่วนต่างๆ ==>
    section: {
      // ส่วนหัว
      header: {
        show_add_store_btn: true,
        // ปุ่มไอคอนสมุด
        show_user_manual_btn: true,
      },
      // ออเดอร์ ==>
      order: {
        show_section: true,
        show_donut: true,
        show_order_list_btn: true,
        show_confirm_payments_btn: true,
        show_open_order_btn: true,
        show_print_shipping_label_btn: true,
        show_scan_shipping_btn: true,
      },
      // Xshipping ==>
      xshipping: {
        show_section: true,
        show_has_tracking_number_btn: true,
        show_requesting_tracking_number_btn: true,
        show_fail_tracking_number_btn: true,
      },
      // สินค้า ==>
      product: {
        show_section: true,
        show_product_list: true,
        show_pull_to_my_store: true,
        show_product_add: true,
        show_scan_product: true,
      },
      // อื่น ==>
      other: {
        show_section: true,
        show_user_group: true,
        show_product_group: true,
        show_report: true,
        show_volume_discount: true,
        show_org_register_member: false,
        show_reseller_list: false,
        show_pending_member_list: false,
        show_warehouse_list: true,
        show_mkp_channel_list: true,
        show_bgjob_list: true,
      },
    },
  },
  // หน้า ร้านผู้ขายส่ง
  seller_stores: {
    show_reseller_registration_box: true,
    can_select_store: true,
  },
  // ออเดอร์ ==>
  order: {
    // สร้างออเดอร์ ==>
    create_order: {
      // แสดง เมนูจุดไข่ปลาด้านขวาบน
      show_3dots_menu: true,

      // ที่อยู่หลัก และ ที่อยู่รอง
      show_receiver_address_index_segment_btn: false,

      // ช่องใส่รหัสตัวแทน
      show_reseller_code_text_input: false,

      // จะเปิดออเดอร์ได้แค่ร้านฉัน
      show_select_products_from_my_store_only_btn: false,

      // ปุ่มเพิ่มสินค้าด้วยการสแกน sku upc
      show_add_product_by_sku_btn: true,
      show_add_product_by_upc_btn: true,

      // ดึงสินค้าเข้าออเดอร์
      quick_add_product_to_order_qty: 1,
    },
    view_order: {
      show_3dots_menu_item_custom_fields_setting_view: true,
      allow_edit_address_receiver: true,
      allow_edit_address_sender: true,
    },
  },
  // หน้าสินค้า ==>
  product: {
    show_3dots_menu: true,
    show_menu_edit_product: true,
    show_menu_barcode: true,
    show_menu_delete_product: true,
    show_non_stock_qty: true,
  },
  // หน้าส่วนลดขายส่ง ==>
  volume_discount: {
    show_add_btn: true,
    show_edit_btn: true,
  },
  // หน้า ตั้งค่า ==>
  store_setting: {
    section: {
      my_store: {
        show_section: true,
        show_menu_edit_store: true,
        show_menu_store_address: true,
        show_menu_store_payment_accounts: true,
        show_menu_setting_helper: true,
        show_menu_print_document_settings: true,
      },
      order: {
        show_section: true,
        show_menu_sender_address: true,
        show_menu_link_order: true,
        show_menu_auto_confirm_order: true,
        show_menu_auto_lock_order: true,
        show_menu_auto_cancel_order: true,
        show_menu_ship_before_pay_and_cod: true,
        show_menu_order_note_settings: true,
      },
      product: {
        show_section: true,
        show_menu_category_manage: true,
        show_menu_volume_discount: true,
        show_menu_sku_upc: true,
      },
      warehouse: {
        show_section: true,
        // show_menu_warehouse_list: true,
      },
    },
  },
  // หน้า อื่นๆ ==>
  setting_view: {
    show_menu_customer: true,
    show_menu_guidance: true,
  },
  // หน้า รายการชื่อร้านของตนเอง
  my_address_list_view: {
    show_add_btn: true,
    show_edit_btn: true,
    show_delete_btn: true,
  },
  // หน้า รายการบัญชีทั้งหมด
  payment_account_list_view: {
    // บัญชัอื่นๆ
    can_edit_info_others_bank: true,
    // บัญชีเงินสด
    can_edit_info_cash: true,
  },
  // หน้่าเกียวกับ XSelly
  about_view: {
    show_contact_info_section: true,
  },
  // หน้ากลุ่มสมาชิก
  user_group_list_view: {
    show_add_btn: true,
    show_setting_group_btn: true,
    show_pg_btn: true,
    show_copy_link_group_btn: true,
  },
  user_group_member_list_view: {
    show_add_reseller_tab: true,
    show_accept_member_btns: true,
    show_manage_member_menu: true,
  },
  templateImportResellerUrl: 'https://app.somsai.co.th/x/import_reseller_template.xlsx',
  report: {
    complete_sales_report: {
      limit_num_of_days_per_download: 5,
    },
  },
}
