/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react'
import api from 'x/utils/api'
import { IApiOptions, IErpContact, IMKPChannelDetail, ISelectedStoreMap, IXScreenProps } from 'x/types'
import * as util from 'x/utils/util'
import _ from 'lodash'
import p from 'x/config/platform-specific'

interface IBaseERPChannelContactProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
}

interface IBaseERPChannelContactState {
  erpMkpContacts: IErpContact[]
  mkpChannels: IMKPChannelDetail[]

  editingContactCodeIndex: number

  backUpErpMkpContacts: IErpContact[]
}

const XSELLY_LABLE = 'XSelly'

export default abstract class BaseERPChannelContact extends React.Component<IBaseERPChannelContactProps, IBaseERPChannelContactState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      erpMkpContacts: null,
      mkpChannels: null,
      editingContactCodeIndex: null,
      backUpErpMkpContacts: null,
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
  }

  async componentDidMount() {
    this._geMkpChannels()
    await this._fetchErpPaymentAccounts()
  }

  _fetchErpPaymentAccounts = async () => {
    const { selectedStore } = this.props
    const store_id = selectedStore.get('id')
    // const params = util.getNavParams(this.props)
    // const { erpChannel } = params
    const reqBody = {
      store_id,
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res: { erp_mkp_contacts: IErpContact[] } = await api.postV2('erp/contact/mkp/lists', reqBody, apiOptions)
    // console.log('res => ', res)
    await util.setStatePromise(this, {
      erpMkpContacts: res.erp_mkp_contacts,
      backUpErpMkpContacts: _.cloneDeep(res.erp_mkp_contacts),
    })
  }

  goBack = () => {
    const { editingContactCodeIndex } = this.state
    if (_.isNil(editingContactCodeIndex) || editingContactCodeIndex === -1) {
      util.navGoBack(this.props)
    } else {
      this._showConfirmationCancel()
    }
  }

  _showConfirmationCancel = () => {
    p.op.showConfirmation(
      '',
      'ต้องการยกเลิกการแก้ไขใช่หรือไม่',
      () => util.navGoBack(this.props),
      () => null,
      'ยกเลิกการแก้ไข',
      'แก้ไขต่อไป'
    )
  }

  _geMkpChannels = () => {
    const { selectedStore } = this.props
    // console.log('selectedStore => ', selectedStore.toJS())
    const mkpChannels = selectedStore.get('channels')
    this.setState({
      // @ts-ignore
      mkpChannels: mkpChannels.size > 0 ? mkpChannels.toJS() : null,
    })
  }

  _onChangeContactCode = (index: number, newCode: string) => {
    const { erpMkpContacts } = this.state
    const newErpMkpContacts = _.clone(erpMkpContacts)
    newErpMkpContacts[index].contact_code = newCode
    this.setState({
      erpMkpContacts: newErpMkpContacts,
    })
  }

  _onChangeContactName = (index: number, newCode: string) => {
    const { erpMkpContacts } = this.state
    const newErpMkpContacts = _.clone(erpMkpContacts)
    newErpMkpContacts[index].contact_name = newCode
    this.setState({
      erpMkpContacts: newErpMkpContacts,
    })
  }

  // ถ้าเป็นของ xselly ไม่ต้อง channel เข้ามา
  // _onChangeContactCode = (newCode: string, channel: IMKPChannelDetail) => {
  //   const { editingContactCodeIndex, erpMkpContacts = [], mkpChannels = [] } = this.state

  //   const updatedContacts = [...erpMkpContacts]
  //   const selectedChannel = mkpChannels[editingContactCodeIndex]

  //   if (_.isNil(channel)) {
  //     const xsellyContactIndex = erpMkpContacts.findIndex((contact) => contact.mkp_shop_name === XSELLY_LABLE)
  //     if (xsellyContactIndex === -1) {
  //       updatedContacts.push({ contact_code: newCode, mkp_shop_name: XSELLY_LABLE })
  //     } else {
  //       updatedContacts[xsellyContactIndex].contact_code = newCode
  //     }
  //   } else {
  //     const contactIndex = erpMkpContacts.findIndex((contact) => contact.mkp_ch_id === channel.id)
  //     if (contactIndex === -1) {
  //       updatedContacts.push({
  //         contact_code: newCode,
  //         mkp_ch_id: channel.id,
  //         mkp_shop_name: channel.name,
  //         mkp_img_uri: channel.img_uri,
  //       })
  //     } else {
  //       updatedContacts[contactIndex].contact_code = newCode
  //     }
  //   }

  //   this.setState({ erpMkpContacts: updatedContacts })
  // }

  _setBackUpErpMkpContacts = () => {
    const { erpMkpContacts } = this.state
    // console.log('_setBackUpErpMkpContacts => ')
    this.setState({
      backUpErpMkpContacts: _.clone(erpMkpContacts),
    })
  }

  _submitContact = async () => {
    const { editingContactCodeIndex, erpMkpContacts } = this.state
    const { selectedStore } = this.props
    const store_id = selectedStore.get('id')
    const selectedContact = erpMkpContacts[editingContactCodeIndex]
    const reqBody = {
      store_id,
      update: [
        {
          erp_sales_channel_contact_id: selectedContact.erp_sales_channel_contact_id,
          contact_name: selectedContact.contact_name,
          contact_code: selectedContact.contact_code,
        },
      ],
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res: { erp_mkp_contacts: IErpContact[] } = await api.postV2('erp/contact/mkp/update', reqBody, apiOptions)
    // console.log('res => ', res)
    if (res.erp_mkp_contacts) {
      p.op.showToast('บันทึกข้อมูลเรียบร้อยแล้ว', 'success')
      await util.setStatePromise(this, {
        erpMkpContacts: res.erp_mkp_contacts,
        backUpErpMkpContacts: _.cloneDeep(res.erp_mkp_contacts),
        editingContactCodeIndex: -1,
      })
    }
  }

  // _navToERPEditPaymentAccountView = () => {
  //   const { navigation, selectedStore } = this.props
  //   navigation.dispatch(
  //     NavActions.navToERPEditPaymentAccountView({
  //       store_id: selectedStore.get('id'),
  //     })
  //   )
  // }
}
