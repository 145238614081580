/* eslint-disable react/no-unused-state */
import React from 'react'
import { ISubscriptionPackageList, SubscriptionPackageListItems } from 'x/index'
import * as util from 'x/utils/util'
import _ from 'lodash'
import p from 'x/config/platform-specific'

interface IBaseSelectAddOnPackageViewProps {}

interface IBaseSelectAddOnPackageViewState {
  subscriptionPackageList: ISubscriptionPackageList
  // isOneTime: boolean
  tskuItems: SubscriptionPackageListItems[]
  selectedPackages: SubscriptionPackageListItems
  selectedBillingCycle: number
  sumAddOnPrice: number
  label: string
  itamTypeMode: 'addon_onetime' | 'addon_lifetime'
}

export default abstract class BaseSelectAddOnPackageView extends React.Component<
  IBaseSelectAddOnPackageViewProps,
  IBaseSelectAddOnPackageViewState
> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>
  // mainPackageItems: any

  constructor(props) {
    super(props)
    this.state = {
      subscriptionPackageList: null,
      tskuItems: null,
      selectedPackages: null,
      selectedBillingCycle: 0,
      sumAddOnPrice: 0,
      label: '',
      itamTypeMode: 'addon_onetime',
      // isOneTime: false,
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
    // @ts-ignore
    // eslint-disable-next-line react/no-unused-class-component-methods
    // this.mainPackageItems = xCONS.SUBSCRIPTION_MAIN_PACKAGE_ITEMS.map((item) => ({
    //   ...item,
    //   label: p.op.t(`Subscription.main.${item.key}`),
    // }))
  }

  async componentDidMount() {
    const params = this._getParams()
    const { subscriptionPackageList, tskuItems, selectedPackages, key } = params
    // console.log('selectedPackages => ', selectedPackages)
    // console.log('tskuItems => ', tskuItems)
    // const selectedBillingCycle = tskuItems[0].billing_cycle
    if (_.isNil(key)) {
      p.op.showConfirmationOkOnly('', 'key is null')
      this.goBack()
      return
    }
    await this._setLabelMode(key)
    const selectedBillingCycle = _.has(tskuItems[0], 'ui_is_selected_billing_cycle')
      ? tskuItems[0].billing_cycle
      : selectedPackages.billing_cycle
    await util.setStatePromise(this, {
      subscriptionPackageList,
      tskuItems,
      selectedPackages,
      selectedBillingCycle,
      itamTypeMode: tskuItems[0].item_type,
    })
    await this._sumAddOnTotalPayment(tskuItems)
    await this._editBillBillingCycle('MINUS')
    await this._editBillBillingCycle('PLUS')
  }

  _getParams = () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    return params
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  goBack = () => {
    // @ts-ignore
    util.navGoBack(this.props)
  }

  _setLabelMode = async (key: string) => {
    let label = ''
    switch (key) {
      case 'm_o_count':
        label = 'ออเดอร์'
        break
      case 'mkp_quota':
        label = 'ช่องทางขาย'
        break
      case 'ug_quota':
        label = 'กลุ่มสมาชิก'
        break
      case 'pg_quota':
        label = 'รายการราคา'
        break
      case 'helper_count':
        label = 'ผู้ช่วยร้าน'
        break
      case 'store_count':
        label = 'ร้านค้า'
        break
      default:
        label = ''
        break
    }
    await util.setStatePromise(this, {
      label,
    })
  }

  _getCountSumTotal = (item: SubscriptionPackageListItems) => {
    const itemSubtype = item.item_subtype
    const selectedCount = _.has(item, 'ui_selected_count') ? item.ui_selected_count : 0
    // console.log('selectedCount => ', selectedCount)
    let sumTotal = 0
    switch (itemSubtype) {
      case 'order_quota':
        // eslint-disable-next-line no-case-declarations
        const countOrder = item.data_json.m_o_count
        sumTotal = countOrder * selectedCount
        break
      case 'mkp_quota':
        // eslint-disable-next-line no-case-declarations
        const countMkpQuota = item.data_json.mkp_quota
        sumTotal = countMkpQuota * selectedCount
        break
      case 'ug_quota':
        // eslint-disable-next-line no-case-declarations
        const countUg = item.data_json.ug_quota
        sumTotal = countUg * selectedCount
        break
      case 'pg_quota':
        // eslint-disable-next-line no-case-declarations
        const countPg = item.data_json.pg_quota
        sumTotal = countPg * selectedCount
        break
      case 'helper_count':
        // eslint-disable-next-line no-case-declarations
        const countHelper = item.data_json.helper_count
        sumTotal = countHelper * selectedCount
        break
      case 'store_count':
        // eslint-disable-next-line no-case-declarations
        const countStore = item.data_json.store_count
        sumTotal = countStore * selectedCount
        break

      default:
        break
    }
    // console.log('sumTotal => ', sumTotal)
    return sumTotal
  }

  _addItem = async (item: SubscriptionPackageListItems) => {
    const { tskuItems } = this.state
    const newTskuItem = []
    tskuItems.forEach((tItem) => {
      const newItem = _.cloneDeep(tItem)
      if (tItem.sku === item.sku) {
        const selectedCount = _.has(item, 'ui_selected_count') ? item.ui_selected_count : 0
        newItem.ui_selected_count = selectedCount + 1
        newTskuItem.push(newItem)
      } else {
        newTskuItem.push(newItem)
      }
    })
    await util.setStatePromise(this, {
      tskuItems: newTskuItem,
    })
    // util.setStatePromise(this, { tskuItems: newTskuItem })
    await this._sumAddOnTotalPayment(newTskuItem)
  }

  _deleteItem = async (item: SubscriptionPackageListItems) => {
    const { tskuItems } = this.state
    const newTskuItem = []
    tskuItems.forEach((tItem) => {
      const newItem = _.cloneDeep(tItem)
      if (tItem.sku === item.sku) {
        const selectedCount = _.has(item, 'ui_selected_count') ? item.ui_selected_count : 0
        newItem.ui_selected_count = selectedCount - 1
        newTskuItem.push(newItem)
      } else {
        newTskuItem.push(newItem)
      }
    })
    await util.setStatePromise(this, {
      tskuItems: newTskuItem,
    })
    await this._sumAddOnTotalPayment(newTskuItem)
    // this.setState({ tskuItems: newTskuItem })
    // util.setStatePromise(this, { tskuItems: newTskuItem })
  }

  _sumAddOnTotalPayment = async (tskuItem: SubscriptionPackageListItems[]) => {
    let sumTotal = 0
    tskuItem.forEach((item) => {
      const uiSelectedCount = _.has(item, 'ui_selected_count') ? item.ui_selected_count : 0
      sumTotal += uiSelectedCount * item.price_with_vat
    })
    await util.setStatePromise(this, {
      sumAddOnPrice: sumTotal,
    })
  }

  _editBillBillingCycle = async (mode: 'MINUS' | 'PLUS') => {
    const { tskuItems, selectedBillingCycle } = this.state
    const newTskuItem = []
    tskuItems.forEach((tItem) => {
      const newTItem = _.clone(tItem)
      let newSelectedBillingCycle = selectedBillingCycle
      if (mode === 'PLUS') {
        newSelectedBillingCycle = selectedBillingCycle + 1
        newTItem.billing_cycle = newSelectedBillingCycle
      } else {
        newSelectedBillingCycle = selectedBillingCycle - 1
        newTItem.billing_cycle = newSelectedBillingCycle
      }
      newTItem.ui_is_selected_billing_cycle = true
      newTskuItem.push(newTItem)
    })
    await util.setStatePromise(this, {
      tskuItems: newTskuItem,
      selectedBillingCycle: mode === 'PLUS' ? selectedBillingCycle + 1 : selectedBillingCycle - 1,
    })
  }

  _onPressFooter = () => {
    const { tskuItems } = this.state
    const params = this._getParams()
    const { callback } = params
    if (_.isFunction(callback)) {
      callback(tskuItems)
      this.goBack()
    } else {
      this.goBack()
    }
  }
}
