import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as ProfileActions from 'x/modules/profile/ProfileState'
import * as MySubscriptionActions from 'x/modules/subscription/MySubscriptionState'
import SubscriptionView from './SubscriptionView'
import { getMySubscription } from 'x/redux/selectors'

export default connect(
  state => ({
    profile: state.get('profile'),
    subscription: getMySubscription(state),
  }),
  dispatch => {
    return {
      fetchUserProfile: bindActionCreators(ProfileActions.fetchUserProfile, dispatch),
      fetchSubscription: bindActionCreators(MySubscriptionActions.fetchSubscription, dispatch),
    }
  },
)(SubscriptionView)
