import { Component } from 'react'
import _ from 'lodash'
import { TextStyle, StyleProp, ViewStyle } from 'react-native'

export interface BaseSegmentProps {
  options: string[]
  disabled?: boolean

  // New Method ref by index
  selectedIndex?: number
  // onSegmentChange?: (arg: { index: number , option: string }) => Promise<void>;
  onSegmentChange?: (newIndex: number) => void

  // Old method ref by string
  selectedValue?: any
  onChange?: (selectedValue: any) => void

  containerStyle?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
}

export interface BaseSegmentState {
  // selectedIndex: number
  containerWidth?: number
}

export default class BaseSegment extends Component<BaseSegmentProps, BaseSegmentState> {
  // export default class BaseSegment extends Component<BaseSegmentProps> {
  static displayName = 'BaseSegment'
  // static defaultProps = {
  //   options: [],
  //   containerStyle: {},
  //   textStyle: {},
  // }

  // widthPercentPerItem: number
  isChangingSegment: boolean

  protected constructor(props: BaseSegmentProps) {
    super(props)
    // this._computeSegmentItemWidthPercent(this.props)
    this.state = {
      // selectedIndex: 0,
      containerWidth: null,
    }
    this.isChangingSegment = false
  }

  getSelectedIndex = () => {
    const { selectedValue, selectedIndex = 0, options } = this.props

    if (!_.isNil(selectedValue)) {
      const foundValueIndex = options.findIndex((opt: string) => opt === selectedValue)
      if (foundValueIndex > -1) {
        return foundValueIndex
      }
      return 0
    }

    return selectedIndex
  }

  // shouldComponentUpdate(nextProps: BaseSegmentProps, nextState: BaseSegmentState) {
  //   const isChangedSizeOptions = this._isChangedSizeOptions(nextProps)
  //   if (isChangedSizeOptions) {
  //     this._computeSegmentItemWidthPercent(nextProps)
  //     return true
  //   } else if (
  //     isDiffAccuracy(this.props, nextProps, ['selectedValue', 'selectedIndex', 'disabled', 'options', 'onSegmentChange', 'onChange'])
  //   ) {
  //     return true
  //   } else if (isDiffAccuracy(this.state, nextState, ['selectedIndex'])) {
  //     return true
  //   }
  //   return false
  // }

  // componentDidUpdate(prevProps: BaseSegmentProps) {
  //   if (isDiffAccuracy(this.props, prevProps, ['selectedValue', 'selectedIndex'])) {
  //     const { selectedValue, selectedIndex, options } = this.props
  //     if (_.isNumber(selectedIndex)) {
  //       this.setState({ selectedIndex })
  //     } else if (_.isString(selectedValue) && _.isArray(options)) {
  //       const foundValueIndex = options.findIndex((opt: string) => opt === selectedValue)
  //       if (foundValueIndex > -1) {
  //         this.setState({ selectedIndex: foundValueIndex })
  //       }
  //     }
  //   }
  // }

  // _isChangedSizeOptions = (nextProps: BaseSegmentProps) => {
  //   const oldOptions = _.isArray(this.props.options) ? this.props.options : []
  //   const newOptions = _.isArray(nextProps.options) ? nextProps.options : []
  //   if (oldOptions.length !== newOptions.length) {
  //     return true
  //   } else if (oldOptions.length === newOptions.length) {
  //   }
  //   return false
  // }

  // _computeSegmentItemWidthPercent = (props: BaseSegmentProps): void => {
  //   const { options } = props
  //   if (!options || !_.isArray(options)) {
  //     return
  //   }
  //   this.widthPercentPerItem = options.length > 0 ? 100 / options.length : 0
  // }

  _callParentOnSegmentChange = async (newIndex: number) => {
    const { onSegmentChange } = this.props
    // if (isAsyncFunction(onSegmentChange)) {
    //   try {
    //     await onSegmentChange(newIndex)
    //   } catch (error) {
    //     // no-op
    //   }
    // } else {
    //   onSegmentChange(newIndex)
    // }
    onSegmentChange(newIndex)
  }

  _callParentOnChange = async (newOption) => {
    const { onChange } = this.props
    // if (isAsyncFunction(onChange)) {
    //   try {
    //     await onChange(newOption)
    //   } catch (error) {
    //     // no-op
    //   }
    // } else {
    //   onChange(newOption)
    // }
    onChange(newOption)
  }

  onSegmentChange = async (newIndex: number): Promise<void> => {
    if (this.isChangingSegment) {
      return
    }
    this.isChangingSegment = true
    if (!_.isNumber(newIndex)) {
      this.isChangingSegment = false
      throw new Error('Segment::_onSegmentChange newIndex must be number.')
    }
    const { selectedIndex, options, onSegmentChange, onChange } = this.props
    if (_.isNumber(selectedIndex) && selectedIndex === newIndex) {
      this.isChangingSegment = false
      return
    }

    if (_.isFunction(onSegmentChange) && _.isArray(options)) {
      const newOption = typeof options[newIndex] !== 'undefined' ? options[newIndex] : null
      if (newOption) {
        // await onSegmentChange({ index: newIndex, option: newOption })
        await this._callParentOnSegmentChange(newIndex)
      }
    }

    // Should deprecated
    if (_.isFunction(onChange) && _.isArray(options) && options.length > 0) {
      const newOption = !_.isNil(options[newIndex] !== 'undefined') ? options[newIndex] : options[0]
      await this._callParentOnChange(newOption)
    }
    // await setStatePromise(this, { selectedIndex: newIndex })
    this.isChangingSegment = false
  }
}
