import React from 'react'
import { Clipboard, TouchableOpacity } from 'react-native'

import CONS from 'x/config/constants'
import _ from 'lodash'
import { diff } from 'deep-object-diff'
import p from 'x/config/platform-specific'
// import s from './_style'
// import StickyKeyboardTab from '../../components/StickyKeyboardTab'
// import XIcon from 'xui/components/XIcon'
// import * as xUtil from 'x/utils/util'
import * as util from 'x/utils/util'
import Segment from 'xui/components/Segment'
import XCustomHeader from 'xui/components/XCustomHeader'
import XCard from 'xui/components/XCard'
import VStack from 'xui/components/VStack'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import XIcon from 'xui/components/XIcon'
import XText from 'xui/components/XText'
import XInput from 'xui/components/XInput'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import {
  IAddress,
  IAddressFormConfig,
  IAddressFormSubmitReturn,
  IAddressFormViewProps,
  IAddressFormViewState,
  IXSegmentOption,
} from 'x/types'
import XSpinner from 'xui/components/XSpinner'
import HelpButton from 'xui/components/HelpButton'
import XSegment from 'xui/components/XSegment'

const EMPTY_ADDRESS = {
  name: '',
  // first_name: '',
  // last_name: '',
  address1: '',
  address2: '',
  address3: '',
  sub_district: '',
  district: '',
  province: '',
  postal_code: '',
  // phone1: '',
  telephone: '',
  // is_primary: false,
  legal_entity_type: 0,
  legal_entity_id: '',
  // created_at: '',
  // updated_at: '',
}

const DEFAULT_ADDRESS_CONFIG: IAddressFormConfig = {
  is_primary: { required: false, visible: true },
  name: { label: 'ชื่อ', placeholder: 'กรุณาระบุชื่อ', required: true, visible: true },
  first_name: { label: 'ชื่อ', placeholder: 'เช่น คุณถล', required: false, visible: false },
  last_name: { label: 'นามสกุล', placeholder: 'เช่น แก้ววงศา', required: false, visible: false },
  address1: { label: 'ที่อยู่', placeholder: 'เช่น 123/5 ถ.ศรีสุข ต.หมากแข้ง อ.เมือง จ.อุดรธานี', required: true, visible: true },
  address2: { label: 'ที่อยู่ (เพิ่มเติม)', placeholder: 'เช่น ชื่อหมู่บ้าน ซอย ถนน (ไม่จำเป็นต้องระบุ)', required: false, visible: true },
  address3: { label: 'ที่อยู่', placeholder: 'เช่น 123/5 ถ.ศรีสุข ต.หมากแข้ง อ.เมือง จ.อุดรธานี', required: false, visible: false },
  sub_district: { label: 'แขวง/ตำบล', placeholder: 'ระบุแขวง/ตำบล', required: false, visible: true },
  district: { label: 'เขต/อำเภอ', placeholder: 'ระบุเขต/อำเภอ', required: false, visible: true },
  province: { label: 'จังหวัด', placeholder: 'ระบุจังหวัด', required: false, visible: true },
  postal_code: { label: 'ไปรษณีย์', placeholder: 'เช่น 10100', keyboardType: 'number-pad', required: true, visible: true },
  legal_entity_type: { required: false, visible: true },
  legal_entity_id: { required: false, visible: true },
  branch_type: { required: false, visible: true },
  branch_number: { label: 'รหัสสาขา', placeholder: 'เช่น A00001', required: false, visible: true },
  telephone: {
    label: 'โทรศัพท์',
    placeholder: 'เช่น 089-xxx-xxxx',
    keyboardType: 'numbers-and-punctuation',
    required: false,
    visible: true,
  },
  phone1: {
    label: 'โทรศัพท์',
    placeholder: 'เช่น 089-xxx-xxxx',
    keyboardType: 'numbers-and-punctuation',
    required: false,
    visible: false,
  },
  email: { label: 'อีเมล', placeholder: 'เช่น xselly@gmail.com', required: false, visible: true },
  contact_email: { label: 'อีเมล', placeholder: 'เช่น xselly@gmail.com', required: false, visible: false },
  note: { label: 'หมายเหตุ', placeholder: '', required: false, visible: true },
}

const ADDRESS_CONFIG_BY_TYPES: { [key: string]: IAddressFormConfig } = {
  // default คือ ที่อยู่ร้านฉัน
  default: DEFAULT_ADDRESS_CONFIG,
  customer_profile: util.deepMerge(DEFAULT_ADDRESS_CONFIG, {
    is_primary: { required: false, visible: false },
    first_name: { required: true, visible: true },
    last_name: { required: false, visible: true },
    name: { required: false, visible: false },
    address1: { required: false, visible: false },
    address2: { required: false, visible: false },
    address3: { required: false, visible: false },
    sub_district: { required: false, visible: false },
    district: { required: false, visible: false },
    province: { required: false, visible: false },
    postal_code: { required: false, visible: false },
    phone1: { required: false, visible: true },
    telephone: { required: false, visible: false },
    note: { required: false, visible: false },
    legal_entity_type: { required: false, visible: false },
    legal_entity_id: { required: false, visible: false },
    branch_type: { required: false, visible: false },
    branch_number: { required: false, visible: false },
    email: { required: false, visible: false },
    contact_email: { required: false, visible: true },
  }),
  customer_address: util.deepMerge(DEFAULT_ADDRESS_CONFIG, {
    is_primary: { required: false, visible: false },
    name: { label: 'ชื่อลูกค้า', required: true, visible: true },
    address1: { label: 'ที่อยู่', required: true, visible: true },
    address2: { label: 'ที่อยู่ (เพิ่มเติม)', required: false, visible: true },
    address3: { required: false, visible: false },
    telephone: { required: false, visible: true },
    note: { required: false, visible: true },
  }),
  quick_receiver: util.deepMerge(DEFAULT_ADDRESS_CONFIG, {
    is_primary: { required: false, visible: false },
    name: { label: 'ชื่อผู้รับ', required: true, visible: true },
    address1: { label: 'ที่อยู่', required: false, visible: false },
    address2: { label: 'ที่อยู่ (เพิ่มเติม)', required: false, visible: true },
    address3: { label: 'ที่อยู่', placeholder: 'เช่น 123/5 ถ.ศรีสุข ต.หมากแข้ง อ.เมือง จ.อุดรธานี', required: true, visible: true },
    note: { label: 'หมายเหตุ', required: false, visible: false },
  }),
  quick_billing: util.deepMerge(DEFAULT_ADDRESS_CONFIG, {
    is_primary: { required: false, visible: false },
    name: { label: 'ชื่อ', required: true, visible: true },
    address1: { label: 'ที่อยู่', required: false, visible: false },
    address2: { label: 'ที่อยู่ (เพิ่มเติม)', required: false, visible: true },
    address3: {
      label: 'ที่อยู่',
      placeholder: 'เช่น 123/5 ถ.ศรีสุข ต.หมากแข้ง อ.เมือง จ.อุดรธานี',
      required: true,
      visible: true,
    },
    telephone: { required: false, visible: true },
    email: { required: false, visible: true },
    note: { label: 'หมายเหตุ', required: false, visible: false },
  }),
}

export default abstract class AddressFormView extends React.Component<IAddressFormViewProps, IAddressFormViewState> {
  static displayName = 'AddressFormView'

  isSubmitProcessing: boolean

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,
      config: ADDRESS_CONFIG_BY_TYPES.default,

      selectedAddress: EMPTY_ADDRESS,
      editingAddress: EMPTY_ADDRESS,

      segmentLegalEntityTypeIndex: 0,
      radioLegalEntityTypeMoreId: 0,

      segmentBranchTypeIndex: 0,

      isSubmitting: false,
    }

    this.isSubmitProcessing = false
  }

  componentDidMount(): void {
    this._initialize()
  }

  _initialize = async () => {
    const type = util.getNavParam(this.props, 'type')
    const initialAddress = util.getNavParam(this.props, 'initAddress')
    // console.log('AddressFormView initAddress => ', initialAddress)

    const newState: Partial<IAddressFormViewState> = { segmentLegalEntityTypeIndex: 0, radioLegalEntityTypeMoreId: null }

    if (initialAddress) {
      // @ts-ignore
      const initAddress: IAddress = _.cloneDeep(initialAddress)

      // if (initialAddress.address3) {
      //   // @ts-ignore
      //   initialAddress.address1 = `${initialAddress.address1 || ''}${initialAddress.address3}`
      //   delete initialAddress.address3
      // }

      const isLegalEntityIndividual = util.isLegalEntityIndividual(initAddress.legal_entity_type)
      if (isLegalEntityIndividual) {
        newState.segmentLegalEntityTypeIndex = 1
        newState.radioLegalEntityTypeMoreId = initAddress.legal_entity_type
      }

      const isLegalEntityCorporation = util.isLegalEntityCorporation(initAddress.legal_entity_type)
      if (isLegalEntityCorporation) {
        newState.segmentLegalEntityTypeIndex = 2
        newState.radioLegalEntityTypeMoreId = initAddress.legal_entity_type
      }

      // set default legal_entity_type for billing_address
      if (
        type === 'quick_billing' &&
        !isLegalEntityIndividual &&
        !isLegalEntityCorporation &&
        (!initAddress.name || initAddress.name === '') &&
        (!initAddress.legal_entity_id || initAddress.legal_entity_id === '')
      ) {
        newState.segmentLegalEntityTypeIndex = 2
        newState.radioLegalEntityTypeMoreId = 11
      }

      let segmentBranchTypeIndex = 0
      if (initAddress.branch_type) {
        segmentBranchTypeIndex = CONS.ADDRESS_BRANCH_TYPE_OPTIONS.findIndex((opt) => opt.value === initAddress.branch_type)
      }
      if (segmentBranchTypeIndex < 0) {
        segmentBranchTypeIndex = 0
      }

      newState.segmentBranchTypeIndex = segmentBranchTypeIndex
      newState.selectedAddress = initAddress
      newState.editingAddress = initAddress
      await util.setStatePromise(this, newState)
    }

    await this._setupAddressFormConfig()
    await this._reflectNamePlaceholderByLegalEntityTypeId()
    await util.setStatePromise(this, { isInitialized: true })
  }

  _setupAddressFormConfig = async () => {
    const type = util.getNavParam(this.props, 'type', 'default')
    const overrideConfig = util.getNavParam(this.props, 'overrideConfig', {})

    let config = ADDRESS_CONFIG_BY_TYPES.default

    if (_.isObject(ADDRESS_CONFIG_BY_TYPES[type]) && !_.isEmpty(ADDRESS_CONFIG_BY_TYPES[type])) {
      config = ADDRESS_CONFIG_BY_TYPES[type]
    }

    await util.setStatePromise(this, { config: util.deepMerge(config, overrideConfig) })
  }

  _changeAddressFormConfig = async (modifyCfg: Partial<IAddressFormConfig>) => {
    const type = util.getNavParam(this.props, 'type', 'default')
    const overrideConfig = util.getNavParam(this.props, 'overrideConfig', {})

    let config = ADDRESS_CONFIG_BY_TYPES.default

    if (_.isObject(ADDRESS_CONFIG_BY_TYPES[type]) && !_.isEmpty(ADDRESS_CONFIG_BY_TYPES[type])) {
      config = ADDRESS_CONFIG_BY_TYPES[type]
    }

    const newCfg = util.deepMerge(config, overrideConfig)
    await util.setStatePromise(this, { config: util.deepMerge(newCfg, modifyCfg) })
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _isFormValidationPassed = async () => {
    const { selectedAddress, editingAddress, config: cfg, segmentLegalEntityTypeIndex, radioLegalEntityTypeMoreId } = this.state
    const addr: any = editingAddress
    const isDiff = diff(selectedAddress, addr)
    if (!isDiff) {
      await p.op.alertPromise('ยังไม่มีการแก้ไข')
      return false
    }

    let errAlert = null

    // Simple empty validation
    const cfgFields = Object.keys(cfg)
    for (let i = 0; i < cfgFields.length; i++) {
      const fieldName = cfgFields[i]
      const field = cfg[fieldName]
      const value = addr[fieldName]
      if (field.required && (!value || (typeof value === 'string' && value.trim().length === 0))) {
        errAlert = { title: 'ข้อมูลไม่ครบถ้วน', message: `กรุณาระบุ ${field.label}` }
        break
      }
    }

    if (errAlert) {
      await p.op.alertPromise(errAlert.title, errAlert.message)
      return false
    }

    // 2-layer validation
    if (_.isString(addr.name) && addr.name.length > 0 && addr.name.length < 3) {
      p.op.alert('รูปแบบไม่ถูกต้อง', `กรุณาระบุ ${cfg.name} มากกว่า 3 ตัวอักษร`)
      return false
    }

    if (_.isString(addr.first_name) && addr.first_name.length > 0 && addr.first_name.length < 3) {
      p.op.alert('รูปแบบไม่ถูกต้อง', `กรุณาระบุ ${cfg.first_name} มากกว่า 3 ตัวอักษร`)
      return false
    }

    let inpZipCode = addr.postal_code ? addr.postal_code : ''
    let inpTel = addr.telephone ? addr.telephone : ''
    let inpEmail = addr.email ? addr.email : ''
    inpZipCode = !_.isNil(inpZipCode) ? inpZipCode.toString().trim() : inpZipCode
    inpTel = !_.isNil(inpTel) ? inpTel.toString().trim() : inpTel
    inpEmail = !_.isNil(inpEmail) ? inpEmail.toString().trim() : inpEmail

    const regZipcode = /^[1-9][0-9]{4}$/
    const postal_code = inpZipCode || null
    const telephone = inpTel || null
    const email = inpEmail || null
    const postalCodeErrMs = 'กรุณาระบุ รหัสไปรษณีย์ ในรูปแบบที่ถูกต้อง'

    if (_.isString(postal_code) && postal_code.length > 0) {
      if (!regZipcode.test(postal_code)) {
        p.op.alert('รูปแบบไม่ถูกต้อง', postalCodeErrMs)
        return false
      }

      if (postal_code.length !== 5) {
        // บังคับให้ระบุรหัสไปษณีย์ได้แค่ 5 ตัวเท่านั้น
        p.op.alert('รูปแบบไม่ถูกต้อง', postalCodeErrMs)
        return false
      }

      // ถ้า postal_code 00000 ให้หน้าบ้านดักไว้ ( อ้างอิง : https://app.clickup.com/t/860pc78y1 )
      if (postal_code === '00000') {
        p.op.alert('รูปแบบไม่ถูกต้อง', postalCodeErrMs)
        return false
      }
    }

    if (_.isString(telephone) && telephone.length > 0 && telephone.length < 9) {
      p.op.alert('รูปแบบไม่ถูกต้อง', 'กรุณาระบุ เบอร์โทรศัพท์ ในรูปแบบที่ถูกต้องซึ่งมีเลขหมายมากกว่าหรือเท่ากับ 9 หลัก')
      return false
    }

    if (segmentLegalEntityTypeIndex === 2 && (_.isNil(radioLegalEntityTypeMoreId) || radioLegalEntityTypeMoreId < 2)) {
      p.op.alert(
        'คำเตือน',
        `กรุณาเลือกประเภทของ ${CONS.ADDRESS_LEGAL_ENTITY_TYPE_OPTIONS[segmentLegalEntityTypeIndex]} หรือหากไม่ต้องการระบุสามารถปรับสภาพบุคคลเป็น "ไม่ระบุ"`
      )
      return false
    }

    const legal_entity_type = segmentLegalEntityTypeIndex === 0 ? 0 : radioLegalEntityTypeMoreId
    const isLegalEntityIndividual = util.isLegalEntityIndividual(legal_entity_type)
    const isLegalEntityCorporation = util.isLegalEntityCorporation(legal_entity_type)

    if (legal_entity_type > 0) {
      if (!_.isString(addr.legal_entity_id) || addr.legal_entity_id.length === 0) {
        p.op.alert(
          'คำเตือน',
          `กรุณาระบุ ${CONS.ADDRESS_LEGAL_ENTITY_LABELS[segmentLegalEntityTypeIndex]} หรือหากไม่ต้องการระบุสามารถปรับสภาพบุคคลเป็น "ไม่ระบุ"`
        )
        return false
      }

      const legalEntityIdToTest = addr.legal_entity_id.replace(/-/g, '').trim()
      const regThirteenDigit = /^\d{13}$/
      if (isLegalEntityIndividual && !regThirteenDigit.test(legalEntityIdToTest)) {
        p.op.alert('คำเตือน', `กรุณาระบุ ${CONS.ADDRESS_LEGAL_ENTITY_LABELS[1]} เป็นตัวเลข 13 หลัก`)
        return false
      }

      const regTenDigit = /^\d{10}$/
      if (isLegalEntityCorporation && !regTenDigit.test(legalEntityIdToTest) && !regThirteenDigit.test(legalEntityIdToTest)) {
        p.op.alert('คำเตือน', `กรุณาระบุ ${CONS.ADDRESS_LEGAL_ENTITY_LABELS[2]} เป็นตัวเลข 10 หลัก หรือ 13 หลัก`)
        return false
      }
    }

    if (email && email.length > 0) {
      const isEmailValid = util.isEmailValid(email)
      if (!isEmailValid) {
        p.op.showConfirmationOkOnly(`อีเมลไม่ถูกต้อง`, 'กรุณาระบุอีเมลในรูปแบบถูกต้อง')
        return false
      }
    }

    if (addr.contact_email && addr.contact_email.length > 0) {
      const isEmailValid = util.isEmailValid(addr.contact_email)
      if (!isEmailValid) {
        p.op.showConfirmationOkOnly(`อีเมลไม่ถูกต้อง`, 'กรุณาระบุอีเมลในรูปแบบถูกต้อง')
        return false
      }
    }

    return true
  }

  _trimAllTextFields = async () => {
    const { editingAddress } = this.state
    const fields = Object.keys(editingAddress)
    const trimmed = _.cloneDeep(editingAddress)
    for (let i = 0; i < fields.length; i++) {
      const fieldName = fields[i]
      const value = trimmed[fieldName]
      if (typeof value === 'string' && value.length > 0) {
        trimmed[fieldName] = value.trim()
      }
    }
    await util.setStatePromise(this, { editingAddress: trimmed })
  }

  _onPressSubmit = async () => {
    if (this.isSubmitProcessing) {
      return
    }
    this.isSubmitProcessing = true
    await util.setStatePromise(this, { isSubmitting: true })
    await this._trimAllTextFields()
    await util.delay(50)

    const isFormValidationPassed = await this._isFormValidationPassed()
    if (!isFormValidationPassed) {
      await util.setStatePromise(this, { isSubmitting: false })
      this.isSubmitProcessing = false
      return
    }

    const { selectedAddress, editingAddress, segmentLegalEntityTypeIndex, radioLegalEntityTypeMoreId } = this.state
    // const isPreventGoBackAfterSubmit = util.getNavParam(this.props, 'isPreventGoBackAfterSubmit', false)
    const onSubmit = util.getNavParam(this.props, 'onSubmit')
    // let newAddress

    try {
      const validAddr = { ...editingAddress }
      const legal_entity_type = segmentLegalEntityTypeIndex === 0 ? 0 : radioLegalEntityTypeMoreId

      if (legal_entity_type === 0) {
        // @ts-ignore
        validAddr.legal_entity_type = 0
        // @ts-ignore
        validAddr.legal_entity_id = null
      } else if (legal_entity_type > 0) {
        // @ts-ignore
        validAddr.legal_entity_type = legal_entity_type
      }

      // @ts-ignore
      if (!validAddr.legal_entity_type) {
        // @ts-ignore
        validAddr.branch_type = null
        // @ts-ignore
        validAddr.branch_number = null
      }

      const result: IAddressFormSubmitReturn = await onSubmit(validAddr)

      if (result.newAddress) {
        await util.setStatePromise(this, { selectedAddress: result.newAddress, editingAddress: result.newAddress })
      }

      if (result.isGoBack) {
        this._goBack()
      }

      // if (!isPreventGoBackAfterSubmit) {
      //   this._goBack()
      // }
    } catch (err) {
      console.log('AddressFormView _onPressSubmit err => ', err)
    }

    await util.delay(50)
    await util.setStatePromise(this, { isSubmitting: false })
    this.isSubmitProcessing = false
  }

  _renderCustomHeader = () => {
    const { isSubmitting } = this.state
    const headerTitle = util.getNavParam(this.props, 'headerTitle', 'ที่อยู่')
    const submitButtonText = util.getNavParam(this.props, 'submitButtonText', 'บันทึก')
    const leftBtn = { backIcon: true, onPressItem: () => this._goBack(), submitting: isSubmitting }
    const rightBtn = { label: submitButtonText, onPressItem: this._onPressSubmit, submitting: isSubmitting }
    // title = `ที่อยู่`
    return <XCustomHeader title={headerTitle} headerLeftProps={leftBtn} headerRightProps={rightBtn} />
  }

  _onEditingAddressChange = (key: string, newValue: any) => {
    const { editingAddress } = this.state
    const newAddress = { ...editingAddress, [key]: newValue }
    this.setState({ editingAddress: newAddress })
  }

  _isFieldRequired = (key: string) => {
    const { config } = this.state
    return config[key].required || false
  }

  _isFieldVisible = (key: string) => {
    const { config } = this.state
    return config[key].visible || false
  }

  _getFieldLabel = (key: string) => {
    const { config } = this.state
    return config[key].label || ''
  }

  _getFieldPlaceholder = (key: string) => {
    const { config } = this.state
    return config[key].placeholder || ''
  }

  _getFieldKeyboardType = (key: string) => {
    const { config } = this.state
    return config[key].keyboardType || 'default'
  }

  _renderGenericInput = (key: string) => {
    const isVisible = this._isFieldVisible(key)
    if (!isVisible) {
      return null
    }
    const required = this._isFieldRequired(key)
    const label = this._getFieldLabel(key)
    const placeholder = this._getFieldPlaceholder(key)
    const keyboardType = this._getFieldKeyboardType(key)

    const { editingAddress } = this.state
    const value = editingAddress[key] || ''
    return (
      <HStack w='full' space='2'>
        <HStack w='114px' space='1' pt='1.5'>
          <XText variant='inactive'>
            {label}
            {required && (
              <XText fontSize='lg' variant='danger'>
                *
              </XText>
            )}
          </XText>
        </HStack>

        <XInput
          flex={1}
          value={_.isNumber(value) ? value.toString() : value}
          placeholder={placeholder}
          onChangeText={(text: string) => this._onEditingAddressChange(key, text)}
          keyboardType={keyboardType}
        />
      </HStack>
    )
  }

  _renderMultipleLineInput = (key: string) => {
    const isVisible = this._isFieldVisible(key)
    if (!isVisible) {
      return null
    }
    const required = this._isFieldRequired(key)
    const label = this._getFieldLabel(key)
    const placeholder = this._getFieldPlaceholder(key)
    const keyboardType = this._getFieldKeyboardType(key)
    const { editingAddress } = this.state
    const value = editingAddress[key] || ''
    return (
      <HStack w='full' space='2'>
        <HStack w='114px' space='1' pt='1.5'>
          <XText variant='inactive'>
            {label}
            {required && (
              <XText fontSize='lg' variant='danger'>
                *
              </XText>
            )}
          </XText>
        </HStack>

        <XInput
          flex={1}
          style={{
            minHeight: 100,
          }}
          value={value}
          onChangeText={(text: string) => this._onEditingAddressChange(key, text)}
          placeholder={placeholder}
          // placeholderTextColor={COLORS.TEXT_INACTIVE}
          multiline
          blurOnSubmit={false} // allow new line on Android keyboard
          keyboardType={keyboardType}
        />
      </HStack>
    )
  }

  _renderEntityInput = () => {
    const visible = this._isFieldVisible('legal_entity_id')
    if (!visible) {
      return null
    }

    const { segmentLegalEntityTypeIndex } = this.state
    const { editingAddress } = this.state

    if (segmentLegalEntityTypeIndex === 0) {
      return null
    }

    const label = CONS.ADDRESS_LEGAL_ENTITY_LABELS[segmentLegalEntityTypeIndex]
    return (
      <HStack w='full' space='2'>
        <HStack w='114px' pt='1.5'>
          <XText variant='inactive'>{label}</XText>
        </HStack>
        <XInput
          flex={1}
          // @ts-ignore
          value={editingAddress.legal_entity_id || ''}
          onChangeText={(text: string) => this._onEditingAddressChange('legal_entity_id', text)}
          keyboardType='number-pad'
          placeholder={`ระบุ${label}`}
        />
      </HStack>
    )
  }

  _onSegmentLegalEntityTypeChange = async (newIndex: number) => {
    const newState: any = { segmentLegalEntityTypeIndex: newIndex }
    if (newIndex === 0) {
      newState.radioLegalEntityTypeMoreId = null
    } else if (newIndex === 1) {
      newState.radioLegalEntityTypeMoreId = CONS.ADDRESS_LEGAL_ENTITY_TYPE_INDIVIDUAL_MORE_OPTIONS[0].id
    } else if (newIndex === 2) {
      newState.radioLegalEntityTypeMoreId = null
    }

    await util.setStatePromise(this, newState)
    await this._reflectNamePlaceholderByLegalEntityTypeId()
  }

  _renderEntityType = () => {
    const visible = this._isFieldVisible('legal_entity_type')
    if (!visible) {
      return null
    }

    const { segmentLegalEntityTypeIndex = 0 } = this.state

    return (
      <HStack w='full' space='2'>
        <HStack w='114px' pt='1.5'>
          <XText variant='inactive'>สภาพบุคคล</XText>
        </HStack>
        <HStack flex={1}>
          <Segment
            onSegmentChange={this._onSegmentLegalEntityTypeChange}
            options={CONS.ADDRESS_LEGAL_ENTITY_TYPE_OPTIONS}
            selectedIndex={segmentLegalEntityTypeIndex}
          />
        </HStack>
      </HStack>
    )
  }

  _reflectNamePlaceholderByLegalEntityTypeId = async () => {
    const { config, radioLegalEntityTypeMoreId: legalEntityTypeId } = this.state
    const isCorporation = util.isLegalEntityCorporation(legalEntityTypeId)

    let newPlaceholder = 'กรุณาระบุชื่อ'

    // 19 = อื่นๆ
    if (isCorporation && legalEntityTypeId !== 19) {
      const foundEntityType = CONS.ADDRESS_LEGAL_ENTITY_TYPE_CORPORATION_MORE_OPTIONS.find(({ id }) => id === legalEntityTypeId)
      if (foundEntityType && foundEntityType.label) {
        newPlaceholder = foundEntityType.label
      }
    }

    const shouldUpdateConfig = config.name && config.name.visible && config.name.placeholder !== newPlaceholder

    if (shouldUpdateConfig) {
      await this._changeAddressFormConfig({ name: { placeholder: newPlaceholder } })
    }
  }

  _onRadioLegalEntityTypeMorePress = async (legalEntityTypeId: number) => {
    await util.setStatePromise(this, { radioLegalEntityTypeMoreId: legalEntityTypeId })
    await this._reflectNamePlaceholderByLegalEntityTypeId()
  }

  _renderEntityTypeMoreItem = (opt, index: number) => {
    const visible = this._isFieldVisible('legal_entity_type')
    if (!visible) {
      return null
    }

    if (opt.isHidden) {
      return null
    }

    const { radioLegalEntityTypeMoreId } = this.state
    const checked = opt.id === radioLegalEntityTypeMoreId
    return (
      <HStack key={`entityTypeMoreItem-${index}`} pb='1'>
        <TouchableOpacity onPress={() => this._onRadioLegalEntityTypeMorePress(opt.id)}>
          <HStack
            px='2'
            py='1'
            space='1'
            alignItems='center'
            borderWidth='1'
            borderColor={checked ? 'gray.400' : 'gray.200'}
            borderRadius='lg'>
            <XIcon name={checked ? 'radio-button-on' : 'radio-button-off'} />
            <XText>{opt.label}</XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  _renderEntityTypeMore = () => {
    const { segmentLegalEntityTypeIndex } = this.state
    if (segmentLegalEntityTypeIndex === 2) {
      return (
        <HStack w='full' space='2' alignItems='center'>
          <Box w='114px' />
          <HStack flex={1} space='1.5' flexWrap='wrap'>
            {CONS.ADDRESS_LEGAL_ENTITY_TYPE_CORPORATION_MORE_OPTIONS.map(this._renderEntityTypeMoreItem)}
          </HStack>
        </HStack>
      )
    }

    if (segmentLegalEntityTypeIndex === 1) {
      return (
        <HStack w='full' space='2' alignItems='center'>
          <Box w='114px' />
          <HStack flex={1} space='1.5' flexWrap='wrap'>
            {CONS.ADDRESS_LEGAL_ENTITY_TYPE_INDIVIDUAL_MORE_OPTIONS.map(this._renderEntityTypeMoreItem)}
          </HStack>
        </HStack>
      )
    }

    return null
  }

  _renderBranchType = () => {
    const visible = this._isFieldVisible('branch_type')
    if (!visible) {
      return null
    }

    const { segmentBranchTypeIndex = 0, segmentLegalEntityTypeIndex } = this.state

    if (!segmentLegalEntityTypeIndex) {
      return null
    }

    return (
      <HStack w='full' space='2'>
        <HStack w='114px' pt='1.5'>
          <XText variant='inactive'>สาขา</XText>
        </HStack>
        <HStack flex={1}>
          <XSegment
            onSegmentChange={this._onSegmentBranchTypeChange}
            options={CONS.ADDRESS_BRANCH_TYPE_OPTIONS}
            selectedIndex={segmentBranchTypeIndex}
          />
        </HStack>
      </HStack>
    )
  }

  _onSegmentBranchTypeChange = (selectedOption: IXSegmentOption, newIndex: number) => {
    const { editingAddress } = this.state
    const newEditingAddress = _.cloneDeep(editingAddress)

    // @ts-ignore
    newEditingAddress.branch_type = selectedOption.value

    if (selectedOption.value === 'b') {
      // @ts-ignore
      newEditingAddress.branch_number = null
    } else {
      // @ts-ignore
      newEditingAddress.branch_number = ''
    }

    this.setState({ segmentBranchTypeIndex: newIndex, editingAddress: newEditingAddress })
  }

  _renderBranchNumber = () => {
    // const visible = this._isFieldVisible('branch_number')
    // if (!visible) {
    //   return null
    // }

    const { segmentBranchTypeIndex } = this.state
    // const { editingAddress } = this.state

    if (CONS.ADDRESS_BRANCH_TYPE_OPTIONS[segmentBranchTypeIndex].value !== 'b') {
      return null
    }

    return this._renderGenericInput('branch_number')
  }

  // _renderInputNote = () => {
  // _renderInputNote = () => {
  //   const { editingAddress, segmentLegalEntityTypeIndex } = this.state
  //   const noteValue = editingAddress.note
  //   return (
  //     <HStack w='full' space='2' alignItems='center' mt={segmentLegalEntityTypeIndex === 0 ? '0' : '1'} mb='2'>
  //       <Box w='114px'>
  //         <XText variant='inactive'>หมายเหตุ</XText>
  //       </Box>
  //       <XInput flex={1} mr='2' value={noteValue} onChangeText={(text: string) => this._onEditingAddressChange('note', text)} />
  //     </HStack>
  //   )
  // }

  _onChangeIsPrimary = () => {
    const { editingAddress } = this.state
    // @ts-ignore
    const isPrimary = _.isNil(editingAddress.is_primary) ? false : editingAddress.is_primary
    this.setState({ editingAddress: { ...editingAddress, is_primary: !isPrimary } })
  }

  _checkBoxPrimary = () => {
    const visible = this._isFieldVisible('is_primary')
    if (!visible) {
      return null
    }

    const { editingAddress } = this.state
    // @ts-ignore
    const isPrimary = _.isNil(editingAddress.is_primary) ? false : editingAddress.is_primary

    return (
      <HStack w='full' space='2' alignItems='center'>
        <Box w='114px' />
        <TouchableOpacity onPress={this._onChangeIsPrimary}>
          <HStack flex={1} space='2' alignItems='center'>
            <XIcon name={isPrimary ? 'check-square-o' : 'square-o'} family='FontAwesome' />
            <XText variant='inactive'>เลือกเป็นที่อยู่หลัก</XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  _onPressPasteInlineAddressButton = async () => {
    const text = await Clipboard.getString()
    const txtAddr = text.trim()
    const quickAddress = util.convertInlineAddressToQuickAddress(txtAddr)

    const type = util.getNavParam(this.props, 'type', 'default')
    const isAddressOneUsage = _.includes(['default', 'customer_address'], type)

    if (isAddressOneUsage) {
      // @ts-ignore
      quickAddress.address1 = quickAddress.address3
      delete quickAddress.address3
    }

    this.setState({ editingAddress: quickAddress })
  }

  _renderToolbar = () => {
    const type = util.getNavParam(this.props, 'type', 'default')
    const visible = _.includes(['default', 'customer_address', 'quick_billing', 'quick_receiver'], type)
    if (!visible) {
      return null
    }

    const { editingAddress } = this.state
    // @ts-ignore
    const isPrimary = _.isNil(editingAddress.is_primary) ? false : editingAddress.is_primary

    return (
      <HStack w='full' pb='2.5' space='0.5' alignItems='center' justifyContent='flex-end'>
        <TouchableOpacity onPress={this._onPressPasteInlineAddressButton}>
          <HStack
            px='2'
            py='1'
            space='1'
            alignItems='center'
            bg='gray.100'
            // borderWidth='1'
            // borderColor={checked ? NB.HC.TEXT.ACTIVE() : NB.HC.TEXT.INACTIVE()}
            borderRadius='lg'>
            <XIcon variant='primary' name='content-paste' family='MaterialCommunityIcons' />
            <XText variant='primary' bold>
              วางชื่อที่อยู่ทั้งก้อน
            </XText>
          </HStack>
        </TouchableOpacity>
        <TouchableOpacity onPress={this._onPressPasteInlineAddressButton}>
          <HelpButton
            title='วางชื่อที่อยู่ทั้งก้อน'
            message={
              'เป็นตัวช่วยให้คุณสามารถกรอกแบบฟอร์มได้สะดวกมากขึ้น\n\n' +
              'คุณสามารถคัดลอกที่อยู่ ในรูปแบบเช่น\n\n' +
              'แม่น้องแอร์รี่ (083)565-8745\n' +
              '123 ถ.พระราม 5 เขตบึงกุ่ม กทม 12346\n\nหรือ\n\n' +
              'คุณประเสริฐ ผลิตภัณฑ์การชั่ง\n' +
              '456 ถนนสาทรใต้ แขวงทุ่งมหาเมฆ\n' +
              'เขตสาทร จังหวัดกรุงเทพ 10120 (089-1234756)\n\n' +
              'เมื่อคัดลอกแล้วสามารถกดปุ่ม "วางชื่อที่อยู่ทั้งก้อน" เพื่อกรอกแบบฟอร์มโดยอัตโนมัติ'
            }
          />
        </TouchableOpacity>
      </HStack>
    )
  }

  // _onPressAutoFillByZipcode = () => {
  //   const { address } = this.state
  //   const zipcode = address.postal_code

  //   if (_.isNil(zipcode) || zipcode.length !== 5) {
  //     return
  //   }

  //   const zipcodeInfos = addrUtil.findtZipcodeInfos(zipcode)

  //   if (!_.isNil(zipcodeInfos) && zipcodeInfos.length > 0) {
  //     const info = zipcodeInfos[0]
  //     this.setState({ address: { ...address, district: info.amphoe, sub_district: info.district, province: info.province } })
  //   }
  // }

  //   { label: 'ที่อยู่*', key: 'address1', inputType: 'text', required: true },
  //   { label: 'ที่อยู่ (เพิ่มเติม)', key: 'address2', inputType: 'text', required: false },
  //   { label: 'แขวง/ตำบล', key: 'sub_district', inputType: 'text', required: true },
  //   { label: 'เขต/อำเภอ*', key: 'district', inputType: 'text', required: true },
  //   { label: 'จังหวัด*', key: 'province', inputType: 'text', required: true },
  //   { label: 'ไปรษณีย์*', key: 'postal_code', inputType: 'text', required: true },
  //   { label: 'โทรศัพท์*', key: 'telephone', inputType: 'text', required: false },
  //   { label: 'อีเมล', key: 'email', inputType: 'text', required: false },

  _renderContent = () => {
    const { isInitialized } = this.state
    if (!isInitialized) {
      return null
    }

    return (
      <XCard w='full'>
        <VStack w='full' px='4' py='2' space='2'>
          {/* <ScrollView> */}
          {this._renderToolbar()}

          {this._checkBoxPrimary()}

          {this._renderGenericInput('name')}
          {this._renderGenericInput('first_name')}
          {this._renderGenericInput('last_name')}

          {this._renderEntityType()}
          {this._renderEntityTypeMore()}
          {this._renderEntityInput()}

          {this._renderBranchType()}
          {this._renderBranchNumber()}

          {this._renderMultipleLineInput('address1')}
          {this._renderMultipleLineInput('address3')}
          {this._renderGenericInput('address2')}
          {this._renderGenericInput('sub_district')}
          {this._renderGenericInput('district')}
          {this._renderGenericInput('province')}
          {this._renderGenericInput('postal_code')}
          {this._renderGenericInput('telephone')}
          {this._renderGenericInput('phone1')}
          {this._renderGenericInput('email')}
          {this._renderGenericInput('contact_email')}

          {/* {this._renderInputNote()} */}
          {this._renderMultipleLineInput('note')}
        </VStack>
      </XCard>
    )
  }

  _renderLoadingSpinner = () => {
    const { isSubmitting } = this.state

    if (!isSubmitting) {
      return null
    }

    return (
      <Box alignItems='center' justifyContent='center' position='absolute' top={0} left={0} right={0} bottom={0} bg='gray.400:alpha.50'>
        <XSpinner />
      </Box>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent>
          <VStack w='full' p='2'>
            {this._renderContent()}
          </VStack>
          {this._renderLoadingSpinner()}
        </XContent>
      </XContainer>
    )
  }
}
