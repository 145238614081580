import React from 'react'
import { Image } from 'react-native'
import Ionicon from 'react-native-vector-icons/Ionicons'
import dayjs from 'dayjs'
import { IProfile } from 'x/index'
import * as util from 'x/utils/util'
import HStack from './HStack'
import Box from './Box'
import VStack from './VStack'
import PackageTag from './PackageTag'
import XText from './XText'

interface IMkpLogoProps {
  // subscription type id
  typeId: number
  profile: IProfile
}
const ProfilePackage = (props: IMkpLogoProps) => {
  const isNoobIos = util.isIosNoobCheckByPass()
  if (props.profile && props.typeId) {
    const { created_at, username, thumbnail_uri } = props.profile
    const userCreatedAt = dayjs(created_at, 'YYYY-MM-DD')
    const userCreatedAtText = userCreatedAt.clone().fromNow()
    return (
      <HStack w='full' bgColor='white' py='2' alignItems='center'>
        <Box flex={2} alignItems='center'>
          {thumbnail_uri ? (
            <Image source={{ uri: thumbnail_uri }} style={{ width: 60, height: 60, borderRadius: 90 }} />
          ) : (
            <Ionicon name='person' size={50} />
          )}
        </Box>
        <VStack flex={8} pl='2'>
          {isNoobIos ? null : <PackageTag typeId={props.typeId} width={80} height={32} />}

          <HStack>
            <XText variant='inactive' numberOfLines={1}>
              {'ชื่อบัญชีผู้ใช้: '}
            </XText>
            <XText variant='active' numberOfLines={1}>
              {username}
            </XText>
          </HStack>
          <HStack>
            <XText variant='inactive' numberOfLines={1}>
              {`ลงทะเบียนใช้งานเมื่อ: `}
            </XText>
            <XText variant='active' numberOfLines={1}>
              {userCreatedAtText}
            </XText>
          </HStack>
        </VStack>
      </HStack>
    )
  }
  return null
}

export default ProfilePackage
