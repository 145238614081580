import React from 'react'
import VStack from 'xui/components/VStack'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import { View, Image, ImageSourcePropType } from 'react-native'
import * as NavActions from 'x/utils/navigation'
import XIcon, { ICON_FAMILY } from 'xui/components/XIcon'
import * as util from 'x/utils/util'
import XInput from 'xui/components/XInput'
import _ from 'lodash'
import CONS from 'x/config/constants'
import { COLORS as xCOLORS, COLORS, STYLES } from 'x/config/styles'

import Segment from 'xui/components/Segment'
import p from 'x/config/platform-specific'
import BaseCustomCreateOrderView from 'x/modules/order/BaseCustomCreateOrderView'

import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XText from 'xui/components/XText'
import XCustomHeader from 'xui/components/XCustomHeader'
import XButton from 'xui/components/XButton'
import XCard from 'xui/components/XCard'
import { IAddress } from 'x/types'
import XFlatPicker from 'xui/components/XFlatPicker'
import XSwitch from 'xui/components/XSwitch'
import SwitchCard from '../../components/SwitchCard'

const { TEXT_INACTIVE } = COLORS
const imgIconCOD: ImageSourcePropType = require('../../images/icon_cod_blue.png')

const line = (
  <View
    style={{
      borderBottomColor: xCOLORS.TEXT_INACTIVE,
      borderBottomWidth: 0.8,
      marginTop: 15,
      marginBottom: 10,
      // marginLeft: 10,
      // marginRight: 10,
    }}
  />
)

export default class BaseUICustomCreateOrderView extends BaseCustomCreateOrderView {
  _renderHeader = () => (
    <XCustomHeader
      title='ตั้งค่าหน้า สร้างออเดอร์'
      // headerLeftProps={onChangeData ? null : { backIcon: true, onPressItem: goBack() }}
      renderHeaderLeft={() => this._renderHeaderLeft()}
      renderHeaderRight={() => this._renderHeaderRight()}
    />
  )

  _renderHeaderLeft = () => {
    const { navigation } = this.props
    const { onChangeData } = this.state
    const { goBack } = navigation
    if (onChangeData) {
      return (
        <XButton onPress={() => this._saveBtn()} variant='ghost'>
          บันทึก
        </XButton>
      )
    }
    // return null
    return <XButton variant='ghost' leftIcon={<XIcon name='arrowleft' family='AntDesign' />} onPress={() => goBack()} />
  }

  _renderHeaderRight = () => {
    const { onChangeData } = this.state
    const { navigation } = this.props
    const { goBack } = navigation
    if (onChangeData) {
      return (
        <XButton
          onPress={() => {
            p.op.showConfirmation(
              '',
              'ยกเลิกการแก้ไข',
              () => goBack(),
              () => null,
              'ตกลง',
              'แก้ไขต่อ'
            )
          }}
          variant='ghost'>
          ยกเลิก
        </XButton>
      )
    }
    return null
  }

  _renderTitleCard = (
    title: string,
    options: string[],
    selectedIndex: number,
    onSegmentChange: (index: number) => void,
    icon?: JSX.Element,
    isSegmentLongFlex?: boolean
  ) => (
    <HStack w='full' alignItems='center' mt='1' py='2'>
      <Box flex={1} flexDirection='row' alignItems='center'>
        {_.isNil(icon) ? null : icon}
        {_.isNil(icon) ? null : <Box w='2' />}
        <XText variant='inactive'>{title}</XText>
      </Box>
      <Box flex={_.isNil(isSegmentLongFlex) ? 1 : 2}>
        <Segment options={options} selectedIndex={selectedIndex} onSegmentChange={onSegmentChange} />
      </Box>
    </HStack>
  )

  _renderXSwitchRow = (title: string, value: boolean, onValueChange: (newValue: boolean) => void) => (
    <HStack mt='2' alignItems='center'>
      <Box flex={1}>
        <XText variant='inactive'>{title}</XText>
      </Box>
      <XSwitch value={value} onValueChange={(val: boolean) => onValueChange(val)} />
    </HStack>
  )

  _renderBody = (renderBody?: any) => (
    <View style={{ marginLeft: 10, marginRight: 10, flexDirection: 'column', paddingTop: 10 }}>{renderBody}</View>
  )

  _renderSwitchCard({
    key,
    value,
    txtLabel,
    txtHelp,
    disabled,
    additionalComponent = null,
    prefixIcon = null,
    prefixIconImg = null,
    changeValueOnly = false,
    mode = null,
  }) {
    // const { onEditingOrderChange } = this.props
    // const { orderTabIndex } = this.state
    return (
      <SwitchCard
        disabled={disabled}
        txtLabel={txtLabel}
        txtHelp={txtHelp}
        switchValue={value || false}
        onToggle={(newValue) =>
          // changeValueOnly
          //   ? this._handleOrderOptionChangeValueOnly(order, key, newValue, orderTabIndex)
          //   : this._handleOrderOptionChange(order, key, newValue, txtLabel)
          {}
        }
        additionalComponent={additionalComponent}
        prefixIcon={prefixIcon}
        prefixIconImg={prefixIconImg}
        mode={mode}
      />
    )
  }

  _renderReceiverAddress = () => {
    const { customCreateOrderData, txtReceiverName, txtReceiverAddress } = this.state
    const { receiver } = customCreateOrderData
    let receiverName = ''
    let receiverAddress = ''
    if (!_.isNil(receiver.vi) && receiver.t === 2) {
      receiverName = txtReceiverName
      receiverAddress = txtReceiverAddress
    }
    // navToAddressListStorePick
    return (
      <XCard w='full' mt='2' onPress={() => this.navToCustomerList()}>
        <XCard.Body>
          <HStack justifyContent='space-between' alignItems='center' space='1'>
            <VStack flex={1} space='2'>
              <HStack space='1'>
                <XIcon name='person' family='Ionicons' variant='inactive' />
                <XText variant={txtReceiverName ? 'active' : 'inactive'}>{txtReceiverName || 'กรุณาเลือกลูกค้า'}</XText>
              </HStack>
              <HStack space='1'>
                <XIcon name='pin' family='Ionicons' variant='inactive' />
                {/* <Icon name='pin' style={{ fontSize: FONT_ICON_NORMAL, color: TEXT_INACTIVE }} /> */}
                <XText variant={txtReceiverAddress ? 'active' : 'inactive'}>{txtReceiverAddress || '-'}</XText>
              </HStack>
            </VStack>
            <XIcon name='arrow-forward' />
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  // navToAddressListStorePick = () => {
  //   // TODO NB3
  //   p.op.showConfirmationOkOnly('', 'เร็วๆนี้')
  //   return
  //   const { navigation } = this.props
  //   navigation
  //     .dispatch
  //     // NavActions.navToPickAddressFormCustomCreateOrder({
  //     //   mode: xCONS.CONTACT_LIST_MODE_KEYS.PICK_ADDRESS,
  //     //   onSuccessPickCallback: (address) => this._addressStorePick(address),
  //     // })
  //     ()
  // }

  _renderDetailShippingTypeCardButton = (data: any) => (
    // const { mode } = this.state
    // const { item, index, disabled } = data
    // console.log('data => ', data)
    // let txtShippingType = null
    // const ALL_SHIPPING_TYPE_ITEMS = CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.map((item) => ({
    //   key: item.index,
    //   ...item,
    //   label: p.op.t(`Order.shippingTypeItems.${item.key}`),
    // }))
    // if (index > -1) {
    //   txtShippingType = ALL_SHIPPING_TYPE_ITEMS[index].label
    // } else if (index === -1 && item && item.value > 0) {
    //   txtShippingType = 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
    // } else {
    //   txtShippingType = 'ยังไม่ได้เลือก'
    // }
    // console.log('txtShippingType => ', txtShippingType)
    // txtShippingType = ''
    <XCard>
      <XCard.Body>
        <HStack alignItems='center' justifyContent='space-between' space='1'>
          <HStack alignItems='center' space='1'>
            <XIcon family='MaterialIcons' name='local-shipping' variant='inactive' />
            <XText pl='2' variant='inactive'>
              {this.state.shippingName}
            </XText>
          </HStack>

          <HStack alignItems='center' justifyContent='flex-end' space='1'>
            {/* <XText>{txtShippingType}</XText> */}
            <XIcon variant='primary' name='arrow-forward' />
          </HStack>
        </HStack>
      </XCard.Body>
    </XCard>
  )

  _renderCommonFlatPickerItemForShipping = (data) => {
    const { item, index, disabled } = data
    const shippingState = util.getShippingHasXShippingLabel(item.value)
    // const UNDER_LINE = p.op.isWeb() ? {} : { borderBottomColor: COLORS.TEXT_INACTIVE, borderBottomWidth: 1 }
    return (
      <HStack w='full' py='4' px='2'>
        <Box flex={1}>
          <XText variant='active'>{`${item.label}`}</XText>
          {_.isNil(shippingState) ? null : (
            <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: shippingState.color }}>{`(${shippingState.label})`}</XText>
          )}
        </Box>
        <XIcon name='arrowright' family='AntDesign' variant='primary' />
      </HStack>
    )
  }

  _renderReceiver = () => {
    const { customCreateOrderData, txtSenderName, txtSenderAdresses, store_id, mode } = this.state
    const { navigation } = this.props
    const { receiver } = customCreateOrderData
    // Sender Paramitor
    const { sender } = customCreateOrderData
    let senderName = _.isNil(txtSenderName) ? '' : txtSenderName
    const senderAdresses = _.isNil(txtSenderAdresses) ? '' : txtSenderAdresses
    if (!_.isNil(sender.sender_name)) {
      senderName = sender.sender_name
    }
    const renderBodySender = () => (
      <Box mt='2'>
        {sender.a === 0 ? null : (
          <Box>
            {this._renderSenderNameInputItem({
              iconName: 'person',
              txtMessage: senderName,
            })}
            {this._renderSenderAddressPickerButtonItem({
              iconName: 'pin',
              txtMessage: senderAdresses || '<< ใช้ที่อยู่ของผู้ขายส่ง >>',
              txtMessageStyle: null,
              handlePress: () =>
                navigation.dispatch(
                  NavActions.navToAddressListStore({
                    store_id,
                    headerTitle: 'เลือกที่อยู่ร้านฉัน',
                    onPressAddress: (address: IAddress) => this._addressStorePickFromSender(address),
                  })
                ),
              // NavActions.navToAddressListStorePickFromCallBack({
              //   store_id,
              //   onSuccessPickCallback: (address) => this._addressStorePickFromSender(address),
              // })
              // (),
              // navigation.dispatch(NavActions.navToAddressListStorePickFromCallBack({ store_id, mode, addressListStorePickCallback })),
              // noTopBorder: true,
            })}
          </Box>
        )}
      </Box>
    )

    // Shipping
    const shippping = customCreateOrderData.shipping
    const shipppingId = _.isNil(shippping.vi) ? 0 : shippping.vi
    const renderBodyShipping = () => {
      if (_.isNil(this.state.shippingTypeItems) || this.state.shippingTypeItems.length < 1) {
        return null
      }
      return (
        <Box w='full'>
          <XText variant='inactive'>รูปแบบจัดส่ง</XText>
          <XFlatPicker
            title='เลือกรูปแบบจัดส่ง'
            placeholder='กรุณาเลือกรูปแบบจัดส่ง'
            placeholderStyle={{ marginLeft: 6 }}
            // ref={this._shippingTypeCreateModePickerRef}
            selectedIndex={shipppingId}
            options={this.state.shippingTypeItems}
            onPick={this._onSegmentChangeShipping}
            renderSelectedValue={this._renderDetailShippingTypeCardButton}
            renderItem={this._renderCommonFlatPickerItemForShipping}
          />
        </Box>
      )
    }
    const hideDataType = receiver.v === 0 && receiver.a === 0
    return (
      <VStack w='full'>
        {/* // Header */}
        {this._renderHeaderTxt(p.op.t('Order.shipmentInfo'))}
        {/* // Receiver */}
        {this._renderTitleCard(
          'ผู้รับ',
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          receiver.v,
          (index: number) => this._onSegmentChangeChooseShowAndHideRecieverAdresses(index),
          <XIcon variant='inactive' name='home' family='FontAwesome' />
        )}
        {hideDataType
          ? null
          : this._renderTitleCard(
              'ประเภทข้อมูล',
              this.OPTIONS_CHOOSE_RECIEVER_ADRESSES_TYPE,
              receiver.t,
              (index: number) => this._onSegmentChangeChooseRecieverAdressesType(index),
              null,
              true
            )}
        {this._renderTitleCard('ระบุอัตโนมัติ', this.OPTIONS_CHOOSE_AUTO_RECIEVER_ADRESSES, receiver.a, (index: number) =>
          this._onSegmentChangeChooseRecieverAdresses(index)
        )}
        {receiver.a === 1 && receiver.t === 0 ? (
          <Box mt='2'>
            {this._renderInputItem({
              iconName: 'person',
              txtMessage: _.isNil(receiver.vs) ? '' : receiver.vs,
              txtPlaceholder: 'ระบุชื่อผู้รับ',
              onChangeText: this._onChangeReciever,
            })}
          </Box>
        ) : null}
        {receiver.a === 1 && receiver.t === 2 ? this._renderReceiverAddress() : null}
        {receiver.a === 1 && receiver.t === 1 ? (
          <Box mt='2'>
            {this._renderInputItem({
              iconName: 'person',
              txtMessage: _.isNil(receiver.vs) ? '' : receiver.vs,
              txtPlaceholder: 'ระบุชื่อผู้รับ',
              onChangeText: this._onChangeReciever,
            })}
            {this._renderInputItem({
              iconName: 'call',
              txtMessage: _.isNil(receiver.p) ? '' : receiver.p,
              txtPlaceholder: 'ระบุเบอร์โทรผู้รับ',
              onChangeText: this._onChangePhone,
            })}
          </Box>
        ) : null}
        {/* // Sender */}
        {line}
        {this._renderTitleCard(
          'ผู้ส่ง',
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          sender.v,
          this._onSegmentChangeChooseShowAndHideSenderAdresses,
          <XIcon variant='inactive' name='store' family='MaterialCommunityIcons' />
        )}
        <Box>
          {sender.v === 0 ? (
            <HStack justifyContent='flex-end'>
              <XText variant='inactive'>(กรุณาระบุชื่อผู้ส่ง หากต้องการซ่อนข้อมูลส่วนนี้)</XText>
            </HStack>
          ) : (
            this._renderTitleCard(
              'ระบุอัตโนมัติ',
              this.OPTIONS_CHOOSE_RECIEVER_AND_SENDER_ADRESSES,
              sender.a,
              this._onSegmentChangeChooseSenderAdresses
            )
          )}
        </Box>
        {renderBodySender()}
        {/* // Shipping */}
        {line}
        {this._renderTitleCard(
          'รูปแบบจัดส่ง',
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          shippping.v,
          this._onSegmentChangeChooseShowAndHideShipping,
          <XIcon variant='inactive' name='local-shipping' family='MaterialIcons' />
        )}
        <Box>
          {shippping.v === 0 ? (
            <Box style={{ paddingBottom: 0, paddingLeft: 10, paddingRight: 10 }}>
              <Box style={{ flex: 1, justifyContent: 'flex-start', flexDirection: 'row' }} />
              <Box style={{ flex: 1, justifyContent: 'flex-end' }}>
                <XText alignSelf='flex-end' variant='inactive'>
                  (กรุณาระบุรูปแบบจัดส่ง หากต้องการซ่อนข้อมูลส่วนนี้)
                </XText>
              </Box>
            </Box>
          ) : (
            this._renderTitleCard(
              'ระบุอัตโนมัติ',
              this.OPTIONS_CHOOSE_RECIEVER_AND_SENDER_ADRESSES,
              shippping.a,
              this._onSegmentChangeChooseShipping
            )
          )}
        </Box>
        {shippping.a === 0 ? null : renderBodyShipping()}
        {/* // ShipBeforePay */}
        {line}
        {this._renderShippingBeforePay()}
      </VStack>
    )
  }

  _renderInputItem = ({ iconName, txtMessage, txtPlaceholder, onChangeText }) => (
    <XCard w='full'>
      <XCard.Body>
        <HStack w='full' flexWrap='wrap' alignItems='center' space='1'>
          <XIcon name={iconName} variant='inactive' />
          <XInput
            flex={1}
            value={txtMessage}
            maxLength={300}
            placeholder={_.isNil(txtPlaceholder) ? '' : txtPlaceholder}
            placeholderTextColor={TEXT_INACTIVE}
            onChangeText={(text) => onChangeText(text)}
            multiline
            blurOnSubmit={false}
          />
        </HStack>
      </XCard.Body>
    </XCard>
  )

  _renderSenderNameInputItem = ({ iconName, txtMessage }) => (
    <XCard w='full' borderBottomWidth='0' borderBottomLeftRadius='0' borderBottomRightRadius='0'>
      <XCard.Body>
        <HStack w='full' flexWrap='wrap' alignItems='center' space='1'>
          <XIcon name={iconName} variant='inactive' />
          <XInput
            flex={1}
            value={txtMessage}
            maxLength={300}
            placeholder='ระบุชื่อผู้จัดส่ง (ชื่อฉัน/ชื่อร้านฉัน)'
            placeholderTextColor={TEXT_INACTIVE}
            onChangeText={(text) => this._onChangeTextSenderNameOnly(text)}
            multiline
            blurOnSubmit={false}
          />
          {/* </Item> */}
        </HStack>
      </XCard.Body>
    </XCard>
  )

  _renderSenderAddressPickerButtonItem = ({
    iconName,
    iconFamily = ICON_FAMILY.Ionicons,
    txtMessage,
    txtMessageStyle,
    handlePress,
    noTopBorder = false,
    numberOfLines = 10,
    backgroundColor = null,
    image = null,
  }) => (
    <XCard
      w='full'
      borderTopWidth={noTopBorder ? '0' : undefined}
      borderTopLeftRadius='0'
      borderTopRightRadius='0'
      disabled={!handlePress}
      onPress={handlePress || null}
      style={backgroundColor ? { backgroundColor } : undefined}>
      <XCard.Body>
        <HStack w='full' flexWrap='wrap' alignItems='center' space='1'>
          <HStack flex={1} space='1'>
            {iconName ? <XIcon variant='inactive' family={iconFamily} name={iconName} /> : null}
            <VStack flex={1}>
              {txtMessage ? (
                <XText variant='inactive' numberOfLines={numberOfLines} style={txtMessageStyle}>
                  {txtMessage}
                </XText>
              ) : null}
            </VStack>
          </HStack>
          <HStack alignItems='center' justifyContent='flex-end' space='1'>
            {image != null ? <Image style={{ width: 30, height: 30 }} source={image} /> : null}
            {handlePress ? <XIcon name='arrow-forward' /> : null}
          </HStack>
        </HStack>
      </XCard.Body>
    </XCard>
  )

  _renderShippingBeforePay = () => {
    const { customCreateOrderData } = this.state
    const shipBeforePay = customCreateOrderData.ship_before_pay
    const textLabelNotCOD = 'ค่าเริ่มต้น หากไม่ส่ง COD'
    const textLabelIsCOD = 'ค่าเริ่มต้น หากส่ง COD'
    return (
      <Box>
        {this._renderTitleCard(
          'จัดส่งก่อนชำระ',
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          shipBeforePay.v,
          this._onSegmentChangeChooseShowAndHideShippingBeforePay,
          <XIcon
            variant='inactive'
            color={COLORS.SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_payOther}
            name='truck-delivery'
            family='MaterialCommunityIcons'
          />
        )}
        {this._renderXSwitchRow(textLabelNotCOD, shipBeforePay.n_cod, (val: boolean) => this._shipBeforePayIfNotCOD(val))}
        {this._renderXSwitchRow(textLabelIsCOD, shipBeforePay.is_cod, (val: boolean) => this._shipBeforePayIfIsCOD(val))}
      </Box>
    )
  }

  _renderShippingCOD = () => {
    const { showAndHideShippingBeforePayCOD } = this.state
    return (
      <View style={{ borderBottomColor: '#E1E1E1', borderBottomWidth: 10, paddingBottom: 5 }}>
        {this._renderTitleCard(
          'จัดส่งแบบ COD',
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          showAndHideShippingBeforePayCOD,
          this._onSegmentChangeChooseShowAndHideShippingBeforePayCOD
        )}
        {this._renderBody(this._renderShipBeforePayCOD())}
      </View>
    )
  }

  _renderShipBeforePayCOD = () => {
    const { mode } = this.state
    return this._renderSwitchCard({
      key: 'is_cod',
      value: true,
      txtLabel: 'ค่าธรรมเนียมเก็บเงินปลายทาง',
      txtHelp: {
        title: 'ค่าธรรมเนียมเก็บเงินปลายทาง',
        message:
          `หากระบุ ค่าธรรมเนียมจะถูกนำไปรวมกับ”ยอดรวม” ของออเดอร์\n\n` +
          `ในกรณีที่มีออเดอร์ซื้อคู่กัน 1 ออเดอร์ ยอดนี้จะถูกระบุต่อไปที่ออเดอร์ซื้อด้วย หากร้านผู้ขายเปิดการตั้งค่าร้าน` +
          `"อนุญาตให้ผู้ซื้อ เปิดการใช้งาน เก็บเงินปลายทาง ได้โดยอัตโนมัติขณะสร้างออเดอร์"\n\n` +
          `ในกรณีที่มีออเดอร์ซื้อคู่กันมากกว่า 1 ออเดอร์ (นั่นคือในออเดอร์นี้มีสินค้ามาจากสต๊อกของหลายผู้ขาย)` +
          `ฉันต้องระบุค่าธรรมเนียมในแต่ละออเดอร์ซื้อเอง (ระบบจะไม่ระบุให้)`,
      },
      prefixIconImg: {
        iconImgName: imgIconCOD,
      },
      changeValueOnly: true,
      mode,
      disabled: false,
    })
  }

  _renderOther = () => {
    const { customCreateOrderData } = this.state
    const salesChannel = customCreateOrderData.sales_channel
    // let salesChannelName: string = ''
    const shipppingId = _.isNil(salesChannel.vi) ? 0 : salesChannel.vi
    // this.channelItems.forEach((data) => {
    //   if (shipppingId === data.value) {
    //     salesChannelName = data.label
    //   }
    // })

    const _renderDetailSaleChannelCardButton = ({ item, index, disabled }) => {
      const channel_id = item.value
      let txtChannelLabel = null
      if (index > -1) {
        txtChannelLabel = item.label
      } else if (index === -1 && channel_id > 0) {
        txtChannelLabel = 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
      } else {
        txtChannelLabel = 'ยังไม่ได้เลือก'
      }

      return (
        <XCard w='full'>
          <XCard.Body>
            <HStack alignItems='center' justifyContent='space-between' space='1'>
              <HStack alignItems='center' space='1'>
                <XIcon name='locate' family='Ionicons' variant='inactive' />
                <XText variant='inactive'>{p.op.t('Order.orderChannel')}</XText>
              </HStack>
              <HStack alignItems='center' justifyContent='flex-end' space='1'>
                <XText>{txtChannelLabel}</XText>
                {disabled ? null : <XIcon name='arrow-forward' />}
              </HStack>
            </HStack>
          </XCard.Body>
        </XCard>
      )
    }

    const _renderCommonFlatPickerItem = (data) => {
      const { item, index, disabled } = data
      return (
        <HStack w='full' py='4' px='2' key={index.toString()}>
          <Box flex={1}>
            <XText variant='active'>{`${item.label}`}</XText>
          </Box>
          <XIcon name='arrowright' family='AntDesign' variant='primary' />
        </HStack>
      )
    }

    const renderSelectedChannel = () => {
      // @ts-ignore
      let channelIndex = 0
      CONS.ORDER_VIEW_CHANNEL_ITEMS.forEach((ch) => {
        if (ch.value === shipppingId) {
          channelIndex = ch.index
        }
      })
      const { sales_channel } = customCreateOrderData
      return (
        <VStack>
          {this._renderTitleCard('ช่องทางขาย', this.OPTIONS_CHOOSE_HIDE_AND_SHOW, sales_channel.v, (index: number) =>
            this._onSegmentChangeChooseShowAndHideSalesChannel(index)
          )}
          <XFlatPicker
            title={p.op.t('Order.orderChannel')}
            selectedIndex={channelIndex}
            options={this.channelItems}
            onPick={({ item }) => {
              // console.log('item => ', item)
              this._onSegmentChangeSalesChannel(item)
            }}
            renderSelectedValue={_renderDetailSaleChannelCardButton}
            renderItem={_renderCommonFlatPickerItem}
          />
        </VStack>
      )
    }

    const closeAutoOrder = () => {
      const autoCloseSellOrder = customCreateOrderData.auto_complete
      return (
        <Box>
          {/* // ปิดออเดอร์ขายอัตโนมัติ */}
          {/* {this._renderTitleCard(
            'ปิดออเดอร์ขายอัตโนมัติ',
            this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
            autoCloseSellOrder.v,
            (index: number) => this._onSegmentChangeChooseShowAndHideAutoCloseSellOrder(index),
            <XIcon variant='inactive' name='clipboard-check-outline' family='MaterialCommunityIcons' />
          )} */}
          <VStack>
            <HStack w='full' alignItems='center' mt='1' py='2'>
              <Box flex={1} flexDirection='row' alignItems='center'>
                <XIcon variant='inactive' name='clipboard-check-outline' family='MaterialCommunityIcons' />
                <Box w='2' />
                <XText variant='inactive'>ปิดออเดอร์ขายอัตโนมัติ</XText>
              </Box>
            </HStack>
            <Box flex={1}>
              <Segment
                options={this.OPTIONS_CHOOSE_HIDE_AND_SHOW}
                selectedIndex={autoCloseSellOrder.v}
                onSegmentChange={(index: number) => this._onSegmentChangeChooseShowAndHideAutoCloseSellOrder(index)}
              />
            </Box>
          </VStack>
          {this._renderXSwitchRow('ค่าเริ่มต้น', autoCloseSellOrder.d, (val: boolean) => this._autoCloseSellOrderValDefault(val))}
        </Box>
      )
    }
    const skipOrderDetailAutoCopy = customCreateOrderData.skip_order_detail_auto_copy.v === 1
    const checkDupReceiverAddr = customCreateOrderData.check_dup_receiver_addr
    return (
      <Box>
        {line}
        {this._renderHeaderTxt('อื่นๆ')}
        {renderSelectedChannel()}
        {line}
        {this._renderDateDelivery()}
        {line}
        {this._renderAutoCancelOrder()}
        {line}
        {closeAutoOrder()}
        {line}
        {this._renderXSwitchRow('คัดลอก "รายละเอียดออเดอร์"\nอัตโนมัติทันทีที่ออเดอร์ถูกสร้าง', skipOrderDetailAutoCopy, (val: boolean) =>
          this._onChangeSkipOrderDetailAutoCopy(val)
        )}
        {line}
        {this._renderXSwitchRow('แจ้งเตือนที่อยู่ผู้รับซ้ำกับออเดอร์\n(ที่สร้างภายใน 30 วัน)', checkDupReceiverAddr, (val: boolean) =>
          this._onChangeCheckDupReceiverAddr(val)
        )}
      </Box>
    )
  }

  _renderDateDelivery = () => {
    const { customCreateOrderData } = this.state
    if (!_.has(customCreateOrderData, 'date_delivery')) {
      return null
    }
    const dateDelivery = customCreateOrderData.date_delivery

    return this._renderTitleCard(
      'วันกำหนดส่ง',
      this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
      dateDelivery.v,
      (index: number) => this._onSegmentChangeChooseShowAndHideDateDelivery(index),
      <XIcon variant='inactive' name='back-in-time' family='Entypo' />
    )
  }

  _renderAutoCancelOrder = () => {
    const { customCreateOrderData } = this.state
    if (!_.has(customCreateOrderData, 'auto_cancel_order')) {
      return null
    }
    const autoCancelOrder = customCreateOrderData.auto_cancel_order
    const useStoreSetting = _.has(customCreateOrderData.auto_cancel_order, 'm') && customCreateOrderData.auto_cancel_order.m === 'd'
    return (
      <Box>
        {/* {this._renderTitleCard(
          p.op.t('StoreSetting.MenuStoreCancelOrderAutomation'),
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          autoCancelOrder.v,
          (index: number) => this._onSegmentChangeChooseShowAndHideAutoCancelOrder(index),
          <XIcon variant='inactive' name='cancel' family='MaterialCommunityIcons' />
        )} */}
        <VStack>
          <HStack w='full' alignItems='center' mt='1' py='2'>
            <Box flex={1} flexDirection='row' alignItems='center'>
              <XIcon variant='inactive' name='cancel' family='MaterialCommunityIcons' />
              <Box w='2' />
              <XText variant='inactive'>{p.op.t('StoreSetting.MenuStoreCancelOrderAutomation')}</XText>
            </Box>
          </HStack>
          <Box flex={1}>
            <Segment
              options={this.OPTIONS_CHOOSE_HIDE_AND_SHOW}
              selectedIndex={autoCancelOrder.v}
              onSegmentChange={(index: number) => this._onSegmentChangeChooseShowAndHideAutoCancelOrder(index)}
            />
          </Box>
        </VStack>
        <Box mt='2' />
        <Segment
          options={['ไม่ยกเลิก', 'ใช้ตั้งค่าร้าน']}
          selectedIndex={useStoreSetting ? 1 : 0}
          onSegmentChange={this._onSegmentChangeChooseAutoCancelOrder}
        />
      </Box>
    )
  }

  _renderCoverSheet = () => {
    const { customCreateOrderData } = this.state
    const coverSheet = customCreateOrderData.notes
    return (
      <Box>
        {line}
        {/* // Header */}
        {this._renderHeaderTxt(p.op.t('ShippingLabel'))}

        {/* {this._renderTitleCard(
          'รูปแทนใบปะหน้าพัสดุ',
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          coverSheet.n_1.v,
          (index: number) => this._onSegmentChangeChooseShowAndHideCoverSheet(index),
          <XIcon variant='inactive' name='photo' family='FontAwesome' />
        )} */}

        {/* // รูปแทนใบปะหน้าพัสดุ */}
        <VStack>
          <HStack w='full' alignItems='center' mt='1' py='2'>
            <Box flex={1} flexDirection='row' alignItems='center'>
              <XIcon variant='inactive' name='photo' family='FontAwesome' />
              <Box w='2' />
              <XText variant='inactive'>รูปแทนใบปะหน้าพัสดุ</XText>
            </Box>
          </HStack>
          <Box flex={1}>
            <Segment
              options={this.OPTIONS_CHOOSE_HIDE_AND_SHOW}
              selectedIndex={coverSheet.n_1.v}
              onSegmentChange={(index: number) => this._onSegmentChangeChooseShowAndHideCoverSheet(index)}
            />
          </Box>
        </VStack>

        {/* // หมายเหตุบนใบปะหน้าพัสดุ */}
        <VStack>
          <HStack w='full' alignItems='center' mt='1' py='2'>
            <Box flex={1} flexDirection='row' alignItems='center'>
              <XIcon variant='inactive' name='edit' family='FontAwesome' />
              <Box w='2' />
              <XText variant='inactive'>หมายเหตุบนใบปะหน้าพัสดุ</XText>
            </Box>
          </HStack>
          <Box flex={1}>
            <Segment
              options={this.OPTIONS_CHOOSE_HIDE_AND_SHOW}
              selectedIndex={coverSheet.n_2.v}
              onSegmentChange={(index: number) => this._onSegmentChangeChooseShowAndHideCoverSheetNote(index)}
            />
          </Box>
        </VStack>

        {/* {this._renderTitleCard(
          'หมายเหตุบนใบปะหน้าพัสดุ',
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          coverSheet.n_2.v,
          this._onSegmentChangeChooseShowAndHideCoverSheetNote,
          <XIcon variant='inactive' name='edit' family='FontAwesome' />
        )}
        <View style={{ marginBottom: 10 }} /> */}
        {/* </Card> */}
      </Box>
    )
  }

  _renderDescription = () => (
    <Box>
      <XText variant='active'>ในหน้า "สร้างออเดอร์" คุณสามารถ ซ่อน แสดง และ ระบุอัตโนมัติให้กับ ข้อมูลต่างๆในหน้าได้</XText>
    </Box>
    // </Card>
  )

  _renderHeaderTxt = (title: string) => (
    <XText textAlign='center' variant='active' bold>
      {title}
    </XText>
  )

  render() {
    // ชื่อย่อต่างๆ ดูได้จาก spec : https://docs.google.com/document/d/1woOUsxhHVzqrM-era1p46kAgLvXug-__H-naUFPocSU/edit
    const { customCreateOrderData } = this.state
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent p='2'>
          <XCard w='full' p='2' mb='4'>
            <VStack w='full'>
              {this._renderDescription()}
              {_.isNil(customCreateOrderData) ? null : (
                <Box w='full'>
                  {this._renderReceiver()}
                  {this._renderCoverSheet()}
                  {this._renderOther()}
                </Box>
              )}
            </VStack>
          </XCard>
          {/* <View style={{ paddingTop: 4 }} /> */}
        </XContent>
      </XContainer>
    )
  }
}
