import React from 'react'
// import { Drawer } from 'native-base'
import { Text, TouchableOpacity, View, Platform } from 'react-native'
import _ from 'lodash'
import { diff } from 'deep-object-diff'

import { COLORS, STYLES } from 'x/config/styles'
import {
  ICategory,
  IMKPChannelDetail,
  IProductListFilterViewProps,
  IProductListFilterViewState,
  IProductListFilterState,
  IXDateRangeSelectorChangeParams,
} from 'x/index'

// import NavHeaderButton from './NavHeaderButton'
// import XCheckbox from './XCheckbox'
// import XCheckButton from './XCheckButton'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import HStack from 'xui/components/HStack'
import Icon from './Icon'
import XIcon from './XIcon'
import XModal from './XModal'
import XButton from './XButton'
import XText from './XText'
import Box from './Box'
import VStack from './VStack'
import XDateRangeSelectorButton from './XDateRangeSelectorButton'
import Segment from './Segment'
import MKPChannelsActive from './MKPChannelsActive'
import XScrollView from './XScrollView'
import XImageIcon from './XImageIcon'

const logoMkpChannel = require('../img/about/icon_mkp_channel.png')

export default class ProductListFilterView extends React.Component<IProductListFilterViewProps, IProductListFilterViewState> {
  static displayName = 'ProductListFilterView'

  static defaultProps: Partial<IProductListFilterViewProps> = {
    defaultFilter: {},
    currentFilter: {},
  }

  // loadProps: boolean

  constructor(props: IProductListFilterViewProps) {
    super(props)
    // this.drawerRef = React.createRef()
    this.state = {
      allChildren: true,
      categoryList: null,
      levelCategoryList: null,

      isModalOpen: false,
      channels: util.getMKPChannels(),
      mkpChIds: [],

      hasCreatedByMkpChannels: util.getMKPChannels(),
      hasCreatedByMkpChMkpChIds: [],
    }

    // this.loadProps = true
    // this.drawerCatRef = React.createRef()
  }

  _seletedCategory = async (category: ICategory) => {
    const { levelCategoryList } = this.state
    const newLvlelCat = []
    levelCategoryList.map((data: ICategory) => {
      if (data.id === category.id) {
        const newCat = data
        newCat.selected = _.isNil(data.selected) ? true : !data.selected
        newLvlelCat.push(newCat)
      } else {
        const newCat = data
        newCat.selected = false
        newLvlelCat.push(newCat)
      }
    })
    await util.setStatePromise(this, {
      levelCategoryList: newLvlelCat,
    })
  }

  _onPressSubmitBtn = async () => {
    const {
      levelCategoryList,
      allChildren,
      mkpChIds,
      segmentHasMkpChIndex,
      createdAtFrom,
      createdAtTo,
      createdAtRangeOptionKey,
      segmentCreatedAtIndex,
      segmentHasParentIndex,
      segmentUpdatedAtIndex,
      updatedAtFrom,
      updatedAtTo,
      updatedAtRangeOptionKey,
      segmentHasCreatedByMkpChIndex,
      hasCreatedByMkpChMkpChIds,
      segmentHasCreatedByMkpChSpecificIndex,
    } = this.state
    const { onSubmit, onRequestCloseView } = this.props

    if (_.isFunction(onRequestCloseView)) {
      onRequestCloseView()
    }
    this.closeFilterModal()

    let selectedCat = null
    levelCategoryList.map((data: ICategory) => {
      if (data.selected) {
        selectedCat = data
      }
    })

    const spf: IProductListFilterState = {
      // category: selectedCat,
      // allChildrenCategory: allChildren,
    }

    if (selectedCat) {
      spf.category = selectedCat
    }

    if (!allChildren) {
      spf.allChildrenCategory = false
    }

    // if (mkpChIds.length > 0) {
    //   // @ts-ignore
    //   spf.mkpChIds = mkpChIds
    // }

    if (_.includes([1, 2], segmentHasMkpChIndex)) {
      spf.segmentHasMkpChIndex = segmentHasMkpChIndex
      spf.mkpChIds = mkpChIds
    }

    if (segmentHasMkpChIndex === 2) {
      spf.mkpChIds = mkpChIds
    }

    if (_.includes([1, 2], segmentHasCreatedByMkpChIndex)) {
      spf.segmentHasCreatedByMkpChIndex = segmentHasCreatedByMkpChIndex
    }

    if (segmentHasCreatedByMkpChIndex === 2 && segmentHasCreatedByMkpChSpecificIndex === 1) {
      spf.segmentHasCreatedByMkpChSpecificIndex = segmentHasCreatedByMkpChSpecificIndex
      spf.hasCreatedByMkpChMkpChIds = hasCreatedByMkpChMkpChIds
    }

    if (_.includes([1, 2], segmentHasParentIndex)) {
      spf.segmentHasParentIndex = segmentHasParentIndex
    }

    if (segmentCreatedAtIndex === 1) {
      spf.segmentCreatedAtIndex = segmentCreatedAtIndex
      spf.createdAtFrom = createdAtFrom
      spf.createdAtTo = createdAtTo
      spf.createdAtRangeOptionKey = createdAtRangeOptionKey
    }

    if (segmentUpdatedAtIndex === 1) {
      spf.segmentUpdatedAtIndex = segmentUpdatedAtIndex
      spf.updatedAtFrom = updatedAtFrom
      spf.updatedAtTo = updatedAtTo
      spf.updatedAtRangeOptionKey = updatedAtRangeOptionKey
    }

    await onSubmit(spf)
    // this.loadProps = true
  }

  _clearAllSelected = async () => {
    const { defaultFilter = {} } = this.props
    const { levelCategoryList } = this.state
    const newLevelCategoryList = []
    levelCategoryList.map((data: ICategory) => {
      const xData = data
      xData.selected = false
      newLevelCategoryList.push(xData)
    })
    await util.setStatePromise(this, {
      levelCategoryList: newLevelCategoryList,
      channels: util.getMKPChannels(),
      hasCreatedByMkpChannels: util.getMKPChannels(),

      allChildren: true,

      segmentCreatedAtIndex: 0,
      createdAtFrom: null,
      createdAtTo: null,
      createdAtRangeOptionKey: null,

      segmentUpdatedAtIndex: 0,
      updatedAtFrom: null,
      updatedAtTo: null,
      updatedAtRangeOptionKey: null,

      segmentHasParentIndex: 0,

      segmentHasMkpChIndex: 0,
      mkpChIds: [],

      segmentHasCreatedByMkpChIndex: 0,
      segmentHasCreatedByMkpChSpecificIndex: 0,
      hasCreatedByMkpChMkpChIds: [],

      ...defaultFilter,
    })
  }

  _setAllChildrenCategory = () => {
    const { allChildren } = this.state
    util.setStatePromise(this, { allChildren: !allChildren })
  }

  isFilterDirty = () => {
    const { defaultFilter = {}, currentFilter = {} } = this.props
    const filterDiff = diff(defaultFilter, currentFilter)
    if (_.isEmpty(filterDiff)) {
      return false
    }
    return true
  }

  openFilterModal = () => {
    const { levelCategoryList, currentFilter } = this.props

    this.setState({ isModalOpen: true, levelCategoryList, ...currentFilter })
  }

  closeFilterModal = () => {
    this.setState({ isModalOpen: false })
  }

  _renderHeader = () => (
    <View
      style={{
        flexBasis: 44,
        height: 50,
        width: '100%',
        backgroundColor: '#fff',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      {/* {isIos ? <View style={{ marginTop: 30 }} /> : null} */}
      <Text style={{ color: COLORS.TEXT_ACTIVE, fontSize: STYLES.FONT_SIZE_LARGER, flex: 1, textAlign: 'center' }}>กรอง</Text>
      {Platform.OS === 'web' ? null : (
        <TouchableOpacity style={{ width: 50, height: 50, justifyContent: 'center' }} onPress={this.closeFilterModal}>
          <XIcon name='cancel' family='MaterialIcons' style={{ flex: 0 }} />
        </TouchableOpacity>
      )}
    </View>
  )

  _renderProductCategoryButton = (data: ICategory, index: number) => {
    const marginLeft = index === 0 ? 4 : 4
    return (
      <TouchableOpacity
        key={`ProductFilterCatItem_${index.toString()}_${data.id}`}
        onPress={() => this._seletedCategory(data)}
        style={{
          // minWidth: 30,
          // width: 50,
          // maxWidth: 120,
          height: 25,
          borderColor: data.l,
          borderWidth: 0.7,
          borderRadius: 6,
          marginLeft,
          paddingLeft: 8,
          marginTop: 10,
          justifyContent: 'center',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        {data.selected ? (
          <Icon name='check' type='AntDesign' style={{ color: COLORS.APP_MAIN, minWidth: 25, fontSize: 20, width: 25 }} />
        ) : (
          <View style={{ height: 1, width: 25 }} />
        )}
        <XText
          style={{
            // fontSize: STYLES.FONT_SIZE_NORMAL,
            color: data.l ? data.l : COLORS.TEXT_INACTIVE,
            textAlign: 'center',
            paddingHorizontal: 2,
            paddingRight: 6,
          }}
          numberOfLines={1}>
          {data.n}
        </XText>
      </TouchableOpacity>
    )
  }

  onChangeSegmentHasMkpChIndex = (idx: number) => {
    const { channels } = this.state
    const newState: Partial<IProductListFilterViewState> = {}
    newState.segmentHasMkpChIndex = idx
    if (idx === 2) {
      newState.mkpChIds = channels.filter((ch: IMKPChannelDetail) => ch.is_active).map((ch) => ch.id)
    }
    // @ts-ignore
    this.setState(newState)
  }

  _renderFilterWhichSystemIn = () => {
    const { channels, segmentHasMkpChIndex = 0 } = this.state

    if (!channels || channels.length === 0) {
      return null
    }

    return (
      <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white'>
        <HStack alignItems='center' space='1'>
          {/* <XIcon variant='active' name='file-document-outline' family='MaterialCommunityIcons' /> */}
          <XImageIcon source={logoMkpChannel} />
          <XText variant='inactive'>สถานะจากช่องทางขาย</XText>
        </HStack>

        <Segment
          selectedIndex={segmentHasMkpChIndex}
          options={['ทั้งหมด', 'ไม่เชื่อม', 'เชื่อมอยู่']}
          onSegmentChange={this.onChangeSegmentHasMkpChIndex}
        />

        {segmentHasMkpChIndex === 2 ? (
          <VStack w='full'>
            <XText variant='inactive'>เชื่อมอยู่กับอย่างน้อย 1 ช่องทางเหล่านี้...</XText>
            <HStack w='full' flexWrap='wrap'>
              <MKPChannelsActive channels={channels} onPressChannel={this._onPressMkpCh} />
            </HStack>
          </VStack>
        ) : null}
      </VStack>
    )
  }

  onChangeSegmentHasCreatedByMkpChIndex = (idx: number) => {
    // const { hasCreatedByMkpChannels } = this.state
    const newState: Partial<IProductListFilterViewState> = {}
    newState.segmentHasCreatedByMkpChIndex = idx
    // if (_.includes([1, 2], idx)) {
    //   newState.hasCreatedByMkpChMkpChIds = hasCreatedByMkpChannels.filter((ch: IMKPChannelDetail) => ch.is_active).map((ch) => ch.id)
    // }
    if (_.includes([0, 1], idx)) {
      newState.segmentHasCreatedByMkpChSpecificIndex = 0
    }
    // @ts-ignore
    this.setState(newState)
  }

  onChangeSegmentHasCreatedByMkpChSpecificIndex = (idx: number) => {
    const { hasCreatedByMkpChannels } = this.state
    const newState: Partial<IProductListFilterViewState> = {}
    newState.segmentHasCreatedByMkpChSpecificIndex = idx
    if (idx === 2) {
      newState.hasCreatedByMkpChMkpChIds = hasCreatedByMkpChannels.filter((ch: IMKPChannelDetail) => ch.is_active).map((ch) => ch.id)
    }
    // @ts-ignore
    this.setState(newState)
  }

  // _renderFilterHasCreatedByMkpCh = () => {
  //   const { hasCreatedByMkpChannels, segmentHasCreatedByMkpChIndex = 0 } = this.state

  //   if (!hasCreatedByMkpChannels || hasCreatedByMkpChannels.length === 0) {
  //     return null
  //   }

  //   return (
  //     <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white'>
  //       <HStack alignItems='center' space='1'>
  //         {/* <XIcon variant='active' name='file-document-outline' family='MaterialCommunityIcons' /> */}
  //         <XImageIcon source={logoMkpChannel} />
  //         <XText variant='inactive'>ถูกสร้างอัตโนมัติโดยช่องทางขาย</XText>
  //       </HStack>

  //       <Segment
  //         selectedIndex={segmentHasCreatedByMkpChIndex}
  //         options={['ทั้งหมด', 'ไม่อยู่ใน', 'อยู่ใน']}
  //         onSegmentChange={this.onChangeSegmentHasCreatedByMkpChIndex}
  //       />

  //       {_.includes([1, 2], segmentHasCreatedByMkpChIndex) ? (
  //         <VStack w='full'>
  //           {segmentHasCreatedByMkpChIndex === 1 ? <XText variant='inactive'>ไม่แสดงช่องทางเหล่านี้...</XText> : null}
  //           {segmentHasCreatedByMkpChIndex === 2 ? <XText variant='inactive'>แสดงเฉพาะช่องทางเหล่านี้...</XText> : null}
  //           <HStack w='full' flexWrap='wrap'>
  //             <MKPChannelsActive channels={hasCreatedByMkpChannels} onPressChannel={this._onPressHasCreatedByMkpChMkpCh} />
  //           </HStack>
  //         </VStack>
  //       ) : null}
  //     </VStack>
  //   )
  // }

  onChangeSegmentHasParentIndex = (idx: number) => {
    const newState: Partial<IProductListFilterViewState> = {}
    newState.segmentHasParentIndex = idx
    if (_.includes([0, 1], idx)) {
      newState.segmentHasCreatedByMkpChIndex = 0
      newState.segmentHasCreatedByMkpChSpecificIndex = 0
    }
    // @ts-ignore
    this.setState(newState)
  }

  _renderFilterHasParent = () => {
    const {
      segmentHasParentIndex = 0,
      segmentHasCreatedByMkpChIndex = 0,
      segmentHasCreatedByMkpChSpecificIndex = 0,
      hasCreatedByMkpChannels,
    } = this.state

    return (
      <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='note-add' family='MaterialIcons' />
          <XText variant='inactive'>สร้างโดย</XText>
        </HStack>

        <Segment
          selectedIndex={segmentHasParentIndex}
          options={['ทั้งหมด', 'ดึงจากร้านอื่น', 'ร้านฉัน']}
          onSegmentChange={this.onChangeSegmentHasParentIndex}
        />

        {segmentHasParentIndex === 2 ? (
          <VStack w='full' space='1'>
            <HStack alignItems='center' space='1'>
              <XIcon variant='active' name='file-document-outline' family='MaterialCommunityIcons' />
              <XText variant='inactive'>วิธีที่ถูกสร้าง</XText>
            </HStack>
            <Segment
              selectedIndex={segmentHasCreatedByMkpChIndex}
              options={['ทั้งหมด', 'สร้างเอง', 'ระบบสร้างให้']}
              onSegmentChange={this.onChangeSegmentHasCreatedByMkpChIndex}
            />
          </VStack>
        ) : null}

        {segmentHasCreatedByMkpChIndex === 2 && hasCreatedByMkpChannels && hasCreatedByMkpChannels.length > 0 ? (
          <VStack w='full' space='1'>
            <HStack alignItems='center' space='1'>
              <XImageIcon source={logoMkpChannel} />
              <XText variant='inactive'>ถูกสร้างอัตโนมัติจากการซิงค์ข้อมูลจากช่องทางขาย</XText>
            </HStack>

            <Segment
              selectedIndex={segmentHasCreatedByMkpChSpecificIndex}
              options={['ทั้งหมด', 'ระบุช่องทาง']}
              onSegmentChange={this.onChangeSegmentHasCreatedByMkpChSpecificIndex}
            />
          </VStack>
        ) : null}

        {segmentHasCreatedByMkpChSpecificIndex === 1 ? (
          <VStack w='full'>
            <XText variant='inactive'>แสดงเฉพาะช่องทางเหล่านี้...</XText>
            <HStack w='full' flexWrap='wrap'>
              <MKPChannelsActive channels={hasCreatedByMkpChannels} onPressChannel={this._onPressHasCreatedByMkpChMkpCh} />
            </HStack>
          </VStack>
        ) : null}
      </VStack>
    )
  }

  _renderCategoryCard = () => {
    const { levelCategoryList, allChildren } = this.state
    if (_.isNil(levelCategoryList)) {
      return null
    }
    return (
      <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' family='MaterialCommunityIcons' name='view-grid' />
          <XText variant='inactive'>หมวดหมู่</XText>
        </HStack>

        <HStack w='full' flexWrap='wrap'>
          {levelCategoryList.map(this._renderProductCategoryButton)}
        </HStack>

        <TouchableOpacity
          // style={{ width: 130, justifyContent: 'center', flexDirection: 'row', alignItems: 'center', minHeight: 34 }}
          onPress={this._setAllChildrenCategory}>
          <HStack alignItems='center' space='1'>
            {allChildren ? <XIcon family='FontAwesome' name='check-square-o' /> : <XIcon family='FontAwesome' name='square-o' />}
            <XText>รวมหมวดหมู่ย่อย</XText>
          </HStack>
        </TouchableOpacity>
      </VStack>
    )
  }

  onChangeSegmentCreatedAtIndex = (idx) => {
    const newState: Partial<IProductListFilterViewState> = {}
    newState.segmentCreatedAtIndex = idx
    if (idx === 0) {
      newState.createdAtRangeOptionKey = null
      newState.createdAtFrom = null
      newState.createdAtTo = null
    }
    if (idx === 1) {
      newState.createdAtRangeOptionKey = CONS.DATERANGE_SELECTOR.OPTION.Today
      const dateRange = util.getDateRangeFromOption(newState.createdAtRangeOptionKey)
      newState.createdAtFrom = dateRange.begin
      newState.createdAtTo = dateRange.end
    }
    // @ts-ignore
    this.setState(newState)
  }

  onChangeDateCreatedAtFromTo = async (params: IXDateRangeSelectorChangeParams) => {
    const { dates, option, optionKey } = params
    const newState: Partial<IProductListFilterViewState> = {}
    newState.createdAtFrom = dates.begin
    newState.createdAtTo = util.changeSecondTime(dates.end, 59)
    newState.createdAtRangeOptionKey = optionKey
    await util.setStatePromise(this, newState)
  }

  _renderFilterCreatedAt = () => {
    const { segmentCreatedAtIndex = 0, createdAtFrom, createdAtTo, createdAtRangeOptionKey } = this.state

    const createdAtDateRange = { begin: createdAtFrom, end: createdAtTo }

    return (
      <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='back-in-time' family='Entypo' />
          <XText variant='inactive'>สร้างภายใน</XText>
        </HStack>
        <Segment selectedIndex={segmentCreatedAtIndex} options={['ทั้งหมด', 'ระบุ']} onSegmentChange={this.onChangeSegmentCreatedAtIndex} />
        {segmentCreatedAtIndex === 1 ? (
          <XDateRangeSelectorButton
            // allowDateRangeLimitDays={this.props.allowDateRangeLimitDays}
            // isOptionsScrollable={IS_SMALL_PHONE}
            isOptionsScrollable
            title='สร้างภายใน...'
            selectedDates={createdAtDateRange}
            selectedOptionKey={createdAtRangeOptionKey}
            onChange={this.onChangeDateCreatedAtFromTo}
            showTime
          />
        ) : null}
      </VStack>
    )
  }

  onChangeSegmentUpdatedAtIndex = (idx) => {
    const newState: Partial<IProductListFilterViewState> = {}
    newState.segmentUpdatedAtIndex = idx
    if (idx === 0) {
      newState.updatedAtRangeOptionKey = null
      newState.updatedAtFrom = null
      newState.updatedAtTo = null
    }
    if (idx === 1) {
      newState.updatedAtRangeOptionKey = CONS.DATERANGE_SELECTOR.OPTION.Today
      const dateRange = util.getDateRangeFromOption(newState.updatedAtRangeOptionKey)
      newState.updatedAtFrom = dateRange.begin
      newState.updatedAtTo = dateRange.end
    }
    // @ts-ignore
    this.setState(newState)
  }

  onChangeDateUpdatedAtFromTo = async (params: IXDateRangeSelectorChangeParams) => {
    const { dates, option, optionKey } = params
    const newState: Partial<IProductListFilterViewState> = {}
    newState.updatedAtFrom = dates.begin
    newState.updatedAtTo = util.changeSecondTime(dates.end, 59)
    newState.updatedAtRangeOptionKey = optionKey
    await util.setStatePromise(this, newState)
  }

  _renderFilterUpdatedAt = () => {
    const { segmentUpdatedAtIndex = 0, updatedAtFrom, updatedAtTo, updatedAtRangeOptionKey } = this.state

    const updatedAtDateRange = { begin: updatedAtFrom, end: updatedAtTo }

    return (
      <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='back-in-time' family='Entypo' />
          <XText variant='inactive'>อัพเดทภายใน</XText>
        </HStack>
        <Segment selectedIndex={segmentUpdatedAtIndex} options={['ทั้งหมด', 'ระบุ']} onSegmentChange={this.onChangeSegmentUpdatedAtIndex} />
        {segmentUpdatedAtIndex === 1 ? (
          <XDateRangeSelectorButton
            // allowDateRangeLimitDays={this.props.allowDateRangeLimitDays}
            // isOptionsScrollable={IS_SMALL_PHONE}
            isOptionsScrollable
            title='อัพเดทภายใน...'
            selectedDates={updatedAtDateRange}
            selectedOptionKey={updatedAtRangeOptionKey}
            onChange={this.onChangeDateUpdatedAtFromTo}
            showTime
          />
        ) : null}
      </VStack>
    )
  }

  _onPressMkpCh = (channels: IMKPChannelDetail[]) => {
    if (_.isNil(channels)) {
      this.setState({ mkpChIds: [] })
      return
    }

    const mkpChIds = []

    channels.forEach((ch: IMKPChannelDetail) => {
      if (ch.is_active) {
        mkpChIds.push(ch.id)
      }
    })

    this.setState({ mkpChIds })
  }

  _onPressHasCreatedByMkpChMkpCh = (channels: IMKPChannelDetail[]) => {
    if (_.isNil(channels)) {
      this.setState({ hasCreatedByMkpChMkpChIds: [] })
      return
    }
    const hasCreatedByMkpChMkpChIds = []

    channels.forEach((ch: IMKPChannelDetail) => {
      if (ch.is_active) {
        hasCreatedByMkpChMkpChIds.push(ch.id)
      }
    })

    this.setState({ hasCreatedByMkpChMkpChIds })
  }

  _renderMain = () => {
    const { isModalOpen } = this.state
    const { levelCategoryList } = this.props
    if (!isModalOpen) {
      return null
    }

    return (
      <VStack flex={1} w='full'>
        {/* {this._renderHeader()} */}
        <XScrollView flex={1} minH={p.op.isWeb() ? '200px' : '500px'}>
          <VStack w='full' space='1'>
            {this._renderFilterUpdatedAt()}
            {this._renderFilterCreatedAt()}
            {this._renderFilterHasParent()}
            {this._renderFilterWhichSystemIn()}
            {this._renderCategoryCard()}
            {/* {this._renderFilterHasCreatedByMkpCh()} */}
          </VStack>
        </XScrollView>

        <HStack w='full' h='12' p='1' space='1' alignItems='center'>
          <XButton w='120px' variant='outline' onPress={this._clearAllSelected}>
            ล้าง
          </XButton>
          <XButton flex={1} onPress={this._onPressSubmitBtn}>
            กรอง
          </XButton>
        </HStack>
      </VStack>
    )
  }

  render() {
    const { disabled, children } = this.props
    const { isModalOpen } = this.state

    return (
      <>
        <XModal
          // webWrapperContainerStyle={{ backgroundColor: 'white' }}
          visible={isModalOpen}
          webNumColumns={1}
          title='กรอง'
          hasCloseButton
          onRequestClose={this.closeFilterModal}>
          {this._renderMain()}
        </XModal>
        {children || (
          <TouchableOpacity disabled={disabled} onPress={this.openFilterModal}>
            <VStack w='9' h='9' alignItems='center' justifyContent='center'>
              <Box pt='1'>
                <XIcon family='MaterialCommunityIcons' name='filter' variant={disabled ? 'inactive' : 'primary'} />
                {this.isFilterDirty() ? (
                  <XIcon
                    family='MaterialCommunityIcons'
                    name='check-circle'
                    variant='success'
                    position='absolute'
                    bottom='0'
                    right='0'
                    size='12px'
                    // style={{ fontSize: 12, width: 12, height: 12, color: COLORS.BRAND_Success }}
                    // style={{ color: COLORS.BRAND_Success, fontSize: 12, position: 'absolute', paddingTop: 12, paddingLeft: 12 }}
                  />
                ) : null}
              </Box>
            </VStack>
          </TouchableOpacity>
        )}
      </>
    )
  }
}
