import React from 'react'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import { ImageBackground, TouchableOpacity } from 'react-native'
import { COLORS } from 'x/config/styles'
import XIcon from 'xui/components/XIcon'
import _ from 'lodash'
import XImage from 'xui/components/XImage'

interface IImgItemSalePageProps {
  imgData: { url: string; link?: string; w: number; h: number }
  index: number
  indexShowToolBar: number
  indexShowToolBarChange: (newIdx: number) => void
}

const VERTICAL_LINE = <HStack h='6' mx='1' borderLeftWidth='1' borderLeftColor='#D4D4D4' />

const ImgItemSalePage: React.FC<IImgItemSalePageProps> = ({ imgData, index, indexShowToolBar, indexShowToolBarChange }) => {
  const [widthImg, setWidthImg] = React.useState(80)

  // React.useEffect(() => {
  //   // Update the document title using the browser API
  //   // document.title = `You clicked ${count} times`;
  // }, [indexShowToolBar, index])

  const _renderToolBar = (
    item: any,
    title: string,
    onShow: boolean,
    idx: number,
    onPressEdit: (item: any, newIndex: number, popViewNumber: number) => void
  ) => {
    if (!onShow) {
      return (
        <HStack
          zIndex={990}
          minHeight='20'
          // bgColor={COLORS.BG_LIGHT_GREY}
          position='absolute'
          top='0'
          // bottom='0'
          left='0'
          right='0'>
          <HStack w='full' h='10' alignItems='center' justifyContent='flex-end'>
            <TouchableOpacity
              onPress={() => indexShowToolBarChange(idx)}
              style={{
                width: 32,
                height: 32,
                alignItems: 'center',
                justifyContent: 'center',
                // backgroundColor: COLORS.WHITE,
                borderRadius: 100,
                marginLeft: 2,
                marginRight: 8,
              }}>
              <XIcon name='settings-outline' family='Ionicons' color={COLORS.TEXT_ACTIVE} />
              <HStack
                position='absolute'
                top='0'
                left='0'
                right='0'
                bottom='0'
                opacity={30}
                bgColor={COLORS.TEXT_INACTIVE}
                borderRadius='full'
              />
            </TouchableOpacity>
          </HStack>
        </HStack>
      )
    }
    return (
      <HStack
        zIndex={990}
        minHeight='20'
        // bgColor={COLORS.BG_LIGHT_GREY}
        position='absolute'
        top='0'
        // bottom='0'
        left='0'
        right='0'>
        <HStack position='absolute' top='0' left='0' right='0' bottom='0' opacity={30} bgColor={COLORS.BLACK} />
        <VStack w='full'>
          <HStack w='full' h='10' alignItems='center' justifyContent='flex-end'>
            <HStack flex={1}>
              <XText ml='2' textAlign='left' color={COLORS.WHITE}>
                {title}
              </XText>
            </HStack>
            {/* {VERTICAL_LINE} */}
            <TouchableOpacity
              onPress={() => indexShowToolBarChange(-1)}
              style={{
                width: 32,
                height: 32,
                alignItems: 'center',
                justifyContent: 'center',
                // backgroundColor: COLORS.WHITE,
                borderRadius: 100,
                marginLeft: 2,
                marginRight: 8,
              }}>
              <XIcon name='close-outline' family='Ionicons' color={COLORS.TEXT_ACTIVE} />
              <HStack position='absolute' top='0' left='0' right='0' bottom='0' opacity={40} bgColor={COLORS.WHITE} borderRadius='full' />
            </TouchableOpacity>
          </HStack>
          <HStack h='10' w='full' alignItems='center' justifyContent='flex-end'>
            <TouchableOpacity
              style={{
                width: 32,
                height: 32,
                alignItems: 'center',
                justifyContent: 'center',
                // backgroundColor: COLORS.WHITE,
                borderRadius: 100,
                marginRight: 4,
              }}>
              <XIcon zIndex={999} name='chevron-up' style={{ fontWeight: 'bold' }} family='Ionicons' color={COLORS.BLACK} />
              <HStack position='absolute' top='0' left='0' right='0' bottom='0' opacity={90} bgColor={COLORS.WHITE} borderRadius='full' />
            </TouchableOpacity>
            {/* {VERTICAL_LINE} */}
            <TouchableOpacity
              style={{
                width: 32,
                height: 32,
                alignItems: 'center',
                justifyContent: 'center',
                // backgroundColor: COLORS.WHITE,
                borderRadius: 100,
                marginRight: 2,
              }}>
              <XIcon zIndex={999} name='chevron-down' style={{ fontWeight: 'bold' }} family='Ionicons' color={COLORS.BLACK} />
              <HStack position='absolute' top='0' left='0' right='0' bottom='0' opacity={90} bgColor={COLORS.WHITE} borderRadius='full' />
            </TouchableOpacity>
            {VERTICAL_LINE}
            <TouchableOpacity
              style={{
                // width: 52,
                height: 32,
                // backgroundColor: COLORS.BG_LIGHT_GREY,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 100,
                marginRight: 2,
                marginLeft: 2,
                flexDirection: 'row',
                paddingLeft: 4,
                paddingRight: 8,
              }}
              onPress={() => {
                if (_.isFunction(onPressEdit)) {
                  onPressEdit(null, index - 1, 1)
                }
              }}>
              <XIcon zIndex={999} name='add' family='Ionicons' color={COLORS.BLACK} />
              <XText zIndex={999} color={COLORS.BLACK}>
                บน
              </XText>
              <HStack position='absolute' top='0' left='0' right='0' bottom='0' opacity={90} bgColor={COLORS.WHITE} borderRadius='full' />
            </TouchableOpacity>
            {/* {VERTICAL_LINE} */}
            <TouchableOpacity
              style={{
                // width: 52,
                height: 32,
                // backgroundColor: COLORS.BG_LIGHT_GREY,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 100,
                marginRight: 2,
                marginLeft: 2,
                flexDirection: 'row',
                paddingLeft: 4,
                paddingRight: 8,
              }}
              onPress={() => {
                if (_.isFunction(onPressEdit)) {
                  onPressEdit(null, index + 1, 1)
                }
              }}>
              <XIcon zIndex={999} name='add' family='Ionicons' color={COLORS.BLACK} />
              <XText zIndex={999} color={COLORS.BLACK}>
                ล่าง
              </XText>
              <HStack position='absolute' top='0' left='0' right='0' bottom='0' opacity={90} bgColor={COLORS.WHITE} borderRadius='full' />
            </TouchableOpacity>
            {VERTICAL_LINE}
            <TouchableOpacity
              style={{
                width: 52,
                height: 32,
                // backgroundColor: COLORS.BG_LIGHT_GREY,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 100,
                marginRight: 8,
                marginLeft: 2,
              }}
              onPress={() => {
                if (_.isFunction(onPressEdit)) {
                  onPressEdit(item, index, 1)
                }
              }}>
              {/* <XText color='#F5C2AF'>แก้ไข</XText> */}
              <XText zIndex={999} color={COLORS.APP_MAIN}>
                แก้ไข
              </XText>
              <HStack position='absolute' top='0' left='0' right='0' bottom='0' opacity={90} bgColor={COLORS.WHITE} borderRadius='full' />
            </TouchableOpacity>
          </HStack>
        </VStack>
      </HStack>
    )
  }

  // console.log('imgData => ', imgData)
  const widthViewMath = Math.round(widthImg) || 1
  // console.log('widthViewMath => ', widthViewMath)
  const widthImgMath = Math.round(imgData.w) || 1
  const hightImgMath = Math.round(imgData.h) || 1
  let imgHeight = hightImgMath
  // console.log('---> ', `${index}`)

  // ถ้าความกว้างมีค่ามากกว่าความยาว
  if (widthImgMath > hightImgMath) {
    const miniaturize = widthImgMath - widthViewMath
    // console.log('miniaturize => ', miniaturize)
    const widthPercent = ((widthImgMath - miniaturize) / widthImgMath) * 100
    // console.log('widthPercent => ', Math.round(widthPercent))
    imgHeight = (Math.round(widthPercent) / 100) * hightImgMath
    //   imgHeight = hightImgMath - Math.round(miniaturize)
    // ถ้าความกว้างของรูปมีค่าเท่ากับความสูง
  } else if (widthImgMath === hightImgMath) {
    imgHeight = widthImg
    // ถ้าความกว้างของรูปมีค่ามากกว่าขนาดจอ
  } else {
    // console.log('LAST => ')
    // imgHeight = (widthImgMath * widthViewMath) / hightImgMath
    const aspectRatio = widthImgMath / hightImgMath
    imgHeight = widthViewMath / Math.round(aspectRatio)
  }
  const finalHeight = Math.round(imgHeight)
  // console.log('finalHeight => ', finalHeight)
  // if (_.isNil(finalHeight) || finalHeight < 1) {
  //   return null
  // }
  return (
    <VStack w='full' bgColor={COLORS.RED} key={`renderImg${index}`}>
      <HStack
        w='full'
        // position='relative'
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          if (width < 1) {
            return
          }
          setWidthImg(Math.round(width))
        }}>
        {_renderToolBar(imgData, 'รูป', indexShowToolBar === index, index, null)}
        {/* <XText>{`finalHeight => ${finalHeight}`}</XText>
        <XText>{`widthViewMath => ${widthViewMath}`}</XText> */}
        {!_.isNil(finalHeight) && finalHeight > 0 && !_.isNil(widthViewMath) && widthViewMath > 0 ? (
          <ImageBackground source={{ uri: imgData.url }} style={{ width: widthViewMath, height: finalHeight }} />
        ) : null}

        {/* <ImageBackground source={{ uri: imgData.url }} style={{ width: '100%', height: this.state.heightImg }} /> */}
        {/* <XImage style={{ width: this.state.heightImg, height: this.state.heightImg }} source={{ uri: imgData.url }} /> */}
      </HStack>
    </VStack>
  )
}

export default ImgItemSalePage
