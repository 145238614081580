import React, { Component } from 'react'
import { TouchableOpacity } from 'react-native'
import dayjs from 'dayjs'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { getSelectedStore } from 'x/redux/selectors'
import * as StoreState from 'x/modules/store/StoreState'

import {
  IOrderListFilterPreference,
  IOrderListFilterTemplate,
  IOrderListFilterTemplateSelectorProps,
  IOrderListFilterTemplateSelectorState,
} from 'x/index'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'

import _ from 'lodash'
import HStack from '../HStack'
import VStack from '../VStack'
import XText from '../XText'
import XCustomHeader from '../XCustomHeader'
import Box from '../Box'
import XCard from '../XCard'
import XOverlay from '../XOverlay'
import XContent from '../XContent'
import FilterBoltButton from '../FilterBoltButton'
import XButton from '../XButton'
import ForwardIcon from '../ForwardIcon'
import XIcon from '../XIcon'

class OrderListFilterTemplateSelector extends Component<IOrderListFilterTemplateSelectorProps, IOrderListFilterTemplateSelectorState> {
  static displayName = 'OrderListFilterTemplateSelector'

  inProcess?: boolean

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,
      isFilterTemplateSelectorModalVisible: false,
    }
  }

  async componentDidMount(): Promise<void> {
    // Normalized selectedTemplate first time
    const { selectedTemplate } = this.props
    const isSelectable = this._isSelectable()
    if (isSelectable && selectedTemplate && selectedTemplate.id) {
      this._onSelectFilterTemplate(selectedTemplate)
      await util.delay(50)
    }

    this.setState({ isInitialized: true })
  }

  _getPref = (): IOrderListFilterPreference => {
    const { selectedStore } = this.props
    const pref = selectedStore.get('pref_ui_order_list_filter')

    if (!pref) {
      return {
        templates: [],
        dashboardItems: [],
        createdAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      }
    }

    return Map.isMap(pref) ? pref.toJS() : pref
  }

  _getNormalizeFilterTemplate = (tmpl: IOrderListFilterTemplate) => {
    const nTmpl = { ...tmpl }

    if (nTmpl.autoCancelOrderDateRangeOptionKey) {
      const dr = util.getDateRangeFromOption(nTmpl.autoCancelOrderDateRangeOptionKey)
      nTmpl.autoCancelOrderFrom = dr.begin
      nTmpl.autoCancelOrderTo = dr.end
    }

    if (nTmpl.createOrderDateRangeOptionKey) {
      const dr = util.getDateRangeFromOption(nTmpl.createOrderDateRangeOptionKey)
      nTmpl.createOrderFrom = dr.begin
      nTmpl.createOrderTo = dr.end
    }

    if (nTmpl.dateDeliveryDateRangeOptionKey) {
      const dr = util.getDateRangeFromOption(nTmpl.dateDeliveryDateRangeOptionKey)
      nTmpl.dateDeliveryFrom = dr.begin
      nTmpl.dateDeliveryTo = dr.end
    }

    if (nTmpl.paymentCompletedAtDateRangeOptionKey) {
      const dr = util.getDateRangeFromOption(nTmpl.paymentCompletedAtDateRangeOptionKey)
      nTmpl.paymentCompletedAtFrom = dr.begin
      nTmpl.paymentCompletedAtTo = dr.end
    }

    if (nTmpl.printedAtDateRangeOptionKey) {
      const dr = util.getDateRangeFromOption(nTmpl.printedAtDateRangeOptionKey)
      nTmpl.printedAtFrom = dr.begin
      nTmpl.printedAtTo = dr.end
    }

    if (nTmpl.readyToShipDateRangeOptionKey) {
      const dr = util.getDateRangeFromOption(nTmpl.readyToShipDateRangeOptionKey)
      nTmpl.readyToShipDateFrom = dr.begin
      nTmpl.readyToShipDateTo = dr.end
    }

    if (nTmpl.mkpChannelActiveMap && !_.isEmpty(nTmpl.mkpChannelActiveMap)) {
      const mkpChannelIds = Object.keys(nTmpl.mkpChannelActiveMap).map((mkpId) => parseInt(mkpId))
      if (mkpChannelIds && mkpChannelIds.length > 0) {
        // const removeMkpIds = []
        for (let i = 0; i < mkpChannelIds.length; i++) {
          const mkpId = mkpChannelIds[i]
          const isExist = util.isMkpChannelExist(mkpId)
          // if (isExist) {
          //   removeMkpIds.push(mkpId)
          // }

          if (!isExist) {
            delete nTmpl.mkpChannelActiveMap[mkpId]
          }
        }

        // if (removeMkpIds.length > 0) {
        //   nTmpl.mkpChannelIds = nTmpl.mkpChannelIds.filter((id) => !removeMkpIds.includes(id))
        // }
      }
    }

    return nTmpl
  }

  _isSelectable = () => {
    const { onSelect } = this.props
    return _.isFunction(onSelect)
  }

  _onSelectFilterTemplate = (tmpl: IOrderListFilterTemplate, isSelected?: boolean) => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { onSelect } = this.props

    if (_.isFunction(onSelect)) {
      if (isSelected) {
        onSelect(null)
      } else {
        const nTmpl = this._getNormalizeFilterTemplate(tmpl)
        onSelect(nTmpl)
      }
    }

    // if (isSelected) {
    //   await this.resetToDefaultSetting()
    //   await this.resetSelectedTemplate()
    //   p.op.showToast('เลิกใช้งานนเทมเพลตตัวกรองออเดอร์', 'success')
    // } else {
    //   const nTmpl = this._getNormalizeFilterTemplate(tmpl)
    //   await util.setStatePromise(this, { selectedTemplate: nTmpl })
    //   await this.updateNewSetting(nTmpl)
    //   p.op.showToast('ใช้งานเทมเพลตตัวกรองออเดอร์แล้ว', 'success')
    // }

    this.inProcess = false
    this._closeFilterTemplateSelectorModal()
  }

  _clearSelect = () => {
    const { selectedTemplate } = this.props
    this._onSelectFilterTemplate(selectedTemplate, true)
  }

  _onPrefManageSaveSuccess = async (newPref: IOrderListFilterPreference, savedTemplate?: IOrderListFilterTemplate) => {
    // console.log('OrderListFilterManageView::onSaveSuccess')
    const { selectedTemplate } = this.props
    const pref = this._getPref()
    const { onSelect, onSaveSuccess } = this.props

    if (selectedTemplate && pref && _.isArray(pref.templates)) {
      const tmplId = selectedTemplate.id
      const foundIndex = pref.templates.findIndex((tmpl) => tmpl.id === tmplId)

      if (foundIndex < 0) {
        if (_.isFunction(onSelect)) {
          onSelect(null)
        }
      }
    }

    if (_.isFunction(onSaveSuccess)) {
      await util.delay(100)
      onSaveSuccess(newPref, savedTemplate)
    }
  }

  _navToOrderListFilterManageView = async () => {
    this._closeFilterTemplateSelectorModal()
    await util.delay(100)

    const { navigation, selectedStore } = this.props

    navigation.dispatch(
      NavActions.navToOrderListFilterManageView({
        store_id: selectedStore.get('id'),
        onSaveSuccess: this._onPrefManageSaveSuccess,
      })
    )
  }

  _navToCreateFilterTemplate = async () => {
    this._closeFilterTemplateSelectorModal()
    await util.delay(100)

    const { navigation, selectedStore } = this.props

    const newTemplate: IOrderListFilterTemplate = {
      id: `${dayjs().format('YYYYMMDD').toString()}x${util.generateRandomString(5)}`,
      name: '',
      createdAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      updatedAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      color: '#b79ec7',
    }

    navigation.dispatch(
      NavActions.navToOrderListFilterEditorView({
        headerTitle: 'สร้างเทมเพลตตัวกรองออเดอร์',
        store_id: selectedStore.get('id'),
        defaultSetting: newTemplate,
        appliedSetting: newTemplate,
        // onSaveSuccess: this._onEditFilterSaveSuccess,
      })
    )
  }

  _openFilterTemplateSelectorModal = () => {
    // const pref = this._getPref()
    // const { templates } = pref
    // if (!templates || templates.length === 0) {
    //   p.op.alert('ยังไม่มีเทมเพลตตัวกรองออเดอร์', 'กรุณาปรับตัวกรอง และกดเพิ่มเทมเพลตตัวกรองออเดอร์ จากปุ่มบันทึกในแถบด้านบนด้านขวามือ')
    //   return
    // }

    this.setState({ isFilterTemplateSelectorModalVisible: true })
  }

  _closeFilterTemplateSelectorModal = () => {
    const { onSelectorModalRequestClose } = this.props
    this.setState({ isFilterTemplateSelectorModalVisible: false })
  }

  _renderFilterTemplateItem = (tmpl: IOrderListFilterTemplate, index: number) => {
    const { selectedTemplate } = this.props
    const isSelected = selectedTemplate && selectedTemplate.id === tmpl.id

    return (
      <TouchableOpacity key={`filter-tmpl-${index}`} onPress={() => this._onSelectFilterTemplate(tmpl, isSelected)}>
        <HStack
          // key={`filter-tmpl-content-${index}`}
          w='full'
          minH='34px'
          p='2'
          space='2'
          bg={isSelected ? 'primary.50' : 'white'}
          borderWidth='1'
          borderColor={tmpl && tmpl.color ? tmpl.color : 'gray.400'}
          // borderTopColor={isSelected ? 'primary.500' : 'gray.300'}
          // borderLeftColor={isSelected ? 'primary.500' : 'gray.300'}
          // borderBottomColor={isSelected ? 'primary.300' : 'gray.400'}
          // borderRightColor={isSelected ? 'primary.400' : 'gray.400'}
          borderRadius='lg'
          alignItems='center'>
          {/* {isSelected ? (
            <XIcon
              name='checkmark-circle'
              family='Ionicons'
              // name={isSelected ? 'checkmark-circle' : 'circle-o'}
              // family={isSelected ? 'Ionicons' : 'FontAwesome'}
              // iconVariant={isSelected ? 'success' : 'inactive'}
            />
          ) : null} */}

          <Box w='20px' h='20px' borderRadius='full' bg={tmpl.color} />

          <HStack flex={1}>
            <XText color={tmpl.color}>{tmpl.name}</XText>
          </HStack>

          {/* {!isSelected && <ForwardIcon />} */}
          {/* {isSelected && <XText>กำลังใช้งาน</XText>} */}
          <HStack w='80px' justifyContent='flex-end' alignItems='center'>
            {isSelected ? <XIcon name='checkmark-circle' family='Ionicons' variant='success' /> : <ForwardIcon />}
          </HStack>
        </HStack>
      </TouchableOpacity>
    )
  }

  _renderEmptyTemplateText = () => (
    <HStack w='full' px='2' py='4' alignItems='center' justifyContent='center'>
      <XText variant='inactive' textAlign='center'>
        {'ยังไม่มีเทมเพลตตัวกรองออเดอร์\nกรุณากดปุ่ม "สร้างเทมเพลตใหม่" เพื่อสร้างเทมเพลต'}
      </XText>
    </HStack>
  )

  _renderFilterTemplateItems = () => {
    const pref = this._getPref()
    const { templates = [] } = pref

    if (!templates || templates.length === 0) {
      return this._renderEmptyTemplateText()
    }

    return templates.map(this._renderFilterTemplateItem)
  }

  _renderFilterTemplateSelectorModal = () => {
    const { isSelectorModalShow, selectedTemplate } = this.props
    const { isFilterTemplateSelectorModalVisible } = this.state

    const isShow = isSelectorModalShow || isFilterTemplateSelectorModalVisible
    if (!isShow) {
      return null
    }

    return (
      <XOverlay visible onRequestClose={this._closeFilterTemplateSelectorModal}>
        <XCustomHeader
          title='เลือกเทมเพลตตัวกรองออเดอร์'
          // headerRightProps={{ closeIcon: true, onPressItem: this._closeFilterTemplateSelectorModal }}
          headerRightProps={{ settingIcon: true, onPressItem: this._navToOrderListFilterManageView }}
        />
        <XContent flex={p.op.isWeb() ? 1 : 0} minH='350px' maxH='800px'>
          <VStack w='full' p='2' space='1.5'>
            {this._renderFilterTemplateItems()}
          </VStack>
        </XContent>

        <HStack w='full' alignItems='center' justifyContent='center' p='2' space='2'>
          <XButton flex={1} h='34px' variant='outline' onPress={this._navToCreateFilterTemplate}>
            สร้างเทมเพลตใหม่
          </XButton>
          <XButton
            disabled={!(selectedTemplate && selectedTemplate.id)}
            w='80px'
            h='34px'
            variant='outline'
            opacity={selectedTemplate && selectedTemplate.id ? 1 : 0}
            onPress={this._clearSelect}>
            ล้าง
          </XButton>
          <XButton w='80px' h='34px' variant='outline' onPress={this._closeFilterTemplateSelectorModal}>
            ปิด
          </XButton>
        </HStack>
      </XOverlay>
    )
  }

  renderTypeButton = () => {
    const { selectedTemplate } = this.props

    return (
      <VStack w='full' bg='white'>
        <XCard disabled={!this._isSelectable()} onPress={this._openFilterTemplateSelectorModal}>
          <HStack
            // flex={1}
            w='full'
            px='2'
            py='1'
            space='2'
            alignItems='center'
            borderWidth='1'
            borderStyle={selectedTemplate ? 'solid' : 'dashed'}
            // borderColor={selectedTemplate ? 'primary.500' : 'gray.400'}
            borderColor={selectedTemplate ? selectedTemplate.color : 'gray.400'}
            // borderColor='gray.400'
            borderRadius='lg'>
            {/* {selectedTemplate && selectedTemplate.color && (
                  <Box w='20px' h='20px' borderRadius='full' bg={selectedTemplate.color} />
                )} */}

            {selectedTemplate && selectedTemplate.color && <Box w='20px' h='20px' borderRadius='full' bg={selectedTemplate.color} />}

            {selectedTemplate && selectedTemplate.name ? (
              <XText flex={1} color={selectedTemplate.color} numberOfLines={3}>
                {selectedTemplate.name}
              </XText>
            ) : (
              <XText variant='primary' numberOfLines={1}>
                เลือกเทมเพลตตัวกรองออเดอร์
              </XText>
            )}
          </HStack>
        </XCard>
        {this._renderFilterTemplateSelectorModal()}
      </VStack>
    )
  }

  renderTypeIcon = () => {
    const { disabled = false, selectedTemplate } = this.props

    const isFiltered = !_.isNil(selectedTemplate) && _.isString(selectedTemplate.id)

    return (
      <VStack alignItems='center' justifyContent='center'>
        <FilterBoltButton disabled={disabled} isFiltered={isFiltered} onPress={this._openFilterTemplateSelectorModal} />
        {this._renderFilterTemplateSelectorModal()}
      </VStack>
    )
  }

  renderTypeCustom = () => {
    const { selectedTemplate, renderCustom } = this.props

    const isFiltered = !_.isNil(selectedTemplate) && _.isString(selectedTemplate.id)

    return (
      <VStack alignItems='center' justifyContent='center'>
        {renderCustom && renderCustom(this._openFilterTemplateSelectorModal, isFiltered)}
        {this._renderFilterTemplateSelectorModal()}
      </VStack>
    )
  }

  renderMain = () => {
    const { type = 'icon' } = this.props

    if (type === 'custom') {
      return this.renderTypeCustom()
    }

    if (type === 'button') {
      return this.renderTypeButton()
    }

    return this.renderTypeIcon()
  }

  render() {
    const { isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    return this.renderMain()
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => ({
    dispatch,
    updateSelectedStoreByKey: bindActionCreators(StoreState.updateSelectedStoreByKey, dispatch),
  })
)(OrderListFilterTemplateSelector) as React.FC<Omit<IOrderListFilterTemplateSelectorProps, 'selectedStore' | 'updateSelectedStoreByKey'>>
