import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as ProfileActions from 'x/modules/profile/ProfileState'
import * as MySubscriptionActions from 'x/modules/subscription/MySubscriptionState'
import { getMySubscription, getSelectedStore } from 'x/redux/selectors'
import InAppPurchaseView from './InAppPurchaseView'

export default connect(
  (state) => ({
    profile: state.get('profile'),
    subscription: getMySubscription(state),
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => ({
    fetchUserProfile: bindActionCreators(ProfileActions.fetchUserProfile, dispatch),
    fetchSubscription: bindActionCreators(MySubscriptionActions.fetchSubscription, dispatch),
  })
)(InAppPurchaseView)
