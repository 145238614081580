import React, { useState } from 'react'

// import p from 'x/config/platform-specific'
// import { PrintOptionKey } from 'x/index'
import { IOverlaySetupSyncOrderCallBack, IMKPChannelDetail } from 'x/index'
import XOverlay from 'xui/components/XOverlay'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import XDateRangePicker from 'xui/components/XDateRangePicker'
import Segment from 'xui/components/Segment'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import dayjs from 'dayjs'
import XIcon from 'xui/components/XIcon'
import { TouchableOpacity } from 'react-native'
import { COLORS } from 'x/config/styles'

// const moment = require('moment')
// require('moment/locale/th')
// require('moment-timezone')

interface IOverlaySetupSyncOrderProps {
  channel: IMKPChannelDetail
  onClose: () => void
  isOverlayVisible: boolean
  onSubmit: (callBack: IOverlaySetupSyncOrderCallBack) => void
}

// const newDateString = moment(new Date()).format(CONS.SERVER_DATE_FORMAT)
// const newFullDatetime = newDateString + ' 00:00:00'
// const endTime = newDateString + ' 00:00:59'

const OPTIONS_FROM = ['ถูกสร้าง', 'ถูกอัพเดท']
const OPTIONS_TIME_PERIOD = ['วันนี้', '3 วัน', '7 วัน', 'ระบุช่วงเวลา']

const OverlaySetupSyncOrder: React.FC<IOverlaySetupSyncOrderProps> = ({ channel, onClose, isOverlayVisible, onSubmit }) => {
  // const [isDidMountTimePicker, didMountTimePicker] = useStateWithPromise<boolean>(false)
  const toDate = {
    startDate: dayjs().hour(0).minute(0).second(0).millisecond(0),
    endDate: dayjs().hour(23).minute(59).second(59).millisecond(999),
  }
  const [dateTime, updateDateTime] = useState(toDate)

  const [segmentOrderFromIndex, onChangeSegmentOrderFromIndex] = useState(0)
  const [segmentIndex, onChangeSegmentIndex] = useState(0)
  const [isForceSync, updateForceSync] = useState(false)

  const onDateChange = ({ begin, end }) => {
    // console.log('TIME begin !!! => ', dayjs(begin).format(CONS.SERVER_DATETIME_FORMAT))
    // console.log('TIME end !!! => ', dayjs(end).format(CONS.SERVER_DATETIME_FORMAT))
    // const endchangeSecondTime = util.changeSecondTime(end, 59)
    const newState = { startDate: begin, endDate: end }
    updateDateTime(newState)
    // this.setState(newState)
  }

  const onChangeSegmentIndexOrderFrom = (newIndex: number) => {
    onChangeSegmentOrderFromIndex(newIndex)
  }

  const onChangeSegmentIndexFunction = (newIndex: number) => {
    if (newIndex === 0) {
      updateDateTime(toDate)
    } else if (newIndex === 1) {
      const sevenDay = {
        startDate: dayjs(new Date()).subtract(2, 'days').hour(0).minute(0).second(0).millisecond(0),
        endDate: dayjs(new Date()).add(59, 'second').hour(23).minute(59).second(59).millisecond(999),
      }
      updateDateTime(sevenDay)
    } else if (newIndex === 2) {
      const fifteenDay = {
        startDate: dayjs(new Date()).subtract(6, 'days').hour(0).minute(0).second(0).millisecond(0),
        endDate: dayjs(new Date()).add(59, 'second').hour(23).minute(59).second(59).millisecond(999),
      }
      updateDateTime(fifteenDay)
    }
    onChangeSegmentIndex(newIndex)
  }

  const submitBtn = () => {
    let startD = util.changeSecondTime(dateTime.startDate, 0)
    startD = util.changeMillisecondsTime(startD, 0)
    let endD = util.changeSecondTime(dateTime.endDate, 59)
    endD = util.changeMillisecondsTime(endD, 999)
    const callBack: IOverlaySetupSyncOrderCallBack = {
      timePeriod: segmentOrderFromIndex === 0 ? 'CREATE' : 'UPDATE',
      datatime: {
        start: dayjs(startD).format(CONS.SERVER_DATETIME_FORMAT_WITH_MILLISECONDS),
        end: dayjs(endD).format(CONS.SERVER_DATETIME_FORMAT_WITH_MILLISECONDS),
      },
      forceSync: isForceSync,
    }
    onSubmit(callBack)
  }
  const mkpName = util.getMKPName(channel.mkp_id)
  // const ORDER_AT = channel.mkp_id === 3 ? ['ถูกสร้าง'] : OPTIONS_FROM
  const _renderOverlay = () => (
    <XOverlay
      contentStyle={{ width: 310, alignSelf: 'center', alignContent: 'flex-start' }}
      visible={isOverlayVisible}
      // webNumColumns={1}
      onRequestClose={() => onClose()}>
      <Box w='full' p='2' bgColor='white' borderRadius='md' _dark={{ bgColor: 'gray.500' }}>
        <HStack>
          <XText variant='active' bold>
            {`ซิงค์ข้อมูลออเดอร์จาก ${mkpName}`}
          </XText>
        </HStack>
        <XText variant='inactive'>ช่วงเวลาที่ออเดอร์...</XText>
        <Box>
          <Segment selectedIndex={segmentOrderFromIndex} options={OPTIONS_FROM} onSegmentChange={onChangeSegmentIndexOrderFrom} />
        </Box>
        <XText variant='inactive'>ภายใน...</XText>
        <Box>
          <Segment selectedIndex={segmentIndex} options={OPTIONS_TIME_PERIOD} onSegmentChange={onChangeSegmentIndexFunction} />
        </Box>
        <Box h='2' />
        <Box justifyContent='center' w='full' h='24'>
          <XDateRangePicker
            // allowOnlyDaysFromToday={31}
            allowDateRangeLimitDays={14}
            selectedDates={{ begin: dateTime.startDate, end: dateTime.endDate }}
            onChangeDate={(newDates) => onDateChange(newDates)}
            showTime
          />
        </Box>
        <XText variant='primary' textAlign='center'>
          สามารถซิงค์สูงสุดครั้งละ 15 วัน
        </XText>
        <HStack mt='2' mr='2'>
          <TouchableOpacity style={{ width: 30, height: 40 }} onPress={() => updateForceSync(!isForceSync)}>
            <XIcon name={!isForceSync ? 'square-o' : 'check-square-o'} family='FontAwesome' />
          </TouchableOpacity>
          <XText variant='inactive'>{`สร้างออเดอร์ย้อนหลัง\n(ที่สร้างก่อนเชื่อมต่อกับ XSelly เมื่อ ${channel.skip_creating_order_created_before})`}</XText>
        </HStack>
        {isForceSync ? (
          <XText
            color={
              COLORS.RED
            }>{`หมายเหตุ: จากการที่ติ๊กเลือก ในระหว่างการซิงค์ออเดอร์นี้ หากพบออเดอร์ที่สร้างบนช่องทางขายก่อนเวลาที่เชื่อมช่องทางขายนี้กับ XSelly ระบบจะสร้างออเดอร์นี้ใน XSelly ให้ โดยสต๊อกสินค้าจะไม่ถูกตัดตามไปด้วย\n\nการติ๊กเลือกนี้ใช้เมื่อคุณต้องการดูยอดขายและกำไรย้อนหลังสำหรับออเดอร์ที่ถูกสร้างบนช่องทางขาย*ก่อน*ทำการเชื่อมต่อกับ XSelly`}</XText>
        ) : null}
        <XButton mt='2' onPress={() => submitBtn()}>
          ดำเนินการซิงค์
        </XButton>
      </Box>
    </XOverlay>
  )

  // Main render
  return (
    <>
      {/* {_renderControlButton()} */}
      {_renderOverlay()}
    </>
  )
}

export default OverlaySetupSyncOrder
