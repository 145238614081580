import React from 'react'
import _ from 'lodash'
// import EntypoIcon from 'react-native-vector-icons/Entypo'

// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'
import * as xFmt from 'x/utils/formatter'
// import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
// import SRowItem from '../../components/renderRowLeftAndRight'
import BasePaymentSubscriptionPackageView from 'x/modules/subscription/BasePaymentSubscriptionPackageView'
import { COLORS } from 'x/config/styles'
import XCard from 'xui/components/XCard'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import XButton from 'xui/components/XButton'
import { SubscriptionPackageListItems } from 'x/index'
import { TouchableOpacity } from 'react-native'
import XIcon from 'xui/components/XIcon'
import XFaqModal from 'xui/components/XFaqModal'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'
import HelpButton from 'xui/components/HelpButton'
import CONS from 'x/config/constants'
import dayjs from 'dayjs'
import XAddressCard from 'xui/components/XAddressCard'

const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

export default abstract class BaseUIPaymentSubscriptionPackageView extends BasePaymentSubscriptionPackageView {
  _renderHeader = () => {
    const title = 'ชำระเงิน'
    return (
      <XCustomHeader
        title={title}
        headerLeftProps={{ backIcon: true, onPressItem: () => this.goBack() }}
        // renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  // _onSegmentSelectedMonthChange = (newIndex: number) => {
  //   this.setState({
  //     selectedMonthIndex: newIndex,
  //   })
  // }

  _renderAddonView = (item: SubscriptionPackageListItems, title: string, key: string) => {
    const dataJson = item.data_json
    if (_.isNil(dataJson)) {
      return null
    }
    const { subscriptionPackageList } = this.state
    const count = _.has(dataJson, key) ? dataJson[key] : 0
    // console.log('dataJson => ', dataJson)
    // console.log('count => ', count)
    const addOnList = this._getAddOnTskusByKey(item, key)
    // console.log('addOnList /0/ => ', addOnList)
    // const selectedCount = _.has(item, 'ui_selected_count') ? item.ui_selected_count : 0
    const countAddOnCount = this._getAddOnSumTotalCount(addOnList)
    const selectedBillingCycle = this._getBillingCycle(item, key)
    // const selectedBillingCycle = 1
    const futureBillingDates = subscriptionPackageList.future_billing_dates
    const startBill = dayjs(futureBillingDates[0].start).format(CONS.SERVER_DATE_FORMAT)
    const endBill = dayjs(futureBillingDates[selectedBillingCycle - 1].end).format(CONS.SERVER_DATE_FORMAT)
    const selectedAddOnCount = countAddOnCount + count
    return (
      <VStack w='full' borderBottomWidth='1' borderBottomColor={COLORS.TEXT_INACTIVE} py='2'>
        <HStack w='full'>
          <HStack flex={2} alignItems='center'>
            <XText>{title}</XText>
            <XFaqModal
              key='วิธีเชื่อมช่องทางขาย'
              headerTitle='TEST'
              // @ts-ignore
              FAQ={FAQ_CONSTANTS.FAQ_CONNECT_MKP_CHANNELS}
              initiateOpenIndex={[0, 1]}
              renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
            />
          </HStack>
          {countAddOnCount > 0 ? (
            <HStack flex={1} justifyContent='flex-end' alignItems='center'>
              <XText fontSize='lg' bold>
                {xFmt.formatCurrency(1252)}
              </XText>
            </HStack>
          ) : null}
        </HStack>
        <HStack w='full' alignItems='center'>
          <VStack flex={2}>
            <HStack alignItems='center' />
            <HStack pr='2' alignItems='center'>
              <XText mr='1' minW='6'>
                {count.toLocaleString()}
              </XText>
              {!(addOnList.length > 0) ? null : (
                <TouchableOpacity disabled={!(addOnList.length > 0)} onPress={() => this._navToAddOnPackageView(addOnList, key)}>
                  <XIcon
                    mx='2'
                    name='pluscircle'
                    family='AntDesign'
                    opacity={addOnList.length > 0 ? 1 : 0.3}
                    color={addOnList.length > 0 ? COLORS.APP_SECONDARY : COLORS.TEXT_INACTIVE}
                  />
                </TouchableOpacity>
              )}
              {countAddOnCount > 0 ? (
                <XText mx='1' minW='6' textAlign='right' color={COLORS.GREEN}>
                  {countAddOnCount.toLocaleString()}
                </XText>
              ) : null}
              {countAddOnCount > 0 ? <XText mx='2'>=</XText> : null}
              {countAddOnCount > 0 ? (
                <XText bold mx='1' color={COLORS.GREEN}>
                  {selectedAddOnCount.toLocaleString()}
                </XText>
              ) : null}
            </HStack>
          </VStack>
        </HStack>
        {countAddOnCount > 0 ? (
          <HStack w='full' mt='2'>
            <VStack flex={1}>
              <XText variant='inactive'>{`เพิ่ม ${countAddOnCount.toLocaleString()} ออเดอร์ต่อเดือน จำนวน ${selectedBillingCycle} รอบบิล`}</XText>
              <XText variant='inactive'>{`ตั้งแต่วันที่ (${startBill} ถึง ${endBill})`}</XText>
            </VStack>
          </HStack>
        ) : null}
      </VStack>
    )
  }

  _renderAddOnPackage = () => {
    const { selectedPackages } = this.state
    if (_.isNil(selectedPackages)) {
      return null
    }
    return (
      <XCard mt='2' p='2'>
        <XText bold>รายละเอียดแพ็กเกจ</XText>
        {this._renderAddonView(selectedPackages, 'ออเดอร์ต่อเดือน', 'm_o_count')}
        {this._renderAddonView(selectedPackages, 'ช่องทางขาย', 'mkp_quota')}
        {this._renderAddonView(selectedPackages, 'กลุ่มสมาชิก', 'ug_quota')}
        {this._renderAddonView(selectedPackages, 'รายการราคา', 'pg_quota')}
        {this._renderAddonView(selectedPackages, 'ผู้ช่วยร้าน', 'helper_count')}
        {this._renderAddonView(selectedPackages, 'ร้าน', 'store_quota')}
        {this._renderAddonView(selectedPackages, 'ลำดับขั้นส่วนลดขายส่ง', 'vd_quota')}
        {this._renderAddonView(selectedPackages, 'คลัง', 'wh_quota')}
      </XCard>
    )
  }

  _renderTaxInvoiceView = () => {
    const { selectedPackages, useTaxInvoice, taxInvoiceAddress, senderAddress, useAddressSender } = this.state
    if (_.isNil(selectedPackages)) {
      return null
    }
    return (
      <XCard mt='2' p='2' w='full'>
        <VStack alignItems='center' justifyContent='center'>
          <XText fontSize='lg' bold>
            ใบกำกับภาษี
          </XText>
        </VStack>
        <VStack>
          <TouchableOpacity
            onPress={() => {
              if (useTaxInvoice) {
                this.setState({ useTaxInvoice: false, useAddressSender: false, taxInvoiceAddress: null, senderAddress: null })
              } else {
                this.setState({ useTaxInvoice: true })
              }
            }}>
            <HStack alignItems='center'>
              <XIcon family='FontAwesome' name={useTaxInvoice ? 'check-square-o' : 'square-o'} />
              <XText ml='2'>ต้องการใบกำกับภาษี</XText>
            </HStack>
          </TouchableOpacity>
          {useTaxInvoice ? (
            <VStack mt='2'>
              <XText variant='inactive'>เลือกสภาพบุคคล</XText>
              <HStack w='full'>
                <TouchableOpacity style={{ flex: 1, justifyContent: 'center' }}>
                  <HStack alignItems='center'>
                    <XIcon name={true ? 'radio-button-on' : 'radio-button-off'} />
                    <XText ml='2' variant='inactive'>
                      นิติบุคคล
                    </XText>
                  </HStack>
                </TouchableOpacity>
                <TouchableOpacity style={{ flex: 1, justifyContent: 'center' }}>
                  <HStack alignItems='center'>
                    <XIcon name={false ? 'radio-button-on' : 'radio-button-off'} />
                    <XText ml='2' variant='inactive'>
                      บุคคลธรรมดา
                    </XText>
                  </HStack>
                </TouchableOpacity>
              </HStack>
            </VStack>
          ) : null}
          {useTaxInvoice && _.isNil(taxInvoiceAddress) ? (
            <XButton mt='2' onPress={() => this._navToPickInvoiceAddress()}>
              ระบุข้อมูลสำหรับใบกำกับภาษี
            </XButton>
          ) : null}

          {!_.isNil(taxInvoiceAddress) ? (
            <VStack mt='2' w='full'>
              <HStack w='full'>
                <HStack flex={1} alignItems='flex-end'>
                  <XText variant='inactive'>ที่อยู่สำหรับระบุในเอกสาร</XText>
                </HStack>
                <XButton>
                  <XText>แก้ไข</XText>
                </XButton>
              </HStack>
              <XAddressCard address={taxInvoiceAddress} isCanEdit={false} />
            </VStack>
          ) : null}

          {!_.isNil(taxInvoiceAddress) ? (
            <VStack mt='2'>
              <HStack w='full'>
                <HStack flex={1} alignItems='flex-end'>
                  <XText variant='inactive'>ที่อยู่สำหรับการจัดส่งเอกสาร</XText>
                </HStack>
                <XButton>
                  <XText>แก้ไข</XText>
                </XButton>
              </HStack>
              <TouchableOpacity
                onPress={() => {
                  if (useAddressSender) {
                    this.setState({ useAddressSender: !useAddressSender, senderAddress: null })
                  } else {
                    this.setState({ useAddressSender: !useAddressSender, senderAddress: taxInvoiceAddress })
                  }
                }}>
                <HStack alignItems='center'>
                  <XIcon family='FontAwesome' name={useAddressSender ? 'check-square-o' : 'square-o'} />
                  <XText ml='2'>ใช้ที่อยู่จัดส่งเดียวกับที่อยู่ใบกำกับภาษี</XText>
                </HStack>
              </TouchableOpacity>
            </VStack>
          ) : null}
          {!useAddressSender && useTaxInvoice && !_.isNil(taxInvoiceAddress) && _.isNil(senderAddress) ? (
            <XButton mt='2' onPress={() => this._navToPickSenderAddress()}>
              ระบุข้อมูลสำหรับจัดส่งใบกำกับภาษี
            </XButton>
          ) : null}
          {!_.isNil(senderAddress) ? <XAddressCard address={senderAddress} isCanEdit={false} /> : null}
        </VStack>
      </XCard>
    )
  }

  _renderPayment = () => {
    const { selectedPackages } = this.state
    if (_.isNil(selectedPackages)) {
      return null
    }
    return (
      <XCard mt='2' p='2'>
        <XText fontSize='lg' bold alignSelf='center'>
          ชำระ
        </XText>
        <HStack w='full'>
          <HStack flex={1}>
            <XText variant='inactive'>ยอด</XText>
          </HStack>
          <HStack flex={1} justifyContent='flex-end'>
            <XText>฿1,335.00</XText>
          </HStack>
        </HStack>
        <HStack w='full'>
          <HStack flex={1}>
            <XText variant='inactive'>VAT</XText>
          </HStack>
          <HStack flex={1} justifyContent='flex-end'>
            <XText>฿235.25</XText>
          </HStack>
        </HStack>
        <HStack w='full' borderBottomColor={COLORS.TEXT_INACTIVE} borderBottomWidth='1' my='2' />
        <HStack w='full'>
          <HStack flex={1}>
            <XText variant='inactive' bold fontSize='lg'>
              รวมยอดชำระ
            </XText>
          </HStack>
          <HStack flex={1} justifyContent='flex-end'>
            <XText bold fontSize='lg'>
              ฿1,570.25
            </XText>
          </HStack>
        </HStack>
        <HStack w='full' borderBottomColor={COLORS.TEXT_INACTIVE} borderBottomWidth='1' my='2' />

        <XText bold fontSize='lg' alignSelf='center'>
          โอนเข้าบัญชีธนาคารและแนบสลิป
        </XText>
      </XCard>
    )
  }

  _renderSelectedMainPackage = () => {
    const { selectedPackages } = this.state
    if (_.isNil(selectedPackages)) {
      return null
    }
    const { name, price_before_vat } = selectedPackages
    return (
      <XCard p='2' mt={_.isNil(selectedPackages) ? '0' : '2'}>
        <XText fontSize='lg' bold alignSelf='center'>
          แพ็กเกจที่เลือก
        </XText>
        <HStack w='full'>
          <VStack flex={2}>
            <XText fontSize='lg'>{name}</XText>
            <XText variant='inactive'>(10/3/2021 - 10/5/2021)</XText>
          </VStack>
          <HStack flex={1} justifyContent='flex-end'>
            <XText fontSize='lg' bold>
              {xFmt.formatCurrency(price_before_vat)}
            </XText>
          </HStack>
        </HStack>
        <XButton variant='outline' mt='2' w='full' onPress={() => this._navToSelectMainPackageView('PER_PACKAGE')}>
          <XText>เปลี่ยนแพ็กเกจ</XText>
        </XButton>
      </XCard>
    )
  }

  _renderSelectedChannelPackage = () => {
    const { selectedPackages } = this.state
    if (!_.isNil(selectedPackages)) {
      return null
    }
    return (
      <XCard p='2'>
        <VStack w='full' space='2'>
          <XButton onPress={() => this._navToSelectMainPackageView('NEW_PACKAGE')}>เปิดแพ็กใหม่</XButton>
          <XButton onPress={() => this._navToSelectMainPackageView('PER_PACKAGE')}>ต่อแพ็กเกจ</XButton>
          <XButton onPress={() => this._navToSelectMainPackageView('UPGRADE_PACKAGE')}>อัพเกรดแพ็กเกจ</XButton>
        </VStack>
      </XCard>
    )
  }

  _renderFooter = () => {
    const { selectedPackages } = this.state
    if (_.isNil(selectedPackages)) {
      return null
    }
    return <XButton mb='1'>ชำระเงิน</XButton>
  }

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent p='1' style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}>
          {this._renderSelectedChannelPackage()}
          {this._renderSelectedMainPackage()}
          {this._renderAddOnPackage()}
          {this._renderTaxInvoiceView()}
          {this._renderPayment()}
        </XContent>
        {this._renderFooter()}
      </XContainer>
    )
  }
}
