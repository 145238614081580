import React, { useState } from 'react'

import * as util from 'x/utils/util'

import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XInput from 'xui/components/XInput'
import api from 'x/utils/api'
import p from 'x/config/platform-specific'
import { TouchableOpacity, Linking, Clipboard } from 'react-native'
import XFaqModal from 'xui/components/XFaqModal'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'
import XButton from 'xui/components/XButton'
import { COLORS } from 'x/config/styles'

// interface IReauthShopifyViewProps {
//   store_id: number
//   mkp_ch_id: number
// }
// onPress={() => Linking.openURL('https://marketplace.lazada.co.th/web/detail.html?articleCode=FW_GOODS-1000011969')}

// SHOPIFY_REAUTH_LINK สำหรับ public Shopify
export const SHOPIFY_REAUTH_LINK = () => {
  if (p.op.isWeb()) {
    return (
      <VStack>
        <XText bold>ขั้นตอนการรับ Shopify Auth Code</XText>
        <XText>{`1. กดปุ่มด้านล่างเพื่อเปิดหน้าเว็บ XSelly บน Shopify App Store
2. กดปุ่ม "Install"
3. เลือก Shopify Account ของร้านที่ต้องการเชื่อมต่อ
4. กดปุ่ม "Install"
5. กดปุ่ม "คัดลอกโค้ด" แล้วนำโค้ดมาวางในช่องด้านล่าง`}</XText>
        <HStack alignItems='center' justifyContent='center' my='2'>
          <XButton onPress={() => Linking.openURL('https://apps.shopify.com/')} h='7' p='0' variant='outline'>
            <XText color={COLORS.APP_MAIN} fontSize='xs' p='1'>
              เปิดหน้าเว็บ บน Shopify App Store
            </XText>
          </XButton>
        </HStack>
      </VStack>
    )
  }
  return (
    <VStack>
      <XText bold>ขั้นตอนการรับ Shopify Auth Code</XText>
      <XText>{`1. กดปุ่มด้านล่างเพื่อเปิดหน้าเว็บ XSelly บน Shopify App Store
2. กดปุ่ม "Install"
3. เลือก Shopify Account ของร้านที่ต้องการเชื่อมต่อ
4. กดปุ่ม "Install"
5. กดปุ่ม "คัดลอกโค้ด" แล้วนำโค้ดมาวางในช่องด้านล่าง`}</XText>
      <HStack alignItems='center' justifyContent='center' my='2'>
        <XButton
          onPress={() => {
            p.op.showToast('คัดลอกลิงก์เรียบร้อยแล้ว', 'success')
            Clipboard.setString('https://apps.shopify.com/')
          }}
          h='7'
          p='0'
          variant='outline'>
          <XText color={COLORS.APP_MAIN} fontSize='xs' p='1'>
            คัดลอกลิงก์
          </XText>
        </XButton>
      </HStack>
    </VStack>
  )
}

const ReauthShopifyView = (props) => {
  // const { webMaxScreenWidth, setPrefTimeslot, selectedStore, updateNewChannelList } = props
  const store_id = util.getNavParam(props, 'store_id')
  const mkp_ch_id = util.getNavParam(props, 'mkp_ch_id')
  const callBackFromLazadaReauth = util.getNavParam(props, 'callBackFromLazadaReauth')

  const [apiKey, updateChannel] = useState('')
  const [isEdit, setEdit] = useState(false)

  const [shopifyApiAccessToken, updateShopifyApiAccessToken] = useState('')
  const [shopifyApiKey, updateShopifyApiKey] = useState('')
  const [shopifyApiSecret, updateShopifyApiSecretl] = useState('')

  const navBack = () => {
    util.navGoBack(props)
  }

  const _cancelBtn = () => {
    p.op.showConfirmation(
      '',
      'ออกจากหน้านี้ใช่หรือไม่',
      () => navBack(),
      () => null,
      'ออกจากหน้านี้',
      'อยู่ในหน้านี้'
    )
  }

  const _saveBtn = async () => {
    // const pattern = /^[a-zA-Z0-9_]+$/
    // const isValid = pattern.test(apiKey)
    // if (!isValid || apiKey.length < 20) {
    //   p.op.showConfirmationOkOnly('API Key ไม่ถูกต้อง', 'กรุณาตรวจสอบ Lazada Auth Code แล้วลองใหม่อีกครั้ง', () => null, 'ปิด')
    //   return
    // }
    if (shopifyApiAccessToken.length < 1 || shopifyApiKey.length < 1 || shopifyApiSecret.length < 1) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุข้อมูลให้ครบถ้วน')
      return
    }

    const apiOptions = {
      showSpinner: true,
    }
    const body = {
      store_id,
      mkp_ch_id,
      access_token: shopifyApiAccessToken,
      api_key: shopifyApiKey,
      api_secret: shopifyApiSecret,
    }
    // return
    try {
      // const res = await api.postV2('mkp/v2/lazada/auth/reauth', body, apiOptions)
      const res = await api.postV2('mkp/v2/shopify/auth/update', body, apiOptions)
      // console.log('res channel => ', res)
      if (res.channel) {
        callBackFromLazadaReauth(res.channel)
        p.op.showToast('เชื่อมต่อเรียบร้อยแล้ว', 'success')
        navBack()
      }
    } catch (error) {
      console.log('error => ', error)
    }
  }

  const leftBtn = isEdit ? { label: 'ยกเลิก', onPressItem: () => _cancelBtn() } : { backIcon: true, onPressItem: () => navBack() }
  const rightBtn = isEdit ? { label: 'บันทึก', onPressItem: () => _saveBtn() } : null

  const _renderTextFAQ = (onPressOpenOverlay: () => void) => (
    <TouchableOpacity style={{ paddingLeft: 4 }} onPress={() => onPressOpenOverlay()}>
      <XText color='blue.800' textDecorationLine='underline'>
        ดูคลิปวิธีเชื่อมต่อ Shopify
      </XText>
    </TouchableOpacity>
  )

  const _renderInputShopifyApiAccessToken = () => {
    // const { mkp_id, shopifyApiAccessToken } = this.state
    // use only shopify
    // if (_.isNil(mkp_id) || mkp_id !== 5) {
    //   return null
    // }

    const title = 'Admin API Access Token'
    const placeHolder = 'ระบุ Admin API Access token'
    // if (isShopify) {
    //   title = 'กรุณาระบุข้อมูลสำหรับการเชื่อมต่อ Shopify'
    //   placeHolder = 'ระบุ Admin API Access token'
    // }

    return (
      <VStack pb='2'>
        <XText variant='inactive' pb='1'>
          {title}
        </XText>
        <HStack w='full'>
          <XInput
            value={shopifyApiAccessToken}
            // multiline
            style={{ flex: 1 }}
            placeholder={placeHolder}
            onChangeText={(text) => {
              updateShopifyApiAccessToken(text)
              setEdit(true)
            }}
          />
        </HStack>
      </VStack>
    )
  }

  const _renderInputShopifyApiKey = () => {
    // const { mkp_id, shopifyApiKey } = this.state
    // // use only shopify
    // if (_.isNil(mkp_id) || mkp_id !== 5) {
    //   return null
    // }
    const title = 'API Key'
    const placeHolder = 'ระบุ API Key'
    return (
      <VStack pb='2'>
        <XText variant='inactive' pb='1'>
          {title}
        </XText>
        <HStack w='full'>
          <XInput
            value={shopifyApiKey}
            // multiline
            style={{ flex: 1 }}
            placeholder={placeHolder}
            onChangeText={(text) => {
              setEdit(true)
              updateShopifyApiKey(text)
            }}
          />
        </HStack>
      </VStack>
    )
  }

  const _renderInputShopifyApiSecret = () => {
    // const { mkp_id, shopifyApiSecret } = this.state
    // // use only shopify
    // if (_.isNil(mkp_id) || mkp_id !== 5) {
    //   return null
    // }
    const title = 'API Secret'
    const placeHolder = 'ระบุ API Secret'
    return (
      <VStack pb='2'>
        <XText variant='inactive' pb='1'>
          {title}
        </XText>
        <HStack w='full'>
          <XInput
            value={shopifyApiSecret}
            // multiline
            style={{ flex: 1 }}
            placeholder={placeHolder}
            onChangeText={(text) => {
              setEdit(true)
              updateShopifyApiSecretl(text)
            }}
          />
        </HStack>
      </VStack>
    )
  }

  return (
    <XContainer>
      <XCustomHeader
        // headerStyle={{ marginTop: 0 }}
        // headerLeftProps={{
        //   backIcon: true,
        //   onPressItem: navBack,
        // }}
        headerLeftProps={leftBtn}
        headerRightProps={rightBtn}
        title='เชื่อมต่อ Shopify'
      />
      <XContent p='2'>
        <XCard p='2' w='full'>
          <VStack pb='2'>
            <VStack w='full'>
              <HStack w='full' alignItems={'cener'} justifyContent={'center'} pb='2'>
                <XText variant='active'>กรุณาระบุข้อมูลสำหรับการเชื่อมต่อ Shopify</XText>
              </HStack>
              {_renderInputShopifyApiAccessToken()}
              {_renderInputShopifyApiKey()}
              {_renderInputShopifyApiSecret()}
            </VStack>
          </VStack>
        </XCard>
      </XContent>
    </XContainer>
  )
}

// // @ts-ignore disable react-navigation header
// TimeSlotsView.navigationOptions = {
//   header: null,
// }

export default ReauthShopifyView
