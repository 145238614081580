import React, { Component } from 'react'
import { Text } from 'native-base'
import { View } from 'react-native'

import { log, logRender, setStatePromise, isUsernameValidFormat } from 'x/utils/util'

import { COLORS, STYLES } from 'x/config/styles'
import BackIcon from '../../components/BackIcon'
import { fromJS, List, Map } from 'immutable'
import p from 'x/config/platform-specific'
import FastImage from 'react-native-fast-image'

import { ResellerAddUserTabProps, ResellerAddUserTabState, UserGroupMap } from 'x/index'

import XInput from 'xui/components/XInput'
import _ from 'lodash'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'

const INTL_STYLES: { [key: string]: any } = {
  tabContainer: {
    borderBottomWidth: 0.5,
    borderBottomColor: COLORS.TEXT_INACTIVE,
  },
  tabHeader: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    height: 40,
  },
  gridTabHeader: {
    paddingVertical: 4,
    paddingHorizontal: 12,
  },
  // colCenter: {
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // },
  ugThumbnail: {
    height: 32,
    width: 32,
    borderRadius: 16,
  },
  txtNormalBold: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
    fontWeight: 'bold',
  },
  txtNormal: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
  },
  txtNormalLight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
  },
}

export default class ResellerAddUserTab extends Component<ResellerAddUserTabProps, ResellerAddUserTabState> {
  static displayName = 'ResellerAddUserTab'
  _inProcess: boolean

  constructor(props: ResellerAddUserTabProps) {
    super(props)
    this.state = {
      username: '',
      submitting: false,
    }

    this._inProcess = false
  }

  _handleSubmitAddReseller = async (): Promise<void> => {
    if (this._inProcess) {
      return
    }
    this._inProcess = true

    const { submitting, username } = this.state
    const { selectedStoreId, selectedUserGroupId, addNewUser } = this.props
    let usernameLowerCase = username.toLocaleLowerCase()
    usernameLowerCase = usernameLowerCase.trim()
    if (!selectedStoreId) {
      p.op.alert('ไม่พบร้านค้่าที่ถูกเลือก', 'กรุณากลับไปเลือกร้านค้าใหม่อีกครั้ง')
      this._inProcess = false
      return
    }

    if (!selectedUserGroupId) {
      p.op.alert('ไม่พบรายการราคาที่ถูกเลือก', 'กรุณากลับไปเลือกรายการราคาใหม่อีกครั้ง')
      this._inProcess = false
      return
    }

    if (!usernameLowerCase || !isUsernameValidFormat(usernameLowerCase)) {
      p.op.alert('ข้อมูลไม่ครบถ้วน', 'กรุณาระบุชื่อบัญชีผู้ใช้ของตัวแทนในรูปแบบที่ถูกต้อง (ภาษาอังกฤษพิมพ์เล็ก 4-20 ตัวอักษร)')
      this._inProcess = false
      return
    }

    await setStatePromise(this, { submitting: true })
    await new Promise((beginDelay) => setTimeout(beginDelay, 200))
    this.setState({ username: usernameLowerCase }, () => {})
    let body = {
      store_id: selectedStoreId,
      ug_id: selectedUserGroupId,
      username: usernameLowerCase,
      role: 'RESELLER',
    }

    await new Promise((resovleFetch) => {
      addNewUser({ body, successCallback: resovleFetch, failedCallback: resovleFetch })
    })

    await new Promise((endDelay) => setTimeout(endDelay, 200))
    await setStatePromise(this, { submitting: false })
    this._inProcess = false
  }

  _handleOnChangeUsername = (newText: string): void => {
    this.setState({ username: newText })
    // this.setState({ username: newText.toLocaleLowerCase() }) // FIX เพิ่มตัวแทนเข้าร้าน โดย ARTID
  }

  _renderInputUsername = () => {
    const { username, submitting } = this.state
    return (
      <XInput
        disabled={submitting}
        value={username}
        autoCorrect={false}
        autoFocus={false}
        onChangeText={this._handleOnChangeUsername}
        placeholder={'ระบุชื่อผู้ใช้ของตัวแทน (username)'}
      />
    )
  }

  _renderBtnAddResellerToUG = () => {
    const { username, submitting } = this.state
    const disabled = submitting || !_.isString(username) || username.length === 0
    // log('_renderBtnAddResellerToUG disabled => ', JSON.stringify(disabled))
    return (
      <XButton flex={1} onPress={this._handleSubmitAddReseller} disabled={disabled}>
        <XText>{'เพิ่มตัวแทนเข้ากลุ่ม'}</XText>
      </XButton>
    )
  }

  render() {
    logRender(this)
    // const { selectedStoreId, selectedUserGroupId } = this.props
    return (
      <View
        style={{
          flex: 1,
          paddingVertical: 8,
          paddingHorizontal: 12,
          // backgroundColor: 'red',
        }}>
        <View style={{ flex: 1 }}>
          <View>
            <XText allowFontScaling={false} variant='inactive'>
              {'ชื่อผู้ใช้ของตัวแทน'}
            </XText>
          </View>
          <View style={{ height: 50 }}>{this._renderInputUsername()}</View>
        </View>
        <View
          style={{
            height: 44,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {this._renderBtnAddResellerToUG()}
        </View>
      </View>
    )
  }
}
