/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React from 'react'
import { IApiOptions, ISelectedStoreMap, IStoreWebsite, IXScreenProps } from 'x/index'
import * as NavActions from 'x/utils/navigation'
import api from 'x/utils/api'
import _ from 'lodash'
import * as util from 'x/utils/util'

interface IBaseStoreWebsiteListViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
}

interface IBaseStoreWebsiteListViewState {
  storeWebsiteList: IStoreWebsite[]
}

export default abstract class BaseStoreWebsiteListView extends React.Component<
  IBaseStoreWebsiteListViewProps,
  IBaseStoreWebsiteListViewState
> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>
  // mainPackageItems: any

  constructor(props) {
    super(props)
    this.state = {
      // salePageList: [salePageJson],
      storeWebsiteList: null,
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
    // @ts-ignore
    // eslint-disable-next-line react/no-unused-class-component-methods
    // this.mainPackageItems = xCONS.SUBSCRIPTION_MAIN_PACKAGE_ITEMS.map((item) => ({
    //   ...item,
    //   label: p.op.t(`Subscription.main.${item.key}`),
    // }))
  }

  async componentDidMount() {
    await this._fetchListWebSite()
    // console.log('subscription => ', this.props.subscription.toJS())
  }

  _fetchListWebSite = async () => {
    const { selectedStore } = this.props
    const store_id = selectedStore.get('id')

    const reqBody = {
      store_id,
      offset: 0,
      limit: 10,
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res = await api.postV2('store/website/list', reqBody, apiOptions)

    // console.log('res! => ', res)
    await util.setStatePromise(this, {
      storeWebsiteList: res.websites,
    })
  }

  _navToStoreWebsiteView = (storeWebsite: IStoreWebsite) => {
    const { navigation } = this.props
    // console.log('SalePage Nav => ', salePage)
    navigation.dispatch(
      NavActions.navToStoreWebsiteView({
        storeWebsite,
        callBackForSucceedDeleteStoreWebsite: () => this._fetchListWebSite(),
      })
    )
  }

  _callBackCreateSucceed = (website: IStoreWebsite) => {
    const { navigation } = this.props
    const { storeWebsiteList } = this.state
    const newStoreWebsiteList = _.isNil(storeWebsiteList) || storeWebsiteList.length === 0 ? [] : _.cloneDeep(storeWebsiteList)
    newStoreWebsiteList.push(website)
    if (!_.isNil(website)) {
      this.setState({
        storeWebsiteList: newStoreWebsiteList,
      })
      navigation.dispatch(
        NavActions.navToStoreWebsiteView({
          storeWebsite: website,
          callBackForSucceedDeleteStoreWebsite: () => this._fetchListWebSite(),
        })
      )
    }
  }

  _navToCreateStoreWebsiteView = () => {
    const { navigation } = this.props
    // console.log('SalePage Nav => ', salePage)
    navigation.dispatch(
      NavActions.navToCreateStoreWebsiteView({
        callBackCreateSucceed: (website: IStoreWebsite) => this._callBackCreateSucceed(website),
      })
    )
  }
}
