import React from 'react'
import _ from 'lodash'
import { TouchableOpacity, View } from 'react-native'
import Icon from 'xui/components/Icon'

import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { S, STYLES, COLORS } from 'x/config/styles'
import CONS from 'x/config/constants'

import { IApiOptions, IBarcodeScannerOutput, IOrder, IShippingOrderCardProps } from 'x/index'
import { bindActionCreators } from 'redux'
import * as OrderState from 'x/modules/order/OrderState'

import api from 'x/utils/api'
import { connect } from 'react-redux'
import CollapsableCard from '../CollapsableCard'
import BarcodeScannerWithSegment from '../BarcodeScannerWithSegment'
import XText from '../XText'

// import Sound from '../../utils/sound'

interface ISavedState {
  segmentBarcodeIndex?: number
}

const ShippingOrderCard: React.FC<IShippingOrderCardProps> = (props) => {
  // const [scannedOrderText, setScannedOrderText] = React.useState(null)
  const [orderDetail, setOrderDetail] = React.useState<IOrder>(null)
  const [isBarcodeRead, setIsBarcodeRead] = React.useState(false)
  const [scannedText, setScannedText] = React.useState(null)
  const [isExpanded, setIsExpanded] = React.useState(false)

  const isMkpShopee = orderDetail && orderDetail.mkp_id && orderDetail.mkp_id === 1
  const isMkpOrder = orderDetail && orderDetail.mkp_order_id && orderDetail.mkp_order_id !== ''

  const [isOrderNotFound, setIsOrderNotFound] = React.useState(false)
  const [isOrderCancelled, setIsOrderCancelled] = React.useState(false)

  const [segmentBarcodeIndex, setSegmentBarcodeIndex] = React.useState(0)

  React.useEffect(() => {
    _initialize()
  }, [])

  React.useEffect(() => {
    if (props.preloadOrder && !orderDetail) {
      const od = props.preloadOrder
      setIsOrderCancelled(computeIsOrderCancelled(od))
      setIsExpanded(false)
      setOrderDetail(od)
      setIsExpanded(true)
      props.onSuccess(od)
    }
  }, [props.preloadOrder])

  React.useEffect(() => {
    if (props.expanded !== isExpanded) {
      setIsExpanded(props.expanded)
    }
  }, [props.expanded])

  async function _initialize() {
    // Load saved state
    const savedState = (await util.loadDefaultState(CONS.STATE_SAVE_KEYS.SHIPPING_ORDER_CARD)) as ISavedState
    if (savedState && savedState.segmentBarcodeIndex) {
      setSegmentBarcodeIndex(savedState.segmentBarcodeIndex)
    }
  }

  async function searchStoreIdOfOrderId(searchOrderId: string) {
    const reqBody = {
      search_order_id: searchOrderId,
      store_ids: props.myStoreIds,
    }
    const apiOptions: IApiOptions = {
      axiosOptions: {
        retry: 0,
        timeout: 60000,
      },
      isApiV2: true,
      isErrorAlertDisabled: true,
      // isErrorAlertDisabled: true,
    }
    try {
      const res = await api.post('order/stores', reqBody, apiOptions)
      if (res.order_store) {
        const { store_id, order_id } = res.order_store
        return order_id.toString() === searchOrderId && store_id ? store_id : null
      }
      // console.log('ShippingOrderCard::searchOrder res => ', res)
    } catch (err) {
      // console.log('ShippingOrderCard::searchOrder err => ', err)
      // on fail
      // @ts-ignore
      if (_.has(err, ['error', 'error_code']) && err.error.error_code === 'SearchOrderIdNotFound') {
        // await p.op.alertPromise(
        //   'ไม่พบออเดอร์',
        //   `กรุณาตรวจสอบความถูกต้องและระบุเลขออเดอร์ใหม่อีกครั้ง เนื่องจาก  "${searchOrderId}" ไม่มีในระบบ`
        // )
        setIsExpanded(false)
        setIsOrderNotFound(true)
        await util.delay(200)
        setIsExpanded(true)
      }
      //  else {
      //   await handleGlobalApiError(err, apiOptions)
      // }
      await props.onFail(reqBody, err) // should be error from api
    }
    return null
  }

  async function searchOrderDetial(storeId: number, searchOrderId: string) {
    const reqBody = {
      store_id: storeId,
      search_order_id: searchOrderId,
      // order_id: parseInt(searchOrderId),
    }

    const res: any = await new Promise((resolve) => {
      props.fetchOrderDetail({
        body: reqBody,
        successCallback: resolve,
        failedCallback: resolve,
        apiOptions: {
          isErrorAlertDisabled: true,
        },
      })
    })

    // on success
    if (res && res.order) {
      // console.log('searchOrderDetial res => ', res)
      const od = res.order as IOrder
      setIsOrderCancelled(computeIsOrderCancelled(od))
      setIsExpanded(false)
      setOrderDetail(od)
      await util.delay(200)
      setIsExpanded(true)
      await props.onSuccess(od)
      return
    }
    //  else {
    //   // res should be error
    //   console.log('searchOrderDetial err => ', res)
    //   await handleGlobalApiError(res, {})
    // }

    // on fail
    setOrderDetail(null)
    await props.onFail(reqBody, res) // should be error from api
  }

  async function _onBarcodeScanned(barcode: IBarcodeScannerOutput) {
    if (barcode.data && !isBarcodeRead) {
      setIsBarcodeRead(true)
      const searchOrderId = barcode.data
      setScannedText(searchOrderId)
      setIsOrderNotFound(false)

      // let targetStoreId = props.selectedStoreId
      // let searchStoreId = await searchStoreIdOfOrderId(searchOrderId)
      // if (searchStoreId) {
      //   targetStoreId = searchStoreId
      // }

      if (props.scanEveryStores) {
        const targetStoreId = await searchStoreIdOfOrderId(searchOrderId)
        if (targetStoreId) {
          await searchOrderDetial(targetStoreId, searchOrderId)
        }
      } else {
        await searchOrderDetial(props.selectedStoreId, searchOrderId)
      }

      await util.delay(2000)
      setIsBarcodeRead(false)
    }
  }

  async function _onBarcodeSegmentChange(newIndex: number) {
    setSegmentBarcodeIndex(newIndex)

    // save segment state
    await util.saveDefaultState(CONS.STATE_SAVE_KEYS.SHIPPING_ORDER_CARD, { segmentBarcodeIndex: newIndex })
  }

  function renderBarcodeScanner() {
    if (orderDetail || !isExpanded) {
      return null
    }
    return (
      <View style={[S.WIDTH_FULL, S.COLUMN_CENTER_TOP, S.PADDING_8]}>
        {/* <View style={{ height: 200, width: 320 }}>
          <View style={{ backgroundColor: 'rgba(88,88,88,0.7)' }}>
              <XText >สแกนออเดอร์</XText>
            </View>
        </View> */}
        <BarcodeScannerWithSegment
          saveStateKey={CONS.STATE_SAVE_KEYS.BARCODE_SCANNER_SHIPPING_ORDER}
          disabled={isBarcodeRead}
          inputPlaceholder='ระบุเลขออเดอร์...'
          selectedIndex={segmentBarcodeIndex}
          onChangeSegment={_onBarcodeSegmentChange}
          // cameraContainerHeight={200}
          onBarcodeScanned={_onBarcodeScanned}
        />
      </View>
    )
  }

  function renderOrderDetail() {
    if (!orderDetail || !orderDetail.id) {
      return null
    }
    const od = orderDetail

    const orderStatusText = getOrderStatusText(od)
    const orderStatusTextStyle = getStatusTextStyle(od)

    return (
      <View style={[S.WIDTH_FULL, S.COLUMN_LEFT_TOP, S.PADDING_8]}>
        {/* <View style={[S.WIDTH_FULL, S.ROW_CENTER]}>
          <XText style={[S.TEXT_ACTIVE, S.TEXT_LARGER, S.TEXT_BOLD, S.TEXT_ALIGN_CENTER]}>{'รายละเอียดออเดอร์'}</XText>
        </View> */}
        {isMkpOrder && isMkpShopee ? (
          <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN]}>
            <XText variant='inactive'>เลขออเดอร์จาก XSelly</XText>
            <XText>{`${od.id}`}</XText>
          </View>
        ) : null}

        {isMkpOrder && isMkpShopee ? (
          <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN]}>
            <XText variant='inactive'>เลขออเดอร์จาก Shopee</XText>
            <XText>{od.mkp_order_id}</XText>
          </View>
        ) : null}
        <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN]}>
          <XText variant='inactive'>{p.op.t('Order.status')}</XText>
          <XText variant={getStatusTextVariant(od)} fontSize='md' bold>
            {orderStatusText}
          </XText>
        </View>
        <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN]}>
          <XText variant='inactive'>จากร้าน</XText>
          <XText>{od.my_store_name}</XText>
        </View>
        <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN]}>
          <XText variant='inactive'>{p.op.t('Order.createdAt')}</XText>
          <XText>{util.formatDateForDisplay(od.created_at)}</XText>
        </View>
        {od.ready_to_ship_date ? (
          <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN]}>
            <XText variant='inactive'>{p.op.t('Order.readyToShipAt')}</XText>
            <XText>{util.formatDateForDisplay(od.ready_to_ship_date)}</XText>
          </View>
        ) : null}
        {isOrderCancelled ? (
          <View style={[S.WIDTH_FULL, S.COLUMN_CENTER_TOP, S.MARGIN_TOP_8]}>
            <XText variant='danger' bold style={[S.TEXT_ALIGN_VERTICAL_CENTER, S.TEXT_ALIGN_CENTER]}>
              {'ไม่สามารถดำเนินการต่อได้\nเนื่องจากออเดอร์ถูกยกเลิกแล้ว'}
            </XText>
          </View>
        ) : null}
        {isOrderCancelled ? (
          <View style={[S.WIDTH_FULL, S.ROW_CENTER, S.MARGIN_TOP_8]}>
            <TouchableOpacity
              style={[
                S.BORDER,
                S.BORDERS_PRIMARY,
                S.BORDER_RADIUS_8,
                S.HEIGHT_34,
                S.BG_PRIMARY,
                S.ROW_CENTER,
                S.PADDING_VERTICAL_4,
                S.PADDING_HORIZONTAL_8,
              ]}
              onPress={props.onRequestReset}>
              <XText variant='activeDark' bold style={S.TEXT_ALIGN_CENTER}>
                สแกนออเดอร์ใหม่
              </XText>
            </TouchableOpacity>
          </View>
        ) : null}

        {/* <View style={[S.WIDTH_FULL, S.ROW_CENTER, S.MARGIN_TOP_8]}>
          <TouchableOpacity
            style={[
              S.BORDER,
              S.BORDERS_PRIMARY,
              S.BORDER_RADIUS_8,
              S.HEIGHT_34,
              S.BG_WHITE,
              S.ROW_CENTER,
              S.PADDING_VERTICAL_4,
              S.PADDING_HORIZONTAL_8,
            ]}
            onPress={props.onRequestGoToOrderDetail}>
            <XText style={[S.TEXT_PRIMARY, S.TEXT_LARGER, S.TEXT_BOLD, S.TEXT_ALIGN_CENTER]}>{'ดูรายละเอียดออเดอร์'}</XText>
          </TouchableOpacity>
        </View> */}
      </View>
    )
  }

  function renderOrderNotFound() {
    return (
      <View style={[S.WIDTH_FULL, S.COLUMN_LEFT_TOP, S.PADDING_8, S.PADDING_VERTICAL_12]}>
        <View style={[S.WIDTH_FULL, S.ROW_CENTER]}>
          <XText bold textAlign='center'>
            ไม่พบออเดอร์เลขที่นี้
          </XText>
        </View>
        <View style={[S.WIDTH_FULL, S.ROW_CENTER, S.MARGIN_TOP_8]}>
          <XText variant='primary' fontSize='md' bold textAlign='center'>
            {scannedText}
          </XText>
        </View>
        <View style={[S.WIDTH_FULL, S.ROW_CENTER, S.MARGIN_TOP_8]}>
          <XText variant='inactive' textAlign='center'>
            (ท่านอาจจะไม่มีสิทธิในการดูออเดอร์)
          </XText>
        </View>
        <View style={[S.WIDTH_FULL, S.ROW_CENTER, S.MARGIN_TOP_12]}>
          <TouchableOpacity
            style={[
              S.BORDER,
              S.BORDERS_PRIMARY,
              S.BORDER_RADIUS_8,
              S.HEIGHT_34,
              S.BG_WHITE,
              S.ROW_CENTER,
              S.PADDING_VERTICAL_4,
              S.PADDING_HORIZONTAL_8,
            ]}
            onPress={props.onRequestReset}>
            <XText variant='primary' fontSize='md' bold textAlign='center'>
              เริ่มค้นหาออเดอร์ใหม่
            </XText>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  function renderContent() {
    if (isOrderNotFound) {
      return renderOrderNotFound()
    }

    if (orderDetail) {
      return renderOrderDetail()
    }

    return renderBarcodeScanner()
  }

  function renderHeaderRightInfo() {
    if (!orderDetail) {
      return null
    }
    return (
      <View style={[S.ROW_MIDDLE_END, { minWidth: 120 }]}>
        {props.preloadOrder ? (
          <XText variant='success' bold fontSize='md'>
            {/* {isMkpOrder && isMkpShopee ? orderDetail.mkp_order_id : `#${orderDetail.id}`} */}
            {orderDetail.id}
          </XText>
        ) : (
          <TouchableOpacity
            style={[
              S.BORDER,
              S.BORDERS_INACTIVE,
              S.BORDER_RADIUS_8,
              S.MIN_HEIGHT_28,
              S.BG_WHITE,
              S.ROW_MIDDLE_BETWEEN,
              S.PADDING_VERTICAL_4,
              S.PADDING_HORIZONTAL_4,
            ]}
            onPress={props.onRequestGoToOrderDetail}>
            <XText variant='primary' bold style={[S.TEXT_ALIGN_CENTER, { marginLeft: 4 }]}>
              {orderDetail.id}
            </XText>
            <Icon
              name='arrow-forward'
              type='Ionicons'
              style={{
                marginLeft: 4,
                fontSize: STYLES.FONT_ICON_SMALLER,
                color: COLORS.TEXT_INACTIVE,
                textAlignVertical: 'center',
                textAlign: 'right',
              }}
            />
          </TouchableOpacity>
        )}
      </View>
    )
  }

  return (
    <CollapsableCard
      disabledAnimation
      disabled={props.disabled}
      expanded={isExpanded}
      onPressToggle={props.onPressToggle}
      renderContent={renderContent}>
      <View
        style={[
          S.HEIGHT_44,
          S.WIDTH_FULL,
          S.ROW_MIDDLE_BETWEEN,
          S.PADDING_8,
          orderDetail ? S.BG_SUCCESS : S.BG_SECONDARY,
          isOrderNotFound || isOrderCancelled ? S.BG_DANGER : {},
        ]}>
        <View style={[S.FLEX, S.ROW_MIDDLE_START]}>
          <XText variant={props.disabled ? 'active' : 'inactive'} bold>
            {orderDetail ? '1. ออเดอร์' : '1. ค้นหาออเดอร์'}
          </XText>
        </View>
        {renderHeaderRightInfo()}
      </View>
      {/* <View>
        <TouchableOpacity onPress={() => _onBarcodeScanned({ data: '1851028' })}>
          <XText>DEBUG:: Scan 1851028</XText>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => _onBarcodeScanned({ data: '1850572' })}>
          <XText>DEBUG:: Scan 1850572 (XShipping)</XText>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => _onBarcodeScanned({ data: '635199' })}>
          <XText>DEBUG:: Scan 635199 (XShipping)</XText>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => _onBarcodeScanned({ data: '1850286' })}>
          <XText>DEBUG:: Scan 1850286 (XShipping)</XText>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => _onBarcodeScanned({ data: '1851027' })}>
          <XText>DEBUG:: Scan 1851027 (MKP Shopee)</XText>
        </TouchableOpacity>
      </View> */}
    </CollapsableCard>
  )
}

// util functions
function getOrderStatusText(order: IOrder): string {
  const { type, state, shipping_state } = order

  // Simulate the payment_state to 119 if the flag `ship_before_pay` is set
  const payment_state = doesOrderWaitsToBeShippedThenPaid(order) ? 119 : order.payment_state

  // console.log("type: " + type + " state: " + state + " payment_state: " + payment_state + " shipping_state: " + shipping_state)
  let translatedKey = 'Order.OrderStatesText.'
  if (type && state && payment_state && shipping_state) {
    if (state < 109) {
      translatedKey += `_${type}${state}`
    } else if (state >= 121 && state <= 189) {
      translatedKey += `_${type}${state}`
    } else if (payment_state < 119) {
      translatedKey += `_${type}${payment_state}`
    } else if (shipping_state <= 129) {
      translatedKey += `_${type}${shipping_state}`
    } else {
      translatedKey += '_0000'
    }
  }
  // return '_0000'
  return p.op.t(translatedKey)
}

function doesOrderWaitsToBeShippedThenPaid(order: IOrder) {
  const shipBeforePay = order.ship_before_pay
  const { payment_state } = order
  const { shipping_state } = order
  if (!_.isNil(shipBeforePay) && shipBeforePay && payment_state === 111 && shipping_state < 129) {
    return true
  }
  return false
}

function computeIsOrderCancelled(order: IOrder) {
  return order && _.isInteger(order.state) && order.state >= 180
}

function getStatusTextStyle(order: IOrder) {
  const { state, payment_state, shipping_state } = order
  const isCancelState = computeIsOrderCancelled(order)

  if (isCancelState) {
    return S.TEXT_DANGER
  }
  if (state === 109 && payment_state === 119 && shipping_state === 129) {
    return S.TEXT_SUCCESS
  }
  return S.TEXT_PRIMARY
}

function getStatusTextVariant(order: IOrder) {
  const { state, payment_state, shipping_state } = order
  const isCancelState = computeIsOrderCancelled(order)

  if (isCancelState) {
    return 'danger'
  }
  if (state === 109 && payment_state === 119 && shipping_state === 129) {
    return 'success'
  }
  return 'primary'
}

ShippingOrderCard.displayName = 'ShippingOrderCard'
// ShippingOrderCard.defaultProps = {}

const ShippingOrderCardWithRedux = connect(
  (state) => ({
    //
  }),
  (dispatch) => ({
    dispatch,
    fetchOrderDetail: bindActionCreators(OrderState.fetchOrderDetail, dispatch),
  })
)(ShippingOrderCard)

export default ShippingOrderCardWithRedux
