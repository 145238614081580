import React from 'react'
import _ from 'lodash'
// import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import BaseResellerManageView from 'x/modules/reseller/BaseResellerManageView'
import XCustomHeader from 'xui/components/XCustomHeader'
import { COLORS } from 'x/config/styles'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XMenuListView from 'xui/components/XMenuListView'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XImage from 'xui/components/XImage'
import XText from 'xui/components/XText'

export default abstract class BaseUIResellerManageView extends BaseResellerManageView {
  renderCustomHeader = () => {
    const leftBtn = {
      backIcon: true,
      onPressItem: () => util.navGoBack(this.props),
    }
    return <XCustomHeader title='สมาชิก' headerLeftProps={leftBtn} />
  }

  _renderResellerInfo = () => {
    const { reseller, userGroup } = this.state
    if (_.isNil(reseller)) {
      return null
    }
    const userGroupName = userGroup.name ? userGroup.name : ''
    return (
      <VStack w='full' bgColor={COLORS.FORM_PRIMARY_BG}>
        <HStack p='2'>
          <XImage
            // resizeMode='cover'
            style={{
              borderRadius: 90,
              width: 50,
              height: 50,
              justifyContent: 'center',
            }}
            source={{ uri: reseller.t }}
          />
          <VStack ml='8'>
            <XText>{reseller.d}</XText>
            {userGroupName === '' ? (
              <XText>{userGroupName}</XText>
            ) : (
              <HStack>
                <XText variant='inactive'>กลุ่มสมาชิก:</XText>
                <XText ml='2'>{userGroupName}</XText>
              </HStack>
            )}
          </VStack>
        </HStack>
      </VStack>
    )
  }

  render() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent
          w='full'
          onLayout={(event) => {
            const { x, y, width, height } = event.nativeEvent.layout
            util.setStatePromise(this, { loadingWidth: width, loadingHeight: height })
          }}>
          {this._renderResellerInfo()}
          <VStack w='full' bgColor='white'>
            <XMenuListView menuItems={this.MENU_ITRMS} />
          </VStack>
        </XContent>
      </XContainer>
    )
  }
}
