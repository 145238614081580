/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react'

// import BaseUIOrderView from 'xui/modules/order/BaseUIOrderView'
import { IAddress, IBaseOrderViewProps, IBaseOrderViewState, PrintOptionKey } from 'x/index'
import { log, delay, submitPrintingDocuments } from 'x/utils/util'
// import ImgManager from '../../components/ImgManager'

import p from 'x/config/platform-specific'
import _ from 'lodash'
// import * as xFmt from 'x/utils/formatter'
// import * as xAcl from 'x/utils/acl'
import xCONS from 'x/config/constants'
import { COLORS, STYLES } from 'x/config/styles'

import BaseUIOrderView from 'xui/modules/order/BaseUIOrderView'
// import { isIphoneX, getStatusBarHeight } from 'xui/utils/iphone-x-helper'
import * as xUtil from 'x/utils/util'
import * as xNavActions from 'x/utils/navigation'
import { Dimensions } from 'react-native'
import * as NavActions from '../../utils/navigation'
import { downloadFileFromUrl } from '../../utils/util-web'
// import ScrollableTabView, { ScrollableTabBar } from 'react-native-scrollable-tab-view'
import ThemeSizeContext from '../../context/ThemeSizeContext'

const {
  CARD_COMMON,
  FONT_SIZE_LARGER,
  FONT_SIZE_NORMAL,
  FONT_SIZE_SMALLER,
  FONT_SIZE_SMALLEST,
  FONT_ICON_NORMAL,
  FONT_ICON_SMALLER,
  FONT_ICON_SMALLEST,
  FONT_ICON_LARGEST,
  NO_MARGIN_PADDING,
  NO_MARGIN,
  NUMBER_PADDING_RIGHT_PLUS_1,
  COLOR_UNCOMMON,
  COLOR_COMMON,
  COLOR_DONE,
  BTN_BASE,
  BTN_MODE_ACTIVE_PRIMARY,
  BTN_TEXT_MODE_ACTIVE_PRIMARY,
  NUMBER_PADDING_RIGHT,
  FOOTER_BG,
  TXT_NORMAL,
  TXT_LABEL,
  TXT_LABEL_BOLD,
  TXT_NORMAL_BOLD,
  TXT_SMALLER_BOLD,
  TXT_LABEL_SMALLER,
  TXT_LABEL_LARGER,
} = STYLES

const {
  APP_MAIN,
  TEXT_INACTIVE,
  FORM_ERROR,
  TEXT_ACTIVE,
  TEXT_PRIMARY,
  FORM_INACTIVE,
  FORM_PRIMARY_BG,
  FORM_SUCCESS,
  FORM_SUCCESS_BG,
  BG_LIGHT_GREY,
  BG_LIGHT_GREY_ALTERNATIVE,
  TEXT_ACTIVE_DARK,
} = COLORS

const { ORDER_VIEW_MODE, PERM_STORE_HELPER, STORE_SETTINGS } = xCONS
const { CREATE, VIEW_EDIT, VIEW_ONLY, EDIT } = ORDER_VIEW_MODE
const { COMPUTED_USE_PRODUCT_UPC, COMPUTED_USE_PRODUCT_SKU } = STORE_SETTINGS

// @ts-ignore
// class OrderView extends BaseOrderView {
class OrderView extends BaseUIOrderView {
  static displayName = 'OrderView'

  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  testNodeRef?: any

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }

  constructor(props) {
    super(props)
    this.testNodeRef = React.createRef()
  }

  //  // Abstract method
  // _getParams() {
  // // @ts-ignore
  //   const { navigation } = this.props
  //   const { state } = navigation
  //   if (state && state.params) {
  //     return state.params
  //   }
  //   return {}
  // }

  // async beginSubmitting(): Promise<void> {
  // }

  // async endSubmitting(): Promise<void> {
  // }

  // _handleDataIsDirty() {
  // }

  // _onPressBackWhileDirtyData = () => {
  // }

  // async _componentDidUpdateExtended(prevProps: IBaseOrderViewProps, prevState: IBaseOrderViewState): Promise<void> {

  // }

  async componentDidMount(): Promise<void> {
    await super.componentDidMount.apply(this)
    if (this.state.mode === CREATE) {
      this.props.navigation.setParams({
        onRequestCloseView: this._handleOnRequestCloseStackExpandModal,
      })

      await delay(200)

      try {
        // FIXME: Logic นี้ถ้าเกิดทำ Responsive Sidebar จะต้องกลับมาดูใหม่อีกรอบ
        const browserWidth = Dimensions.get('window').width
        const expectedTwoColumnsWidth =
          xCONS.SPECIFIC_PLATFORM.WEB.SIDEBAR_WIDTH + xCONS.SPECIFIC_PLATFORM.WEB.MAIN_CONTENT_ONE_COLUMN_WIDTH * 2
        const hasMoreThanOneColumns = browserWidth >= expectedTwoColumnsWidth
        // console.log('handlePrepareToAddProductsToCart browserWidth => ', browserWidth)
        // console.log('handlePrepareToAddProductsToCart expectedTwoColumnsWidth => ', expectedTwoColumnsWidth)
        // console.log('handlePrepareToAddProductsToCart hasMoreThanOneColumns => ', hasMoreThanOneColumns)

        if (!hasMoreThanOneColumns) {
          // ถ้า width ไม่เอื้อ > 1 column ทำการ return ออกจาก method นี้
          return
        }
      } catch (error) {
        return
      }
      await this.handlePrepareToAddProductsToCart()
    }
  }

  async componentDidUpdate(prevProps: IBaseOrderViewProps, prevState: IBaseOrderViewState): Promise<void> {
    await super.componentDidUpdate.apply(this, [prevProps, prevState])
    if (prevState.mode !== this.state.mode && this.state.mode === CREATE) {
      this.props.navigation.setParams({
        onRequestCloseView: this._handleOnRequestCloseStackExpandModal,
      })
    }
  }

  _settingOrderView() {
    p.op.alert('เร็วๆ นี้')
  }

  _handleOnRequestCloseStackExpandModal = async () => {
    const isEditing = this._isEditingOrder()
    const { mode } = this.state
    if (isEditing || this.props.isDirty || mode === CREATE) {
      const isUserConfirm = await p.op.isUserConfirm(
        'ยังไม่ได้บันทึกการแก้ไขออเดอร์',
        'คุณต้องการละทิ้งการแก้ไขหรือไม่',
        'ละทิ้งการแก้ไข',
        'ปิด'
      )
      return isUserConfirm
    }
    return true
  }

  // _cleanupForUnmount() {
  // }
  // _handleErrMissingReceiverAddressRequresTel(name: string): void {
  //   p.op.alert(`กรุณาระบุเบอร์โทรศัพท์ของผู้รับ ${name}`)
  //   console.log('No-Impl::_handleErrMissingReceiverAddressRequresTel')
  // }

  _handleErrMissingReceiverAddressRequresTel = (name: string) => {
    const { rAddr } = this.props
    let err = ''
    const name0 = !!_.isNil(rAddr.get('name'))
    const address = !!(_.isNil(rAddr.get('address1')) && _.isNil(rAddr.get('address2')) && _.isNil(rAddr.get('address3')))
    const postalCode = !!_.isNil(rAddr.get('postal_code'))
    const phon = !!_.isNil(rAddr.get('telephone'))
    if (name0) {
      err = 'ชื่อ'
    } else if (address) {
      err = 'ที่อยู่'
    } else if (postalCode) {
      err = 'รหัสไปรษณีย์'
    } else if (phon) {
      err = 'เบอร์โทร'
    }
    p.op.showConfirmation(
      `กรุณาระบุ ${err} ผู้รับสินค้า`,
      'รูปแบบขนส่งที่คุณเลือก ต้องการชื่อ ที่อยู่และเบอร์โทรศัพท์ หากคุณต้องการระบุภายหลังในกด "ระบุภายหลัง"',
      () => this._createOrder(true),
      () => {
        const { customCreateOrder } = this.state
        // const receiver = customCreateOrder.receiver
        const { onChangeAddress } = this.props
        onChangeAddress({
          receiver: {
            name: _.isNil(name) ? '' : name,
          },
        })
        const newCustomCreateOrder = customCreateOrder
        const newReceiver = {} as any
        newReceiver.v = customCreateOrder.receiver.v
        newReceiver.t = 2
        newReceiver.a = 0
        newCustomCreateOrder.receiver = newReceiver
        xUtil.setStatePromise(this, {
          customCreateOrder: newCustomCreateOrder,
          txtReceiverName: _.isNil(name) ? rAddr.get('name') || '' : name,
        })
        this._renderReceiverActionSheet()
      },
      'ระบุภายหลัง',
      'ระบุตอนนี้'
    )
  }

  _handleErrMissingShippingType() {
    p.op.alert('กรุณาเลือกรูปแบบจัดส่ง')
    // console.log('No-Impl::_handleErrMissingShippingType')
  }

  _handleErrMissingReceiverAddress() {
    p.op.alert('กรุณาระบุที่อยู่ผู้รับสินค้า')
    // console.log('No-Impl::_handleErrMissingReceiverAddress')
  }

  // _handleErrNotEnoughQty(productName, storeId, productIdx, availableQty) {
  // }

  // _handleErrHasNullProductQty(productName, storeId, productIdx, availableQty) {
  // }

  // _handleErrProductQtyIsZero(productName, productIdx, availableQty) {
  // }

  // _handleErrMissingProductId() {
  // }

  // _handleErrMissingPostalCode(quickAddress) {
  //   const { navigation } = this.props
  //   const { mode } = this.state
  //   // const selectedStoreID = navigation.state.params.store_id || null
  //   const selectedStoreID = xUtil.getNavParam(this.props, 'store_id')
  //   const thisOrder = this._getFocusedOrder()
  //   const selectedShippingTypeIndex = xCONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.findIndex(
  //     (shippingTypeId) => shippingTypeId.value === thisOrder.toJS().shipping_type_id
  //   )
  //   p.op.alert('กรุณาระบุรหัสไปรษณีย์ผู้รับ', null, () => {
  //     this.setState({ pickingKey: 'receiver' }, () => {
  //       navigation.dispatch(
  //         NavActions.navToOrderQuickAddress({
  //           initAddress: quickAddress,
  //           mode,
  //           store_id: selectedStoreID,
  //           shippingTypeIndex: selectedShippingTypeIndex,
  //           type: 'RECEIVER',
  //         })
  //       )
  //     })
  //   })
  // }

  async _handleOnOpenShippingLabelFromUrl(url: string, fileName: string): Promise<void> {
    await downloadFileFromUrl({ url, fileName })
  }

  // _settingCreateOrder() {
  //   p.op.alert('เร็วๆ นี้')
  //   // console.log('No-Impl::_settingCreateOrder')
  // }

  async _onOpenPaperangFromUrl(url: string, fileNameDotPdf: string): Promise<void> {
    await downloadFileFromUrl({ url, fileName: fileNameDotPdf.replace('.pdf', '.png') })
  }

  // _handleErrNewQtyIsMoreThanOpenOrder(productName: string, subIndex: number, pIndex: number) {
  // }

  // _handleErrCannotEditWhenHasShippingProduct(productName: string, subIndex: number, pIndex: number) {
  // }

  // _handleErrCannotConfirmWhenProductQtyIsZero(productName: string, subIndex: number, pIndex: number) {
  // }

  async _handleMissingTelReceiver(): Promise<void> {
    p.op.alert('กรุณาระบุเบอร์โทรศัพท์ของผู้รับ')
    // console.log('No-Impl::_handleMissingTelReceiver')
  }

  async _handleMissingTelSender(): Promise<void> {
    p.op.alert('กรุณาระบุเบอร์โทรศัพท์ของผู้ส่ง')
    // console.log('No-Impl::_handleMissingTelSender')
  }

  // navToAddProductListView = async (
  //   navParams: { store_id: number; ug_id?: number; pg_ids?: number[]; seller_store_id?: number },
  //   isMineProducts: boolean
  // ) => {
  //   this.inProcess = true

  //   const generateNavAction = isMineProducts
  //     ? NavActions.navToProductListPullMyProductToOrder
  //     : // : NavActions.navToProductListPulSellerProductToOrder
  //       NavActions.navToProductListPullSellerProductToOrder
  //   // @ts-ignore navToProductListPullMyProductToOrder or navToProductListPullSellerProductToOrder why error ????
  //   const navAction = generateNavAction(navParams)
  //   this.props.navigation.dispatch(navAction)

  //   await 500
  //   this.inProcess = false
  // }

  // navToQuickAddressView = async (params: {
  //   initAddress: IQuickAddress
  //   mode: string
  //   store_id: number
  //   shippingTypeIndex: number
  //   type: 'SENDER' | 'RECEIVER'
  // }) => {
  //   // p.op.alert('เร็วๆ นี้')
  //   // console.log('no-impl navToQuickAddressView')
  //   const thisOrder = this._getFocusedOrder()
  //   let callNewXShipping = false
  //   if (params.type === 'RECEIVER' && thisOrder.has(`x`)) {
  //     callNewXShipping = await this._warningChangeReceiverAddressHasXShipping(
  //       'คุณเปลี่ยนที่อยู่',
  //       'ระบบจะทำการยกเลิกเลขติดตามพัสดุเดิม และขอใหม่ เพื่อให้ที่อยู่ผู้รับเปลี่ยนตามที่แก้ไขไว้ หากระบบขอใหม่ไม่สำเร็จ กรุณากดยกเลิกเลขติดตามพัสดุและขอใหม่เอง'
  //     )
  //     // @ts-ignore callBack type err
  //     params.callBack = () => this._afterNavToQuickAddressViewsuccessCallback()
  //     if (callNewXShipping) {
  //       this.props.navigation.dispatch(NavActions.navToOrderQuickAddress(params))
  //     }
  //   } else {
  //     this.props.navigation.dispatch(NavActions.navToOrderQuickAddress(params))
  //   }
  //   // this.props.navigation.dispatch(NavActions.navToOrderQuickAddress(params))
  // }

  // navToOrderPrivateAndPublicNotes = async (params: {
  //   store_id: number
  //   mode: string
  //   orderTabIndex: number
  //   key: 'PUBLIC' | 'PRIVATE'
  //   notes: any
  // }) => {
  //   p.op.alert('เร็วๆ นี้')
  //   console.log('no-impl navToOrderPrivateAndPublicNotes')
  // }

  // _renderNavOrderTypeOne = async () => {
  //   p.op.alert('เร็วๆ นี้')
  //   console.log('no-impl _renderNavOrderTypeOne')
  // }

  // _renderNavOrderTypeTwo = async () => {
  //   p.op.alert('เร็วๆ นี้')
  //   console.log('no-impl _renderNavOrderTypeTwo')
  // }

  _handleAfterSuccessFetchOrderDetail = async (): Promise<void> => {
    // Do nothing
  }

  // _renderVolumeDiscountSummary = (order: Map<string, any>): JSX.Element => {
  //   if (!Map.isMap(order)) {
  //     return null
  //   }
  //   console.log('FIXME: No-Impl:: _renderVolumeDiscountSummary')
  //   return null
  //   // return (
  //   //   <View>
  //   //       <Text>{'FIXME: No-Impl:: _renderVolumeDiscountSummary'}</Text>
  //   //   </View>
  //   // )
  // }

  // _renderButtonCameraUPCAndSKU = (isStoreUseSKU, isStoreUseUPC) => {
  //   const { optionSelectedSellerStoreIndex, optionChooseProductsFromIndex } = this.state
  //   const { sellerStores } = this.props
  //   const storeName = sellerStores.getIn([optionSelectedSellerStoreIndex, 'name'])
  //   return (
  //     <Row style={{ marginTop: 4, height: 40 }}>
  //       <TouchableOpacity
  //         style={INTL_STYLES.btnPrimaryActive}
  //         // onPress={this.onAddProducts}>
  //         onPress={this.handlePrepareToAddProductsToCart}>
  //         <Row>
  //           <VStack style={[INTL_STYLES.colMiddleCenter, { flex: 0, width: 28 }]} />
  //           <VStack size={100} style={INTL_STYLES.colMiddleCenter}>
  //             {optionChooseProductsFromIndex === 0 ? (
  //               <Text style={INTL_STYLES.txtBtnPrimaryActive} numberOfLines={1}>
  //                 {'เพิ่มสินค้าจากร้าน ' + storeName}
  //               </Text>
  //             ) : (
  //               <Text style={INTL_STYLES.txtBtnPrimaryActive}>{'เพิ่มสินค้า'}</Text>
  //             )}
  //           </VStack>
  //           <VStack style={[INTL_STYLES.colMiddleCenter, { flex: 0, width: 28 }]}>
  //             <ForwardIcon activeDark={true} style={{ flex: 0 }} />
  //           </VStack>
  //         </Row>
  //       </TouchableOpacity>
  //     </Row>
  //   )
  // }

  // _renderXModelCODFeeCalculater = (amountTotal: number) => {
  //   return (
  //     <View>
  //       <Text>{'FIXME: No-Impl:: _renderXModelCODFeeCalculater xxx'}</Text>
  //     </View>
  //   )
  // }

  _handleAfterSuccessConfirmOrder = async (res: any): Promise<void> => {
    // Do nothing
  }

  // async _handleAfterSuccessCreateOrder(res: Response): Promise<void> {
  // }

  // async _handleOnTabChanged(): Promise<void> {
  // }

  // _askForRateAfterOpenOrder = () => {
  // }

  // async _editProductFromBuyOrderFlow(): Promise<void> {

  // }

  // _onHamburgerWillShow = async (): Promise<void> => {
  //   // if (this.inProcess) {
  //   //   return
  //   // }
  //   // this.inProcess = true
  //   // await setStatePromise(this, { isHamburgerMenuOpened: true })
  //   // // if (this.hamburgerMenuRef && _.isFunction(this.hamburgerMenuRef.select)) {
  //   // //   this.hamburgerMenuRef.select(-1)
  //   // // }
  //   // this.inProcess = false
  // }

  // _onHamburgerWillHide = async (): Promise<void> => {
  //   // await setStatePromise(this, { isHamburgerMenuOpened: false })
  // }

  // _onHamburgerMenuSelect = async (index: number): Promise<void | boolean> => {
  //   // if (this.inSelectHamburger) {
  //   //   return
  //   // }
  //   // this.inSelectHamburger = true
  //   // // log('ModalDropdown:: onSelect index => ', index)
  //   // // log('_onHamburgerMenuSelect ModalDropdown:: onSelect index => ', index)
  //   // const selectedItem = this.state.hamburgerMenuOptions[index]
  //   // // log('ModalDropdown:: onSelect selectedItem => ', selectedItem)
  //   // if (selectedItem && _.isFunction(selectedItem.onPress)) {
  //   //   // MUST!! delay for finished render dropdown before call method
  //   //   await new Promise(delayOnPress => {
  //   //     setTimeout(delayOnPress, 200)
  //   //   })
  //   //   selectedItem.onPress()
  //   //   this._afterHamburgerMenuSelectedSafetyClose()
  //   // }
  //   // this.inSelectHamburger = false
  //   // await setStatePromise(this, { isHamburgerMenuOpened: false })
  //   // return false
  // }

  // _afterHamburgerMenuSelectedSafetyClose = () => {
  //   setTimeout(() => {
  //     if (this.state.isHamburgerMenuOpened) {
  //       this.setState({ isHamburgerMenuOpened: false })
  //     }
  //   }, 200)
  // }

  // _renderModalDropdownRow = (data: { label: string; isDanger?: boolean; onPress: () => void }): JSX.Element => {
  //   return null
  //   // // log('ModalDropdown renderRow::data => ', data)
  //   // const isDanger = _.has(data, 'isDanger') ? data.isDanger : false
  //   // let textColor = TEXT_INACTIVE
  //   // if (isDanger) {
  //   //   textColor = FORM_ERROR
  //   // }
  //   // return (
  //   //   <TouchableOpacity>
  //   //     <Text
  //   //       allowFontScaling={false}
  //   //       style={{
  //   //         fontSize: FONT_SIZE_LARGER,
  //   //         color: textColor,
  //   //         paddingTop: 10,
  //   //         paddingBottom: 10,
  //   //         paddingLeft: 10,
  //   //         paddingRight: 10,
  //   //       }}>
  //   //       {data.label}
  //   //     </Text>
  //   //   </TouchableOpacity>
  //   // )
  // }

  // _renderHamburgerButton = (): JSX.Element | null => {
  //   return null
  //   // // const hamburgerMenuOptions = this._calculateHamburgerMenuOptions()
  //   // const { hamburgerMenuOptions, isInitialized, submitting, refreshing, mode } = this.state
  //   // const isDisabled = !isInitialized || submitting || refreshing
  //   // const order = this._getFocusedOrder()
  //   // const permission = acl.canDoAtSelectedStore(PERM_STORE_HELPER.ORDER_PRINT_SHIPPING)
  //   // const visible = this._canPrintShippingLabel(order)
  //   // const showPrinter = mode === VIEW_EDIT && permission && visible
  //   // const marginRightFromMode = showPrinter ? 25 : 5
  //   // // console.log(permission + ' => ' + visible + ' <=> ' + showPrinter)
  //   // // log('_renderHamburgerButton:: hamburgerMenuOptions => ', hamburgerMenuOptions)
  //   // return (
  //   //   <View style={{ flexDirection: 'row', marginRight: marginRightFromMode }}>
  //   //     {
  //   //       showPrinter ? <View style={{ flex: 0, marginRight: 5 }}>
  //   //         <TouchableOpacity onPress={() => this._selectedShippingLabel()}>
  //   //           <XIcon name='printer' family={ICON_FAMILY.AntDesign}
  //   //             style={{
  //   //             alignSelf: 'center',
  //   //             textAlign: 'center',
  //   //             color: isDisabled ? TEXT_INACTIVE : TEXT_PRIMARY,
  //   //             fontSize: 23,
  //   //             // minWidth: 80,
  //   //             // width: 50,
  //   //             // height: 50,
  //   //             flex: 0,
  //   //             paddingTop: 0,
  //   //             // จริงๆ 0 มันกลางแล้ว แต่ปรับขึ้นเป็น 2 เพราะสายตาคนมองเทียบกับอย่างอื่นแล้วมันจึงดูไม่กลาง
  //   //             paddingBottom: Platform.OS === 'android' ? 2 : 0,
  //   //             paddingLeft: 0,
  //   //             paddingRight: 0,
  //   //           }} />
  //   //         </TouchableOpacity>
  //   //       </View> : null
  //   //     }
  //   //     <View style={{ flex: 0 }}>
  //   //       <ModalDropdown
  //   //       disabled={isDisabled}
  //   //       ref={r => r && (this.hamburgerMenuRef = r)}
  //   //       style={{
  //   //         flex: 1,
  //   //         width: '100%',
  //   //         height: '100%',
  //   //         alignItems: 'center',
  //   //         justifyContent: 'center',
  //   //         flexDirection: 'row',
  //   //         display: hamburgerMenuOptions && hamburgerMenuOptions.length > 0 ? 'flex' : 'none',
  //   //       }}
  //   //       dropdownStyle={{
  //   //         width: 180,
  //   //         height: 40 * hamburgerMenuOptions.length,
  //   //       }}
  //   //       showsVerticalScrollIndicator={false}
  //   //       onDropdownWillShow={this._onHamburgerWillShow}
  //   //       onDropdownWillHide={this._onHamburgerWillHide}
  //   //       renderRow={this._renderModalDropdownRow}
  //   //       onSelect={this._onHamburgerMenuSelect}
  //   //       options={hamburgerMenuOptions}>
  //   //       <IconMC
  //   //         name='dots-vertical'
  //   //         style={{
  //   //           alignSelf: 'center',
  //   //           textAlign: 'center',
  //   //           color: isDisabled ? TEXT_INACTIVE : TEXT_PRIMARY,
  //   //           fontSize: FONT_ICON_LARGEST,
  //   //           minWidth: 54,
  //   //           flex: 0,
  //   //           paddingTop: 0,
  //   //           // จริงๆ 0 มันกลางแล้ว แต่ปรับขึ้นเป็น 2 เพราะสายตาคนมองเทียบกับอย่างอื่นแล้วมันจึงดูไม่กลาง
  //   //           paddingBottom: Platform.OS === 'android' ? 2 : 0,
  //   //           paddingLeft: 0,
  //   //           paddingRight: 5,
  //   //         }}
  //   //       />
  //   //   </ModalDropdown>
  //   //     </View>
  //   //   </View>

  //   // )
  // }

  _openLinkOrderToWebView(hash: any) {
    try {
      // @ts-ignore
      // Linking.openURL(hash, '_blank')
      window.open(hash, '_blank')
    } catch (error) {
      log(error)
    }
    this.inProcess = false
  }

  _pickAddress = (key: string) => {
    const { navigation, onChangeAddress, editReceiverAddress } = this.props
    const { store_id, mode } = this.state
    // p.op.alert(`No-Impl _pickAddress mode = ${mode} / key = ${key}`)
    // p.op.alert(`เร็วๆ นี้`)
    // console.log(` No-Impl _pickAddress mode = ${mode} / key = ${key} `)

    if (key === 'sender') {
      this.setState({ pickingKey: key }, async () => {
        if (mode === CREATE) {
          await this.popToCurrentView()
          const onPickSender = (address: IAddress) => {
            onChangeAddress({ sender: address })
            // const senderNane = this.state.txtSenderName
            // onEditingOrderChange({ key, value: address, orderTabIndex: this.state.orderTabIndex })
          }
          navigation.dispatch(
            xNavActions.navToAddressListStore({
              headerTitle: 'เลือกที่อยู่ร้านฉัน',
              onPressAddress: (address: IAddress) => onPickSender(address),
              store_id,
              useSellerAddress: true,
            })
          )
        } else if (mode === VIEW_EDIT) {
          await this.popToCurrentView()

          await this.popToCurrentView()
          const onPickSender = async (address: IAddress) => {
            const order = this._getFocusedOrder()
            const body = {
              store_id,
              order_id: order.get('id'),
              type: 'SENDER',
              new_address_id: address.id,
            }

            const callNewXShipping = await this._warningChangeReceiverAddressHasXShipping(
              'คุณเปลี่ยนที่อยู่',
              'ระบบจะทำการยกเลิกเลขติดตามพัสดุเดิม และขอใหม่ เพื่อให้ที่อยู่ผู้ส่งเปลี่ยนตามที่แก้ไขไว้ หากระบบขอใหม่ไม่สำเร็จ กรุณากดยกเลิกเลขติดตามพัสดุและขอใหม่เอง'
            )

            editReceiverAddress({ body, successCallback: async () => await this._changeReceiverAddressHasXShipping(callNewXShipping) })
            // const senderNane = this.state.txtSenderName
            // onEditingOrderChange({ key, value: address, orderTabIndex: this.state.orderTabIndex })
          }
          navigation.dispatch(
            xNavActions.navToAddressListStore({
              headerTitle: 'เลือกที่อยู่ร้านฉัน',
              onPressAddress: (address: IAddress) => onPickSender(address),
              store_id,
            })
          )

          // navigation.dispatch(NavActions.navToAddressListStorePickChange({ store_id }))

          // navigation.dispatch(NavActions.navToAddressListStorePickChange({ store_id }))
          this.setState({ orderTabsVisibility: false })
        }
      })
    } else if (key === 'receiver') {
      this.setState({ pickingKey: key }, () => {
        navigation.dispatch(
          NavActions.navToPickCustomerAddress({
            mode: xCONS.CONTACT_LIST_MODE_KEYS.PICK_ADDRESS,
            callBackSelectedAddress: (address) => this._succeedPickReceiverAddress(address),
          })
        )
      })
    } else if (key === 'billing_address') {
      this.setState({ pickingKey: key }, () => {
        navigation.dispatch(
          NavActions.navToPickCustomerAddress({
            mode: xCONS.CONTACT_LIST_MODE_KEYS.PICK_ADDRESS,
            callBackSelectedAddress: (address) => this._succeedPickBillingAddress(address),
          })
        )
      })
    } else {
      log('No match key.')
    }
  }

  onPressShippingTypePicker = () => {
    p.op.alert(`no-impl onPressShippingTypePicker`)
  }

  // navToPaymentView = (params) => {
  //   this.props.navigation.navigate('PaymentView', params)
  // }

  // navToPaymentListView = (params) => {
  //   this.props.navigation.navigate('PaymentListView', params)
  // }

  navToOrderShipList = async (params: { store_id: number; order_id: number }) => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { navigation } = this.props
    navigation.dispatch(NavActions.navToOrderShipList(params))
    await delay(500)
    this.inProcess = false
  }

  navToShipView = (params) => {
    // p.op.alert('เร็วๆ นี้')
    // console.log('no-impl navToShipView')
    this.props.navigation.dispatch(NavActions.navToShipView(params))
  }

  getInjectScrollableTabViewContentPropsStyle() {
    const { orderTabsVisibility, mode } = this.state
    const footerHeight = 50
    let height = this.context.contentWithNavHeaderHeight
    const width = this.context.contentOneOfThreeColumnWidth
    if (!_.includes([EDIT], mode)) {
      height -= footerHeight
    }
    if (orderTabsVisibility) {
      height -= 50 // ScrollableTabBar height
    }
    return { height, width }
  }

  // End Abstract Method

  // _navToPaymentView = async () => {
  //   // @ts-ignore
  //   const { navigation, initOrdersToBePaid } = this.props
  //   console.log('_navToPaymentView navigation => ', navigation)
  //   const selectedStoreID = navigation.getParam('store_id', null)
  //   // const orderId = order.get('id')
  //   const orderId = navigation.getParam('order_id', null)
  //   // const orderType = order.get('type')
  //   const orderType = 3
  //   const myStoreId = selectedStoreID

  //   // const orderStoreId = order.get('store_id')
  //   const orderStoreId = 3
  //   // const partnerName = order.get('partner_name')
  //   const partnerName = 'partnerName'
  //   // const partnerStoreId = order.get('partner_store_id')
  //   const partnerStoreId = 7

  //   // const isShipBeforePay = order.get('ship_before_pay') || false
  //   const isShipBeforePay = false
  //   // const isCOD = order.get('is_cod') || false
  //   const isCOD = false

  //   // const remainingAmount = order.get('remaining_forecast_amount')
  //   const remainingAmount = 111222
  //   // const codAmount = order.get('cod_amount')
  //   const codAmount = null

  //   initOrdersToBePaid({ orderId, remaining_forecast_amount: remainingAmount, cod_amount: codAmount })
  //   await delay(10)
  //   console.log('navigation => ', navigation)
  //   navigation.navigate('PaymentView', {
  //       orderId,
  //       // orderType: order.get('type'),
  //       orderType: 3,
  //       myStoreId: selectedStoreID,
  //       orderStoreId: 3,
  //       partnerName: 'partnerName',
  //       partnerStoreId: 7,
  //       fromPaymentList: true,
  //       // isShipBeforePay,
  //       // isCOD,
  //   })
  // }

  // setDate = (newDate) => {
  //   this.setState({ chosenDate: newDate });
  // }

  uploadImage = async () => {
    // @ts-ignore
    const x = await this._onImgManagerRef.submitUploadImages()
    console.log('x => ', x)
  }

  // _selectedShippingLabel = async () => {
  //   const { selectedStore, navigation } = this.props
  //   // const usePaperang = selectedStore.has('s_use_printing_paperang') && selectedStore.get('s_use_printing_paperang') ? true : false
  //   // const usePDF = selectedStore.has('s_use_printing_pdf') && selectedStore.get('s_use_printing_pdf') ? true : false
  //   // const printingBillPaperang = selectedStore.has('s_use_printing_bill_paperang') && selectedStore.get('s_use_printing_bill_paperang') ? true : false
  //   const asOptions = ['ใบปะหน้าพัสดุ (PDF Size A4)', 'ใบเสร็จรับเงิน (Paperang)', 'ยกเลิก']
  //   p.op.showActionSheet(
  //     {
  //       options: asOptions,
  //       cancelButtonIndex: asOptions.length - 1,
  //       title: p.op.t('print_doc'),
  //     },
  //     buttonIndex => {
  //       const btnIdx = _.isString(buttonIndex) ? parseInt(buttonIndex) : buttonIndex
  //       // log('buttonIndex => ', buttonIndex.toString())
  //       switch (btnIdx) {
  //         case 0:
  //           this._openShippingLabelPDF()
  //           break
  //         case 1:
  //           this._openShippingLabelBill()
  //           break
  //       }
  //     }
  //   )
  // }

  // _renderHamburgerButton = (): JSX.Element | null => {
  //   // const hamburgerMenuOptions = this._calculateHamburgerMenuOptions()
  //   const { hamburgerMenuOptions, isInitialized, submitting, refreshing, mode } = this.state
  //   const isDisabled = !isInitialized || submitting || refreshing
  //   const order = this._getFocusedOrder()
  //   const type = order.get('type')
  //   // const permission = acl.canDoAtSelectedStore(PERM_STORE_HELPER.ORDER_PRINT_SHIPPING)
  //   // const showPrinter = mode === VIEW_EDIT && permission && this._canPrintShippingLabel(order)
  //   let showPrinter = type === 1 || type === 2
  //   // const marginRightFromMode = showPrinter ? 25 : 5
  //   // const showAllShipping = permission && this._canPrintShippingLabel(order)
  //   // log('_renderHamburgerButton:: hamburgerMenuOptions => ', hamburgerMenuOptions)
  //   if (!xUtil.canHelperPrintShippingLabel()) {
  //     showPrinter = false
  //   }

  //   return (
  //     // MUST specify height here as there is iOS bug that if no view has height (in this case the printer icon is hidden),
  //     // the ... button will DISAPPEARED
  //     // <View style={{ flexDirection: 'row', marginRight: marginRightFromMode, height: 36 }}>
  //     <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
  //       {/* <TouchableOpacity onPress={() => this._selectedShippingLabel()}> */}
  //       {/* FIXME: เป็น temporary layout เพราะว่าไม่ได้ design มาก่อนว่า header จะมีหลายปุ่ม */}
  //       {/* {showPrinter ? (
  //         <TouchableOpacity
  //           style={{
  //             flex: 1,
  //             position: 'absolute',
  //             width: 34,
  //             // height: 36,
  //             height: '100%',
  //             left: -44,
  //             alignItems: 'center',
  //             justifyContent: 'center',
  //           }}
  //           onPress={this._selectedShippingLabel}>
  //           <XIcon
  //             name='printer'
  //             family={'AntDesign'}
  //             style={{
  //               alignSelf: 'center',
  //               textAlign: 'center',
  //               color: isDisabled ? TEXT_INACTIVE : TEXT_PRIMARY,
  //               fontSize: 23,
  //               // minWidth: 80,
  //               // width: 50,
  //               // height: 50,
  //               flex: 0,
  //               paddingTop: 0,
  //               // จริงๆ 0 มันกลางแล้ว แต่ปรับขึ้นเป็น 2 เพราะสายตาคนมองเทียบกับอย่างอื่นแล้วมันจึงดูไม่กลาง
  //               paddingBottom: Platform.OS === 'android' ? 2 : 0,
  //               paddingLeft: 0,
  //               paddingRight: 0,
  //               // marginTop: Platform.OS === 'android' ? 5 : 4, // <<< PRINTER MARGIN
  //             }}
  //           />
  //         </TouchableOpacity>
  //       ) : null} */}
  //       {showPrinter ? (
  //         <PrintingOptionOverlayButton
  //           buttonStyle={{ marginLeft: -30, marginTop: -4 }}
  //           selectedStore={this.props.selectedStore}
  //           disabled={isDisabled}
  //           onSubmit={this.summitDownloadPrinting}
  //           // onSettings={this.navToStoreSettingAllPrintingShipping}
  //         />
  //       ) : null}
  //       <XDropdown
  //         disabled={isDisabled}
  //         ref={(r) => r && (this.hamburgerMenuRef = r)}
  //         // style={{
  //         //   // flex: 0,
  //         //   flex: 1,
  //         //   width: 34,
  //         //   // backgroundColor: 'red',
  //         //   height: '100%',
  //         //   alignItems: 'center',
  //         //   justifyContent: 'center',
  //         //   flexDirection: 'row',
  //         //   display: hamburgerMenuOptions && hamburgerMenuOptions.length > 0 ? 'flex' : 'none',
  //         // }}
  //         dropdownStyle={{
  //           width: 180,
  //           height: 40 * hamburgerMenuOptions.length,
  //         }}
  //         showsVerticalScrollIndicator={false}
  //         onDropdownWillShow={this._onHamburgerWillShow}
  //         onDropdownWillHide={this._onHamburgerWillHide}
  //         renderRow={this._renderModalDropdownRow}
  //         onSelect={this._onHamburgerMenuSelect}
  //         options={hamburgerMenuOptions}>
  //         {/* // @ts-ignore  */}
  //         <Icon
  //           type='MaterialCommunityIcons'
  //           name='dots-vertical'
  //           style={{
  //             alignSelf: 'center',
  //             textAlign: 'center',
  //             color: isDisabled ? TEXT_INACTIVE : TEXT_PRIMARY,
  //             fontSize: FONT_ICON_LARGEST,
  //             minWidth: 34,
  //             flex: 0,
  //             paddingTop: 0,
  //             // จริงๆ 0 มันกลางแล้ว แต่ปรับขึ้นเป็น 2 เพราะสายตาคนมองเทียบกับอย่างอื่นแล้วมันจึงดูไม่กลาง
  //             paddingBottom: Platform.OS === 'android' ? 2 : 0,
  //             paddingLeft: 0,
  //             paddingRight: 5,
  //           }}
  //         />
  //       </XDropdown>
  //     </View>
  //   )
  // }

  summitDownloadPrinting = async (key: PrintOptionKey) => {
    const { selectedStore } = this.props
    const order = this._getFocusedOrder()
    const store_id = selectedStore.get('id')
    const order_id = order.get('id')

    const hasPermissionToPrintDocumentsWithAlert = this.hasPermissionToPrintDocumentsWithAlert()
    if (!hasPermissionToPrintDocumentsWithAlert) {
      return
    }

    await submitPrintingDocuments({
      printKey: key,
      store_id,
      order_ids: [order_id],
      // handleOpenPaperang: p.op.isWeb() ? null : (url) => this._onOpenPaperangFromUrl(url, key),
    })
  }

  // _renderCustomHeader = () => {
  //   const { editingOrder } = this.props
  //   const { mode, orderTabsVisibility, submitting, isHamburgerMenuOpened, isInitialized } = this.state
  //   let title = null
  //   const headerLeftProps: INavHeaderButtonProps = {
  //     submitting: submitting || isHamburgerMenuOpened || !isInitialized,
  //   }
  //   const headerRightProps: INavHeaderButtonProps = {
  //     submitting: submitting || isHamburgerMenuOpened || !isInitialized,
  //   }
  //   let headerRightCustomRender = null
  //   const order = mode === CREATE ? editingOrder : this._getFocusedOrder()
  //   const type = order.get('type')
  //   const order_name = order.has('name') ? order.get('name') : 'ไม่พบชื่อออเดอร์'
  //   const typeText = type && type === 3 ? 'ซื้อ' : 'ขาย'
  //   // ORDER VIRE HEADER
  //   switch (mode) {
  //     case CREATE:
  //       title = p.op.t('Order.createOrder')
  //       headerLeftProps.backIcon = true
  //       headerLeftProps.onPressItem = this._goBack
  //       // headerRightProps.settingIcon = true
  //       // headerRightProps.onPressItem = this._settingCreateOrder
  //       headerRightCustomRender = this._renderHamburgerButton
  //       break
  //     case EDIT:
  //       title = p.op.t('Order.orderEditTitle', { typeText, num: order_name })
  //       headerLeftProps.label = p.op.t('done')
  //       headerLeftProps.onPressItem = this._handleConfirmEditOrder
  //       headerRightProps.label = p.op.t('cancel')
  //       headerRightProps.onPressItem = this._handleCancelConfirmEditOrder
  //       break
  //     case VIEW_EDIT:
  //       headerLeftProps.backIcon = true
  //       headerLeftProps.onPressItem = this._goBack
  //       title = order_name ? p.op.t('Order.orderNumCard', { typeText, num: order_name }) : ''
  //       headerRightCustomRender = this._renderHamburgerButton
  //       break
  //     case VIEW_ONLY:
  //       headerLeftProps.backIcon = true
  //       headerLeftProps.onPressItem = this._goBack
  //       title = order_name ? p.op.t('Order.orderNumCard', { typeText, num: order_name }) : ''
  //       headerRightCustomRender = this._renderHamburgerButton
  //       break

  //     default:
  //       title = ''
  //       headerLeftProps.backIcon = true
  //       headerLeftProps.onPressItem = this._goBack
  //   }

  //   return (
  //     <Stack
  //       style={{
  //         flex: 1,
  //         flexGrow: 0,
  //         flexShrink: 0,
  //         flexBasis: 'auto',
  //         width: '100%',
  //         height: xCONS.SPECIFIC_PLATFORM.WEB.HEADER_HEIGHT,
  //         backgroundColor: 'white',
  //       }}>
  //       <Row
  //         style={{
  //           flex: 1,
  //           flexGrow: 0,
  //           flexShrink: 0,
  //           flexBasis: 'auto',
  //           width: '100%',
  //           height: xCONS.SPECIFIC_PLATFORM.WEB.HEADER_HEIGHT,
  //         }}>
  //         <VStack style={[INTL_STYLES.colMiddleCenter, { flex: 1, width: 54 }]}>
  //           <NavHeaderButton {...headerLeftProps} />
  //         </VStack>
  //         <VStack size={100} style={INTL_STYLES.colMiddleCenter}>
  //           <Text style={INTL_STYLES.txtTitle}>{title}</Text>
  //         </VStack>
  //         <VStack style={[INTL_STYLES.colMiddleCenter, { flex: 1, width: 54 }]}>
  //           {_.isFunction(headerRightCustomRender) ? headerRightCustomRender() : <NavHeaderButton {...headerRightProps} />}
  //         </VStack>
  //       </Row>
  //     </Stack>
  //   )
  // }

  // _renderOrderSingleTab = ({ order, idx }) => {
  //   const { orderTabIndex } = this.state
  //   if (!order || !isImmutable(order)) {
  //     return <View key={`EmptyTab_${idx}`} />
  //   }

  //   const isEmptyOrder = order && order.get('updated_at') ? false : true

  //   let orderType
  //   if (isEmptyOrder) {
  //     orderType = idx === 0 ? 1 : 3 // ถ้าไม่มีข้อมูล ให้ assume ว่า idx 0 เป็นออเดอร์ขาย
  //   } else {
  //     orderType = order.get('type')
  //   }

  //   const txtOrderContext = orderType === 3 ? 'ซื้อ' : 'ขาย'
  //   const txtOrderName = order.get('name') ? `${txtOrderContext}#${order.get('name')}` : null
  //   const orderId = order.get('id') ? order.get('id') : 'none'

  //   if (!txtOrderName) {
  //     return <View key={`NoNameTab_${idx}`} />
  //   }

  //   // const isFocusedTab = orderTabIndex === idx
  //   return (
  //     // @ts-ignore
  //     <View
  //       key={`OrderTab_${idx}`}
  //       tabLabel={txtOrderName} // ต้องมี!! เพราะถ้าไม่มี จะไม่มี order name บน Tabs
  //       style={{
  //         flex: 1,
  //         // width: '100%',
  //         // height: '100%',
  //         backgroundColor: orderType === xCONS.ORDER_TYPE_3 ? COLORS.FORM_PRIMARY_BG : COLORS.BG_LIGHT_GREY,
  //       }}
  //       // heading={txtOrderName} // ถ้าใช้ View มันไม่มี heading
  //       >
  //       {isEmptyOrder ? (
  //         <View
  //           // Spinner
  //           style={{
  //             flex: 1,
  //             width: '100%',
  //             height: '100%',
  //             position: 'absolute',
  //             alignSelf: 'center',
  //             top: 0,
  //             bottom: 0,
  //             left: 0,
  //             right: 0,
  //             backgroundColor: 'rgba(0,0,0,0.3)',
  //           }}
  //           // color={ COLORS.APP_MAIN }
  //         />
  //       ) : null}
  //       {!isEmptyOrder ? this._renderOrderByMode(order) : null}
  //       {/* {!isEmptyOrder ? this._renderFooter(order) : null} */}
  //     </View>
  //   )
  // }

  // _renderTabWrapperByMode = () => {
  //   const { mode } = this.state
  //   const { editingOrder } = this.props

  //   // Bypass for force normal render CREATE and EDIT mode
  //   if (mode === CREATE) {
  //     return this._renderOrderByMode(editingOrder)
  //   }
  //   return this._renderOrderTabs()
  // }

  // _renderOrderByMode = order => {
  //   const { mode, isInitialized } = this.state
  //   if (!order || !Map.isMap(order) || !isInitialized) {
  //     return null
  //   }
  //   const isEditableInConfirmationMode = this._calculateEditableInConfirmationMode(order)
  //   const { isEditableQty, isEditableEtc } = isEditableInConfirmationMode

  //   if (mode !== CREATE && !order.get('id')) {
  //     return <Text style={{ flex: 1, marginTop: 50, textAlign: 'center', fontSize: FONT_SIZE_SMALLER }}>{'กำลังโหลดออเดอร์...'}</Text>
  //   }

  //   const contentProps = {
  //     style: [NO_MARGIN_PADDING, { display: this.state.isInitialized ? null : 'none' }],
  //     keyboardShouldPersistTaps: xCONS.KEYBOARD_PERSIST_TAB_MODE,
  //     // keyboardDismissMode='on-drag,
  //     ref: this._onOrderScrollViewRef,
  //     extraScrollHeight: 50,
  //     refreshControl: this._renderOrderRefreshControl(_.includes([VIEW_EDIT, VIEW_ONLY], mode)),
  //     onContentSizeChange: this._onOrderContentSizeChange,
  //     disableKBDismissScroll: false,
  //     enableResetScrollToCoords: false,
  //     // children: orderReactNode,
  //   }

  //   return (
  //     // @ts-ignore
  //     <XContent {...contentProps}>
  //       <Text>_renderOrderByMode</Text>
  //       <Text>order_id = {order.get('id')}</Text>
  //     </XContent>
  //   )
  //   // return (
  //   //   <XContent {...contentProps}>
  //   //     {_.includes([CREATE], mode) ? this._renderShipmentCard(order) : null}
  //   //     {!_.includes([CREATE], mode) ? this._renderOrderDetailCard(order) : null}
  //   //     {this._renderStoreProducts({
  //   //       order,
  //   //       isCreateMode: _.includes([CREATE], mode),
  //   //       isEditable: _.includes([CREATE, EDIT], mode) || (_.includes([VIEW_EDIT], mode) && isEditableQty),
  //   //       isEditableEtc: _.includes([VIEW_EDIT], mode) ? isEditableEtc : _.includes([CREATE, EDIT], mode),
  //   //     })}
  //   //     {/* การชำระ */}
  //   //     {/* {this._renderShipBeforePay()} */}
  //   //     {this._renderCoverSheet()}
  //   //     {this._renderOptionsCard(order)}
  //   //   </XContent>
  //   // )
  // }

  // _renderOrderTabs = () => {
  //   const { editingOrder } = this.props
  //   const { mode, orderTabIndex, orderTabsVisibility, submitting, refreshing, isInitialized } = this.state
  //   if (!editingOrder || !Map.isMap(editingOrder)) {
  //     return null
  //   }

  //   const parentOrder = editingOrder
  //   const subOrders = editingOrder.get('suborders') ? editingOrder.get('suborders') : List([])
  //   const isLocked = _.includes([CREATE, EDIT], mode) || !orderTabsVisibility || submitting || refreshing
  //   // log('_renderOrderTabs orderTabsVisibility => ', orderTabsVisibility)

  //   return (
  //     <ScrollableTabView
  //       contentProps={{
  //         style: {
  //           flex: 1,
  //           width: '100%',
  //           height: '100%',
  //         },
  //       }}
  //       ref={r => r && (this._orderTabsRef = r)}
  //       locked={isLocked}
  //       renderTabBar={this._renderCustomScrollableTabBar}
  //       initialPage={0}
  //       scrollWithoutAnimation={!orderTabsVisibility}
  //       prerenderingSiblingsNumber={10}
  //       onChangeTab={this._handleChangeTab}
  //       tabBarUnderlineStyle={{ height: 0 }}
  //       tabBarPosition={'top'}>
  //       {this._renderOrderSingleTab({ order: parentOrder, idx: 0 })}
  //       {subOrders.map((suborder, index) => this._renderOrderSingleTab({ order: suborder, idx: index + 1 }))}
  //     </ScrollableTabView>
  //   )
  // }

  // _renderCustomScrollableTabBar = e => {
  //   return (
  //     <ScrollableTabBar
  //       tabsContainerStyle={{
  //         justifyContent: 'flex-start',
  //         alignItems: 'center',
  //       }}
  //       style={{
  //         flexWrap: 'wrap',
  //         paddingTop: this.state.orderTabsVisibility ? 5 : 0,
  //         height: this.state.orderTabsVisibility ? 40 : 0,
  //         backgroundColor: COLORS.BG_LIGHT_GREY_ALTERNATIVE,
  //       }}
  //       renderTab={this._renderCustomHeaderScrollableChildTab}
  //     />
  //   )
  // }

  getWebOrderNoteMaxWidth = () => this.context.contentOneOfThreeColumnWidth - 30

  getWebTextOrderNodeMaxWidthMagicNumber = () => this.context.contentOneOfThreeColumnWidth - 140

  _testShowActionSheet = () => {
    // const elem = findNodeHandle(this.testNodeRef.current)
    // console.log('_testShowActionSheet elem => ', elem)
    // // @ts-ignore
    // var rect = elem.getBoundingClientRect()
    // console.log(rect.top, rect.right, rect.bottom, rect.left)

    p.op.showActionSheet(
      {
        title: 'test title dsdsfdsfdsfdssfsfds dsdsfdsfdsfdssfsfds dsdsfdsfdsfdssfsfds',
        options: ['Cancel', 'Remove'],
        destructiveButtonIndex: 1,
        cancelButtonIndex: 0,
        buttonRef: this.testNodeRef,
      },
      (buttonIndex) => {
        p.op.alert(`Yo button Index => ${buttonIndex}`)
        if (buttonIndex === 1) {
          /* destructive action */
        }
      }
    )
  }

  // _renderOrderTabs = () => {
  //   const { editingOrder } = this.props
  //   const { mode, orderTabIndex, orderTabsVisibility, submitting, refreshing, isInitialized } = this.state
  //   if (!editingOrder || !Map.isMap(editingOrder) || !isInitialized) {
  //     return null
  //   }
  //
  //   const parentOrder = editingOrder
  //   const subOrders = editingOrder.get('suborders') ? editingOrder.get('suborders') : List([])
  //   const isLocked = _.includes([CREATE, EDIT], mode) || !orderTabsVisibility || submitting || refreshing
  //   // log('_renderOrderTabs orderTabsVisibility => ', orderTabsVisibility)
  //   const parentOrderId = parentOrder.get('id')
  //
  //   return (
  //     <ScrollableTabView
  //       key={`ORDERS_${parentOrderId}_${isInitialized}_${mode}`}
  //       contentProps={{
  //         style: {
  //           flex: 1,
  //           width: '100%',
  //           // height: '100%',
  //           height: this.context.contentWithNavHeaderHeight,
  //           marginBottom: 70,
  //         },
  //       }}
  //       // ref={r => r && (this._orderTabsRef = r)}
  //       ref={this._orderTabsRef}
  //       locked={isLocked}
  //       renderTabBar={this._renderCustomScrollableTabBar}
  //       page={orderTabIndex}
  //       initialPage={orderTabIndex}
  //       scrollWithoutAnimation={!orderTabsVisibility}
  //       prerenderingSiblingsNumber={10}
  //       onChangeTab={this._handleChangeTab}
  //       tabBarUnderlineStyle={{ height: 0 }}
  //       tabBarPosition={'top'}>
  //       {this._renderOrderSingleTab(parentOrder, -1)}
  //       {subOrders.map(this._renderOrderSingleTab)}
  //     </ScrollableTabView>
  //   )
  // }

  // Override เพราะ web มันไม่สามารถแสดงผล Footer Tabs ได้อย่างถูกต้อง
  // _renderOrderSingleTab = (order, index) => {
  //   const idx = index + 1
  //   const { orderTabIndex, orderTabsVisibility } = this.state
  //   if (!order || !isImmutable(order)) {
  //     return <View key={`EmptyTab_${idx}`} />
  //   }
  //
  //   const isEmptyOrder = order && order.get('updated_at') ? false : true
  //
  //   let orderType
  //   if (isEmptyOrder) {
  //     orderType = idx === 0 ? 1 : 3 // ถ้าไม่มีข้อมูล ให้ assume ว่า idx 0 เป็นออเดอร์ขาย
  //   } else {
  //     orderType = order.get('type')
  //   }
  //
  //   const txtOrderContext = orderType === 3 ? 'ซื้อ' : 'ขาย'
  //   const txtOrderName = order.get('name') ? `${txtOrderContext}#${order.get('name')}` : null
  //   const orderId = order.get('id') ? order.get('id') : 'none'
  //
  //   if (!txtOrderName) {
  //     return <View key={`NoNameTab_${idx}`} />
  //   }
  //
  //   // const isFocusedTab = orderTabIndex === idx
  //   return (
  //     // @ts-ignore
  //     <View
  //       key={`OrderTab_${idx}`}
  //       tabLabel={txtOrderName} // ต้องมี!! เพราะถ้าไม่มี จะไม่มี order name บน Tabs
  //       style={{
  //         flex: 1,
  //         // width: '100%',
  //         // height: '100%',
  //         backgroundColor: orderType === xCONS.ORDER_TYPE_3 ? COLORS.FORM_PRIMARY_BG : COLORS.BG_LIGHT_GREY,
  //       }}
  //       // heading={txtOrderName} // ถ้าใช้ View มันไม่มี heading
  //     >
  //       <XContainer>
  //         <XContent scrollEnabled={false} contentContainerStyle={{ paddingBottom: orderTabsVisibility ? 175 : 125 }}>
  //           {isEmptyOrder ? (
  //             <View
  //               // Spinner
  //               style={{
  //                 flex: 1,
  //                 width: '100%',
  //                 height: '100%',
  //                 position: 'absolute',
  //                 alignSelf: 'center',
  //                 top: 0,
  //                 bottom: 0,
  //                 left: 0,
  //                 right: 0,
  //                 backgroundColor: 'rgba(0,0,0,0.3)',
  //               }}
  //               // color={ COLORS.APP_MAIN }
  //             />
  //           ) : null}
  //           {!isEmptyOrder ? this._renderOrderByMode(order) : null}
  //         </XContent>
  //         {!isEmptyOrder ? this._renderFooter(order) : null}
  //       </XContainer>
  //     </View>
  //   )
  // }

  // render() {
  //   // @ts-ignore
  //   const { navigation } = this.props
  //   const { mode, isInitialized, orderTabsVisibility } = this.state
  //   const { contentOneOfThreeColumnWidthStyle, contentHeightStyle, contentWithNavHeaderHeightStyle } = this.context

  //   const store_id = navigation.getParam('store_id', null)
  //   const order_id = navigation.getParam('order_id', null)
  //   const focusedOrder = this._getFocusedOrder()
  //   return (
  //     <View
  //       // @ts-ignore
  //       style={StyleSheet.flatten([contentOneOfThreeColumnWidthStyle, contentHeightStyle])}>
  //       {this._renderCustomHeader()}
  //       {this.renderAddressListOverlay()}
  //       {this.renderOverInputcodAmount()}
  //       <XContent
  //         // @ts-ignore
  //         style={StyleSheet.flatten([
  //           contentOneOfThreeColumnWidthStyle,
  //           contentWithNavHeaderHeightStyle,
  //           // @ts-ignore
  //           { overflowY: 'hidden', flexWrap: 'wrap' },
  //         ])}
  //         // @ts-ignore
  //         contentContainerStyle={StyleSheet.flatten([
  //           contentOneOfThreeColumnWidthStyle,
  //           contentWithNavHeaderHeightStyle,
  //           {
  //             // @ts-ignore
  //             overflowY: mode === CREATE ? 'auto' : 'hidden',
  //             paddingBottom: 50,
  //           },
  //           // { overflowY: mode === CREATE ? 'auto' : 'hidden' },
  //         ])}
  //         // scrollEnabled={mode === CREATE}
  //       >
  //         {this._renderTabWrapperByMode()}
  //       </XContent>
  //       {!_.includes([CREATE, EDIT], mode) && focusedOrder ? this._renderFooter(focusedOrder) : null}
  //       {mode === CREATE ? this._renderFooterCreateOrder() : null}
  //     </View>
  //   )
  // }
}

export default OrderView
