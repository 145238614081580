/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react'
import api from 'x/utils/api'
import { IApiOptions, IERPChannel, ISelectedStoreMap, IXScreenProps } from 'x/types'
import * as util from 'x/utils/util'
import _ from 'lodash'
import * as NavActions from 'x/utils/navigation'
import p from 'x/config/platform-specific'
import * as acl from 'x/utils/acl'
import CONS from 'x/config/constants'

interface IHamburgerChannelMenuOptions {
  key: string
  label: string
  onPress: () => void | Promise<void>
  icon?: {
    name: string
    family?:
      | 'AntDesign'
      | 'Entypo'
      | 'EvilIcons'
      | 'Feather'
      | 'FontAwesome'
      | 'FontAwesome5'
      | 'Foundation'
      | 'Ionicons'
      | 'MaterialCommunityIcons'
      | 'MaterialIcons'
      | 'Octicons'
      | 'SimpleLineIcons'
      | 'Zocial'
  }
  perm?: string | string[]
  isValidation: () => boolean
  isDanger?: boolean
}

interface IBaseERPDetailViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
}

const VAT = {
  NO_VAT: 'NO_VAT',
  VAT_ZERO: 'VAT_ZERO',
  VAT_INCLUDED: 'VAT_INCLUDED',
}

interface IBaseERPDetailViewState {
  erpName: string
  isEditErpName: boolean
  mode: 'VIEW' | 'EDIT'
  erpNameBeforeEdit: string
  contactIdRadioIndex: number
  productNumberRadioIndex: number

  documentQuotationTypeRadioIndex: number // ใบเสนอราคา
  documentBillingTypeRadioIndex: number // ใบแจ้งหนี้
  documentReceiptTypeRadioIndex: number // ใบเสร็จรับเงิน
  documentTaxTypeRadioIndex: number // ใบกำกับภาษีขาย
  isDocumentQuotationType: boolean // ใบเสนอราคา
  isDocumentBillingType: boolean // ใบแจ้งหนี้
  isDocumentReceiptType: boolean // ใบเสร็จรับเงิน
  isDocumentTaxType: boolean // ใบกำกับภาษีขาย

  vatSegmentIndex: number

  erpChannelDetail: IERPChannel

  // ProductCode
  isEditErpProductCode: boolean
  erpProductCodeText: string
  erpBeforeProductCodeText: string
  erpShippingFeeIndex: number

  // OtherFee
  isEditErpOtherFee: boolean
  erpOtherFeeText: string
  erpBeforeOtherFeeText: string
  erpOtherFeeIndex: number

  // OtherFee
  isEditErpDiscount: boolean
  erpDiscountText: string
  erpBeforeDiscountText: string
  erpDiscountIndex: number

  hamburgerMenuOptions: IHamburgerChannelMenuOptions[]
  isHamburgerMenuOpened: boolean

  // ปัดเศษ
  erpRoundAdjustmentAccountCodeText: string
  erpBeforeRoundAdjustmentAccountCodeText: string
  isEditingRoundAdjustmentAccountCode: boolean
  erpRoundAdjustmentVatType: number

  invoiceDueDays: number
  isEditinvoiceDueDays: boolean
  erpBeforeInvoiceDueDays: number

  isLoading: boolean
  isOverlayDeleteVisible: boolean
}

export default abstract class BaseERPDetailView extends React.Component<IBaseERPDetailViewProps, IBaseERPDetailViewState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      erpName: '',
      isEditErpName: false,
      mode: 'VIEW',
      erpNameBeforeEdit: '',
      contactIdRadioIndex: 0,
      productNumberRadioIndex: 0,

      documentQuotationTypeRadioIndex: 0,
      documentBillingTypeRadioIndex: 0,
      documentReceiptTypeRadioIndex: 0,
      documentTaxTypeRadioIndex: 0,
      isDocumentQuotationType: false,
      isDocumentBillingType: false,
      isDocumentReceiptType: false,
      isDocumentTaxType: false,

      vatSegmentIndex: 0,

      erpChannelDetail: null,

      isEditErpProductCode: false,
      erpProductCodeText: '',
      erpBeforeProductCodeText: '',
      erpShippingFeeIndex: 0,

      isEditErpOtherFee: false,
      erpBeforeOtherFeeText: '',
      erpOtherFeeText: '',
      erpOtherFeeIndex: 0,

      isEditErpDiscount: false,
      erpBeforeDiscountText: '',
      erpDiscountText: '',
      erpDiscountIndex: 0,

      hamburgerMenuOptions: null,
      isHamburgerMenuOpened: false,

      erpRoundAdjustmentAccountCodeText: '',
      erpBeforeRoundAdjustmentAccountCodeText: '',
      isEditingRoundAdjustmentAccountCode: false,
      erpRoundAdjustmentVatType: 0,

      invoiceDueDays: 1,
      isEditinvoiceDueDays: false,
      erpBeforeInvoiceDueDays: 1,

      isLoading: false,
      isOverlayDeleteVisible: false,
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
  }

  async componentDidMount() {
    await this._fetchErpChannel()
    await this._setChannelDetail()
  }

  _fetchErpChannel = async () => {
    const params = util.getNavParams(this.props)
    const { erp_ch_id } = params
    const { selectedStore } = this.props
    // const {} = this.state
    const store_id = selectedStore.get('id')
    const reqBody = {
      store_id,
      erp_ch_id,
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res = await api.postV2('erp/channel/detail', reqBody, apiOptions)
    // console.log('res _fetchErpChannel => ', res)
    const erpChannel = res.erp_channel_detail
    // erpChannel.is_vat = false
    // console.log('erpChannel // => ', erpChannel)
    await util.setStatePromise(this, {
      erpChannelDetail: erpChannel,
    })
  }

  _setChannelDetail = async (newErpChannel?: IERPChannel) => {
    const { erpChannelDetail } = this.state
    // console.log('erpChannelDetail => ', erpChannelDetail)
    const erpChannelUpdate = !_.isNil(newErpChannel) ? newErpChannel : erpChannelDetail
    // console.log('erpChannelUpdate => ', erpChannelUpdate)
    if (_.isNil(erpChannelUpdate)) {
      return
    }
    // ERP Name
    const erpName = erpChannelUpdate.name

    // รหัสผู้ติดต่อ
    let contactIdRadioIndex = 0
    if (erpChannelUpdate.contact_code_source === 'ADDRESS_REF_1') {
      contactIdRadioIndex = 1
    }
    if (erpChannelUpdate.contact_code_source === 'ADDRESS_REF_2') {
      contactIdRadioIndex = 2
    }

    // ใบเสนอราคา
    const isDocumentQuotationType = erpChannelUpdate.quotation_issue_date !== ''
    let documentQuotationTypeRadioIndex = 0
    if (erpChannelUpdate.quotation_issue_date === 'XSELLY_CREATE_DATE') {
      documentQuotationTypeRadioIndex = 1
    }

    // ใบแจ้งหนี้
    let documentBillingTypeRadioIndex = 0
    const isDocumentBillingType = erpChannelUpdate.invoice_issue_date !== ''
    if (erpChannelUpdate.invoice_issue_date === 'XSELLY_CREATE_DATE') {
      documentBillingTypeRadioIndex = 1
    }
    const invoiceDueDays = erpChannelUpdate.invoice_due_days ? erpChannelUpdate.invoice_due_days : 1

    // ใบเสร็จรับเงิน
    const isDocumentReceiptType = erpChannelUpdate.receipt_issue_date !== ''
    let documentReceiptTypeRadioIndex = 0
    if (erpChannelUpdate.receipt_issue_date === 'PEAK_CREATE_DATE') {
      documentReceiptTypeRadioIndex = 1
    }
    if (erpChannelUpdate.receipt_issue_date === 'XSELLY_CREATE_DATE') {
      documentReceiptTypeRadioIndex = 2
    }

    // เลขที่สินค้า
    let productNumberRadioIndex = 0
    if (erpChannelUpdate.product_code_source === 'UPC') {
      productNumberRadioIndex = 1
    }

    // Vat Type
    let vatSegmentIndex = 0
    if (erpChannelUpdate.vat_type === VAT.VAT_ZERO) {
      vatSegmentIndex = 1
    }
    if (erpChannelUpdate.vat_type === VAT.NO_VAT) {
      vatSegmentIndex = 2
    }

    // ค่าจัดส่ง
    const erpProductCodeText = erpChannelUpdate.erp_shipping_fee_account_code ? erpChannelUpdate.erp_shipping_fee_account_code : ''
    let erpShippingFeeIndex = 0
    if (erpChannelUpdate.erp_shipping_fee_vat_type === VAT.VAT_ZERO) {
      erpShippingFeeIndex = 1
    }
    if (erpChannelUpdate.erp_shipping_fee_vat_type === VAT.NO_VAT) {
      erpShippingFeeIndex = 2
    }

    // ค่าอื่นๆ
    const erpOtherFeeText = erpChannelUpdate.erp_other_fee_account_code ? erpChannelUpdate.erp_other_fee_account_code : ''
    let erpOtherFeeIndex = 0
    if (erpChannelUpdate.erp_other_fee_vat_type === VAT.VAT_ZERO) {
      erpOtherFeeIndex = 1
    }
    if (erpChannelUpdate.erp_other_fee_vat_type === VAT.NO_VAT) {
      erpOtherFeeIndex = 2
    }

    // ค่าส่วรลดท้ายบิล
    const erpDiscountText = erpChannelUpdate.discount_account_code ? erpChannelUpdate.discount_account_code : ''
    let erpDiscountIndex = 0
    if (erpChannelUpdate.discount_vat_type === VAT.VAT_ZERO) {
      erpDiscountIndex = 1
    }
    if (erpChannelUpdate.discount_vat_type === VAT.NO_VAT) {
      erpDiscountIndex = 2
    }

    // ปัดเศษ
    const erpRoundAdjustmentAccountCodeText = erpChannelUpdate.erp_round_adjustment_account_code
      ? erpChannelUpdate.erp_round_adjustment_account_code
      : ''
    let erpRoundAdjustmentVatType = 0
    if (erpChannelUpdate.erp_round_adjustment_vat_type === VAT.VAT_ZERO) {
      erpRoundAdjustmentVatType = 1
    }
    if (erpChannelUpdate.erp_round_adjustment_vat_type === VAT.NO_VAT) {
      erpRoundAdjustmentVatType = 2
    }

    await util.setStatePromise(this, {
      erpName,
      contactIdRadioIndex,
      productNumberRadioIndex,
      isDocumentQuotationType,
      documentQuotationTypeRadioIndex,
      isDocumentBillingType,
      documentBillingTypeRadioIndex,
      isDocumentReceiptType,
      documentReceiptTypeRadioIndex,
      vatSegmentIndex,
      erpProductCodeText,
      erpShippingFeeIndex,
      erpOtherFeeText,
      erpOtherFeeIndex,
      erpRoundAdjustmentAccountCodeText,
      invoiceDueDays,
      erpDiscountText,
      erpDiscountIndex,
      erpRoundAdjustmentVatType,
    })

    await this._configHamburgerMenuItems()
  }

  _configHamburgerMenuItems = async () => {
    const { erpChannelDetail } = this.state
    if (_.isNil(erpChannelDetail)) {
      return
    }
    const isActive = erpChannelDetail.is_active
    const menu = []

    const updateBusinessInformation: IHamburgerChannelMenuOptions = {
      key: 'un_auth_erp',
      label: 'อัปเดตข้อมูลกิจการ',
      onPress: () => this._syncErpChannel(),
      icon: { name: 'refresh', family: 'EvilIcons' },
      // isDanger: true,
      isValidation: () => true,
    }

    const unAuthErp: IHamburgerChannelMenuOptions = {
      key: 'un_auth_erp',
      label: 'ปิดการใช้งาน',
      onPress: () => this._unAuthErpChannel(),
      icon: { name: 'close-o', family: 'EvilIcons' },
      isDanger: true,
      isValidation: () => true,
    }

    const authErp: IHamburgerChannelMenuOptions = {
      key: 'auth_erp',
      label: 'เปิดการใช้งาน',
      onPress: () => this._authErpChannel(),
      icon: { name: 'check', family: 'EvilIcons' },
      isValidation: () => true,
    }

    const deleteErp: IHamburgerChannelMenuOptions = {
      key: 'delete_erp',
      label: 'ลบการเชื่อมต่อระบบบัญชี',
      onPress: () => {
        if (!acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ERP_CHANNEL_DELETE)) {
          this._warningPermission()
          return
        }
        this.setState({ isOverlayDeleteVisible: true })
      },
      icon: { name: 'delete-forever', family: 'MaterialIcons' },
      isDanger: true,
      isValidation: () => true,
    }
    // const syncProduct: IHamburgerChannelMenuOptions = {
    //   key: 'sync_product',
    //   label: 'ซิงค์ข้อมูลสินค้า (ทั้งหมด)',
    //   onPress: () => this._syncAllProductMKP(),
    //   icon: { name: 'sync', family: 'MaterialCommunityIcons' },
    //   isValidation: () => true,
    // }
    // menu.push(syncProduct)

    menu.push(updateBusinessInformation)
    if (isActive) {
      menu.push(unAuthErp)
    } else {
      menu.push(authErp)
      menu.push(deleteErp)
    }

    await util.setStatePromise(this, {
      hamburgerMenuOptions: menu,
    })
  }

  _warningPermission = () => {
    p.op.showConfirmationOkOnly(``, `คุณไม่ได้รับสิทธิ์ในการใช้งานฟีเจอร์นี้`)
  }

  _unAuthErpChannel = async () => {
    if (!acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ERP_CHANNEL_SET_ACTIVE)) {
      this._warningPermission()
      return
    }
    const params = util.getNavParams(this.props)
    const { erp_ch_id } = params
    const { selectedStore } = this.props
    const { erpChannelDetail } = this.state

    const systemName = util.getErpChannelSystemName(erpChannelDetail.erp_system_id)
    const confirm = await new Promise<boolean>((passConfirm) => {
      p.op.showConfirmation(
        `ยืนยันการปิดใช้งานบัญชี ${erpChannelDetail.name}`,
        `เมื่อยืนยันแล้วระบบจะปิดการใช้งานกับ ${systemName} `,
        () => passConfirm(true),
        () => passConfirm(false),
        'ยืนยัน',
        'ยกเลิก'
      )
    })

    if (!confirm) {
      return
    }

    await this._setIsLoading()
    const store_id = selectedStore.get('id')
    const reqBody = {
      store_id,
      erp_ch_id,
      is_active: false,
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res = await api.postV2('erp/channel/update', reqBody, apiOptions)
    // console.log('res _fetchErpChannel => ', res)
    if (res.erp_channel_detail) {
      await util.setStatePromise(this, {
        erpChannelDetail: res.erp_channel_detail,
        erpName: res.erp_channel_detail.name,
      })
      const { callBackUpdateNewErp } = params
      if (_.isFunction(callBackUpdateNewErp)) {
        callBackUpdateNewErp(res.erp_channel_detail)
      }
      await this._setChannelDetail(res.erp_channel_detail)
      p.op.showToast('ปิดการใช้งานเรียบร้อยแล้ว', 'success')
    }

    await this._UnsetIsLoading()
  }

  _authErpChannel = async () => {
    if (!acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ERP_CHANNEL_SET_ACTIVE)) {
      this._warningPermission()
      return
    }
    const params = util.getNavParams(this.props)
    const { erp_ch_id } = params
    const { selectedStore } = this.props
    const { erpChannelDetail } = this.state

    const systemName = util.getErpChannelSystemName(erpChannelDetail.erp_system_id)
    const confirm = await new Promise<boolean>((passConfirm) => {
      p.op.showConfirmation(
        `ยืนยันการเปิดใช้งานบัญชี ${erpChannelDetail.name}`,
        `เมื่อยืนยันแล้วระบบจะเปิดใช้งานบัญชีกับ ${systemName} อีกครั้ง`,
        () => passConfirm(true),
        () => passConfirm(false),
        'ยืนยัน',
        'ยกเลิก'
      )
    })

    if (!confirm) {
      return
    }

    await this._setIsLoading()
    const store_id = selectedStore.get('id')
    const reqBody = {
      store_id,
      erp_ch_id,
      is_active: true,
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res = await api.postV2('erp/channel/update', reqBody, apiOptions)
    // console.log('res _fetchErpChannel => ', res)
    if (res.erp_channel_detail) {
      await util.setStatePromise(this, {
        erpChannelDetail: res.erp_channel_detail,
        erpName: res.erp_channel_detail.name,
      })
      const { callBackUpdateNewErp } = params
      if (_.isFunction(callBackUpdateNewErp)) {
        callBackUpdateNewErp(res.erp_channel_detail)
      }
      await this._setChannelDetail(res.erp_channel_detail)
      p.op.showToast('เปิดการใช้งานเรียบร้อยแล้ว', 'success')
    }

    await this._UnsetIsLoading()
  }

  _deleteErpChannel = async () => {
    const params = util.getNavParams(this.props)
    const { erp_ch_id } = params
    const { selectedStore } = this.props
    const { erpChannelDetail } = this.state
    // p.op.showToast('DELETED !!', 'success')
    // return

    await this._setIsLoading()
    const store_id = selectedStore.get('id')
    const reqBody = {
      store_id,
      erp_ch_id,
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res = await api.postV2('erp/channel/delete', reqBody, apiOptions)
    // console.log('res _fetchErpChannel => ', res)
    if (res.erp_channels) {
      const { callBackUpdateNewErpList } = params
      if (_.isFunction(callBackUpdateNewErpList)) {
        callBackUpdateNewErpList(res.erp_channels)
      }
      await this._setChannelDetail(res.erp_channel_detail)
      p.op.showToast('ยกเลิกการเชื่อมต่อบัญชีเรียบร้อยแล้ว', 'success')
      this.goBack()
    }

    await this._UnsetIsLoading()
  }

  goBack = () => {
    const { isEditErpName, isEditErpProductCode, isEditErpOtherFee, isEditingRoundAdjustmentAccountCode, isEditinvoiceDueDays } = this.state
    if (isEditErpName || isEditErpProductCode || isEditErpOtherFee || isEditingRoundAdjustmentAccountCode || isEditinvoiceDueDays) {
      p.op.showConfirmation(
        '',
        'ต้องการยกเลิกการแก้ไขใช่หรือไม่',
        () => util.navGoBack(this.props),
        () => null,
        'ยกเลิกการแก้ไข',
        'แก้ไขต่อไป'
      )
      return
    }
    util.navGoBack(this.props)
  }

  _setIsLoading = async () => {
    await util.setStatePromise(this, {
      isLoading: true,
    })
  }

  _UnsetIsLoading = async () => {
    await util.setStatePromise(this, {
      isLoading: false,
    })
  }

  _updateErpName = (newErpName: string) => {
    this.setState({
      erpName: newErpName,
    })
  }

  _submitNewErpName = async () => {
    const { erpName } = this.state
    this.setState({
      // erpNameBeforeEdit: erpName,
      isEditErpName: false,
    })
    await this._UpdateChannel('name', erpName)
  }

  _submitNewErpProductCode = async () => {
    const { erpProductCodeText } = this.state
    this.setState({
      // erpBeforeProductCodeText: erpProductCodeText,
      isEditErpProductCode: false,
    })
    await this._UpdateChannel('erp_shipping_fee_account_code', erpProductCodeText)
  }

  _submitInvoiceDueDays = async () => {
    const { invoiceDueDays } = this.state
    if (invoiceDueDays < 1) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุจำนวนวันที่ครบกำหนดนับจากวันที่สร้างเอกสารอย่างน้อย 1 วัน')
      return
    }
    this.setState({
      // erpBeforeProductCodeText: erpProductCodeText,
      isEditinvoiceDueDays: false,
    })
    await this._UpdateChannel('invoice_due_days', invoiceDueDays)
  }

  _submitNewErpOtherFee = async () => {
    const { erpOtherFeeText } = this.state
    this.setState({
      // erpBeforeProductCodeText: erpOtherFeeText,
      isEditErpOtherFee: false,
    })
    await this._UpdateChannel('erp_other_fee_account_code', erpOtherFeeText)
  }

  _submitNewErpDiscount = async () => {
    const { erpDiscountText } = this.state
    this.setState({
      // erpBeforeProductCodeText: erpOtherFeeText,
      isEditErpDiscount: false,
    })
    await this._UpdateChannel('discount_account_code', erpDiscountText)
  }

  _submitNewErpRoundAdjustmentAccountCodeText = async () => {
    const { erpRoundAdjustmentAccountCodeText } = this.state
    this.setState({
      isEditingRoundAdjustmentAccountCode: false,
    })
    await this._UpdateChannel('erp_round_adjustment_account_code', erpRoundAdjustmentAccountCodeText)
  }

  _confirmChangeMkpSetting = async () => {
    const confirm = await new Promise<boolean>((passConfirm) => {
      p.op.showConfirmation(
        'เปลี่ยนการตั้งค่าระบบบัญชี?',
        'การเปลี่ยนแปลงนี้จะมีผลทันทีสำหรับการสร้างเอกสารหลังจากนี้ (ไม่มีผลย้อนหลังกับเอกสารที่สร้างไปแล้ว) คุณต้องการเปลี่ยนการตั้งค่านี้ใช่หรือไม่?',
        () => passConfirm(true),
        () => passConfirm(false),
        'ยืนยัน',
        'ยกเลิก'
      )
    })
    return confirm
  }

  contactIdRadioOnChange = async (newVal: number) => {
    const confirm = await this._confirmChangeMkpSetting()
    if (!confirm) {
      return
    }
    let val = 'LEGAL_ENTITY_ID'
    if (newVal === 1) {
      val = 'ADDRESS_REF_1'
    }
    if (newVal === 2) {
      val = 'ADDRESS_REF_2'
    }
    this._UpdateChannel('contact_code_source', val)
  }

  productNumberadioOnChange = async (newVal: number) => {
    const confirm = await this._confirmChangeMkpSetting()
    if (!confirm) {
      return
    }
    let val = 'SKU'
    if (newVal === 1) {
      val = 'UPC'
    }
    this._UpdateChannel('product_code_source', val)
  }

  _navToERPPaymentAccountView = () => {
    const { navigation, selectedStore } = this.props
    const { erpChannelDetail } = this.state
    navigation.dispatch(
      NavActions.navToERPPaymentAccountView({
        store_id: selectedStore.get('id'),
        erpChannel: erpChannelDetail,
      })
    )
  }

  _navToERPChannelContact = () => {
    const { navigation, selectedStore } = this.props
    navigation.dispatch(
      NavActions.navToERPChannelContact({
        store_id: selectedStore.get('id'),
      })
    )
  }

  _UpdateChannel = async (key: string, value: any) => {
    const params = util.getNavParams(this.props)
    const { erp_ch_id } = params
    const { selectedStore } = this.props
    // const {} = this.state
    const store_id = selectedStore.get('id')
    const reqBody = {
      store_id,
      erp_ch_id,
      [key]: value,
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res = await api.postV2('erp/channel/update', reqBody, apiOptions)
    // console.log('res _fetchErpChannel => ', res)
    if (res.erp_channel_detail) {
      await util.setStatePromise(this, {
        erpChannelDetail: res.erp_channel_detail,
        erpName: res.erp_channel_detail.name,
      })
      const { callBackUpdateNewErp } = params
      if (_.isFunction(callBackUpdateNewErp)) {
        callBackUpdateNewErp(res.erp_channel_detail)
      }
      await this._setChannelDetail(res.erp_channel_detail)
      p.op.showToast('แก้ไขข้อมูลเรียบร้อยแล้ว', 'success')
    }
  }

  _syncErpChannel = async () => {
    const params = util.getNavParams(this.props)
    const { erp_ch_id } = params
    const { selectedStore } = this.props
    // const {} = this.state
    const store_id = selectedStore.get('id')
    const reqBody = {
      store_id,
      erp_ch_id,
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res = await api.postV2('erp/channel/sync', reqBody, apiOptions)
    // console.log('res _fetchErpChannel => ', res)
    if (res.erp_channel_detail) {
      await util.setStatePromise(this, {
        erpChannelDetail: res.erp_channel_detail,
        erpName: res.erp_channel_detail.name,
      })
      const { callBackUpdateNewErp } = params
      if (_.isFunction(callBackUpdateNewErp)) {
        callBackUpdateNewErp(res.erp_channel_detail)
      }
      await this._setChannelDetail(res.erp_channel_detail)
      p.op.showToast('อัปเดตข้อมูลกิจการเรียบร้อยแล้ว', 'success')
    }
  }

  _vatChange = async (newIndex: number, key: string) => {
    const confirm = await this._confirmChangeMkpSetting()
    if (!confirm) {
      return
    }
    if (newIndex === 0) {
      await this._UpdateChannel(key, 'VAT_INCLUDED')
    } else if (newIndex === 1) {
      await this._UpdateChannel(key, 'VAT_ZERO')
    } else {
      await this._UpdateChannel(key, 'NO_VAT')
    }
  }

  // _saveBtn = () => {
  //   const params = util.getNavParams(this.props)
  //   const { callBackUpdateNewErp } = params
  //   if (_.isFunction(callBackUpdateNewErp)) {
  //     callBackUpdateNewErp()
  //   }
  //   this.goBack()
  // }
}
