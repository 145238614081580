import _ from 'lodash'
import api from 'x/utils/api'
import { takeLatest, put, call, fork, all } from 'redux-saga/effects'
import * as SubscriptionActions from 'x/modules/subscription/SubscriptionState'
import * as MySubscriptionActions from 'x/modules/subscription/MySubscriptionState'
import actions from '../../config/actions'
import * as util from '../../utils/util'
import { log } from '../../utils/util'

import p from '../../config/platform-specific'
import xCONS from '../../config/constants'

const moment = require('moment')
require('moment/locale/th')
require('moment-timezone')

moment.locale('th')

// import { fromJS } from 'immutable'
// import p from '../../config/platform-specific'
// import xCONS from '../../config/constants'
// import { setCrashlyticsUserId } from './../../utils/analytics'

export default function* watchAllSubscription() {
  yield all([fork(watchSubscriptionFetch)])
}

function* watchSubscriptionFetch() {
  log('In watchSubscriptionFetch')
  // @ts-ignore
  yield takeLatest(actions.MY_SUBSCRIPTION_FETCH, fetchMySubscription)
}

function* fetchMySubscription({ successCallback = null, failedCallback = null }) {
  log('In fetchMySubscription')
  try {
    // @ts-ignore
    const res = yield call(api.get, api.GET_SUBSCRIPTION)
    log('In fetchMySubscription res: ', res)
    yield call(handleSubscriptionFromResponse, res)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    log('In fetchMySubscription error: ', error)
    yield put({ type: actions.MY_SUBSCRIPTION_FETCH_FAILED })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

export function* handleSubscriptionFromResponse(res) {
  if (res && res.subscription) {
    const { subscription } = res

    // log('openSubscriptionWarning => ', appGlobalMethods.openSubscriptionWarning)

    // โหลดเข้าทั้ง mySubscription และ subscription ถ้ามีของ store owner's subscription ค่อยมาทับ subscription ทีหลัง
    yield put(MySubscriptionActions.loadSubscription({ ...subscription, lastUpdated: moment() }))
    yield put(SubscriptionActions.loadSubscription({ ...subscription, lastUpdated: moment() }))

    // Check for show warning out of order quota
    yield call(checkSubscriptionOrderQuota, subscription)

    util.log('handleSubscriptionFromResponse subscription => ', subscription)
  }
}

export function* checkSubscriptionOrderQuota(subscription: { [key: string]: any }) {
  const { order_count_curr, order_count_quota, endBillingDate, exceeded_at } = subscription
  const { STORAGE_KEYS, SERVER_DATETIME_FORMAT } = xCONS
  const availableOrder = order_count_quota - order_count_curr
  const availableOrderPercent = Math.round((availableOrder / order_count_quota) * 100)

  const warningMessage = {
    type: null,
    message: `คุณใช้งานได้อีก ${availableOrder} ออเดอร์`,
    closeable: true,
    priority: 2,
  }

  if (!_.isNil(exceeded_at)) {
    const exceeded_at_moment = moment(exceeded_at)
    const timeLeft = exceeded_at_moment.clone().diff(moment(), 'd')
    if (timeLeft < -2) {
      warningMessage.message =
        'คุณใช้ออเดอร์เกินจำนวนโควต้าแล้ว คุณจะไม่สามารถเปิดและรับออเดอร์จากตัวแทนได้ กรุณาติดต่อทีมงาน XSelly เพื่อเลือกแพ็คเกจใช้งานที่เหมาะสมกับคุณ'
      warningMessage.closeable = false
      warningMessage.type = 'danger'
      warningMessage.priority = 4
      yield put(MySubscriptionActions.showWarning(warningMessage))
      return
    }
  }

  const now = moment()
  // const usagePercentOrder = (order_count_curr / order_count_quota) * 100

  // ถ้ามีออเดอร์อยู่ ตัดขั้นตอน validation ออกมา แล้ว reset orderTracker
  if (availableOrderPercent > 20) {
    // ถ้า available %order > 20
    const initTracker = { exceededAt: exceeded_at || null, lastWarning: null, lastUpdated: now.format(SERVER_DATETIME_FORMAT) }
    yield call(p.op.storageSet, STORAGE_KEYS.SUBSCRIPTION_ORDER_TRACKER, initTracker)
  } else {
    // ถ้า available %order < 20
    // log('availableOrderPercent => ', availableOrderPercent)
    let orderTracker = yield call(p.op.storageGet, STORAGE_KEYS.SUBSCRIPTION_ORDER_TRACKER)
    if (!orderTracker) {
      orderTracker = {
        exceededAt: exceeded_at || null,
        lastWarning: null,
        lastUpdated: now.format(SERVER_DATETIME_FORMAT),
      }
    }

    if (exceeded_at) {
      orderTracker.exceededAt = exceeded_at || orderTracker.exceededAt
    }

    // MOCK ::
    // orderTracker.exceededAt = now.clone().add(42, 'h').format(SERVER_DATETIME_FORMAT)
    // orderTracker.exceededAt = now.clone()
    // orderTracker.exceededAt = now.clone().add(5, 'm').format(SERVER_DATETIME_FORMAT)
    // orderTracker.exceededAt = now.clone().add(0, 'm').format(SERVER_DATETIME_FORMAT)
    // orderTracker.lastWarning = now.clone().subtract(27, 'h').format(SERVER_DATETIME_FORMAT)
    // log('orderTracker => ', orderTracker)

    const { exceededAt, lastWarning } = orderTracker

    // if (!lastWarning) {
    //   orderTracker.lastWarning = now.clone().format(SERVER_DATETIME_FORMAT)
    // }

    // let lastestWarning = moment().format(SERVER_DATETIME_FORMAT)
    const txtFromNowToBillingDate = moment(endBillingDate, 'YYYY-MM-DD').endOf('d').fromNow()
    const txtBreakTheOrder = 'คุณจะไม่สามารถเปิดและรับออเดอร์จากตัวแทนได้'
    const txtEndOfBillingDate = `ก่อนหมดรอบบิลใน${txtFromNowToBillingDate} หากต้องการเพิ่มจำนวนออเดอร์กรุณาติดต่อทีมงาน`
    const txtContactXSelly = ' กรุณาติดต่อทีมงาน XSelly เพื่อเลือกแพ็คเกจใช้งานที่เหมาะสมกับคุณ เนื่องจาก'
    const txtOutOfOrder = ' คุณใช้ออเดอร์เกินจำนวนโควต้า'

    const latestWarningTime = lastWarning ? moment(lastWarning, SERVER_DATETIME_FORMAT) : null

    if (availableOrderPercent <= 0) {
      warningMessage.message = txtOutOfOrder
      warningMessage.message += txtContactXSelly

      if (!exceededAt) {
        // orderTracker.exceededAt = exceeded_at ? exceeded_at : now.clone().add(48, 'h').format(SERVER_DATETIME_FORMAT)
        orderTracker.exceededAt = exceeded_at || now.clone().format(SERVER_DATETIME_FORMAT)
      }

      // const outOfOrderTime = moment(orderTracker.exceededAt, SERVER_DATETIME_FORMAT)
      const outOfOrderTime = moment(orderTracker.exceededAt, SERVER_DATETIME_FORMAT).add(48, 'h')
      // log('outOfOrderTime => ', outOfOrderTime)
      // const diffTime = now.diff(outOfOrderTime, 'm')
      // log('diffTime => ', diffTime)

      // if (diffTime < 0) {
      //   // ยังเหลือเวลา
      //   const txtFromNowToCutTime = outOfOrderTime.fromNow()
      //   warningMessage.message = warningMessage.message + ` ${txtFromNowToCutTime} `
      // } else {
      //   // หมดเวลา
      //   warningMessage.message = warningMessage.message + ' ตอนนี้'
      // }

      if (now.isBefore(outOfOrderTime, 'm')) {
        // ยังเหลือเวลา
        const txtFromNowToCutTime = outOfOrderTime.fromNow()
        warningMessage.message += `${txtFromNowToCutTime} `
      } else {
        // หมดเวลา
        warningMessage.message += 'ตอนนี้'
      }

      // ใช้ออเดอร์เกินโควค้า
      warningMessage.message += txtBreakTheOrder
      // warningMessage.message = warningMessage.message + txtEndOfBillingDate
      warningMessage.closeable = false
      warningMessage.type = 'danger'
    } else if (availableOrderPercent <= 10) {
      // เหลือ 10%
      if (Math.abs(now.diff(latestWarningTime, 'h')) >= 6 || !latestWarningTime) {
        warningMessage.message += txtEndOfBillingDate
        warningMessage.closeable = true
        warningMessage.type = 'warning'
        orderTracker.lastWarning = now.format(SERVER_DATETIME_FORMAT)
        orderTracker.lastUpdated = now.format(SERVER_DATETIME_FORMAT)
      }
    } else if (availableOrderPercent <= 20) {
      // เหลือ 20 %
      if (Math.abs(now.diff(latestWarningTime, 'h')) >= 24 || !latestWarningTime) {
        warningMessage.message += txtEndOfBillingDate
        // warningMessage.message = warningMessage.message + ` หลังจากคุณใช้งานออเดอร์จนหมด 48 ชั่วโมง ${txtBreakTheOrder}`
        warningMessage.closeable = true
        warningMessage.type = 'warning'
        orderTracker.lastWarning = now.format(SERVER_DATETIME_FORMAT)
        orderTracker.lastUpdated = now.format(SERVER_DATETIME_FORMAT)
      }
    } else {
      // Reset รอบบิล
      warningMessage.closeable = true
      warningMessage.type = null
      orderTracker.exceededAt = exceeded_at || null
      orderTracker.lastWarning = null
      orderTracker.lastUpdated = null
    }

    yield call(p.op.storageSet, STORAGE_KEYS.SUBSCRIPTION_ORDER_TRACKER, orderTracker)

    if (warningMessage.type && warningMessage.type) {
      yield put(MySubscriptionActions.showWarning(warningMessage))
    }
    // let afterOrderTracking = yield call(p.op.storageGet, STORAGE_KEYS.SUBSCRIPTION_ORDER_TRACKER)
    // log('afterOrderTracking => ', afterOrderTracking)
  }
}
