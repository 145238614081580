import React from 'react'
import { APP_CONFIG } from 'x/config/mode'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import { ISelectedStoreMap, IXScreenProps } from 'x/types'
import { IIconType } from 'xui/components/Icon'
import VerifySlipIcon from 'xui/components/VerifySlipIcon'

const ICON_MY_STORE = require('../../../../../xui/x/img/tb/tb_my0.png')
const PULL_VOLUMN_DISCOUNT_ICON_IMAGE = require('../../../../../xui/x/img/volumn_discount.png')
const VERIFY_SLIP_ICON = require('../../../../../xui/x/img/verify_slip/blue.png')

export interface IStoreSettingListSections {
  txtTitle: string
  data: IStoreSettingMenuList[]
}
// icon: { name: 'view-grid', family: 'MaterialCommunityIcons', style: { paddingTop: 2 } },
export interface IStoreSettingMenuList {
  labelKey: string
  navAction: any
  iconImage?: {
    name: any
    style: any
  }
  icon?: {
    name: string
    family: IIconType
    style?: any
  }
  renderIcon?: () => JSX.Element
}

export interface BaseStoreSettingViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  resetEditingStore: () => void
}

export interface BaseStoreSettingViewState {
  STORE_SETTING_LIST_SECTIONS: IStoreSettingListSections[]
}

export default abstract class BaseStoreSettingView extends React.Component<BaseStoreSettingViewProps, BaseStoreSettingViewState> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  // helperProfileId: number

  protected constructor(props) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    super(props)
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      STORE_SETTING_LIST_SECTIONS: null,
    }
    // this._tabRef = React.createRef()
  }

  async componentDidMount() {
    // const { navigation, selectedStore } = this.props
    // const { state } = navigation
    // const { tabIndex, permissionList, permission_id } = state.params
    const { selectedStore, resetEditingStore } = this.props
    // console.log('selectedStore => ', selectedStore.get('id'))
    await this._configMenu()
    if (!selectedStore.has('id')) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      p.op.showConfirmationOkOnly('การเข้าถึงที่ไม่ถูกต้อง', 'ยังไม่มีการเลือกร้านค้าที่จะตั้งค่า')
      util.navGoBack(this.props)
    } else {
      resetEditingStore()
      // await this._loadStoreMenu()
    }
  }

  _configMenu = async () => {
    const { selectedStore } = this.props
    // const store_id = selectedStore.get('id')
    const store_name = selectedStore.get('name')
    // let MENU = []
    const newMenu = []
    // console.log(`store_id`, store_id)
    const sectionMyStore = {
      txtTitle: `${p.op.t('StoreSetting.HeaderMyStore')}: ${store_name}`,
      // iconName: 'store',
      // iconFamily: 'MaterialCommunityIcons',
      data: this._configMenuSectionMyStore(),
    }

    const sectionPayment = {
      txtTitle: p.op.t('StoreSetting.HeaderPayment'),
      data: this._configMenuSectionPayment(),
    }

    const sectionShipping = {
      txtTitle: `${p.op.t('StoreSetting.HeaderShipping')}`,
      // iconName: 'store',
      // iconFamily: 'MaterialCommunityIcons',
      data: this._configMenuSectionShipping(),
    }

    const sectionOrder = {
      txtTitle: p.op.t('StoreSetting.HeaderOrder'),
      // iconName: 'list-box-outline',
      // iconFamily: 'Ionicons',
      data: this._configMenuSectionOrder(),
    }

    const sectionProduct = {
      txtTitle: p.op.t('StoreSetting.HeaderProduct'),
      // iconName: 'list-box-outline',
      // iconFamily: 'Ionicons',
      data: this._configMenuSectionProduct(),
    }

    // const sectionWarehouse = {
    //   txtTitle: p.op.t('StoreSetting.HeaderWarehouse'),
    //   // iconName: 'list-box-outline',
    //   // iconFamily: 'Ionicons',
    //   data: await this._configMenuSectionWarehouse(),
    // }

    const sectionrOther = {
      txtTitle: p.op.t('StoreSetting.HeaderOther'),
      // iconName: 'list-box-outline',
      // iconFamily: 'Ionicons',
      data: this._configMenuSectionOther(),
    }

    if (APP_CONFIG.store_setting.section.my_store.show_section) {
      newMenu.push(sectionMyStore)
    }

    if (APP_CONFIG.store_setting.section.my_store.show_section) {
      newMenu.push(sectionPayment)
    }

    // if (true) {
    newMenu.push(sectionShipping)
    // }

    if (APP_CONFIG.store_setting.section.order.show_section) {
      newMenu.push(sectionOrder)
    }

    if (APP_CONFIG.store_setting.section.product.show_section) {
      newMenu.push(sectionProduct)
    }
    // if (APP_CONFIG.store_setting.section.warehouse.show_section) {
    //   newMenu.push(sectionWarehouse)
    // }

    // if (true) {
    newMenu.push(sectionrOther)
    // }

    // MENU = newMenu
    // {
    //   // ใบปะหน้าพัสดุ
    //   txtTitle: p.op.t('StoreSetting.HeaderShippingLabel'),
    //   // iconName: 'list-box-outline',
    //   // iconFamily: 'Ionicons',
    //   data: [
    //     { labelKey: 'MenuPaperangShippingLabel', navAction: NavActions.navToStoreSettingPaperang },
    //     { labelKey: 'MenuPDFShippingLabel', navAction: NavActions.navToStoreSettingPDFShippingLabel },
    //   ],
    // },

    await util.setStatePromise(this, {
      STORE_SETTING_LIST_SECTIONS: newMenu,
    })
  }

  // _loadStoreMenu = async () => {
  // const { selectedStore } = this.props
  // const store_id = nextStore ? nextStore.get('id') : selectedStore.get('id')
  // const store_name = nextStore ? nextStore.get('name') : selectedStore.get('name')
  // const MENU = []
  // await util.setStatePromise(this, {
  //   STORE_SETTING_LIST_SECTIONS: MENU,
  // })
  // this.setState({
  //   STORE_SETTING_LIST_SECTIONS: MENU,
  // })
  // }

  _configMenuSectionMyStore = () => {
    const { selectedStore } = this.props
    const store_id = selectedStore.get('id')
    const menu = []
    const editStore = {
      labelKey: 'MenuGeneral',
      navAction: NavActions.navToStoreSettingGeneral,
      iconImage: { name: ICON_MY_STORE, style: { width: 26, height: 20, marginLeft: 0, marginRight: 0, marginTop: 0 } },
    }
    const storeAddress = {
      labelKey: 'MenuStoreAddress',
      navAction: NavActions.navToAddressListStore({
        store_id,
        headerTitle: 'ที่อยู่ร้านฉัน',
        // onPressAddress: (address) => console.log('//////// => ', address),
      }),
      icon: { name: 'home', family: 'FontAwesome' },
    }
    const paymentAccounts = {
      labelKey: 'MenuStorePaymentAccounts',
      // navAction: NavActions.navToStorePaymentAccountListView({ store_id }),
      navAction: NavActions.navToStorePaymentAccountListView({ targetStoreId: store_id }),
      icon: { name: 'cash', family: 'Ionicons', style: { paddingTop: 2 } },
      // icon: { name: 'bank-transfer-in', family: 'MaterialCommunityIcons' },  <<< chinese icon
    }
    const helper = {
      labelKey: 'MenuStoreShoppingAssistant',
      navAction: NavActions.navToAssistantGroupList,
      icon: { name: 'users', family: 'FontAwesome', style: { paddingTop: 2 } },
    }
    const printingAllShippingLabel = {
      labelKey: 'MenuPrintingAllShippingLabel',
      navAction: NavActions.navToStoreSettingAllPrintingShipping,
      icon: { name: 'printer', family: 'AntDesign', style: { paddingTop: 1 } },
    }

    // const verifySlip = {
    //   labelKey: 'MenuVerifySlip',
    //   navAction: NavActions.navToStoreSettingVerifySlip,
    //   // iconImage: { name: VERIFY_SLIP_ICON, style: { width: 24, height: 24, marginLeft: 0, marginRight: 0, marginTop: 0 } },
    //   renderIcon: () => <VerifySlipIcon />
    // }

    if (APP_CONFIG.store_setting.section.my_store.show_menu_edit_store) {
      menu.push(editStore)
    }
    if (APP_CONFIG.store_setting.section.my_store.show_menu_store_address) {
      menu.push(storeAddress)
    }
    // ย้ายไป section อื่นแล้ว
    // if (APP_CONFIG.store_setting.section.my_store.show_menu_store_payment_accounts) {
    //   menu.push(paymentAccounts)
    // }
    if (APP_CONFIG.store_setting.section.my_store.show_menu_setting_helper) {
      menu.push(helper)
    }
    if (APP_CONFIG.store_setting.section.my_store.show_menu_print_document_settings) {
      menu.push(printingAllShippingLabel)
    }
    // {
    //   labelKey: 'MenuProductGroups',
    //   navAction: NavActions.navToProductGroupListView({ store_id }),
    // },
    // { labelKey: 'MenuStoreSettingShippingView', navAction: NavActions.navToStoreSettingShipping }, // ค่าขนส่ง TO DO ARTID

    // if (APP_CONFIG.store_setting.section.order.show_menu_order_note_settings) {
    // menu.push(verifySlip)
    // }
    return menu
  }

  _configMenuSectionPayment = () => {
    const { selectedStore } = this.props
    const store_id = selectedStore.get('id')
    const menu = []

    const paymentAccounts = {
      labelKey: 'MenuStorePaymentAccounts',
      // navAction: NavActions.navToStorePaymentAccountListView({ store_id }),
      navAction: NavActions.navToStorePaymentAccountListView({ targetStoreId: store_id }),
      icon: { name: 'cash', family: 'Ionicons', style: { paddingTop: 2 } },
      // icon: { name: 'bank-transfer-in', family: 'MaterialCommunityIcons' },  <<< chinese icon
    }

    const verifySlip = {
      labelKey: 'MenuVerifySlip',
      navAction: NavActions.navToStoreSettingVerifySlip({ store_id }),
      // iconImage: { name: VERIFY_SLIP_ICON, style: { width: 24, height: 24, marginLeft: 0, marginRight: 0, marginTop: 0 } },
      renderIcon: () => <VerifySlipIcon />,
    }

    if (APP_CONFIG.store_setting.section.my_store.show_menu_store_payment_accounts) {
      menu.push(paymentAccounts)
    }

    // if (APP_CONFIG.store_setting.section.order.show_menu_order_note_settings) {
    menu.push(verifySlip)
    // }

    return menu
  }

  _configMenuSectionOrder = () => {
    const menu = []
    const senderAddress = {
      labelKey: 'MenuSenderAddress',
      navAction: NavActions.navToStoreSettingOrderPolicy,
      icon: { name: 'store', family: 'MaterialCommunityIcons' },
    }
    const linkOrder = {
      labelKey: 'MenuOrderBillOnlineShowAmount',
      navAction: NavActions.navToStoreSettingOrderBillOnlineShowAmount,
      icon: { name: 'link', family: 'Ionicons' },
    }
    const orderAutomation = {
      labelKey: 'MenuOrderAutomation',
      navAction: NavActions.navToStoreSettingOrderAutomation,
      icon: { name: 'checkmark-circle-outline', family: 'Ionicons' },
    }
    const lockOrderAutomation = {
      labelKey: 'MenuLockOrderAutomation',
      navAction: NavActions.navToStoreSettingLockOrderAutomation,
      icon: { name: 'lock-closed-outline', family: 'Ionicons' },
    }
    const cancelOrderAutomation = {
      labelKey: 'MenuStoreCancelOrderAutomation',
      navAction: NavActions.navToStoreCancelOrderAutomation,
      icon: { name: 'cancel', family: 'MaterialCommunityIcons', style: { fontSize: 17, paddingTop: 1 } },
    }
    const shipBeforePayAndCod = {
      labelKey: 'MenuShipBeforePayAndCod',
      navAction: NavActions.navToStoreSettingShipBeforePayAndCod,
      icon: { name: 'cash', family: 'Ionicons', style: { paddingTop: 2 } },
    }
    const orderNote = {
      labelKey: 'MenuOrderNote',
      navAction: NavActions.navToStoreSettingOrderNote,
      icon: { name: 'edit', family: 'FontAwesome', style: { paddingTop: 2 } },
    }
    // { labelKey: 'MenuOrderTimeout', navAction: NavActions.navToStoreSettingOrderTime },

    if (APP_CONFIG.store_setting.section.order.show_menu_sender_address) {
      menu.push(senderAddress)
    }
    if (APP_CONFIG.store_setting.section.order.show_menu_link_order) {
      menu.push(linkOrder)
    }
    if (APP_CONFIG.store_setting.section.order.show_menu_auto_confirm_order) {
      menu.push(orderAutomation)
    }
    if (APP_CONFIG.store_setting.section.order.show_menu_auto_lock_order) {
      menu.push(lockOrderAutomation)
    }
    if (APP_CONFIG.store_setting.section.order.show_menu_auto_cancel_order) {
      menu.push(cancelOrderAutomation)
    }
    if (APP_CONFIG.store_setting.section.order.show_menu_ship_before_pay_and_cod) {
      menu.push(shipBeforePayAndCod)
    }
    if (APP_CONFIG.store_setting.section.order.show_menu_order_note_settings) {
      menu.push(orderNote)
    }

    return menu
  }

  _configMenuSectionProduct = () => {
    const menu = []
    const menuCategory = {
      labelKey: 'MenuCategory',
      navAction: NavActions.navToCategoryListViewContainer({ mode: 'VIEW' }),
      icon: { name: 'view-grid', family: 'MaterialCommunityIcons', style: { paddingTop: 2 } },
    }
    const menuVolumeDiscount = {
      labelKey: 'MenuVolumeDiscount',
      navAction: NavActions.navToStoreSettingVolumeDiscount,
      iconImage: { name: PULL_VOLUMN_DISCOUNT_ICON_IMAGE, style: { paddingTop: 2 } },
    }
    const menuStoreProductSN = {
      labelKey: 'MenuStoreProductSN',
      navAction: NavActions.navToStoreSettingStoreProductSN,
      icon: { name: 'barcode-scan', family: 'MaterialCommunityIcons' },
    }

    if (APP_CONFIG.store_setting.section.product.show_menu_category_manage) {
      menu.push(menuCategory)
    }
    if (APP_CONFIG.store_setting.section.product.show_menu_volume_discount) {
      menu.push(menuVolumeDiscount)
    }
    if (APP_CONFIG.store_setting.section.product.show_menu_sku_upc) {
      menu.push(menuStoreProductSN)
    }

    return menu
    // { labelKey: 'MenuStorePrice', navAction: NavActions.navToStoreSettingStorePrice },
  }

  _configMenuSectionWarehouse = () => {
    const menu = []
    const menuWarehose = {
      labelKey: 'MenuWarehouseList',
      navAction: NavActions.navToWarehouseList({ store_id: null }),
      icon: { name: 'home-group', family: 'MaterialCommunityIcons', style: { paddingTop: 2 } },
    }

    // if (APP_CONFIG.store_setting.section.warehouse.show_menu_warehouse_list) {
    //   menu.push(menuWarehose)
    // }

    return menu
    // { labelKey: 'MenuStorePrice', navAction: NavActions.navToStoreSettingStorePrice },
  }

  _configMenuSectionShipping = () => {
    const menu = []
    const menuShipping = {
      labelKey: 'MenuShowShippingType',
      navAction: NavActions.navToStoreSettingShippingTypeView({ onPressGoBack: null }),
      icon: { name: 'local-shipping', family: 'MaterialIcons', style: { paddingTop: 2 } },
    }

    const menuXShipping = {
      labelKey: 'MenuStoreSettingXshipping',
      navAction: NavActions.navToStoreSettingXShipping({ onPressGoBack: null }),
      icon: { name: 'barcode', family: 'MaterialCommunityIcons', style: { paddingTop: 2 } },
    }

    // if (true) {
    menu.push(menuXShipping)
    // }

    // if (true) {
    menu.push(menuShipping)
    // }

    return menu
    // { labelKey: 'MenuStorePrice', navAction: NavActions.navToStoreSettingStorePrice },
  }

  _configMenuSectionOther = () => {
    const menu = []
    const menuWarehose = {
      labelKey: 'MenuPeakAccount',
      navAction: NavActions.navToOtherList({ store_id: null }),
      icon: { name: 'export', family: 'MaterialCommunityIcons', style: { paddingTop: 0 } },
    }

    // if (true) {
    menu.push(menuWarehose)
    // }

    return menu
    // { labelKey: 'MenuStorePrice', navAction: NavActions.navToStoreSettingStorePrice },
  }
}
