import React from 'react'

import _ from 'lodash'

import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import * as xUtil from 'x/utils/util'
import * as xAcl from 'x/utils/acl'

import dayjs, { Dayjs } from 'dayjs'

// const {
// PRODUCT_STOCK,
// REPORT_VIEW,
// PRODUCT_STOCK_VIEW,
// PRODUCT_STOCK_EDIT,
// PRODUCT_COST,
// PRODUCT_PRICE,
// PRODUCT_WEIGHT,
// PRODUCT_SHIPPING_QTY_RATE,
// PRODUCT_CODE_EDIT_SKU,
// PRODUCT_CODE_EDIT_UPC,
// } = CONS.PERM_STORE_HELPER

interface XBaseReportPaymentProps {
  // visible: boolean
  onRequestClose: () => void
  allowOnlyDaysFromToday: number
  store_id: number | string
  fetchAccounts: Function
  // dateRangePicker: React.ComponentClass<IDateRangePickerProps, IDateRangePickerState>
  handleOnDownloadFile: (url: string, fileName: string, type: string) => void
}

interface XBaseReportPaymentState {
  selectedDateRange: { begin: Dayjs; end: Dayjs }
  selectedOptSegment: number
  warningDateRangeText: string
  loading: boolean
  haveBankAccounts: boolean
  bankAccounts: IAccount[]
  downloadingReport: boolean
}

export interface IAccount {
  id: number
  name: string
  account_number: number
  bank_id: number
  selected?: boolean
}

export default abstract class BaseReportPayment extends React.Component<XBaseReportPaymentProps, XBaseReportPaymentState> {
  DATE_RANGE_REPORT_PAYMENT: string[]

  inProcess: boolean

  abstract _handleOnDownloadFile(url: string, fileName: string): Promise<void>

  constructor(props: XBaseReportPaymentProps) {
    super(props)
    this.state = {
      selectedDateRange: { begin: dayjs(), end: dayjs() },
      selectedOptSegment: 0,
      warningDateRangeText: '',
      loading: false,
      haveBankAccounts: false,
      bankAccounts: null,
      downloadingReport: false,
    }
    this.DATE_RANGE_REPORT_PAYMENT = ['วันนี้', '7 วัน', 'เดือนที่แล้ว', 'ระบุ']
    this.inProcess = false
  }

  async componentDidMount() {
    const warningDateRangeText = this._reportwarningTextSetup()
    xUtil.setStatePromise(this, {
      warningDateRangeText,
    })
    await this._fetchBankAccounts()
  }

  // componentDidUpdate = async (prevProps: XBaseReportPaymentProps, prevState: XBaseReportPaymentState) => {
  //   if (this.props.visible !== prevProps.visible) {
  //     if (this.props.visible && !prevState.haveBankAccounts) {
  //       await this._fetchBankAccounts()
  //     }
  //   }
  // }

  _onSeleted = (data: IAccount) => {
    const { bankAccounts } = this.state
    const newBankAccounts = []
    bankAccounts.map((acc: IAccount) => {
      if (data.id === acc.id) {
        if (_.isNil(acc.selected) || !acc.selected) {
          acc.selected = true
        } else {
          acc.selected = false
        }
      }
      newBankAccounts.push(acc)
    })
    xUtil.setStatePromise(this, { bankAccounts: newBankAccounts })
  }

  _getBankName = (data: IAccount) => {
    const { bank_id } = data
    const bankName = _.find(CONS.BANK_INFO, (bank) => bank.id === bank_id)
    if (_.isNil(bankName)) {
      return ''
    }
    let name = p.op.t(`Bank.${bankName.key}`)
    if (!_.isNil(bankName)) {
      if (_.isNil(name)) {
        name = ''
      }
    } else {
      name = ''
    }
    return name
  }

  _getBankKeyName = (data: IAccount) => {
    const { bank_id } = data
    const bankName = _.find(CONS.BANK_INFO, (bank) => bank.id === bank_id)
    if (_.isNil(bankName)) {
      return ''
    }
    let name = bankName.key
    if (!_.isNil(bankName)) {
      if (_.isNil(name)) {
        name = ''
      }
    } else {
      name = ''
    }
    return name
  }

  _getUserBankName = (data: IAccount) => {
    let { name } = data
    const { bank_id } = data
    if (bank_id === 25 || bank_id === 27) {
      name = ''
    }
    if (_.isNil(data.name)) {
      name = ''
    }
    return name
  }

  _getBankAccountNumber = (data: IAccount) => {
    let name = `${data.account_number}`
    const { bank_id } = data
    if (bank_id === 25 || bank_id === 27) {
      name = ''
    }
    if (_.isNil(data.account_number)) {
      name = ''
    }
    return name
  }

  _fetchBankAccounts = async () => {
    const { fetchAccounts, store_id } = this.props
    if (!_.isFunction(fetchAccounts)) {
      return
    }
    await xUtil.setStatePromise(this, { loading: true })
    let respone = null
    await new Promise((resolve) => {
      fetchAccounts({
        store_id,
        successCallback: (res) => {
          respone = res
          // @ts-ignore
          resolve(null)
        },
        failedCallback: resolve,
      })
    })
    let bankAccounts = null
    if (respone.payment_accounts) {
      bankAccounts = respone.payment_accounts
    }
    await xUtil.setStatePromise(this, { haveBankAccounts: true, loading: false, bankAccounts, downloadingReport: false })
  }

  _reportwarningTextSetup = () => {
    const { allowOnlyDaysFromToday } = this.props
    const owner = xAcl.isSelectedStoreOwner()
    const r_shpg_day = allowOnlyDaysFromToday
    const howDay = `แพ็กเกจของคุณดูย้อนหลังได้ ${r_shpg_day} วัน`
    let text = 'แพ็กเกจของคุณไม่สามารถใช้งานฟีเจอร์นี้ได้'
    if (owner) {
      if (!xAcl.packFree()) {
        text = howDay
      }
    } else {
      const canDo = xAcl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.REPORT_PAYMENT, CONS.REPORT_SET_BIT_BINARY.report_payment)
      if (xUtil.isStoreOwner() && !xAcl.packFree()) {
        text = `คุณไม่ได้รับสิทธิในการใช้งานฟีเจอร์นี้`
        return text
      }
      if (canDo) {
        text = howDay
      } else {
        text = `คุณไม่ได้รับสิทธิในการใช้งานฟีเจอร์นี้`
      }
    }

    return text
  }

  _onChangeDateRange = async (newDates: { begin: Dayjs; end: Dayjs }) => {
    xUtil.setStatePromise(this, { selectedDateRange: newDates })
  }

  _onChangeSegmentBaseReportPayment = async (newIndex: number) => {
    const { selectedOptSegment, selectedDateRange } = this.state
    const newSelectedDateRange = selectedDateRange
    if (selectedOptSegment === newIndex) {
      return
    }
    let startDate = dayjs()
    let endDate = dayjs()
    switch (newIndex) {
      case 1:
        startDate = dayjs().subtract(6, 'days')
        break
      case 2:
        startDate = dayjs().subtract(1, 'months').startOf('month')
        endDate = dayjs().subtract(1, 'months').endOf('month')
        break
      default:
        break
    }
    newSelectedDateRange.begin = startDate
    newSelectedDateRange.end = endDate
    await xUtil.setStatePromise(this, {
      selectedOptSegment: newIndex,
      selectedDateRange: newSelectedDateRange,
    })
  }

  _seleteChooseOtp = (key: string, value: boolean) => {
    xUtil.setStatePromise(this, {
      [key]: !value,
    })
  }

  _downloadHistoryReport = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    await xUtil.setStatePromise(this, { downloadingReport: true })

    const { selectedDateRange, bankAccounts, loading } = this.state
    const { store_id } = this.props
    if (loading) {
      this.inProcess = false
      await xUtil.setStatePromise(this, { downloadingReport: false })
      return
    }
    // const canDo = xAcl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.REPORT_PAYMENT, CONS.REPORT_SET_BIT_BINARY.report_payment)
    // if (!canDo) {
    //   p.op.alert('', 'คุณไม่มีสิทธิ์ดูรายงานตัวนี้')
    //   this.inProcess = false
    //   await xUtil.setStatePromise(this, { downloadingReport: false })
    //   return
    // }
    // const isAuthenticated = xAcl.isSelectedStoreOwner()
    // if (!isAuthenticated) {
    //   p.op.alert('ไม่สามารถดูรายงานได้', 'คุณไม่มีสิทธิ์ดูรายงานตัวนี้')
    //   this.inProcess = false
    //   await xUtil.setStatePromise(this, { downloadingReport: false })
    //   return
    // }

    // STOPS download if a store owner with free package wants to load report
    // if (xUtil.isPackFreeAndSelectedStoreOwner()) {
    //   p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
    //   this.inProcess = false
    //   await xUtil.setStatePromise(this, { downloadingReport: false })
    //   return
    // }
    // ถ้าเป็น IOS และเข้าเงื่อนไขก็ปล่อยผ่าน // https://app.clickup.com/t/86cvy21qt
    const isNoobIos = xUtil.isIosNoobCheckByPass()
    if (!isNoobIos && xUtil.isPackFreeAndSelectedStoreOwner()) {
      p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
      this.inProcess = false
      await xUtil.setStatePromise(this, { downloadingReport: false })
      return
    }

    // STOPS download if not a store owner nor helper with permission
    if (!xAcl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.REPORT_PAYMENT)) {
      p.op.alert('ไม่สามารถดูรายงานได้', 'คุณไม่มีสิทธิ์ดูรายงานตัวนี้')
      this.inProcess = false
      await xUtil.setStatePromise(this, { downloadingReport: false })
      return
    }

    const arr = []
    if (_.isNil(bankAccounts) || bankAccounts.length < 1) {
      p.op.alert('กรุณาเลือกบัญชี', 'กรุณาเลือกอย่างน้อย 1 บัญชี')
      this.inProcess = false
      await xUtil.setStatePromise(this, { downloadingReport: false })
      return
    }
    const keepBankAccounts = []
    bankAccounts.map((acc: IAccount) => {
      if (acc.selected) {
        arr.push(acc.id)
        keepBankAccounts.push(acc)
      }
    })
    // console.log('Seleted => ', arr)

    // if (!canDo) {
    //   p.op.t('คุณไม่ได้รับสิทธิในการใช้งานฟีเจอร์นี้')
    //   this.inProcess = false
    //   return
    // }

    // if (xAcl.packFree()) {
    //   p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
    //   this.inProcess = false
    //   await xUtil.setStatePromise(this, { downloadingReport: false })
    //   return
    // }

    const startDate = selectedDateRange.begin.format('YYYY-MM-DD')
    const endDate = selectedDateRange.end.format('YYYY-MM-DD')
    if (arr.length < 1) {
      p.op.alert('กรุณาเลือกบัญชี', 'กรุณาเลือกอย่างน้อย 1 บัญชี')
      this.inProcess = false
      await xUtil.setStatePromise(this, { downloadingReport: false })
      return
    }
    const url = xUtil.getReportPaymentExcelUrl({
      store_id,
      startDate,
      endDate,
      accounts: arr,
    })
    // console.log('URL : ', url)
    const now = dayjs().format('YYYYMMDD-hhmmss')
    let bankName = `${keepBankAccounts.length} accounts`
    if (keepBankAccounts.length < 2) {
      bankName = this._getBankKeyName(keepBankAccounts[0])
      if (keepBankAccounts[0].bank_id === 27) {
        bankName = 'SCB_QR_CODE'
      }
    }

    let fileName = `${bankName}_${now}`

    fileName = `${fileName}.xlsx`
    await this._handleOnDownloadFile(url, fileName)
    p.op.aLogEvent(CONS.EVENT_NAME.REPORT_PRODUCT_PAYMENT, { s: store_id })
    // await p.op.storageSet(CONS.STORAGE_KEYS.REPORT_PRODUCT_DAILY_SALES, keepStore)
    await setTimeout(() => {
      this.inProcess = false
    }, 500)
    // await xUtil.delay(3000)
    await xUtil.setStatePromise(this, { downloadingReport: false })
  }
}
