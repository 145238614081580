import React from 'react'
// import PropTypes from 'prop-types'
import _ from 'lodash'
import * as xFmt from 'x/utils/formatter'
import { isImmutable, Map, List, fromJS } from 'immutable'
import { lastUpdatedSubscription, appGlobalMethods, flowDone } from 'x/utils/global'
import { APP_CONFIG } from 'x/config/mode'
import XFaqModal from 'xui/components/XFaqModal'
import { Moment } from 'moment'
import xCONS from 'x/config/constants'
import api from 'x/utils/api'
import * as xUtil from 'x/utils/util'
import XFlatPicker from 'xui/components/XFlatPicker'
import { IAddressList } from 'xui/components/XAddressesListOverlay'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'
import HStack from 'xui/components/HStack'
import XIcon from 'xui/components/XIcon'
import XText from 'xui/components/XText'
import { TouchableOpacity } from 'react-native'
import * as NavActions from '../../utils/navigation'
import { getCreateToken } from '../../utils/file'
import {
  IBaseOrderViewProps,
  IBaseOrderViewState,
  IOrderViewParams,
  ICustomCreateOrder,
  IQuickAddress,
  IAddress,
  IMap,
  ISelectedAddresses,
  IXShippingTracking,
  IMKPChannelDetail,
  ITimeSlot,
  IOnSubmitOverlayEditTime,
  IOrderTemplate,
  IProductDetailItem,
  IAddStoreProductSelectorOptions,
  IOrderErpChannels,
  IOrder,
} from '../../types'
import { log, setStatePromise, getFocusedOrder, delay, parseSmartFloat, parseSmartInt } from '../../utils/util'
import actions from '../../config/actions'
import * as acl from '../../utils/acl'
import * as util from '../../utils/util'
import p from '../../config/platform-specific'
import CONS from '../../config/constants'

const moment = require('moment')
require('moment/locale/th')
require('moment-timezone')

moment.locale('th')

const { ORDER_VIEW_MODE, PERM_STORE_HELPER, PERM_STORE_OWNER_ONLY } = CONS
const { VIEW_EDIT, VIEW_ONLY, CREATE, EDIT } = ORDER_VIEW_MODE

const KEY_PAPREANG_BILL = 'KEY_PAPREANG_BILL'
const KEY_PAPERANG_SHIPPING_LABLE = 'KEY_PAPERANG_SHIPPING_LABLE'
// const OPTIONS_SHIPPING = ['ไม่ระบุ', 'ระบุ']

const FAQ_ORDER_VIEW = () => {
  const _renderFAQ = (onPressOpenOverlay: () => void) => (
    <TouchableOpacity onPress={() => onPressOpenOverlay()}>
      <HStack px='1' py='2' space='1' alignItems='center'>
        <XIcon name='help-circle-outline' family='Ionicons' variant='active' />
        <XText variant='active'>คำถามพบบ่อย</XText>
      </HStack>
    </TouchableOpacity>
  )
  return (
    <XFaqModal
      key='คำถามพบบ่อย'
      headerTitle='คำถามพบบ่อย'
      // @ts-ignore
      FAQ={FAQ_CONSTANTS.FAQ_ORDER}
      initiateOpenIndex={[0]}
      renderButton={(onPressOpenOverlay: () => void) => _renderFAQ(onPressOpenOverlay)}
    />
  )
}

export default abstract class BaseOrderView extends React.Component<IBaseOrderViewProps, IBaseOrderViewState> {
  // shippingTypeItems: { [key: string]: any }
  ALL_SHIPPING_TYPE_ITEMS: Array<{
    index: number
    right: string // right icon ?
    value: number
    key: string
    tel_required?: boolean
    label: string
  }>

  // channelItems: { [key: string]: any }
  channelItems: Array<{
    index: number
    right: string // right icon ?
    value: number
    key: string
    label: string
  }>

  hamburgerMenuItems: Array<{ [key: string]: any }>

  hamburgerMenuItemsForCreateOrder: Array<{ [key: string]: any }>

  flowName: string

  inProcess: boolean

  isUnmounting: boolean

  isLocalPrinted?: boolean

  inReceivingNewAddress?: boolean

  isSetRefusedAddress?: boolean

  inSelectHamburger?: boolean

  scrollToBottomY?: number // for app

  ORDER_OPTION_KEYS: string[] // TODO: To P'O, do we need this?

  OPTIONS_CHOOSE_PRODUCTS_FROM: string[]

  OPTIONS_CHOOSE_PRODUCTS_FOLLOWING: string[]

  OPTIONS_CHOOSE_SHIPPING_COD: string[]

  OPTIONS_CHOOSE_MINIMUM_FEE: string[]

  OPTIONS_CHOOSE_DCIMAL: string[]

  OPTIONS_CHOOSE_SHIPPING_MKP_TYPE: string[]

  OPTIONS_CHOOSE_SETUP_DEADLINE_ORDER: string[]

  OPTIONS_CHOOSE_SETUP_AUTO_CANCEL_ORDER: string[]

  isNavBackAble: boolean

  _FIRST_TIME_TAB_INIT: boolean

  inputRefs: any[]

  scrollViewRef?: any

  hamburgerMenuRef?: any

  // shipBeforePayAndCodForCreateOrder: boolean

  oldFeeCod: string

  oldCodAmount: string

  moneyType: boolean

  minimumFee: boolean

  keepAddresses: {
    txtReceiverName: string
    txtReceiverAddress: string
    txtSenderName: string
    txtSenderAddress: string
  }

  OPTIONS_CHOOSE_ADDRESSES: string[]

  _shippingTypeCreateModePickerRef: React.RefObject<XFlatPicker<any>>

  _shippingTypeViewModePickerRef: React.RefObject<XFlatPicker<any>>

  dateTimePickerDateDeliveryRef: React.RefObject<any>

  dateTimePickerAutoCancelOrderRef: React.RefObject<any>

  // hamburgerMenuOptions?: { label: string, isDanger?: boolean, onPress: () => void }[];

  // ====== Abstract methods ========
  abstract _getParams(): IOrderViewParams

  // abstract _setParams(params: { [key: string]: any }, callback?: () => void): void
  abstract _cleanupForUnmount(): void

  // abstract _handleErrMissingPostalCode(quickAddress: IQuickAddress): void

  abstract _handleMissingTelReceiver(): Promise<void>

  abstract _handleMissingTelSender(): Promise<void>

  abstract _handleErrMissingShippingType(): void

  abstract _handleErrMissingReceiverAddress(): void

  abstract _handleErrMissingReceiverAddressRequresTel(name: string): void

  abstract _handleErrNotEnoughQty(productName: string, storeId: number, productIdx: number, availableQty: number): void

  abstract _handleErrHasNullProductQty(productName: string, storeId: number, productIdx: number, availableQty?: number): void

  abstract _handleErrProductQtyIsZero(productName: string, productIdx: number, availableQty: number): void

  abstract _handleErrMissingProductId(): void

  abstract _handleErrNewQtyIsMoreThanOpenOrder(productName: string, subIndex: number, pIndex: number): void

  abstract _handleErrCannotEditWhenHasShippingProduct(productName: string, subIndex: number, pIndex: number): void

  abstract _handleErrCannotConfirmWhenProductQtyIsZero(productName: string, subIndex: number, pIndex: number): void

  abstract _handleOnOpenShippingLabelFromUrl(url: string, fileName: string): Promise<void>

  abstract _onOpenPaperangFromUrl(url: string, key: string): Promise<void>

  // abstract _handleOnPressParentOrderId(parentId: number): Promise<boolean>
  abstract _handleOnPressParentOrderId(): Promise<boolean>

  abstract _handleOnPressChildOrderId(childId: number): Promise<boolean>

  abstract _handleAfterSuccessFetchOrderDetail(): Promise<void>

  // FIXME: res actually should be IOrder interface
  abstract _handleAfterSuccessConfirmOrder(res: any): Promise<void>

  abstract _handleAfterSuccessCreateOrder(res: any): Promise<void>
  // abstract _handleOnTabChanged(): Promise<void>
  // abstract _componentDidUpdateExtended(prevProps: IBaseOrderViewProps, prevState: IBaseOrderViewState): Promise<void>

  // abstract _editProductFromBuyOrderFlow(): Promise<void>

  // handle change mode in navigation (or router's params)
  // abstract _changeModeTo(mode: string, callback?: () => void): Promise<void>
  // abstract _willMountExtended(): Promise<void> // inittWillMount extended
  // abstract _consturctorExtended(that: any, props: IBaseOrderViewProps): Promise<void> // in-App / in-Web extended the constructor from base
  // abstract _finishedInitialized(): Promise<void>

  abstract _onPressAutoOperations(): void

  // abstract _forceNativeChangeTabByProgrammatically(): Promise<void>
  abstract beginSubmitting(): Promise<void>

  abstract endSubmitting(): Promise<void>
  // abstract _handleDataIsDirty(): void

  abstract _copyOrderLinkWithDetailToClipboard(shippingLink: string, txtClipboard: string): Promise<void> | void

  abstract _copyLinkOrderToClipboard(shippingLink: string, showToast?: boolean)

  abstract _shareLinkOrderToSocial(res: any)

  abstract _openLinkOrderToWebView(res: any)

  abstract _renderNavOrderTypeOne(): Promise<void>

  abstract _renderNavOrderTypeTwo(): Promise<void>

  // abstract _settingCreateOrder(): void

  abstract _settingOrderView(): void

  protected constructor(props: IBaseOrderViewProps) {
    super(props)
    this.state = {
      // load from nav params
      mode: 'NONE',
      store_id: 0,
      order_id: null,
      isNoInitFetch: false,

      // for check initialize status
      isInitialized: false,

      // In-App usages
      bottomOffset: 0,
      currentRef: null,

      // Common usages
      isHamburgerMenuOpened: false,
      hamburgerMenuOptions: [],
      orderTabIndex: 0,

      submitting: false,
      pickingKey: null,
      refreshing: false,
      showProfit: true, // ไว้ ซ่อนแสดง กำไร

      // For Create mode only
      txtReceiverName: null,
      txtReceiverAddress: null,
      txtBillingName: null,
      txtBillingAddress: null,
      isBillingAddressUsed: false,
      txtSenderName: null,
      txtSenderAddress: null,
      lastShippingCost: null,
      ctoken: getCreateToken(),

      orderTabsVisibility: false,

      isVisibleCameraUPC: false,
      isVisibleCameraSKU: false,
      isVisibleSellerStoreSelector: false,

      optionSelectedSellerStoreIndex: 0,
      optionChooseProductsFromIndex: 0,
      optionChooseProductsFollowingIndex: 0,
      optionChooseProductsByGroupIndex: 0,

      copyLinkOrder: null,

      visibleModelCod: false,
      optionChooseCodFeeCalculationMethodIndex: 0,
      optionChooseRound: 0,
      // feeCalculate: 0,
      optionChooseMinimumFeeFromIndex: 0,
      // amountTotalCod: 0,

      // switchDeliveryBeforePayment: false,
      // switchDeliveryBeforePaymentCod: false,
      storageGetCod: null,
      // refreshState: false,
      // @ts-ignore + FIXME: what type ???
      customCreateOrder: this.props.editingCustomCreateOrder.toJS(),
      // @ts-ignore + FIXME: what type ???
      customOrderView: this.props.selectedCustomOrderView.toJS(),
      onOffXShippingOverlay: false,
      inputOpenForStaffMemberText: '',
      optionChooseAddressesIndex: 0,
      discountDetails: null,
      addressesForOrganization: null,
      widthAndHeightForAddressesOrganization: null,
      isLoadingForAddressesOrganization: true,

      isOpenPrintOptionModel: false,
      optionChooseShippingMKPTypeFromIndex: 0,
      textAddressMKPSelected: '',
      textTimeMKPSelected: '',
      timeSlotMKP: null,
      addressMKPId: null,

      shippingTypeItems: [],
      optionChooseSetupDateDeliveryOrderIndex: 0,
      selectedDateRangeSetUpDateDeliveryOrder: new Date(),
      isShowWraningDateDelivery: false,
      optionChooseSetupAutoCancelOrderIndex: 0,
      selectedDateRangeSetUpAutoCancelOrder: new Date(),
      descriptionAutoCancelOrder: null,
      // STATE FOR PERCENT DISCOINT MODEL
      isVisibleSetModelPercentDiscount: false,

      isVisibleAddressesListOverlay: false,
      addressesListOverlay: null,

      isOpenCODAmountOverlay: false,

      shallCheckForDuplicateReceiverAddr: false,
    }

    this.flowName = actions.ORDER_FETCH
    this.inProcess = false
    this.isUnmounting = false

    this.isNavBackAble = false
    this._FIRST_TIME_TAB_INIT = false
    this.inputRefs = []
    this.scrollViewRef = null
    this.hamburgerMenuRef = null

    // this.shipBeforePayAndCodForCreateOrder = false
    this.oldFeeCod = '0'
    this.oldCodAmount = '0'

    this.moneyType = false
    this.minimumFee = false

    // this._getParams = this._getParams.bind(this)
    // this._onRefresh = this._onRefresh.bind(this)
    // this._fetchOrderDetail = this._fetchOrderDetail.bind(this)
    // this._isAutoCompleteOrderOpAllowed = this._isAutoCompleteOrderOpAllowed.bind(this)
    // this._handleOrderOptionChange = this._handleOrderOptionChange.bind(this)
    // this._calcShippingCost = this._calcShippingCost.bind(this)
    // this._shouldRenderCalcShippingCostBtn = this._shouldRenderCalcShippingCostBtn.bind(this)
    // this._onReceiveNewAddress = this._onReceiveNewAddress.bind(this)
    // this._onPickNewAddressToPatch = this._onPickNewAddressToPatch.bind(this)

    // this._getShippingTypeLabel = this._getShippingTypeLabel.bind(this)
    // this._openShippingLabelPDF = this._openShippingLabelPDF.bind(this)
    // this._getOrderChannelLabel = this._getOrderChannelLabel.bind(this)
    // this._getStoreNameFromStoreId = this._getStoreNameFromStoreId.bind(this)
    // this._getMainButtonsProperties = this._getMainButtonsProperties.bind(this)

    // Manual bind because arrow function is not fast enough for base's constructor
    // this._editProductFromBuyOrderFlow = this._editProductFromBuyOrderFlow.bind(this)

    this.ALL_SHIPPING_TYPE_ITEMS = CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.map((item) => ({
      key: item.index,
      ...item,
      label: p.op.t(`Order.shippingTypeItems.${item.key}`),
    }))

    this.channelItems = CONS.ORDER_VIEW_CHANNEL_ITEMS.map((item) => ({
      key: item.index,
      ...item,
      label: p.op.t(`Order.channelItems.${item.key}`),
    }))

    // this.ORDER_OPTION_KEYS = ['auto_complete', 'order_use_retail_price', 'channel_id']
    this.ORDER_OPTION_KEYS = ['auto_complete', 'channel_id']

    this.hamburgerMenuItems = [
      {
        key: 'auto_complete',
        label: 'รับชำระ/จัดส่ง อัตโนมัติ',
        onPress: () => this._onPressAutoOperations(),
        icon: { name: 'flash-auto', family: 'MaterialIcons' },
        perm: PERM_STORE_HELPER.PAYMENT_ADD, // TODO: Use better permission?
        // visibleCondition: order => !this.isOrderInCancelled(order) && this._canAutoOperations(order),
        visibleCondition: this._canAutoOperations,
      },
      {
        key: 'auto_complete_payment',
        label: 'บันทึกรับชำระอัตโนมัติ',
        icon: { name: 'cash', family: 'Ionicons' },
        onPress: this._onPressAutoFinishPayment,
        perm: PERM_STORE_HELPER.PAYMENT_ADD,
        visibleCondition: this._orderCanAutoFinishPayment,
      },
      {
        key: 'auto_complete_shipping',
        label: 'จัดส่งอัตโนมัติ',
        icon: { name: 'local-shipping', family: 'MaterialIcons' },
        onPress: this._onPressAutoFinishShipping,
        perm: PERM_STORE_HELPER.SHIPPING_ADD,
        visibleCondition: this._orderCanAutoFinishShipping,
      },
      // {
      //   key: 'print_shipping',
      //   label: 'พิมพ์ใบปะหน้าพัสดุ',
      //   onPress: this._openShippingLabelPDF,
      //   perm: PERM_STORE_HELPER.ORDER_PRINT_SHIPPING,
      //   visibleCondition: order => this._canPrintShippingLabel(order),
      // },
      {
        key: 'edit_order',
        label: 'แก้ไขข้อมูลออเดอร์',
        icon: { name: 'edit', family: 'AntDesign' },
        onPress: this._changeToEditMode,
        perm: PERM_STORE_HELPER.ORDER_EDIT,
        visibleCondition: (order, mode) => _.includes([1, 2], order.get('type')) && this._canEditOrder(order, mode),
      },
      {
        key: 'edit_products',
        label: 'แก้ไขสินค้าและจำนวน',
        icon: { name: 'edit', family: 'AntDesign' },
        onPress: this._editProductFromBuyOrderFlow,
        perm: PERM_STORE_HELPER.ORDER_EDIT,
        visibleCondition: (order, mode) => order.get('type') === 3 && this._canEditOrder(order, mode),
      },
      {
        key: 'edit_etc',
        label: 'แก้ไขค่าส่ง/ส่วนลด/ค่าอื่นๆ',
        icon: { name: 'edit', family: 'AntDesign' },
        onPress: this._changeToEditMode,
        perm: PERM_STORE_HELPER.ORDER_EDIT,
        visibleCondition: (order, mode) => order.get('type') === 3 && this._canEditOrder(order, mode),
      },
      {
        key: 'clone_order',
        label: 'สร้างออเดอร์ใหม่\nโดยใช้ข้อมูลออเดอร์นี้',
        icon: { name: 'duplicate-outline', family: 'Ionicons' },
        onPress: this.cloneOrder,
        perm: PERM_STORE_HELPER.ORDER_ADD,
        visibleCondition: (order, mode) => _.includes([1], order.get('type')),
      },
      {
        key: 'show_profit',
        label: 'แสดงกำไรค่าสินค้า',
        onPress: this._handleShowProfit,
        icon: { name: 'currency-usd', family: 'MaterialCommunityIcons' },
        perm: PERM_STORE_HELPER.PRODUCT_COST,
        visibleCondition: (order) => _.includes([1, 2], order.get('type')) && !this._isShowProfit(),
      },
      {
        key: 'hide_profit',
        label: 'ซ่อนกำไรค่าสินค้า',
        icon: { name: 'currency-usd-off', family: 'MaterialCommunityIcons' },
        onPress: this._handleHideProfit,
        perm: PERM_STORE_HELPER.PRODUCT_COST,
        visibleCondition: (order) => _.includes([1, 2], order.get('type')) && this._isShowProfit(),
      },
      {
        key: 'lock_order',
        label: 'ล็อกออเดอร์นี้',
        onPress: this._handleLockOrder,
        icon: { name: 'lock', family: 'MaterialCommunityIcons' },
        perm: PERM_STORE_HELPER.ORDER_EDIT,
        visibleCondition: (order) => _.includes([1, 2], order.get('type')) && !this._isLockedOrder(order),
      },
      {
        key: 'unlock_order',
        label: 'ปลดล็อกออเดอร์นี้',
        icon: { name: 'lock-open-variant', family: 'MaterialCommunityIcons' },
        onPress: this._handleUnlockOrder,
        perm: PERM_STORE_HELPER.ORDER_EDIT,
        visibleCondition: (order) => _.includes([1, 2], order.get('type')) && this._isLockedOrder(order),
      },
    ]

    if (APP_CONFIG.order.view_order.show_3dots_menu_item_custom_fields_setting_view) {
      this.hamburgerMenuItems.push({
        key: 'custom_order_view',
        label: 'ตั้งค่าหน้า ดูออเดอร์',
        icon: { name: 'settings', family: 'Ionicons' },
        onPress: this._nevToSettingOrderView,
        // perm: PERM_STORE_HELPER.CUSTOM_ORDER_VIEW,
        visibleCondition: () => true,
        // visibleCondition: (order, mode) => this._canEditOrder(order, mode),
        // visibleCondition: true,
      })
    }
    // this.hamburgerMenuItems.push({
    //   key: 'order_copy_template_setting',
    //   label: 'ตั้งค่าการคัดลอก รายละเอียดออเดอร์',
    //   onPress: this.navToOrderCopyTemplateView,
    //   perm: PERM_STORE_HELPER.CUSTOM_ORDER_VIEW,
    //   visibleCondition: (order, mode) => this._canEditOrder(order, mode),
    //   // visibleCondition: order => _.includes([1, 2], order.get('type')) && this._isLockedOrder(order),
    // })

    this.hamburgerMenuItems.push({
      key: 'faq',
      label: 'คำถามพบบ่อย',
      icon: { name: 'help-circle-outline', family: 'Ionicons' },
      onPress: this._nevToSettingOrderView,
      // perm: PERM_STORE_HELPER.CUSTOM_ORDER_VIEW,
      visibleCondition: () => true,
      // visibleCondition: (order, mode) => this._canEditOrder(order, mode),
      // visibleCondition: true,
      customRander: () => FAQ_ORDER_VIEW(),
    })

    this.hamburgerMenuItems.push({
      key: 'cancel_order',
      label: 'ยกเลิกออเดอร์',
      icon: { name: 'cancel', family: 'MaterialCommunityIcons' },
      onPress: this._doCancelOrder,
      perm: PERM_STORE_HELPER.ORDER_CANCEL,
      visibleCondition: (order, mode) => this._canCancelOrder(order, mode),
      isDanger: true,
    })

    this.hamburgerMenuItemsForCreateOrder = [
      {
        key: 'custom_create_order',
        label: 'ตั้งค่าหน้า สร้างออเดอร์',
        icon: { name: 'settings', family: 'Ionicons' },
        onPress: this._navToSettingCreateOrder,
        perm: true, // TODO: Use better permission?
        // visibleCondition: order => !this.isOrderInCancelled(order) && this._canAutoOperations(order),
        visibleCondition: true,
      },
    ]

    this.OPTIONS_CHOOSE_PRODUCTS_FROM = ['ร้านผู้ขายส่ง', 'ร้านฉัน']
    this.OPTIONS_CHOOSE_PRODUCTS_FOLLOWING = ['กลุ่มสมาชิก', 'รายการราคา']

    this.OPTIONS_CHOOSE_SHIPPING_COD = ['บาท', '%']
    this.OPTIONS_CHOOSE_MINIMUM_FEE = ['ไม่มีขั้นต่ำ', 'มีขั้นต่ำ']
    this.OPTIONS_CHOOSE_DCIMAL = ['ปัดขึ้น', 'ปัดลง', 'ไม่ปัด']
    this.OPTIONS_CHOOSE_ADDRESSES = [`ใช้ที่อยู่หลัก`, `ใช้ที่อยู่รอง`]
    this.OPTIONS_CHOOSE_SHIPPING_MKP_TYPE = ['นัดรับ', 'Dropoff']
    this.OPTIONS_CHOOSE_SETUP_DEADLINE_ORDER = ['ไม่มีกำหนดส่ง', 'ระบุ']
    this.OPTIONS_CHOOSE_SETUP_AUTO_CANCEL_ORDER = ['ไม่ยกเลิก', 'ระบุ', 'ใช้ตั้งค่าร้าน']

    this._shippingTypeCreateModePickerRef = React.createRef()
    this._shippingTypeViewModePickerRef = React.createRef()
    this.dateTimePickerDateDeliveryRef = React.createRef()
    this.dateTimePickerAutoCancelOrderRef = React.createRef()
    // this._consturctorExtended(this, props)
  }

  // componentWillMount() {
  //   // // Check Order Subscription
  //   // this._orderSubscriptionTracking()
  //   //
  //   // const { selectedStore, fetchMyStore, sellerStoresShouldFetch, fetchSellerStores } = this.props
  //   // const { store_id, order_id } = this._getParams()
  //   // log('componentWillMount order_id => ', order_id)
  //   // const mySelectedStoreId = selectedStore.get('id')
  //   // const hasMySelectedStoreId = _.isNumber(mySelectedStoreId) && mySelectedStoreId > 0
  //   //
  //   // // Checking selectedStore If not flow access should fetch before init order
  //   // if (!hasMySelectedStoreId || sellerStoresShouldFetch) {
  //   //   const body = { store_id }
  //   //   const fetchSellerStoreFunc = () => fetchSellerStores({ successCallback: this._initWillMount })
  //   //   if (!hasMySelectedStoreId && !sellerStoresShouldFetch) {
  //   //     fetchMyStore({ body, successCallback: this._initWillMount })
  //   //   } else if (!hasMySelectedStoreId && sellerStoresShouldFetch) {
  //   //     fetchMyStore({ body, successCallback: fetchSellerStoreFunc })
  //   //   } else { // hasMySelectedStore && !sellerStoresShouldFetch
  //   //     fetchSellerStoreFunc()
  //   //   }
  //   // } else {
  //   //   this._initWillMount()
  //   // }
  // }
  async componentDidMount(): Promise<void> {
    await this.doInit()
  }

  doInit = async () => {
    await this._setShippingTypeItems()
    await this._loadParamsFromNavToState()
    await this._initializeDepdencencyData()
    await this._initializeDefaultValueByMode()
    await this._updateUGData()
    await this._setCustomCreateOrder()
    await this._updateHamburgerMenuOptions()
    await this._setShippingMKPData()
    await this._setDateTimeDateDeliverViewEditMode()
    await this._setDateTimeAutoCancelOrder()

    if (APP_CONFIG.order.create_order.show_receiver_address_index_segment_btn) {
      await this._setAddressesForOrganization()
    }

    // ดึงค่าที่จำจากคำนวณ cod
    let storageGetCod = null
    await p.op.storageGet(CONS.STORAGE_KEYS.COD_CALCULATOR).then((val: any) => {
      storageGetCod = val
    })
    // console.log('storageGetCod', storageGetCod)

    if (this && !this.isUnmounting) {
      this.isNavBackAble = true
    }
    flowDone(this.flowName)
    // await setStatePromise(this, { isInitialized: true })
    // await this._finishedInitialized()

    // const { mode } = this.state
    const focusedOrder = this._getFocusedOrder()
    // if (mode !== CREATE) {
    //   util.setStatePromise(this, { switchDeliveryBeforePayment: focusedOrder.get('ship_before_pay') || false })
    //   util.setStatePromise(this, { switchDeliveryBeforePaymentCod: focusedOrder.get('is_cod') || false })
    // }
    this.oldFeeCod = focusedOrder.get('cod_fee') || '0'
    this.oldCodAmount = focusedOrder.get('cod_amount') || '0'
    await util.setStatePromise(this, { storageGetCod })

    // onEditingOrderChange({ key: 'gen_tracking_codes', value: tackingCodes, orderTabIndex })
    // setTimeout(() => {
    // await util.delay(2500)
    // await util.setStatePromise(this, { isInitialized: true, storageGetCod })
    // }, 100)
    this.props.setIsInitialized(true)
  }

  // _doAsyncComponentDidMount = async (): Promise<void> => {
  //   await this._loadParamsFromNavToState()
  //   await this._initializeDepdencencyData()
  //   await this._initializeDefaultValueByMode()
  //   await this._updateHamburgerMenuOptions()
  //   await new Promise(initDelay => setTimeout(initDelay, 1500))
  //   if (this && !this.isUnmounting) {
  //     this.isNavBackAble = true
  //   }
  //   flowDone(this.flowName)
  // }

  async componentDidUpdate(prevProps: IBaseOrderViewProps, prevState: IBaseOrderViewState): Promise<void> {
    const { mode, showProfit, orderTabIndex, pickingKey, m = null, isInitialized, refreshing } = this.state
    const { selectedAddress, editingOrder, selectedOrder, seekSelectedAddress, onChangeAddress, shouldFetch } = this.props
    // const currentMode = navigation && navigation.state.params ? navigation.state.params.mode : null
    const isTabChanged = mode && mode !== CREATE && prevState.orderTabIndex !== orderTabIndex
    const isSelectedOrderChanged = prevProps.selectedOrder !== selectedOrder
    const isProfitChanged = prevState.showProfit !== showProfit
    // const isFinishedInitialized = (!prevState.isInitialized && isInitialized) || (prevState.isInitialized && isInitialized)
    const isRefreshed = !prevState.refreshing && refreshing
    const isModeChanged = prevState.mode !== mode

    // if (mode && mode !== CREATE && prevProps.orderTabIndex !== orderTabIndex) {
    //   // TODO: Update Header Hamburger Menu by OrderId ?
    //   await this._updateHamburgerMenuOptions()
    // }
    if (isTabChanged || isSelectedOrderChanged || isProfitChanged || isModeChanged) {
      await this._updateUGData()
      await this._updateHamburgerMenuOptions()
      await this._setNewShowProfit()
    }

    // if (isSelectedOrderChanged) {
    //   await this._forceNativeChangeTabByProgrammatically()
    // }

    // FIXME: Should deprecated these two conditions and use callback instead. (For Stability)
    // Waiting for receive selected Address and destroy it!
    if (prevProps.selectedAddress !== selectedAddress) {
      // log(this, 'componentDidUpdate selectedAddress => : ', selectedAddress.toJS())
      if (isImmutable(selectedAddress) && selectedAddress.get(pickingKey)) {
        // Quick Fixed for avoid re-update same selectedAddress again after failed validation
        if (this.isSetRefusedAddress) {
          this.isSetRefusedAddress = false
          return
        }
        this._onReceiveNewAddress(pickingKey, selectedAddress.get(pickingKey))
      }
    }

    if (prevProps.seekSelectedAddress !== seekSelectedAddress) {
      // log(this, 'componentDidUpdate seekSelectedAddress => : ', seekSelectedAddress.toJS())
      if (pickingKey) {
        const newAddress = {}
        newAddress[pickingKey] = seekSelectedAddress
        onChangeAddress({ ...newAddress })
      }
    }

    // For should refresh order detail
    if (!prevProps.shouldFetch && shouldFetch) {
      // Clear Refs
      await this._onRefresh(false)
    }

    // if (isTabChanged || isSelectedOrderChanged || isFinishedInitialized || isRefreshed) {
    //   this._handleOnTabChanged()
    // }

    // if (_.isFunction(this._componentDidUpdateExtended)) {
    //   await this._componentDidUpdateExtended(prevProps, prevState)
    // }

    // if (!isFinishedInitialized) {
    //   // Force done initialize if have current order loaded
    //   const focusedOrder = this._getFocusedOrder()
    //   if (Map.isMap(focusedOrder) && focusedOrder.get('updated_at')) {
    //     this.setState({ isInitialized: true })
    //   }
    // }

    if (this.props.isInitialized && !this.state.isInitialized) {
      this.setState({ isInitialized: true })
    }

    // if (prevProps.isDirty !== this.props.isDirty && this.props.isDirty) {
    //   this._handleDataIsDirty() // แจ้งว่าข้อมูล dirty
    // }
  }

  componentWillUnmount(): void {
    if (!this.isUnmounting) {
      this.isUnmounting = true
      // this.props.resetOrder()
      this._cleanupForUnmount()
    }
  }

  isGoBackButtonHidden = (): boolean => {
    const { navigation } = this.props
    return util.getNavParam(this.props, 'isGoBackButtonHidden', false)
  }

  _setShippingTypeItems = async () => {
    const VISIBLE_SHIPPING_TYPES_IDS = util.getVisibleShippingTypes()
    const shippingTypeItems = VISIBLE_SHIPPING_TYPES_IDS.map((item) => ({
      key: item.index,
      ...item,
      label: p.op.t(`Order.shippingTypeItems.${item.key}`),
    }))
    await util.setStatePromise(this, {
      shippingTypeItems,
    })
  }

  _setDateTimeDateDeliverViewEditMode = async (newOrder?: IOrder) => {
    const { mode } = this.state
    if (mode === VIEW_EDIT) {
      // const order = this._getFocusedOrder()
      const order = !_.isNil(newOrder) ? fromJS(newOrder) : this._getFocusedOrder()
      // console.log('order => ', order.toJS())
      const dateDeliver = order.get('date_delivery')
      // console.log('order => ', order.toJS())
      // console.log('dateDeliver => ', dateDeliver)
      if (!_.isNil(dateDeliver)) {
        const dateFormatToDate = util.getDateTimeFormatSupportNewDate(dateDeliver)
        await util.setStatePromise(this, {
          optionChooseSetupDateDeliveryOrderIndex: 1,
          selectedDateRangeSetUpDateDeliveryOrder: dateFormatToDate,
          isShowWraningDateDelivery: false,
        })
      } else {
        await util.setStatePromise(this, {
          optionChooseSetupDateDeliveryOrderIndex: 0,
          isShowWraningDateDelivery: false,
        })
      }
    }
  }

  _setDateTimeAutoCancelOrder = async (newOrder?: IOrder) => {
    // console.log('newOrder => ', newOrder)
    const { mode } = this.state
    if (mode === VIEW_EDIT) {
      this.OPTIONS_CHOOSE_SETUP_AUTO_CANCEL_ORDER = ['ไม่ใช้งาน', 'ระบุ']
      // const order = this._getFocusedOrder()
      const order = !_.isNil(newOrder) ? fromJS(newOrder) : this._getFocusedOrder()
      // console.log('order => ', order.toJS())
      const dateAutoCancelOrder = order.get('expiration_date')
      // console.log('order => ', order.toJS())
      // console.log('dateAutoCancelOrder => ', dateAutoCancelOrder)
      if (!_.isNil(dateAutoCancelOrder)) {
        const dateFormatToDate = util.getDateTimeFormatSupportNewDate(dateAutoCancelOrder)
        // console.log('dateFormatToDate => ', dateFormatToDate)
        await util.setStatePromise(this, {
          optionChooseSetupAutoCancelOrderIndex: 1,
          selectedDateRangeSetUpAutoCancelOrder: dateFormatToDate,
          isShowWraningDateTimeAutoCancelOrder: false,
        })
      } else {
        await util.setStatePromise(this, {
          optionChooseSetupAutoCancelOrderIndex: 0,
          isShowWraningDateTimeAutoCancelOrder: false,
        })
      }
      this._checkDescriptionAutoCancelOrder()
    } else if (mode === CREATE) {
      const { selectedStore } = this.props
      const { customCreateOrder } = this.state
      const autoCancelOrder = customCreateOrder.auto_cancel_order
      const orderExp = selectedStore.get('order_exp')
      const useStoreSetting = _.has(autoCancelOrder, 'm') && autoCancelOrder.m === 'd'
      // ถ้าไม่มีค่าเริ่มต้น ให้เปิดใช้ตามตั้งค่าร้านเสมอ
      if (_.isNil(autoCancelOrder)) {
        this._onSegmentChangeSetUpAutoCancelOrder(2, true)
      } else if (_.isNumber(orderExp) && orderExp !== 0 && useStoreSetting) {
        this._onSegmentChangeSetUpAutoCancelOrder(2, true)
      }
    }
  }

  _checkDescriptionAutoCancelOrder = () => {
    const { selectedStore } = this.props
    let descriptionAutoCancelOrder = null
    const orderExp = selectedStore.get('order_exp')
    // console.log('orderExp => ', orderExp)
    if (_.isNumber(orderExp) && orderExp !== 0) {
      const orderExpToHour = orderExp / 60
      descriptionAutoCancelOrder = `${p.op.t('StoreSetting.MenuStoreCancelOrderAutomation')} ภายใน ${orderExpToHour} ชัวโมง`
    }
    // console.log('descriptionAutoCancelOrder / => ', descriptionAutoCancelOrder)
    util.setStatePromise(this, {
      descriptionAutoCancelOrder,
    })
  }

  _warningCannotEditOnPurchaseOrder = () => {
    p.op.showConfirmationOkOnly('', 'คุณไม่สามารถแก้ไขข้อมูลนี้ในออเดอร์ซื้อได้ หากต้องการเปลี่ยนแปลงข้อมูลนี้ กรุณาติดต่อผู้ขาย')
  }

  validatorOnPressDateDelivery = (): boolean => {
    const order = this._getFocusedOrder()
    const orderLock = this._isLockedOrder(order)
    // if (!util.isStoreOwner()) {
    //   p.op.showConfirmationOkOnly('', p.op.t('warning.no_right'))
    //   return
    // }
    const orderType = order.get('type') ? parseInt(order.get('type')) : 0
    if (orderType === 3) {
      this._warningCannotEditOnPurchaseOrder()
      return false
    }
    if (this.state.mode === EDIT) {
      this._warningCantEditOnEditMode()
      return false
    }
    // if (!util.isStoreOwner()) {
    //   p.op.showConfirmationOkOnly('', p.op.t('warning.no_right'))
    //   return false
    // }
    // 1850900
    if (orderLock) {
      p.op.showConfirmationOkOnly(
        'ออเดอร์ถูกล็อก',
        'หากต้องการแก้ไขวันกำหนดส่ง กรุณาเลือก "ปุ่ม 3 จุด ข้างบนทางขวา" เลือก "ปลดล็อกออเดอร์นี้"'
      )
      return false
    }
    return true
  }

  _setShippingMKPData = async () => {
    const thisOrder = this._getFocusedOrder()
    const hasMKP = thisOrder.has('mkp_id')
    const hasChannelId = thisOrder.has('user_group_id')
    const channels = util.getMKPChannels()
    if (!hasChannelId && !hasMKP && _.isNil(channels)) {
      return null
    }
    const channelId = thisOrder.get('user_group_id')

    const channel = _.find(channels, (ch: IMKPChannelDetail) => ch.id === channelId)

    if (_.isNil(channel)) {
      return null
    }
    const { addresses } = channel
    const address = _.isNil(addresses) ? null : _.find(addresses, (addr: IAddress) => addr.is_primary)
    let addressTextLable = ''
    let addressMKPId = null
    if (!_.isNil(address)) {
      addressTextLable = util.getAddresFormatLable(address)
      addressMKPId = address.address_mapping_uuid
    }
    // const { optionChooseShippingMKPTypeFromIndex } = this.state
    const shippingId = thisOrder.get('shipping_type_id')
    const isShoppeeMKP = thisOrder.get('mkp_id') === 1
    const timeSlots = channel.timeslot_prefs ? channel.timeslot_prefs : null

    const timeSlot = !_.isNil(timeSlots) ? null : _.find(timeSlots, (data: ITimeSlot) => data.shipping_type_id === shippingId)

    const timeSlotTextLable = _.isNil(timeSlot) ? 'ไม่ได้ระบุ' : `${timeSlot.start_time} - ${timeSlot.end_time}`
    let optionChooseShippingMKPTypeFromIndex = 1
    if (isShoppeeMKP) {
      optionChooseShippingMKPTypeFromIndex = 0
    }

    const timeSlotMKP = !_.isNil(timeSlot)
      ? {
          startTime: timeSlot.start_time,
          endTime: timeSlot.end_time,
        }
      : null
    await util.setStatePromise(this, {
      optionChooseShippingMKPTypeFromIndex,
      textAddressMKPSelected: addressTextLable,
      textTimeMKPSelected: timeSlotTextLable,
      timeSlotMKP,
      addressMKPId,
    })
  }

  _setNewShowProfit = async () => {
    const { mode } = this.state
    if (mode === CREATE) {
      return
    }
    const defaultObj = await p.op.getAppDefaultObject()
    let showProfit = false
    const defaultShowProfitKey = 'order_selected_showProfit'
    if (defaultShowProfitKey in defaultObj && !_.isNull(defaultObj[defaultShowProfitKey])) {
      showProfit = defaultObj[defaultShowProfitKey]
    } else {
      // แสดงกำไรเสมอ ถ้ายังไม่เคยเซ็ต flag
      showProfit = true
    }
    await setStatePromise(this, {
      showProfit,
    })
  }

  _setAddressesForOrganization = async () => {
    await util.setStatePromise(this, {
      isLoadingForAddressesOrganization: true,
    })
    const { selectedStore } = this.props
    const addresses = selectedStore.get(`addresses`).toJS()
    const twoAddress = await this._getTwoAddressesForOrganization(addresses)
    await util.setStatePromise(this, {
      addressesForOrganization: twoAddress,
      isLoadingForAddressesOrganization: false,
    })
  }

  _getAddresText = (address: ISelectedAddresses) => {
    let txtAddress = ''
    txtAddress = address.address1
    txtAddress = address.address2 ? `${txtAddress} ${address.address2}` : txtAddress
    txtAddress = address.sub_district ? `${txtAddress} ${address.sub_district}` : txtAddress
    txtAddress = address.district ? `${txtAddress} ${address.district}` : txtAddress
    txtAddress = address.province ? `${txtAddress} ${address.province}` : txtAddress
    txtAddress = address.postal_code ? `${txtAddress}\n${address.postal_code}` : txtAddress
    txtAddress = address.telephone ? `${txtAddress}\nโทร. ${address.telephone}` : txtAddress
    return txtAddress
  }

  // ค้นหาที่อยู่หลักและที่อยู่รอง
  _getTwoAddressesForOrganization = async (addresses: ISelectedAddresses[]) => {
    if (_.isNil(addresses) || addresses.length < 1) {
      return null
    }
    if (addresses.length < 2) {
      addresses[0].is_primary ? this._onSegmentChangeOpenAddresses(0) : this._onSegmentChangeOpenAddresses(1)
      return addresses
    }
    if (addresses.length > 1) {
      let newAddresses = []
      // ที่อยู่หลัก
      const addresOne = []
      // ที่อยู่รอง
      const addressTwo = []
      addresses.map((add: ISelectedAddresses) => {
        if (!_.isNil(add.is_primary) && add.is_primary) {
          addresOne.push(add)
        } else {
          addressTwo.push(add)
        }
      })
      if (addresOne.length > 1 && addressTwo.length > 1) {
        p.op.showConfirmationOkOnly(`เกิดผิดพลาด`, `ที่อยู่ผู้รับไม่ถูกต้อง กรุณาติดต่อทีมงาน`)
        return null
      }
      if (addresOne.length === 1) {
        newAddresses.push(addresOne[0])
      }
      if (addressTwo.length === 1) {
        newAddresses.push(addressTwo[0])
      }
      if (newAddresses.length < 1) {
        newAddresses = null
        p.op.showConfirmationOkOnly(`เกิดข้อผิดพลาด`, `ที่อยู่ผู้รับไม่ถูกต้อง`)
      }
      return newAddresses
    }
  }

  _updateUGData = async () => {
    const { mode } = this.state
    if (mode === CREATE) {
      return
    }
    const order = await this._getFocusedOrder()
    const discountDetails = await util.getDiscountDetails(order)
    await util.setStatePromise(this, {
      discountDetails,
    })
  }

  _editProductFromBuyOrderFlow = async (): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const focusedOrder = this._getFocusedOrder()
    const orderParentId = Map.isMap(focusedOrder) ? focusedOrder.get('parent_id') : null
    const orderParentName = Map.isMap(focusedOrder) ? focusedOrder.get('parent_name') : null
    const orderPreventMod = Map.isMap(focusedOrder) ? focusedOrder.get('prevent_mod') : false
    const orderType = Map.isMap(focusedOrder) ? focusedOrder.get('type') : 0

    // log(this, '_editProductFromBuyOrderFlow focusedOrder.toJS() => ', focusedOrder.toJS())
    if (this._isLockedOrderShowAlert(focusedOrder)) {
      this.inProcess = false
      return
    }

    if (!orderParentId || orderType !== 3) {
      this.inProcess = false
      return
    }

    if (orderPreventMod) {
      p.op.alert('ไม่สามารถแก้ไขรายการสินค้า', 'เนื่องจากผู้ขายส่งทำการล็อกออเดอร์แล้ว\n\nหากต้องการทำการแก้ไขกรุณาติดต่อผู้ขายส่ง')
      this.inProcess = false
      return
    }

    const isUserConfirmed = await new Promise<boolean>((passConfirm) => {
      p.op.showConfirmation(
        'แก้ไขรายการสินค้า',
        `ระบบจะทำการเปิดออเดอร์ขาย #${orderParentId} เพื่อทำการแก้ไขรายการสินค้า\nคุณต้องการดำเนินการ ใช่หรือไม่`,
        () => passConfirm(true),
        () => passConfirm(false),
        'ใช่',
        'ไม่ใช่'
      )
    })

    if (!isUserConfirmed) {
      this.inProcess = false
      return
    }

    // await this._handleOnPressParentOrderId(orderParentId)
    await this._handleOnPressParentOrderId()
    await new Promise((transitionDelay) => setTimeout(transitionDelay, 500))
    let isLoadedOrder = false
    // log(this, '_editProductFromBuyOrderFlow after changedTab isLoadedOrder => ', JSON.stringify(isLoadedOrder))
    for (let i = 1; i <= 6; i++) {
      isLoadedOrder = !!this.props.editingOrder.get('updated_at')
      if (!isLoadedOrder) {
        // not necessary because onRefresh is real synchronous now
        // log(this, 'Waiting for 3 times:: wait 500ms i => ', i)
        await new Promise((delayForLoadOrder) => setTimeout(delayForLoadOrder, 500))
      } else {
        break
      }
    }
    if (isLoadedOrder) {
      const newOrder = this._getFocusedOrder()
      const newOrderType = Map.isMap(newOrder) ? newOrder.get('type') : 0
      const newOrderState = Map.isMap(newOrder) ? newOrder.get('state') : 0

      // if (_.includes([1, 2], newOrderType) && this._canEditOrder(newOrder, VIEW_EDIT)) {
      //   if (newOrderState === 102) {
      //     p.op.alert('คุณสามารถดำเนินการแก้ไข แล้วกดปุ่ม "ยืนยัน" (สีส้มด้านซ้ายล่าง) เมื่อเสร็จสิ้น')
      //     this.inProcess = false
      //     return
      //   }
      //   await setStatePromise(this, { orderTabsVisibility: false, mode: EDIT })
      //   await new Promise(delay => setTimeout(delay, 500))
      //   // await this._changeModeTo(EDIT)
      // } else {
      //   const txtOrderStatus =
      //     newOrderType > 0 && newOrderState > 180
      //       ? p.op.t(`Order.OrderStatesText._${newOrderType}${newOrderState}`)
      //       : null
      //   const txtMessage = txtOrderStatus
      //     ? `เนื่องจากออเดอร์ #${orderParentName} อยู่ในสถานะ ${txtOrderStatus}`
      //     : 'ระบบไม่ทราบสถานะของออเดอร์'
      //   p.op.alert('ไม่สามารถแก้ไขรายการสินค้า', txtMessage)
      // }
      if (this._canEditOrder(newOrder, VIEW_EDIT)) {
        if (_.includes([2, 3], newOrderType) && newOrderState === 101) {
          p.op.alert(p.op.t('Order.Warning.pleaseEditThenConfirm.t'), p.op.t('Order.Warning.pleaseEditThenConfirm.m'))
          this.inProcess = false
          return
        }
        if (_.includes([1, 2], newOrderType) && newOrderState === 102) {
          p.op.alert(p.op.t('Order.Warning.pleaseEditThenConfirm.t'), p.op.t('Order.Warning.pleaseEditThenConfirm.m'))
          this.inProcess = false
          return
        }
        await setStatePromise(this, { orderTabsVisibility: false, mode: EDIT })
        await new Promise((delay) => setTimeout(delay, 500))
        // await this._changeModeTo(EDIT)
      }
    }
    this.inProcess = false
  }

  _changeToEditMode = async (): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    if (_.includes([VIEW_EDIT], this.state.mode)) {
      const focusedOrder = this._getFocusedOrder()
      const isCancelledOrder = this._isOrderCancelled(focusedOrder)
      if (isCancelledOrder) {
        p.op.alert('ออเดอร์ถูกยกเลิกแล้ว', 'ไม่สามารถแก้ไขข้อมูลราคาและสินค้าได้อีก')
        this.inProcess = false
        return
      }
      if (this._isLockedOrderShowAlert(focusedOrder)) {
        this.inProcess = false
        return
      }
      const type = focusedOrder.get('type')
      const state = focusedOrder.get('state')

      if ((type === 1 && state === 102) || (_.includes([2, 3], type) && state === 101)) {
        p.op.alert(p.op.t('Order.Warning.pleaseEditThenConfirm.t'), p.op.t('Order.Warning.pleaseEditThenConfirm.m'))
        this.inProcess = false
        return
      }
    }

    await setStatePromise(this, { orderTabsVisibility: false, mode: EDIT })
    await new Promise((delay) => setTimeout(delay, 500))
    // await this._changeModeTo(EDIT)
    this.inProcess = false
  }

  _changeModeTo = async (mode: string, callback?: () => void | Promise<void>): Promise<void> => {
    await setStatePromise(this, { mode })
    await new Promise((delay) => setTimeout(delay, 300))
    if (_.isFunction(callback)) {
      await callback()
    }
  }

  _loadParamsFromNavToState = async (): Promise<void> => {
    const params = this._getParams()
    const {
      store_id = null, // required
      order_id = null, // optional (on CREATE mode)
      mode = null, // required
    } = params

    if (
      (store_id && order_id && _.includes(ORDER_VIEW_MODE, mode)) || // General case
      (mode === CREATE && store_id)
    ) {
      // Create mode no need order_id
      // @ts-ignore ไม่รู้ว่าทำไม error ทั้งๆ ที่ this ก็เป็น Component
      await setStatePromise(this, params)
      // log(this, '_loadParamsFromNavToState params => ', params)
    } else {
      // log(this, '_loadParamsFromNavToState missing params => ', params)
      p.op.alert('การเข้าถึงไม่ถูกต้อง', 'กรุณาลองใหม่อีกครั้ง')
      // TODO: Call force goBack Method.
    }
  }

  _initializeDepdencencyData = async (): Promise<void> => {
    const { myStores, fetchInitializeData, selectedStore, fetchMyStore } = this.props
    // log(this, '_initializeDepdencencyData => myStores.toJS() ', myStores.toJS())
    if (myStores.size < 1) {
      // แสดงว่ายังไม่ผ่านการ fetch init
      const isInitSuccess = await new Promise((isLoaded) => {
        fetchInitializeData({
          successCallback: () => isLoaded(true),
          failedCallback: () => isLoaded(false),
        })
      })
      if (!isInitSuccess) {
        p.op.alert('โหลดข้อมูลไม่สำเร็จ', 'กรุณาลองใหม่อีกครั้ง')
        // Failed to load initialize data (my stores, seller stores, subscription)
        return
      }
    }

    const { store_id, order_id } = this.state
    // log(this, '_initializeDepdencencyData store_id => ', store_id)
    // log(this, '_initializeDepdencencyData order_id => ', order_id)
    const mySelectedStoreId = selectedStore.get('id')
    const isAlreadyLoadedMyStore = _.isNumber(mySelectedStoreId) && mySelectedStoreId > 0

    // Checking selectedStore If not flow access should fetch before init order
    if (!isAlreadyLoadedMyStore) {
      const body = { store_id }
      const isMyStoreSuccessLoaded = await new Promise((isMyStoreLoaded) => {
        fetchMyStore({
          body,
          successCallback: () => isMyStoreLoaded(true),
          failedCallback: () => isMyStoreLoaded(false),
        })
      })
      if (!isMyStoreSuccessLoaded) {
        p.op.alert('ไม่มีข้อมูลร้านฉัน', 'กรุณากลับไปเลือกร้านค้าใหม่อีกครั้ง')
        // Failed to load my selected store
        return
      }
    }
    // Check Order Subscription
    await this._orderSubscriptionTracking()
  }

  async _orderSubscriptionTracking(): Promise<void> {
    // log(this, '_orderSubscriptionTracking begin')
    const { fetchSubscription, subscription } = this.props
    const lastUpdated: Moment | null = subscription.get('lastUpdated')
    const now = moment()
    if (lastUpdated && moment.isMoment(lastUpdated)) {
      if (Math.abs(now.diff(lastUpdated, 'h')) < 3) {
        // ถ้าระยะเวลา last fetched ไม่เกิน 3 ชม จะไม่ fetch ใหม่
        // log(this, '_orderSubscriptionTracking NO_FETCH cuz lastUpdated within 3 hours')
        return
      }
    }

    let shouldFetchSubscription: boolean
    if (lastUpdatedSubscription && moment.isMoment(lastUpdatedSubscription)) {
      // lastUpdatedSubscription ถูก set แล้ว เวลาที่ื fetch จากนั้นต้องเกิน 5 นาที ถึงจะยอมให้ fetch
      const diffMinutes: number = Math.abs(now.diff(lastUpdatedSubscription, 'm', true))
      shouldFetchSubscription = diffMinutes > 5
    } else {
      // ไม่ถูก set lastUpdatedSubscription
      shouldFetchSubscription = true
    }

    if (!shouldFetchSubscription) {
      // log(this, '_orderSubscriptionTracking NO_FETCH cuz is already fetching now')
      return
    }

    // log(this, '_orderSubscriptionTracking FETCHING')

    appGlobalMethods.subscriptionUpdate()
    const isLoaded = await new Promise((loadEnded) => {
      fetchSubscription({
        successCallback: () => {
          appGlobalMethods.subscriptionReset()
          // log(this, '_orderSubscriptionTracking RESET lastUpdatedSubscription')
          loadEnded(true)
        },
        failedCallback: () => loadEnded(false),
      })
    })

    if (!isLoaded) {
      p.op.alert('โหลดข้อมูลแพ็คเกจล้มเหลว', 'กรุณาลองใหม่อีกครั้ง')
    }
  }

  _initializeDefaultValueByMode = async (): Promise<void> => {
    const { selectedStoreName, onEditingOrderChange, resetOrder, selectedStore, onChangeAddress, sellerStores } = this.props
    const { mode, order_id, isNoInitFetch } = this.state

    if (!_.includes(ORDER_VIEW_MODE, mode)) {
      throw new Error('_initializeDefaultValueByMode no correct mode')
    }

    if (mode === CREATE) {
      resetOrder()
      await new Promise((delayForResettingOrder) => setTimeout(delayForResettingOrder, 100))
      // TODO: Reset params เกี่ยวกับการเลือกสินค้า PG/UG <<==== ทำให้มันดีกว่านี้ได้
      onEditingOrderChange({ key: 'chooseProductParams', value: null, orderTabIndex: 0 })

      // await this._initCreateOrderWithPGUG()
    }

    const defaultObj = await p.op.getAppDefaultObject()
    // log(this, 'BaseOrderView p.op.getAppDefaultObject  defaultObj => ', defaultObj)

    let initReplaceState: Partial<IBaseOrderViewState> & { [key: string]: any }
    initReplaceState = {}

    if (!this._hasSellerStores()) {
      initReplaceState.optionChooseProductsFromIndex = 1
    }

    if (mode === CREATE) {
      // Sender Address Default value
      const defaultSAddrIdKey = 'order_selected_sender_address_id'
      if (_.has(defaultObj, defaultSAddrIdKey) && !_.isNil(defaultObj[defaultSAddrIdKey])) {
        const orderSelectedSenderAddressId = parseInt(defaultObj[defaultSAddrIdKey])
        const storeAddresses = selectedStore.get('addresses') || List([])
        // log('orderSelectedSenderAddressId => ', JSON.stringify(orderSelectedSenderAddressId))
        // log('storeAddresses => ', JSON.stringify(storeAddresses.toJS()))
        if (storeAddresses.size > 0 && orderSelectedSenderAddressId) {
          const foundAddrIndex = selectedStore.get('addresses').findIndex((addr) => addr.get('id') === orderSelectedSenderAddressId)
          if (foundAddrIndex > -1) {
            const defaultAddress = storeAddresses.get(foundAddrIndex)
            const { customCreateOrder } = this.state
            const { sender } = customCreateOrder
            if (sender.v === 1 && sender.a === 0) {
              onChangeAddress({ sender: defaultAddress.toJS() })
              this._onReceiveNewAddress('sender', defaultAddress)
            }
          }
        }
      }

      for (let i = 0; i < this.ORDER_OPTION_KEYS.length; i++) {
        const key = this.ORDER_OPTION_KEYS[i]
        if (key in defaultObj && !_.isNull(defaultObj[key])) {
          onEditingOrderChange({ key, value: defaultObj[key], orderTabIndex: 0 })
        }
      }

      if (selectedStoreName) {
        initReplaceState.txtSenderName = selectedStoreName
      }
      // default sender name by user memo
      const { customCreateOrder, txtSenderName } = this.state
      const { sender } = customCreateOrder
      // ถ้า customCreateOrder เลือกผู้ส่ง แล้วเลือก แสดง > ไม่ระบุ จึงจะไป default sender name ที่จำครั้งล่าสุด
      // แต่หากว่าถ้าใช้ custom sender จะใช้ txtSenderName เพราะ txtSenderName ถูก set ก่อนหน้านี้ที่ this._setCustomCreateOrder
      if (sender.v === 1 && sender.a === 0) {
        const defaultSenderNameKey = 'order_selected_txtSenderName'
        const defaultSenderIDKey = `order_selected_sender_address_id`
        if (defaultSenderNameKey in defaultObj && !_.isNull(defaultObj[defaultSenderNameKey])) {
          initReplaceState.txtSenderName = defaultObj[defaultSenderNameKey]
          // util.setStatePromise(this, {
          //   txtSenderName: initReplaceState.txtSenderName,
          // })
        }
        // สำหรับหาที่อยู่ผู้ส่ง ที่จำเอาไว้
        if (defaultSenderIDKey in defaultObj && !_.isNull(defaultObj[defaultSenderIDKey])) {
          let storeAddresses = selectedStore.has(`addresses`) ? selectedStore.get(`addresses`).toJS() : []
          const senderAddress = _.find(storeAddresses, (addr) => addr.id === defaultObj[defaultSenderIDKey])
          if (!_.isNil(senderAddress)) {
            const addressSenderOnly = this._getAddresText(senderAddress)
            initReplaceState.txtSenderAddress = addressSenderOnly
          } else {
            storeAddresses = this._getAddress(defaultObj[defaultSenderIDKey])
            if (!_.isNil(senderAddress)) {
              const addressSenderOnly = this._getAddresText(senderAddress)
              initReplaceState.txtSenderAddress = addressSenderOnly
            }
          }
        }
      }

      // แสดงกำไรเสมอ ถ้าเป็นโหมด CREATE
      initReplaceState.showProfit = true

      // ปลดออกเนื่องจากใช้ AddStoreProductSelector component
      // const defaultSelectedSellerStoreIndex = 'order_selected_seller_store_index'
      // if (_.has(defaultObj, defaultSelectedSellerStoreIndex)) {
      //   if (!_.isNaN(parseInt(defaultObj[defaultSelectedSellerStoreIndex])) && parseInt(defaultObj[defaultSelectedSellerStoreIndex]) >= 0) {
      //     const selectedStoreIndex = parseInt(defaultObj[defaultSelectedSellerStoreIndex])
      //     const isAvailableStore = !_.isNil(sellerStores.getIn([selectedStoreIndex, 'id']))
      //     if (isAvailableStore) {
      //       initReplaceState.optionSelectedSellerStoreIndex = selectedStoreIndex
      //     }
      //   }
      // }
    } else {
      const defaultShowProfitKey = 'order_selected_showProfit'
      if (defaultShowProfitKey in defaultObj && !_.isNull(defaultObj[defaultShowProfitKey])) {
        initReplaceState.showProfit = defaultObj[defaultShowProfitKey]
      } else {
        // แสดงกำไรเสมอ ถ้ายังไม่เคยเซ็ต flag
        initReplaceState.showProfit = true
      }
    }

    if (!_.isEmpty(initReplaceState)) {
      await setStatePromise(this, initReplaceState)
    }

    // await setStatePromise(this, { mode })
    if (_.includes([VIEW_EDIT, VIEW_ONLY, EDIT], mode) && !isNoInitFetch) {
      await this._fetchOrderDetail()
    } else if (isNoInitFetch) {
      await this._autoSetOrderTabIndex()
    }

    // await this._willMountExtended()
  }

  _setCustomCreateOrder = async (keepAddresses = false): Promise<void> => {
    // README: keepAddresses Data
    // keepAddresses = {
    //   txtSenderName, txtSenderAddress, txtReceiverName, txtReceiverAddress
    // }
    const { orderTabIndex, customCreateOrder, mode, txtSenderAddress } = this.state
    // console.log('customCreateOrder // => ', customCreateOrder)
    if (mode !== CREATE) {
      return
    }
    const { onEditingOrderChange, pickerSelect, findAddresses, navigation, onChangeAddress, selectedStore } = this.props
    const { txtSenderName } = this.state
    // const order = this._getFocusedOrder()
    const autoComplete = customCreateOrder.auto_complete
    const salesChannel = customCreateOrder.sales_channel
    const { shipping } = customCreateOrder
    const shipBeforePay = customCreateOrder.ship_before_pay
    const { receiver } = customCreateOrder
    // let txtReceiverName = null
    const { sender } = customCreateOrder
    let txtSenderNameByCus = txtSenderName
    let txtSenderAddressCus = null
    let txtReceiverName = null
    let txtReceiverAddress = null
    // console.log('mode', mode)
    // const { store_id } = navigation.state.params
    const store_id = util.getNavParam(this.props, 'store_id')
    if (mode === CREATE) {
      onEditingOrderChange({ key: 'auto_complete', value: autoComplete.d, orderTabIndex })
      onEditingOrderChange({ key: 'channel_id', value: salesChannel.vi, orderTabIndex })
      // เซต รูปแบบจัดส่ง
      let isShippingTypeCOD = false // use for setting ship_before_pay flag later
      if (shipping.a === 1) {
        const customCreateOrderShipping = this.ALL_SHIPPING_TYPE_ITEMS.find((sp) => shipping.vi === sp.value)
        if (!_.isNil(customCreateOrderShipping)) {
          onEditingOrderChange({ key: 'shipping_type_id', value: shipping.vi, orderTabIndex })
          if (_.includes(xCONS.COD_SHIPPING_TYPE_IDS, shipping.vi)) {
            isShippingTypeCOD = true
            onEditingOrderChange({ key: 'is_cod', value: true, orderTabIndex })
          } else {
            onEditingOrderChange({ key: 'is_cod', value: false, orderTabIndex })
          }
        }
      }
      // เซต จัดส่งก่อนชำระ
      if (isShippingTypeCOD) {
        onEditingOrderChange({ key: 'ship_before_pay', value: shipBeforePay.is_cod, orderTabIndex })
      } else {
        onEditingOrderChange({ key: 'ship_before_pay', value: shipBeforePay.n_cod, orderTabIndex })
      }

      // เซต ผู้รับ ผู้ส่ง
      const customerAddresses = _.isNil(receiver.vi) ? null : [receiver.vi]
      const storeAddresses = _.isNil(sender.vi) ? null : [sender.vi]
      if (!_.isNil(sender.sender_name)) {
        txtSenderNameByCus = sender.sender_name
      }
      // const addresses = await this._loadAddress(storeAddresses, customerAddresses)
      const body: any = {}
      const receiverAddress = receiver.a === 0 ? null : await this._getAddress(receiver.vi)
      // console.log('receiverAddress', receiverAddress)
      const senderAddress = this._getAddress(sender.vi)
      if (!_.isNil(senderAddress)) {
        onChangeAddress({ sender: senderAddress })
        txtSenderNameByCus = _.isNil(txtSenderNameByCus) ? senderAddress.name : txtSenderNameByCus
        txtSenderAddressCus = this._getAddressName(senderAddress)
      }

      if (!_.isNil(receiverAddress)) {
        onChangeAddress({ receiver: receiverAddress })
        txtReceiverName = receiverAddress.name
        txtReceiverAddress = this._getAddressName(receiverAddress)
      }
      // ถ้าได้ ceelBack จากหน้า customCreateOrder แล้วค่าไม่ได้กรอกอัตโนมัติ ให้ใช้ค่าที่อยู่เดิม
      // console.log('keepAddresses', this.keepAddresses)
      if (keepAddresses) {
        if (receiver.a === 0 && !_.isNil(this.keepAddresses)) {
          txtReceiverName = this.keepAddresses.txtReceiverName
          txtReceiverAddress = this.keepAddresses.txtReceiverAddress
        }
        if (sender.a === 0 && !_.isNil(this.keepAddresses)) {
          txtSenderNameByCus = this.keepAddresses.txtSenderName
          txtSenderAddressCus = this.keepAddresses.txtSenderAddress
        }
      }

      if (!_.isNil(storeAddresses) && _.isNil(txtSenderAddressCus)) {
        body.store = {
          id: store_id,
          address_ids: storeAddresses,
        }
      }

      if (!_.isNil(customerAddresses) && _.isNil(txtReceiverAddress)) {
        body.customer_addr_ids = customerAddresses
      }
      let response = null
      if ((!_.isNil(customerAddresses) && _.isNil(txtReceiverAddress)) || (!_.isNil(storeAddresses) && _.isNil(txtSenderAddressCus))) {
        response = await new Promise<any>((resolve) => {
          findAddresses({
            body,
            // successCallback: (res) => {
            //   response = res
            //   setTimeout(resolve, 200)
            // },
            // failedCallback: (err) => {
            //   response = err
            //   setTimeout(resolve, 200)
            // },
            successCallback: resolve,
            failedCallback: resolve,
          })
        })
      }
      const addresses = _.has(response, 'addresses') ? response.addresses : null
      const errorRespose = _.has(response, 'error') ? response.error : null
      // let txtSenderNameByCus = null
      // let txtSenderAdresses = null
      // let txtReceiverName = null
      // let txtReceiverAddress = null
      // console.log('addresses', addresses)
      // console.log('errorResposeId', errorResposeId)
      if (_.isArray(addresses) && addresses.length > 0) {
        addresses.map((address) => {
          if (!_.isNil(sender.vi) && address.id === sender.vi && _.isNil(txtSenderAddressCus) && sender.a !== 0) {
            onChangeAddress({ sender: address })
            txtSenderNameByCus = _.isNil(txtSenderNameByCus) ? address.name : txtSenderNameByCus
            // txtSenderAddressCus = address.address1
            // txtSenderAddressCus = address.address2 ? `${txtSenderAddressCus} ${address.address2}` : txtSenderAddressCus
            // txtSenderAddressCus = address.sub_district ? `${txtSenderAddressCus} ${address.sub_district}` : txtSenderAddressCus
            // txtSenderAddressCus = address.district ? `${txtSenderAddressCus} ${address.district}` : txtSenderAddressCus
            // txtSenderAddressCus = address.province ? `${txtSenderAddressCus} ${address.province}` : txtSenderAddressCus
            // txtSenderAddressCus = address.postal_code ? `${txtSenderAddressCus}\n${address.postal_code}` : txtSenderAddressCus
            // txtSenderAddressCus = address.telephone ? `${txtSenderAddressCus}\nโทร. ${address.telephone}` : txtSenderAddressCus
            // txtSenderAddressCus = address.email ? `${txtSenderAddressCus}\nอีเมล ${address.email}` : txtSenderAddressCus
            // if (address.legal_entity_type > 0) {
            //   txtSenderAddressCus = address.legal_entity_id
            //     ? `${txtSenderAddressCus}\n${util.getLegalEntityLabel(address.legal_entity_type)} ${address.legal_entity_id}`
            //     : txtSenderAddressCus
            // }
            txtSenderAddressCus = xUtil.convertAddressToInlineAddress(address, { withoutName: true })
          }
          if (!_.isNil(receiver.vi) && address.id === receiver.vi && _.isNil(txtReceiverAddress) && receiver.a !== 0) {
            onChangeAddress({ receiver: address })
            txtReceiverName = address.name
            // txtReceiverAddress = address.address1
            // txtReceiverAddress = address.address2 ? `${txtReceiverAddress} ${address.address2}` : txtReceiverAddress
            // txtReceiverAddress = address.sub_district ? `${txtReceiverAddress} ${address.sub_district}` : txtReceiverAddress
            // txtReceiverAddress = address.district ? `${txtReceiverAddress} ${address.district}` : txtReceiverAddress
            // txtReceiverAddress = address.province ? `${txtReceiverAddress} ${address.province}` : txtReceiverAddress
            // txtReceiverAddress = address.postal_code ? `${txtReceiverAddress}\n${address.postal_code}` : txtReceiverAddress
            // txtReceiverAddress = address.telephone ? `${txtReceiverAddress}\nโทร. ${address.telephone}` : txtReceiverAddress
            // txtReceiverAddress = address.email ? `${txtReceiverAddress}\nอีเมล ${address.email}` : txtReceiverAddress
            // if (address.legal_entity_type > 0) {
            //   txtReceiverAddress = address.legal_entity_id
            //     ? `${txtReceiverAddress}\n${util.getLegalEntityLabel(address.legal_entity_type)} ${address.legal_entity_id}`
            //     : txtReceiverAddress
            // }
            txtReceiverAddress = xUtil.convertAddressToInlineAddress(address, { withoutName: true })
          }
        })
      } else if (!_.isNil(errorRespose) && _.isNumber(errorRespose)) {
        // @ts-ignore id is no valid type of errorRespose
        const errorId = errorRespose.id
        // console.log(errorId)
        if (errorId === sender.vi) {
          p.op.showConfirmation(
            'กรุณาตั้งค่าที่อยู่ผู้ส่งสำหรับการระบุอัตโนมัติใหม่',
            'ที่อยู่ผู้ส่งสำหรับการระบุอัดโนมัติไม่ถูกต้อง กรุณาระบุใหม่',
            () => this._navToSettingCreateOrder,
            () => {},
            'ตั้งค่า',
            'ปิด'
          )
        } else if (errorId === receiver.vi) {
          p.op.showConfirmation(
            'กรุณาตั้งค่าที่อยู่ผู้รับสำหรับการระบุอัตโนมัติใหม่',
            'ที่อยู่ผู้รับสำหรับการระบุอัดโนมัติไม่ถูกต้อง กรุณาระบุใหม่',
            () => this._navToSettingCreateOrder,
            () => {},
            'ตั้งค่า',
            'ปิด'
          )
        }
      }

      // set auto_cancel_order
      const hasAutoCancelOrder = _.has(customCreateOrder, 'auto_cancel_order')
      if (hasAutoCancelOrder && customCreateOrder.auto_cancel_order.m && customCreateOrder.auto_cancel_order.m === 'd') {
        this._onSegmentChangeSetUpAutoCancelOrder(2, true)
      } else if (hasAutoCancelOrder && !_.has(customCreateOrder.auto_cancel_order, 'm')) {
        this._onSegmentChangeSetUpAutoCancelOrder(0)
      }
    }
    if (_.isNil(txtSenderNameByCus) && _.isNil(sender.sender_name) && _.isNil(sender.vi)) {
      txtSenderNameByCus = selectedStore.get('name')
    }

    // onEditingOrderChange({ key: 'gen_tracking_codes', value: tackingCodes, orderTabIndex })

    // หากไม่มี custom ที่อยู่ผู้ส่ง ก็ไปใช้ค่าที่จำไว้ก่อนหน้า
    if (_.isNil(txtSenderAddressCus)) {
      txtSenderAddressCus = txtSenderAddress
    }
    // this.setState({ txtReceiverName, txtReceiverAddress, txtSenderName: txtSenderNameByCus, txtSenderAddress: txtSenderAddressCus })
    await util.setStatePromise(this, {
      txtReceiverName,
      txtReceiverAddress,
      txtSenderName: txtSenderNameByCus,
      txtSenderAddress: txtSenderAddressCus,
    })
  }

  _getAddress = (addressId) => {
    if (_.isNil(addressId)) {
      return null
    }
    const { addressesList } = this.props
    let resAddress = null
    if (addressesList.size > 0) {
      addressesList.map((address) => {
        if (_.isNil(address.id)) {
          return null
        }
        if (address.id === addressId) {
          resAddress = address
        }
      })
    }
    return resAddress
  }

  _getAddressName = (address) => {
    if (_.isNil(address)) {
      return null
    }
    // let addressName = address.address1
    // addressName = address.address2 ? `${addressName} ${address.address2}` : addressName
    // addressName = address.sub_district ? `${addressName} ${address.sub_district}` : addressName
    // addressName = address.district ? `${addressName} ${address.district}` : addressName
    // addressName = address.province ? `${addressName} ${address.province}` : addressName
    // addressName = address.postal_code ? `${addressName}\n${address.postal_code}` : addressName
    // addressName = address.telephone ? `${addressName}\nโทร. ${address.telephone}` : addressName
    const addressName = xUtil.convertAddressToInlineAddress(address, { withoutName: true })
    return addressName
  }

  // async _generateNewCToken(): Promise<void> {
  //   await setStatePromise(this, { ctoken: getCreateToken() })
  // }

  _showConfirmationToEditAddress = (order: Map<string, any>, key: 'sender' | 'receiver', okCallback: () => void): void => {
    const isLockedOrder = order.get('prevent_mod') || false
    const txtAddressOwner = key === 'sender' ? 'ผู้ส่ง' : 'ผู้รับ'
    const orderId = order.get('id')
    const orderName = order.get('name')
    const orderType = order.get('type')
    const parentOrderId = order.get('parent_id')
    const parentOrderName = order.get('parent_name')
    if (orderType === 3 && isLockedOrder) {
      p.op.alert(`ไม่สามารถแก้ไขที่อยู่${txtAddressOwner}`, p.op.t('e_specifics._052'))
      return
    }

    if (orderType === 2) {
      p.op.alert(`คุณไม่สามารถแก้ไขที่อยู่${txtAddressOwner}`, `กรุณาติดต่อให้ตัวแทนของคุณแก้ไขที่อยู่${txtAddressOwner}`)
      return
    }

    if (orderType === 1 && isLockedOrder) {
      this._isLockedOrderShowAlert(order)
      return
    }

    const subOrderNames = List.isList(order.get('suborders'))
      ? order
          .get('suborders')
          .map((so) => `#${so.get('name')}`)
          .toArray()
      : null
    const siblingOrderNames = List.isList(order.get('siblings'))
      ? order
          .get('siblings')
          .map((so) => `#${so.get('name')}`)
          .toArray()
      : null
    let txtOrderNames = `#${orderName}`

    if (parentOrderName) {
      txtOrderNames += ', ' + `#${parentOrderName}`
    }

    if (_.isArray(subOrderNames) && subOrderNames.length > 0) {
      for (let i = 0; i < subOrderNames.length; i++) {
        const subOrderName = `#${subOrderNames[i]}`
        txtOrderNames = `${txtOrderNames}, ${subOrderName}`
      }
    }

    if (_.isArray(siblingOrderNames) && siblingOrderNames.length > 0) {
      for (let i = 0; i < siblingOrderNames.length; i++) {
        const siblingOrderName = `#${siblingOrderNames[i]}`
        txtOrderNames = `${txtOrderNames}, ${siblingOrderName}`
      }
    }

    p.op.showConfirmation(
      `ดำเนินการแก้ไขที่อยู่${txtAddressOwner}`,
      `ยืนยันที่จะแก้ไขที่อยู่${txtAddressOwner}ของออเดอร์ ${txtOrderNames} ใช่หรือไม่`,
      () => {
        if (_.isFunction(okCallback)) {
          okCallback()
        }
      }
    )
  }

  // async _initWillMount() {
  //   const {selectedStoreName, onEditingOrderChange, resetOrder, selectedStore, onChangeAddress, changeOrderTabIndexTo} = this.props
  //   const {mode, order_id, store_id, orderTabIndex, isNoInitFetch} = this._getParams()
  //
  //   if (mode === CREATE) {
  //     resetOrder()
  //   }
  //
  //   const defaultObj = await p.op.getAppDefaultObject()
  //   log(this, 'BaseOrderView p.op.getAppDefaultObject  defaultObj => ', defaultObj)
  //
  //   let initReplaceState = {}
  //
  //   if (mode === CREATE) {
  //     // Sender Address Default value
  //     const defaultSAddrIdKey = 'order_selected_sender_address_id'
  //     if (defaultSAddrIdKey in defaultObj && !_.isNull(defaultObj[defaultSAddrIdKey])) {
  //       const order_selected_sender_address_id = parseInt(defaultObj[defaultSAddrIdKey])
  //       const storeAddresses = selectedStore.get('addresses') || List([])
  //       log(this, 'order_selected_sender_address_id => ', JSON.stringify(order_selected_sender_address_id))
  //       log(this, 'storeAddresses => ', JSON.stringify(storeAddresses.toJS()))
  //       if (storeAddresses.size > 0 && order_selected_sender_address_id) {
  //         const foundAddrIndex = selectedStore.get('addresses').findIndex(addr =>
  //           addr.get('id') === order_selected_sender_address_id)
  //         if (foundAddrIndex > -1) {
  //           let defaultAddress = storeAddresses.get(foundAddrIndex)
  //           onChangeAddress({sender: defaultAddress.toJS()})
  //           this._onReceiveNewAddress('sender', defaultAddress)
  //         }
  //       }
  //     }
  //
  //     for (let i = 0; i < this.ORDER_OPTION_KEYS.length; i++) {
  //       const key = this.ORDER_OPTION_KEYS[i]
  //       if (key in defaultObj && !_.isNull(defaultObj[key])) {
  //         onEditingOrderChange({key, value: defaultObj[key], orderTabIndex: 0})
  //       }
  //     }
  //
  //     if (selectedStoreName) {
  //       initReplaceState['txtSenderName'] = selectedStoreName
  //     }
  //
  //     // default sender name by user memo
  //     const defaultSenderNameKey = 'order_selected_txtSenderName'
  //     if (defaultSenderNameKey in defaultObj && !_.isNull(defaultObj[defaultSenderNameKey])) {
  //       initReplaceState['txtSenderName'] = defaultObj[defaultSenderNameKey]
  //     }
  //
  //     // แสดงกำไรเสมอ ถ้าเป็นโหมด CREATE
  //     initReplaceState['showProfit'] = true
  //
  //   } else {
  //     const defaultShowProfitKey = 'order_selected_showProfit'
  //     if (defaultShowProfitKey in defaultObj && !_.isNull(defaultObj[defaultShowProfitKey])) {
  //       initReplaceState['showProfit'] = defaultObj[defaultShowProfitKey]
  //     } else { // แสดงกำไรเสมอ ถ้ายังไม่เคยเซ็ต flag
  //       initReplaceState['showProfit'] = true
  //     }
  //   }
  //
  //   await new Promise(resolve => {
  //     if (!_.isEmpty(initReplaceState)) {
  //       this.setState(initReplaceState, () => {
  //         resolve()
  //       })
  //     } else {
  //       resolve()
  //     }
  //   })
  //
  //   await setStatePromise(this, { mode })
  //   if (_.includes([VIEW_EDIT, VIEW_ONLY, EDIT], mode) && !isNoInitFetch) {
  //     await this._fetchOrderDetail()
  //   }
  //
  //   // await this._willMountExtended()
  // }

  // _changeModeTo(mode: string, callback?: () => void): void {
  //   const { setMode } = this.props
  //   if (_.has(CONS.ORDER_VIEW_MODE, mode)) {
  //     setMode({ mode })
  //     if (_.isFunction(callback)) {
  //       setTimeout(callback, 500)
  //     }
  //   }
  // }

  // _fetchOrderDetail(callback?: (res: Response) => void): void {
  //   const { fetchOrderDetail, editingOrder } = this.props
  //   const { store_id, order_id } = this._getParams()
  //   // log(this, '_fetchOrderDetail store_id, order_id => ', store_id, order_id)
  //   if (store_id && order_id) {
  //     const parentOrderId = editingOrder.get('id')
  //     fetchOrderDetail({
  //       store_id,
  //
  //       // Refactor to fetch parent firsted support order tabs
  //       order_id: _.isNumber(parentOrderId) && parentOrderId > 0 ? parentOrderId : order_id,
  //
  //       successCallback: res => {
  //         if (_.isFunction(callback)) {
  //           callback(res)
  //         }
  //         this._handleAfterSuccessFetchOrderDetail(res)
  //       },
  //     })
  //   }
  // }

  // async _fetchOrderDetail(ignoredParent?: boolean): Promise<any> {
  //   log(this, 'In BaseOrderView:: _fetchOrderDetail')
  //   return await new Promise((resolve, reject) => {
  //     const { fetchOrderDetail, selectedOrder } = this.props
  //     const { store_id, order_id } = this._getParams()
  //     log(this, '_fetchOrderDetail _getParams order_id => ', order_id)
  //     log(this, '_fetchOrderDetail _getParams store_id => ', store_id)
  //     if (store_id && order_id) {
  //       const parentOrderId = ignoredParent ? null : selectedOrder.get('id')
  //       const fetchOrderId = _.isNumber(parentOrderId) && parentOrderId > 0 ? parentOrderId : order_id
  //       if (fetchOrderId) {
  //         fetchOrderDetail({
  //           store_id,
  //           // Refactor to fetch parent firsted support order tabs
  //           order_id: fetchOrderId,
  //           successCallback: (res: Response) => {
  //             this._handleAfterSuccessFetchOrderDetail(res)
  //             resolve(res)
  //           },
  //           failedCallback: (err: Error) => {
  //             reject(err)
  //           }
  //         })
  //       }
  //     } else {
  //       reject()
  //     }
  //
  //     setTimeout(reject, 10000)
  //   }).catch(error => { throw error })
  // }

  _fetchOrderDetail = async (skipOrderReset?: boolean): Promise<void> => {
    const { fetchOrderDetail } = this.props
    const { store_id, order_id } = this.state
    if (store_id && order_id) {
      const response = await new Promise((isFetched) => {
        fetchOrderDetail({
          body: { store_id, order_id },
          successCallback: (res: Response) => isFetched(res),
          failedCallback: () => isFetched(false),
          skipOrderReset: !_.isNil(skipOrderReset) ? skipOrderReset : false,
        })
      })
      // log(this, '_fetchOrderDetail response => ', response)
      if (response) {
        await this._handleAfterSuccessFetchOrderDetail()
        await new Promise((stateDelay) => setTimeout(stateDelay, 200))
        await this._autoSetOrderTabIndex()
        // await this._handleOnTabChanged()
        await this._loadUgPgDiscountInOrderToState()
        await this._updateHamburgerMenuOptions()
      }
    }
    // log(this, 'In BaseOrderView:: _fetchOrderDetail')
    // return await new Promise((resolve, reject) => {
    //   const { fetchOrderDetail, selectedOrder } = this.props
    //   const { store_id, order_id } = this.state
    //   // log(this, '_fetchOrderDetail _getParams order_id => ', order_id)
    //   // log(this, '_fetchOrderDetail _getParams store_id => ', store_id)
    //   if (store_id && order_id) {
    //     fetchOrderDetail({
    //       store_id,
    //       order_id,
    //       successCallback: (res: Response) => {
    //         this._handleAfterSuccessFetchOrderDetail(res)
    //         resolve(res)
    //       },
    //       failedCallback: (err: Error) => {
    //         reject(err)
    //       }
    //     })
    //   } else {
    //     reject()
    //   }
    //
    //   setTimeout(reject, 10000)
    // }).catch(error => { throw error })
  }

  _onReceiveNewAddress = async (key, newAddress) => {
    if (this.inReceivingNewAddress) {
      return
    }
    this.inReceivingNewAddress = true
    // const addressObj = this.props.selectedAddress.get(key)
    // log(this, '____onReceiveNewAddress___ addressObj:', newAddress.toJS())

    const newAddrObj = Map.isMap(newAddress) ? newAddress.toJS() : newAddress
    const {
      id,
      name,
      address1,
      address2,
      address3,
      district,
      sub_district,
      province,
      postal_code,
      telephone,
      email,
      legal_entity_type = 0,
      legal_entity_id,
      shouldPatch,
    } = newAddrObj
    let txtAddress = null

    const currentOrder = this._getFocusedOrder() /// ONE
    let keyAddress = 'ผู้ส่ง'
    if (key === 'receiver') {
      keyAddress = 'ผู้รับ'
    }

    if (key === 'billing_address') {
      keyAddress = 'ที่อยู่สำหรับใบกำกับภาษี'
    }

    if (this.state.mode !== CREATE && shouldPatch) {
      if (currentOrder.get('shipping_type_id') && currentOrder.get('shipping_type_id') < 6) {
        if (_.isNull(telephone) || telephone === '' || _.isUndefined(telephone)) {
          p.op.alert('คำเตือน', `ไม่สามารถเลือกที่อยู่${keyAddress}นี้ได้ \nเนื่องจากไม่ได้ระบุ "หมายเลขโทรศัพท์" ของ${keyAddress}`)
          this.inProcess = false
          this.isSetRefusedAddress = true
          // this.props.onChangeAddress({
          //   key: {
          //     id: null,
          //     name: null,
          //     address3: null,
          //   },
          // })
          const emptyQuickAddr = {}
          emptyQuickAddr[key] = {
            id: null,
            name: null,
            address3: null,
          }
          this.props.onChangeAddress(emptyQuickAddr)
          this.inReceivingNewAddress = false
          return
        }
      }
    } // END เมื่อเลือกรายชื่อผู้รับ จะมีการตรวจสอบหมายเลขโทรศัพท์ตรงนี้

    txtAddress = xUtil.convertAddressToInlineAddress(newAddrObj, { withoutName: true })
    // if (id) {
    //   // txtAddress = `${address1} ${address2 ? address2 : ''} ${district} ${sub_district} ${province}\n${postal_code}\nโทร. ${telephone}`
    //   txtAddress = address1
    //   txtAddress = address2 ? `${txtAddress} ${address2}` : txtAddress
    //   txtAddress = sub_district ? `${txtAddress} ${sub_district}` : txtAddress
    //   txtAddress = district ? `${txtAddress} ${district}` : txtAddress
    //   txtAddress = province ? `${txtAddress} ${province}` : txtAddress
    //   if ((!txtAddress || txtAddress === '') && address3) {
    //     txtAddress = address3
    //   }
    //   txtAddress = postal_code ? `${txtAddress}\n${postal_code}` : txtAddress
    //   txtAddress = telephone ? `${txtAddress}\nโทร. ${telephone}` : txtAddress
    // } else if (!id && address3) {
    //   // txtAddress = `${address3}\n${postal_code} ${telephone ? `\nโทร.​ ${telephone}` : ''}`
    //   txtAddress = address3
    //   txtAddress = postal_code ? `${txtAddress}\n${postal_code}` : txtAddress
    //   txtAddress = telephone ? `${txtAddress}\nโทร. ${telephone}` : txtAddress
    // } else if (!id && address1) {
    //   txtAddress = address1
    // }

    // txtAddress = email ? `${txtAddress}\nอีเมล ${email}` : txtAddress

    // if (legal_entity_type > 0 && legal_entity_id) {
    //   txtAddress = legal_entity_id ? `${txtAddress}\n${util.getLegalEntityLabel(legal_entity_type)} ${legal_entity_id}` : txtAddress
    // }

    if (key === 'sender') {
      // p.op.setAppDefaultValue('order_selected_sender_address_id', id)
      if (shouldPatch) {
        await this._onPickNewAddressToPatch({
          address_id: id,
          address: newAddrObj,
          type: 'SENDER',
        })
      } else {
        await xUtil.setStatePromise(this, {
          txtSenderName: _.isNil(name) ? this.state.txtSenderName : name,
          txtSenderAddress: txtAddress,
        })
      }
    } else if (key === 'receiver') {
      // _findDupByReceiverAddr ตรวจสอบที่อยู่ผู้รับซ้ำ
      await this._findDupByReceiverAddr({
        store_id: this.props.selectedStore.get('id'),
        name: newAddrObj.name,
        postal_code: newAddrObj.postal_code,
        telephone: newAddrObj.telephone,
      })
      if (shouldPatch) {
        await this._onPickNewAddressToPatch({
          address_id: id,
          address: newAddrObj,
          type: 'RECEIVER',
        })
      } else {
        await xUtil.setStatePromise(this, {
          txtReceiverName: name,
          txtReceiverAddress: txtAddress,
        })
      }
    } else if (key === 'billing_address') {
      if (shouldPatch) {
        await this._onPickNewAddressToPatch({
          address_id: id,
          address: newAddrObj,
          type: 'BILLING_ADDRESS',
        })
      } else {
        await xUtil.setStatePromise(this, {
          txtBillingName: name,
          txtBillingAddress: txtAddress,
        })
      }
    } else {
      log(this, '_onReceiveNewAddress No match key.')
    }

    this.inReceivingNewAddress = false
  }

  _onPickNewAddressToPatch = async (editingAddress: {
    address_id?: number
    address?: { name: string; address3: string; postal_code: string; telephone: string }
    type: 'SENDER' | 'RECEIVER' | 'BILLING_ADDRESS'
  }) => {
    // log(this, '_onPickNewAddressToPatch editingAddress => ', editingAddress)
    const { editReceiverAddress, selectedStore } = this.props
    const { address_id, address, type } = editingAddress
    let selectedStoreID = Map.isMap(selectedStore) ? selectedStore.get('id') : null
    const params = this.state
    if (!selectedStoreID) {
      selectedStoreID = params.store_id ? params.store_id : null
    }

    const order = this._getFocusedOrder()
    if (selectedStoreID && selectedStoreID && order.get('id')) {
      const body: any = {
        store_id: selectedStoreID,
        order_id: order.get('id'),
        type,
      }

      if (type === 'BILLING_ADDRESS') {
        body.type = 'BILLING'
      }

      if (address_id) {
        body.new_address_id = address_id
      } else if (!_.isEmpty(address) && address.name && address.address3 && address.postal_code) {
        body.new_address = []
        body.new_address.push(address)
        // if (!body.new_address.telephone) {
        //   body.new_address.telephone = ''
        // }
      }
      // log(this, '_onPickNewAddressToPatch body => ', body)
      let callNewXShipping = false
      if (body.new_address_id || body.new_address) {
        if (type === 'RECEIVER' && order.has('x')) {
          callNewXShipping = await this._warningChangeReceiverAddressHasXShipping(
            'คุณเปลี่ยนที่อยู่',
            'ระบบจะทำการยกเลิกเลขติดตามพัสดุเดิม และขอใหม่ เพื่อให้ที่อยู่ผู้รับเปลี่ยนตามที่แก้ไขไว้ หากระบบขอใหม่ไม่สำเร็จ กรุณากดยกเลิกเลขติดตามพัสดุและขอใหม่เอง'
          )
          if (!callNewXShipping) {
            // set receiver = { id: null } ให้กลับไปยังค่าเริ่มต้น เพื่อเวลาที่กดเลือกที่อยู่เดิมจะได้เกิด diff ตอน componentDidUpdate
            this.props.onChangeAddress({ receiver: { id: null } })
            return
          }
        }

        editReceiverAddress({ body, successCallback: async () => await this._changeReceiverAddressHasXShipping(callNewXShipping) })
      } else {
        log(this, 'Cannot editReceiverAddress with body => ', body)
      }
    }
  }

  // 1316370
  _changeReceiverAddressHasXShipping = async (callNewXShipping: boolean) => {
    const { selectedStore } = this.props
    const thisOrder = this._getFocusedOrder()
    await util.delay(2000)
    if (callNewXShipping) {
      await this._callNewXShipping(selectedStore.get(`id`), thisOrder.get('id'))
    }
  }

  _afterNavToQuickAddressViewsuccessCallback = () => {
    this._changeReceiverAddressHasXShipping(true)
  }

  _warningChangeReceiverAddressHasXShipping = async (title: string, msg: string) => {
    const order = this._getFocusedOrder()
    if (_.isNil(order.get('x'))) {
      return false
    }
    const xshipping = isImmutable(order) ? order.get('x').toJS() : order.x
    const multipleTracking = xshipping.o
    let callNewXShipping = false
    let isCancellable = false

    multipleTracking.map((tracking: IXShippingTracking) => {
      // ต้องมีเลขที่สามาระยกเลิกได้
      if (tracking.c && tracking.c === 1) {
        isCancellable = true
      }
    })

    let isUserConfirmedChangeXshipping = true
    if (isCancellable) {
      isUserConfirmedChangeXshipping = await new Promise<boolean>((passConfirm) => {
        p.op.showConfirmation(
          title,
          msg,
          () => passConfirm(true),
          () => passConfirm(false),
          'ตกลง',
          'ยกเลิก'
        )
      })
    }

    // user ต้องการยกเลิกเลขพัสดุอัตโนมัติ
    if (isUserConfirmedChangeXshipping) {
      callNewXShipping = true
    }

    return callNewXShipping
  }

  _isAutoCompleteOrderOpAllowed = (mode) =>
    // log(this, '_isAutoCompleteOrderOpAllowed.mode: ' + mode)
    _.includes([CREATE], mode)

  _handleOrderOptionChange = async (order: Map<string, any>, key: string, newValue: boolean, txtToastLabel?: string) => {
    const { onEditingOrderChange, onEditingOptionChange } = this.props
    const { mode, store_id, orderTabIndex } = this.state
    if (_.includes([CREATE], mode)) {
      await p.op.setAppDefaultValue(key, newValue)
      onEditingOrderChange({ key, value: newValue, orderTabIndex })
    } else if (_.includes([VIEW_EDIT, EDIT], mode)) {
      const order_id = order && order.get('id') ? order.get('id') : null
      if (!order_id) {
        return
      }
      onEditingOptionChange({ store_id, order_id, key, value: newValue, txtToast: txtToastLabel })
    }
  }

  _calcShippingCost = (order, isCreatingOrder, shipping_type_id) => {
    const { profile, myStores, editingOrder } = this.props
    const { orderTabIndex } = this.state
    // const order = isCreatingOrder ?  this.props.editingOrder : this.props.selectedOrder
    // TODO: Handle no shipping Type
    // let order
    let products = []
    const successCallback = (res) => {
      let errMsg
      if (res.error) {
        switch (res.error) {
          case 10:
            errMsg = 'มีสินค้าอย่างน้อย 1 รายการที่ไม่ได้ระบุค่าจัดส่งต่อชิ้น'
            break
          case 20:
            errMsg =
              'อาจเป็น 1 ในกรณีดังนี้\n1. น้ำหนักรวมเกินค่าที่ทางร้านกำหนดไว้\n2.ทางร้านไม่รองรับการจัดส่งประเภทนี้\nหรือ\n3. มีสินค้าอย่างน้อย 1 รายการที่ไม่ได้ระบุน้ำหนัก'
            break
          case 30:
            errMsg = 'มีอย่างน้อย 1 ร้านค้าที่ไม่ได้เปิดฟีเจอร์การใช้งานคำนวณค่าจัดส่งอัตโนมัติ'
            break
          default:
            errMsg = `เกิดจากการผิดพลาดในการคำนวณ​ (${res.error})`
            break
        }
        p.op.aLogEvent(CONS.EVENT_NAME.ORDER_CALC_SHIPPING_COST_ERROR, {
          err: res.error,
          user_id: profile && profile.get('id') ? profile.get('id') : -1,
          store_count: myStores && myStores.size ? myStores.size : -1,
        }) // -1 is unknown and should be impossible case
        p.op.alert('ไม่สามารถคำนวณค่าจัดส่งได้', errMsg)
        // this.inProcess = false
      } else if ('total' in res) {
        // } else if (res.total) { // MUST NOT check with res.total as total could be 0 (free shipping) and not enter this block
        const okCallback = () => {
          this.setState({
            lastShippingCost: res.total,
          })
          this.props.onEditingOrderChange({
            key: 'shipping_cost',
            value: util.validateInputCurrency(res.total),
            orderTabIndex,
          })
          // log(this, 'okCallback!!!')
        }
        // const cancelCallback = () => {
        //   // do nothing
        //   // log('cancelCallback')
        // }
        p.op.aLogEvent(CONS.EVENT_NAME.ORDER_CALC_SHIPPING_COST_SUCCESS, {
          shipping_cost: res.total,
          user_id: profile && profile.get('id') ? profile.get('id') : -1,
          store_count: myStores && myStores.size ? myStores.size : -1,
        })
        p.op.showConfirmation(
          `ค่าจัดส่งคำนวณได้ ฿${res.total}`,
          'คุณต้องการระบุค่าจัดส่งนี้ในออเดอร์หรือไม่',
          okCallback,
          null,
          'ระบุ',
          'ไม่ระบุ'
        )
      } else {
        // shall never happen
        p.op.aLogEvent(CONS.EVENT_NAME.ORDER_CALC_SHIPPING_COST_FAILED, {
          user_id: profile && profile.get('id') ? profile.get('id') : -1,
          store_count: myStores && myStores.size ? myStores.size : -1,
        })
        p.op.alert('เกิดความผิดพลาดในการคำนวณ', JSON.stringify(res))
      }
    }
    if (isCreatingOrder) {
      if (!shipping_type_id || shipping_type_id <= 0) {
        this.inProcess = false
        this._handleErrMissingShippingType()
        return
      }
      if (shipping_type_id === CONS.SHIPPING_TYPE_IDS.OTHER) {
        p.op.alert('ไม่สามารถคำนวณค่าจัดส่งรูปแบบ"อื่นๆ"ได้')
        return
      }

      const storeProducts = this._getPreparedStoreProductsFromOrder(order)
      // log(this, '_calcShippingCost storeProducts', storeProducts)
      if (_.isArray(storeProducts) && storeProducts.length > 0) {
        storeProducts.forEach((storeProduct) => {
          // log ('storeProduct: ', storeProduct)
          // log ('storeProduct.products: ', storeProduct.products)
          if (storeProduct.products) {
            products = products.concat(storeProduct.products)
          }
        })

        const failCallback = (err) => {
          let errMsg = ''
          if (typeof err !== 'undefined' && err != null) {
            if (err.error === 10) {
              errMsg =
                'กรุณาเช็กว่าสินค้าทุกรายการได้มีการระบุค่าส่งตามรูปแบบการจัดส่งที่เลือกไว้หากคิดค่าส่งตามชิ้น หรือระบุนำ้หนักหากคิดตามน้ำหนัก'
            } else if (err.error === 30) {
              errMsg =
                'กรุณาติดต่อทีมงานที่ LINE: @XSelly เพื่อตั้งค่าการเปิดการใช้งานฟีเจอร์คำนวณค่าส่ง (ใช้งานได้ตั้งแต่แพ็ก Bronze ขึ้นไป)'
            } else {
              // TODO: Handle the case where err.error === 20 (have to check w/ backend)
              errMsg = `${
                'ฟีเจอร์คำนวณค่าส่งอัตโนมัติ สามารถใช้ได้ตั้งแต่แพ็ก Bronze ขึ้นไป ' + 'หากสนใจกรุณาติดต่อทีมงานที่ LINE: @XSelly '
              }${JSON.stringify(err)}`
            }
          } else {
            errMsg = 'ฟีเจอร์คำนวณค่าส่งอัตโนมัติ สามารถใช้ได้ตั้งแต่แพ็ก Bronze ขึ้นไป ' + 'หากสนใจกรุณาติดต่อทีมงานที่ LINE: @XSelly '
          }
          p.op.alert('ไม่สามารถคำนวณค่าจัดส่งได้', errMsg)
        }
        const payload = {
          store_id: this.state.store_id,
          shipping_type_id,
          discount: editingOrder.get('discount') || 0,
          etc_cost: editingOrder.get('etc_cost') || 0,
          products,
          successCallback,
          failCallback,
        }
        this.props.onShippingCostCalc(payload)
      } else {
        p.op.alert('กรุณาเพิ่มสินค้าในออเดอร์ก่อน')
      }
    } else {
      // TODO: Handle edit case
      p.op.alert('Error', 'Not Yet support for edit mode')
    }
  }

  _shouldRenderCalcShippingCostBtn = (): boolean => {
    const { mode } = this.state
    return mode === CREATE
  }

  _onRefresh = async (skipOrderReset = true): Promise<void> => {
    const { refreshing, submitting, mode } = this.state
    if (refreshing || submitting) {
      return
    }

    if (!_.includes([VIEW_EDIT, VIEW_ONLY], mode)) {
      return
    }

    await setStatePromise(this, { refreshing: true })
    await this._fetchOrderDetail(skipOrderReset)
    await this._setDateTimeAutoCancelOrder()
    await this._setDateTimeDateDeliverViewEditMode()
    if (this && !this.isUnmounting) {
      await setStatePromise(this, { refreshing: false })
    }
    // const { resetOrder } = this.props
    // resetOrder()
    // await setStatePromise(this,{ refreshing: true, orderTabsVisibility: false })
    // return await this._fetchOrderDetail().then((res: Response) => {
    //   if (this && !this.isUnmounting) {
    //     this.setState({ refreshing: false })
    //   }
    //   // log(this, 'BaseOrderView:: after _onRefresh  res => ', res)
    //   return res
    // }).catch(err => err)
  }

  _onDeleteProduct = ({ store_id, pp_id }) => {
    // log(`Delete Product ==> pp_id=${pp_id}`)
    this.props.removeProduct({ store_id, pp_id })
  }

  _getColNameProductSummaryPrice(orderType: number): string {
    switch (orderType) {
      case CONS.ORDER_TYPE_3:
        return 'ราคาฉันซื้อ\nผู้ขายส่ง'
      case CONS.ORDER_TYPE_2:
        return 'ราคาฉันขาย\nตัวแทน'
      default:
        // case 0 and 1
        return 'ราคาฉันขาย\nลูกค้า'
    }
  }

  _getShippingTypeLabel = (order: any): string => {
    // log(this, '_getShippingTypeLabel')
    // log(this, order.toJS())
    // log(this, this.shippingTypeItems)
    const shippingTypeId = order.get('shipping_type_id') || null
    if (_.isNumber(shippingTypeId)) {
      const sIndex = _.findIndex(this.ALL_SHIPPING_TYPE_ITEMS, (sItem) => sItem.value === shippingTypeId)
      if (sIndex > -1 && this.ALL_SHIPPING_TYPE_ITEMS[sIndex] && this.ALL_SHIPPING_TYPE_ITEMS[sIndex].label) {
        return this.ALL_SHIPPING_TYPE_ITEMS[sIndex].label
      }
      if (sIndex === -1 && shippingTypeId > 0) {
        return 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
      }
    }
    return 'ยังไม่เลือกรูปแบบจัดส่ง'
  }

  // _openShippingLabelPDF = async (type: PrintShippingLabelKey = 'A4_PDF'): Promise<void> => {
  //   if (this.inProcess) {
  //     return
  //   }
  //   this.inProcess = true
  //   // const { store_id, order_id } = this.props.navigation.state.params
  //   // const { store_id, order_id } = this._getParams()
  //   const { selectedStore } = this.props
  //   const order = this._getFocusedOrder()
  //   const store_id = selectedStore.get('id')
  //   const order_id = order.get('id')
  //   const isPrinted = order.get('printed')
  //   if (isPrinted || this.isLocalPrinted) {
  //     // รอ animate ของ modal close
  //     await delay(800)
  //     const isUserConfirmed = await new Promise<boolean>((passConfirm) => {
  //       p.op.showConfirmation(
  //         'คำเตือน',
  //         'คุณเคยดาวน์โหลดใบปะหน้าพัสดุออเดอร์นี้แล้ว ต้องการดาวน์โหลดซ้ำใช่หรือไม่',
  //         () => passConfirm(true),
  //         () => passConfirm(false)
  //       )
  //     })
  //     if (!isUserConfirmed) {
  //       this.inProcess = false
  //       return
  //     }
  //   }

  //   // // root4Go is apiHostUrl4Go
  //   // let root4Go = ''
  //   // const selectedStoreJS = selectedStore.toJS()
  //   // // log(selectedStoreJS)
  //   // if (!_.isNil(selectedStoreJS)) {
  //   //   if (!_.isNil(selectedStoreJS.apiMappings4Go)) {
  //   //     const print_tpl_ids = selectedStoreJS.apiMappings4Go.print_tpl_ids
  //   //     if (_.includes(print_tpl_ids, selectedStoreJS.print_template_id)) {
  //   //       root4Go = selectedStoreJS.apiHostUrl4Go
  //   //     }
  //   //   }
  //   // }

  //   // const ppr = _.isNil(type) ? false : type === 'PAPERANG' ? true : false
  //   // const sticker = _.isNil(type) ? false : type === 'STICKER' ? true : false
  //   const ppr = _.isNil(type) ? false : type === xCONS.PRINT_OPTIONS.PAPERANG ? true : false
  //   const sticker = _.isNil(type) ? false : type === xCONS.PRINT_OPTIONS.STICKER_PDF ? true : false

  //   this.isLocalPrinted = true // สำหรับเวลาเรากดปริ้นแล้วไม่ได้ refresh order ใหม่
  //   const url = util.getShippingLabelPDFUrl({
  //     store_id,
  //     orders: [order_id],
  //     only_not_printed: false,
  //     limit: 1,
  //     // root4Go,
  //     isGoApi: true,
  //     paperang: ppr,
  //     sticker,
  //   })

  //   const fileName = util.getShippingLabelPDFFileNameByOrder(order_id)
  //   if (ppr) {
  //     // this._onOpenPaperangFromUrl(url, KEY_PAPERANG_SHIPPING_LABLE)
  //     // ถ้าเป็น web ให้ส่ง fileName ไปแทน key เพื่อดาวน์โหลดเลย
  //     this._onOpenPaperangFromUrl(url, p.op.isWeb() ? fileName : KEY_PAPERANG_SHIPPING_LABLE)
  //     p.op.aLogEvent(CONS.EVENT_NAME.SHIPPING_LABLE_SINGLE_PPR, { s: store_id })
  //   } else {
  //     await this._handleOnOpenShippingLabelFromUrl(url, fileName)
  //     p.op.aLogEvent(CONS.EVENT_NAME.SHIPPING_LABLE_SINGLE_A4, { s: store_id })
  //   }
  //   // await this._handleOnOpenShippingLabelFromUrl(url, fileName)
  //   this.inProcess = false
  // }

  _openShippingLabelBill = async (): Promise<void> => {
    const { selectedStore } = this.props
    const order = this._getFocusedOrder()
    const store_id = selectedStore.get('id')
    const order_id = order.get('id')
    const url = util.getShippingLabelBillUrl({
      store_id,
      order_ids: [order_id],
      printing_type: 2,
      size: 2,
    })
    await this._onOpenPaperangFromUrl(url, KEY_PAPREANG_BILL)
    p.op.aLogEvent(CONS.EVENT_NAME.RECEIPT_PPR, { s: store_id })
  }

  _getOrderChannelLabel = (order: any): string => {
    const orderChannelId = order.get('channel_id') || null
    if (_.isNumber(orderChannelId)) {
      const sIndex = this.channelItems.findIndex((sItem) => sItem.value === orderChannelId)
      if (sIndex > -1 && this.channelItems[sIndex] && this.channelItems[sIndex].label) {
        return this.channelItems[sIndex].label
      }
      if (sIndex === -1 && orderChannelId > 0) {
        return 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
      }
    }
    return 'ไม่ระบุ'
  }

  // _getStoreNameFromStoreId = (
  //   store_id: string | number,
  //   incPrefix?: boolean
  // ): {
  //   name: string | null
  //   isMyStore?: boolean
  //   isNotFound?: boolean
  // } => {
  //   const { myStores, sellerStores } = this.props
  //   let foundStore = null
  //   const storeId = _.isString(store_id) ? parseInt(store_id) : store_id
  //   foundStore = myStores.find((s) => parseInt(s.get('id')) === storeId)
  //   if (foundStore) {
  //     return { name: incPrefix ? `ร้านฉัน: ${foundStore.get('name')}` : foundStore.get('name'), isMyStore: true }
  //   }

  //   foundStore = sellerStores.find((s) => parseInt(s.get('id')) === storeId)
  //   if (foundStore) {
  //     return { name: incPrefix ? `ร้านผู้ขายส่ง: ${foundStore.get('name')}` : foundStore.get('name') }
  //   }

  //   return { name: 'ไม่พบชื่อร้านค้า', isNotFound: true } // 'ไม่พบชื่อร้านค้า'
  // }

  // FIXME: จริงๆ ปรับ [key: string]: any เป็น products: IProduct[] ได้ แต่ตอนนี้ยังไม่มี interface product
  _getPreparedStoreProductsFromOrder = (order: Map<string, any>): Array<{ store_id: number; [key: string]: any }> | null => {
    const store_products = isImmutable(order) ? order.get('store_products') : null
    // console.log('store_products => ', store_products.toJS())
    if (isImmutable(store_products) && Map.isMap(store_products)) {
      const store_ids = store_products.keySeq().toArray()
      const preparedStoreProducts: Array<{ store_id: number; [key: string]: any }> = []
      const myProductsFromOtherStores = []

      // Stores Loop
      for (let sIdx = 0; sIdx < store_ids.length; sIdx++) {
        const store_id = store_ids[sIdx]
        const storeProducts = store_products.get(store_id) || List([])
        if (List.isList(storeProducts) && storeProducts.size > 0) {
          const products = []

          // Products Loop
          for (let pIdx = 0; pIdx < storeProducts.size; pIdx++) {
            const product = storeProducts.get(pIdx)
            if (Map.isMap(product) && product.get('pp_id')) {
              const pp_id = product.get('pp_id') || null
              const qty = parseInt(product.get('qty') || 0)
              const price = parseFloat(product.get('price') || 0)
              const pg_id = product.get('pg_id')
              const ug_id = product.get('ug_id')
              const seller_unit_discount = product.get('seller_unit_discount')

              const price_rate_type = parseInt(product.get('price_rate_type') || 0)
              const price_rate_value = parseFloat(product.get('price_rate_value') || 0)

              const available_qty = parseInt(product.get('available_qty') || 0)

              const product_name = product.get('name') || ''
              const product_variant = product.get('variant') || null
              const display_name = product_variant ? `${product_name} ${product_variant}` : product_name

              const useMyStoreId = product.get('useMyStoreId')

              if (qty === 0) {
                this._handleErrProductQtyIsZero(display_name, pIdx, available_qty)
                return null
              }

              if (available_qty < qty) {
                this._handleErrNotEnoughQty(display_name, store_id, pIdx, available_qty)
                return null
              }

              if (pp_id) {
                // // FIXME: Mocking always sending price for create order (demo prices)
                // if (price_rate_type && price === price_rate_value) {
                //   products.push({pp_id, qty, price})
                // } else if (price_rate_type && price !== price_rate_value) {
                //   products.push({pp_id, qty, price})
                // } else if (!price_rate_type && price !== price_rate_value) {
                //   products.push({pp_id, qty, price})
                // } else {
                //   products.push({pp_id, qty, price})
                // }

                // if (price_rate_type && price === price_rate_value) {
                //   products.push({ pp_id, qty })
                // } else if (price_rate_type && price !== price_rate_value) {
                //   products.push({ pp_id, qty, price })
                // } else if (!price_rate_type && price !== price_rate_value) {
                //   products.push({ pp_id, qty })
                // } else {
                //   products.push({ pp_id, qty })
                // }
                const product: { [key: string]: any } = { pp_id, qty }
                // if (price_rate_type && price !== price_rate_value) {
                //   product.price = price
                // }
                product.price = price

                if (pg_id) {
                  product.pg_id = pg_id
                }

                if (seller_unit_discount) {
                  product.seller_unit_discount = seller_unit_discount
                }

                if (useMyStoreId) {
                  myProductsFromOtherStores.push(product)
                } else {
                  products.push(product)
                }
              } else {
                this._handleErrMissingProductId()
                return null
              }
            }
          }

          if (products && products.length > 0) {
            preparedStoreProducts.push({ store_id, products })
          }
        }
      }

      if (myProductsFromOtherStores.length > 0) {
        const myStoreId = this.state.store_id
        const foundMyStoreIndex = preparedStoreProducts.findIndex((stObj) => _.isObject(stObj) && stObj.store_id === myStoreId)
        if (foundMyStoreIndex > -1) {
          preparedStoreProducts[foundMyStoreIndex].products = preparedStoreProducts[foundMyStoreIndex].products.concat(
            myProductsFromOtherStores
          )
        } else {
          preparedStoreProducts.push({ store_id: myStoreId, products: myProductsFromOtherStores })
        }
      }

      return preparedStoreProducts
    }
    return null
  }

  // _getFocusedOrder(newOrder = null, newIndex = null) {
  //   const { editingOrder, changeOrderTabIndexTo } = this.props
  //   const { orderTabIndex } = this.state
  //   const sourceOrder = isImmutable(newOrder) ? newOrder : editingOrder
  //   const targetIndex = _.isNumber(newIndex) ? newIndex : orderTabIndex
  //   let focusedOrder
  //   if (targetIndex <= 0) {
  //     focusedOrder = sourceOrder
  //   } else {
  //     const suborders =
  //       Map.isMap(sourceOrder) && sourceOrder.has('suborders') && sourceOrder.get('suborders').size > 0
  //         ? sourceOrder.get('suborders')
  //         : null

  //     if (_.isNull(suborders)) {
  //       // focusedOrder = sourceOrder
  //       // changeOrderTabIndexTo(0)
  //       focusedOrder = Map({}) // Empty Map
  //     } else {
  //       focusedOrder = List.isList(suborders) && suborders.get(targetIndex - 1) ? suborders.get(targetIndex - 1) : Map({})
  //     }
  //   }
  //   return focusedOrder
  // }

  _getFocusedOrder = (newOrder = null, newIndex = null) => {
    const { editingOrder, changeOrderTabIndexTo } = this.props
    const { orderTabIndex } = this.state
    const sourceOrder = isImmutable(newOrder) ? newOrder : editingOrder
    const targetIndex = _.isNumber(newIndex) ? newIndex : orderTabIndex
    return getFocusedOrder(sourceOrder, targetIndex)
  }

  _doCancelOrder = (): void => {
    const focusedOrder = this._getFocusedOrder()
    if (this._isLockedOrderShowAlert(focusedOrder)) {
      return
    }
    // ฟังชั่นที่ถูกคอมเม้นนี้ คือต้นแบบแจ้งเตือนหากออเดอร์ถูกล็อก ตอนนี้ไปเรียกที่ this._isLockedOrderShowAlert(focusedOrder)
    // if (this._isLockedOrder(focusedOrder) && type === 3) {
    //   p.op.alert('ออเดอร์ถูกล็อก', 'หากต้องการดำเนินการกรุณาติดต่อผู้ขายโดยตรง')
    //   return
    // }

    // if (_.includes([1, 2], type)) {
    //   const subOrders = focusedOrder.get('suborders') || List([])
    //   for (let i = 0; i < subOrders.size; i++) {
    //     const subOrder = subOrders.get(i) || Map({})
    //     if (this._isLockedOrder(subOrder)) {
    //       const txtOrderName = subOrder.get('name')
    //       const txtPartnerName = subOrder.get('partner_name') || 'ผู้ขายส่ง'
    //       p.op.alert(`ออเดอร์ซื้อ #${txtOrderName} ถูกล็อก`, `หากต้องการดำเนินการกรุณาติดต่อร้าน ${txtPartnerName} โดยตรง`)
    //       return
    //     }
    //   }
    // }

    this._cancelOrder()
  }

  _navToSettingCreateOrder = (): void => {
    // const { txtReceiverName, txtReceiverAddress, txtSenderName, txtSenderAddress } = this.state
    // this.keepAddresses = {
    //   txtReceiverName,
    //   txtReceiverAddress,
    //   txtSenderName,
    //   txtSenderAddress,
    // }
    // this._settingCreateOrder()
    this.props.navigation.dispatch(
      NavActions.navToCustomCreateOrderView({
        mode: this.state.mode,
        store_id: this.state.store_id,
        callBackFromCustomOrder: (res) => this.updateFromCallBackCustomCreateOrder(res),
      })
    )
  }

  _nevToSettingOrderView = (): void => {
    // this._settingOrderView()
    this.props.navigation.dispatch(
      NavActions.navToCustomOrderView({
        mode: this.state.mode,
        store_id: this.state.store_id,
        callBackFromCustomOrder: (res) => this.updateFromCallBackCustomOrderView(res),
      })
    )
  }

  navToOrderCopyTemplateView = (): void => {
    this.props.navigation.dispatch(NavActions.navToOrderCopyTemplateView)
  }

  navToStoreSettingShippingType = (): void => {
    if (!acl.isSelectedStoreOwner()) {
      return
    }
    const { mode } = this.state
    // console.log('this._shippingTypeCreateModePickerRef => ', this._shippingTypeCreateModePickerRef)
    // this._shippingTypeCreateModePickerRef.current._onClosePicker()
    // if (this._shippingTypeCreateModePickerRef && this._shippingTypeCreateModePickerRef.current) {
    //   this._shippingTypeCreateModePickerRef.current._onClosePicker()
    // }
    if (mode === 'CREATE') {
      this._shippingTypeCreateModePickerRef.current._onClosePicker()
    }
    if (mode === 'VIEW_EDIT') {
      this._shippingTypeViewModePickerRef.current._onClosePicker()
    }

    this.props.navigation.dispatch(
      NavActions.navToStoreSettingShippingTypeView({
        onPressGoBack: () => {
          this._onGoBackFromShippingTypeView()
          if (mode === 'CREATE') {
            if (this._shippingTypeCreateModePickerRef && this._shippingTypeCreateModePickerRef.current) {
              if (_.isFunction(this._shippingTypeCreateModePickerRef.current.openPicker())) {
                this._shippingTypeCreateModePickerRef.current.openPicker()
              }
            }
          }
          if (mode === 'VIEW_EDIT') {
            if (this._shippingTypeViewModePickerRef && this._shippingTypeViewModePickerRef.current) {
              if (_.isFunction(this._shippingTypeViewModePickerRef.current.openPicker())) {
                this._shippingTypeViewModePickerRef.current.openPicker()
              }
            }
          }
        },
      })
    )
  }

  navToStoreSettingXSgipping = (): void => {
    if (!acl.isSelectedStoreOwner()) {
      return
    }
    const { mode } = this.state
    if (mode === 'CREATE') {
      this._shippingTypeCreateModePickerRef.current._onClosePicker()
    }
    if (mode === 'VIEW_EDIT') {
      this._shippingTypeViewModePickerRef.current._onClosePicker()
    }

    this.props.navigation.dispatch(
      NavActions.navToStoreSettingXShipping({
        onPressGoBack: () => {
          // this._onGoBackFromShippingTypeView()
          if (mode === 'CREATE') {
            this._shippingTypeCreateModePickerRef.current.openPicker()
          }
          if (mode === 'VIEW_EDIT') {
            this._shippingTypeViewModePickerRef.current.openPicker()
          }
        },
      })
    )
  }

  _onGoBackFromShippingTypeView = async () => {
    await this._setShippingTypeItems()
  }

  _isLockedOrder = (order) => Map.isMap(order) && order.get('prevent_mod')

  _isLockedOrderShowAlert = (order) => {
    const type = order.get('type') || 0

    if (this._isLockedOrder(order) && type === 3) {
      p.op.alert('ออเดอร์ถูกล็อก', 'หากต้องการดำเนินการกรุณาติดต่อผู้ขายโดยตรง')
      return true
    }

    if (_.includes([1, 2], type)) {
      const subOrders = order.get('suborders') || List([])
      for (let i = 0; i < subOrders.size; i++) {
        const subOrder = subOrders.get(i) || Map({})
        if (this._isLockedOrder(subOrder) && subOrder.get('state') < 181) {
          const txtOrderName = subOrder.get('name')
          const txtPartnerName = subOrder.get('partner_name') || 'ผู้ขายส่ง'
          p.op.alert(`ออเดอร์ซื้อ #${txtOrderName} ถูกล็อก`, `หากต้องการดำเนินการกรุณาติดต่อร้าน ${txtPartnerName} โดยตรง`)
          return true
        }
      }
    }

    if (this._isLockedOrder(order) && type === 1) {
      p.op.alert('ออเดอร์ถูกล็อก', 'กรุณาปลดล็อกออเดอร์ก่อนดำเนินการแก้ไขออเดอร์')
      return true
    }

    return false
  }

  popToCurrentView = async () => {
    xUtil.navPopToCurrent(this.props)
    // const { navigation } = this.props
    // const { goBack, state } = navigation.dangerouslyGetParent()
    // const currentRouteKey = navigation.state.key
    // const { routes } = state
    // const currentRotueIndex = routes.findIndex((rt) => rt.key === currentRouteKey)
    // for (let i = routes.length - 1; i > currentRotueIndex; i--) {
    //   const latestRoute = routes[i]
    //   // FIXME: Need to mannual migrate logic to react-navigation v6
    //   goBack(latestRoute.key)
    //   await util.delay(200)
    // }
  }

  _createOrder = (submitEmptyReceiver = false) => {
    // const { pp_shippingTypeIndex, navigation } = this.props // Deprecated pp_shippingTypeIndex
    const { navigation } = this.props
    const selectedStoreID = util.getNavParam(this.props, 'store_id')
    // this._doCreateOrder(selectedStoreID, pp_shippingTypeIndex, submitEmptyReceiver)
    this._doCreateOrder(selectedStoreID, submitEmptyReceiver)
  }

  // _doCreateOrder = async (selectedStoreID: number, selectedShippingTypeIndex: number, submitEmptyReceiver = false): Promise<void> => {
  _doCreateOrder = async (selectedStoreID: number, submitEmptyReceiver = false): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    if (p.op.isWeb()) {
      await this.popToCurrentView()
    }

    // log(this, '_doCreateOrder submitEmptyReceiver ' + submitEmptyReceiver)
    const { createOrder, editingOrder, rAddr, sAddr, bAddr, selectedStore } = this.props
    const params = this.state
    const store_id = params.store_id ? params.store_id : selectedStoreID
    const { txtSenderName, customCreateOrder, isBillingAddressUsed = false } = this.state
    const order = editingOrder // Rename pointer to order
    const sender_id = sAddr.get('id') // Rename pointer to order
    const selectedShippingTypeId = order.get('shipping_type_id')
    // const order_use_retail_price = order.get('order_use_retail_price') || 0
    // const s_use_retail_price = selectedStore.get('s_use_retail_price') || false

    if (store_id) {
      // if (selectedShippingTypeIndex < 0) {
      if (!selectedShippingTypeId) {
        this.inProcess = false
        this._handleErrMissingShippingType()
        return
      }
      // const shippingTypeValue = _.filter(CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS, value => value.index === selectedShippingTypeIndex)
      // // README: คอมเม้นไว้ก่อนเผื่อได้ใช้
      // // ตรวจสอบเบอร์โทร ผู้รับ และ ผู้ส่ง
      // // if (selectedShippingTypeIndex < 6) {
      // // if (_.includes(CONS.TEL_NUM_REQUIRED_FOR_SHIPPING_TYPE_IDS, shippingTypeValue[0].value)) {
      // //   if (CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS[selectedShippingTypeIndex].tel_required) {
      // //     const telephoneRAddr = rAddr.get('telephone')
      // //     const telephoneSAddr = sAddr.get('telephone')
      // //     // MUST USE _.isNull to check as _.isEmpty cannot distinguish b/w undefined and null
      // //     // telephoneRAddr will be undefined if ไม่ระบุที่อยู่ผู้รับ
      // //     // telephoneSAddr will be null if ไม่ระบุที่อยู่ผุ้ส่ง
      // //     if (_.isNull(telephoneRAddr) || telephoneRAddr === '') {
      // //       // if (!telephonerAddr || telephonerAddr === '') {
      // //       this.inProcess = false
      // //       this._handleMissingTelReceiver()
      // //       return
      // //     }
      // //     if (sAddr.get('address1') != '<< ใช้ที่อยู่ผู้ขายส่ง >>') {
      // //       if (_.isNull(telephoneSAddr) || telephoneSAddr === '') {
      // //         this.inProcess = false
      // //         this._handleMissingTelSender()
      // //         return
      // //       }
      // //     }
      // //   }
      // // }

      // README comfirmCreateOrder ตรงนี้มีไว้สำหรับแจ้งเตือนเมื่อมีที่ผู้รับซ้ำสำหรับออเดอร์ที่สร้างภายใน 30 วัน
      let comfirmCreateOrder = true
      if (!_.isNil(this.state.addressesListOverlay)) {
        this.inProcess = false
        await new Promise((resovle) => {
          // const oldTime = util.getTimeFormatYYYYMMDDhhmm(selectedDateRangeSetUpDateDeliveryOrder)
          // const newTime = util.getTimeFormatYYYYMMDDhhmm(newDate)
          p.op.showConfirmationThreeButtons(
            '',
            'ที่อยู่ผู้รับซ้ำกับออเดอร์ที่เคยสร้างมาก่อนหน้านี้ คุณต้องการดำเนินการ "ยืนยันการสร้างออเดอร์" ต่อใช่หรือไม่',
            'ยืนยันการสร้างออเดอร์',
            () => {
              // @ts-ignore
              resovle()
            },
            'ดูออเดอร์ที่มีที่อยู่ผู้รับซ้ำ',
            () => {
              // @ts-ignore
              resovle()
              comfirmCreateOrder = false
              util.setStatePromise(this, {
                isVisibleAddressesListOverlay: true,
              })
            },
            'ปิดหน้าต่างนี้',
            () => {
              // @ts-ignore
              resovle()
              comfirmCreateOrder = false
            }
          )
        })
      }
      if (!comfirmCreateOrder) {
        this.inProcess = false
        await this.endSubmitting()
        return
      }
      this.inProcess = true
      // // << ใช้ที่อยู่ผู้ขายส่ง >>
      // // log('products => ', products)
      const body: { [key: string]: any } = {
        store_id,
        discount: parseSmartFloat(order.get('discount')) || 0,
        shipping_cost: parseSmartFloat(order.get('shipping_cost')) || 0,
        etc_cost: parseSmartFloat(order.get('etc_cost')) || 0,
        // sender_address_id: sender_id,               // FIXME: ตอนนี้เป็น null หรือ มี ID
      }

      // Channge prepared/validation logic to this._getPreparedStoreProductsFromOrder
      // const selectedProducts = order.get('products')
      // let products = []
      //
      // for (let i = 0; i < selectedProducts.size; i++) {
      //   const product = selectedProducts.get(i)
      //   const pp_id = product.get('pp_id') || null
      //   const qty = parseInt(product.get('qty') || 0)
      //   const price = parseFloat(product.get('price') || 0)
      //
      //   const price_rate_type = parseInt(product.get('price_rate_type') || 0)
      //   const price_rate_value = parseFloat(product.get('price_rate_value') || 0)
      //
      //   const available_qty = product.get('available_qty') || 0
      //   if (available_qty < qty) {
      //     const product_name = product.get('name')
      //     const product_variant = product.get('variant') || null
      //     const display_name = product_variant ? `${product_name} ${product_variant}` : product_name
      //     this.inProcess = false
      //     this._handleErrNotEnoughQty(display_name, i, available_qty)
      //     return
      //   }
      //
      //   if (pp_id) {
      //     if (price_rate_type && price === price_rate_value) {
      //       products.push({ pp_id, qty })
      //     } else if (price_rate_type && price !== price_rate_value) {
      //       products.push({ pp_id, qty, price })
      //     } else if (!price_rate_type && price !== price_rate_value) {
      //       products.push({ pp_id, qty })
      //     } else {
      //       products.push({ pp_id, qty })
      //     }
      //
      //   } else {
      //     this.inProcess = false
      //     this._handleErrMissingProductId()
      //     return
      //   }
      // }

      // if (products.length > 0) {
      //   body['products'] = products
      // } else {
      //   p.op.alert('คำเตือน', 'ยังไม่ได้เลือกสินค้า กรุณาเลือกสินค้าก่อนทำรายการเปิดออเดอร์')
      //   this.inProcess = false
      //   return
      // }

      // let zeroPriceProductNames: string[] = []

      // ไม่เติม s ตาม API
      const store_product = this._getPreparedStoreProductsFromOrder(order)
      if (_.isArray(store_product) && store_product.length > 0) {
        body.store_product = store_product
      } else if (_.isArray(store_product) && store_product.length === 0) {
        p.op.alert('คำเตือน', 'ยังไม่ได้เลือกสินค้า กรุณาเลือกสินค้าก่อนทำรายการเปิดออเดอร์')
        this.inProcess = false
        return
      } else {
        this.inProcess = false
        return
      }
      const store_products = editingOrder.get('store_products')
      // Checking Overall ug_id and insert to API if needed
      if (Map.isMap(store_products)) {
        const storeIds = []
        let orderUgId = null

        // @ts-ignore ไม่รู้ว่าจะใส่ type ไหนให้มัน
        store_products.mapEntries((entry: any[]) => {
          if (_.isArray(entry) && entry.length > 0) {
            storeIds.push(entry[0])
          }
        })

        for (let storeIdx = 0; storeIdx < storeIds.length; storeIdx++) {
          const storeId = storeIds[storeIdx]
          const products = store_products.get(storeId) || List([])
          for (let productIdx = 0; productIdx < products.size; productIdx++) {
            const focusedProductUgId = products.getIn([productIdx, 'ug_id'])
            // เช็ค PG/UG Condition ไม่ให้มีปนกัน
            if (focusedProductUgId && focusedProductUgId > 0) {
              // ถ้ามี ug_id ถูกใช้อยู่
              if (!orderUgId) {
                // ถ้าไม่เคยถูกพบมาก่อน
                orderUgId = focusedProductUgId
              } else if (orderUgId && orderUgId !== focusedProductUgId) {
                // ถ้าเคยมี ug_id ที่เริ่มใช้แล้ว แล้วมีสินค้าอื่นที่ mixed คนละ ug มา ต้อง error
                // should be impossible case
                p.op.alert(
                  'มีสินค้าหลากหลายกลุ่มสมาชิก',
                  'กรุณาจัดสินค้าให้มีกลุ่มสมาชิกเดียวเท่านั้น เนื่องจากระบบไม่สามารถคำนวณสินค้าที่มาจากหลายกลุ่มสมาชิกรวมกันได้'
                )
                this.inProcess = false
                return
              }
            }
            // // เช็คไม่ให้มีสินค้าราคาเป็น 0 บาท
            // const focusedProductPrice = products.getIn([productIdx, 'price'])
            // if (!focusedProductPrice || focusedProductPrice <= 0) {
            //   const fpName = editingOrder.getIn(['store_products', storeId, productIdx, 'name'])
            //   const fpVariantName = editingOrder.getIn(['store_products', storeId, productIdx, 'variant'])
            //   const txtWarningProductName: string = fpVariantName ? `${fpName} (${fpVariantName})` : fpName
            //   // p.op.alert(
            //   //   'ไม่ได้ระบุราคาสินค้า',
            //   //   `กรุณาระบุราคาของ "${txtWarningProductName}" หรือ หากคุณต้องการแจกสินค้าฟรี กรุณาระบุราคาสินค้าให้มากกว่า 0฿ แล้วกำหนดใช้ส่วนลดให้ลูกค้า`
            //   // )
            //   // this.inProcess = false
            //   // return
            //   zeroPriceProductNames.push(txtWarningProductName)
            // }
          }
        }

        if (orderUgId && orderUgId > 0) {
          body.user_group_id = orderUgId
        }
      }

      // if (zeroPriceProductNames.length > 0) {
      //   const isUserConfirm = await p.op.isUserConfirm(
      //     'มีสินค้าราคา 0฿',
      //     `กรุณายืนยันว่าคุณต้องการแจกสินค้าดังต่อไปนี้ใช่หรือไม่\n${zeroPriceProductNames.join(', ')}`,
      //     'ใช่',
      //     'ไม่ใช่'
      //   )
      //   if (!isUserConfirm) {
      //     this.inProcess = false
      //     return
      //   }
      // }

      if (sender_id) {
        body.sender_address_id = sender_id
      }
      if (txtSenderName && txtSenderName.length > 0) {
        body.sender_name = txtSenderName
      }
      // ARTID
      const XShipping = order.has('use_xshipping') ? order.get('use_xshipping') : false
      if (XShipping) {
        body.use_xshipping = order.get('use_xshipping')
      }
      const receiverCustom = customCreateOrder.receiver
      const receiverNameOnly = customCreateOrder.receiver.vs
      if (rAddr.get('id')) {
        body.receiver_address_id = parseInt(rAddr.get('id'))
      } else if (rAddr.get('address3')) {
        const singleReceiverArray = []
        const rAddress = {} as any
        rAddress.name = rAddr.get('name')
        rAddress.address3 = rAddr.get('address3')
        rAddress.address2 = rAddr.get('address2')
        rAddress.sub_district = rAddr.get('sub_district')
        rAddress.district = rAddr.get('district')
        rAddress.province = rAddr.get('province')
        rAddress.postal_code = rAddr.get('postal_code')
        rAddress.telephone = rAddr.get('telephone') || ''
        if (rAddr.get('email')) {
          rAddress.email = rAddr.get('email')
        }
        if (rAddr.get('legal_entity_type') > 0 && rAddr.get('legal_entity_id')) {
          rAddress.legal_entity_type = rAddr.get('legal_entity_type')
          rAddress.legal_entity_id = rAddr.get('legal_entity_id')
        }
        singleReceiverArray.push(rAddress)
        body.receiver = singleReceiverArray
      } else if (receiverCustom.t === 0 || rAddr.get('name')) {
        const singleReceiverArray = []
        const rAddress = {} as any
        const name = _.isNil(receiverNameOnly) ? rAddr.get('name') : receiverNameOnly
        const telephone = _.isNil(rAddr.get('telephone')) ? null : rAddr.get('telephone')
        if (!_.isNil(telephone)) {
          rAddress.telephone = rAddr.get('telephone')
        }
        rAddress.name = name
        singleReceiverArray.push(rAddress)
        if (!_.isNil(name) && name !== '') {
          body.receiver = singleReceiverArray
        }
      } else if (receiverCustom.t === 1) {
        const singleReceiverArray = []
        const rAddress = {} as any
        const name = receiverNameOnly
        const telephone = receiverCustom.p
        rAddress.telephone = telephone
        rAddress.name = name
        singleReceiverArray.push(rAddress)
        if (!_.isNil(name) && name !== '') {
          body.receiver = singleReceiverArray
        }
      }

      if (isBillingAddressUsed && (_.isNil(bAddr) || (!bAddr.get('id') && !bAddr.get('address3')))) {
        p.op.alert('คำเตือน', 'กรุณาระบุ "ที่อยู่ผู้รับสำหรับใบกำกับภาษี" เนื่องจากคุณได้เปิดใช้งาน "ออกใบกำกับภาษี"')
        this.inProcess = false
        return
      }

      if (isBillingAddressUsed && bAddr && bAddr.get('id')) {
        body.billing_address_id = parseInt(bAddr.get('id'))
      } else if (isBillingAddressUsed && bAddr && bAddr.get('address3')) {
        // const singleReceiverArray = []
        const bAddress = {} as any
        bAddress.name = bAddr.get('name')
        bAddress.address3 = bAddr.get('address3')
        bAddress.address2 = bAddr.get('address2')
        bAddress.sub_district = bAddr.get('sub_district')
        bAddress.district = bAddr.get('district')
        bAddress.province = bAddr.get('province')
        bAddress.postal_code = bAddr.get('postal_code')
        bAddress.telephone = bAddr.get('telephone') || ''
        if (rAddr.get('email')) {
          bAddress.email = bAddr.get('email')
        }
        if (bAddr.get('legal_entity_type') > 0 && bAddr.get('legal_entity_id')) {
          bAddress.legal_entity_type = bAddr.get('legal_entity_type')
          bAddress.legal_entity_id = bAddr.get('legal_entity_id')
        }
        // singleReceiverArray.push(bAddress)
        // body.billing_address = singleReceiverArray
        body.billing_address = bAddress
      }

      // const shipping_type_id = (selectedShippingTypeIndex > -1 && CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS[selectedShippingTypeIndex].value) || null
      // if (shipping_type_id) {
      if (selectedShippingTypeId) {
        // body.shipping_type_id = shipping_type_id
        body.shipping_type_id = selectedShippingTypeId
      }

      // skip store id (check at first if)
      // Check only normal fields (not Array)
      const requiredFields = ['sender_name', 'shipping_type_id', 'discount', 'shipping_cost', 'etc_cost', 'store_product']
      const requiredDisplays = ['ชื่อผู้จัดส่ง', 'รูปแบบจัดส่ง', 'ส่วนลด', 'ค่าจัดส่ง', 'ค่าอื่นๆ', 'รายการสินค้า']
      for (let i = 0; i < requiredFields.length; i++) {
        const key = requiredFields[i]
        const warningText = requiredDisplays[i]
        if (!_.has(body, key)) {
          // log(`check key = ${key} in body doesn't passed...`)
          this.inProcess = false
          p.op.alert('คำเตือน', `ข้อมูลที่จำเป็นสำหรับการสร้างออเดอร์ไม่ครบ กรุณาระบุ "${warningText}"`)
          // Alert.alert('คำเตือน', `ข้อมูลที่จำเป็นสำหรับการสร้างออเดอร์ไม่ครบ กรุณาระบุ "${warningText}"`,
          //     [{ text: 'ตกลง', onPress: () => { this.inProcess = false } }])
          return
        }
        // log(`check key = ${key} in body passed...`)
      }

      // if (!submitEmptyReceiver && !rAddr.get('id') && !rAddr.get('address3')) {
      // if (!submitEmptyReceiver && !rAddr.get('id')) {
      //   // TODO: ARTID เดี๋ยวมาทำใหม่
      //   if (rAddr.get('address3') || !_.isNil(receiverCustom.vi)) {
      //     // if (!rAddr.get('postal_code')) {
      //     //   this.inProcess = false
      //     //   this._handleErrMissingPostalCode(rAddr.toJS())
      //     //   return
      //     // }
      //   } else {
      //     this.inProcess = false
      //     // if (!_.has(body, 'receiver_name')) {
      //     this._handleErrMissingReceiverAddress()
      //     return
      //     // }
      //   }
      // }
      // const isTelNumRequiredForShippingType = _.includes(CONS.TEL_NUM_REQUIRED_FOR_SHIPPING_TYPE_IDS, shipping_type_id)
      // const allowMissingReceiverAddrForShippingType = _.includes(CONS.ALLOW_MISSING_RECEIVER_ADDR_FOR_SHIPPING_TYPE_IDS, shipping_type_id)
      const isTelNumRequiredForShippingType = _.includes(CONS.TEL_NUM_REQUIRED_FOR_SHIPPING_TYPE_IDS, selectedShippingTypeId)
      const allowMissingReceiverAddrForShippingType = _.includes(
        CONS.ALLOW_MISSING_RECEIVER_ADDR_FOR_SHIPPING_TYPE_IDS,
        selectedShippingTypeId
      )
      // if (_.isNil(rAddr.get('id')) && _.isNil(receiverCustom.vi) && _.isNil(receiverCustom.vs)) {
      //   this.inProcess = false
      //   this._handleErrMissingReceiverAddress()
      //   return
      // }

      // ถ้าใช้ที่อยู่หลักหรือที่อยู่รอง ก็ไม่จำเป็นต้องเช็ดที่อยู่ผู้รับ
      if (!APP_CONFIG.order.create_order.show_receiver_address_index_segment_btn) {
        // ถ้าไม่ใช่ขายหน้าร้าน
        if (!allowMissingReceiverAddrForShippingType) {
          // ถ้าขนส่งต้องการให้ระบุ เลยต้องแจ้งเตือนให้รู้
          if (isTelNumRequiredForShippingType && (_.isNil(rAddr.get('telephone')) || rAddr.get('telephone') === '')) {
            if (receiverCustom.t === 0 && !submitEmptyReceiver) {
              this.inProcess = false
              this._handleErrMissingReceiverAddressRequresTel(receiverCustom.vs)
              return
            }
            if (receiverCustom.t === 1 && !submitEmptyReceiver) {
              this.inProcess = false
              this._handleErrMissingReceiverAddressRequresTel(receiverCustom.vs)
              return
            }
            if ((receiverCustom.t === 2 || !_.isNil(rAddr.get('id'))) && !submitEmptyReceiver) {
              const name = !!_.isNil(rAddr.get('name'))
              const address = !!(_.isNil(rAddr.get('address1')) && _.isNil(rAddr.get('address2')) && _.isNil(rAddr.get('address3')))
              const postalCode = !!_.isNil(rAddr.get('postal_code'))
              const phon = !!_.isNil(rAddr.get('telephone'))
              if (name || address || postalCode || phon) {
                this.inProcess = false
                this._handleErrMissingReceiverAddressRequresTel(rAddr.get('name'))
                return
              }
            }
          } else if (
            _.isNil(rAddr.get('id')) &&
            this._checkReceiverAddress(rAddr) &&
            _.isNil(receiverCustom.vi) &&
            _.isNil(receiverCustom.vs) &&
            !submitEmptyReceiver
          ) {
            this.inProcess = false
            this._handleErrMissingReceiverAddress()
            return
          } else {
            // console.log('rAddr', rAddr.toJS())
          }
        }
      }

      // เพิ่ม option flag 2 ตัว
      // TODO: To P'O, do we need this?
      for (let i = 0; i < this.ORDER_OPTION_KEYS.length; i++) {
        const key = this.ORDER_OPTION_KEYS[i]
        const value = order.get(key) || false
        body[key] = value
      }
      // END TODO

      // if (s_use_retail_price) {
      //   body['price_rate'] = order_use_retail_price ? 2 : 1
      // }

      // Optional
      // log("order.has('channel_id')", order.has('channel_id'))
      // const boolOrNumChannelId = order.get('channel_id')
      // let channel_id
      // if (boolOrNumChannelId && boolOrNumChannelId > 0) {
      //   channel_id = parseInt(boolOrNumChannelId)
      // }
      const channel_id = parseSmartInt(order.get('channel_id'))
      // log('_.isNumber(order.get(\'channel_id\'))', _.isNumber(order.get('channel_id')))
      // log('parseInt(order.get(\'channel_id\'))', parseInt(order.get('channel_id')))
      // log('BaseOrderView.boolOrNumChannelId: ' + boolOrNumChannelId)
      // log('BaseOrderView.channel_id: ', channel_id)
      // log('BaseOrderView.channel_id > 0: ', channel_id > 0)
      // log('BaseOrderView.body:', body)
      if (channel_id && channel_id > 0) {
        body.channel_id = channel_id
      } else {
        delete body.channel_id
      }

      body.ctoken = this.state.ctoken
      const notesOrder = this.props.editingOrder.get('notes')
      if (!_.isNil(notesOrder) && !_.isEmpty(notesOrder)) {
        body.notes = notesOrder.toJS()
      }

      // การชำระ cod / จัดส่งก่อนชำระ
      if (!_.isNil(editingOrder.get('ship_before_pay')) && editingOrder.get('ship_before_pay')) {
        body.ship_before_pay = editingOrder.get('ship_before_pay')
      }

      let codAmount = editingOrder.has('cod_amount') ? editingOrder.get('cod_amount') : '0'
      if (codAmount === '') {
        codAmount = '0'
      }
      if (editingOrder.get('is_cod') && codAmount === '0') {
        const onFocus = this.inputRefs.find((val) => val.key === 'cod_amount_0')
        if (_.isNil(onFocus)) {
          p.op.showConfirmationOkOnly(null, 'กรุณาระบุยอดเก็บเงินปลายทาง(COD)หน้ากล่อง')
        } else {
          p.op.showConfirmationOkOnly(null, 'กรุณาระบุยอดเก็บเงินปลายทาง(COD)หน้ากล่อง', () => onFocus.value.focus())
        }
        this.inProcess = false
        await this.endSubmitting()
        return
      }
      if (!_.isNil(editingOrder.get('is_cod')) && editingOrder.get('is_cod')) {
        body.is_cod = editingOrder.get('is_cod')
        body.cod_fee = editingOrder.get('cod_fee') || 0
        body.cod_amount = editingOrder.get('cod_amount')
      }
      const { mode } = this.state
      if (mode === CREATE && APP_CONFIG.order.create_order.show_reseller_code_text_input) {
        const openForStaffMember = this.state.inputOpenForStaffMemberText
        if (!_.isNil(openForStaffMember) || openForStaffMember !== '') {
          body.reseller_code = openForStaffMember
        }
      }
      const isAddresses = this.state.optionChooseAddressesIndex
      if (mode === CREATE && APP_CONFIG.order.create_order.show_receiver_address_index_segment_btn) {
        if (!_.isNil(isAddresses)) {
          body.send_to_reseller_address_index = isAddresses + 1
        }
      }
      // console.log('CREATE ORDER ! => ', body)
      // console.log('B 01')

      // TODO :: ข้าม logic ข้างล่างนี้ไปก่อน อ้างอิงโดย => https://app.clickup.com/t/2but5wv
      // if (this._shallValidateForCustomPackingSize(selectedStore)) {
      //   const storeProduct = body && body.store_product ? body.store_product : null
      //   const isResult = await this._checkProductBeforeSubmit(storeProduct)
      //   // console.log('B 02')
      //   // await delay(3000)
      //   if (!isResult) {
      //     // p.op.showConfirmationOkOnly('', 'คุณระบุจำนวนสินค้าในออเดอร์ไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง')
      //     this.inProcess = false
      //     await this.endSubmitting()
      //     return
      //   }
      //   // console.log('B 03')
      // }

      // SET CONFIG CREATE DATE DELIVERY //
      const { selectedDateRangeSetUpDateDeliveryOrder, optionChooseSetupDateDeliveryOrderIndex } = this.state
      let dateDelivery = null
      if (optionChooseSetupDateDeliveryOrderIndex === 1) {
        dateDelivery = util.getTimeFormatYYYYMMDDhhmmssIfNeedSecondToZero(selectedDateRangeSetUpDateDeliveryOrder)
      }
      body.date_delivery = dateDelivery
      // END SET CONFIG CREATE DATE DELIVERY //

      // SET CONFIG CREATE AUTO CANCEL ORDER //
      const { selectedDateRangeSetUpAutoCancelOrder, optionChooseSetupAutoCancelOrderIndex } = this.state
      let expirationDate = null
      if (optionChooseSetupAutoCancelOrderIndex === 1 || optionChooseSetupAutoCancelOrderIndex === 2) {
        expirationDate = util.getTimeFormatYYYYMMDDhhmmssIfNeedSecondToZero(selectedDateRangeSetUpAutoCancelOrder)
      }
      body.expiration_date = expirationDate
      // END CONFIG CREATE AUTO CANCEL ORDER //

      // return
      // ARTID CREATE ORDER HERE
      // log('### prepared to createOrder body => ', body)
      // createOrder(body, this._handleAfterSuccessCreateOrder)

      await p.op.storageSet(CONS.STORAGE_KEYS.CREATE_ORDER_LAST_SELECTED_STORE, this.state.optionChooseProductsFromIndex)
      await p.op.storageSet(CONS.STORAGE_KEYS.CREATE_ORDER_LAST_SELECTED_PG_UG, this.state.optionChooseProductsFollowingIndex)
      await p.op.storageSet(CONS.STORAGE_KEYS.CREATE_ORDER_LAST_SELECTED_PG_UG_INDEX, this.state.optionChooseProductsByGroupIndex)

      const orderResponse = await new Promise((resolveCreateOrder) => {
        createOrder({
          body,
          successCallback: resolveCreateOrder,
          failedCallback: () => resolveCreateOrder(null),
        })
      })

      if (orderResponse) {
        await p.op.storageSet(CONS.STORAGE_KEYS.USE_XSHIPPING, XShipping)
        await this._doMemoDefaultUserSelectionAfterCreateOrder(body)
        // @ts-ignore whatever response FIXME: อาจจะไป track ดูว่ามีอะไรบ้างแล้วประกาศ interface
        await this._handleAfterSuccessCreateOrder(orderResponse)
      }

      // if (!_.has(body, 'receiver_address_id') && !_.has(body, 'receiver')) {
      //   showConfirmation('คำเตือน', 'คุณไม่ได้ระบุที่อยู่จัดส่งของลูกค้า หากคุณเปิดออเดอร์แล้ว ' +
      //     'สถานะของออเดอร์จะไม่สามารถไปต่อได้จนกว่าคุณจะกลับมาระบุที่อยู่ลูกค้าให้สมบูรณ์ คุณยืนยันที่จะเปิดออเดอร์ตอนนี้ใช่หรือไม่',
      //     () => createOrder(body), null, 'ใช่', 'ไม่')
      // } else {
      //   createOrder(body)
      // }
    } else {
      p.op.alert('คำเตือน', 'ไม่พบร้านค้าที่ทำรายการ หรือไม่การเข้าถึงที่ไม่ถูกต้อง กรุณาลองเลือกร้านแล้วทำรายการใหม่อีกครั้ง')
    }
    this.inProcess = false
  }

  _shallValidateForCustomPackingSize = (selectedStore): boolean => {
    const doesSelectedStoreUseCustomPackingSize = selectedStore.has('s_use_custom_packing_size')
      ? selectedStore.get('s_use_custom_packing_size')
      : false
    // Do the validation if this store either have a seller or s_use_custom_packing_size = true
    // Note: As there is no efficient way to know if the seller stores or all the nested seller stores has turn
    // the flag s_use_custom_packing_size=true. Therefore this is the best guess and the cheapest way to check.
    // However it does not cover the case where none of the seller store does use the custom packing size feature.
    // So we might have to unnecessary do validation in this case.
    return this._hasSellerStores() || doesSelectedStoreUseCustomPackingSize
  }

  _checkProductBeforeSubmit = async (storeProduct): Promise<boolean> => {
    // console.log('C 01 => ', storeProduct)
    // const storeProduct = body && body.store_product ? body.store_product : null
    const { selectedStore, editingOrder } = this.props
    if (_.isNil(storeProduct)) {
      return true
    }
    const newBody = {
      store_id: selectedStore.get('id'),
      store_product: storeProduct,
    }

    try {
      const response: {
        status: 'ok'
        error: {
          status_code: number
          error_code: string
          message: {
            pp_ids: number[]
            custom_packing_template: number[]
          }
        }
      } = await api.postV2(api.POST_VALIDATE_QTY, newBody)
      if (response && response.status && response.status === 'ok') {
        return true
      }
    } catch (error) {
      // must use "store_products" instead of "products" as
      // the "products" will be null in order create mode and it also doesn't reflect the editing one anyway
      // const store_products = editingOrder.get('store_products').toJS()
      const store_products = this._getFocusedOrder(editingOrder).get('store_products').toJS()
      // console.log("Editing Order", editingOrder.toJS())
      // console.log("Focus Order", this._getFocusedOrder(editingOrder).toJS())
      // console.log("In Error")
      // console.log("store_products: ", store_products)
      let wrongQtyProductNames = ''
      let customPackingTemplate = ''
      // @ts-ignore
      const wrong_qty_pp_ids = error.error.message.pp_ids
      // @ts-ignore
      const { custom_packing_template } = error.error.message
      custom_packing_template.map((id) => {
        customPackingTemplate += ` ${id}`
      })
      let matchedProductInTheStore
      let productsInEachStore
      let store_id
      // for each wrong-qty pp_ids, loop to get the name
      wrong_qty_pp_ids.map((wrong_qty_pp_id: number) => {
        matchedProductInTheStore = null
        // loop editing products in each store
        for (store_id in store_products) {
          productsInEachStore = store_products[store_id]
          // console.log('productsInEachStore: ', productsInEachStore)

          // try to find the product that its pp_id is matched with the wrong one.
          matchedProductInTheStore = _.find(productsInEachStore, (productInEachStore) => {
            // console.log('wrong_qty_pp_id: ', wrong_qty_pp_id)
            // console.log('productInEachStore.pp_id: ', productInEachStore.pp_id)
            // console.log('productInEachStore: ', productInEachStore)
            if (wrong_qty_pp_id === productInEachStore.pp_id) {
              return productInEachStore
            }
          })
          // console.log('matchedProductInTheStore => ', matchedProductInTheStore)

          if (!_.isNil(matchedProductInTheStore)) {
            // append the wrong-qty product names
            wrongQtyProductNames = `${wrongQtyProductNames} ${matchedProductInTheStore.name}` + `\n`
            break
          }
        }
      })
      p.op.showConfirmationOkOnly(
        'จำนวนสินค้าไม่ถูกต้อง',
        `กรุณาระบุจำนวนของสินค้าด้านล่างนี้ให้ได้ตามขนาดบรรจุนี้เท่านั้น: ${customPackingTemplate}\n\n${wrongQtyProductNames}`
      )
      return false
    }

    // await delay(3000)
    // console.log('C 02 => ', response)
  }

  _checkReceiverAddress = (address: Map<string, any>) => {
    const { customCreateOrder } = this.state
    const { receiver } = customCreateOrder
    // ถ้าเลือกซ่อน ไม่ระบุ ก็ปล่อยผ่านไปเลย
    if (receiver.v === 0 && receiver.a === 0) {
      return false
    }
    const name = address.get('name')
    let xName = false
    const address3 = address.get('address3')
    let xAddress3 = false
    const postalCode = address.get('postal_code')
    let xPostalCode = false
    const telephone = address.get('telephone')
    let xTelephone = false
    if (_.isNil(name)) {
      xName = true
    }
    if (_.isNil(address3)) {
      xAddress3 = true
    }
    if (_.isNil(postalCode)) {
      xPostalCode = true
    }
    if (_.isNil(telephone)) {
      xTelephone = true
    }
    // ถ้าทุกอย่างไม่ได้กรอกอะไรเลยให่้แจงเตือน
    if (xName && xAddress3 && xPostalCode && xTelephone) {
      return true
    }
    return false
  }

  _doMemoDefaultUserSelectionAfterCreateOrder = async (reqBody: {
    sender_address_id?: number
    sender_name?: string
    [key: string]: any
  }): Promise<void> => {
    if (!_.isObject(reqBody)) {
      return
    }

    const defaultObj = await p.op.getAppDefaultObject()
    const newDefaultObj = _.cloneDeep(defaultObj)
    if (_.has(reqBody, 'sender_address_id')) {
      newDefaultObj.order_selected_sender_address_id = reqBody.sender_address_id
    } else {
      newDefaultObj.order_selected_sender_address_id = null
    }

    // Save Local Value
    if (reqBody.sender_name) {
      newDefaultObj.order_selected_txtSenderName = reqBody.sender_name
    } else {
      newDefaultObj.order_selected_txtSenderName = ''
    }

    await p.op.setAppDefaultObject(newDefaultObj)
  }

  _handleConfirmOrder = (): void => {
    this.actionConfirmOrder('CONFIRM')
  }

  _handleConfirmEditOrder = (): void => {
    this.actionConfirmOrder('EDIT')
  }

  _handleCancelConfirmEditOrder = async (): Promise<void> => {
    const { onEditingOrderChange } = this.props
    const { orderTabIndex } = this.state
    this.props.orderRevert()
    await this._autoSetOrderTabIndex()
    await new Promise((delaySetState) => setTimeout(delaySetState, 200))
    await onEditingOrderChange({ key: 'cod_fee', value: this.oldFeeCod, orderTabIndex })
    await onEditingOrderChange({ key: 'cod_amount', value: this.oldCodAmount, orderTabIndex })
    await setStatePromise(this, { mode: VIEW_EDIT })
  }

  actionConfirmOrder = async (action: 'EDIT' | 'CONFIRM', callback?: (res: any) => void | Promise<void>): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { orderConfirm, orderRevert, selectedOrder, editingOrder, selectedStore } = this.props
    const { orderTabIndex } = this.state
    // let payload = {
    //   store_id: selectedStoreID,
    //   summary: true,
    //   types: CONS.ORDER_SUMMARY_ALL,
    //   limit: 20,
    // }

    const oldOrder = this._getFocusedOrder(selectedOrder, orderTabIndex)
    const newOrder = this._getFocusedOrder(editingOrder, orderTabIndex)
    // START เอาไว้ตรวจสอบหากพบว่าขนส่งเลข xshipping ไม่ตรงกับรูปแบบจัดส่งปัจจุบัน //
    let isUserConfirmedFromShippingChange = await new Promise<boolean>((passConfirm) => {
      if (newOrder.has('x')) {
        const xshipping = newOrder.get('x').toJS()
        const shippingTypeId = newOrder.get('shipping_type_id')
        const multipleTracking = xshipping.o
        let hasShippingChange = false
        let xshippingName = ''
        const shippingName = util.getShippingTypeNameById(shippingTypeId)
        multipleTracking.map((tracking: IXShippingTracking) => {
          // ต้องมีเลขที่สามาระยกเลิกได้
          if (tracking.c && tracking.c === 1) {
            if (shippingTypeId !== tracking.p) {
              xshippingName = util.getShippingTypeNameById(tracking.p)
              hasShippingChange = true
            }
          }
        })
        // console.log('hasShippingChange => ', hasShippingChange)
        if (hasShippingChange) {
          p.op.showConfirmation(
            'รูปแบบขนส่งไม่ตรงกับเลขพัสดุ',
            `ออเดอร์นี้มีเลขพัสดุจาก\n"${xshippingName}"\nในขณะที่ รูปแบบจัดส่งของออเดอร์คือ\n"${shippingName}"\nซึ่งไม่ตรงกัน
            \nกรุณากด "ปิด" เพื่อกลับไปแก้ไขรูปแบบจัดส่งให้ตรงกับเลขพัสดุ หรือยกเลิกเลขพัสดุที่มีอยู่
            \nหรือหากไม่ต้องการแก้ไขสามารถกดปุ่ม "ยืนยันออเดอร์" ได้`,
            () => passConfirm(true),
            () => passConfirm(false),
            'ยืนยันออเดอร์',
            'ปิด'
          )
        } else {
          passConfirm(null)
        }
      } else {
        passConfirm(null)
      }
    })
    // console.log('actionConfirmOrder 02 => ', isUserConfirmedFromShippingChange)
    if (!_.isNull(isUserConfirmedFromShippingChange) && isUserConfirmedFromShippingChange) {
      isUserConfirmedFromShippingChange = await new Promise<boolean>((passTwoConfirm) => {
        p.op.showConfirmation(
          'ยืนยันโดยที่เลขพัสดุไม่ตรงกับรูปแบบจัดส่ง',
          'คุณต้องการยืนยันออเดอร์ โดยที่ออเดอร์นี้มี เลขพัสดุไม่ตรงกับรูปแบบจัดส่ง ใช่หรือไม่',
          () => passTwoConfirm(true),
          () => passTwoConfirm(false),
          'ดำเนินการ',
          'ยกเลิก'
        )
      })
    }
    // console.log('actionConfirmOrder 03 => ', isUserConfirmedFromShippingChange)
    if (!_.isNull(isUserConfirmedFromShippingChange) && !isUserConfirmedFromShippingChange) {
      this.inProcess = false
      await this.endSubmitting()
      return
    }
    // console.log('PASS !! ')
    // END เอาไว้ตรวจสอบหากพบว่าขนส่งเลข xshipping ไม่ตรงกับรูปแบบจัดส่งปัจจุบัน //

    // Form Validation
    const body: { [key: string]: any } = {}
    const type = oldOrder.get('type') || null
    const order_id = newOrder.get('id') || null
    const parent_id = oldOrder.get('parent_id') || null
    const updated_at = newOrder.get('updated_at') || null
    const store_id = selectedStore.get('id')
    // log(this, 'actionConfirmOrder body init => ', body)

    if (!order_id || !store_id || !updated_at) {
      p.op.alert('ไม่สามารถระบุออเดอร์และร้านค้า', 'กรุณากลับไปเลือกร้านค้าและออเดอร์ใหม่อีกครั้ง')
      this.inProcess = false
      return
    }

    util.appendObjectIfImmutableDataChange(oldOrder, newOrder, body, 'discount', 'float')
    util.appendObjectIfImmutableDataChange(oldOrder, newOrder, body, 'shipping_cost', 'float')
    util.appendObjectIfImmutableDataChange(oldOrder, newOrder, body, 'etc_cost', 'float')

    const { hasProductsError, products } = this._getChangedPreparedProductFromStoreProducts(oldOrder, newOrder)
    if (hasProductsError) {
      this.inProcess = false
      return
    }

    if (products && products.length > 0) {
      body.products = products
    }

    // const selectedProducts = selectedOrder.get('products')
    // const editingProducts = editingOrder.get('products')
    // let changedProducts = []
    // for (let index = 0; index < editingProducts.size; index++) {
    //   // For Condition Checking
    //   const oldProduct = selectedProducts.get(index)
    //   const newProduct = editingProducts.get(index)
    //   const oldQty = parseInt(oldProduct.get('qty')) || 0
    //   const newQty = parseInt(newProduct.get('qty')) || 0
    //   const shippedQty = parseInt(newProduct.get('shipped_qty')) || 0
    //
    //   // For Error display
    //   const product_name = newProduct.get('name')
    //   const product_variant = newProduct.get('variant') || null
    //   const display_name = product_variant ? `${product_name} ${product_variant}` : product_name
    //
    //   if (newQty >= shippedQty && newQty < oldQty && newQty !== oldQty) {
    //     changedProducts.push({
    //       pp_id: parseInt(newProduct.get('pp_id')),
    //       qty: newQty,
    //     })
    //   } else if (newQty > oldQty) {
    //     p.op.alert('จำนวนสินค้าเกินกว่าที่เคยสั่ง', `กรุณาปรับจำนวนของ ${display_name} ลดลง เนื่องจากระบบไม่อนุณาติให้ปรับจำนวนสินค้าได้เกินกว่าจำนวนเริ่มต้น`,
    //       [{
    //         text: 'ตกลง',
    //         onPress: () => util.focusKeyRefIfExisted(this.inputRefs, `mProduct_qty_0_${index}`, this.scrollViewRef),
    //       }],
    //     )
    //     this.inProcess = false
    //     return
    //   } else if (newQty < shippedQty && shippedQty !== 0) {
    //     p.op.alert('มีสินค้าถูกส่งออกไปแล้ว', `กรุณาปรับจำนวนของ ${display_name} ไม่ให้ต่ำกว่าที่เคยส่ง`,
    //       [{
    //         text: 'ตกลง',
    //         onPress: () => util.focusKeyRefIfExisted(this.inputRefs, `mProduct_qty_0_${index}`, this.scrollViewRef),
    //       }],
    //     )
    //     this.inProcess = false
    //     return
    //   } else if (newQty < 0) {
    //     p.op.alert('จำนวนสินค้าต้องมากกว่าศูนย์', `กรุณาปรับจำนวนของ ${display_name} ให้มากกว่าศูนย์`,
    //       [{
    //         text: 'ตกลง',
    //         onPress: () => util.focusKeyRefIfExisted(this.inputRefs, `mProduct_qty_0_${index}`, this.scrollViewRef),
    //       }],
    //     )
    //     this.inProcess = false
    //     return
    //   }
    // }
    //
    // const selectedSubOrders = selectedOrder.get('suborders')
    // const editingSubOrders = editingOrder.get('suborders')
    // for (let subIndex = 0; subIndex < editingSubOrders.size; subIndex++) {
    //   const oldProducts = selectedSubOrders.getIn([subIndex, 'products'])
    //   const newProducts = editingSubOrders.getIn([subIndex, 'products'])
    //   for (let pIndex = 0; pIndex < newProducts.size; pIndex++) {
    //     const oldQty = parseInt(oldProducts.getIn([pIndex, 'qty'])) || 0
    //     const newQty = parseInt(newProducts.getIn([pIndex, 'qty'])) || 0
    //     const shippedQty = parseInt(newProducts.getIn([pIndex, 'shipped_qty'])) || 0
    //
    //     const product_name = newProducts.getIn([pIndex, 'name'])
    //     const product_variant = newProducts.getIn([pIndex, 'variant']) || null
    //     const display_name = product_variant ? `${product_name} ${product_variant}` : product_name
    //     if (newQty >= shippedQty && newQty < oldQty && newQty !== oldQty) {
    //       changedProducts.push({
    //         pp_id: parseInt(newProducts.getIn([pIndex, 'pp_id'])),
    //         qty: newQty,
    //       })
    //     } else if (newQty > oldQty) {
    //       p.op.alert('จำนวนสินค้าเกินกว่าที่เคยสั่ง',
    //         `กรุณาปรับจำนวนของ ${display_name} ลดลง เนื่องจากระบบไม่อนุณาติให้ปรับจำนวนสินค้าได้เกินกว่าจำนวนเริ่มต้น`,
    //         this._handleErrNewQtyIsMoreThanOpenOrder(display_name, subIndex, pIndex))
    //       this.inProcess = false
    //       return
    //     } else if (newQty < shippedQty && shippedQty !== 0) {
    //       p.op.alert('มีสินค้าถูกส่งออกไปแล้ว', `กรุณาปรับจำนวนของ ${display_name} ไม่ให้ต่ำกว่าที่เคยส่ง`,
    //         this._handleErrCannotEditWhenHasShippingProduct(display_name, subIndex, pIndex))
    //       this.inProcess = false
    //       return
    //     } else if (newQty < 0) {
    //       p.op.alert('จำนวนสินค้าต้องมากกว่าศูนย์', `กรุณาปรับจำนวนของ ${display_name} ให้มากกว่าศูนย์`,
    //         this._handleErrCannotConfirmWhenProductQtyIsZero(display_name, subIndex, pIndex))
    //       this.inProcess = false
    //       return
    //     }
    //   }
    // }
    // if (changedProducts.length > 0) {
    //   body['products'] = changedProducts
    // }
    // console.log(newOrder.get('cod_amount'))
    // console.log(newOrder.toJS())
    const codAmount = editingOrder.has('cod_amount') ? editingOrder.get('cod_amount') : '0'
    if (editingOrder.get('is_cod') && codAmount === '0') {
      const onFocus = this.inputRefs.find((val) => val.key === 'cod_amount_0')
      if (_.isNil(onFocus)) {
        p.op.showConfirmationOkOnly(null, 'กรุณาระบุยอดเก็บเงินปลายทาง(COD)หน้ากล่อง')
      } else {
        p.op.showConfirmationOkOnly(null, 'กรุณาระบุยอดเก็บเงินปลายทาง(COD)หน้ากล่อง', () => onFocus.value.focus())
      }
      this.inProcess = false
      await this.endSubmitting()
      return
    }
    if (oldOrder.get('ship_before_pay') !== newOrder.get('ship_before_pay')) {
      body.ship_before_pay = newOrder.get('ship_before_pay')
    }
    const oldCodFee = +oldOrder.get('cod_fee')
    const newCodFee = +newOrder.get('cod_fee')
    const oldCodAmount = +oldOrder.get('cod_amount')
    const newCodAmount = +newOrder.get('cod_amount')
    if (oldOrder.get('is_cod') !== newOrder.get('is_cod')) {
      if (newOrder.get('is_cod')) {
        body.is_cod = newOrder.get('is_cod')
        body.cod_fee = !newCodFee ? 0 : newCodFee
        body.cod_amount = !newCodAmount ? 0 : newCodAmount
      } else {
        body.is_cod = newOrder.get('is_cod')
      }
    } else if (oldOrder.get('is_cod') === newOrder.get('is_cod')) {
      if (newOrder.get('is_cod')) {
        if (oldCodFee !== newCodFee) {
          body.cod_fee = _.isNil(newCodFee) ? 0 : newCodFee
        }
        if (oldCodAmount !== newCodAmount) {
          body.cod_amount = newCodAmount
        }
      }
    }

    // ถ้าเปิดใช้ is_cod แต่ไม่ระบุ cod_amount จะแจ้งให้ไประบุก่อน
    if (newOrder.get('is_cod') && (_.isNil(newOrder.get('cod_amount')) || newOrder.get('cod_amount') === 0)) {
      this.inProcess = false
      await this.endSubmitting()
      p.op.showConfirmationOkOnly(null, 'กรุณาระบุยอดเก็บเงินปลายทาง(COD)หน้ากล่อง')
    }
    // if (newCodAmount === 0) {
    //   this.inProcess = false
    //   await this.endSubmitting()
    //   p.op.showConfirmationOkOnly(null, 'กรุณาระบุยอดเก็บเงินปลายทาง(COD)หน้ากล่อง')
    //   return
    // }
    if (action === 'EDIT' && _.isEmpty(body) && !hasProductsError) {
      await this._changeModeTo(VIEW_EDIT)
      orderRevert()
      p.op.showToast('ไม่มีรายการแก้ไขออเดอร์', 'warning')
      this.inProcess = false
      return
    }

    body.store_id = store_id
    body.order_id = order_id
    body.updated_at = updated_at
    log(this, 'actionConfirmOrder body before fetch => ', body)

    const txtTitleConfirmation = action === 'EDIT' ? 'แก้ไขราคาและสินค้า' : 'ยืนยันราคาและสินค้า'
    let txtMessageConfirmation = ''
    if (action === 'EDIT' && body.products) {
      txtMessageConfirmation = 'ระบบจะทำการแก้ไขราคาและสินค้าตามข้อมูลที่คุณระบุ คุณแน่ใจใช่หรือไม่'
    } else if (action === 'EDIT' && !body.products) {
      txtMessageConfirmation = 'ระบบจะทำการแก้ไขราคาตามข้อมูลที่คุณระบุ คุณแน่ใจใช่หรือไม่'
    } else {
      txtMessageConfirmation = 'ระบบจะทำการยืนยันราคาตามข้อมูลที่คุณระบุ คุณแน่ใจใช่หรือไม่'
    }

    const isUserConfirmed = await new Promise<boolean>((passConfirm) => {
      p.op.showConfirmation(
        txtTitleConfirmation,
        txtMessageConfirmation,
        () => passConfirm(true),
        () => passConfirm(false)
      )
    })

    if (!isUserConfirmed) {
      this.inProcess = false
      await this.endSubmitting()
      return
    }

    const apiOptions = {
      showSpinner: true,
    }
    const parcelsBody: { [key: string]: any } = {
      store_id: selectedStore.get(`id`),
      order_id,
    }
    // START ตรวจสอบ Multiparcel // ซ่อนชั่วคราว รอ prod server พร้อม
    // const res = await api.postV2(api.POST_MULTIPARCEL_LIST, parcelsBody, apiOptions)
    // if (res.order_id) {
    //   const { parcels } = res
    //   if (parcels.length > 0) {
    //     p.op.showConfirmationOkOnly(
    //       'ออเดอร์นี้มีการจัดการหลายพัสดุ',
    //       'กรุณายกเลิกการจัดการหลายพัสดุก่อน  แล้วค่อยทำการแก้ไขออเดอร์นี้อีกครั้ง'
    //     )
    //     this.inProcess = false
    //     await this.endSubmitting()
    //     return
    //   }
    // }
    // END ตรวจสอบ Multiparcel
    let callNewXShipping = false
    if ((body.cod_amount || body.products) && oldOrder.has(`x`)) {
      let isUserConfirmedChangeCODFee = false
      let isCODConfirmedChange = false

      if (body.cod_amount) {
        isUserConfirmedChangeCODFee = await this._warningChangeReceiverAddressHasXShipping(
          'คุณเปลี่ยนยอดเก็บเงินปลายทาง',
          'ระบบจะทำการยกเลิกเลขติดตามพัสดุเดิม และขอใหม่ เพื่อให้ยอดเก็บเงินปลายทางตรงตามที่แก้ไขไว้ หากระบบขอใหม่ไม่สำเร็จ กรุณากดยกเลิกเลขติดตามพัสดุและขอใหม่เอง'
        )
        if (isUserConfirmedChangeCODFee) {
          isCODConfirmedChange = true
        }
      }

      // หากว่ายืนยันเปลี่ยน cod จะแจ้งเตือนการเปลี่ยนแปลงสินค้า
      if (body.products && !isCODConfirmedChange) {
        isUserConfirmedChangeCODFee = await this._warningChangeReceiverAddressHasXShipping(
          'คุณมีเปลี่ยนแปลงสินค้า',
          'ระบบจะทำการยกเลิกเลขติดตามพัสดุเดิม และขอใหม่ เพื่อให้สินค้าตรงตามที่แก้ไขไว้ หากระบบขอใหม่ไม่สำเร็จ กรุณากดยกเลิกเลขติดตามพัสดุและขอใหม่เอง'
        )
      }

      if (isUserConfirmedChangeCODFee) {
        callNewXShipping = true
      } else {
        this.inProcess = false
        this.endSubmitting()
        return
      }
    }
    // return
    // order note comfirm
    const orderType = newOrder.get('type')
    const pendingShpLblImg = newOrder.get('pending_shp_lbl_img')
    const pendingShpLblNote = newOrder.get('pending_shp_lbl_note')
    if (orderType === 3) {
      if (pendingShpLblImg) {
        p.op.showConfirmation(
          null,
          'มีรูปแทนใบปะหน้ารอให้คุณเลือกอยู่ กดตกลงเพื่อไปเลือก',
          async () => {
            this.inProcess = false
            await this.endSubmitting()
            await this._renderNavOrderTypeOne()
          },
          () => {},
          'ตกลง'
        )
      }
      if (pendingShpLblImg) {
        this.inProcess = false
        await this.endSubmitting()
        return
      }
      if (pendingShpLblNote) {
        p.op.showConfirmation(
          null,
          'มีหมายเหตุใบปะหน้ารอให้คุณเลือกอยู่ กดตกลงเพื่อไปเลือก',
          async () => {
            this.inProcess = false
            await this.endSubmitting()
            await this._renderNavOrderTypeTwo()
          },
          () => {},
          'ตกลง'
        )
      }
      if (!_.isNil(newOrder.get('is_cod')) && newOrder.get('is_cod')) {
        this.oldFeeCod = newOrder.get('cod_fee') || '0'
        this.oldCodAmount = newOrder.get('cod_amount') || '0'
      }
      if (pendingShpLblImg || pendingShpLblNote) {
        this.inProcess = false
        await this.endSubmitting()
        return
      }
    }

    // if (this._shallValidateForCustomPackingSize(selectedStore)) {
    //   // const store_product = this._getPreparedStoreProductsFromOrder(newOrder)
    //   // const storeProduct = newOrder.get('store_products')
    //   // console.log('store_product => ', storeProduct)

    //   const storeProduct = await this._getStoreProductEditMode()
    //   // console.log('B 02 => ', storeProduct)
    //   // await delay(3000)
    //   if (storeProduct.length > 0) {
    //     const isResult = await this._checkProductBeforeSubmit(storeProduct)
    //     if (!isResult) {
    //       // p.op.showConfirmationOkOnly('', 'คุณระบุจำนวนสินค้าในออเดอร์ไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง')
    //       this.inProcess = false
    //       await this.endSubmitting()
    //       return
    //     }
    //   }
    //   // console.log('B 03')
    // }

    // console.log('PASS')
    // return
    // action === 'EDIT'
    // ARTID EDIT ORDER HERE
    await this.beginSubmitting()
    // actionConfirmOrder Order have mkp
    const thisOrder = this._getFocusedOrder()
    if (!_.isNil(thisOrder) && thisOrder.has('mkp_id') && !_.isNil(thisOrder.get('mkp_id'))) {
      await this.handleConfirmOrderHaveMKP()
      return
    }
    await new Promise((resolveConfirmApi) => {
      const successCallback = async (res) => {
        orderRevert()
        // await this._changeModeTo(VIEW_EDIT)
        await this._handleAfterSuccessConfirmOrder(res)
        await setStatePromise(this, { mode: VIEW_EDIT, orderTabsVisibility: false })
        if (_.isFunction(callback)) {
          await callback(res)
        }
        if (callNewXShipping) {
          await this._callNewXShipping(store_id, order_id)
        }
        resolveConfirmApi(null)
      }
      const failedCallback = resolveConfirmApi

      // FIXME: เช็คสำหรับ Order type 3 ถ้ามีการแก้ไข products จะต้อง edit product อย่างเดียวก่อน
      if (body.products && type === 3) {
        const parentBody: { [key: string]: any } = {}
        parentBody.store_id = store_id
        parentBody.order_id = parent_id
        parentBody.updated_at = updated_at
        parentBody.products = body.products
        delete body.products
        orderConfirm({
          body: parentBody,
          successCallback: (res: any) => {
            // log(this, 'actionConfirmOrder after edit order products res => ', res)
            if (res.order) {
              const child_updated_at = res.order.updated_at
              body.updated_at = child_updated_at
              orderConfirm({ body, successCallback, failedCallback })
            }
          },
          failedCallback,
        })
      } else {
        orderConfirm({ body, successCallback, failedCallback })
      }
    })

    await new Promise((stateDelay) => setTimeout(stateDelay, 200))
    // await this._handleOnTabChanged()
    await this._loadUgPgDiscountInOrderToState()
    await this._updateHamburgerMenuOptions()

    // log(this, 'actionConfirmOrder confirmOrder body => ', body)
    // p.op.showConfirmation(txtTitleConfirmation, txtMessageConfirmation,
    //   () => {
    //     const successCallback = (res) => {
    //       orderRevert()
    //       if (_.isFunction(callback)) {
    //         callback(res)
    //       }
    //       this.setState({refreshing: false, orderTabsVisibility: false}, () => {
    //         this._changeModeTo(VIEW_EDIT, () => {
    //           this._handleAfterSuccessConfirmOrder(res)
    //         })
    //       })
    //     }
    //
    //     this.setState({refreshing: true}, () => {
    //       // FIXME: เช็คสำหรับ Order type 3 ถ้ามีการแก้ไข products จะต้อง edit product อย่างเดียวก่อน
    //       if (body.products && type === 3) {
    //         let parentBody = {}
    //         parentBody['store_id'] = store_id
    //         parentBody['order_id'] = parent_id
    //         parentBody['updated_at'] = updated_at
    //         parentBody['products'] = body.products
    //         delete body.products
    //         orderConfirm(parentBody, (res) => {
    //           log(this, 'actionConfirmOrder after edit order products res => ', res)
    //           if (res.order) {
    //             const child_updated_at = res.order.updated_at
    //             body['updated_at'] = child_updated_at
    //             orderConfirm(body, successCallback)
    //           }
    //         })
    //       } else {
    //         orderConfirm(body, successCallback)
    //       }
    //     })
    //     this.inProcess = false
    //     // Comment out Refresh OrderList After Confirm because action type is old
    //     // navigation.dispatch({ type: actions.ORDERS_FETCH, payload }) // Refresh OrderList
    //   },
    //   () => {
    //     this.inProcess = false
    //   }
    // )

    await this.endSubmitting()
  }

  _getStoreProductEditMode = async () => {
    const { editingOrder, selectedStore } = this.props
    // console.log("this.props", this.props)
    // console.log("this.state", this.state)
    // console.log("editingOrder", editingOrder.toJS())
    // console.log("selectedStore", selectedStore.toJS())
    const focusedOrder = this._getFocusedOrder(editingOrder)
    // console.log("focusedOrder: ", focusedOrder.toJS())
    const storeProducts = focusedOrder.get('store_products').toJS()
    // console.log("storeProducts", storeProducts)
    // return storeProducts;
    // const storeId = 1
    const toBeValidatedStoreProducts = []
    let tmpQty = 0
    for (const storeId in storeProducts) {
      const tmpStoreProducts = []
      if (_.isArray(storeProducts[storeId])) {
        storeProducts[storeId].map((product) => {
          tmpQty = _.isNumber(product.qty) ? product.qty : +product.qty
          if (tmpQty > 0) {
            // add the product only if qty > 0
            const newProduct = {
              pp_id: product.pp_id,
              qty: _.isNumber(product.qty) ? product.qty : +product.qty,
            }
            tmpStoreProducts.push(newProduct)
          }
        })
      }
      if (tmpStoreProducts.length > 0) {
        // add to the list only if sum qty > 0
        toBeValidatedStoreProducts.push({
          // store_id: parseInt(storeId), // MUST NOT Send store_id ด้วยเหตุผมด้านล่างนี้
          // store_id ที่ได้จาก for loop นี้ กับ pp_id ที่หน้าบ้านมีจะไม่สัมพันธ์กันถ้าเป็นสินค้าที่ดึงมา
          // เพราะ store_id ที่ได้จะเป็นของร้านขายส่ง แต่ว่า pp_id ที่อยู่ใน store_id นี้จะเป็นจากร้านตัวแทน
          products: tmpStoreProducts,
        })
      }
    }
    // console.log('toBeValidatedStoreProducts: ', toBeValidatedStoreProducts);
    return toBeValidatedStoreProducts
  }

  _getChangedPreparedProductFromStoreProducts = (oldOrder: any, newOrder: any): { products: any[]; hasProductsError?: boolean } => {
    const hasProductsError = false
    const products = []
    const oldStoreProducts = oldOrder.get('store_products') || Map({})
    const newStoreProducts = newOrder.get('store_products') || Map({})

    // log('oldOrder', oldOrder.toJS())
    // log('newOrder', newOrder.toJS())
    // ถ้าไม่มีการแก้ไขใดๆ
    // log(this, '_getChangedPreparedProductFromStoreProducts Begin loop')
    if (oldStoreProducts !== newStoreProducts) {
      const oldStoreIds = oldStoreProducts.keySeq().toArray() || []
      const newStoreIds = newStoreProducts.keySeq().toArray() || []
      // log(this, '_getChangedPreparedProductFromStoreProducts _getChangedPreparedProductFromStoreProducts oldStoreIds => ', oldStoreIds)
      // log(this, '_getChangedPreparedProductFromStoreProducts _getChangedPreparedProductFromStoreProducts newStoreIds => ', newStoreIds)

      // ใช้ newStoreIds เป็นที่ตั้ง เพราะไม่มีการ remove item มีแต่การปรับ qty === 0
      for (let i = 0; i < newStoreIds.length; i++) {
        const newStoreId = newStoreIds[i]
        const oldStoreIdIndex = oldStoreIds.findIndex((focusedStoreId) => parseInt(focusedStoreId) === parseInt(newStoreId))
        const oldStoreId = _.isNumber(oldStoreIdIndex) && oldStoreIdIndex > -1 ? oldStoreIds[oldStoreIdIndex] : null
        const oldProducts = oldStoreId && oldStoreId > 0 && oldStoreProducts.get(oldStoreId) ? oldStoreProducts.get(oldStoreId) : null
        const newProducts = newStoreProducts.get(newStoreId) // ควรจะมีแน่นอน
        // log(this, '_getChangedPreparedProductFromStoreProducts mainLoop: newStoreId => ', newStoreId)
        // log(this, '_getChangedPreparedProductFromStoreProducts mainLoop: newProducts.toJS() => ', newProducts.toJS())

        if (_.isNull(oldStoreId) || oldStoreId <= 0) {
          // ถ้าไม่มีในของเก่า แสดงว่าเป็นสินค้า add ใหม่ทั้งหมด
          // log(this, '_getChangedPreparedProductFromStoreProducts oldStoreProduct is Null in StoreId => ', newStoreId)
          for (let j = 0; j < newProducts.size; j++) {
            const newProduct = newProducts.get(j)
            const validatedProduct = this._getValidatedQtyAndPriceProductItemForEditAPI(newProduct)
            // log(this, '_getChangedPreparedProductFromStoreProducts 1 => validatedProduct', validatedProduct)
            // log(this, '_getChangedPreparedProductFromStoreProducts 1 => newStoreId', newStoreId)
            if (validatedProduct.isErrorNullQty) {
              this._handleErrHasNullProductQty(newProduct.get('name'), newStoreId, j, newProduct.get('available_qty'))
              return { hasProductsError: true, products: [] }
            }
            if (validatedProduct.isErrorNotEnoughQty) {
              this._handleErrNotEnoughQty(newProduct.get('name'), newStoreId, j, newProduct.get('available_qty'))
              return { hasProductsError: true, products: [] }
            }
            validatedProduct.action = 'ADD'
            // validatedProduct['store_id'] = newStoreId
            if (newProduct.has('useMyStoreId') && newProduct.get('useMyStoreId')) {
              validatedProduct.store_id = newProduct.get('useMyStoreId')
            } else {
              validatedProduct.store_id = newStoreId
            }
            products.push(validatedProduct)
          }
        } else {
          // ต้องเอาไปเทียบกับของเก่าก่อน ว่าอันไหน ADD ใหม่ อันไหน EDIT ถึงจะเพิ่มเข้าไป
          for (let k = 0; k < newProducts.size; k++) {
            const newProduct = newProducts.get(k)
            const newProductPPID = newProduct.get('pp_id')
            const oldProductIndex = oldProducts.findIndex((oProd) => parseInt(oProd.get('pp_id')) === parseInt(newProductPPID))
            // const oldProduct = oldProducts.get(oldProductIndex)
            // log(this, '_getChangedPreparedProductFromStoreProducts oldStoreProduct has Value newStoreId => ', newStoreId)
            // log(this, '_getChangedPreparedProductFromStoreProducts oldStoreProduct has Value newProduct => ', newProduct.toJS())
            // log(this, '_getChangedPreparedProductFromStoreProducts oldStoreProduct has Value oldStoreId => ', oldStoreId)
            // log(this, '_getChangedPreparedProductFromStoreProducts oldStoreProductct has Value oldProductIndex has Value oldProductPPID => ', JSON.stringify(oldProduct.get('pp_id')))
            // log(this, '_getChangedPreparedProductFromStoreProducts oldStoreProduct has Value newProductPPID => ', JSON.stringify(newProductPPID))
            // log(this, '_getChangedPreparedProductFromStoreProducts oldStoreProduct has Value oldProductIndex => ', JSON.stringify(oldProductIndex))

            if (oldProductIndex >= 0) {
              // ถ้ามีให้มาเทียบดูว่ามีอะไรที่ของใหม่เปลี่ยนไปจากของเก่ารึเปล่า ถ้าเปลี่ยนถึงจะ EDIT
              const oldProductQty = oldProducts.getIn([oldProductIndex, 'qty']) ? parseInt(oldProducts.getIn([oldProductIndex, 'qty'])) : 0
              const oldProductPrice = oldProducts.getIn([oldProductIndex, 'price'])
                ? parseFloat(oldProducts.getIn([oldProductIndex, 'price']))
                : 0.0
              const oldProductSellerUnitDiscount = oldProducts.getIn([oldProductIndex, 'seller_unit_discount'])
                ? parseFloat(oldProducts.getIn([oldProductIndex, 'seller_unit_discount']))
                : 0.0
              const newProductQty = newProduct.get('qty') ? parseInt(newProduct.get('qty') || 0) : 0
              const newProductPrice = newProduct.get('price') ? parseFloat(newProduct.get('price') || 0) : 0.0
              const newProductSellerUnitDiscount = newProduct.get('seller_unit_discount')
                ? parseFloat(newProduct.get('seller_unit_discount') || 0)
                : 0.0

              const isDiffQty = newProductQty !== oldProductQty
              const isDiffPrice = newProductPrice !== oldProductPrice
              const isDiffSellerUnitDiscount = oldProductSellerUnitDiscount !== newProductSellerUnitDiscount

              if (isDiffQty || isDiffPrice || isDiffSellerUnitDiscount) {
                // ถ้า qty หรือ price มีการเปลี่ยนแปลง ถึงจะ append ลงใน list
                const validatedProduct = this._getValidatedQtyAndPriceProductItemForEditAPI(newProduct)
                // log(this, '_getChangedPreparedProductFromStoreProducts 2 => validatedProduct', validatedProduct)
                // log(this, '_getChangedPreparedProductFromStoreProducts 2 => newStoreId', newStoreId)
                if (validatedProduct.isErrorNullQty) {
                  this._handleErrHasNullProductQty(newProduct.get('name'), newStoreId, k, newProduct.get('available_qty'))
                  return { hasProductsError: true, products: [] }
                }

                if (validatedProduct.isErrorNotEnoughQty) {
                  this._handleErrNotEnoughQty(newProduct.get('name'), newStoreId, k, newProduct.get('available_qty'))
                  return { hasProductsError: true, products: [] }
                }

                if (isDiffSellerUnitDiscount) {
                  // @ts-ignore
                  validatedProduct.seller_unit_discount = newProductSellerUnitDiscount
                }

                if (!isDiffPrice) {
                  delete validatedProduct.price
                }

                if (!isDiffQty) {
                  delete validatedProduct.qty
                }

                products.push(validatedProduct)

                // validatedProduct['action'] = 'EDIT' // ไม่จำเป็นต้องส่งไป เพราะ default เป็น EDIT
                // validatedProduct['store_id'] = newStoreId // ไม่จำเป็นต้องส่งไป ถ้าเป็นเคส EDIT
              } else {
                log(this, '_getChangedPreparedProductFromStoreProducts 2 => is no diff product newProductPPID', newProductPPID)
              }
            } else {
              // ถ้าไม่เจอ index สินค้าเก่า แสดงว่าเป็นสินค้าที่เพิ่มเข้ามาใหม่ใน store นี้
              const validatedProduct = this._getValidatedQtyAndPriceProductItemForEditAPI(newProduct)
              // log(this, '_getChangedPreparedProductFromStoreProducts 3 => validatedProduct', validatedProduct)
              // log(this, '_getChangedPreparedProductFromStoreProducts 3 => newStoreId', newStoreId)
              if (validatedProduct.isErrorNullQty) {
                this._handleErrHasNullProductQty(newProduct.get('name'), newStoreId, k, newProduct.get('available_qty'))
                return { hasProductsError: true, products: [] }
              }
              if (validatedProduct.isErrorNotEnoughQty) {
                this._handleErrNotEnoughQty(newProduct.get('name'), newStoreId, k, newProduct.get('available_qty'))
                return { hasProductsError: true, products: [] }
              }
              validatedProduct.action = 'ADD'
              // log('store_id 2')
              // log(validatedProduct)
              // TODO from pKeng: Trying to fix problem that reseller cannot confirm editing order if adding at least a new product pulled from a seller. The root cause is seller's storeId is used instead of my (reseller's) storeId
              // TODO pO Please verfiy this fix if it's correct. Also verify if line 1974 also need the same change ==> validatedProduct['store_id'] = newStoreId
              if (newProduct.has('useMyStoreId') && newProduct.get('useMyStoreId')) {
                validatedProduct.store_id = newProduct.get('useMyStoreId')
              } else {
                validatedProduct.store_id = newStoreId
              }
              products.push(validatedProduct)
            }
          }
          // End check editing products
        }
        // End check adding products
      }
      // End store loop
    }
    return { hasProductsError, products }
  }

  _getValidatedQtyAndPriceProductItemForEditAPI = (
    product: Map<string, any> | any
  ): {
    pp_id?: number
    qty?: number
    price?: number
    isErrorNullQty?: boolean
    isErrorNotEnoughQty?: boolean
    action?: string
    store_id?: number
  } => {
    // let targetProduct: {
    //   pp_id: number
    //   pg_id?: number
    //   qty: string | number
    //   available_qty?: string | number
    //   price?: string | number
    //   price_rate_type?: number
    //   price_rate_value?: string | number
    //   [key: string]: any,
    // } | null = null
    // FIXME: เพิ่ม product interface
    let targetProduct: any
    if (Map.isMap(product)) {
      targetProduct = product.toJS()
    } else {
      targetProduct = product
    }

    const { pp_id, pg_id, qty, available_qty, price, price_rate_type, price_rate_value } = targetProduct
    // log(this, '_getValidatedQtyAndPriceProductItemForEditAPI targetProduct => ', targetProduct)
    if (_.isNil(qty) || (_.isString(qty) && qty.length === 0)) {
      return { isErrorNullQty: true }
    }

    if (!_.isNull(available_qty) && !_.isUndefined(available_qty) && parseInt(qty) > parseInt(available_qty)) {
      return { isErrorNotEnoughQty: true }
    }

    // FIXME: ใช้งาน product interface
    const validProduct: any = { pp_id: parseInt(pp_id), qty: parseInt(qty) }
    if (pg_id) {
      validProduct.pg_id = parseInt(pg_id)
    }
    if (price_rate_type && parseFloat(price) !== parseFloat(price_rate_value)) {
      validProduct.price = parseFloat(price)
    }
    return validProduct

    // // FIXME: THIS IS MOCK FOR CREATE Order  (demo prices)
    // return price_rate_type && parseFloat(price) !== parseFloat(price_rate_value)
    //   ? {pp_id: parseInt(pp_id), qty: parseInt(qty), price: parseFloat(price)}
    //   : {pp_id: parseInt(pp_id), qty: parseInt(qty), price: parseFloat(price)}
    // // return price_rate_type && parseFloat(price) !== parseFloat(price_rate_value)
    // //   ? { pp_id: parseInt(pp_id), qty: parseInt(qty), price: parseFloat(price) }
    // //   : { pp_id: parseInt(pp_id), qty: parseInt(qty) }
  }

  _replaceZipcode = (splitedAddresses, lineNumber, cutAline, isStart, isEnd, betweenLineIndex) => {
    const splitedAddressesLength = splitedAddresses.length
    let newAddress = ''
    for (let i = 0; i < splitedAddressesLength; i++) {
      if (lineNumber === i) {
        if (isStart) {
          splitedAddresses[i] = splitedAddresses[i].substring(5, splitedAddresses[i].length)
          newAddress = `${newAddress + splitedAddresses[i]}\n`
        } else if (isEnd) {
          splitedAddresses[i] = splitedAddresses[i].substring(0, splitedAddresses[i].length - 5)
          newAddress = `${newAddress + splitedAddresses[i]}\n`
        } else if (cutAline) {
        } else {
          const lineStart = splitedAddresses[i].substring(0, betweenLineIndex)
          const lineEnd = splitedAddresses[i].substring(betweenLineIndex + 5, splitedAddresses[i].length)
          splitedAddresses[i] = `${lineStart} ${lineEnd}`
          newAddress = `${newAddress + splitedAddresses[i]}\n`
        }
      } else if (!_.isEmpty(splitedAddresses[i])) {
        newAddress = `${newAddress + splitedAddresses[i]}\n`
      }
    }
    return newAddress
  }

  _parseFirstLineNameAddressToQuickAddress = (text: string): IQuickAddress => util.convertInlineAddressToQuickAddress(text)

  //   _parseFirstLineNameAddressToQuickAddress = (text: string): IQuickAddress => {
  //     const quickAddress: IQuickAddress | any = {
  //       id: null,
  //       address1: null,
  //       address2: null,
  //       sub_district: null,
  //       district: null,
  //       province: null,
  //       postal_code: null,
  //       telephone: null,
  //     }

  //     if (!text || text.trim().length === 0) {
  //       return quickAddress
  //     }
  //     let txtAddress = _.clone(text)
  //     // let quickAddress:QuickAddress = <QuickAddress>{}

  //     quickAddress.address3 = ''

  //     const regZipcode = /[1-9][0-9]{3}0/g
  //     let splitedAddresses = txtAddress.split('\n')

  //     if (!splitedAddresses || splitedAddresses.length === 0) {
  //       return quickAddress
  //     }
  //     let zip_code = ''
  //     let regInline
  //     let regX
  //     let breakZipcode = false
  //     let cutAline = false
  //     let isStart = false
  //     let isEnd = false
  //     let betweenLineIndex = 0
  //     for (let i = 0; i < splitedAddresses.length; i++) {
  //       // บรรทัดที่ i
  //       // let aLine = splitedAddresses[i].trim()
  //       const aLine = splitedAddresses[i]
  //       regInline = aLine.match(regZipcode)
  //       // log(regInline)
  //       if (!_.isNull(regInline)) {
  //         // ถ้าบรรทัดที่ i ไม่มีคำที่ต้องการ ก็ไม่ต้องทำอะไรต่อ
  //         for (let ii = 0; ii < regInline.length; ii++) {
  //           // คำที่เจอในบรรทัดที่ i ลำดับที่ ii
  //           if (aLine.length === 5) {
  //             // คำที่เจอตรงตามเงื่อนไขพอดี
  //             cutAline = true
  //             quickAddress.postal_code = aLine
  //             txtAddress = this._replaceZipcode(splitedAddresses, i, cutAline, isStart, isEnd, betweenLineIndex)
  //             // txtAddress = txtAddress.replace(quickAddress.postal_code, '')
  //             breakZipcode = true
  //             break
  //           } else {
  //             let rawDataZipCode
  //             const start = aLine.indexOf(regInline[ii])
  //             let end = false
  //             const valLineOfEnd = aLine.length - 5
  //             if (valLineOfEnd === start) {
  //               end = true
  //             }
  //             if (start === 0) {
  //               // คำที่เจอ เจอเป็นคำแรกของบรรทัดที่ i
  //               isStart = true
  //               regX = /[1-9][0-9]{3}0[^\d]/g
  //               rawDataZipCode = aLine.match(regX)
  //               if (_.isArray(rawDataZipCode) && rawDataZipCode.length > 0) {
  //                 // ถ้าข้างหลังคำที่เจอไม่ใช่ตัวเลข ให้ดำเนินการต่อไป
  //                 zip_code = rawDataZipCode[0].substring(0, 5)
  //                 quickAddress.postal_code = zip_code
  //                 txtAddress = this._replaceZipcode(splitedAddresses, i, cutAline, isStart, isEnd, betweenLineIndex)
  //                 // txtAddress = txtAddress.replace(zip_code, '')
  //                 breakZipcode = true
  //                 break
  //               }
  //             } else if (end) {
  //               // ถ้าคำที่เจอ เจอเป็นคำสุดท้ายของบรรทัดที่ i
  //               isEnd = true
  //               regX = /[^\d][1-9][0-9]{3}0/g
  //               rawDataZipCode = aLine.match(regX)
  //               if (_.isArray(rawDataZipCode) && rawDataZipCode.length > 0) {
  //                 zip_code = rawDataZipCode[0].substring(1, 6)
  //                 quickAddress.postal_code = zip_code
  //                 txtAddress = this._replaceZipcode(splitedAddresses, i, cutAline, isStart, isEnd, betweenLineIndex)
  //                 // txtAddress = txtAddress.replace(zip_code, '')
  //                 breakZipcode = true
  //                 break
  //               }
  //             } else {
  //               regX = /[^\d][1-9][0-9]{3}0[^\d]/g
  //               rawDataZipCode = aLine.match(regX)
  //               if (!_.isNil(rawDataZipCode)) {
  //                 const matchResult = regX.exec(aLine)
  //                 betweenLineIndex = matchResult.index + 1
  //                 if (_.isArray(rawDataZipCode) && rawDataZipCode.length > 0) {
  //                   zip_code = rawDataZipCode[0].substring(1, 6)
  //                   quickAddress.postal_code = zip_code
  //                   txtAddress = this._replaceZipcode(splitedAddresses, i, cutAline, isStart, isEnd, betweenLineIndex)
  //                   // txtAddress = txtAddress.replace(zip_code, '')
  //                   breakZipcode = true
  //                   break
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //       if (breakZipcode) {
  //         break
  //       }
  //     }
  //     // let postal_code = txtAddress.match(regZipcode) || []
  //     // // log('BOV4 ' + postal_code)
  //     // if (postal_code.length > 0) {
  //     //   let postalCode = postal_code[0].trim()
  //     //   if (postalCode.length === 6) {
  //     //     postalCode = postalCode.substring(1, 6)
  //     //   }
  //     //   quickAddress['postal_code'] = postalCode
  //     //   // quickAddress['postal_code'] = postal_code[0]
  //     //   txtAddress = txtAddress.replace(quickAddress.postal_code, '')
  //     // }

  //     // Must split each line before doing matching as the regPhones won't work properly if there is new line char (\n)
  //     // regPhones แบบเก่า /\(?\+?[0-9]*\)?(\-{0,2}\s{0,1}[0-9]){7,}\)?/g,
  //     const regPhones = [
  //       /\+?[0-9]{3}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4,7}/g,
  //       // The above regex is tested against the following samples:
  //       // 096 978 4644
  //       // 096-978 4644
  //       // 096 9784644
  //       // (0851247452)
  //       // (085 124-7452)
  //       // 66848744155
  //       // +66848744155

  //       /\(?\+?[0-9]{0,2}\)?[0-9_]{0,3}\s{0,1}?\(?\+?[0-9_]*\)?(\-{0,2}[0-9_]){7,}\)?/g, // (+66) 837031118, +662 837031118, (0851247452), (085-124-7452), 085-124-7452, (085)1234567, 085_8974568
  //       // /\(?\+?[0-9]{0,2}\)?[0-9]{0,3}\s{0,1}?\(?\+?[0-9]*\)?(\-{0,2}[0-9]){7,}\)?/g, // (+66) 837031118, +662 837031118, (0851247452), (085-124-7452), 085-124-7452, (085)1234567
  //       // /\(?\+?[0-9]*\)?(\-{0,2}[\s\S]{0,2}[0-9](?!.*\n)){7,}\)?/g,
  //       // อัจฉริยา เจริญศักดิ์ 66848744155 176/79 หมู่บ้านรัตนาวลัยหลักสี่ ประชาอุทิศ17 ถนน ประชาอุทิศ เขตดอนเมือง จังหวัดกรุงเทพมหานคร 10210
  //     ]
  //     // สำหรับเช็คเลขเรียง 10 ตัว
  //     // 0[0-9]{0,2}[0-9]{7}
  //     splitedAddresses = txtAddress.split('\n')
  //     let telephone
  //     let isTelFound = false
  //     for (let idx = 0; idx < regPhones.length; idx++) {
  //       // loop for each pattern
  //       if (isTelFound) {
  //         break
  //       }
  //       // log('BOV2')
  //       for (let i = 0; i < splitedAddresses.length; i++) {
  //         let aLine = splitedAddresses[i].trim()
  //         aLine = util.replaceOutUnknowString(aLine)

  //         telephone = aLine.match(regPhones[idx])
  //         // log('BOV3 ' + aLine)
  //         if (telephone && telephone.length > 0) {
  //           // log('telephone Matched found!!')
  //           // log(telephone)
  //           quickAddress.telephone = telephone[0].trim()
  //           txtAddress = txtAddress.replace(quickAddress.telephone, '')
  //           txtAddress = txtAddress.replace('โทร', '')
  //           txtAddress = txtAddress.replace('โทร.', '')
  //           txtAddress = txtAddress.replace('โทร:', '')
  //           txtAddress = txtAddress.replace('โทร ', '')
  //           txtAddress = txtAddress.replace('tel.', '')
  //           txtAddress = txtAddress.replace('tel: ', '')
  //           // log('txtAddress1: ' + txtAddress)
  //           txtAddress = txtAddress.replace('tel ', '')
  //           // log('txtAddress2: ' + txtAddress)

  //           isTelFound = true
  //           break
  //         }
  //       }
  //     }

  //     // ensure that it matches 5 to 6 char that the last 5 are digit and it's not part of long number like phone#
  //     // const regZipcode = /[0-9][0-9-]{4,}/g // this one will catch the wrong one i.e. 12/209-210 ซ.13

  //     // if (isTelFound) {
  //     // reset the array as txtAddress has been changed
  //     splitedAddresses = util.replaceOutUnknowString(txtAddress.trim()).split('\n')

  //     // Handle the case where the pasted text is just one line by spliting into two items i.e.
  //     // ร.ต.อ.หญิงจิราภรณ์ บัวทอง 270 หมู่2 บ้านโพธิ์ไทร ต.โพธิ์ไทร อ.พิบูลมังสาหาร จ.อุบลราชธานี 34190
  //     // or
  //     // ภาวิณี ศรีวิเศษ รพ.สต.โกรกกุลา ต.ตาเนิน อ.เนินสง่า จ.ชัยภูมิ 36130 โทร0922791582
  //     // In short, converting ["ภาวิณี ศรีวิเศษ รพ.สต.โกรกกุลา ต.ตาเนิน อ.เนินสง่า จ.ชัยภูมิ 36130 โทร0922791582"]
  //     // to ["ภาวิณี ศรีวิเศษ", "รพ.สต.โกรกกุลา ต.ตาเนิน อ.เนินสง่า จ.ชัยภูมิ 36130 โทร0922791582"]
  //     // console.log('splitedAddresses.len check', splitedAddresses)
  //     if (splitedAddresses.length === 1) {
  //       // one liner
  //       // Split the string into an array based on spaces
  //       const splitArray = splitedAddresses[0].split(' ')

  //       // Combine the first two elements and the rest
  //       splitedAddresses = [splitArray.slice(0, 2).join(' '), splitArray.slice(2).join(' ')]
  //       // console.log('splitedAddresses', splitedAddresses)
  //     }
  //     // สับ empty string ทิ้งไป
  //     // แก้ไขปัญหา https://effily.atlassian.net/browse/XSELLY-725 (case 1, 2, 3)
  //     for (let i = 0; i < splitedAddresses.length; i++) {
  //       splitedAddresses[i] = splitedAddresses[i].trim()
  //       if (splitedAddresses[i] === '') {
  //         splitedAddresses.splice(i, 1)
  //       }
  //     }
  //     // }

  //     // Handle / and - in the address
  //     //   ปารนีย์ อนันต์เศรษฐศิริ
  //     //   12/209-210 ซ.13 ถ.ลพบุรีราเมศวร์ ต.คลองแห อ.หาดใหญ่ จ.สงขลา 90110
  //     //   โทร
  //     //   0831309933

  //     // log('BOV5 ' + splitedAddresses)
  //     // console.log('splitedAddresses', splitedAddresses)
  //     if (splitedAddresses) {
  //       if (splitedAddresses.length === 1 && splitedAddresses[0].trim().length > 0) {
  //         // single line (name + address are on the same line)
  //         // Get the name assuming that it comes before any digit
  //         const regADigit = /\d/g
  //         const theLine = splitedAddresses[0].trim()
  //         const singleLineSplitedAddr = theLine.match(regADigit)
  //         // console.log('singleLineSplitedAddr ', singleLineSplitedAddr)
  //         if (singleLineSplitedAddr && singleLineSplitedAddr.length > 0) {
  //           // log('singleLineSplitedAddr')
  //           // log(singleLineSplitedAddr)
  //           const firstDigit = singleLineSplitedAddr[0]
  //           const firstDigitPos = theLine.indexOf(firstDigit)
  //           quickAddress.name = theLine.substring(0, firstDigitPos).trim()
  //           quickAddress.address3 = theLine.substring(firstDigitPos, theLine.length).trim()
  //         }
  //       } else if (splitedAddresses.length > 1) {
  //         const regADigit = /\d/g
  //         const theLine = splitedAddresses[0].trim()
  //         const singleLineSplitedAddr = theLine.match(regADigit)
  //         // console.log('singleLineSplitedAddr ', singleLineSplitedAddr)

  //         // console.log('firstDigitPos', firstDigitPos)

  //         /*
  //         Case ทดสอบ ที่ 1) มี เลขอยู่ข้างหน้า ลูกค้าใช้ในการรันเลขส่วนตัว
  //         6.COD200฿แม่นม4ห่อ ศศิวิมล บุญชัย
  //         PRESENT CONDOMINIUM EKACHAI 32  ที่55/257 ชั้น3 ตึกB ถ.เอกชัย แขวงบางขุนเทียน เขตจอมทอง กรุงเทพฯ    (k.บลู
  //         10150
  //         โทร. 0842750758)

  //         Case ทดสอบ ที่ 2) แบบ happy path
  //         กนกวรรณ เศรษฐพงศ์วนิช
  //         1345 ถนนราชดำเนิน เขตดอนเมือง
  //         กทม 10180
  //         0971627389
  //         */
  //         if (!_.isNil(singleLineSplitedAddr)) {
  //           // const firstDigit = singleLineSplitedAddr[0]
  //           // const firstDigitPos = theLine.indexOf(firstDigit)
  //           // console.log('the line', theLine)

  //           // Set the whole first line as name
  //           quickAddress.name = theLine

  //           // quickAddress.name = theLine.substring(0, firstDigitPos).trim()
  //           // quickAddress.address3 = theLine.substring(firstDigitPos, theLine.length).trim() + ' '
  //         } else {
  //           quickAddress.name = splitedAddresses[0].trim()
  //         }
  //         // multiline
  //         // Starting from 2nd line compute the address
  //         for (let j = 1; j < splitedAddresses.length; j++) {
  //           const tmp = splitedAddresses[j].trim()
  //           if (tmp && tmp.length > 0) {
  //             if (j > 1) {
  //               quickAddress.address3 += '\n'
  //             }
  //             quickAddress.address3 += tmp
  //           }
  //         }
  //       }
  //     }

  //     // if (splitedAddresses && splitedAddresses.length > 1) {
  //     //   quickAddress['name'] = splitedAddresses[0].trim()
  //     //   for (var j = 1; j < splitedAddresses.length; j++) {
  //     //     let tmp = splitedAddresses[j].trim()
  //     //     if (j > 1) {
  //     //       quickAddress['address3'] += '\n'
  //     //     }
  //     //     if (tmp && tmp.length > 0) {
  //     //       quickAddress['address3'] += tmp
  //     //     }
  //     //   }
  //     // }

  //     /**
  //      * regex for legal entity id:
  //      * ^\d{1}-\d{4}-\d{5}-\d{1,2}-\d{1,3}$
  //      * ^\d{1,}-\d{5}-\d{3}-\d{3}-\d{2}$
  //      * ^\d{1,}-\d{5}-\d{9,}$
  //      * ^\d{1}-\d{5}-\d{6}-\d{3}$
  //      * ^\d{13}$
  // คุณประเสริฐ ผลิตภัณฑ์การชั่ง
  // 456 ถนนสาทรใต้ แขวงทุ่งมหาเมฆ
  // เขตสาทร กรุงเทพ 10120 (089-1234756)
  // 0-1212-15435-12-3
  //     */
  //     // console.log('quickAddress before legal check', quickAddress)

  //     const regLegalEntityIdArray = [
  //       // 0-1212-15435-12-3
  //       /\d{1}-\d{4}-\d{5}-\d{1,2}-\d{1,3}/g,
  //       // 0-12316-513-513-55
  //       /\d{1,}-\d{5}-\d{3}-\d{3}-\d{2}/g,
  //       // 0-12316-513513515
  //       /\d{1,}-\d{5}-\d{9,}/g,
  //       // 0-12316-513513-515
  //       /\d{1}-\d{5}-\d{6}-\d{3}/g,
  //       // 0121215435123
  //       /\d{13}/g,

  //       // sample:
  //       // อัจฉริยา เจริญศักดิ์ 66848744155 176/79 หมู่บ้านรัตนาวลัยหลักสี่ ประชาอุทิศ17 ถนน ประชาอุทิศ เขตดอนเมือง จังหวัดกรุงเทพมหานคร 10210 0-1212-15435-12-3
  //     ]

  //     const regLegalEntityIdTenDigitArray = [
  //       // 3-0332-9304-3
  //       /\d{1}-\d{4}-\d{4}-\d{1}/g,
  //       // 3033293043
  //       /\d{10}/g,
  //     ]

  //     const addressToCheckText = quickAddress.address3
  //     let legelEntityId: string = null
  //     let legelEntityIdFound = false
  //     let legalEntityIdMatchTenDigit = false

  //     for (let i = 0; i < regLegalEntityIdArray.length; i++) {
  //       const regUse = regLegalEntityIdArray[i]
  //       const reg = new RegExp(regUse)
  //       if (reg.test(addressToCheckText)) {
  //         legelEntityIdFound = true
  //         const checkedArray = addressToCheckText.match(regUse)
  //         // console.log('regLegalEntityId regUse', regUse)
  //         // console.log('regLegalEntityId checkedArray', checkedArray)
  //         if (checkedArray && checkedArray.length > 0) {
  //           // eslint-disable-next-line prefer-destructuring
  //           legelEntityId = checkedArray[0]
  //           break
  //         }
  //       }
  //     }

  //     if (!legelEntityIdFound) {
  //       for (let i = 0; i < regLegalEntityIdTenDigitArray.length; i++) {
  //         const regUse = regLegalEntityIdTenDigitArray[i]
  //         const reg = new RegExp(regUse)
  //         if (reg.test(addressToCheckText)) {
  //           legelEntityIdFound = true
  //           const checkedArray = addressToCheckText.match(regUse)
  //           if (checkedArray && checkedArray.length > 0) {
  //             // eslint-disable-next-line prefer-destructuring
  //             legelEntityId = checkedArray[0]
  //             legalEntityIdMatchTenDigit = true
  //             break
  //           }
  //         }
  //       }
  //     }

  //     if (legelEntityIdFound && legelEntityId) {
  //       quickAddress.legal_entity_id = legelEntityId.replace(/-/g, '')
  //       const { name: nm, address3: a3 } = quickAddress
  //       if (
  //         legalEntityIdMatchTenDigit ||
  //         nm.includes('บริษัท') ||
  //         nm.includes('บจก') ||
  //         nm.includes('ห้างหุ้น') ||
  //         nm.includes('จำกัด') ||
  //         nm.includes('มหาชน') ||
  //         a3.includes('บริษัท') ||
  //         a3.includes('บจก') ||
  //         a3.includes('ห้างหุ้น') ||
  //         a3.includes('จำกัด') ||
  //         a3.includes('มหาชน')
  //       ) {
  //         quickAddress.legal_entity_type = 2
  //       } else {
  //         quickAddress.legal_entity_type = 1
  //       }
  //       quickAddress.address3 = quickAddress.address3.replace(`${CONS.ADDRESS_LEGAL_ENTITY_LABELS[1]}:`, '')
  //       quickAddress.address3 = quickAddress.address3.replace(`${CONS.ADDRESS_LEGAL_ENTITY_LABELS[2]}:`, '')
  //       quickAddress.address3 = quickAddress.address3.replace(`เลขบัตรประชาชน:`, '')
  //       quickAddress.address3 = quickAddress.address3.replace(`บัตรประชาชน:`, '')
  //       quickAddress.address3 = quickAddress.address3.replace(`เลขผู้เสียภาษี:`, '')
  //       quickAddress.address3 = quickAddress.address3.replace(`เลขบัตร:`, '')
  //       quickAddress.address3 = quickAddress.address3.replace(CONS.ADDRESS_LEGAL_ENTITY_LABELS[1], '')
  //       quickAddress.address3 = quickAddress.address3.replace(CONS.ADDRESS_LEGAL_ENTITY_LABELS[2], '')
  //       quickAddress.address3 = quickAddress.address3.replace('เลขบัตรประชาชน', '')
  //       quickAddress.address3 = quickAddress.address3.replace('บัตรประชาชน', '')
  //       quickAddress.address3 = quickAddress.address3.replace('เลขผู้เสียภาษี', '')
  //       quickAddress.address3 = quickAddress.address3.replace('เลขบัตร', '')
  //       quickAddress.address3 = quickAddress.address3.replace(/\n/g, '')
  //       quickAddress.address3 = util.replaceOutUnknowString(quickAddress.address3.replace(legelEntityId, '').trim())
  //     }
  //     // console.log('quickAddress after legal check', quickAddress)

  //     // console.log('quickAddress before email check', quickAddress)
  //     const regEmail = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g
  //     let email: string = null
  //     let emailFound = false

  //     if (regEmail.test(addressToCheckText)) {
  //       emailFound = true
  //       const checkedArray = addressToCheckText.match(regEmail)
  //       // console.log('email regEmail', regEmail)
  //       // console.log('email checkedArray', checkedArray)
  //       if (checkedArray && checkedArray.length > 0) {
  //         // eslint-disable-next-line prefer-destructuring
  //         email = checkedArray[0]
  //       }
  //     }

  //     if (emailFound && email) {
  //       quickAddress.email = email
  //       quickAddress.address3 = quickAddress.address3.replace('อีเมลล์:', '')
  //       quickAddress.address3 = quickAddress.address3.replace('อีเมล์:', '')
  //       quickAddress.address3 = quickAddress.address3.replace('อีเมล:', '')
  //       quickAddress.address3 = quickAddress.address3.replace('email:', '')
  //       quickAddress.address3 = quickAddress.address3.replace('Email:', '')
  //       quickAddress.address3 = quickAddress.address3.replace('EMail:', '')
  //       quickAddress.address3 = quickAddress.address3.replace('E-Mail:', '')
  //       quickAddress.address3 = quickAddress.address3.replace('E-mail:', '')
  //       quickAddress.address3 = quickAddress.address3.replace('e-mail:', '')
  //       quickAddress.address3 = quickAddress.address3.replace('อีเมลล์', '')
  //       quickAddress.address3 = quickAddress.address3.replace('อีเมล์', '')
  //       quickAddress.address3 = quickAddress.address3.replace('อีเมล', '')
  //       quickAddress.address3 = quickAddress.address3.replace('email', '')
  //       quickAddress.address3 = quickAddress.address3.replace('Email', '')
  //       quickAddress.address3 = quickAddress.address3.replace('EMail', '')
  //       quickAddress.address3 = quickAddress.address3.replace('E-Mail', '')
  //       quickAddress.address3 = quickAddress.address3.replace('E-mail', '')
  //       quickAddress.address3 = quickAddress.address3.replace('e-mail', '')
  //       quickAddress.address3 = quickAddress.address3.replace(/\n/g, '')
  //       quickAddress.address3 = util.replaceOutUnknowString(quickAddress.address3.replace(email, '').trim())
  //     }
  //     // console.log('quickAddress after email check', quickAddress)

  //     // log('quickAddress')
  //     // log(quickAddress)
  //     return quickAddress
  //   }

  _isOrderCancelled = (order) => isImmutable(order) && order.get('state') >= 180

  _handleChangeShippingType = (order, store_id, newShippingTypeId, newShippingTypeLabel, newShippingTypeIndex) => {
    // log(newShippingTypeId)
    // if (newShippingTypeIndex < 6) {
    // const orderType = order.has('type') && order.get('type') === 1
    if (this._isLockedOrder(order)) {
      p.op.showConfirmationOkOnly('ออเดอร์ถูกล็อก', 'กรุณาปลดล็อกออเดอร์นี้และทำรายการใหม่อีกครั้ง')
      return
    }
    if (_.includes(CONS.TEL_NUM_REQUIRED_FOR_SHIPPING_TYPE_IDS, newShippingTypeId)) {
      const focusedOrder = this._getFocusedOrder()
      if (!focusedOrder) {
        p.op.alert('เกิดข้อผิดพลาด', 'กรุณาออกจากหน้าแล้วเข้าใหม่อีกครั้ง')
        return
      }
      const focusedOrderJS = focusedOrder.toJS()
      if (focusedOrderJS.sender && _.isEmpty(focusedOrderJS.sender.telephone)) {
        p.op.alert('คำเตือน', 'กรุณาระบุหมายเลขโทรศัพท์ผู้ส่ง')
        return
      }
      // if (focusedOrder.toJS().receiver !== null) {
      //   if (focusedOrder.toJS().receiver.telephone === null || focusedOrder.toJS().receiver.telephone === '') {
      if (focusedOrderJS.receiver && _.isEmpty(focusedOrderJS.receiver.telephone)) {
        // if (focusedOrder.toJS().receiver.telephone === null || focusedOrder.toJS().receiver.telephone === '') {
        p.op.alert('คำเตือน', 'กรุณาระบุหมายเลขโทรศัพท์ผู้รับ')
        return
        // }
      }
    }
    // console.log(`order.get('type') => `, order.get('type'))
    if (newShippingTypeId !== order.get('shipping_type_id')) {
      // alert('Not same ' + selectedShippingTypeId + ' : ' + order.get('shipping_type_id'))
      p.op.showConfirmation(
        'ยืนยันการแก้ไขรูปแบบจัดส่ง',
        `โปรดยืนยันการแก้ไขรูปแบบจัดส่งเป็น\n"${newShippingTypeLabel}"\n\nหากยืนยัน ระบบจะทำการแก้ไขรูปแบบจัดส่งและคำนวณค่าจัดส่งใหม่`,
        async () => {
          const payload: any = {
            store_id,
            order_id: order.get('id'),
            shipping_type_id: newShippingTypeId,
          }

          const isCheckShippingTypeNormalToShippingTypeCOD =
            _.includes(CONS.COD_SHIPPING_TYPE_IDS, newShippingTypeId) &&
            !_.includes(CONS.COD_SHIPPING_TYPE_IDS, order.get('shipping_type_id'))
          const newShippingLable = util.getShippingTypeNameById(newShippingTypeId)
          const oldShippingLable = util.getShippingTypeNameById(order.get('shipping_type_id'))
          // const isCheckShippingTypeNormalToShippingTypeCOD = false
          if (isCheckShippingTypeNormalToShippingTypeCOD) {
            // console.log('newShippingTypeId => ', newShippingTypeId)
            const isUserConfirmedCODShippingType = await new Promise<boolean>((passConfirm) => {
              const comfirmationHasShipBeforePay =
                `คุณกำลังเปลี่ยนรูปแบบจัดส่งเป็น ${newShippingLable}` +
                '\n\n(มีผลเฉพาะกับออเดอร์นี้เท่านั้น กรุณาปรับที่ออเดอร์ซื้อที่เกี่ยวข้องเอง ถ้ามี)'
              const comfirmationDontHavaShipBeforePay =
                `คุณกำลังเปลี่ยนรูปแบบจัดส่งเป็น ${newShippingLable}` +
                '\n\nคุณต้องการระบุยอด COD และเปิดสวิตช์ “จัดส่งก่อนชำระ” เลยหรือไม่' +
                '\n\n(มีผลเฉพาะกับออเดอร์นี้เท่านั้น กรุณาปรับที่ออเดอร์ซื้อที่เกี่ยวข้องเอง ถ้ามี)'
              const bodyComfirmation = order.get('ship_before_pay') ? comfirmationHasShipBeforePay : comfirmationDontHavaShipBeforePay
              p.op.showConfirmationThreeButtons(
                'ระบุ ยอดเก็บเงินปลายทาง และ จัดส่งก่อนชำระ?',
                bodyComfirmation,
                'ไม่ต้องการ',
                () => {
                  passConfirm(false)
                },
                'ต้องการ (แนะนำ)',
                () => {
                  passConfirm(true)
                },
                'ยกเลิก',
                () => {
                  passConfirm(null)
                }
              )
            })
            if (_.isNil(isUserConfirmedCODShippingType)) {
              return
            }
            if (isUserConfirmedCODShippingType) {
              util.setStatePromise(this, {
                newShippingTypeForChangeShippingTypeToCOD: newShippingTypeId,
                isOpenCODAmountOverlay: true,
              })
              return
            }
            const doubleConfirmed = await new Promise<boolean>((passConfirm) => {
              p.op.showConfirmation(
                'ยืนยันการไม่ระบุ ยอดเก็บเงินปลายทาง และ จัดส่งก่อนชำระ',
                'คุณสามารถแก้ไขเองได้ในภายหลัง หากต้องการ',
                () => passConfirm(true),
                () => passConfirm(false),
                'ยืนยัน',
                'ยกเลิก'
              )
            })
            if (!doubleConfirmed) {
              return
            }
          }
          // return
          const isCheckShippingTypeCODToShippingTypeNormal =
            !_.includes(CONS.COD_SHIPPING_TYPE_IDS, newShippingTypeId) &&
            _.includes(CONS.COD_SHIPPING_TYPE_IDS, order.get('shipping_type_id'))
          const ship_before_pay = order.get('ship_before_pay')
          if (isCheckShippingTypeCODToShippingTypeNormal && ship_before_pay) {
            const confirmed = await new Promise<boolean>((passConfirm) => {
              p.op.showConfirmationThreeButtons(
                'ปิดการใช้งาน "จัดส่งก่อนชำระ"?',
                `คุณกำลังเปลี่ยนรูปแบบจัดส่งจากแบบเก็บเงินปลายทาง COD \n"${oldShippingLable}" เป็น แบบไม่เก็บเงินปลายทาง \n"${newShippingLable}""` +
                  '\n\nดังนั้น XSelly ขอแนะนำให้ปิดการใช้งาน "จัดส่งก่อนชำระ" ด้วยเพื่อรอให้มีการบันทึกรับชำระก่อนจัดส่ง' +
                  '\n\nคุณต้องการดำเนินการตามคำแนะนำ ใช่หรือไม่',
                'ไม่',
                () => passConfirm(false),
                'ใช่ (แนะนำ)',
                () => passConfirm(true),
                'ยกเลิก',
                () => passConfirm(null)
              )
            })
            if (_.isNull(confirmed)) {
              return
            }
            if (confirmed) {
              this.onChangeShippingCODToNormalType(newShippingTypeId)
              return
            }
          }
          if (order.has('x')) {
            payload.successCallback = (order: any) => this._successCallbackFromChangeShipping(order)
          }
          const subOrders = order.get('suborders')
          // log('Confirmed change shippingType')
          if (order.get('type') === 3) {
            // Keng: At the moment, decided that changing on type 3 won't propagate to parent
            this.props.onShippingTypeChange(payload)

            // ask user if the change shall propagate to the parent order
            // p.op.showConfirmationThreeButtons('แก้ไขออเดอร์ขายที่เกี่ยวข้อง',
            //   `คุณต้องการแก้ไขรูปแบบจัดส่งของ\nออเดอร์ขาย(#${order.get('parent_id')})และออเดอร์ซื้อที่เกี่ยวข้องทั้งหมด  ให้เป็น "${newShippingTypeLabel}" ด้วยหรือไม่`,
            //   'แก้ไขแค่ออเดอร์ซื้อนี้เท่านั้น',
            //   ()=>{ this.props.onShippingTypeChange(payload) },
            //   'แก้ไขออเดอร์ขายและซื้อที่เกี่ยวข้องทั้งหมด',
            //   () => {
            //     // log('แก้ไขออเดอร์ซื้อทั้งหมดด้วย')
            //     payload['update_sales_order'] = true
            //     this.props.onShippingTypeChange(payload)
            //   }
            // )
          } else if (subOrders && subOrders.size > 0) {
            let subOrderIdLabels = '('
            subOrders.forEach((subOrder, idx) => {
              subOrderIdLabels += `#${subOrder.get('id')}`
              if (idx === subOrders.size - 1) {
                subOrderIdLabels += ')'
              } else {
                subOrderIdLabels += ', '
              }
            })
            await delay(200)
            // ask user if the change shall propagate to sub orders
            p.op.showConfirmationThreeButtons(
              'แก้ไขออเดอร์ซื้อที่เกี่ยวข้อง',
              `คุณต้องการแก้ไขรูปแบบจัดส่งของ\n${subOrders.size} ออเดอร์ซื้อที่เกี่ยวข้อง ${subOrderIdLabels} ให้เป็น "${newShippingTypeLabel}" ด้วยหรือไม่\n\n*คุณสามารถแก้ไขรูปแบบจัดส่งของแต่ละออเดอร์ซื้อได้ในภายหลัง`,
              'แก้ไขแค่ออเดอร์ขายนี้เท่านั้น',
              () => {
                this.props.onShippingTypeChange(payload)
              },
              'แก้ไขออเดอร์ซื้อที่เกี่ยวข้องทั้งหมดด้วย',
              () => {
                // log('แก้ไขออเดอร์ซื้อทั้งหมดด้วย')
                payload.update_purchase_orders = true
                this.props.onShippingTypeChange(payload)
              }
            )
          } else {
            // order type 1 || 2 without sub orders
            this.props.onShippingTypeChange(payload)
          }
        },
        null,
        'ยืนยัน'
        // this.props.onShippingTypeChange(payload)
        // onShippingTypeChange
        // }, null, 'ยืนยัน', 'ยกเลิก'
      )
    }

    // Refresh All Store Order List
    this.props.dispatch({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL })
  }

  // order = json order
  _successCallbackFromChangeShipping = (order: any) => {
    // console.log('_successCallbackFromChangeShipping => ', order)
    if (order && order.x) {
      this._callNewXShipping(this.props.selectedStore.get('id'), order.id)
    }
  }

  _cancelOrder = async (): Promise<void> => {
    const { cancelOrder, selectedStore } = this.props
    const order = this._getFocusedOrder()
    let selectedStoreID = selectedStore.get('id')
    if (_.isString(selectedStoreID)) {
      selectedStoreID = parseInt(selectedStoreID)
    }
    if (!selectedStoreID) {
      const params = this.state
      selectedStoreID = params.store_id ? params.store_id : null
    }
    log(this, "selectedStore.get('id') => ", selectedStore.get('id'))
    log(this, 'selectedStoreID => ', selectedStoreID)

    // KENG HANDLE CANCLE ORDER
    // const shippings = order.get('shippings') || List([])
    // if (shippings.size > 0) {
    //   Alert.alert('ไม่สามารถยกเลิกออเดอร์', 'เนื่องจากมีการจัดส่งสินค้าแล้ว ระบบจึงไม่อนุญาตให้ยกเลิกออเดอร์นี้',
    //     [{ text: 'ตกลง', onPress: () => { this.inProcess = false } }], { cancelable: false })
    // }

    const order_id = order.get('id') || null
    const updated_at = order.get('updated_at') || null

    if (selectedStoreID && order_id && updated_at) {
      const subOrders = order.get('suborders')
      const hasSubOrder = subOrders && subOrders.size > 0
      let warningMsgKey
      if (order.get('type') === 3) {
        warningMsgKey = 'Order.alertCancelOrderType3'
      } else if (hasSubOrder) {
        warningMsgKey = 'Order.alertCancelOrderType12HasSubOrder'
      } else {
        warningMsgKey = 'Order.alertCancelOrderType12NoSubOrder'
      }

      const isUserConfirmed = await new Promise<boolean>((passConfirm) => {
        p.op.showConfirmation(
          'คำเตือน',
          p.op.t(warningMsgKey),
          () => passConfirm(false),
          () => passConfirm(true),
          'ไม่ยกเลิกออเดอร์',
          'ยกเลิกออเดอร์'
        )
      })

      if (!isUserConfirmed) {
        return
      }

      cancelOrder({ store_id: selectedStoreID, order_id, updated_at })

      // p.op.showConfirmation('คำเตือน',
      //   p.op.t(warningMsgKey),
      //   () => {
      //     cancelOrder({ store_id: selectedStoreID, order_id, updated_at })
      //   },
      //   () => {
      //     this.inProcess = false
      //   },
      //   'ตกลง, ฉันต้องการยกเลิกออเดอร์',
      //   'ปิด, ฉันขอทบทวนดูก่อน',
      //   false,
      // )
      // this.showConfirmation({
      //   message: p.op.t('Order.alertCancelOrder'),
      //   resolveCallback: () => cancelOrder({ store_id: selectedStoreID, order_id }),
      //   txtOK: 'ตกลง, ฉันต้องการยกเลิกออเดอร์',
      //   txtCancel: 'ปิด, ฉันขอทบทวนดูก่อน',
      // })
    } else {
      p.op.alert('คำเตือน', 'เกิดข้อผิดพลาดบางประการ ไม่พบข้อมูลร้านฉัน กรุณากลับไปเลือกร้านค้าใหม่')
    }
  }

  _handleChangeOrderChannel = (channel_id: number) => {
    const { editingOrder, onEditingOptionChange, onEditingOrderChange } = this.props
    const { store_id, order_id, customCreateOrder, mode, orderTabIndex } = this.state
    const parentOrderId = parseSmartInt(editingOrder.get('id'))
    const oldChannelId = parseSmartInt(editingOrder.get('channel_id'))

    if (parentOrderId && _.isNumber(channel_id) && oldChannelId !== channel_id) {
      const order = this._getFocusedOrder()
      if (this._isLockedOrderShowAlert(order)) {
        return
      }

      onEditingOptionChange({
        store_id,
        order_id: parentOrderId,
        key: 'channel_id',
        value: channel_id,
        txtToast: 'ช่องทางการขาย',
      })
    }
    if (mode === CREATE) {
      const newCustomCreateOrder = customCreateOrder
      newCustomCreateOrder.sales_channel.vi = channel_id
      util.setStatePromise(this, { customCreateOrder: newCustomCreateOrder })
      onEditingOrderChange({ key: 'channel_id', value: channel_id, orderTabIndex })
      p.op.setAppDefaultValue('channel_id', channel_id)
    }
  }

  // #### New Refactor Method
  _handleHideProfit = async (): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    await this._toggleShowProfit(false)
    this.inProcess = false
  }

  _handleShowProfit = async (): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    await this._toggleShowProfit(true)
    this.inProcess = false
  }

  _handleUnlockOrder = async (): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const order = this._getFocusedOrder()
    const erpDocs: List<IMap<IOrderErpChannels>> = order.get('erp_docs') || List([])
    let isErpDocUsed = false

    for (let i = 0; i < erpDocs.size; i++) {
      const erpDoc = erpDocs.get(i)
      const erpDocCancelledAt = erpDoc.get('cancelled_at') || null
      if (_.isNil(erpDocCancelledAt) || !_.isString(erpDocCancelledAt)) {
        isErpDocUsed = true
        break
      }
    }

    if (isErpDocUsed) {
      p.op.alert('มีการใช้งานเอกสารสำหรับระบบบัญชีอยู่', 'กรุณายกเลิกเอกสารทั้งหมด ก่อนทำการแก้ไขออเดอร์')
      this.inProcess = false
      return
    }

    await this._toggleOrderLock(false)
    this.inProcess = false
  }

  _handleLockOrder = async (): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    await this._toggleOrderLock(true)
    this.inProcess = false
  }

  // ===================== Order Detail ============================
  _toggleShowProfit = async (value: boolean): Promise<void> => {
    const { mode } = this.state
    await setStatePromise(this, { showProfit: value })
    await p.op.setAppDefaultValue('order_selected_showProfit', value)
    await this._changeModeTo(mode)
  }

  _toggleOrderLock = async (lock: boolean): Promise<void> => {
    const { store_id } = this.state
    const { selectedStore, onEditingOptionChange } = this.props
    const order = this._getFocusedOrder()
    const order_id = order.get('id')
    const selectedStoreId = store_id || selectedStore.get('id')
    if (selectedStoreId && selectedStoreId > 0 && order_id) {
      onEditingOptionChange({
        store_id: selectedStoreId,
        order_id,
        key: 'prevent_mod',
        value: lock,
        txtToast: `${lock ? 'ล็อก' : 'ปลดล็อก'} ออเดอร์นี้สำเร็จแล้ว`,
      })
      await new Promise((reduxDelay) => setTimeout(reduxDelay, 50))
    }
  }

  _canShip = (orderState, shippingState) =>
    // Immutable products
    util.isOrderInShippableState(orderState, shippingState) && acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.SHIPPING_ADD)

  _getMainButtonsProperties = (order: Map<string, any>) => {
    const { mode, store_id } = this.state
    const order_id = Map.isMap(order) ? order.get('id') : null

    if (!order_id) {
      return null
    }

    const type = parseInt(order.get('type'))
    const state = parseInt(order.get('state'))
    const payment_state = parseInt(order.get('payment_state'))
    const shipping_state = parseInt(order.get('shipping_state'))

    const { CONFIRM, PAY, SHIP } = CONS.ORDER_OPERATION_AVAILABILITY
    const statusConfirmBtn = CONFIRM[`_${type}${state}`] || 0
    let statusPayBtn = PAY[`_${type}${payment_state}`] || 0
    // util.log('...statusPayBtn statusPayBtn =>', statusPayBtn)
    // util.log('...statusPayBtn type =>', type)
    // util.log('...statusPayBtn payment_state =>', payment_state)
    // util.log('...statusPayBtn PAY => ', PAY[`_${type}${payment_state}`])

    // Additional logic for pay btn as the remaining amount does have effect
    const remainingAmt = parseFloat(order.get('remaining_forecast_amount'))
    // util.log('...statusPayBtn remainingAmt => ', remainingAmt)
    if (remainingAmt > 0 && type === 3) {
      statusPayBtn = 1 // แจ้งชำระ
    }

    let statusShipBtn
    // if (util.isOrderInShippableState(state, shipping_state)) {
    if (this._canShip(state, shipping_state)) {
      statusShipBtn = SHIP[`_${type}${shipping_state}`] || 0
    } else {
      statusShipBtn = 0
    }

    // const isCancelState = util.isOrderInCancelledState(state)
    // if (isCancelState) {
    //   statusShipBtn = 0
    // } else {
    //   statusShipBtn = SHIP[`_${type}${shipping_state}`] || 0
    // }

    // เงื่อนไขพิเศษ ถ้ามีการจ่ายเงินเข้ามาแล้ว ในระหว่างที่ state ยังไม่จบ (payment state = 119 สำหรับ type 1,2)
    // แต่มี payments เข้ามา ให้แสดงปุ่ม "ยืนยันรับชำระก่อน"
    if (payment_state !== 119 && (type === 1 || type === 2)) {
      const payments = order.get('payments') || List([])
      for (let i = 0; i < payments.size; i++) {
        const payment = payments.get(i)
        const status = payment.get('status')
        const isRefund = payment.get('isRefund')
        // util.log('special case index=' + i + ' status=' + status + ' isRefund=' + isRefund)
        if (status === 0 && isRefund === false) {
          statusPayBtn = CONS.ORDER_OPERATION_AVAILABILITY.PAY.CONFIRM_GETTING_PAID // ยืนยันรับชำระ
          break
        }
      }
    } else if (payment_state !== 119 && type === 3) {
      // สำหรับ type 3 ได้รับเงินคืน ก็ต้องมีปุ่มยืนยันรับเงินคืนด้วย
      const payments = order.get('payments') || List([])
      for (let i = 0; i < payments.size; i++) {
        const payment = payments.get(i)
        const status = payment.get('status')
        const isRefund = payment.get('isRefund')
        if (status === 0 && isRefund === true) {
          statusPayBtn = CONS.ORDER_OPERATION_AVAILABILITY.PAY.CONFIRM_GETTING_REFUND // ยืนยันรับยืนยันรับเงินคืน
          break
        }
      }
    }

    log(this, `_renderFooter statusConfirmBtn : ${statusConfirmBtn}`)
    log(this, `_renderFooter statusPayBtn : ${statusPayBtn}`)
    log(this, `_renderFooter statusShipBtn : ${statusShipBtn}`)

    const txtConfirmBtn =
      statusConfirmBtn > 0 ? p.op.t(`Order.OrderConfirmButtonText._${statusConfirmBtn}`) : p.op.t(`Order.OrderStatesText._${type}${state}`)
    const txtPayBtn =
      statusPayBtn > 0 ? p.op.t(`Order.OrderPayButtonText._${statusPayBtn}`) : p.op.t(`Order.OrderStatesText._${type}${payment_state}`)
    const txtShipBtn =
      statusShipBtn > 0 ? p.op.t(`Order.OrderShipButtonText._${statusShipBtn}`) : p.op.t(`Order.OrderStatesText._${type}${shipping_state}`)

    // if (_.includes([CREATE, EDIT], mode) || !mode || !type && !state && !payment_state && !shipping_state) {
    //   return <View/>
    // }

    const btnModeForConfirm = statusConfirmBtn > 0 ? CONS.BTN_MODE.ACTIVE_PRIMARY : CONS.BTN_MODE.INACTIVE // use 1, 2, 3
    let btnModeForPayment = statusPayBtn > 0 ? CONS.BTN_MODE.ACTIVE_PRIMARY : CONS.BTN_MODE.INACTIVE
    if (CONS.BTN_MODE.ACTIVE_PRIMARY === btnModeForPayment && CONS.BTN_MODE.ACTIVE_PRIMARY === btnModeForConfirm) {
      btnModeForPayment = CONS.BTN_MODE.ACTIVE_SECONDARY
    }

    let btnModeForShipping
    if (statusShipBtn <= 0) {
      btnModeForShipping = CONS.BTN_MODE.INACTIVE
    } else if (btnModeForConfirm !== CONS.BTN_MODE.INACTIVE || btnModeForPayment !== CONS.BTN_MODE.INACTIVE) {
      btnModeForShipping = CONS.BTN_MODE.ACTIVE_SECONDARY
    } else {
      btnModeForShipping = CONS.BTN_MODE.ACTIVE_PRIMARY
    }

    // // let paymentFunc = statusPayBtn > 2
    // let paymentFunc = statusPayBtn === CONS.ORDER_OPERATION_AVAILABILITY.PAY.CONFIRM_GETTING_PAID ||
    // statusPayBtn === CONS.ORDER_OPERATION_AVAILABILITY.PAY.CONFIRM_GETTING_REFUND
    //   ? () => navigation.dispatch(
    //     NavActions.navToPaymentListView({
    //       store_id,
    //       order_id,
    //       btnRight: this._getPaymentListAction(),
    //       fromOrderDetail: true,
    //     })
    //   ) // confirm payment
    //   : () => this.goToPayment({ order: order }) // to pay

    // TODO: Refactor onPress methods by mode for 3 Main Buttons
    const properties =
      CONS.BTN_MODE.INACTIVE === btnModeForConfirm &&
      CONS.BTN_MODE.INACTIVE === btnModeForPayment &&
      CONS.BTN_MODE.INACTIVE === btnModeForShipping
        ? null
        : {
            confirm: {
              text: txtConfirmBtn,
              mode: btnModeForConfirm,
            },
            pay: {
              text: txtPayBtn,
              mode: btnModeForPayment,
            },
            ship: {
              text: txtShipBtn,
              mode: btnModeForShipping,
            },
          }
    return properties
  }

  _onRequestCloseSellerStoreSelector = () => {
    this.setState({ isVisibleSellerStoreSelector: !this.state.isVisibleSellerStoreSelector })
  }

  _handleChangeSellerStore = (index: number) => {
    const isAvailableStore = !_.isNil(this.props.sellerStores.getIn([index, 'id']))
    const newSelectedStoreIndex = isAvailableStore ? index : -1
    this.setState(
      {
        isVisibleSellerStoreSelector: false,
        optionSelectedSellerStoreIndex: newSelectedStoreIndex,
      },
      () => {
        p.op.setAppDefaultValue('order_selected_seller_store_index', newSelectedStoreIndex)

        if (p.op.isWeb()) {
          xUtil.navPopToCurrent(this.props)
        }
      }
    )
  }

  _canEditOrder = (order: Map<string, any>, mode) => {
    const state = parseInt(order.get('state') || 0)
    const type = parseInt(order.get('type') || 0)
    return (
      _.includes([VIEW_EDIT], mode) &&
      // !(_.includes([2, 3], type) && state === 101) && // เอาออกเพื่อ warning
      // !(_.includes([1, 2], type) && state === 102) && // เอาออกเพื่อยอมให้กดแล้ว warning
      !(type === 3 && state === 103)
      // !(type === 3 && state === 103) && !(state > 180)
    )
  }

  _isShowProfit = () => this.state.showProfit

  _canCancelOrder = (order: Map<string, any>, mode) => {
    // // Immutable shippings
    // return (_.includes([VIEW_EDIT, EDIT], mode)) && !(shippings.size > 0 || state >= 180) // ยกเลิกได้ต่อเมื่อยังไม่ได้จัดส่ง และ ยังไม่ถูกยกเลิกมาก่อน
    // KENG HANDLE CANCLE ORDER
    const state = parseInt(order.get('state') || 0)
    const shippings = order.get('shippings') || List([])
    return _.includes([VIEW_EDIT, EDIT], mode) && state < 180 // ยกเลิกได้ต่อเมื่อยังไม่ถูกยกเลิกมาก่อน ถึงจัดส่งครบถ้วนแล้วก็ยกเลิกได้
  }

  // Determine order is should be deal mode?
  _calculateEditableInConfirmationMode = (order: Map<string, any>) => {
    // isMarketPlacesOrder = true => dont edit
    const isMarketPlacesOrder = xUtil.isMarketPlacesOrder(order)
    if (isMarketPlacesOrder) {
      return { isEditableQty: false, isEditableEtc: false }
    }
    // const order_id = order.get('id') || null
    const type = parseInt(order.get('type') || 0)
    const state = parseInt(order.get('state') || 0)
    const payment_state = parseInt(order.get('payment_state') || 0)
    const shipping_state = parseInt(order.get('shipping_state') || 0)

    if (type && state && payment_state && shipping_state && state < 109 && acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_EDIT)) {
      const isEditableInConfirmationMode = CONS.ORDER_OPERATION_AVAILABILITY.CONFIRM[`_${type}${state}`] || 0
      switch (isEditableInConfirmationMode) {
        // case 0:
        //   return { isEditableQty: false, isEditableEtc: false }
        case 1:
          return { isEditableQty: true, isEditableEtc: true }
        case 2:
          return { isEditableQty: true, isEditableEtc: true }
        // return { isEditableQty: false, isEditableEtc: true }
        default:
          return { isEditableQty: false, isEditableEtc: false }
      }
    }
    return { isEditableQty: false, isEditableEtc: false }
  }

  _canAutoOperations = (order: Map<string, any>): boolean => {
    if (!Map.isMap(order) || this.isOrderInCancelled(order)) {
      return false
    }
    const type = parseInt(order.get('type') || 0)
    const products = order.get('products') || List([])
    const remaining_forecast_amount = parseFloat(order.get('remaining_forecast_amount') || 0)
    // return this._canAutoFinishPayment(type, remaining_forecast_amount) || this._canAutoFinishShipping(type, products)
    return this._canAutoFinishPayment(type, remaining_forecast_amount) && this._canAutoFinishShipping(type, products)
  }

  _orderCanAutoFinishPayment = (order: Map<string, any>): boolean => {
    if (!Map.isMap(order) || this.isOrderInCancelled(order) || this._canAutoOperations(order)) {
      return false
    }
    const type = parseInt(order.get('type') || 0)
    const remaining_forecast_amount = parseFloat(order.get('remaining_forecast_amount') || 0)
    return this._canAutoFinishPayment(type, remaining_forecast_amount)
  }

  _orderCanAutoFinishShipping = (order: Map<string, any>): boolean => {
    if (!Map.isMap(order) || this.isOrderInCancelled(order) || this._canAutoOperations(order)) {
      return false
    }
    const type = parseInt(order.get('type') || 0)
    const products = order.get('products') || List([])
    return this._canAutoFinishShipping(type, products)
  }

  _canAutoFinishPayment = (type: number, remaining_forecast_amount: number): boolean =>
    type === 1 && remaining_forecast_amount > 0 && acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PAYMENT_ADD)

  _canPrintShippingLabel = (order: Map<string, any>): boolean => {
    // Immutable products
    // log('_canPrintShippingLabel order => ', order.toJS())
    const state = parseInt(order.get('state') || 0)
    const type = parseInt(order.get('type') || 0)
    const shipping_state = parseInt(order.get('shipping_state') || 0)
    return (
      util.isOrderInShippableState(state, shipping_state) &&
      CONS.ORDER_OPERATION_AVAILABILITY.SHIP[`_${type}${shipping_state}`] !== 0 &&
      acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_PRINT_SHIPPING)
    )
  }

  _canAutoFinishShipping = (type: number, products: List<Map<string, any>>): boolean => {
    // Immutable products
    if (!type || !isImmutable(products)) {
      return false
    }
    let needToShipQty = 0
    if (isImmutable(products) && products.size > 0) {
      products.forEach((product: Map<string, any>) => {
        if (isImmutable(product)) {
          const qty = product && isImmutable(product) ? parseInt(product.get('qty') || 0) : 0
          const shipped_qty = product && isImmutable(product) ? parseInt(product.get('shipped_qty') || 0) : 0
          needToShipQty += qty - shipped_qty
        }
      })
    }

    return (type === 1 || type === 2) && products.size > 0 && needToShipQty > 0
  }

  _onPressAutoFinishPayment = async (isConfirmed?: boolean): Promise<void> => {
    const { dispatch } = this.props
    const nowDateTime = moment()
    if (!_.isFunction(dispatch) || !nowDateTime) {
      p.op.alert('ข้อมูลฟังก์ชันไม่ถูกต้อง', 'กรุณาโหลดออเดอร์ใหม่แล้วทำรายการใหม่อีกครั้ง')
      return
    }

    const order = this._getFocusedOrder()
    const { store_id } = this.state
    if (!Map.isMap(order) || !order.get('id') || !store_id) {
      p.op.alert('ข้อมูลออเดอร์ไม่ถูกต้อง', 'กรุณาโหลดออเดอร์ใหม่แล้วทำรายการใหม่อีกครั้ง')
      return
    }
    const order_id = order.get('id') || null
    const total_amount = order.get('remaining_forecast_amount') || 0

    if (!isConfirmed) {
      const isUserConfirm = await p.op.isUserConfirm(
        'บันทึกรับชำระอัตโนมัติ',
        `คุณต้องการบันทึกรับชำระจากลูกค้าแบบอัตโนมัติ หรือไม่?\n\nหากยืนยัน ระบบจะบันทึกว่า คุณได้รับชำระจากลูกค้าเต็มจำนวน\nณ ${nowDateTime.format(
          'DD/MM/YYYY HH:mm น.'
        )} โดยไม่แนบสลิป`,
        'ยืนยัน',
        'ยกเลิก'
      )

      if (!isUserConfirm) {
        return
      }
    }
    // Alert.alert('ปิดออเดอร์ซื้อแล้ว')
    if (total_amount > 0) {
      dispatch({
        type: actions.AUTOMATIC_SUBMIT_PAYMENT,
        payload: {
          note: 'บันทึกรับชำระจากลูกค้าแบบอัตโนมัติ',
          total_amount,
          post_date: xFmt.formatToServerDateTime(nowDateTime),
          store_id,
          // payment_account_id: 76, // <---- ต้องไป fetch เอามา
          pay_to_store_id: store_id,
          orders: [{ order_id, amount: total_amount }],
        },
      })
      p.op.aLogEvent('BUTTON_autoFinishPayment', { amt: total_amount })
    }
  }

  _onPressAutoFinishShipping = async (isConfirmed?: boolean): Promise<void> => {
    // Pass condition products.size > 0
    const { dispatch } = this.props
    const { store_id } = this.state
    if (!_.isFunction(dispatch)) {
      p.op.alert('ข้อมูลฟังก์ชันไม่ถูกต้อง', 'กรุณาโหลดออเดอร์ใหม่แล้วทำรายการใหม่อีกครั้ง')
      return
    }

    const order = this._getFocusedOrder()
    if (!Map.isMap(order) || !order.get('id') || !store_id) {
      p.op.alert('ข้อมูลออเดอร์ไม่ถูกต้อง', 'กรุณาโหลดออเดอร์ใหม่แล้วทำรายการใหม่อีกครั้ง')
      return
    }

    const order_id = order.get('id') || null
    const shipping_type_id = parseInt(order.get('shipping_type_id')) || 0
    const transaction_cost = parseFloat(order.get('transaction_cost')) || 0
    const tracking_number = '-'
    const note = 'บันทึกจัดส่งอัตโนมัติ'
    const products = []
    let totalQty = 0
    const orderProducts = order.get('products') || List([])

    orderProducts.forEach((product: Map<string, any>) => {
      if (isImmutable(product)) {
        const pp_id = product.get('pp_id')
        const qty = product.get('qty') - product.get('shipped_qty')
        if (pp_id && qty > 0) {
          totalQty += qty
          products.push({ pp_id, qty })
        }
      }
    })

    if (!isConfirmed) {
      const isUserConfirm = await p.op.isUserConfirm(
        'บันทึกจัดส่งอัตโนมัติ',
        'คุณต้องการบันทึกจัดส่งแบบอัตโนมัติ หรือไม่?\n\nหากยืนยัน ระบบจะบันทึกว่า ออเดอร์นี้จัดส่งครบถ้วนแล้ว โดยไม่ระบุเลขติดตามพัสดุ',
        'ยืนยัน',
        'ยกเลิก'
      )

      if (!isUserConfirm) {
        return
      }
    }

    if (products.length > 0) {
      const body = { store_id, order_id, shipping_type_id, tracking_number, note, products }
      // dispatch({ type: actions.SHIPMENT_ORDER_OPERATE_SHIP, payload: body })
      dispatch({ type: actions.SHIPMENT_ORDER_OPERATE_SHIP, body })
      p.op.aLogEvent('BUTTON_autoFinishShipping', { amt: transaction_cost, ok: true, qty: totalQty })
    }
  }

  _updateHamburgerMenuOptions = async (): Promise<void> => {
    await setStatePromise(this, { hamburgerMenuOptions: this._calculateHamburgerMenuOptions() })
    // await new Promise((computeDelay) => setTimeout(computeDelay, 100))
  }

  _calculateHamburgerMenuOptions = (): Array<{ label: string; isDanger?: boolean; onPress: () => void }> => {
    const { mode } = this.state
    if (!_.includes(ORDER_VIEW_MODE, mode)) {
      return []
    }
    const order = this._getFocusedOrder() // create mode should have null id
    const isMarketPlacesOrder = util.isMarketPlacesOrder(order)
    if (mode !== CREATE) {
      if (!Map.isMap(order) || !order.get('id')) {
        return []
      }
    }
    const topMenuOptions = []
    if (mode === CREATE) {
      this.hamburgerMenuItemsForCreateOrder.forEach((menu) => {
        const { key, perm, visibleCondition } = menu
        if (perm) {
          // ถ้า permission ผ่าน
          if (visibleCondition) {
            // ถ้าเงื่อนไขในการแสดงผลผ่าน
            topMenuOptions.push(menu)
          }
        }
      })
    } else {
      this.hamburgerMenuItems.forEach((menu) => {
        const { key, perm, visibleCondition } = menu
        //
        const mkp_id = order.has('mkp_id') ? order.get('mkp_id') : null
        const mkp_is_self_delivery = order.has('mkp_is_self_delivery') ? order.get('mkp_is_self_delivery') : null
        // console.log('order // => ', order.toJS())
        // TODO: TED Fund Phase 2: ซ่อนปุ่มเมนูต่างๆ นอกจากตั้งค่าออเดอร์ หากเป็น mkp_is_self_delivery = false
        const tempSkipRender = !_.isNil(mkp_id) && !_.isNil(mkp_is_self_delivery) && !mkp_is_self_delivery && key !== 'custom_order_view'
        // console.log('tempSkipRender => ', tempSkipRender)
        // if (acl.canDoAtSelectedStore(perm)) {
        if ((!perm || (perm && acl.canDoAtSelectedStore(perm))) && !tempSkipRender) {
          // ถ้า permission ผ่าน
          if (visibleCondition(order, mode)) {
            // ถ้าเงื่อนไขในการแสดงผลผ่าน
            topMenuOptions.push(menu)
            // No need anymore
            // if (key === 'edit_order' && order.get('type') === 3) {
            //   let modifiedMenu = _.clone(menu)
            //   modifiedMenu.label = 'แก้ไขราคา'
            //   topMenuOptions.push(modifiedMenu)
            // } else {
            //   topMenuOptions.push(menu)
            // }
          }
        }
      })
    }

    return topMenuOptions
  }

  getOrderStatusText = (orderProperties: { type: number; state: number; payment_state: number; shipping_state: number }): string => {
    const { type, state, shipping_state } = orderProperties

    const order = this._getFocusedOrder()
    // const shipBeforePay = order.get('ship_before_pay')
    // const original_payment_state =  orderProperties.payment_state
    // Simulate the payment_state to 119 if the flag `ship_before_pay` is set
    const payment_state = util.doesOrderWaitsToBeShippedThenPaid(order) ? 119 : orderProperties.payment_state
    const isMkpOrder = order.has('mkp_id')
    // console.log("type: " + type + " state: " + state + " payment_state: " + payment_state + " shipping_state: " + shipping_state)
    let translatedKey = 'Order.OrderStatesText.'
    if (type && state && payment_state && shipping_state) {
      if (state < 109) {
        translatedKey += `_${type}${state}`
      } else if (state >= 121 && state <= 189) {
        translatedKey += `_${type}${state}`
      } else if (payment_state < 119) {
        translatedKey += `_${type}${payment_state}`
      } else if (shipping_state <= 129) {
        translatedKey += `_${type}${shipping_state}`
      } else {
        translatedKey += '_0000'
      }
    }
    // เคสพิเศษ ถ้าเป็น order มาจาก MKP แล้ว state = 101 && payment_state = 111 ให้แสดงรอลูกค้าชำระ
    // อ้างอิง https://app.clickup.com/t/860pj7teh
    if (isMkpOrder && state === 101 && order.get('payment_state') === 111) {
      translatedKey = 'Order.OrderStatesText._1111'
    }
    // return '_0000'
    return p.op.t(translatedKey)
  }

  getOrderMyTaskTextByOrderType = (orderType: number): string => {
    let translatedKey = 'Order.'
    switch (orderType) {
      case 1:
        translatedKey += 'dispTaskSellToCustomer'
        break
      case 2:
        translatedKey += 'dispTaskSellToReseller'
        break
      case 3:
        translatedKey += 'dispTaskBuyFromSeller'
        break
      default:
        translatedKey += 'Unknown'
    }
    return p.op.t(translatedKey)
  }

  getOrderTypeText = (orderType: number): string => (orderType === 3 ? 'ออเดอร์ซื้อ' : 'ออเดอร์ขาย')

  // getOrderStateBoxes = (
  //   order: Map<string, any>
  // ): Array<{
  //   key: number
  //   headerText: string
  //   headerTextSuffix: string
  //   subHeaderText: string
  //   data: Array<{
  //     text: string
  //     iconDisabled?: boolean
  //     status: boolean
  //   }>
  //   isCompleted?: boolean
  //   isLastColumn?: boolean
  // }> => {
  //   const type = order.get('type')
  //   const state = order.get('state')
  //   // const payment_state = order.get('payment_state')
  //   const shipping_state = order.get('shipping_state')
  //   const isCancelState = this.isOrderInCancelled(order)

  //   const NO_ACTION = { text: '-', iconDisabled: true }

  //   const payment = this.computeOrderPaymentInfo(order)
  //   // default all flags to false
  //   const isShippingDoneSeller = shipping_state === 129
  //   let buyerHeaderText
  //   let buyerSubHeaderText
  //   let sellerHeaderText
  //   let sellerSubHeaderText

  //   const isConfirmDoneBuyer = state !== 101 && state !== 103
  //   const isConfirmDoneSeller = state !== 101 && state !== 102

  //   const isPaymentDoneBuyer = payment.isDoneBuyer
  //   const isPaymentDoneSeller = payment.isDoneSeller
  //   // const txtShipBeforePay = util.checkShipBeForePayStatus(order) ? ' (จัดส่งก่อนชำระ)' : ''
  //   // let remainingAmt = order.get('remaining_forecast_amount')

  //   switch (type) {
  //     case 1:
  //     case 2:
  //       {
  //         buyerHeaderText = 'ตัวแทน'
  //         buyerSubHeaderText = order.get('partner_name')
  //         sellerHeaderText = 'ฉัน'
  //         sellerSubHeaderText = ''
  //       }
  //       break
  //     case 3:
  //       {
  //         buyerHeaderText = 'ฉัน'
  //         buyerSubHeaderText = ''
  //         sellerHeaderText = 'ผู้ขายส่ง'
  //         sellerSubHeaderText = order.get('partner_name')
  //         // isShippingDoneSeller = shipping_state === 129
  //       }
  //       break
  //     default:
  //       break
  //   }
  //   if (type === 1) {
  //     buyerHeaderText = 'ลูกค้า'
  //     buyerSubHeaderText = order.get('partner_name')
  //   }
  //   let buyerData
  //   let sellerData
  //   if (isCancelState) {
  //     const payments: { [key: string]: any } = order.get('payments')
  //     const hasPayment = payments && payments.count() > 0
  //     buyerData = [
  //       NO_ACTION,
  //       hasPayment ? { text: payment.buyerStatusText, status: isPaymentDoneBuyer } : NO_ACTION,
  //       // hasPayment ? { text: payment.buyerStatusText, status: false } : NO_ACTION,
  //       NO_ACTION,
  //     ]
  //     sellerData = [NO_ACTION, hasPayment ? { text: payment.sellerStatusText, status: isPaymentDoneSeller } : NO_ACTION, NO_ACTION]
  //   } else {
  //     buyerData = []
  //     if (type === 1) {
  //       buyerData.push(NO_ACTION)
  //     } else {
  //       buyerData.push({ text: 'ยืนยัน', status: isConfirmDoneBuyer })
  //     }
  //     buyerData.push({ text: payment.buyerStatusText, status: isPaymentDoneBuyer })
  //     buyerData.push(NO_ACTION)

  //     sellerData = [
  //       { text: 'ยืนยัน', status: isConfirmDoneSeller },
  //       { text: payment.sellerStatusText, status: isPaymentDoneSeller },
  //       { text: 'จัดส่ง', status: isShippingDoneSeller },
  //     ]
  //   }

  //   // เช็คว่าการดำเนินการเสร็จสิ้นทั้งหมดแล้วหรือไม่
  //   const isCompletedBuyer = buyerData.reduce((prevFact, bd) => {
  //     if (bd.iconDisabled) {
  //       return prevFact
  //     }
  //     return prevFact && bd.status
  //   }, true)

  //   const isCompletedSeller = sellerData.reduce((prevFact, sd) => {
  //     if (sd.iconDisabled) {
  //       return prevFact
  //     }
  //     return prevFact && sd.status
  //   }, true)

  //   // for(let i = 0; i < buyerData.length; i++) {
  //   //   buyerData[i]['isCompleted'] = isCompletedBuyer
  //   //   sellerData[i]['isCompleted'] = isCompletedSeller
  //   // }

  //   // log('XXX buyerData => ', buyerData)
  //   // log('XXX sellerData => ', sellerData)
  //   let sellerType = ' (ผู้ขาย)'
  //   if (order.has('helper_profile_id')) {
  //     const helper = order.get('helper_profile_id')
  //     const { selectedStore } = this.props
  //     const helpers = selectedStore.has('helpers') ? selectedStore.get('helpers') : null
  //     if (!_.isNil(helpers)) {
  //       helpers.map((h) => {
  //         if (h.get('pid') === helper) {
  //           if (h.has('n')) {
  //             sellerType = ` [${h.get('n')}]`
  //           } else {
  //             sellerType = ''
  //           }
  //           sellerHeaderText = 'ผู้ช่วย'
  //         }
  //       })
  //     }
  //   }
  //   return [
  //     {
  //       key: 0,
  //       headerText: buyerHeaderText,
  //       headerTextSuffix: ' (ผู้ซื้อ)',
  //       subHeaderText: buyerSubHeaderText,
  //       data: buyerData,
  //       isCompleted: isCompletedBuyer,
  //     },
  //     {
  //       key: 1,
  //       headerText: sellerHeaderText,
  //       headerTextSuffix: sellerType,
  //       subHeaderText: sellerSubHeaderText,
  //       data: sellerData,
  //       isLastColumn: true,
  //       isCompleted: isCompletedSeller,
  //     },
  //   ]
  // }

  // computeOrderPaymentInfo = (order: Map<string, any>): { [key: string]: boolean | string } => {
  //   const payment_state = order.get('payment_state')
  //   const ps = payment_state
  //   const payment = {
  //     isDoneBuyer: false,
  //     isDoneSeller: false,
  //     buyerStatusText: 'ชำระ',
  //     sellerStatusText: 'ยืนยัน',
  //   }

  //   const remainingAmt = order.get('remaining_forecast_amount')
  //   if (ps === 119) {
  //     payment.isDoneBuyer = true
  //     payment.isDoneSeller = true
  //   } else {
  //     if (remainingAmt <= 0 && ps !== 111 && ps !== 113 && ps !== 115) {
  //       payment.isDoneBuyer = true
  //     }

  //     if (remainingAmt >= 0 && ps !== 111 && ps !== 112 && ps !== 113 && ps !== 114) {
  //       payment.isDoneSeller = true
  //     }

  //     if (remainingAmt < 0 && ps === 112) {
  //       // refund case
  //       // display the following text for the case that seller has confirmed for the overpay
  //       payment.isDoneBuyer = false
  //       // payment.isDoneSeller = false
  //       payment.buyerStatusText = 'ยืนยัน'
  //       payment.sellerStatusText = 'คืนเงิน'
  //     }
  //     if (ps === 115) {
  //       payment.buyerStatusText = 'ยืนยัน'
  //       payment.sellerStatusText = 'คืนเงิน'
  //     } else if (ps === 113) {
  //       payment.buyerStatusText = 'ยืนยัน'
  //       payment.sellerStatusText = 'ยืนยัน'
  //     }
  //   }

  //   return payment
  // }

  getOrderShippingInfo = (
    order: Map<string, any>
  ): {
    myTotalShippedQtyCount: number
    myTotalOrderedQtyCount: number
    sellerTotalShippedQtyCount: number
    sellerTotalOrderedQtyCount: number
  } | null => {
    const order_updated_at = order.get('updated_at') || null
    if (!order_updated_at) {
      // แสดงว่าเป็นออเดอร์เปล่า
      return null
    }

    const type = order.get('type')
    let myTotalShippedQtyCount = 0
    let myTotalOrderedQtyCount = 0
    let sellerTotalShippedQtyCount = 0
    let sellerTotalOrderedQtyCount = 0

    if (type === 3) {
      // type 3 has no suborders
      order.get('products').forEach((product: Map<string, any>) => {
        // log(product)
        sellerTotalShippedQtyCount += parseInt(product.get('shipped_qty') || 0)
        sellerTotalOrderedQtyCount += parseInt(product.get('qty') || 0)
      })
    } else {
      order.get('products').forEach((product: Map<string, any>) => {
        // log(product)
        myTotalShippedQtyCount += parseInt(product.get('shipped_qty') || 0)
        myTotalOrderedQtyCount += parseInt(product.get('qty') || 0)
      })
      if (order.has('suborders') && order.get('suborders')) {
        order.get('suborders').forEach((subOrder: Map<string, any>) => {
          subOrder.get('products').forEach((product: Map<string, any>) => {
            sellerTotalShippedQtyCount += parseInt(product.get('shipped_qty') || 0)
            sellerTotalOrderedQtyCount += parseInt(product.get('qty') || 0)
          })
        })
      }
    }
    return {
      myTotalShippedQtyCount,
      myTotalOrderedQtyCount,
      sellerTotalShippedQtyCount,
      sellerTotalOrderedQtyCount,
    }
  }

  isOrderInCancelled = (order: Map<string, any>): boolean => {
    const state: number = order.get('state')
    return state >= 181 && state <= 184
  }

  getSenderTextByOrder = (order: Map<string, any>) => {
    // Immutable
    const sender = order.get('sender') || null
    if (!sender) {
      return ''
    }
    const use_store_addr = order.get('use_store_addr') || false
    const is_addr_forced = order.get('is_addr_forced') || false
    const order_type = order.get('type') || null
    // log('_senderParseToTextDetail sender.toJS()', sender.toJS())

    let txtFullAddress = ''
    let useFullAddress = false

    // const name = sender.get('name') || null
    const address1 = sender.get('address1') || null
    // const address2 = sender.get('address2') || null
    // const sub_district = sender.get('sub_district') || null
    // const district = sender.get('district') || null
    // const province = sender.get('province') || null
    // const postal_code = sender.get('postal_code') || null
    // const telephone = sender.get('telephone') || null

    if (order_type === 1 || (order_type === 2 && use_store_addr)) {
      useFullAddress = true
    } else if (order_type === 2 && !use_store_addr) {
      if (address1) {
        useFullAddress = true
      } else {
        txtFullAddress = '<< ใช้ที่อยู่ตัวแทน >>' // should be impossible case
      }
    } else if (order_type === 3) {
      if (!use_store_addr && !is_addr_forced) {
        useFullAddress = true
      } else if (is_addr_forced) {
        txtFullAddress = '<< ใช้ที่อยู่ของผู้ขายส่ง เนื่องจากผู้ขายส่งบังคับ >>'
      } else {
        txtFullAddress = '<< ใช้ที่อยู่ของผู้ขายส่ง >>'
      }
    }

    if (useFullAddress === true) {
      if (address1) {
        // txtFullAddress = address1
        // txtFullAddress = address2 ? `${txtFullAddress} ${address2}` : txtFullAddress
        // txtFullAddress = sub_district ? `${txtFullAddress} ${sub_district}` : txtFullAddress
        // txtFullAddress = district ? `${txtFullAddress} ${district}` : txtFullAddress
        // txtFullAddress = province ? `${txtFullAddress} ${province}` : txtFullAddress
        // txtFullAddress = postal_code ? `${txtFullAddress}\n${postal_code}` : txtFullAddress
        // txtFullAddress = telephone ? `${txtFullAddress}\nโทร. ${telephone}` : telephone
        txtFullAddress = xUtil.convertAddressToInlineAddress(sender)
      } else {
        txtFullAddress = 'ไม่ได้ระบุที่อยู่จัดส่ง'
      }
    }
    return txtFullAddress
  }

  changeOrderTabIndexTo = async (newIndex: number): Promise<void> => {
    log(this, 'changeOrderTabIndexTo newIndex => ', newIndex)
    if (newIndex >= 0) {
      await setStatePromise(this, { orderTabIndex: newIndex })
    } else {
      await setStatePromise(this, { orderTabIndex: 0, orderTabsVisibility: false })
    }
  }

  _autoSetOrderTabIndex = async (): Promise<void> => {
    // const currentTabIndex = this.state.orderTabIndex
    // const currentParentOrder = this.props.editingOrder

    // If index has valuable
    // if (currentTabIndex === 0) {
    //   const isValuableParentOrder = currentParentOrder.get('updated_at')
    //   if (isValuableParentOrder) {
    //     await setStatePromise(this, {orderTabIndex: 0})
    //   }
    // } else if (currentTabIndex > 0) {
    //   const suborders = currentParentOrder.get('suborders') || List([])
    //   const isValuableChildOrder = suborders.getIn([currentTabIndex, 'updated_at'])
    //   if (isValuableChildOrder) {
    //     await setStatePromise(this, {orderTabIndex: currentTabIndex})
    //   }
    // }
    const { orderTabIndex, order_id } = this.state
    const { editingOrder } = this.props
    const isValuableParentOrder = !!editingOrder.get('updated_at')
    const parentOrderId = editingOrder.get('id')
    // For refresh case
    const currentTabIsValuable =
      (orderTabIndex === 0 && isValuableParentOrder) ||
      (orderTabIndex > 0 && !!editingOrder.getIn(['suborders', orderTabIndex - 1, 'updated_at']))
    if (currentTabIsValuable) {
      // log(this, '_autoSetOrderTabIndex currentTabIsValuable => ', currentTabIsValuable)
      return
    }

    // log(this, '_autoSetOrderTabIndex isValuableParentOrder => ', isValuableParentOrder)
    // log(this, '_autoSetOrderTabIndex parentOrderId => ', parentOrderId)
    if (parentOrderId === order_id && isValuableParentOrder && orderTabIndex !== 0) {
      await setStatePromise(this, { orderTabIndex: 0 })
      await new Promise((setStateDelay) => setTimeout(setStateDelay, 50))
      return
    }
    const subOrders = editingOrder.get('suborders') || List([])
    for (let i = 0; i < subOrders.size; i++) {
      const subOrder = subOrders.get(i) || Map({})
      const isValuableSubOrder = !!subOrder.get('updated_at')
      const subOrderId = subOrder.get('id')
      const subOrderRealIndex = i + 1
      // log(this, '_autoSetOrderTabIndex subOrderRealIndex => ', subOrderRealIndex)
      // log(this, '_autoSetOrderTabIndex isValuableSubOrder => ', isValuableSubOrder)
      // log(this, '_autoSetOrderTabIndex subOrderId => ', subOrderId)
      if (subOrderId === order_id && isValuableSubOrder && orderTabIndex !== subOrderRealIndex) {
        await setStatePromise(this, { orderTabIndex: subOrderRealIndex })
        await new Promise((setStateDelay) => setTimeout(setStateDelay, 50))
        return
      }
    }
  }

  _loadUgPgDiscountInOrderToState = async (): Promise<void> => {
    const { editingOrder, userGroups, productGroups } = this.props
    if (!Map.isMap(editingOrder) || !List.isList(userGroups) || !List.isList(productGroups)) {
      return
    }

    const isValuableParentOrder = !!editingOrder.get('updated_at')
    if (!isValuableParentOrder) {
      return
    }

    const ug_id = editingOrder.get('user_group_id')
    // const pg_id = editingOrder.getIn(['ugpg_discounts', 0, 'pg_id'])
    // log(this, '_loadUgPgDiscountInOrderToState ug_id => ', ug_id)
    // log(this, '_loadUgPgDiscountInOrderToState pg_id => ', pg_id)

    const newState: Partial<IBaseOrderViewState> = {}

    if (ug_id) {
      newState.optionChooseProductsFollowingIndex = 0 // ใช้กลุ่มสมาชิก
      const foundUgIndex = userGroups.findIndex((ug) => Map.isMap(ug) && ug.get('id') === ug_id)
      newState.optionChooseProductsByGroupIndex = foundUgIndex
    } else {
      // if (pg_id) {
      newState.optionChooseProductsFollowingIndex = 1 // // ใช้กลุ่มสินค้า
      // const foundPgIndex = productGroups.findIndex(pg => Map.isMap(pg) && pg.get('id') === pg_id)
      newState.optionChooseProductsByGroupIndex = 0
    }

    // log(this, '_loadUgPgDiscountInOrderToState newState => ', newState)
    if (!_.isEmpty(newState)) {
      await setStatePromise(this, newState)
      await new Promise((setStateDelay) => setTimeout(setStateDelay, 50))
    }
  }

  _initCreateOrderWithPGUG = async (): Promise<void> => {
    const { mode } = this.state
    if (mode !== CREATE) {
      return
    }

    const { editingOrder, userGroups, productGroups } = this.props
    if (!Map.isMap(editingOrder) || !List.isList(userGroups) || !List.isList(productGroups)) {
      return
    }
    // const hasOnlyOneUG = userGroups.size === 1
    // const hasOnlyOnePG = productGroups.size === 1
    const newState: Partial<IBaseOrderViewState> = {}

    // SET Selected last PG/UG
    await p.op.storageGet(CONS.STORAGE_KEYS.CREATE_ORDER_LAST_SELECTED_STORE).then((val: any) => {
      // console.log('val => !! ', val)
      if (!_.isNil(val)) {
        newState.optionChooseProductsFromIndex = val
      }
    })

    // ค่าพื้นฐาน
    if (userGroups.size > 1) {
      newState.optionChooseProductsFollowingIndex = 0 // ใช้กลุ่มสมาชิก
      newState.optionChooseProductsByGroupIndex = 0
    } else {
      newState.optionChooseProductsFollowingIndex = 1 // // ใช้กลุ่มราคา
      newState.optionChooseProductsByGroupIndex = 0
    }

    // ค่าจากการจำครั้งล่าสุด
    let optionChooseProductsFollowingIndexForStorage = 0
    await p.op.storageGet(CONS.STORAGE_KEYS.CREATE_ORDER_LAST_SELECTED_PG_UG).then((val: any) => {
      // console.log('PG UG => !! ', val)
      if (!_.isNil(val)) {
        optionChooseProductsFollowingIndexForStorage = val
        newState.optionChooseProductsFollowingIndex = val
      }
    })
    // console.log('userGroups => ', userGroups.size)
    // console.log('productGroups => ', productGroups.size)
    await p.op.storageGet(CONS.STORAGE_KEYS.CREATE_ORDER_LAST_SELECTED_PG_UG_INDEX).then((val: any) => {
      // console.log('PG UG INDEX => !! ', val)
      if (!_.isNil(val)) {
        // ใช้กลุ่มสมาชิก
        if (optionChooseProductsFollowingIndexForStorage === 0 && userGroups.size >= val + 1) {
          newState.optionChooseProductsByGroupIndex = val
          // ใช้กลุ่มราคา
        } else if (optionChooseProductsFollowingIndexForStorage === 1 && productGroups.size >= val + 1) {
          newState.optionChooseProductsByGroupIndex = val
        }
      }
    })

    if (!_.isEmpty(newState)) {
      await setStatePromise(this, newState)
      // await new Promise((setStateDelay) => setTimeout(setStateDelay, 50))
    }
  }

  _onSegmentChangeChooseProductFrom = (newIndex: number): void => {
    if (this.state.optionChooseProductsFromIndex === newIndex) {
      return // ignore duplicated
    }
    this.setState({ optionChooseProductsFromIndex: newIndex }, () => {
      if (p.op.isWeb()) {
        xUtil.navPopToCurrent(this.props)
      }
    })
  }

  _onSegmentChangeChooseCodFeeCalculationMethod = async (newIndex: number) => {
    // if (this.state.optionChooseCodFeeCalculationMethodIndex === newIndex) {
    //   return // ignore duplicated
    // }
    this.moneyType = true

    const onFocusDefaultFeeCod = await this.inputRefs.find((val) => val.key === 'initialCODFee')
    await util.setStatePromise(this, { optionChooseCodFeeCalculationMethodIndex: newIndex })
    // this.setState({ optionChooseCodFeeCalculationMethodIndex: newIndex })
    if (!_.isNil(onFocusDefaultFeeCod)) {
      onFocusDefaultFeeCod.value.focus()
    } else {
      setTimeout(() => {
        const onFocusDefaultFeeCod = this.inputRefs.find((val) => val.key === 'initialCODFee')
        if (!_.isNil(onFocusDefaultFeeCod)) {
          onFocusDefaultFeeCod.value.focus()
        }
      }, 100)
    }
  }

  _onSegmentChangeMinimumFee = (newIndex: number): void => {
    // if (this.state.optionChooseMinimumFeeFromIndex === newIndex) {
    //   return // ignore duplicated
    // }
    this.minimumFee = true
    this.setState({ optionChooseMinimumFeeFromIndex: newIndex })
  }

  _onSegmentChangeShippingMKPType = (newIndex: number): void => {
    // if (newIndex === 1) {
    //   p.op.showConfirmationOkOnly('กรุณาติดต่อทีมงาน', 'หากต้องการใช้ "Dropoff" กรุณาติดต่อ ทีมงานที่ LINE: @XSelly หรือ โทร 097-167-1650')
    //   return
    // } else
    if (newIndex === 2) {
      p.op.showConfirmationOkOnly(
        'กรุณาติดต่อทีมงาน',
        'หากต้องการใช้รูปแบบขนส่ง "อื่นๆ" กรุณาติดต่อ ทีมงานที่ LINE: @XSelly หรือ โทร 097-167-1650'
      )
      return
    }
    util.setStatePromise(this, {
      optionChooseShippingMKPTypeFromIndex: newIndex,
    })
  }

  _openDatePickerDateDelivery = () => {
    if (this.dateTimePickerDateDeliveryRef && this.dateTimePickerDateDeliveryRef.current) {
      if (_.isFunction(this.dateTimePickerDateDeliveryRef.current._openDatePicker())) {
        this.dateTimePickerDateDeliveryRef.current._openDatePicker()
      }
    }
  }

  _onSegmentChangeSetUpDateDelivery = (newIndex: number): void => {
    const { mode, optionChooseSetupDateDeliveryOrderIndex } = this.state
    if (newIndex === optionChooseSetupDateDeliveryOrderIndex) {
      return
    }
    if (mode === CREATE) {
      if (newIndex === 1) {
        this._openDatePickerDateDelivery()
        const today = new Date()
        const newDateAddTwoDay = new Date()
        newDateAddTwoDay.setDate(today.getDate() + 2)
        util.setStatePromise(this, {
          selectedDateRangeSetUpDateDeliveryOrder: newDateAddTwoDay,
        })
      } else if (newIndex === 0) {
        util.setStatePromise(this, {
          optionChooseSetupDateDeliveryOrderIndex: newIndex,
        })
      }
    } else if (mode === VIEW_EDIT) {
      if (!acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_EDIT)) {
        p.op.showConfirmationOkOnly('', p.op.t('warning.no_right'))
        return
      }
      const order = this._getFocusedOrder()
      const orderLock = this._isLockedOrder(order)
      const orderType = order.get('type') ? parseInt(order.get('type')) : 0
      if (orderType === 3) {
        this._warningCannotEditOnPurchaseOrder()
        return
      }
      if (orderLock) {
        p.op.showConfirmationOkOnly(
          'ออเดอร์ถูกล็อก',
          'หากต้องการแก้ไขวันกำหนดส่ง กรุณาเลือก "ปุ่ม 3 จุด ข้างบนทางขวา" เลือก "ปลดล็อกออเดอร์นี้"'
        )
        return
      }
      const today = new Date()
      const newDateAddTwoDay = new Date()
      newDateAddTwoDay.setDate(today.getDate() + 2)
      if (newIndex === 1 && optionChooseSetupDateDeliveryOrderIndex === 0) {
        this._openDatePickerDateDelivery()
        util.setStatePromise(this, {
          selectedDateRangeSetUpDateDeliveryOrder: newDateAddTwoDay,
          // optionChooseSetupDateDeliveryOrderIndex: newIndex,
          // isShowWraningDateDelivery: true,
        })
      } else if (newIndex === 0 && optionChooseSetupDateDeliveryOrderIndex === 1) {
        p.op.showConfirmation(
          'ปิดใช้งานวันกำหนดส่ง',
          '',
          () => {
            this._onChangeDateDelivery(null)
            util.setStatePromise(this, {
              optionChooseSetupDateDeliveryOrderIndex: newIndex,
              selectedDateRangeSetUpDateDeliveryOrder: newDateAddTwoDay,
            })
          },
          () => null,
          'ตกลง',
          'ยกเลิก'
        )
        //
      }
    } else if (mode === EDIT) {
      this._warningCantEditOnEditMode()
    }
  }

  _warningCantEditOnEditMode = () => {
    p.op.showConfirmationOkOnly(
      '',
      'ไม่สามารถแก้ไขได้ในโหมดแก้ไขออเดอร์ กรุณากดปุ่ม"ยกเลิก"ด้านบน เพื่อกลับสู่โหมดดูออเดอร์ปกติ แล้วแก้ไขอีกครั้ง'
    )
  }

  _openDatePickerAutoCancleOrder = () => {
    // console.log('this.dateTimePickerAutoCancelOrderRef => ', this.dateTimePickerAutoCancelOrderRef)
    if (this.dateTimePickerAutoCancelOrderRef && this.dateTimePickerAutoCancelOrderRef.current) {
      if (_.isFunction(this.dateTimePickerAutoCancelOrderRef.current._openDatePicker())) {
        this.dateTimePickerAutoCancelOrderRef.current._openDatePicker()
      }
    }
  }

  _onSegmentChangeSetUpAutoCancelOrder = async (
    newIndex: number,
    skipWarningDueToInitCustomCreateOrderSetting?: boolean
  ): Promise<void> => {
    const { selectedStore } = this.props
    const { mode, optionChooseSetupAutoCancelOrderIndex } = this.state
    if (newIndex === optionChooseSetupAutoCancelOrderIndex) {
      return
    }
    if (mode === CREATE) {
      let orderExp = selectedStore.get('order_exp')
      if (!_.isNumber(orderExp) || orderExp === 0) {
        // ถ้าไม่มีตั้งค่าร้าน ให้เพิ่มค่าเริ่มต้นที่ 2 วัน ( 2880 นาที )
        orderExp = 2880
      }
      const today = new Date()
      const newDate = new Date()
      newDate.setMinutes(today.getMinutes() + orderExp)
      if (newIndex === 1) {
        this._openDatePickerAutoCancleOrder()
        await util.setStatePromise(this, {
          selectedDateRangeSetUpAutoCancelOrder: newDate,
        })
      } else if (newIndex === 2) {
        this._checkDescriptionAutoCancelOrder()
        const orderExp = selectedStore.get('order_exp')
        // console.log('orderExp => ', orderExp)
        if (!_.isNumber(orderExp) || orderExp === 0) {
          if (skipWarningDueToInitCustomCreateOrderSetting) {
            return
          }
          p.op.showConfirmationOkOnly(
            'คุณไม่ได้ตั้งค่าร้านไว้',
            'หากต้องการตั้งค่าเริ่มต้นของร้าน ให้กลับไปที่หน้าแรก "การขาย" แล้วกด "รูปเฟืองขวาบน" แล้วก็เลือกเมนู "ยกเลิก ออเดอร์อัตโนมัติ"'
          )
          return
        }
        await util.setStatePromise(this, {
          selectedDateRangeSetUpAutoCancelOrder: newDate,
          optionChooseSetupAutoCancelOrderIndex: newIndex,
        })
      } else if (newIndex === 0) {
        await util.setStatePromise(this, {
          optionChooseSetupAutoCancelOrderIndex: newIndex,
        })
      }
    } else if (mode === VIEW_EDIT) {
      if (!acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_EDIT)) {
        p.op.showConfirmationOkOnly('', p.op.t('warning.no_right'))
        return
      }
      const order = this._getFocusedOrder()
      const orderLock = this._isLockedOrder(order)
      const orderType = order.get('type') ? parseInt(order.get('type')) : 0
      if (orderType === 3) {
        this._warningCannotEditOnPurchaseOrder()
        return
      }
      if (orderLock) {
        p.op.showConfirmationOkOnly(
          'ออเดอร์ถูกล็อก',
          'หากต้องการแก้ไขวันกำหนดส่ง กรุณาเลือก "ปุ่ม 3 จุด ข้างบนทางขวา" เลือก "ปลดล็อกออเดอร์นี้"'
        )
        return
      }
      let orderExp = selectedStore.get('order_exp')
      if (!_.isNumber(orderExp) || orderExp === 0) {
        // ถ้าไม่มีตั้งค่าร้าน ให้เพิ่มค่าเริ่มต้นที่ 2 วัน ( 2880 นาที )
        orderExp = 2880
      }
      const today = new Date()
      const newDate = new Date()
      newDate.setMinutes(today.getMinutes() + orderExp)
      if (newIndex === 1 && optionChooseSetupAutoCancelOrderIndex === 0) {
        this._openDatePickerAutoCancleOrder()
        await util.setStatePromise(this, {
          selectedDateRangeSetUpAutoCancelOrder: newDate,
          // optionChooseSetupAutoCancelOrderIndex: newIndex,
          // isShowWraningDateTimeAutoCancelOrder: true,
        })
      } else if (newIndex === 0 && optionChooseSetupAutoCancelOrderIndex === 1) {
        // const dateExp = util.getTimeFormatYYYYMMDDhhmm(newDate)
        p.op.showConfirmation(
          '',
          `ปิดใช้งาน ${p.op.t('StoreSetting.MenuStoreCancelOrderAutomation')}`,
          () => {
            this._onChangeDateTimeAutoCancelOrder(null)
            util.setStatePromise(this, {
              optionChooseSetupAutoCancelOrderIndex: newIndex,
              selectedDateRangeSetUpAutoCancelOrder: newDate,
            })
          },
          () => null,
          'ตกลง',
          'ยกเลิก'
        )
        //
      }
      // util.setStatePromise(this, {
      //   optionChooseSetupAutoCancelOrderIndex: newIndex,
      // })
    } else if (mode === EDIT) {
      this._warningCantEditOnEditMode()
    }
  }

  _onSegmentChangeRound = (newIndex: number): void => {
    if (this.state.optionChooseRound === newIndex) {
      return // ignore duplicated
    }
    this.setState({ optionChooseRound: newIndex })
  }

  _onChangeAddStoreProductSelectorOptions = async (newOpts: IAddStoreProductSelectorOptions) => {
    if (p.op.isWeb()) {
      xUtil.navPopToCurrent(this.props)
    }

    const { selectedOrder } = this.props
    const {
      optionChooseProductsByGroupIndex,
      optionChooseProductsFollowingIndex,
      optionChooseProductsFromIndex,
      optionSelectedSellerStoreIndex,
    } = this.state
    const chooseProductMode = this._getCurrentOrderChooseProductMode()
    let txtTargetMode = null

    const isOptionChooseProductsFollowingIndexChanged =
      _.isNumber(newOpts.optionChooseProductsFollowingIndex) &&
      optionChooseProductsFollowingIndex !== newOpts.optionChooseProductsFollowingIndex
    const isChangeUgToPgAfterAddProduct =
      isOptionChooseProductsFollowingIndexChanged && chooseProductMode === 'UG' && newOpts.optionChooseProductsFollowingIndex !== 0
    const isChangePgToUgAfterAddProduct =
      isOptionChooseProductsFollowingIndexChanged && chooseProductMode === 'PG' && newOpts.optionChooseProductsFollowingIndex !== 1

    if (isChangeUgToPgAfterAddProduct) {
      txtTargetMode = 'รายการราคา'
    } else if (isChangePgToUgAfterAddProduct) {
      txtTargetMode = 'กลุ่มสินค้า'
    }

    const isOptionChooseProductsByGroupIndexChanged =
      _.isNumber(newOpts.optionChooseProductsByGroupIndex) && optionChooseProductsByGroupIndex !== newOpts.optionChooseProductsByGroupIndex
    const orderId = selectedOrder.get('id')
    const ug_id = selectedOrder.get('user_group_id')
    if (isOptionChooseProductsByGroupIndexChanged && orderId && ug_id) {
      p.op.alert('ไม่สามารถเปลี่ยนกลุ่มผู้ใช้ได้', 'เนื่องจากมีการกำหนดกลุ่มสมาชิกขณะเปิดออเดอร์ไปแล้ว')
      return
    }

    const isChangeUgAfterAddProduct =
      chooseProductMode === 'UG' && isOptionChooseProductsByGroupIndexChanged && optionChooseProductsFollowingIndex === 0
    if (isChangeUgToPgAfterAddProduct || isChangePgToUgAfterAddProduct || isChangeUgAfterAddProduct) {
      const isUserConfirmed = await new Promise<boolean>((passConfirm) => {
        p.op.showConfirmation(
          'เปลี่ยนแปลงลักษณะราคา',
          `กรุณายืนยันว่าคุณต้องการจะเลือกสินค้าใหม่ โดยคิดราคาออเดอร์ตาม${txtTargetMode} และสินค้าที่ถูกเลือกมาแล้วจะลบออกโดยอัตโนมัติ`,
          () => passConfirm(true),
          () => passConfirm(false)
        )
      })

      if (!isUserConfirmed) {
        return
      }

      await this._initializeDefaultValueByMode()
    }

    // console.log('_onChangeAddStoreProductSelectorOptions newOptions => ', newOpts)
    this.setState(newOpts)
  }

  _onSegmentChangeChooseProductFollowing = async (newIndex: number): Promise<void> => {
    const { optionChooseProductsFollowingIndex, optionChooseProductsByGroupIndex } = this.state
    const { selectedStore, editingOrder } = this.props

    if (optionChooseProductsFollowingIndex === newIndex) {
      return // ignore duplicated
    }

    const chooseProductMode = this._getCurrentOrderChooseProductMode()
    let txtTargetMode = null
    if (chooseProductMode === 'UG' && newIndex !== 0) {
      txtTargetMode = 'รายการราคา'
    } else if (chooseProductMode === 'PG' && newIndex !== 1) {
      txtTargetMode = 'กลุ่มสินค้า'
    }

    if (txtTargetMode) {
      const isUserConfirmed = await new Promise<boolean>((passConfirm) => {
        p.op.showConfirmation(
          'เปลี่ยนแปลงลักษณะราคา',
          `กรุณายืนยันว่าคุณต้องการจะเลือกสินค้าใหม่ โดยคิดราคาออเดอร์ตาม${txtTargetMode} และสินค้าที่ถูกเลือกมาแล้วจะลบออกโดยอัตโนมัติ`,
          () => passConfirm(true),
          () => passConfirm(false)
        )
      })
      if (!isUserConfirmed) {
        return
      }
      await this._initializeDefaultValueByMode()
    }

    const user_groups = selectedStore.get('user_groups') || List([])
    const product_groups = selectedStore.get('product_groups') || List([])
    const hasOnlyOneUg = user_groups.size === 1
    // const haveOnlyOnePg = product_groups.size === 1
    let groupItems = List([])
    let targetIndex = newIndex
    if (targetIndex === 0) {
      groupItems = user_groups
    } else if (targetIndex === 1) {
      groupItems = product_groups
    } else {
      return
    }
    if (hasOnlyOneUg && newIndex === 0) {
      // ถ้ามี ug แค่ 1 ตัว ให้เปลี่ยนไปเป็น pg แทน
      groupItems = product_groups
      targetIndex = 1
    }
    const newState: { [key: string]: any } = { optionChooseProductsFollowingIndex: newIndex }
    const isValuableGroupIndex = groupItems.has(optionChooseProductsByGroupIndex)
    if (!isValuableGroupIndex) {
      newState.optionChooseProductsByGroupIndex = 0
    }
    await setStatePromise(this, newState)

    if (p.op.isWeb()) {
      xUtil.navPopToCurrent(this.props)
    }
  }

  _onRadioChangeChooseProductsByGroup = async (newIndex: number): Promise<void> => {
    const { optionChooseProductsFollowingIndex, optionChooseProductsByGroupIndex } = this.state

    if (optionChooseProductsByGroupIndex === newIndex) {
      return // ignore duplicated
    }

    const { selectedOrder, userGroups, productGroups } = this.props
    const user_groups = userGroups || List([])
    const product_groups = productGroups || List([])
    const hasOnlyOneUG = user_groups.size === 1
    const hasOnlyOnePG = product_groups.size === 1
    const orderId = selectedOrder.get('id')
    const ug_id = selectedOrder.get('user_group_id')
    if (orderId && ug_id) {
      p.op.alert('ไม่สามารถเปลี่ยนกลุ่มผู้ใช้ได้', 'เนื่องจากมีการกำหนดกลุ่มสมาชิกขณะเปิดออเดอร์ไปแล้ว')
      return
    }

    const chooseProductMode = this._getCurrentOrderChooseProductMode()
    if (chooseProductMode === 'UG') {
      // เริ่มมีการเลือกสินค้าใน mode UG ไปบ้างแล้ว
      if (optionChooseProductsFollowingIndex === 0 && optionChooseProductsByGroupIndex !== newIndex) {
        // ถ้ามีการเปลี่ยน UG จะเคลียร์สินค้าออก
        const isUserConfirmed = await new Promise<boolean>((passConfirm) => {
          p.op.showConfirmation(
            'เปลี่ยนแปลงลักษณะราคา',
            `กรุณายืนยันว่าคุณต้องการจะเลือกสินค้าใหม่ โดยคิดราคาออเดอร์ตามกลุ่มสมาชิกอื่น และสินค้าที่ถูกเลือกมาแล้วจะลบออกโดยอัตโนมัติ`,
            () => passConfirm(true),
            () => passConfirm(false)
          )
        })
        if (!isUserConfirmed) {
          return
        }
        await this._initializeDefaultValueByMode()
      }
    }

    const newState: Partial<IBaseOrderViewState> = { optionChooseProductsByGroupIndex: newIndex }
    if (hasOnlyOneUG && hasOnlyOnePG) {
      newState.optionChooseProductsFollowingIndex = 1 // always by pg
    }

    await setStatePromise(this, newState)

    if (p.op.isWeb()) {
      xUtil.navPopToCurrent(this.props)
    }
  }

  _hasSellerStores = () => {
    const { sellerStores = List([]) } = this.props
    return sellerStores.size > 0
  }

  _getCurrentOrderChooseProductMode = (): 'UG' | 'PG' | 'None' => {
    const { editingOrder } = this.props
    const chooseProductParams = editingOrder.get('chooseProductParams')
    if (chooseProductParams) {
      const ug_id = chooseProductParams.get('ug_id')
      const pg_ids = chooseProductParams.get('pg_ids')
      if (ug_id && ug_id > 0) {
        return 'UG'
      }
      if (List.isList(pg_ids) && pg_ids.size > 0) {
        return 'PG'
      }
    }
    return 'None'
  }

  _getProductFilterParams = (): { pg_ids?: number[]; ug_id?: number; isError?: boolean } => {
    const { selectedStore } = this.props
    const {
      optionChooseProductsFromIndex = 0, // seller or my
      optionChooseProductsFollowingIndex = 0, // ug or pg
      optionChooseProductsByGroupIndex = 0, // which group ?
    } = this.state
    const hasSellerStores = this._hasSellerStores()
    let isMineProducts = optionChooseProductsFromIndex === 1
    if (!isMineProducts && !hasSellerStores) {
      isMineProducts = true
    }

    const user_groups = selectedStore.get('user_groups') || List([])
    const product_groups = selectedStore.get('product_groups') || List([])
    const hasOnlyOneUG = user_groups.size === 1
    const hasOnlyOnePG = product_groups.size === 1
    const additionParams: { pg_ids?: number[]; ug_id?: number; isError?: boolean } = {}
    if (isMineProducts) {
      if (hasOnlyOneUG && hasOnlyOnePG) {
        // log('_getProductFilterParams hasOnlyOneUG && hasOnlyOnePG')
        const selectedPG = product_groups.get(0)
        if (!Map.isMap(selectedPG) || !selectedPG.get('id')) {
          p.op.alert('ไม่พบรายการราคาที่คุณเลือก', 'กรุณาลองใหม่อีกครั้ง')
          return { isError: true } // กรณีไม่พบ PG should be impossible
        }
        additionParams.pg_ids = [selectedPG.get('id')]
      } else if (optionChooseProductsFollowingIndex === 0) {
        // log('_getProductFilterParams optionChooseProductsFollowingIndex === 0')
        const selectedUG = user_groups.get(optionChooseProductsByGroupIndex)
        if (!Map.isMap(selectedUG) || !selectedUG.get('id')) {
          p.op.alert('ไม่พบกลุ่มสมาชิกที่คุณเลือก', 'กรุณาลองใหม่อีกครั้ง')
          return { isError: true } // กรณีไม่พบ UG should be impossible
        }
        additionParams.ug_id = selectedUG.get('id')
        // log('_getProductFilterParams additionParams => ', additionParams)
      } else if (optionChooseProductsFollowingIndex === 1) {
        // log('_getProductFilterParams optionChooseProductsFollowingIndex === 1')
        const selectedPG = product_groups.get(optionChooseProductsByGroupIndex)
        if (!Map.isMap(selectedPG) || !selectedPG.get('id')) {
          p.op.alert('ไม่พบรายการราคาที่คุณเลือก', 'กรุณาลองใหม่อีกครั้ง')
          return { isError: true } // กรณีไม่พบ PG should be impossible
        }
        additionParams.pg_ids = [selectedPG.get('id')]
      } else {
        // log('_getProductFilterParams else')
        return { isError: true } // should be impossible case but safety first
      }
    }
    return additionParams
  }

  createSaleOrderLinkWithDetail = async (order) => {
    const orderParentId = order.get('parent_id')
    const orderType = order.get('type')
    p.op.aLogEventWithUserId(xCONS.EVENT_NAME.ORDER_COPY_DETAIL, { orderType })
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    let isLoadedOrder = false
    isLoadedOrder = !!this.props.editingOrder.get('updated_at')
    if (!_.includes([1, 2], orderType) && !isLoadedOrder) {
      // p.op.alert('สามารถคัดลอกรายละเอียดออเดอร์ได้ที่ออเดอร์ขายเท่านั้น')
      const isUserConfirmed = await p.op.isUserConfirm(
        'คัดลอกรายละเอียดออเดอร์',
        `แอปจะโหลดข้อมูลออเดอร์ขาย #${orderParentId} เพื่อทำการคัดลอกรายละเอียดออเดอร์\nคุณต้องการดำเนินการ ใช่หรือไม่`,
        'ใช่',
        'ไม่ใช่'
      )

      if (!isUserConfirmed) {
        this.inProcess = false
        return
      }

      // await this._handleOnPressParentOrderId(orderParentId)
      await this._handleOnPressParentOrderId()
      await delay(500)

      for (let i = 1; i <= 6; i++) {
        isLoadedOrder = !!this.props.editingOrder.get('updated_at')
        if (!isLoadedOrder) {
          // not necessary because onRefresh is real synchronous now
          await delay(500)
        } else {
          break
        }
      }
      if (!isLoadedOrder) {
        // ถ้าเกิดรอโหลดซัก 3 แล้วแล้วยังไม่สำเร็จอาจจะต้อง ignore ตัวเองไปก่อน
        this.inProcess = false
        return
      }
    }

    const { selectedStore, selectedCustomOrderView } = this.props
    let hash = await this._getHashOrder(order)

    if (!_.isString(hash)) {
      const res: any = await new Promise((resolve) => {
        this.props.getCopyLinkOrder({
          body: {
            store_id: selectedStore.get('id'),
            order_id: order.get('id'),
          },
          successCallback: resolve,
          failedCallback: () => resolve(null),
        })
      })

      // console.log('res => ', res)
      // @ts-ignore
      if (!_.isObject(res) || !_.isString(res.shipping_link)) {
        p.op.alert('ไม่สามารถสร้างลิงก์ออเดอร์ได้', 'กรุณาลองใหม่อีกครั้ง')
        this.inProcess = false
        return
      }
      // @ts-ignore
      hash = res.shipping_link
    }

    // console.log('order => ', order.toJS())
    const parentOrder = this.props.editingOrder
    const orderId = parentOrder.get('id')

    // const orderAmountTotal = parentOrder.get('amountTotal') // Calculated in redux
    const orderAmountTotal = parseSmartFloat(parentOrder.get('transaction_cost')) // ใช้ transaction_cost จากหลังบ้านเพื่อความถูกต้อง
    const orderAmountProducts = parentOrder.get('amountProducts') // Calculated in redux
    const orderCreateDateTime = xUtil.formatDateForDisplay(parentOrder.get('created_at'))
    const orderShippingCost = parseSmartFloat(parentOrder.get('shipping_cost'))
    const isCodOrder = parentOrder.get('is_cod') || false
    const orderCodAmount = parseSmartFloat(parentOrder.get('cod_amount'))
    const orderCodFee = parseSmartFloat(parentOrder.get('cod_fee'))
    const orderDiscount = parseSmartFloat(parentOrder.get('discount'))
    const orderEtcCost = parseSmartFloat(parentOrder.get('etc_cost'))

    const orderUgPgDiscounts = parentOrder.get('ugpg_discounts') || List([])
    const orderMemberDiscount = orderUgPgDiscounts.reduce(
      (prevAmount, discountMap) => prevAmount + parseFloat(discountMap.get('ta') || 0), // ta is total amount (of discount)
      0
    )

    const shippingTypeId = parentOrder.get('shipping_type_id') || 0
    const shippingTypeIndex = shippingTypeId > 0 ? this.ALL_SHIPPING_TYPE_ITEMS.findIndex((sti) => sti.value === shippingTypeId) : -1
    const txtShippingTypeLabel = shippingTypeIndex > -1 ? this.ALL_SHIPPING_TYPE_ITEMS[shippingTypeIndex].label : 'ยังไม่ระบุ'

    const receiver = parentOrder.get('receiver') || null
    const txtOrderFullAddress = this.parseAddressToSingleLineText(receiver) || 'ยังไม่ระบุ'

    const myProducts = parentOrder.get('products') || List([])
    let sellerProducts = List([])
    const subOrders = parentOrder.get('suborders') || List([])

    for (let i = 0; i < subOrders.size; i++) {
      const subOrder = subOrders.get(i)
      const subProducts = subOrder.get('products')
      sellerProducts = sellerProducts.concat(subProducts)
    }
    const allProducts = myProducts.concat(sellerProducts)

    // ออเดอร์ #748594
    // รวมยอดชำระ 390.04 บาท
    // --------------
    // 1 x กระติกน้ำ 100 บาท
    // 2 x แก้ว 100.52 บาท
    // --------------
    // รวมค่าสินค้า 301.04 บาท
    // ค่าส่ง 50 บาท
    // ค่าธรรมเนียมเก็บเงินปลายทาง 40 บาท
    // --------------
    // รูปแบบจัดส่ง: ยังไม่ระบุ
    // ชื่อที่อยู่ผู้รับ: ยังไม่ระบุ
    // ดูรายละเอียดและสถานะการจัดส่งได้ที่ `https://xselly.com/a/?h=KDUfK

    // let txtClipboard = `ออเดอร์ #${orderName}`
    // if (isCodOrder) {
    //   txtClipboard += `\nยอดเก็บเงินปลายทาง ${xFmt.formatCurrency(orderCodAmount)}`
    // } else {
    //   txtClipboard += `\nรวมยอดชำระ ${xFmt.formatCurrency(orderAmountTotal)}`
    // }
    // txtClipboard += `\n--------------`

    // // txtClipboard += `\nTODO: {รายการสินค้า}`
    // for (let i = 0; i < allProducts.size; i++) {
    //   const product = allProducts.get(i)
    //   const pName = product.get('name')
    //   const vName = product.get('variant') || ''
    //   const price = parseFloat(product.get('price') || 0)
    //   const qty = parseInt(product.get('qty') || 0)
    //   txtClipboard += `\nx${qty} ${pName} ${vName} ${xFmt.formatCurrency(price)}`
    // }

    // txtClipboard += `\n--------------`
    // txtClipboard += `\nรวมค่าสินค้า ${xFmt.formatCurrency(orderAmountProducts)}`
    // if (orderShippingCost > 0) {
    //   txtClipboard += `\nค่าจัดส่ง ${xFmt.formatCurrency(orderShippingCost)}`
    // }
    // if (orderEtcCost > 0) {
    //   txtClipboard += `\nค่าอื่นๆ ${xFmt.formatCurrency(orderEtcCost)}`
    // }
    // if (orderDiscount > 0) {
    //   txtClipboard += `\nส่วนลด ${xFmt.formatCurrency(orderDiscount)}`
    // }
    // if (orderMemberDiscount > 0) {
    //   txtClipboard += `\nส่วนลดสมาชิก ${xFmt.formatCurrency(orderMemberDiscount)}`
    // }
    // if (isCodOrder && orderCodFee > 0) {
    //   txtClipboard += `\nค่าธรรมเนียมเก็บเงินปลายทาง ${xFmt.formatCurrency(orderCodFee)}`
    // }
    // txtClipboard += `\n--------------`
    // txtClipboard += `\nรูปแบบจัดส่ง: ${txtShippingTypeLabel}`
    // txtClipboard += `\nชื่อที่อยู่ผู้รับ: ${txtOrderFullAddress}`
    // txtClipboard += `\n\nดูรายละเอียดและสถานะการจัดส่งได้ที่: ${hash}`

    let txtProductItems = ''
    let txtProductWithPriceItems = ''
    let productTotalQty = 0
    for (let i = 0; i < allProducts.size; i++) {
      const product = allProducts.get(i)
      const pName = product.get('name')
      const vName = product.get('variant') || ''
      const price = parseFloat(product.get('price') || 0)
      const qty = parseInt(product.get('qty') || 0)
      if (qty > 0) {
        txtProductWithPriceItems += `\nx${qty} ${pName} ${vName} ${xFmt.formatCurrency(price)}`
        txtProductItems += `\nx${qty} ${pName} ${vName}`
        productTotalQty += qty
      }
    }

    // เข้าไปเช็คดูว่ามี custom template หรือไม่ ถ้าไม่มีจะใช้ default
    let orderCopyTemplateString = selectedCustomOrderView.getIn(['order_copy_templates', 0, 'v']) || null

    if (typeof orderCopyTemplateString !== 'string') {
      orderCopyTemplateString = CONS.PREF_DEFAULT_ORDER_COPY_TEMPLATE
    }

    const txtClipboard = util.translateOrderCopyTemplate(orderCopyTemplateString, {
      orderId,
      orderAmountTotal,
      orderAmountProducts,
      orderCreateDateTime,
      productTotalQty,

      orderCodAmount,
      orderShippingCost,
      orderEtcCost,
      orderDiscount,
      orderMemberDiscount,
      orderCodFee,

      isCodOrder,

      txtProductItems,
      txtProductWithPriceItems,
      txtShippingTypeLabel,
      txtOrderFullAddress,
      txtOrderLink: hash,
    })
    // console.log('txtClipboard => ', txtClipboard)
    // console.log('hash => ', hash)
    await this._copyOrderLinkWithDetailToClipboard(hash, txtClipboard)
    this.inProcess = false
  }

  createCopyLinkOrder = async (order, key) => {
    if (this.inProcess) {
      return
    }
    const { selectedStore } = this.props
    this.inProcess = true
    // const { copyLinkOrder } = this.state
    // let hash = order.get('hash')
    // if (!_.isNil(copyLinkOrder)) {
    //   hash = copyLinkOrder
    // } else if (!_.isNil(hash)) {
    //   hash = `https://xselly.com/a/?h=${hash}`
    // } else {
    //   hash = null
    // }
    const hash = await this._getHashOrder(order)
    if (!_.isNil(hash)) {
      if (key === CONS.COPY_LINK_ORDER.SHARE_LINK) {
        this._shareLinkOrderToSocial(hash)
      } else if (key === CONS.COPY_LINK_ORDER.COPY_LINK) {
        this._copyLinkOrderToClipboard(hash, true)
      } else {
        this._openLinkOrderToWebView(hash)
      }
    } else {
      this.props.getCopyLinkOrder({
        body: {
          store_id: selectedStore.get('id'),
          order_id: order.get('id'),
        },
        successCallback: (res) => this.successCopyLinkOrder(res, key),
        failedCallback: (err) => this.failedCopyLinkOrder(err),
      })
    }

    setTimeout(() => {
      this.inProcess = false
    }, 1500)
  }

  _getHashOrder = async (order) => {
    const { copyLinkOrder } = this.state
    let hash = order.get('hash')
    const hashOrderUrlPrefix = 'https://xselly.com/a/?h='
    if (!_.isNil(copyLinkOrder)) {
      // Handle a weird bug where iOS copy just hash (no https://..)
      // Hence, as a safety check, prepend 'https://..' whenever it's not found.
      if (!copyLinkOrder.startsWith(hashOrderUrlPrefix)) {
        hash = `${hashOrderUrlPrefix}${copyLinkOrder}`
      } else {
        hash = copyLinkOrder
      }
    } else if (!_.isNil(hash)) {
      hash = `${hashOrderUrlPrefix}${hash}`
    } else {
      hash = null
    }
    return hash
  }

  successCopyLinkOrder = (res, key) => {
    if (key === CONS.COPY_LINK_ORDER.COPY_LINK) {
      this._copyLinkOrderToClipboard(res.shipping_link, true)
    } else if (key === CONS.COPY_LINK_ORDER.SHARE_LINK) {
      this._shareLinkOrderToSocial(res.shipping_link)
    } else {
      this._openLinkOrderToWebView(res.shipping_link)
    }
  }

  failedCopyLinkOrder = (err) => {
    this.inProcess = false
    this.setState({ refreshing: false })
    p.op.showToast('ไม่สามารถคัดลอกลิงก์ได้ กรุณาทำรายการใหม่ภายหลัง', 'warning', 3000)
  }

  successOnOffShowAmountBillOnline = (key, res, showAmount) => {
    const { orderTabIndex } = this.state
    const { onEditingOrderChange } = this.props
    let showAmountSetting = 'ซ่อน'
    if (showAmount) {
      showAmountSetting = 'แสดง'
    }
    // if (_.isNil(res.showAmount)) {
    //   p.op.showToast(`เกิดข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง`, 'warning')
    //   return
    // }
    const showToast = false
    onEditingOrderChange({ key, value: res.show_amount, orderTabIndex })
    this._copyLinkOrderToClipboard(res.shipping_link, showToast)
    p.op.showToast(`${showAmountSetting}ราคาเรียบร้อยแล้ว\nคัดลอกลิงก์ ${res.shipping_link} เรียบร้อยแล้ว`, 'success')
    // this.setState({ refreshState: !this.state.refreshState })
  }

  storageSetCod = (newSetting, myStoreId, shippingTypeItem, chooseData) => {
    if (_.isNil(myStoreId)) {
      return
    }
    const { storageGetCod } = this.state
    // ตั้งไว้ปั้นข้อมูลก้อนใหม่
    const saveNewData = []
    // checkStoreId ถ้าเปน store อื่น ให้เพิ่ม store ใหม่เข้าไปในความจำ
    const checkStoreId = _.isNil(storageGetCod)
      ? null
      : storageGetCod.map((val) => {
          // x ถ้าเจอ store ที่ต้องการแล้ว ไม่ต้องไปวนซ้ำ
          let x = false
          if (x) {
            saveNewData.push(val)
            return true
          }
          // เช็ดว่า store ของเรามีไหม
          if (val.key === myStoreId) {
            x = true
            let i = 0
            const arr = []
            val.data.map((xData) => {
              i += 1
              let y = false

              // if (y) {
              // } else {
              // ใน store ของเราเคยมีหรือเปล่า ดูจาก shippingTypeItem.key ที่เราเลือกมา
              if (xData.shippingKey === shippingTypeItem.key) {
                // y ไว้ดูว่า ถ้าเจอขอมูลเก่าแล้ว แปลว่าได้เพิ่มข้อมูลใหม่ไปแลว ไม่ต้องเพิ่มซ้ำอีก
                y = true
                arr.push(chooseData)
              } else {
                arr.push(xData)
              }
              // }
              // ถ้า loop รอบสุดท้ายแล้ว ใส่ค่าใหม่กลับเข้าไป
              if (i === val.data.length) {
                if (!y) {
                  arr.push(chooseData)
                }
                val.data = arr
                // val.data.push(arr)
                saveNewData.push(val)
              }
            })
            return true
          }
          saveNewData.push(val)
          return false
        })

    // ถ้าไม่เคยมีการจำอะไรมาก่อน
    if (_.isNil(storageGetCod)) {
      p.op.storageSet(CONS.STORAGE_KEYS.COD_CALCULATOR, newSetting)
      util.setStatePromise(this, { storageGetCod: newSetting })
      // ถ้าเป็น store ใหม่
    } else if (!checkStoreId[0]) {
      // console.log(saveNewData)
      // console.log(newSetting)
      saveNewData.push(newSetting[0])
      p.op.storageSet(CONS.STORAGE_KEYS.COD_CALCULATOR, saveNewData)
      util.setStatePromise(this, { storageGetCod: saveNewData })
      // ใส่ข้อมูลใหม่เข้าไป
    } else {
      // if (checkStoreId) {
      p.op.storageSet(CONS.STORAGE_KEYS.COD_CALCULATOR, saveNewData)
      util.setStatePromise(this, { storageGetCod: saveNewData })
      // } else {
      //   console.log('------------------------------ 4.2')
      //   saveNewData
      //   // thisMyAllNewSetting[myStoreId][shippingTypeItem.key] = settingData
      //   console.log(saveNewData)
      //   p.op.storageSet(CONS.STORAGE_KEYS.COD_CALCULATOR, newSetting)
      //   util.setStatePromise(this, { storageGetCod: newSetting })
      //   // p.op.storageSet(CONS.STORAGE_KEYS.COD_CALCULATOR, checkStoreId)
      //   // util.setStatePromise(this, { storageGetCod: checkStoreId })
      // }
    }
  }

  _cancelTackingCode = (i: any) => {
    if (_.isNil(i)) {
      p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'ยังไม่สามารถยกเลิกเลขพัสดุได้ในขณะนี้')
      return
    }
    const { store_id, order_id } = this.state
    this.props.cancelTackingCode({
      body: {
        store_id,
        order_id,
        courier_order_id: i,
        // tracking_code: tackingCode,
      },
      successCallback: (res) => this._successCallBackFromTracking(res),
      failedCallback: (res) => this._failTracking(res),
      // failedCallback: err => p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'ไม่สามารถยกเลิกเลขติดตามพัสดุตัวนี้ได้ กรุณาติดต่อทีมงาน XSelly'),
    })
  }

  // _requestTracking = () => {
  //   const { store_id, order_id } = this.state
  //   this.props.cancelTackingCode({
  //     body: {
  //       store_id,
  //       order_id,
  //     },
  //     successCallback: res => this._cancelTackingSuccess(null, res),
  //     failedCallback: res => this._failTracking(res),
  //     // failedCallback: err => p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'ไม่สามารถยกเลิกเลขติดตามพัสดุตัวนี้ได้ กรุณาติดต่อทีมงาน XSelly'),
  //   })
  // }

  autoRequestTracking = (a: any) => {
    const { store_id, order_id } = this.state
    const auto = _.isNil(a) ? null : a
    if (_.isNil(a)) {
      p.op.showToast('ไม่สามารถทำรายการได้ในขนะนี้ กรุณาติดต่อทีมงาน XSelly', 'warning')
      return
    }
    this.props.autoRequestTracking({
      body: {
        store_id,
        order_id,
        auto_request_xshipping: auto,
      },
      successCallback: (res) => this._successCallBackFromTracking(res),
      failedCallback: (res) => this._failTracking(res),
      // failedCallback: err => p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'ไม่สามารถยกเลิกเลขติดตามพัสดุตัวนี้ได้ กรุณาติดต่อทีมงาน XSelly'),
    })
  }

  _successCallBackFromTracking = (res: any) => {
    const { orderTabIndex } = this.state
    const { onEditingOrderChange } = this.props
    onEditingOrderChange({ key: 'x', value: fromJS(res.x), orderTabIndex })
  }

  // _cancelTackingSuccess = (tackingCode, res) => {
  //   const { orderTabIndex } = this.state
  //   const { onEditingOrderChange } = this.props
  //   const order = this._getFocusedOrder()
  //   const getTackingCodes = order.get('x')
  //   const newTackingCodes = []
  //   if (_.isNil(getTackingCodes)) {
  //     p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'กรุณาออกจากหน้านี้แล้วเข้ามาดูรายการนี้อีกครั้ง')
  //     return
  //   }
  //   getTackingCodes.map(val => {
  //     // console.log(val.toJS())
  //     if (val.get('tc') !== tackingCode) {
  //       newTackingCodes.push(val.toJS())
  //     }
  //   })
  //   onEditingOrderChange({ key: 'x', value: fromJS(newTackingCodes), orderTabIndex })
  //   // this.setState({getTackingCodes: newTackingCodes})
  // }

  _createTackingCode = (orderId: any) => {
    const { store_id, order_id } = this.state
    this.props.createTackingCode({
      body: {
        store_id,
        order_id,
      },
      successCallback: (res) => this._successCallBackFromTracking(res),
      failedCallback: (res) => this._failTracking(res),
      // failedCallback: err => p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'ไม่สามารถยกเลิกเลขติดตามพัสดุตัวนี้ได้ กรุณาติดต่อทีมงาน XSelly'),
    })
  }

  _updateTackingCode = (x: any) => {
    // if (_.isNil(tackingCode)) {
    //   p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'ยังไม่สามารถยกเลิกเลขพัสดุได้ในขณะนี้')
    //   return
    // }
    const { store_id, order_id } = this.state
    if (x.has('i') && !_.isNil(x.get('i'))) {
      this.props.updateTackingCode({
        body: {
          store_id,
          order_id,
          courier_order_id: x.get('i'),
        },
        successCallback: (res) => this._successCallBackFromTracking(res),
        failedCallback: (res) => this._failTracking(res),
        // failedCallback: err => p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'ไม่สามารถยกเลิกเลขติดตามพัสดุตัวนี้ได้ กรุณาติดต่อทีมงาน XSelly'),
      })
    } else {
      this.props.updateTackingCode({
        body: {
          store_id,
          order_id,
        },
        successCallback: (res) => this._successCallBackFromTracking(res),
        failedCallback: (res) => this._failTracking(res),
        // failedCallback: err => p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'ไม่สามารถยกเลิกเลขติดตามพัสดุตัวนี้ได้ กรุณาติดต่อทีมงาน XSelly'),
      })
    }
  }

  _failTracking = (res) => {
    p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'ไม่สามารถทำรายการที่ต้องท่านต้องการได้ในขณะนี้')
  }

  updateFromCallBackCustomCreateOrder = async (res: ICustomCreateOrder) => {
    await util.setStatePromise(this, {
      customCreateOrder: res,
    })
    this._setCustomCreateOrder(true)
  }

  updateFromCallBackCustomOrderView = async (res: ICustomCreateOrder) => {
    // @ts-ignore //FIXME: @Artid type ของ customOrderView มีปัญหา แม้แต่สั่ง this.setState({ customOrderView: res, }) ก็ไม่สามารถอ่านได้
    await util.setStatePromise(this, {
      customOrderView: res,
    })
  }

  onChangeReceiverName = (receiverName: string) => {
    const { customCreateOrder } = this.state
    const newCustomCreateOrder = customCreateOrder
    newCustomCreateOrder.receiver.vs = receiverName
    util.setStatePromise(this, { customCreateOrder: newCustomCreateOrder })
  }

  _onChangePhone = (text: string) => {
    const { customCreateOrder } = this.state
    const newCustomCreateOrderData = customCreateOrder
    newCustomCreateOrderData.receiver.p = text
    this.setState({ customCreateOrder: newCustomCreateOrderData })
  }

  _useXShippingForCreateOrder = () => {
    const { selectedStore } = this.props

    const XShpping = selectedStore.has('s_use_xshipping') ? selectedStore.get('s_use_xshipping') : false
    if (!XShpping) {
      return false
    }
    const shippingTypeId = this._getShippingTypeId()
    if (shippingTypeId < 0) {
      return false
    }
    const XShippings = selectedStore.has('xshippings') ? selectedStore.get('xshippings') : false
    if (!XShippings) {
      return false
    }
    let useXShipping = false
    XShippings.map((sp) => {
      if (sp.get('shipping_type_id') === shippingTypeId && sp.get('set_xshipping_on_create_order') === false) {
        useXShipping = true
      }
    })
    let getStoreXShipping = null
    if (useXShipping) {
      p.op.storageGet(CONS.STORAGE_KEYS.USE_XSHIPPING).then((val: any) => {
        getStoreXShipping = val
      })
    }

    if (_.isNil(getStoreXShipping)) {
      useXShipping = useXShipping || false
    }
    return useXShipping
  }

  _getShippingTypeId = () => {
    // const { pp_shippingTypeIndex } = this.props // Deprecated
    const { mode } = this.state
    const order = this._getFocusedOrder()
    const shippingID = order.get('shipping_type_id')
    // if (mode === CREATE) {
    //   const shippingTypeId = (pp_shippingTypeIndex > -1 && this.shippingTypeItems[pp_shippingTypeIndex].value) || -1
    // return shippingTypeId
    // } else {
    //   // const shippingTypeId = (shippingID > -1 && this.shippingTypeItems[shippingID].value) || -1
    //   const shippingTypeId = this.shippingTypeItems.find(sp => shippingID === sp.value)
    //   return _.isNil(shippingTypeId) ? -1 : shippingTypeId.value
    // }
    const shippingTypeId = this.ALL_SHIPPING_TYPE_ITEMS.find((sp) => shippingID === sp.value)
    return _.isNil(shippingTypeId) ? -1 : shippingTypeId.value
  }

  _getShippingTypeName = () => {
    // return ''
    // const {pp_shippingTypeIndex} = this.props // Deprecated
    const { mode } = this.state
    const order = this._getFocusedOrder()
    const shippingID = order.get('shipping_type_id')

    // if (mode === CREATE) {
    //   const shippingTypeName = (pp_shippingTypeIndex > -1 && this.shippingTypeItems[pp_shippingTypeIndex].label) || ''
    //   return shippingTypeName
    // } else {
    //   // const shippingTypeName = (shippingID > -1 && this.shippingTypeItems[shippingID].label) || ''
    //   const shippingTypeName = this.shippingTypeItems.find(sp => shippingID === sp.value)
    //   return _.isNil(shippingTypeName) ? '' : shippingTypeName.label
    // }

    const shippingTypeName = this.ALL_SHIPPING_TYPE_ITEMS.find((sp) => shippingID === sp.value)
    return _.isNil(shippingTypeName) ? '' : shippingTypeName.label
  }

  _handleCloseXShippingOverlay = () => {
    util.setStatePromise(this, {
      onOffXShippingOverlay: false,
    })
  }

  _handleOpenXShippingOverlay = async () => {
    await setStatePromise(this, { onOffXShippingOverlay: true })
  }

  // // Deprecated
  // _isProductValid = (product) => {
  //   return product && isImmutable(product) && product.has('qty')
  // }

  // // Deprecated
  // _clearInCartProduct() {
  //   this.props.clearInCartProducts()
  // }

  _senderParseToTextDetail = (sender, use_store_addr, is_addr_forced, order_type) => {
    // Immutable
    // log('_senderParseToTextDetail sender.toJS()', sender.toJS())
    // log('_senderParseToTextDetail use_store_addr', use_store_addr)
    // log('_senderParseToTextDetail is_addr_forced', is_addr_forced)
    // log('_senderParseToTextDetail order_type', order_type)
    let txtFullAddress = ''
    let useFullAddress = false

    const address1 = isImmutable(sender) ? sender.get('address1') : null
    // const address2 = isImmutable(sender) ? sender.get('address2') : null
    // const sub_district = isImmutable(sender) ? sender.get('sub_district') : null
    // const district = isImmutable(sender) ? sender.get('district') : null
    // const province = isImmutable(sender) ? sender.get('province') : null
    // const postal_code = isImmutable(sender) ? sender.get('postal_code') : null
    // const telephone = isImmutable(sender) ? sender.get('telephone') : null

    if (order_type === 1 || (order_type === 2 && use_store_addr)) {
      useFullAddress = true
    } else if (order_type === 2 && !use_store_addr) {
      if (address1) {
        useFullAddress = true
      } else {
        txtFullAddress = '<< ใช้ที่อยู่ตัวแทน >>' // should be impossible case
      }
    } else if (order_type === 3) {
      if (!use_store_addr && !is_addr_forced) {
        useFullAddress = true
      } else if (is_addr_forced) {
        txtFullAddress = '<< ใช้ที่อยู่ของผู้ขายส่ง เนื่องจากผู้ขายส่งบังคับ >>'
      } else {
        txtFullAddress = '<< ใช้ที่อยู่ของผู้ขายส่ง >>'
      }
    }
    // log('_senderParseToTextDetail useFullAddress', useFullAddress)

    // const name = sender.get('name') || null
    if (isImmutable(sender) && useFullAddress === true) {
      if (address1) {
        // txtFullAddress = address1
        // txtFullAddress = address2 ? `${txtFullAddress} ${address2}` : txtFullAddress
        // txtFullAddress = sub_district ? `${txtFullAddress} ${sub_district}` : txtFullAddress
        // txtFullAddress = district ? `${txtFullAddress} ${district}` : txtFullAddress
        // txtFullAddress = province ? `${txtFullAddress} ${province}` : txtFullAddress
        // txtFullAddress = postal_code ? `${txtFullAddress}\n${postal_code}` : txtFullAddress
        // txtFullAddress = telephone ? `${txtFullAddress}\nโทร. ${telephone}` : txtFullAddress
        txtFullAddress = xUtil.convertAddressToInlineAddress(sender as IMap<IAddress>)
      } else {
        txtFullAddress = 'ไม่ได้ระบุที่อยู่จัดส่ง'
      }
    }

    return txtFullAddress
  }

  _receiverParseToTextDetail = (receiver, sender_name) => {
    // Immutable  //sender_name ใช้สำหรับตรวจสอบว่าหากที่อยู่ผู้ส่งระบุเอง เวลาคัดลอกต้องใช้ชื่อนี้ ไม่ใช่ชื่อร้าน
    // log('_receiverParseToTextDetail receiver.toJS()', receiver.toJS())
    const ADDR_UNDEFINED = 'ยังไม่ได้ระบุที่อยู่'
    if (receiver) {
      const address3 = receiver.get('address3') || null
      // const postal_code = receiver.get('postal_code') || null
      const telephone = receiver.get('telephone') || null
      // const email = receiver.get('email') || null
      // const legal_entity_type = receiver.get('legal_entity_type') || 0
      // const legal_entity_id = receiver.get('legal_entity_id') || null
      // let txtFullAddress = ''
      let name = receiver.get('name') || null
      if (!name && !address3 && !telephone) {
        return ADDR_UNDEFINED
      }
      if (!_.isEmpty(sender_name)) {
        name = sender_name
      }

      // if (address3) {
      //   txtFullAddress = _.isNil(name) ? `${address3}` : `${name}\n${address3}`
      //   txtFullAddress = postal_code ? `${txtFullAddress}\n${postal_code}` : txtFullAddress
      //   txtFullAddress = telephone ? `${txtFullAddress}\nโทร. ${telephone}` : txtFullAddress
      //   txtFullAddress = email ? `${txtFullAddress}\nอีเมล ${email}` : txtFullAddress
      //   txtFullAddress =
      //     legal_entity_type > 0 && legal_entity_id
      //       ? `${txtFullAddress}\n${util.getLegalEntityLabel(legal_entity_type)} ${legal_entity_id}`
      //       : txtFullAddress

      //   return txtFullAddress
      // }

      // const address1 = receiver.get('address1') || null
      // if (address1) {
      //   const address2 = receiver.get('address2') || null
      //   const sub_district = receiver.get('sub_district') || null
      //   const district = receiver.get('district') || null
      //   const province = receiver.get('province') || null
      //   txtFullAddress = _.isNil(name) ? `${address1}` : `${name}\n${address1}`
      //   txtFullAddress = address2 ? `${txtFullAddress} ${address2}` : txtFullAddress
      //   txtFullAddress = sub_district ? `${txtFullAddress} ${sub_district}` : txtFullAddress
      //   txtFullAddress = district ? `${txtFullAddress} ${district}` : txtFullAddress
      //   txtFullAddress = province ? `${txtFullAddress} ${province}` : txtFullAddress
      //   txtFullAddress = postal_code ? `${txtFullAddress}\n${postal_code}` : txtFullAddress
      //   txtFullAddress = telephone ? `${txtFullAddress}\nโทร. ${telephone}` : txtFullAddress
      //   txtFullAddress = email ? `${txtFullAddress}\nอีเมล ${email}` : txtFullAddress
      //   txtFullAddress =
      //     legal_entity_type > 0 && legal_entity_id
      //       ? `${txtFullAddress}\n${util.getLegalEntityLabel(legal_entity_type)} ${legal_entity_id}`
      //       : txtFullAddress

      //   // log('address1 returning: ' + txtFullAddress)
      //   return txtFullAddress
      // }
      const txtFullAddress = xUtil.convertAddressToInlineAddress(receiver, { replacedName: name })

      if (_.isString(txtFullAddress) && txtFullAddress.length > 0) {
        return txtFullAddress
      }

      if (!_.isNil(receiver.get('name'))) {
        let txtNameAndPhon = receiver.get('name')
        const phon = _.isNil(receiver.get('telephone')) ? null : receiver.get('telephone')
        if (!_.isNil(phon)) {
          txtNameAndPhon += `\nโทร. ${phon}`
        }
        return txtNameAndPhon
      }
      return ADDR_UNDEFINED
    }
    return ADDR_UNDEFINED
  }

  parseAddressToSingleLineText = (addr: IMap<IAddress> | IMap<IQuickAddress>): string | null => {
    if (!Map.isMap(addr)) {
      // Cannot parse any thing (ยังไม่ระบุ)
      return null
    }
    const adr = addr as IMap<IAddress>

    if (!adr) {
      // ดักเผื่อ debug ว่าจริงๆ แล้วไม่มี object address มาเลย
      return 'ไม่มีข้อมูล'
    }
    // const isNormalAddress = _.isNumber(adr.get('id')) && _.isString(adr.get('address1'))
    // const isNormalAddress = _.isNumber(adr.get('id')) && (_.isString(adr.get('name')) || _.isString(adr.get('address1')))
    // จาก task https://app.clickup.com/t/2nfy9zb บอกว่ามีโอกาสที่จะมีเพียงแค่เบอร์โทรอย่างเดียวได้
    const isNormalAddress = !_.isNil(adr.get('id'))
    const qadr = addr as IMap<IQuickAddress>
    // const isQuickAddress = _.isString(qadr.get('address3'))
    const isQuickAddress = !_.isNil(qadr.get('address3'))

    if (!isNormalAddress && !isQuickAddress) {
      // Cannot parse any thing (ยังไม่ระบุ)
      return null
    }

    // let txtFullAddress: string
    // const name = adr.get('name')
    // const zipCode = adr.get('postal_code')
    // const telephone = adr.get('telephone')
    // // const legal_entity_type = adr.get('legal_entity_type') || 0
    // // const legal_entity_id = adr.get('legal_entity_id') || null

    // if (isNormalAddress) {
    //   txtFullAddress = ''
    //   const nAddr = addr as IMap<IAddress>
    //   const address1 = nAddr.get('address1')
    //   const address2 = nAddr.get('address2')
    //   const subDistrict = nAddr.get('sub_district')
    //   const district = nAddr.get('district')
    //   const province = nAddr.get('province')

    //   // txtFullAddress = address2 ? txtFullAddress + ` ${address2}` : txtFullAddress
    //   // txtFullAddress = subDistrict ? txtFullAddress + ` ${subDistrict}` : txtFullAddress
    //   // txtFullAddress = district ? txtFullAddress + ` ${district}` : txtFullAddress
    //   // txtFullAddress = province ? txtFullAddress + ` ${province}` : txtFullAddress
    //   // txtFullAddress = zipCode ? txtFullAddress + ` ${zipCode}` : txtFullAddress
    //   // txtFullAddress = telephone ? txtFullAddress + ` โทร. ${telephone}` : txtFullAddress

    //   if (!_.isNil(name)) {
    //     txtFullAddress += name
    //   }

    //   if (!_.isNil(address1)) {
    //     txtFullAddress += ` ${address1}`
    //   }

    //   if (!_.isNil(address2)) {
    //     txtFullAddress += ` ${address2}`
    //   }

    //   if (!_.isNil(subDistrict)) {
    //     txtFullAddress += ` ${subDistrict}`
    //   }

    //   if (!_.isNil(district)) {
    //     txtFullAddress += ` ${district}`
    //   }

    //   if (!_.isNil(province)) {
    //     txtFullAddress += ` ${province}`
    //   }
    // }

    // if (isQuickAddress) {
    //   txtFullAddress = ''
    //   const qAddr = addr as IMap<IQuickAddress>
    //   const address3 = qAddr.get('address3')
    //   // txtFullAddress = _.isNil(name) ? `${address3}` : `${name} ${address3}`
    //   // txtFullAddress = zipCode ? txtFullAddress + ` ${zipCode}` : txtFullAddress
    //   // txtFullAddress = telephone ? txtFullAddress + ` โทร. ${telephone}` : txtFullAddress

    //   if (!_.isNil(name)) {
    //     txtFullAddress += name
    //   }

    //   if (!_.isNil(address3)) {
    //     txtFullAddress += ` ${address3}`
    //   }
    // }

    // // nunmber or string ?
    // if (!_.isNil(zipCode)) {
    //   txtFullAddress += ` ${zipCode}`
    // }

    // // nunmber or string ?
    // if (!_.isNil(telephone)) {
    //   txtFullAddress += ` โทร. ${telephone}`
    // }

    // // if (legal_entity_type > 0 && _.isString(legal_entity_id)) {
    // //   txtFullAddress += ` (${CONS.ADDRESS_LEGAL_ENTITY_LABELS[legal_entity_type]} ${legal_entity_id})`
    // // }

    // // กรณีมี id แต่ไม่ได้ระบุอะไรมาสักอย่างเลย
    // if (txtFullAddress === '') {
    //   return null
    // }
    const txtFullAddress = xUtil.convertAddressToInlineAddress(addr)

    return txtFullAddress
  }

  getDisplayMyTaskByOrderType = (type) => {
    switch (type) {
      case 1:
        return p.op.t('Order.dispTaskSellToCustomer')
      case 2:
        return p.op.t('Order.dispTaskSellToReseller')
      case 3:
        return p.op.t('Order.dispTaskBuyFromSeller')
      default:
        return ''
    }
  }

  calculateDisplayState = ({ type, state, payment_state, shipping_state }) => {
    if (type && state && payment_state && shipping_state) {
      if (state < 109) {
        return `_${type}${state}`
      }
      if (state >= 121 && state <= 189) {
        return `_${type}${state}`
      }
      if (payment_state < 119) {
        return `_${type}${payment_state}`
      }
      if (shipping_state <= 129) {
        return `_${type}${shipping_state}`
      }
      return '_0000'
    }
    return '_0000'
  }

  calculatePaymentDisplayState = ({ type, payment_state }) => {
    if (type && payment_state && payment_state < 119) {
      return `_${type}${payment_state}`
    }
    return '_0000'
  }

  _onChangeTextOpenForStaffMember = (text: string) => {
    if (text.length === 13) {
      // const ss = text.substring(0, 2)
      // if (ss === 'ss' || ss === 'SS') {
      this._getResellerAddresses(text)
      // }
    }
    util.setStatePromise(this, {
      inputOpenForStaffMemberText: text,
    })
  }

  _getResellerAddresses = async (resellerCode: string) => {
    await util.setStatePromise(this, {
      isLoadingForAddressesOrganization: true,
    })
    const { getResellerAddresses, selectedStore } = this.props
    const orgId = util.getOrgId(selectedStore)
    // console.log(`orgId =>> `, orgId)
    if (_.isNil(orgId)) {
      p.op.showConfirmationOkOnly(`เกิดข้อผิดพลาด`, `ไม่พบรหัสองค์กร (Org_id null)`)
      return
    }
    const body = {
      org_id: orgId,
      store_id: selectedStore.get(`id`),
      reseller_code: resellerCode.toUpperCase(),
    }
    const response: { addresses: ISelectedAddresses[] } = await new Promise((resolveSave) => {
      getResellerAddresses({
        body,
        // @ts-ignore successCallback ยังไม่รู้จะจัดการยังไง
        successCallback: resolveSave,
        failedCallback: () => resolveSave(null),
      })
    })
    // console.log(`response !! => `, response)
    // const mockAddress = [
    //   {
    //     id: 56,
    //     name: 'อัญชลี สืบสายลา',
    //     address1: '33 ม.1',
    //     address2: null,
    //     address3: null,
    //     sub_district: 'ต.หัวเมือง',
    //     district: 'อ.มหาชนะชัย',
    //     province: 'จ.ยโสธร',
    //     postal_code: 35130,
    //     telephone: '0899292760',
    //     created_at: '2020-01-07 17:12:13',
    //     updated_at: '2020-01-07 17:12:13',
    //     legal_entity_type: 1,
    //     legal_entity_id: '3350600138412',
    //     note: null,
    //     is_primary: false,
    //   },
    //   {
    //     id: 56,
    //     name: 'อัญชลี สืบสายลา 02',
    //     address1: '33 ม.1',
    //     address2: null,
    //     address3: null,
    //     sub_district: 'ต.หัวเมือง',
    //     district: 'อ.มหาชนะชัย',
    //     province: 'จ.ยโสธร',
    //     postal_code: 35130,
    //     telephone: '0899292760',
    //     created_at: '2020-01-07 17:12:13',
    //     updated_at: '2020-01-07 17:12:13',
    //     legal_entity_type: 1,
    //     legal_entity_id: '3350600138412',
    //     note: null,
    //     is_primary: true,
    //   },
    // ]

    if (!_.isNil(response) && response.addresses) {
      const newAddresse = await this._getTwoAddressesForOrganization(response.addresses)
      await util.setStatePromise(this, {
        addressesForOrganization: newAddresse,
        isLoadingForAddressesOrganization: false,
      })
    } else {
      // p.op.showConfirmationOkOnly(``, ``)
      await util.setStatePromise(this, {
        isLoadingForAddressesOrganization: false,
      })
    }
  }

  _onSegmentChangeOpenAddresses = (newIndex: number): void => {
    if (this.state.optionChooseAddressesIndex === newIndex) {
      return
    }
    this.setState({ optionChooseAddressesIndex: newIndex })
  }

  _callNewXShipping = async (store_id: number, order_id: number) => {
    const { editingOrder, cancelTackingCode, createTackingCode } = this.props
    // const { store_id, order_id } = this.state
    // console.log(`editingOrder`, editingOrder)
    const showConfirmationErr = () =>
      p.op.showConfirmationOkOnly(
        'ไม่สามารถยกเลิกเลขพัสดุได้',
        'กรุณายกเลิกเลขพัสดุด้วยตนเอง หากยกเลิกเลขพัสดุด้วยตนเองไม่ได้ กรุณาติดต่อทีมงาน'
      )
    const x = editingOrder.has('x') ? editingOrder.get('x') : null
    if (_.isNil(x)) {
      return
    }

    const multipleTracking = x.get('o').toJS()
    if (_.isNil(multipleTracking)) {
      showConfirmationErr()
      return
    }
    let isHasSuccessCancellable = false
    let canCreateTackingCode = false
    for (let i = 0; i < multipleTracking.length; i++) {
      const tracking = multipleTracking[i]
      // ต้องเลขสามารถยกเลิกได้
      if (tracking.c && tracking.c === 1) {
        const { i } = tracking
        const body: { [key: string]: any } = {
          store_id,
          order_id,
          courier_order_id: i,
        }
        const res: any = await new Promise((resolve) => {
          cancelTackingCode({
            body,
            successCallback: resolve,
            failedCallback: resolve,
          })
        })
        if (res && res.x) {
          isHasSuccessCancellable = true
          // canCreateTackingCode ถ้าเจอเลขที่สามารถขอใหม่ได้ ก็เตรียม canCreateTackingCode ไว้สำหรับขอเลขใหม่ทันที
          if (canCreateTackingCode) {
            return
          }
          // r = requestable
          canCreateTackingCode = res.x.r ? res.x.r === 1 : false
        }
      }
    }

    if (!canCreateTackingCode) {
      await util.setStatePromise(this, {
        refreshing: false,
        submitting: false,
      })
      this._onRefresh()
      return
    }
    // ยกเลิกเลขพัสดุเรียบร้อยแล้ว ให้ไปขอเลขพัสดุใหม่
    if (isHasSuccessCancellable) {
      const bodyForCreate: { [key: string]: any } = {
        store_id,
        order_id,
      }
      const res: any = await new Promise((resolve) => {
        createTackingCode({
          body: bodyForCreate,
          successCallback: resolve,
          failedCallback: resolve,
        })
      })
      // ขอเลขพัสดุสำเร็จแล้ว ขอข้อมูลออเดอร์ใหม่อีกครั้ง เพื่อรีโหลดข้อมูล ที่มี xshipping ใหม่
      if (res && res.x) {
        await util.setStatePromise(this, {
          refreshing: false,
          submitting: false,
        })
        this._onRefresh()
      } else {
        await util.setStatePromise(this, {
          refreshing: false,
          submitting: false,
        })
        this._onRefresh()
        p.op.showConfirmationOkOnly('ขอเลขพัสดุใหม่ไม่สำเร็จ', 'กรุณากดขอเลขพัสดุใหม่อีกครั้ง')
      }
    }
  }

  openPrintOptionModel = () => {
    util.setStatePromise(this, {
      isOpenPrintOptionModel: true,
    })
  }

  closePrintOptionModal = () => {
    util.setStatePromise(this, {
      isOpenPrintOptionModel: false,
    })
  }

  handleConfirmOrderHaveMKP = async () => {
    const { comfirmOrderMKP, selectedOrder, selectedStore } = this.props
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      order_ids: [selectedOrder.get('id')],
    }

    const { textAddressMKPSelected, timeSlotMKP, addressMKPId, textTimeMKPSelected, optionChooseShippingMKPTypeFromIndex } = this.state
    if (!_.isNil(textAddressMKPSelected) && textAddressMKPSelected !== '') {
      // body.address
    }
    // timeslot_pref
    if (!_.isNil(timeSlotMKP) && !_.isNil(textTimeMKPSelected) && textTimeMKPSelected !== '') {
      body.pickup = {
        timeslot_pref: {
          start_time: timeSlotMKP.startTime,
          end_time: timeSlotMKP.endTime,
        },
      }
    }

    if (!_.isNil(addressMKPId) && !_.isNil(textAddressMKPSelected) && textAddressMKPSelected !== '') {
      if (body.pickup) {
        body.pickup.pickup_addr_id = addressMKPId
      } else {
        body.pickup = {
          pickup_addr_id: addressMKPId,
        }
      }
    }

    body.shipping_method =
      optionChooseShippingMKPTypeFromIndex === 0 ? 'PICKUP' : optionChooseShippingMKPTypeFromIndex === 1 ? 'DROPOFF' : 'OTHER'
    const res: { status: 'ok' } = await new Promise((resolve) => {
      comfirmOrderMKP({
        body,
        successCallback: resolve,
        failedCallback: resolve,
      })
    })

    // console.log('res => ', res)
    if (res.status === 'ok') {
      this._fetchOrderDetail()
    }
    this.endSubmitting()
  }

  onSubmitOverlayEditTimeSlot = (data: IOnSubmitOverlayEditTime) => {
    const { time } = data
    const stratTime = util.getTimeFormathhmm(time.start)
    const endtime = util.getTimeFormathhmm(time.end)

    const channels = util.getMKPChannels()
    if (_.isNil(channels)) {
      return null
    }
    const thisOrder = this._getFocusedOrder()
    const channelId = thisOrder.get('user_group_id')
    const channel = _.find(channels, (ch: IMKPChannelDetail) => ch.id === channelId)
    if (_.isNil(channel)) {
      return null
    }
    const { addresses } = channel
    const address = _.isNil(addresses) ? null : _.find(addresses, (addr: IAddress) => addr.address_mapping_uuid === data.addressId)
    let addressTextLable = ''
    let addressMKPId = null
    if (!_.isNil(address)) {
      addressTextLable = util.getAddresFormatLable(address)
      addressMKPId = address.address_mapping_uuid
    }
    const timeSlotTextLable = `${stratTime} - ${endtime}`

    const timeSlotMKP = {
      startTime: stratTime,
      endTime: endtime,
    }

    util.setStatePromise(this, {
      textAddressMKPSelected: addressTextLable,
      textTimeMKPSelected: timeSlotTextLable,
      timeSlotMKP,
      addressMKPId,
    })
    // ทำ loading ก็ดีนะ
  }

  _skipOrderDetailAutoCopy = async (): Promise<boolean> => {
    // const { getSelectedCustomCreateOrder, editingCustomCreateOrder } = this.props
    const { customCreateOrder } = this.state
    // console.log('getSelectedCustomCreateOrder => ', getSelectedCustomCreateOrder)
    // console.log('editingCustomCreateOrder => ', editingCustomCreateOrder)
    // console.log('customCreateOrder => ', customCreateOrder)
    if (
      _.isNil(customCreateOrder) ||
      (customCreateOrder.skip_order_detail_auto_copy && customCreateOrder.skip_order_detail_auto_copy.v === 1)
    ) {
      return false
    }
    return true
  }

  _onChangeDateRangeSetUpDateDeliveryOrder = async (newDate: Date) => {
    const { mode, selectedDateRangeSetUpDateDeliveryOrder } = this.state
    if (mode === CREATE) {
      util.setStatePromise(this, {
        selectedDateRangeSetUpDateDeliveryOrder: newDate,
        optionChooseSetupDateDeliveryOrderIndex: 1,
      })
    } else if (mode === VIEW_EDIT) {
      if (!util.isStoreOwner() && !acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_EDIT)) {
        p.op.showConfirmationOkOnly('', p.op.t('warning.no_right'))
        return
      }
      const order = this._getFocusedOrder()
      const orderType = order.get('type') ? parseInt(order.get('type')) : 0
      if (orderType === 3) {
        this._warningCannotEditOnPurchaseOrder()
        return
      }
      let confirmChangeDateTimeDateDeliverry = false
      await delay(300)
      await new Promise((resovle) => {
        // const oldTime = util.getTimeFormatYYYYMMDDhhmm(selectedDateRangeSetUpDateDeliveryOrder)
        const newTime = util.getTimeFormatYYYYMMDDhhmm(newDate)
        p.op.showConfirmation(
          '',
          `ตั้งกำหนดส่งเป็น ${newTime}`,
          () => {
            confirmChangeDateTimeDateDeliverry = true
            // @ts-ignore
            resovle()
          },
          () => {
            // @ts-ignore
            resovle()
          },
          'ยืนยัน',
          'ยกเลิก'
        )
      })
      if (!confirmChangeDateTimeDateDeliverry) {
        const dateDeliver = order.get('date_delivery')
        const dateFormatToDate = util.getDateTimeFormatSupportNewDate(dateDeliver)
        await util.setStatePromise(this, {
          selectedDateRangeSetUpDateDeliveryOrder: dateFormatToDate,
        })
        return
      }
      this._onChangeDateDelivery(newDate)
    } else if (mode === EDIT) {
      console.log('// a01 //')
      this._warningCantEditOnEditMode()
      console.log('// a02 //')
    }
  }

  _onChangeDateRangeSetUpAutoCancelOrder = async (newDate: Date) => {
    const { mode } = this.state
    if (mode === CREATE) {
      util.setStatePromise(this, {
        selectedDateRangeSetUpAutoCancelOrder: newDate,
        optionChooseSetupAutoCancelOrderIndex: 1,
      })
    } else if (mode === VIEW_EDIT) {
      if (!acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_EDIT)) {
        p.op.showConfirmationOkOnly('', p.op.t('warning.no_right'))
        return
      }
      const order = this._getFocusedOrder()
      const orderType = order.get('type') ? parseInt(order.get('type')) : 0
      if (orderType === 3) {
        this._warningCannotEditOnPurchaseOrder()
        return
      }
      let confirmChangeDateTimeDateDeliverry = false
      await new Promise((resovle) => {
        // const oldTime = util.getTimeFormatYYYYMMDDhhmm(selectedDateRangeSetUpAutoCancelOrder)
        const newTime = util.getTimeFormatYYYYMMDDhhmm(newDate)
        p.op.showConfirmation(
          '',
          `ตั้ง${p.op.t('StoreSetting.MenuStoreCancelOrderAutomation')} เป็น ${newTime}`,
          () => {
            confirmChangeDateTimeDateDeliverry = true
            // @ts-ignore
            resovle()
          },
          () => {
            // @ts-ignore
            resovle()
          },
          'ยืนยัน',
          'ยกเลิก'
        )
      })
      if (!confirmChangeDateTimeDateDeliverry) {
        const dateAutoCancelOrder = order.get('expiration_date')
        const dateFormatToDate = util.getDateTimeFormatSupportNewDate(dateAutoCancelOrder)
        await util.setStatePromise(this, {
          selectedDateRangeSetUpAutoCancelOrder: dateFormatToDate,
        })
        return
      }
      this._onChangeDateTimeAutoCancelOrder(newDate)
    } else if (mode === EDIT) {
      this._warningCantEditOnEditMode()
    }
  }

  _onChangeDateDelivery = async (dateDelivery: Date | null) => {
    const { onOrderDateDeliveryChange, selectedStore } = this.props
    const order = this._getFocusedOrder()
    const payload: any = {
      store_id: selectedStore.get('id'),
      order_id: order.get('id'),
      date_delivery: _.isNil(dateDelivery) ? null : util.getTimeFormatYYYYMMDDhhmmssIfNeedSecondToZero(dateDelivery),
      callBack: (newOrder) => this._setDateTimeDateDeliverViewEditMode(newOrder),
    }
    onOrderDateDeliveryChange(payload)
    // await delay(3000)
    // await this._setDateTimeDateDeliverViewEditMode()
  }

  _onChangeDateTimeAutoCancelOrder = async (date: Date | null) => {
    const { onOrderDateTimeAutoCancelOrderChange, selectedStore } = this.props
    const order = this._getFocusedOrder()
    const payload: any = {
      store_id: selectedStore.get('id'),
      order_id: order.get('id'),
      expiration_date: _.isNil(date) ? null : util.getTimeFormatYYYYMMDDhhmmssIfNeedSecondToZero(date),
      callBack: (newOrder) => this._setDateTimeAutoCancelOrder(newOrder),
    }
    onOrderDateTimeAutoCancelOrderChange(payload)
    // await delay(3000)
    // await this._setDateTimeAutoCancelOrderViewEditMode()
  }

  _findDupByReceiverAddr = async ({ store_id, postal_code, name, telephone }) => {
    const { isBypassReceiverAddressDupCheck = false, customCreateOrder } = this.state
    const checkDupReceiverAddr = _.has(customCreateOrder, 'check_dup_receiver_addr') ? customCreateOrder.check_dup_receiver_addr : true
    if (!checkDupReceiverAddr) {
      return
    }

    if (isBypassReceiverAddressDupCheck) {
      await xUtil.setStatePromise(this, { isBypassDupCheck: false })
      return
    }

    const reqBody = {
      store_id,
      postal_code: _.isNil(postal_code) ? null : _.isNumber(postal_code) ? postal_code.toString() : postal_code,
      name,
      telephone,
      within_days: 31,
    }

    const shallCheckForDuplicateReceiverAddr =
      _.isNil(postal_code) ||
      postal_code === '' ||
      _.isNil(telephone) ||
      telephone === '' ||
      _.isNil(name) ||
      name === '' ||
      this.state.mode === VIEW_EDIT
    if (shallCheckForDuplicateReceiverAddr) {
      this.setState({ shallCheckForDuplicateReceiverAddr })
      return
    }

    try {
      const res: { order_address: IAddressList[] } = await api.postV2(api.POST_FIND_DUP_BY_RECEIVER_ADDRESS, reqBody)
      // console.log('_findDupByReceiverAddr => ', res)

      if (res.order_address && res.order_address.length > 0) {
        util.setStatePromise(this, {
          addressesListOverlay: res.order_address,
          isVisibleAddressesListOverlay: true,
          shallCheckForDuplicateReceiverAddr: false,
        })
        // console.log('order_address => ', res.order_address)
      } else {
        util.setStatePromise(this, {
          addressesListOverlay: null,
        })
      }
    } catch (err) {
      // console.log('PaymentAccountPicker::initPaymentAccounts err => ', err)
    }
  }

  onChangeShippingNormalCODType = async (codAmount: string) => {
    const { selectedStore, onShippingTypeChange } = this.props
    const { newShippingTypeForChangeShippingTypeToCOD } = this.state
    if (_.isNil(newShippingTypeForChangeShippingTypeToCOD)) {
      return
    }
    const order = this._getFocusedOrder()
    const store_id = selectedStore.get('id')
    const subOrders = order.get('suborders')
    const payload: any = {
      store_id,
      order_id: order.get('id'),
      shipping_type_id: newShippingTypeForChangeShippingTypeToCOD,
      successCallback: (order: any) => this._successCallbackFromChangeShippingTypeCOD(order, codAmount),
    }
    if (subOrders && subOrders.size > 0) {
      let subOrderIdLabels = '('
      subOrders.forEach((subOrder, idx) => {
        subOrderIdLabels += `#${subOrder.get('id')}`
        if (idx === subOrders.size - 1) {
          subOrderIdLabels += ')'
        } else {
          subOrderIdLabels += ', '
        }
      })
      const newShippingTypeLabel = util.getShippingTypeNameById(newShippingTypeForChangeShippingTypeToCOD)
      await delay(200)
      // ask user if the change shall propagate to sub orders
      p.op.showConfirmationThreeButtons(
        'แก้ไขออเดอร์ซื้อที่เกี่ยวข้อง',
        `คุณต้องการแก้ไขรูปแบบจัดส่งของ\n${subOrders.size} ออเดอร์ซื้อที่เกี่ยวข้อง ${subOrderIdLabels} ให้เป็น "${newShippingTypeLabel}" ด้วยหรือไม่\n\n*คุณสามารถแก้ไขรูปแบบจัดส่งของแต่ละออเดอร์ซื้อได้ในภายหลัง`,
        'แก้ไขแค่ออเดอร์ขายนี้เท่านั้น',
        () => {
          this.props.onShippingTypeChange(payload)
        },
        'แก้ไขออเดอร์ซื้อที่เกี่ยวข้องทั้งหมดด้วย',
        () => {
          // log('แก้ไขออเดอร์ซื้อทั้งหมดด้วย')
          payload.update_purchase_orders = true
          this.props.onShippingTypeChange(payload)
        }
      )
    } else {
      onShippingTypeChange(payload)
    }
  }

  _successCallbackFromChangeShippingTypeCOD = async (order: any, codAmount: string) => {
    // console.log('_successCallbackFromChangeShippingTypeCOD => ', codAmount)
    const { selectedStore, orderConfirm } = this.props
    const store_id = selectedStore.get('id')
    await this.beginSubmitting()
    // const thisOrder = order
    const order_id = order.id
    // if (order.parent_name) {
    //   order_id = order.parent_name
    // }
    const { type } = order
    await new Promise((resolveConfirmApi) => {
      const successCallback = async (res) => {
        // orderRevert()
        // await this._changeModeTo(VIEW_EDIT)
        await this._handleAfterSuccessConfirmOrder(res)
        await setStatePromise(this, { mode: VIEW_EDIT, orderTabsVisibility: false })
        // if (_.isFunction(callback)) {
        //   await callback(res)
        // }
        await this._callNewXShipping(store_id, order_id)
        resolveConfirmApi(null)
      }
      const failedCallback = resolveConfirmApi
      const body: { [key: string]: any } = {}
      body.store_id = store_id
      body.order_id = order_id
      body.is_cod = true
      body.cod_amount = codAmount
      body.cod_fee = '0'
      body.updated_at = order.updated_at
      if (!order.ship_before_pay) {
        body.ship_before_pay = true
      }

      // console.log('NANI => ', order)
      orderConfirm({ body, successCallback, failedCallback })
    })
    this.inProcess = false
    this.endSubmitting()
  }

  onChangeShippingCODToNormalType = async (newShippingTypeId: number) => {
    const { selectedStore, onShippingTypeChange } = this.props
    if (_.isNil(newShippingTypeId)) {
      return
    }
    const order = this._getFocusedOrder()
    const store_id = selectedStore.get('id')
    const subOrders = order.get('suborders')
    const payload: any = {
      store_id,
      order_id: order.get('id'),
      shipping_type_id: newShippingTypeId,
      successCallback: (order: any) => this._successCallbackFromChangeShippingTypeCODToNormal(order),
    }
    if (subOrders && subOrders.size > 0) {
      let subOrderIdLabels = '('
      subOrders.forEach((subOrder, idx) => {
        subOrderIdLabels += `#${subOrder.get('id')}`
        if (idx === subOrders.size - 1) {
          subOrderIdLabels += ')'
        } else {
          subOrderIdLabels += ', '
        }
      })
      const newShippingTypeLabel = util.getShippingTypeNameById(newShippingTypeId)
      await delay(200)
      // ask user if the change shall propagate to sub orders
      p.op.showConfirmationThreeButtons(
        'แก้ไขออเดอร์ซื้อที่เกี่ยวข้อง',
        `คุณต้องการแก้ไขรูปแบบจัดส่งของ\n${subOrders.size} ออเดอร์ซื้อที่เกี่ยวข้อง ${subOrderIdLabels} ให้เป็น "${newShippingTypeLabel}" ด้วยหรือไม่\n\n*คุณสามารถแก้ไขรูปแบบจัดส่งของแต่ละออเดอร์ซื้อได้ในภายหลัง`,
        'แก้ไขแค่ออเดอร์ขายนี้เท่านั้น',
        () => {
          this.props.onShippingTypeChange(payload)
        },
        'แก้ไขออเดอร์ซื้อที่เกี่ยวข้องทั้งหมดด้วย',
        () => {
          // log('แก้ไขออเดอร์ซื้อทั้งหมดด้วย')
          payload.update_purchase_orders = true
          this.props.onShippingTypeChange(payload)
        }
      )
    } else {
      onShippingTypeChange(payload)
    }
  }

  _successCallbackFromChangeShippingTypeCODToNormal = async (order: any) => {
    // console.log('_successCallbackFromChangeShippingTypeCOD => ', codAmount)
    const { selectedStore, orderConfirm } = this.props
    const store_id = selectedStore.get('id')
    await this.beginSubmitting()
    // const thisOrder = order
    const order_id = order.id
    // if (order.parent_name) {
    //   order_id = order.parent_name
    // }
    // const type = order.type
    await new Promise((resolveConfirmApi) => {
      const successCallback = async (res) => {
        // orderRevert()
        // await this._changeModeTo(VIEW_EDIT)
        await this._handleAfterSuccessConfirmOrder(res)
        await setStatePromise(this, { mode: VIEW_EDIT, orderTabsVisibility: false })
        // if (_.isFunction(callback)) {
        //   await callback(res)
        // }
        await this._callNewXShipping(store_id, order_id)
        resolveConfirmApi(null)
      }
      const failedCallback = resolveConfirmApi
      const body: { [key: string]: any } = {}
      body.store_id = store_id
      body.order_id = order_id
      body.ship_before_pay = false
      body.updated_at = order.updated_at
      // console.log('NANI => ', order)
      orderConfirm({ body, successCallback, failedCallback })
    })
    this.inProcess = false
    this.endSubmitting()
  }

  _succeedPickReceiverAddress = async (address) => {
    const { store_id, mode } = this.state
    const { onChangeAddress, editReceiverAddress } = this.props
    if (mode === CREATE) {
      onChangeAddress({
        receiver: address,
      })
    } else if (mode === VIEW_EDIT) {
      const order = this._getFocusedOrder()
      const body = {
        store_id,
        order_id: order.get('id'),
        type: 'RECEIVER',
        new_address_id: address.id,
      }

      const callNewXShipping = await this._warningChangeReceiverAddressHasXShipping(
        'คุณเปลื่ยนที่อยู่',
        'ระบบจะทำการยกเลิกเลขติดตามพัสดุเดิม และขอใหม่ เพื่อให้ที่อยู่ผู้รับเปลื่ยนตามที่แก้ไขไว้ หากระบบขอใหม่ไม่สำเร็จ กรุณากดยกเลิกเลขติดตามพัสดุและขอใหม่เอง'
      )

      editReceiverAddress({ body, successCallback: async () => this._changeReceiverAddressHasXShipping(callNewXShipping) })
      // @ts-ignore
      this.setState({ orderTabsVisibility: false })
    } else {
      //
    }
  }

  _succeedPickBillingAddress = async (address) => {
    const { store_id, mode } = this.state
    const { onChangeAddress, editReceiverAddress } = this.props
    if (mode === CREATE) {
      onChangeAddress({
        billing_address: address,
      })
    } else if (mode === VIEW_EDIT) {
      const order = this._getFocusedOrder()
      const body = {
        store_id,
        order_id: order.get('id'),
        // type: 'BILLING_ADDRESS',
        type: 'BILLING',
        new_address_id: address.id,
      }

      const callNewXShipping = await this._warningChangeReceiverAddressHasXShipping(
        'คุณเปลื่ยนที่อยู่',
        'ระบบจะทำการยกเลิกเลขติดตามพัสดุเดิม และขอใหม่ เพื่อให้ที่อยู่ผู้รับเปลื่ยนตามที่แก้ไขไว้ หากระบบขอใหม่ไม่สำเร็จ กรุณากดยกเลิกเลขติดตามพัสดุและขอใหม่เอง'
      )

      editReceiverAddress({ body, successCallback: async () => this._changeReceiverAddressHasXShipping(callNewXShipping) })
      // @ts-ignore
      this.setState({ orderTabsVisibility: false })
    } else {
      //
    }
  }

  cloneOrder = async () => {
    const isUserConfirm = await p.op.isUserConfirm(
      'สร้างออเดอร์ใหม่โดยใช้ข้อมูลจากออเดอร์นี้',
      'กรุณายืนยันเพื่อเปลี่ยนไปสร้างออเดอร์ใหม่ โดยจะคัดลอกข้อมูล ผู้รับ ผู้ส่ง รูปแบบจัดส่ง ส่วนลด ค่าส่ง ค่าอื่นๆ และสินค้า จากออเดอร์นี้'
    )
    if (!isUserConfirm) {
      return
    }

    const { navigation, selectedOrder, selectedStore, productGroups = List([]) } = this.props

    console.log('cloneOrder selectedOrder => ', selectedOrder.toJS())
    const od = selectedOrder.toJS()
    const odTmpl: IOrderTemplate = {
      store_id: od.store_id,
      is_cod: od.is_cod,

      ship_before_pay: od.ship_before_pay,
      store_products: od.store_products,
      shipping_type_id: od.shipping_type_id,

      discount: od.discount,
      shipping_cost: od.shipping_cost,
      etc_cost: od.etc_cost,

      // etc_cost: od.rece,
    }

    if (od.is_cod) {
      odTmpl.cod_fee = od.cod_fee
      odTmpl.cod_amount = od.cod_amount
    }

    if (od.user_group_id) {
      odTmpl.user_group_id = od.user_group_id
    }

    if (_.isString(od.sender_name) && od.sender_name.length > 0) {
      odTmpl.sender_name = od.sender_name
    }

    // @ts-ignore
    if (_.isObject(od.sender) && od.sender.id) {
      // @ts-ignore
      odTmpl.sender = od.sender
    }

    // @ts-ignore
    if (_.isObject(od.receiver) && od.receiver.id) {
      // @ts-ignore
      odTmpl.receiver = od.receiver
    }

    // @ts-ignore
    if (_.isObject(od.billing_address) && (od.billing_address.address1 || od.billing_address.address3)) {
      // @ts-ignore
      odTmpl.billing_address = od.billing_address
    }

    if (_.isArray(od.vd_discounts) && od.vd_discounts.length > 0) {
      odTmpl.vd_discounts = od.vd_discounts
    }

    // const myStoreId = xUtil.getNavParam(this.props, 'store_id')
    const myStoreId = selectedStore.get('id')
    const product_group_ids = productGroups.toJS().map((pg) => pg.id) || []
    navigation.setParams({ mode: CREATE, store_id: myStoreId })

    await xUtil.delay(50)
    await this.doInit()

    // Begin Apply template
    console.log('cloneOrder odTmpl => ', odTmpl)
    this.props.setIsInitialized(false)
    // ป้องกันการแสดงผลชั่งคราว ก่อนทำการสร้างออเดอร์ใหม่ + bypass เช็คที่อยู่ผู้รับ 1 ครั้ง
    await xUtil.setStatePromise(this, {
      isInitialized: false,
      isBypassReceiverAddressDupCheck: true,
      orderTabIndex: 0,
      orderTabsVisibility: false,
    })
    await xUtil.delay(200)

    if (odTmpl.user_group_id) {
      const userGroups = selectedStore.get('user_groups') || List([])
      const foundUgIndex = userGroups.findIndex((ug) => ug.get('id') === odTmpl.user_group_id)
      // console.log('cloneOrder foundUgIndex => ', foundUgIndex)

      if (foundUgIndex > -1) {
        await this._onChangeAddStoreProductSelectorOptions({
          optionChooseProductsFollowingIndex: 0,
          optionChooseProductsByGroupIndex: foundUgIndex,
          optionChooseProductsFromIndex: this.state.optionChooseProductsFromIndex,
          optionSelectedSellerStoreIndex: this.state.optionSelectedSellerStoreIndex,
        })
        await xUtil.delay(50)
        // this._onSegmentChangeChooseProductFollowing(0) // 0 === ug
        // await xUtil.delay(50)
        // this._onRadioChangeChooseProductsByGroup(foundUgIndex)
        // await xUtil.delay(50)
      }
    }

    if (_.isNil(odTmpl.user_group_id)) {
      await this._onChangeAddStoreProductSelectorOptions({
        optionChooseProductsFollowingIndex: 1,
        optionChooseProductsByGroupIndex: 0,
        optionChooseProductsFromIndex: this.state.optionChooseProductsFromIndex,
        optionSelectedSellerStoreIndex: this.state.optionSelectedSellerStoreIndex,
      })
      await xUtil.delay(50)

      // this._onSegmentChangeChooseProductFollowing(1) // 1 === pg
      // await xUtil.delay(50)
      // // this._onRadioChangeChooseProductsByGroup(0)
      // // await xUtil.delay(50)
    }

    const storeIds = Object.keys(odTmpl.store_products).map((storeIdStr) => parseInt(storeIdStr))

    for (let si = 0; si < storeIds.length; si++) {
      const targetStoreId = storeIds[si]
      const targetStoreProducts = odTmpl.store_products[targetStoreId]
      const isSellerStoreProduct = targetStoreId !== myStoreId

      for (let pi = 0; pi < targetStoreProducts.length; pi++) {
        const pd = targetStoreProducts[pi]

        if (pd.qty === 0) {
          continue
        }

        // Add Products
        // eslint-disable-next-line no-await-in-loop
        // await this.fetchToAddProduct({
        //   store_id: myStoreId,
        //   seller_store_id: isSellerStoreProduct ? targetStoreId : undefined,
        //   pt_id: pd.product_id,
        //   pp_id: pd.pp_id,
        //   user_group_id: odTmpl.user_group_id,
        //   product_group_ids: _.isNil(odTmpl.user_group_id) ? product_group_ids : undefined,
        //   qty: pd.qty,
        // })

        // pd modified
        // console.log(`si=${si} :: pi${pi} :: pd => `, pd)

        const spdv = [
          {
            i: pd.pp_id,
            n: pd.variant || '',
            q: 9999999,
            p: pd.price || '0.00',
            // @ts-ignore
            c: pd.cost || pd.price || '0.00',
          },
        ]

        // let pgIds = product_group_ids || undefined

        // if (pd.pp_id === 1977112 || pd.pp_id === 1977601 || pd.pp_id === 1977749) {
        //   // @ts-ignore
        //   spdv[0].s = [{ p: pd.price || '0.00', q: 9999999, g: 1138 }]
        //   // @ts-ignore
        //   pgIds = [1138]
        // } else {
        //   // @ts-ignore
        //   spdv[0].p = pd.price || '0.00'
        //   // @ts-ignore
        //   pgIds = undefined
        // }

        if (_.isArray(odTmpl.vd_discounts) && odTmpl.vd_discounts.length > 0) {
          for (let i = 0; i < odTmpl.vd_discounts.length; i++) {
            const vd = odTmpl.vd_discounts[i]
            if (vd.pp_ids.includes(pd.pp_id)) {
              // @ts-ignore
              spdv[0].s = [{ p: pd.price || '0.00', q: 9999999, g: vd.pg_id }]
            }
          }
        }

        const spd = {
          id: pd.product_id,
          n: pd.name,
          // @ts-ignore
          t: pd.thumbnail_uris,
          v: spdv,
        }

        // @ts-ignore
        if (isSellerStoreProduct && pd.h) {
          // @ts-ignore
          spd.s = targetStoreId
          // @ts-ignore
          spd.v[0].h = pd.h
        }

        // // @ts-ignore
        // if (isSellerStoreProduct && pd.h) {
        //   // @ts-ignore
        //   spd.v[0].h = pd.h
        // }
        // if (isSellerStoreProduct && pd.h) {
        //   // @ts-ignore
        //   spd.v[0].i = pd.h
        // }
        // console.log(`si=${si} :: pi${pi} :: spd => `, spd)

        this._handlePullProductToOrder({
          // @ts-ignore
          product: spd,
          pp_id: pd.pp_id,
          // seller_store_id: isSellerStoreProduct ? targetStoreId : undefined,
          // @ts-ignore
          seller_store_id: isSellerStoreProduct && !pd.h ? targetStoreId : undefined,
          user_group_id: odTmpl.user_group_id,
          product_group_ids: _.isNil(odTmpl.user_group_id) ? product_group_ids : undefined,
          // product_group_ids: _.isNil(odTmpl.user_group_id) ? pgIds : undefined,
          qty: pd.qty,
        })

        // Apply Price
        // eslint-disable-next-line no-await-in-loop
        await xUtil.delay(50)
        this.props.onChangeStoreProductByKey({ pp_id: pd.pp_id, key: 'price', value: pd.price })
      }
    }

    this.props.onEditingOrderChange({ key: 'discount', value: odTmpl.discount, orderTabIndex: 0 })
    this.props.onEditingOrderChange({ key: 'shipping_cost', value: odTmpl.shipping_cost, orderTabIndex: 0 })
    this.props.onEditingOrderChange({ key: 'etc_cost', value: odTmpl.etc_cost, orderTabIndex: 0 })

    this.props.onEditingOrderChange({ key: 'shipping_type_id', value: odTmpl.shipping_type_id, orderTabIndex: 0 })

    this.props.onEditingOrderChange({ key: 'ship_before_pay', value: odTmpl.ship_before_pay, orderTabIndex: 0 })
    this.props.onEditingOrderChange({ key: 'is_cod', value: odTmpl.is_cod, orderTabIndex: 0 })

    if (odTmpl.is_cod) {
      this.props.onEditingOrderChange({ key: 'cod_fee', value: odTmpl.cod_fee, orderTabIndex: 0 })
      this.props.onEditingOrderChange({ key: 'cod_amount', value: odTmpl.cod_amount, orderTabIndex: 0 })
    }

    if (_.isObject(odTmpl.receiver) && odTmpl.receiver.id) {
      // odTmpl.receiver = od.receiver
      await this._onReceiveNewAddress('receiver', fromJS(odTmpl.receiver))
      await xUtil.delay(50)
      this.props.onChangeAddress({ receiver: odTmpl.receiver })
      await xUtil.delay(50)
    }
    //  else {
    //   this.props.onChangeAddress({ receiver: { id: null } })
    // }

    if (_.isObject(odTmpl.sender) && odTmpl.sender.id) {
      // odTmpl.sender = od.sender
      await this._onReceiveNewAddress('sender', fromJS(odTmpl.sender))
      await xUtil.delay(50)
      this.props.onChangeAddress({ sender: odTmpl.sender })
      await xUtil.delay(50)
    }

    if (odTmpl.sender_name) {
      await xUtil.setStatePromise(this, { txtSenderName: odTmpl.sender_name })
      await xUtil.delay(50)
    }

    if (odTmpl.billing_address) {
      await xUtil.setStatePromise(this, { isBillingAddressUsed: true })
      await this._onReceiveNewAddress('billing_address', fromJS(odTmpl.billing_address))
      await xUtil.delay(50)
      this.props.onChangeAddress({ billing_address: odTmpl.billing_address })
      await xUtil.delay(50)
    }

    this.props.setIsInitialized(true)
    await xUtil.setStatePromise(this, { isInitialized: true })
  }

  // fetchToAddProduct = async (params: {
  //   store_id: number
  //   seller_store_id?: number
  //   pt_id: number
  //   pp_id: number
  //   qty: number
  //   user_group_id?: number
  //   product_group_ids?: number[]
  // }) => {
  //   try {
  //     // const store_id = util.getNavParam(this.props, 'store_id')
  //     // const seller_store_id = util.getNavParam(this.props, 'seller_store_id')
  //     const apiOpt: IApiOptions = {
  //       axiosOptions: {
  //         timeout: 30000,
  //       },
  //       showSpinner: true,
  //     }

  //     const reqBody = {
  //       store_id: params.store_id,
  //       product_id: params.pt_id,
  //     }

  //     if (params.seller_store_id) {
  //       reqBody.store_id = params.seller_store_id
  //     }

  //     const res = await api.post(api.POST_PRODUCT, reqBody, apiOpt)

  //     if (res.product) {
  //       this._handlePullProductToOrder({
  //         product: res.product,
  //         pp_id: params.pp_id,
  //         seller_store_id: params.seller_store_id,
  //         user_group_id: params.user_group_id,
  //         product_group_ids: params.product_group_ids,
  //         qty: params.qty,
  //       })
  //     }

  //     // console.log('fetchToAddProduct item => ', params)
  //     // console.log('fetchToAddProduct res => ', res)
  //   } catch (err) {
  //     // console.log('fetchToAddProduct err => ', err)
  //   }
  // }

  _handlePullProductToOrder = async (params: {
    product: IProductDetailItem
    pp_id: number
    qty: number
    seller_store_id?: number
    user_group_id?: number
    product_group_ids?: number[]
  }): Promise<void> => {
    const { product, pp_id: selectedPpId, seller_store_id, user_group_id, product_group_ids, qty } = params
    const { navigation, userGroups: selectedUserGroups, quickAddProductToOrder } = this.props
    const store_id = util.getNavParam(this.props, 'store_id')
    // const seller_store_id = util.getNavParam(this.props, 'seller_store_id')
    // const selectedVariantIndex = await util.getActionSheetAddOneProductToCartSelectedVariantIndex({
    //   selectedProductListItem: product,
    //   store_id,
    //   seller_store_id,
    // })

    // const selectedVariantIndex = product.variants.findIndex((v) => v.pp_id === selectedPpId)

    // // console.log('_handlePullProductToOrder:: selectedVariantIndex => ', selectedVariantIndex)

    // if (_.isNil(selectedVariantIndex) || selectedVariantIndex === -1) {
    //   return
    // }

    const willPullProductParams = await util.getPullProductParamsFromProductListItem({
      selectedProductListItem: product,
      selectedVariantIndex: 0,
      store_id,
      seller_store_id,
      selectedUserGroups,
      ug_id: user_group_id,
      pg_ids: product_group_ids,
      qty,
    })

    // console.log('_handlePullProductToOrder:: willPullProductParams => ', willPullProductParams)

    if (!willPullProductParams) {
      return
    }

    quickAddProductToOrder({
      store_id,
      seller_store_id,
      orderProducts: [willPullProductParams],
      ug_id: user_group_id,
      pg_ids: product_group_ids,
      callback: this._handleOnPullProductToOrderSuccess,
    })
  }

  _handleOnPullProductToOrderSuccess = (newState: Map<string, any>): void => {
    // log('success callback of quick add => newState.toJS()', newState.toJS())
    const store_products = newState.getIn(['editingOrder', 'store_products']) || null
    const store_ids = store_products.keySeq().toArray() || []
    // log('success callback of quick add => store_ids', store_ids)
    if (store_products && store_ids.length > 0) {
      let products = List([])

      for (let sIdx = 0; sIdx < store_ids.length; sIdx++) {
        const sId = store_ids[sIdx]
        const sProds = store_products.get(sId) || List([])
        products = products.concat(sProds)
      }

      let txtSummary = ''
      for (let i = 0; i < products.size; i++) {
        if (i === 0) {
          txtSummary = 'สินค้าในออเดอร์ทั้งหมด\n'
        }
        const product = products.get(i)
        const name = product.get('name')
        const variant = product.get('variant')
        const qty = product.get('qty')
        const txtProductName = variant ? `${name} (${variant})` : name
        txtSummary += `\n${txtProductName} x${qty}`
      }
      // showSuccessToast(`เพิ่ม ${txtProductName} แล้ว`)
      if (txtSummary) {
        p.op.showToast(txtSummary, 'success')
      }
    }
  }

  _getErpCode = (erpDocs: IOrderErpChannels[]) => {
    let erpDocsText = ''
    if (_.isNil(erpDocs)) {
      return ''
    }
    erpDocs.forEach((docs, idx) => {
      if (docs.erp_doc_code) {
        if (idx < 3) {
          erpDocsText = `${erpDocsText + docs.erp_doc_code}`
          if (idx + 1 !== erpDocs.length) {
            erpDocsText += ', '
          }
        }
        if (idx === 3 && erpDocs.length > 3) {
          erpDocsText += `(+${erpDocs.length - 3})`
        }
      }
    })
    return erpDocsText
  }
}
