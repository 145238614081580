import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import _ from 'lodash'

import api from 'x/utils/api'
import * as bg from 'x/utils/bg'

import { S, COLORS } from 'x/config/styles'
import CONS from 'x/config/constants'
import {
  IApiOptions,
  IBgJobItem,
  IBgJobTaskItem,
  IBackgroundTaskListViewNavParams,
  IBackgroundTaskListApiRequest,
  IBackgroundTaskListResponse,
  IXScreenProps,
} from 'x/index'

import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import p from 'x/config/platform-specific'
import XCustomHeader from 'xui/components/XCustomHeader'
import Segment from 'xui/components/Segment'
import ForwardIcon from 'xui/components/ForwardIcon'
import ErrorListItem from 'xui/components/ErrorListItem'
import XText from 'xui/components/XText'
import XContainer from 'xui/components/XContainer'
import InfiniteList, { InfiniteListBase } from '../../components/InfiniteList'

// interface IWithJob {
//   job: IBgJobItem
// }
// interface IWithJobUUID {
//   jobUUID: number
// }

// type IBackgroundTaskListViewNavParams = IWithJob | IWithJobUUID

type IProps = IXScreenProps<IBackgroundTaskListViewNavParams>

interface IState {
  x?: number
  job?: IBgJobItem
  selectedTaskStatusIndex: number
  isFetching: boolean
  isAlreadyFetchInited: boolean
  isMounted: boolean
  fetchErrorMessage?: {
    statusCode: number
    statusMessage: string
  }
}

class BackgroundTaskListView extends React.Component<IProps, IState> {
  static displayName = 'BackgroundTaskListView'

  jobTaskListRef: React.RefObject<InfiniteListBase<IBgJobTaskItem>>

  constructor(props) {
    super(props)

    this.jobTaskListRef = React.createRef()

    this.state = {
      isAlreadyFetchInited: false,
      isFetching: false,
      selectedTaskStatusIndex: 0,
      isMounted: false,
    }
  }

  async componentDidMount() {
    const { navigation } = this.props
    // const job = util.getNavParam(this.props, 'job')
    // const jobUUID = util.getNavParam(this.props, 'jobUUID')

    // if (job && job.uuid) {
    //   this.setState({ job })
    // } else if (jobUUID) {
    //   this.setState({ job: { uuid: jobUUID } })
    // }

    const initialTaskStatusIndex = util.getNavParam(this.props, 'initialTaskStatusIndex')
    if (_.isNumber(initialTaskStatusIndex)) {
      await util.setStatePromise(this, { selectedTaskStatusIndex: initialTaskStatusIndex })
    }

    await util.delay(200)

    await util.setStatePromise(this, { isMounted: true })
  }

  getFetchStoreId = () => {
    const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'storeId')
    return storeId
  }

  getFetchJobUUID = () => {
    const { navigation } = this.props
    const jobUUID = util.getNavParam(this.props, 'jobUUID')
    return jobUUID
  }

  _navToOrderView = (orderId: number) => {
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToOrderDetail({
        store_id: this.getFetchStoreId(),
        order_id: orderId,
      })
    )
  }

  navToProductView = (productId: number) => {
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToProductViewSelf({
        store_id: this.getFetchStoreId(),
        product_id: productId,
      })
    )
  }

  navToMkpProductView = (ref_uuid: string, ref_id: number) => {
    const { job } = this.state
    if (_.isNil(job)) {
      return
    }
    const successCount = job.done_task_count || 0
    const errorCount = job.error_task_count || 0
    const doneCount = successCount + errorCount
    const totalCount = job.total_task_count
    const donePercent = !doneCount ? 0 : Math.floor((doneCount / totalCount) * 100)
    if (donePercent !== 100) {
      return
    }
    if (_.isNil(ref_uuid)) {
      p.op.showConfirmationOkOnly('', 'ref_uuid Not Found')
      return
    }
    if (_.isNil(ref_id)) {
      p.op.showConfirmationOkOnly('', 'ref_id Not Found')
      return
    }
    const { navigation } = this.props
    const mkpId = util.getMKPId(ref_id)
    if (mkpId === 0) {
      p.op.showConfirmationOkOnly('', 'mkp_id Not Found')
      return
    }

    navigation.dispatch(
      NavActions.navToMkpProductDetailView({
        store_id: this.getFetchStoreId(),
        mkp_ch_id: ref_id,
        item_uuid: ref_uuid,
        mkp_id: mkpId,
      })
    )
  }

  getRefContextTaskByType = (task: IBgJobTaskItem): { label: string; prefix: string } => {
    const refId = task.ri
    const refName = task.rs
    const refUUID = task.uuid
    const taskType = task.t

    // KENG TODO: handle case if both refId and refName are both provided.
    let displayRefIdText = refName ? `${refName}` : null
    if (!displayRefIdText && refId) {
      displayRefIdText = `${refId}`
    } else if (!displayRefIdText && refUUID) {
      displayRefIdText = refUUID
    }

    let label = 'รหัสอ้างอิง'
    let prefix = ''
    if (
      refId &&
      _.includes(
        [
          CONS.BACKGROUND_JOB_TYPES.SHIPPING_CREATE,
          CONS.BACKGROUND_JOB_TYPES.PAYMENT_CREATE,
          CONS.BACKGROUND_JOB_TYPES.MKP_ORDER_CONFIRM,
          CONS.BACKGROUND_JOB_TYPES.MKP_SYNC_ORDER,
          CONS.BACKGROUND_JOB_TYPES.IMPORT_PAYMENT_COD,
          CONS.BACKGROUND_JOB_TYPES.IMPORT_ORDER_CREATE,
        ],
        taskType
      )
    ) {
      label = 'ออเดอร์'
      prefix = '#'
    } else if (!refId && _.includes([CONS.BACKGROUND_JOB_TYPES.MKP_ORDER_CONFIRM, CONS.BACKGROUND_JOB_TYPES.MKP_SYNC_ORDER], taskType)) {
      label = 'ออเดอร์'
    } else if (_.includes([CONS.BACKGROUND_JOB_TYPES.MKP_SYNC_PRODUCT], taskType)) {
      label = 'สินค้า'
      // prefix = ''
    }
    // else {
    //   label = 'รหัสอ้างอิง ${displayRefIdText}'
    //   prefix = ''
    // }

    return { label, prefix }
  }

  // Render Methods
  renderBgJobTaskItem = (jtItem: { item?: IBgJobTaskItem; index: number; containerWidth?: number }) => {
    const maxWidth = jtItem.containerWidth || undefined
    const isEven = jtItem.index % 2 === 0
    const task = jtItem.item
    const refId = task.ri
    const refName = task.rs
    const refUUID = task.uuid
    const createAtText = task.sa
    const doneAtText = task.ca
    // TODO: create adapter translate error code
    const errorMessageText = task.ec ? util.getBgTaskErrorText(task.ec) : null
    const errorJSONText = task.ej ? util.getBgTaskJSONErrorText(task.ej) : null

    // const processAtText = task.processed_at || '(กำลังรอคิว)'
    // console.log('task => ', task)
    const taskType = task.t

    let onTaskPress
    // if (refId && _.includes([CONS.BACKGROUND_JOB_TYPES.SHIPPING_CREATE, CONS.BACKGROUND_JOB_TYPES.PAYMENT_CREATE], taskType)) {
    //   onTaskPress = () => this._navToOrderView(refId)
    // }
    // console.log('/// => ', jtItem.item)
    if (
      refId &&
      _.includes(
        [
          CONS.BACKGROUND_JOB_TYPES.SHIPPING_CREATE,
          CONS.BACKGROUND_JOB_TYPES.PAYMENT_CREATE,
          CONS.BACKGROUND_JOB_TYPES.MKP_ORDER_CONFIRM,
          CONS.BACKGROUND_JOB_TYPES.MKP_SYNC_ORDER,
          CONS.BACKGROUND_JOB_TYPES.XSHIPPING_STATUS_UPDATE,
          CONS.BACKGROUND_JOB_TYPES.IMPORT_PAYMENT_COD,
          CONS.BACKGROUND_JOB_TYPES.IMPORT_ORDER_CREATE,
          CONS.BACKGROUND_JOB_TYPES.ERP_CREATE_DOCUMENT,
          CONS.BACKGROUND_JOB_TYPES.ERP_SYNC_DOCUMENT,
          CONS.BACKGROUND_JOB_TYPES.ERP_SYNC_STATUS_DOCUMENT,
        ],
        taskType
      )
    ) {
      onTaskPress = () => this._navToOrderView(refId)
    } else if (
      _.includes(
        [
          // CONS.BACKGROUND_JOB_TYPES.MKP_SYNC_PRODUCT,
          CONS.BACKGROUND_JOB_TYPES.IMPORT_PRODUCT_STOCK_QTY_EDIT,
          CONS.BACKGROUND_JOB_TYPES.PRODUCT_QTY_ADD,
          CONS.BACKGROUND_JOB_TYPES.PRODUCT_QTY_UPDATE,
        ],
        taskType
      )
    ) {
      onTaskPress = () => this.navToProductView(refId)
    } else if (_.includes([CONS.BACKGROUND_JOB_TYPES.MKP_SYNC_PRODUCT], taskType)) {
      onTaskPress = () => this.navToMkpProductView(task.ru, task.ri)
    }

    if (
      _.includes(
        [
          CONS.BACKGROUND_JOB_TYPES.IMPORT_PRODUCT_STOCK_QTY_EDIT,
          CONS.BACKGROUND_JOB_TYPES.PRODUCT_QTY_ADD,
          CONS.BACKGROUND_JOB_TYPES.PRODUCT_QTY_UPDATE,
        ],
        taskType
      )
    ) {
      if (errorMessageText || errorJSONText) {
        onTaskPress = null
      }
    }

    const hasAction = _.isFunction(onTaskPress)
    // const displayRefIdText = this.getRefNameTaskByType(task)
    const refConext = this.getRefContextTaskByType(task)
    let refDisplayValue = refConext.prefix
    if (refName && !refId) {
      refDisplayValue += refName
    } else if (refName && _.includes([CONS.BACKGROUND_JOB_TYPES.MKP_SYNC_PRODUCT], taskType)) {
      refDisplayValue = refName
    } else if (refId) {
      refDisplayValue += refId
    } else if (refUUID) {
      refDisplayValue += refUUID
    }

    // ดักพิเศษเฉพราะ type นี้ และมี refName ให้แสดง refName (SKU)
    if (_.includes([CONS.BACKGROUND_JOB_TYPES.IMPORT_PRODUCT_STOCK_QTY_EDIT], taskType)) {
      if (!_.isNil(refName)) {
        refDisplayValue = refName
      }
    }

    let refString
    // ดักพิเศษเฉพราะ type นี้ และมี refName ให้แสดง refName (เลขออเดอร์จากระบบอื่น)
    if (_.includes([CONS.BACKGROUND_JOB_TYPES.IMPORT_ORDER_CREATE], taskType)) {
      if (!_.isNil(refName)) {
        refString = refName
      }
    }

    // ดักพิเศษสำหรับ ERP
    if (!_.isNil(refName) && _.includes([CONS.BACKGROUND_JOB_TYPES.ERP_CREATE_DOCUMENT], taskType)) {
      refDisplayValue = refName
    }
    if (!_.isNil(refName) && _.includes([CONS.BACKGROUND_JOB_TYPES.ERP_SYNC_DOCUMENT], taskType)) {
      refDisplayValue = refName
    }
    if (!_.isNil(refName) && _.includes([CONS.BACKGROUND_JOB_TYPES.ERP_SYNC_STATUS_DOCUMENT], taskType)) {
      refDisplayValue = refName
    }

    const { selectedTaskStatusIndex } = this.state
    const containerBgStyle = { backgroundColor: COLORS.WHITE }
    if (selectedTaskStatusIndex === 2) {
      containerBgStyle.backgroundColor = COLORS.FORM_SUCCESS_BG
    } else if (selectedTaskStatusIndex === 1) {
      containerBgStyle.backgroundColor = COLORS.FORM_ERROR_BG
    }
    const contentContainerStyle = hasAction ? { width: maxWidth - 64 } : S.WIDTH_FULL

    const infoMessageText: string | null = bg.getInfoMessageText(task.in)

    return (
      <TouchableOpacity style={{ width: maxWidth }} disabled={!hasAction} onPress={onTaskPress}>
        <View
          style={[
            S.CARDLIKE_BORDERS,
            S.CARDLIKE_BODY,
            S.BG_WHITE,
            S.ROW_MIDDLE_BETWEEN,
            S.PADDING_HORIZONTAL_12,
            { flexWrap: 'wrap' },
            containerBgStyle,
          ]}>
          <View style={[S.COLUMN_LEFT_MIDDLE, { flexWrap: 'wrap', overflow: 'hidden' }, contentContainerStyle]}>
            {/* // เคสพิเศษสำหรับ erp */}
            {_.includes([CONS.BACKGROUND_JOB_TYPES.ERP_SYNC_DOCUMENT], taskType) ||
            _.includes([CONS.BACKGROUND_JOB_TYPES.ERP_CREATE_DOCUMENT], taskType) ||
            (_.includes([CONS.BACKGROUND_JOB_TYPES.ERP_SYNC_STATUS_DOCUMENT], taskType) && refId) ? (
              <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
                <XText variant='inactive'>ออเดอร์:</XText>
                <XText variant='active' bold>{`#${refId}`}</XText>
              </View>
            ) : null}
            {/* <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_START]}>
              <Text style={S.TEXT_ACTIVE}>{job.name}</Text>
            </View> */}
            {/* {displayRefIdText ? (
              <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_START]}>
                <Text style={S.TEXT_ACTIVE}>{displayRefIdText}</Text>
              </View>
            ) : null} */}
            <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN]}>
              <XText variant='inactive'>{refDisplayValue ? refConext.label : ''}</XText>
              <XText
                variant='active'
                bold
                // style={[S.TEXT_ACTIVE, S.TEXT_BOLD]}
                // onPress={() => {
                //   Clipboard.setString(refDisplayValue)
                //   p.op.showToast(`คัดลอก ${refDisplayValue} แล้ว`, 'success')
                // }}
              >
                {refDisplayValue}
              </XText>
            </View>
            {!refString ? null : (
              <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
                <XText variant='inactive'>รหัสอ้างอิง:</XText>
                <XText variant='active'>{refString}</XText>
              </View>
            )}
            {/* <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
              <Text style={S.TEXT_INACTIVE}>{`ดำเนินการแล้ว`}</Text>
              <Text style={S.TEXT_ACTIVE}>{`${doneCount}/${totalCount}`}</Text>
            </View>
            <View style={[S.WIDTH_FULL, S.ROW_CENTER]}>
              <LoadingBar now={doneCount} max={totalCount} height={12} />
            </View> */}
            <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
              <XText variant='inactive'>เข้าคิวเมื่อ:</XText>
              <XText variant='active'>{createAtText}</XText>
            </View>
            {!doneAtText ? null : (
              <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
                <XText variant='inactive'>เสร็จสิ้นเมื่อ:</XText>
                <XText variant='inactive'>{doneAtText}</XText>
              </View>
            )}
            {!errorMessageText ? null : (
              <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
                <XText variant='inactive'>{'ข้อผิดพลาด: '}</XText>
                <XText color={COLORS.BRAND_Danger}>{errorMessageText}</XText>
              </View>
            )}
            {!errorJSONText ? null : (
              <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
                <XText variant='inactive'>{'ที่ผิดพลาด: '}</XText>
                <XText color={COLORS.BRAND_Danger}>{errorJSONText}</XText>
              </View>
            )}
            {!(refName && _.includes([CONS.BACKGROUND_JOB_TYPES.IMPORT_PAYMENT_COD], taskType)) ? null : (
              <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
                <XText variant='inactive' />
                <XText variant='inactive'>{refName}</XText>
              </View>
            )}
            {!infoMessageText ? null : (
              <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
                <XText variant='inactive'>หมายเหตุ:</XText>
                <XText variant='inactive'>{infoMessageText}</XText>
              </View>
            )}

            {/* {MODE === 'dev' && errorMessageText ? (
              <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
                <Text style={[S.TEXT_INACTIVE]}>{'[DEBUG] ข้อผิดพลาด:'}</Text>
                <Text style={[S.TEXT_DANGER]}>{task.ec}</Text>
              </View>
            ) : null} */}
            {/* <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
              <Text style={[S.TEXT_INACTIVE]}>{'เริ่มดำเนินการ:'}</Text>
              <Text style={[S.TEXT_ACTIVE]}>{processAtText}</Text>
            </View> */}
            {/* <View style={S.CARDLIKE_BODY}>
            <Text style={S.TEXT_INACTIVE}>Debug: {JSON.stringify(bgItem)}</Text>
          </View> */}
          </View>
          {hasAction ? (
            <View style={[S.ROW_CENTER, S.WIDTH_34]}>
              <ForwardIcon />
            </View>
          ) : null}
        </View>
      </TouchableOpacity>
    )
  }

  fetchBgJobTasks = async ({ offset, limit }) => {
    await util.setStatePromise(this, { isFetching: true })
    const { job, selectedTaskStatusIndex = 0 } = this.state
    const isFirstFetch = offset === 0

    const requestBody: IBackgroundTaskListApiRequest = {
      store_id: this.getFetchStoreId(),
      job_uuid: this.getFetchJobUUID(),
      // ref_id: 3333,
      // ref_uuid: 'c7a9f222-7731-4502-87cd-6a25e5c6ced7',
      // ref_string: 'test',
      offset,
      limit,
      return_count: isFirstFetch,
      created_within_mins: 10080,
      status: 'all',
      // "types": ["c.bgjob.abc"]
    }

    switch (selectedTaskStatusIndex) {
      case 0:
        requestBody.status = 'inprocess'
        break
      case 1:
        requestBody.status = 'error'
        break
      case 2:
        requestBody.status = 'success'
        break
    }

    const apiOptions: IApiOptions = {
      axiosOptions: {
        retry: 0,
        timeout: 60000,
      },
      isErrorAlertDisabled: true,
      isApiV2: true,
    }
    const fetchedData = {
      count: undefined,
      items: [],
    }
    try {
      const res = await api.post<IBackgroundTaskListApiRequest, IBackgroundTaskListResponse>(
        api.POST_BACKGROUND_TASK_LIST,
        requestBody,
        apiOptions
      )
      if (res.tasks) {
        fetchedData.items = res.tasks
      }
      if (_.isNumber(res.count)) {
        fetchedData.count = res.count
      }
      if (res.job) {
        await util.setStatePromise(this, { job: res.job })
        await util.delay(100)
      }
    } catch (err) {
      // console.log('fetchBgJobs err => ', err)
      if (requestBody.offset === 0) {
        await util.setStatePromise(this, { fetchErrorMessage: util.getErrorMessageFromErrorResponse(err) })
      }
    }

    await util.setStatePromise(this, { isFetching: false, isAlreadyFetchInited: true })

    return {
      items: fetchedData.items,
      count: fetchedData.count,
    }
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _onTaskStatusSegmentChange = (newIndex: number) => {
    if (newIndex !== this.state.selectedTaskStatusIndex) {
      this.setState({ selectedTaskStatusIndex: newIndex, isAlreadyFetchInited: false, fetchErrorMessage: null })
    }
  }

  _renderTaskStatusSegment = () => {
    const { job = null, selectedTaskStatusIndex = 0, isFetching } = this.state
    let inProcessCount = job ? job.total_task_count - (job.done_task_count + job.error_task_count) : null
    const errorCount = job ? job.error_task_count : null
    const successCount = job ? job.done_task_count : null
    // const successCount = job ? doneCount - errorCount : null
    if (inProcessCount < 0) {
      inProcessCount = 0
    }

    const optInProcess = `กำลังดำเนินการ ${inProcessCount ? `(${inProcessCount})` : ''}`
    const optError = `มีข้อผิดพลาด ${errorCount ? `(${errorCount})` : ''}`
    const optSuccess = `เสร็จสิ้น ${successCount ? `(${successCount})` : ''}`

    return (
      <Segment
        disabled={isFetching}
        selectedIndex={selectedTaskStatusIndex}
        onSegmentChange={this._onTaskStatusSegmentChange}
        options={[optInProcess, optError, optSuccess]}
      />
    )
  }

  _renderEmptyListItem = ({ containerWidth = undefined }) => {
    const { isAlreadyFetchInited, isFetching, fetchErrorMessage } = this.state
    if (!isAlreadyFetchInited || isFetching) {
      return null
    }

    if (fetchErrorMessage) {
      return this.renderFetchErrorMessage(containerWidth)
    }

    return (
      <View style={[S.ROW_CENTER, S.PADDING_VERTICAL_12, S.MARGIN_VERTICAL_12, { width: containerWidth }]}>
        <XText variant='inactive'>ไม่มีงานที่อยู่ในสถาะนี้</XText>
      </View>
    )
  }

  // แสดง error message จากการ fetch
  renderFetchErrorMessage = (containerWidth) => {
    const handleshowAlertFetchError = () => util.showAlertFetchError(this.state.fetchErrorMessage)
    return (
      <ErrorListItem
        containerStyle={{ width: containerWidth }}
        onPressShowAlertInfo={handleshowAlertFetchError}
        onPressDoRefresh={this.doRefresh}
      />
    )
  }

  doRefresh = async () => {
    await util.setStatePromise(this, { fetchErrorMessage: null, inProcessCount: null })
    await util.delay(100)
    await this.jobTaskListRef.current.reset()
  }

  _renderJobDetail = () => {
    const { job = null, isFetching } = this.state

    if (!job) {
      // if (isFetching) {
      //   return (
      //     <View style={[S.WIDTH_FULL, S.ROW_CENTER, { height: 100 }]}>
      //       <Spinner />
      //     </View>
      //   )
      // }
      return <View style={{ height: 100 }} />
    }

    const successCount = job.done_task_count || 0
    const errorCount = job.error_task_count || 0
    const doneCount = successCount + errorCount
    const totalCount = job.total_task_count
    const donePercent = !doneCount ? 0 : Math.floor((doneCount / totalCount) * 100)
    // const inProcessCount = totalCount - (doneCount + errorCount)

    const createAtText = job.created_at
    const processAtText = job.all_bgtasks_created_at || '(กำลังรอคิว)'
    const completedAtText = job.all_bgtasks_completed_at || null

    const infoMessageText = bg.getInfoMessageText(job.in)
    // const infoMessageText = !_.isNil(job.in) ? util.getBgTaskErrorText(job.in.msg_key) : null

    let taskStatusText = `กำลังดำเนินการ (${donePercent}%)`
    const statusBgJobStatusStyle = { color: COLORS.TEXT_ACTIVE }
    if (donePercent === 100 || !_.isNil(completedAtText)) {
      taskStatusText = 'เสร็จสิ้น'
      statusBgJobStatusStyle.color = COLORS.BRAND_Success
    }

    const jobType = job.type
    const jobTypeText = util.getBgJobTypeText(jobType)
    // S.TEXT_BOLD
    return (
      <View style={[S.WIDTH_FULL, S.BG_WHITE, S.PADDING_8, { borderBottomWidth: 0.5, borderBottomColor: COLORS.TEXT_INACTIVE }]}>
        <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
          <XText variant='inactive'>ประเภท:</XText>
          <XText variant='active' bold>
            {jobTypeText}
          </XText>
        </View>
        <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN]}>
          {/* <Text style={[S.TEXT_INACTIVE]}>{'รหัสคิว:'}</Text> */}
          <XText variant='inactive' />
          <XText variant='inactive' style={[S.TEXT_SMALL]}>
            {job.name}
          </XText>
        </View>
        <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
          <XText variant='inactive'>สถานะ:</XText>
          <XText bold style={[statusBgJobStatusStyle]}>
            {taskStatusText}
          </XText>
        </View>
        <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
          <XText variant='inactive'>เข้าคิวเมื่อ:</XText>
          <XText variant='active'>{createAtText}</XText>
        </View>
        <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
          <XText variant='inactive'>เริ่มดำเนินการ:</XText>
          <XText variant='active'>{processAtText}</XText>
        </View>
        {completedAtText ? (
          <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
            <XText variant='inactive'>เสร็จสิ้นเมื่อ:</XText>
            <XText color={COLORS.BRAND_Success}>{completedAtText}</XText>
          </View>
        ) : null}
        {infoMessageText ? (
          <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
            <XText variant='inactive'>หมายเหตุ:</XText>
            <XText variant='active'>{infoMessageText}</XText>
          </View>
        ) : null}
        <View style={{ height: 12 }} />
        <XText variant='inactive' textAlign='center'>
          รายการงานแบ่งตามสถานะ
        </XText>
        <View style={[S.ROW_CENTER, S.MARGIN_VERTICAL_4]}>{this._renderTaskStatusSegment()}</View>
      </View>
    )
  }

  render() {
    const { isMounted = false } = this.state
    return (
      <XContainer>
        <XCustomHeader
          headerStyle={{ borderBottomWidth: 0 }}
          title='รายละเอียดคิวงาน'
          headerLeftProps={{ backIcon: true, onPressItem: this._goBack }}
        />
        {this._renderJobDetail()}

        {isMounted ? (
          <InfiniteList<IBgJobTaskItem>
            ref={this.jobTaskListRef}
            key={`${this.state.selectedTaskStatusIndex}`}
            displayMode='list'
            style={S.FLEX}
            renderItem={this.renderBgJobTaskItem}
            onFetchItems={this.fetchBgJobTasks}
            fetchLimit={CONS.PRODUCTS_FETCH_LIMIT_MORE}
            // ListEmptyComponent={this._renderEmptyListItem()}
            renderListEmptyItem={this._renderEmptyListItem}
          />
        ) : null}
      </XContainer>
    )
  }
}

export default BackgroundTaskListView
