import { Component } from 'react'

import _ from 'lodash'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

import CONS from 'x/config/constants'
import { ISelectedStoreMap, IReportMenuItem, IXScreenProps } from 'x/index'

import * as NavActions from 'x/utils/navigation'
import { isImmutable } from 'immutable'
import * as xAcl from 'x/utils/acl'

const moment = require('moment')
require('moment/locale/th')
require('moment-timezone')

const {
  REPORT_ORDER,
  REPORT_HELPER,
  REPORT_RESELLER,
  REPORT_INVENTORY,
  REPORT_PAYMENT,
  REPORT_SHIPPING,
  REPORT_VIEW,
} = CONS.PERM_STORE_HELPER

export interface BaseReportListViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  sellerStoresShouldFetch: boolean
  fetchSellerStores: any
  sellerStores: any
  subscription: any
  getPermissionMemberList: any
  fetchAccounts: () => void
}

export interface BaseReportListViewState {
  isReportProductDailySales: boolean
  isReportHelperSales: boolean
  isReportPayment: boolean
}

// interface IReportMenuItem {
//   label: string
//   iconName?: string
//   iconFamily?: 'Ionicons' | 'FontAwesome' | 'MaterialIcons' | 'MaterialCommunityIcons' | 'AntDesign' | 'Foundation'
//   iconStyle?: Object
//   nav?: () => void
//   perm?: string | string[]
//   isHeader?: boolean
//   permBitIndex?: number
// }

export default abstract class BaseReportListView extends Component<BaseReportListViewProps, BaseReportListViewState> {
  abstract handleOnDownloadFile(url: string, fileName: string): Promise<void>

  menuItems: IReportMenuItem[]

  static displayName = 'BaseUIReportListView'

  constructor(props) {
    super(props)
    this.state = {
      isReportProductDailySales: false,
      isReportPayment: false,
      isReportHelperSales: false,
    }
    const { selectedStore } = props
    // const { navigate } = navigation
    // const { state } = navigation
    // if (!state.params || !state.params.store_id) {
    //   return
    // }

    const store_id = selectedStore.get(`id`)
    if (_.isNil(store_id)) {
      p.op.showConfirmationOkOnly(`เกิดข้อผิดพลาด`, `ไม่พบร้านค้า กรุณาปิดและลองใหม่อีกครั้ง (store_id of null)`)
      return
    }
    this._alertComingSoon = this._alertComingSoon.bind(this)
    this._navToReportStock = this._navToReportStock.bind(this)
    this.menuItems = [
      {
        label: 'ออเดอร์',
        isHeader: true,
      },
      {
        label: 'ยอดขายประจำวัน',
        iconName: 'line-chart',
        iconFamily: 'FontAwesome',
        // nav: () => navigate('TodaySummaryReport'), //Mock
        nav: () => {
          this._navToDailySalesReportView()
          // p.op.aLogEvent(CONS.EVENT_NAME.REPORT_DAILY, { store_id })
          // navigate('DailySalesReportView')
        }, // Mock
        // nav: this._alertComingSoon,
        perm: REPORT_ORDER,
        permBitIndex: CONS.REPORT_SET_BIT_BINARY.report_daily_sales,
      },
      {
        label: 'ยอดขายอย่างละเอียด',
        iconName: 'list-alt',
        iconFamily: 'FontAwesome',
        nav: () => {
          // console.log('sellerStores => ', this.props.sellerStores.toJS())
          this._navToCompleteSalesReportView()
        },
        // nav: this._alertComingSoon,
        perm: REPORT_ORDER,
        permBitIndex: CONS.REPORT_SET_BIT_BINARY.report_complete_sales,
      },
      {
        label: 'ยอดขายผู้ใช้งานและผู้ช่วย',
        iconName: 'account-star',
        iconFamily: 'MaterialCommunityIcons',
        nav: () => this._navToReportHelperSales(),
        perm: REPORT_HELPER,
        permBitIndex: CONS.REPORT_SET_BIT_BINARY.report_helper,
      },
      {
        label: 'ยอดขายตัวแทน',
        iconName: 'users',
        iconFamily: 'FontAwesome',
        // nav: () => navigate('ResellerSummaryReport'), //Mock
        nav: () => {
          // console.log('sellerStores => ', this.props.sellerStores.toJS())
          this._navToReportResellerView()
        },
        perm: REPORT_RESELLER,
        permBitIndex: CONS.REPORT_SET_BIT_BINARY.report_reseller,
      },
      {
        label: 'ยอดขายจำแนกตามช่องทาง',
        iconFamily: 'Ionicons',
        iconName: 'locate',
        // nav: this._alertComingSoon,
        nav: this._navToReportSalesByChannel,
        perm: REPORT_ORDER,
        permBitIndex: CONS.REPORT_SET_BIT_BINARY.report_sales_by_channels,
      },
      {
        label: 'สินค้า',
        isHeader: true,
      },
      {
        label: 'สินค้าคงคลัง',
        iconName: 'archive',
        iconFamily: 'FontAwesome',
        nav: this._navToReportStock,
        perm: REPORT_INVENTORY,
        permBitIndex: CONS.REPORT_SET_BIT_BINARY.report_stock,
        iconStyle: { minWidth: 25 },
      },
      {
        label: 'การปรับคลังสินค้า',
        iconName: 'warehouse',
        iconFamily: 'MaterialCommunityIcons',
        nav: () => this._navToReportWarehouseAdjustment(),
        perm: REPORT_INVENTORY,
        permBitIndex: CONS.REPORT_SET_BIT_BINARY.report_stock_history,
        iconStyle: { minWidth: 25 },
      },
      {
        label: 'การขายสินค้ารายวัน',
        iconName: 'calendar',
        iconFamily: 'FontAwesome',
        nav: () => this._navToReportProductDailySales(),
        // nav: this._alertComingSoon,
        perm: REPORT_INVENTORY,
        permBitIndex: CONS.REPORT_SET_BIT_BINARY.report_product_daily_sales,
        iconStyle: { minWidth: 25 },
      },

      {
        label: 'ชำระ',
        isHeader: true,
      },
      {
        label: 'การรับชำระตามบัญชี',
        iconName: 'bank-transfer-in',
        iconFamily: 'MaterialCommunityIcons',
        nav: () => this._handleOpenReportPayment(),
        iconStyle: { minWidth: 30, fontSize: 25, width: 22, marginLeft: -2 },
        perm: REPORT_PAYMENT,
        permBitIndex: CONS.REPORT_SET_BIT_BINARY.report_payment,
      },

      {
        label: 'จัดส่ง',
        isHeader: true,
      },
      {
        // label: 'รายการจัดส่งวันนี้',
        label: 'ออเดอร์ที่จัดส่งแล้ว',
        iconName: 'truck',
        iconFamily: 'FontAwesome',
        nav: () => {
          // console.log('sellerStores => ', this.props.sellerStores.toJS())
          this._navToShippingReport()
        },
        // nav: this._alertComingSoon,
        perm: REPORT_SHIPPING,
        permBitIndex: CONS.REPORT_SET_BIT_BINARY.report_shipped,
      },

      {
        label: 'ออเดอร์ที่ยังไม่ได้จัดส่ง',
        iconName: 'cubes',
        iconFamily: 'FontAwesome',
        nav: () => {
          // console.log('sellerStores => ', this.props.sellerStores.toJS())
          this._navToRequisitionReport()
        },
        // nav: this._alertComingSoon,
        perm: REPORT_SHIPPING,
        permBitIndex: CONS.REPORT_SET_BIT_BINARY.report_requisition,
      },

      // {
      //   label: 'รายการราคาของสินค้า',  // TO ARTID รายการราคาของสินค้า
      //   iconName: 'tag',
      //   iconFamily: 'FontAwesome',
      //   nav: () => {
      //     // console.log('sellerStores => ', this.props.sellerStores.toJS())
      //     this._navToReportProductPriceList()
      //   },
      //   // nav: this._alertComingSoon,
      // },

      {
        label: 'องค์กร',
        isHeader: true,
        isShowHeader: () => this._showOrganizationHeader(),
      },
      {
        label: 'จำนวนตัวแทนของ Dealer',
        iconName: 'folder-account',
        iconFamily: 'MaterialCommunityIcons',
        nav: async () => await this._downloadOwnerCountResellers(),
        // nav: this._alertComingSoon,
        perm: REPORT_VIEW,
        // permBitIndex: CONS.REPORT_SET_BIT_BINARY.report_complete_sales,
        advancedChecking: () => this.advancedCheckingOwnerCountResellers(),
      },

      {
        label: 'อื่นๆ',
        isHeader: true,
      },
      {
        label: 'รายงาน Excel PEAK Account',
        iconName: 'export',
        iconFamily: 'MaterialCommunityIcons',
        // nav: () => navigate('TodaySummaryReport'), //Mock
        nav: () => this._navToReportImportPeak(), // Mock
        // nav: this._alertComingSoon,
        perm: REPORT_ORDER,
        permBitIndex: CONS.REPORT_SET_BIT_BINARY.report_peak_account,
      },
    ]
  }

  componentWillMount() {
    const { sellerStoresShouldFetch, fetchSellerStores } = this.props
    if (sellerStoresShouldFetch) {
      fetchSellerStores({})
    }
  }

  _showOrganizationHeader = () => {
    // TODO :: ตอนนี้มีแค่ SomSai ใช้
    const { selectedStore } = this.props
    const isOrgOwner = util.isOrgOwnerStore(selectedStore)
    if (!isOrgOwner) {
      return false
    }
    return true
  }

  _alertComingSoon() {
    p.op.alert('เร็วๆ นี้')
  }

  advancedCheckingOwnerCountResellers = () => {
    const { selectedStore } = this.props
    const isOrgOwner = util.isOrgOwnerStore(selectedStore)
    if (!isOrgOwner) {
      return false
    }
    return true
  }

  _downloadOwnerCountResellers = async () => {
    const { selectedStore } = this.props
    const org_id = util.getOrgId(selectedStore)
    if (_.isNil(org_id) || !org_id) {
      p.op.showConfirmationOkOnly(``, `คุณไม่สามารถใช้งานฟีเจอร์นี้ได้ org_id is null `)
      return
    }
    const url = util.getReportOwnerCountResellersExcelUrl({
      org_id,
    })
    const startDateTime = moment().format('DD-MM-YYYY_H-mm-ss')
    const fileName = `OwnerCountResellers_${startDateTime}.xlsx`
    await this.handleOnDownloadFile(url, fileName)
  }

  _navToReportImportPeak() {
    const { navigation, selectedStore } = this.props
    // const { state, dispatch } = navigation
    // if (!state.params || !state.params.store_id || !isImmutable(sellerStores)) {
    //   return
    // }
    const store_id = selectedStore.get('id')
    navigation.dispatch(NavActions.navToReportImportPeak({ store_id }))
  }

  _navToReportStock() {
    const { selectedStore, navigation } = this.props
    // const { state, dispatch } = navigation
    // if (!state.params || !state.params.store_id) {
    //   return
    // }

    const store_id = selectedStore.get(`id`)
    // p.op.aLogEvent(CONS.EVENT_NAME.REPORT_STOCK, { s: store_id })
    navigation.dispatch(NavActions.navToReportStockView({ store_id }))
    // NavActions.navToReportStockView({ store_id })
  }

  _navToReportWarehouseAdjustment() {
    const { selectedStore, navigation } = this.props
    // console.log('_navToReportWarehouseAdjustment => ')
    const store_id = selectedStore.get(`id`)
    navigation.dispatch(NavActions.navToReportWarehouseAdjustment({ store_id }))
  }

  _navToCompleteSalesReportView() {
    const { selectedStore, navigation } = this.props
    // const { state, dispatch } = navigation
    if (_.isNil(selectedStore) || _.isNil(selectedStore.get(`id`))) {
      return
    }
    const store_id = selectedStore.get(`id`)
    navigation.dispatch(NavActions.navToCompleteSalesReportView({ store_id }))
    // p.op.aLogEvent(CONS.EVENT_NAME.REPORT_REQUISITION, { store_id })
  }

  _navToDailySalesReportView() {
    const { selectedStore, navigation } = this.props
    // const { state, dispatch } = navigation
    if (_.isNil(selectedStore) || _.isNil(selectedStore.get(`id`))) {
      return
    }
    const store_id = selectedStore.get(`id`)
    navigation.dispatch(NavActions.navToDailySalesReportView({ store_id }))
    // p.op.aLogEvent(CONS.EVENT_NAME.REPORT_REQUISITION, { store_id })
  }

  _navToReportProductDailySales() {
    const { selectedStore, navigation } = this.props
    const store_id = selectedStore.get(`id`)
    navigation.dispatch(NavActions.navToReportProductDailySalesView({ store_id }))
  }

  _handleOpenReportProductDailySales = async () => {
    util.setStatePromise(this, {
      isReportProductDailySales: true,
    })
  }

  _handleCloseReportProductDailySales = async () => {
    util.setStatePromise(this, {
      isReportProductDailySales: false,
    })
  }

  _handleOpenReportPayment = async () => {
    util.setStatePromise(this, {
      isReportPayment: true,
    })
  }

  _handleCloseReportPayment = async () => {
    util.setStatePromise(this, {
      isReportPayment: false,
    })
  }

  _handleOpenisReportHelperSales = async () => {
    util.setStatePromise(this, {
      isReportHelperSales: true,
    })
  }

  _handleCloseisReportHelperSales = async () => {
    util.setStatePromise(this, {
      isReportHelperSales: false,
    })
  }

  _navToReportResellerView() {
    const { selectedStore, navigation } = this.props
    // const { state, dispatch } = navigation
    // if (!state.params || !state.params.store_id || !isImmutable(sellerStores)) {
    //   return
    // }
    const storeId = selectedStore.get(`id`)
    // p.op.aLogEvent(xCONS.EVENT_NAME.REPORT_RESELLER, { store_id })
    navigation.dispatch(NavActions.navToReportResellerView({ store_id: storeId }))
  }

  _navToReportHelperSales() {
    const { selectedStore, navigation } = this.props
    // const { state, dispatch } = navigation
    // if (!state.params || !state.params.store_id || !isImmutable(sellerStores)) {
    //   return
    // }
    const storeId = selectedStore.get(`id`)
    // p.op.aLogEvent(xCONS.EVENT_NAME.REPORT_RESELLER, { store_id })
    navigation.dispatch(NavActions.navToReportHelperSales({ store_id: storeId }))
  }

  _navToReportSalesByChannel = () => {
    const { navigation, selectedStore } = this.props
    const { dispatch } = navigation
    const storeId = selectedStore.get(`id`)
    p.op.aLogEvent(CONS.EVENT_NAME.REPORT_SALES_BY_CHANNELS, { store_id: storeId })
    dispatch(NavActions.navToReportSalesByChannelView({ store_id: storeId }))
  }

  _navToShippingReport = () => {
    const { selectedStore, navigation } = this.props
    const { dispatch } = navigation
    const store_id = selectedStore.get(`id`)
    p.op.aLogEvent(CONS.EVENT_NAME.REPORT_SHIPPING, { s: store_id })
    dispatch(NavActions.navToReportShippingView({ store_id }))
  }

  _navToRequisitionReport() {
    const { selectedStore, navigation } = this.props
    // const { state, dispatch } = navigation
    // if (!state.params || !state.params.store_id || !isImmutable(sellerStores)) {
    //   return
    // }
    const store_id = selectedStore.get(`id`)

    p.op.aLogEvent(CONS.EVENT_NAME.REPORT_REQUISITION, { store_id })

    // p.op.showConfirmation(
    //   'ดาวน์โหลด',
    //   'คุณต้องการดาวน์โหลดรายการสินค้า\nของทุกออเดอร์ที่ยังไม่ได้จัดส่งหรือไม่',
    //   () => {
    navigation.dispatch(NavActions.navToReportRequisitionView({ store_id }))
    //   }
    // )
  }

  _navToReportProductPriceList() {
    const { sellerStores, navigation } = this.props
    // const { state, dispatch } = navigation
    const params = util.getNavParams(this.props)
    if (!params || !params.store_id || !isImmutable(sellerStores)) {
      return
    }
    const { store_id } = params

    p.op.aLogEvent(CONS.EVENT_NAME.REPORT_REQUISITION, { store_id })

    // p.op.showConfirmation(
    //   'ดาวน์โหลด',
    //   'คุณต้องการดาวน์โหลดรายการสินค้า\nของทุกออเดอร์ที่ยังไม่ได้จัดส่งหรือไม่',
    //   () => {
    navigation.dispatch(NavActions.navToReportProductPriceList({ store_id }))
    //   }
    // )
  }

  _onPressToNav = async (menu: IReportMenuItem) => {
    if (p.op.isWeb()) {
      try {
        const { navigation } = this.props
        // @ts-ignore
        navigation.popToTop()
        await util.delay(200)
      } catch (error) {
        //
      }
    }
    const { label, nav = null } = menu
    // TODO :: หากมีดักเยอะกว่านี้ ปรับ label ให้ไปประกาศใน cons
    if (label === 'ยอดขายประจำวัน') {
      const canDo =
        xAcl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_STOCK_VIEW) &&
        xAcl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_COST)
      const isAuthenticated = xAcl.isSelectedStoreOwner()
      if (!isAuthenticated && !canDo) {
        p.op.showConfirmationOkOnly('ไม่สามารถดูข้อมูลได้', 'เนื่องจากขาดสิทธิ์ในการใช้งาน', () => {})
      }
    }
    if (!_.isNil(nav)) {
      nav()
    }
  }
}
