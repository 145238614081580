/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
import React from 'react'
import { ISelectedStoreMap, ISubscriptionPackageList, SubscriptionPackageListItems } from 'x/index'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import _ from 'lodash'

interface IBaseSelectPackageViewProps {
  selectedStore: ISelectedStoreMap
}

interface IBaseSelectPackageViewState {
  selectedMonthIndex: number
  subscriptionPackageList: ISubscriptionPackageList
  packageList: SubscriptionPackageListItems[]
}

export default abstract class BaseSelectPackageView extends React.Component<IBaseSelectPackageViewProps, IBaseSelectPackageViewState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>
  // mainPackageItems: any

  constructor(props) {
    super(props)
    this.state = {
      selectedMonthIndex: 0,
      subscriptionPackageList: null,
      packageList: null,
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
    // @ts-ignore
    // eslint-disable-next-line react/no-unused-class-component-methods
    // this.mainPackageItems = xCONS.SUBSCRIPTION_MAIN_PACKAGE_ITEMS.map((item) => ({
    //   ...item,
    //   label: p.op.t(`Subscription.main.${item.key}`),
    // }))
  }

  async componentDidMount() {
    const { subscriptionPackageList } = this._getParams()
    console.log('subscriptionPackageList => ', subscriptionPackageList)
    await util.setStatePromise(this, { subscriptionPackageList })
    await this._onSegmentSelectedMonthChange(0)
    // await this._setPackageList()
  }

  _getParams = () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    return params
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  goBack = () => {
    // @ts-ignore
    util.navGoBack(this.props)
  }

  _onSegmentSelectedMonthChange = async (newIndex: number) => {
    const { subscriptionPackageList } = this.state
    const itams = subscriptionPackageList.items
    const packageList = []
    itams.forEach((itam) => {
      const billingCycle = itam.billing_cycle
      const isMainPackage = itam.is_main_package
      const billingCycleMap = {
        0: 1,
        1: 3,
        2: 6,
      }
      const selectedBillingCycle = billingCycleMap[newIndex] || 12
      if (billingCycle === selectedBillingCycle && isMainPackage) {
        packageList.push(itam)
      }
    })
    await util.setStatePromise(this, {
      selectedMonthIndex: newIndex,
      packageList,
    })
  }

  _getAddOnTskusByKey = (itam: SubscriptionPackageListItems, key: string) => {
    const { subscriptionPackageList } = this.state
    const dataJson = itam.data_json
    const { type } = dataJson

    const addonTskuBySubscriptionType = subscriptionPackageList.addon_tsku_by_subscription_type
    const subscriptionTypeList = addonTskuBySubscriptionType.find((data) => data.type === type)
    const { tskus } = subscriptionTypeList
    // console.log('subscriptionTypeList => ', subscriptionTypeList)
    const ADD_ON_LIST = []
    switch (key) {
      case 'm_o_count':
        tskus.forEach((tsku) => {
          if (tsku.includes('T-ADDON-QUOTA-ORDER')) {
            const itamByTsku = subscriptionPackageList.items.find((itamx) => itamx.tsku === tsku)
            ADD_ON_LIST.push(itamByTsku)
          }
        })
        // const filteredTsku = subscriptionTypeList.tskus.filter((item) => item.includes('T-ADDON-QUOTA-ORDER'))
        // console.log('filter => ', filteredTsku)
        // ADD_ON_LIST.push(...filteredTsku) // Add the filtered tskus to the ADD_ON_LIST array
        break

      default:
        break
    }
    // console.log('ADD_ON_LIST => ', ADD_ON_LIST)
    return ADD_ON_LIST
  }

  _getAddOnSumTotalCount = (items: SubscriptionPackageListItems[]) => {
    // console.log('_getAddOnSumTotalCount => ', items)
    let sumTotal = 0
    items.forEach((item) => {
      const itemSubtype = item.item_subtype
      const selectedCount = _.has(item, 'ui_selected_count') ? item.ui_selected_count : 0
      switch (itemSubtype) {
        case 'order_quota':
          // eslint-disable-next-line no-case-declarations
          const count = item.data_json.m_o_count
          sumTotal += count * selectedCount
          break

        default:
          break
      }
    })
    return sumTotal
  }

  _callbackSelectedAddOn = (tskuItems: SubscriptionPackageListItems[]) => {
    // console.log('_callbackSelectedAddOn => ', tskuItems)
    const { subscriptionPackageList } = this.state
    const packageList = subscriptionPackageList.items
    // console.log('packageList => // ', packageList)
    const newPackageList = []
    packageList.forEach((pack) => {
      let newPack = null
      tskuItems.forEach((tsku) => {
        if (tsku.sku === pack.sku) {
          newPack = tsku
        }
      })
      // console.log('newPack => ', newPack)
      if (!_.isNil(newPack)) {
        newPackageList.push(newPack)
      } else {
        newPackageList.push(pack)
      }
    })
    const newSubscriptionPackageList = _.clone(subscriptionPackageList)
    newSubscriptionPackageList.items = newPackageList
    this.setState({ subscriptionPackageList: newSubscriptionPackageList })
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _navToPaymentSubscriptionPackageView = () => {
    // @ts-ignore
    const { navigation } = this.props
    // @ts-ignore
    navigation.dispatch(NavActions.navToPaymentSubscriptionPackageView({}))
  }

  _onPressSelectedPackage = (item: SubscriptionPackageListItems) => {
    const params = this._getParams()
    const { callback } = params
    if (_.isFunction(callback)) {
      callback(item)
      this.goBack()
    } else {
      this.goBack()
    }
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _navToAddOnPackageView = (tskuItems: SubscriptionPackageListItems[]) => {
    const { subscriptionPackageList, selectedMonthIndex } = this.state
    const billingCycleMap = {
      0: 1,
      1: 3,
      2: 6,
    }
    const selectedMonth = billingCycleMap[selectedMonthIndex] || 12
    // @ts-ignore
    const { navigation } = this.props
    // @ts-ignore
    // navigation.dispatch(
    //   NavActions.navToSelectAddOnPackageView({
    //     subscriptionPackageList,
    //     tskuItems,
    //     selectedPackages: null,
    //     callback: (TskuItems: SubscriptionPackageListItems[]) => this._callbackSelectedAddOn(TskuItems),
    //   })
    // )
  }
}
