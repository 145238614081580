import React from 'react'
import { Text, View, TouchableOpacity, Dimensions, Image, Platform, Clipboard } from 'react-native'

import _ from 'lodash'
// import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import BaseChannelListView from 'x/modules/channels/BaseChannelListView'
import XCustomHeader from 'xui/components/XCustomHeader'
// import { Input } from 'react-native-elements'
import { STYLES, COLORS } from 'x/config/styles'
import { Button } from 'react-native-elements'
import XIcon from 'xui/components/XIcon'
import { IMKPChannelDetail } from 'x/index'
import p from 'x/config/platform-specific'
import HelpButton from 'xui/components/HelpButton'
import * as NavActions from 'x/utils/navigation'
import { isImmutable } from 'immutable'
import VStack from 'xui/components/VStack'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import XButton from 'xui/components/XButton'
import MkpLogo from 'xui/components/MkpLogo'
import DeleteMkpChannelButton from 'xui/components/MkpChannel/DeleteMkpChannelButton'
import XOverlay from 'xui/components/XOverlay'
import XInput from 'xui/components/XInput'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'

const VIEW_HEIGHT_TEN = <View style={{ height: 10 }} />

export default abstract class BaseUIChannelListView extends BaseChannelListView {
  abstract _renderMain: () => JSX.Element

  // abstract renderImageDocumentUpload: () => JSX.Element
  abstract goBack: () => void

  // abstract navToToAddressViewFromStoreMyView: (isPrimary: boolean, address?: ISelectedAddresses) => void
  // abstract navToChannelsView: (channel?: IMKPChannelDetail) => void

  abstract renderOrdersSummaryDonut: () => JSX.Element

  // _testABC = () => {
  //   // const url = 'https://app.xselly.com/_mkp/authstatus.html?status=authsuccess'
  //   // const url = 'https://app.xselly.com/_mkp/authstatus.html?status=cancelauthsuccess'
  //   const url = 'https://app.xselly.com/_mkp/authstatus.html?status=cancelauthfailed&reason=ER003_03'
  //   // const url = 'https://app.xselly.com/_mkp/authstatus.html?status=authfailed'

  //   const regexReason = /reason=(\w+)/
  //   const matchesReason = url.match(regexReason)
  //   const authReasonStatus = matchesReason ? matchesReason[1] : null

  //   console.log('authReasonStatus => ', authReasonStatus)
  //   const STATUS_MKP_SHOWTOAST = ['authsuccess', 'cancelauthsuccess']
  //   if (authReasonStatus) {
  //     console.log('PASS')
  //     p.op.showConfirmationOkOnly(p.op.t('mkp_auth_status.connection_feil'), p.op.t(`mkp_auth_status.${authReasonStatus}`))
  //   } else {
  //     const regex = /status=(\w+)/
  //     const matches = url.match(regex)
  //     const authStatus = matches ? matches[1] : null
  //     if (_.find(STATUS_MKP_SHOWTOAST, (sta) => sta === authStatus)) {
  //       console.log('OK')
  //       p.op.showToast(p.op.t(`mkp_auth_status.${authStatus}`), 'success')
  //     } else {
  //       console.log('NO')
  //       p.op.showConfirmationOkOnly(p.op.t('mkp_auth_status.connection_feil'), null)
  //     }
  //   }
  // }

  _renderHeaderRight = () => {
    const { isRenderWebView } = this.state
    if (isRenderWebView && !p.op.isWeb()) {
      return null
    }
    const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />
    // return <XButton variant='ghost' leftIcon={<XIcon family='AntDesign' name='plus' />} onPress={() => this._testABC()} />
    return (
      <HStack>
        <XHelpModal
          key='วิธีเชื่อมช่องทางการขาย'
          headerTitle='วิธีเชื่อมช่องทางการขาย'
          FAQ={FAQ.MKP_CONNECT_FAQ}
          initiateOpenIndex={[0, 1]}
          renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
        />
        <XButton variant='ghost' leftIcon={<XIcon family='AntDesign' name='plus' />} onPress={() => this.navToChannelsView()} />
      </HStack>
    )
    // return (
    //   <TouchableOpacity onPress={() => this.navToChannelsView()}>
    //     <XIcon family='AntDesign' name='plus' style={{ flex: 0 }} />
    //   </TouchableOpacity>
    // )
  }

  renderCustomHeader = () => {
    const isGoBackButtonHidden = util.getNavParam(this.props, 'isGoBackButtonHidden', false)
    const { isRenderWebView } = this.state

    const leftBtn = isGoBackButtonHidden
      ? undefined
      : {
          backIcon: true,
          onPressItem: () => {
            if (isRenderWebView) {
              util.setStatePromise(this, { isRenderWebView: false })
            } else {
              this.goBack()
            }
          },
        }
    return <XCustomHeader title='รายการ ช่องทางขาย' headerLeftProps={leftBtn} headerRight={this._renderHeaderRight()} />
  }

  _onPressFooter = (channel: IMKPChannelDetail) => {
    // pg_ids ไม่ได้ใช้ตรงนี้แล้ว
    // if (channel.pg_ids.length < 1) {
    //   const { navigation, selectedStore } = this.props
    //   navigation.dispatch(
    //     NavActions.navToChannelAssignProductGroups({
    //       store_id: selectedStore.get(`id`),
    //       ug_id: channel.id,
    //       callBackMKP: (pgs: { pg_id: number }[]) => this._callBackFromAssignProductGroups(channel, pgs),
    //     })
    //   )
    // }

    const IS_REMAINING_TIME = channel.expire_time === '' ? true : util.isRemainingTime(channel.expire_time)
    if (!channel.is_active) {
      this._callAuthChannel(channel)
    } else if (IS_REMAINING_TIME) {
      // ยังไม่หมดอายุ (token ยังไม่ expire)
      this._callAuthChannel(channel)
    } else {
      // channel is active แต่ token หมดอายุแล้ว
      p.op.showConfirmationOkOnly(``, `เกิดข้อผิดพลาด กรุณาติดต่อทีมงาน`)
    }
  }

  navToChannelsView = (channel = null) => {
    const { navigation, selectedStore, subscription } = this.props
    const { channelList } = this.state
    // const type = subscription.get(`type`)
    const mode = _.isNil(channel) ? 'CREATE' : 'VIEW'
    // const mkpQuota = subscription.has('mkp_quota') ? subscription.get('mkp_quota') : null
    // console.log('mkpQuota => ', mkpQuota)

    if (mode === 'CREATE') {
      // // mode CREATE ต้องเช็กแพ็กเกจก่อน
      // // ถ้า Quota หาย
      // if (_.isNil(mkpQuota)) {
      //   p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'กรุณาปิดหน้าต่างนี้ แล้วลองใหม่อีกครั้ง (mkp quota is null)')
      //   return
      // }
      // // ถ้าไม่มี Quota เลย
      // if (mkpQuota === 0) {
      //   p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
      //   return
      // }
      // // ถ้า Quota เต็ม
      // if (!_.isNil(channelList) && channelList.length >= mkpQuota) {
      //   p.op.alert(p.op.t('Subscription.warning.packageFull'), p.op.t('Subscription.warning.insufficientTypeMsg'))
      //   return
      // }
      navigation.dispatch(
        NavActions.navToChannelView({
          store_id: selectedStore.get('id'),
          mode,
          channel,
          callBackURL: (url: string, channelFromCallbBack: IMKPChannelDetail) => this._callBackURL(url, channelFromCallbBack),
          callBackUpdateChannelList: (channels: IMKPChannelDetail[]) => this._callBackUpdateChannelList(channels),
          callBackUpdateChannel: (Channel: IMKPChannelDetail) => this._callBackUpdateChannel(Channel),
        })
      )
    } else {
      navigation.dispatch(
        NavActions.navToChannelView({
          store_id: selectedStore.get(`id`),
          mode,
          channel,
          callBackURL: (url: string, channelFromCallbBack: IMKPChannelDetail) => this._callBackURL(url, channelFromCallbBack),
          callBackUpdateChannelList: (channels: IMKPChannelDetail[]) => this._callBackUpdateChannelList(channels),
          callBackUpdateChannel: (Channel: IMKPChannelDetail) => this._callBackUpdateChannel(Channel),
        })
      )
    }
  }

  _callBackFromAssignProductGroups = (channel: IMKPChannelDetail, pgs: { pg_id: number }[]) => {
    // console.log(`pgs => `, pgs)
    const { selectedStore, updateNewChannelList } = this.props
    const channels = isImmutable(selectedStore.get(`channels`)) ? selectedStore.get(`channels`).toJS() : selectedStore.get(`channels`)
    // console.log(`_callBackFromAssignProductGroups `, channels)
    const newChannel = channel
    const newPGs = []
    pgs.forEach((num: { pg_id: number }) => {
      const newPG = { id: num.pg_id }
      newPGs.push(newPG)
    })
    newChannel.pg_ids = newPGs

    const newChannels = []
    // @ts-ignore
    channels.forEach((ch: IMKPChannelDetail) => {
      if (ch.id === channel.id) {
        newChannels.push(newChannel)
      } else {
        newChannels.push(ch)
      }
    })

    updateNewChannelList(newChannels)

    this._callAuthChannel(newChannel)
  }

  _renderChannelNoConnectionView = (channel: IMKPChannelDetail) => {
    // const isHasChannelStatus = !_.isNil(channel.status) ? true : false
    // console.log(`isHasChannelStatus `, isHasChannelStatus)
    // channelStatus = 999 คือ ไม่มีเลข status
    // const channelStatus = channel.is_active
    const MKP_NAME = util.getMKPName(channel.mkp_id)
    let CHANNEL_STATUS_COLOR = COLORS.TEXT_INACTIVE
    let STATUS_TEXT_LABLE = '-'
    const VIEW_HEIGHT = 110
    let FOOTER_TEXT = 'ดำเนินการการเชื่อมต่อ'
    // const IS_CONNECTED = channelStatus === 3 // auth เรียบร้อยแล้ว
    const IS_REMAINING_TIME = channel.expire_time === '' ? true : util.isRemainingTime(channel.expire_time)
    // const IS_ACTIVE = channel.is_active
    // console.log('IS_REMAINING_TIME => ', IS_REMAINING_TIME)
    if (!IS_REMAINING_TIME) {
      STATUS_TEXT_LABLE = `การเชื่อมต่อหมดอายุ`
      CHANNEL_STATUS_COLOR = COLORS.RED
      FOOTER_TEXT = `ต่ออายุการเชื่อมต่อ ${MKP_NAME}`
    } else {
      STATUS_TEXT_LABLE = 'ยังไม่ได้เชื่อมต่อ'
      CHANNEL_STATUS_COLOR = COLORS.RED
    }
    return (
      <Box h={VIEW_HEIGHT} pb='1'>
        {/* <View style={{ flex: 1, height: 40, justifyContent: 'center', alignItems: 'center' }}>
          <XText variant='inactive' bold>
            ข้อมูลการเชื่อมต่อ
          </XText>
        </View> */}
        <Box flex={1} flexDirection='row' alignItems='center' px='2'>
          <Box justifyContent='flex-start'>
            <XText variant='inactive'>สถานะการเชื่อมต่อ</XText>
          </Box>
          {!IS_REMAINING_TIME ? (
            <Box style={{ justifyContent: 'flex-start' }}>
              <HelpButton message={`กรุณากดปุ่ม "${FOOTER_TEXT}" เพื่อดำเนินการต่อ`} />
            </Box>
          ) : (
            <Box style={{ justifyContent: 'flex-start' }}>
              <HelpButton message={`กรุณากดปุ่ม "${FOOTER_TEXT}" เพื่อดำเนินการต่อ`} />
            </Box>
          )}
          <Box style={{ flex: 1, alignItems: 'flex-end' }}>
            <XText bold color={CHANNEL_STATUS_COLOR}>
              {STATUS_TEXT_LABLE}
            </XText>
          </Box>
        </Box>
        <XButton mx='2' onPress={() => this._onPressFooter(channel)}>
          <XText color='white' bold>
            {FOOTER_TEXT}
          </XText>
        </XButton>
      </Box>
    )
  }

  _renderBoxView = (count: number, text: string) => (
    <View
      style={{
        borderColor: COLORS.TEXT_INACTIVE,
        borderWidth: 0.8,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
      }}>
      <Text
        style={{
          fontSize: STYLES.FONT_SIZE_LARGER,
          color: COLORS.TEXT_ACTIVE,
        }}>
        {count}
      </Text>
      <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>{text}</Text>
    </View>
  )

  _renderChannelBody = (channel: IMKPChannelDetail) => {
    const MKP_NAME = util.getMKPName(channel.mkp_id)
    // const TIME_LEFT = util.remainingDay(channel.expire_time)
    // console.log('TIME_LEFT => , ', TIME_LEFT)
    // const mkpChannelName = util.getMKPChannelName(channel.id)
    // let isShowWarning = false
    let WarningText = `เชื่อมต่อกับ ${MKP_NAME} แล้ว`
    let textColor = COLORS.GREEN
    // let warningText = `อีก ${TIME_LEFT} วัน การเชื่อมต่อจะหมดอายุการใช้งาน`
    // let warningBackground = COLORS.BRAND_Warning
    // const TIME_SECOND_LEFT = util.remainingSecond(channel.expire_time)
    // ถ้า auth token เหลือเวลาน้อยกว่า 17 วัน ให้แจ้งเตือนว่าต่ออายุการเชื่อมต่อ
    // if (TIME_LEFT < 17) {
    //   WarningText = 'การเชื่อมต่อใกล้หมดอายุแล้ว'
    //   isShowWarning = true
    //   textColor = COLORS.ORANGE
    // }
    // console.log('channel => ', channel)
    if (!channel.is_active || !channel.is_auth) {
      WarningText = `ไม่ได้เชื่อมต่ออยู่กับ ${MKP_NAME}`
      textColor = COLORS.RED
      // isShowWarning = true
    }
    // let BOX_HEIHT = 70
    // if (isShowWarning) {
    //   BOX_HEIHT = 110
    // }
    return (
      <Box py='1' p='2'>
        {/* <View style={{ flex: 1, height: 40, justifyContent: 'center', alignItems: 'center' }}>
          <XText variant='inactive' bold>
            ข้อมูลการเชื่อมต่อ
          </XText>
        </View> */}
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <View style={{ width: 100, flex: 1 }}>
            <Image
              style={{ borderRadius: 7, width: 60, height: 60 }}
              source={{
                uri: channel.img_uri,
              }}
            />
          </View>
          <View style={{ flex: 3, alignItems: 'flex-end', justifyContent: 'flex-end', height: 60 }}>
            <XText variant='active' bold>
              {channel.mkp_shop_name}
            </XText>
            <XText bold color={textColor}>
              {WarningText}
            </XText>

            {/* {isShowWarning || TIME_SECOND_LEFT < 1 ? (
              <XText bold color={COLORS.RED}>
                {WarningText}
              </XText>
            ) : (
              <XText bold color={COLORS.GREEN}>{`เชื่อมต่อกับ ${MKP_NAME} แล้ว`}</XText>
            )} */}
          </View>
        </View>

        {/* {isShowWarning ? VIEW_HEIGHT_TEN : null} */}

        {/* // ปิดใช้งาน ยืนยันการเชื่อมต่อสำหรับ V2 */}
        {/* {channel.show_upgrade_auth_button ? (
          <XButton
            mx='2'
            mt='2'
            onPress={() =>
              p.op.showConfirmation(
                '',
                'เพื่อดำเนินการเชื่อมต่อกับ Shopee กรุณาทำ 2 ขั้นตอนนี้ หลังจากที่กดปุ่ม "ดำเนินการต่อ" ด้านล่างแล้ว\n' +
                  '1. เลือกภูมิภาค TH (Thailand) แล้วล็อกอินด้วย Username และ Password ของ Shopee\n' +
                  '2. กดปุ่ม "Confirm Authorization"',
                () => this._authV2Provisory(channel),
                () => null,
                'ตกลง',
                'ยกเลิก'
              )
            }>
            <XText color='white' bold>
              ยืนยันการเชื่อมต่อสำหรับ V2
            </XText>
          </XButton>
        ) : null} */}

        {/* {isShowWarning && channel.is_active ? (
          <XButton mx='2' mt='2' onPress={() => this._onPressFooter(channel)}>
            <XText color='white' bold>
              {`ต่ออายุการเชื่อมต่อ ${MKP_NAME}`}
            </XText>
          </XButton>
        ) : null} */}
      </Box>
    )
  }

  _navToProductListMkpView = (channel) => {
    const { navigation, selectedStore } = this.props
    navigation.dispatch(
      NavActions.navToProductListViewMkpView({
        store_id: selectedStore.get('id'),
        // mkp_channel_id: channel.id,
        mkp_ch_id: channel.id,
        mkp_id: channel.mkp_id,
        mode: 'MKP_MAPPING',
      })
    )
  }

  _renderHeaderCard = (channel: IMKPChannelDetail) => {
    // console.log(`channel => `, channel)
    const ICON_SIZE = STYLES.MKP_ICON_SIZE_NORMAL
    const isHasConnection = true
    return (
      <Box flexDirection='row' justifyContent='center' alignItems='center' px='2' w='full'>
        {/* VIEW IMG */}
        <Box>{isHasConnection ? <MkpLogo mkpId={channel.mkp_id} width={ICON_SIZE} height={ICON_SIZE} /> : <Box w='10' h='10' />}</Box>
        {/* VIEW TEXT  */}
        <Box flex={1} justifyContent='center' pl='2'>
          <XText fontSize='md' bold variant='active' numberOfLines={1}>
            {channel.name}
          </XText>
          {/* <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>{``}</Text> */}
        </Box>
        {/* // TouchableOpacity SETTING ICON */}
        <TouchableOpacity
          onPress={() => this._navToProductListMkpView(channel)}
          style={{ width: 40, height: 50, alignItems: 'center', justifyContent: 'center', paddingBottom: 0, marginRight: -8 }}>
          <XIcon family='FontAwesome' name='shopping-cart' style={{ flex: 0, color: COLORS.APP_MAIN }} />
        </TouchableOpacity>
        <View style={{ width: 10 }} />
        <TouchableOpacity
          onPress={() => this.navToChannelsView(channel)}
          style={{ width: 40, height: 50, alignItems: 'center', justifyContent: 'center', paddingBottom: 0, marginRight: -8 }}>
          <XIcon family='Ionicons' name='settings' style={{ flex: 0, color: COLORS.APP_MAIN }} />
        </TouchableOpacity>
      </Box>
    )
  }

  _renderWraningAuthMKP = (channel: IMKPChannelDetail) => {
    const MKPChannelWraning = util.getMKPChannelWraning(channel)
    if (_.isNil(MKPChannelWraning) || !MKPChannelWraning.isShowWarning) {
      return null
    }
    const { warningText, warningBackground } = MKPChannelWraning
    return (
      <HStack justifyContent='center' alignItems='center' bgColor={warningBackground} w='full'>
        <XText bold textAlign='center' color='white' p='1'>
          {warningText}
        </XText>
      </HStack>
    )
  }

  _renderFooter = (channel: IMKPChannelDetail) => {
    const isActive = channel.is_active

    // const isAuth = channel.is_auth
    // const mkpCd = channel.mkp_id
    // const TIME_LEFT = util.remainingDay(channel.expire_time)
    // let isTimeOut = false
    // const isTiktok = mkpCd === 3
    // // ถ้าเวลาเหลือน้อยกว่า 17 วันและไม่ใช่ Tiktok สามารถ re auth ได้
    // // หรือ ถ้าเป็น tiktok และมีวันใช้งานเหลือน้อยกว่า 6 วันก็สามาร re auth ได้
    // if ((TIME_LEFT < 17 && !isTiktok) || (isTiktok && TIME_LEFT < 7)) {
    //   isTimeOut = true
    // }
    // // console.log('channel => ', channel)
    // if (isActive && isAuth && !isTimeOut) {
    //   return null
    // }

    const canReauth = util.isMkpchannelCanReauth(channel)

    return (
      <HStack w='full' mt='2' p='2'>
        {canReauth ? (
          <XButton flex={1} variant='outline' onPress={() => this._onPressReAuthBtn(channel)}>
            เชื่อมต่ออีกครั้ง
          </XButton>
        ) : null}
        {/* {!isActive || !isAuth || isTimeOut ? (
          <XButton flex={1} variant='outline' onPress={() => this._onPressReAuthBtn(channel)}>
            เชื่อมต่ออีกครั้ง
          </XButton>
        ) : null} */}
        {!isActive ? <HStack w='2' /> : null}

        {!isActive ? (
          <DeleteMkpChannelButton channel={channel} selectedStore={this.props.selectedStore} onDeleted={() => this._getChannels()} />
        ) : null}
      </HStack>
    )
  }

  _onPressReAuthBtn = async (channel: IMKPChannelDetail) => {
    const { mkp_id } = channel
    if (mkp_id !== 2 && mkp_id !== 4 && mkp_id !== 5) {
      this._callAuthChannel(channel)
      return
    }
    // IF Lazada MKP
    if (mkp_id === 2) {
      // p.op.showConfirmationOkOnly('', 'กรุณาติดต่อทีมงาน')
      this._navToReauthLazada(channel.id)
      return
    }
    // IF LINE MKP
    if (mkp_id === 4) {
      await util.setStatePromise(this, { selectedChannel: channel })
      this._showOverlayInputApiKey()
    }
    // IF LINE Shpify
    if (mkp_id === 5) {
      await util.setStatePromise(this, { selectedChannel: channel })
      this._navToReauthShpify(channel.id)
    }
  }

  // <XButton flex={1} bgColor={COLORS.BRAND_Danger}>
  //   ลบช่องทางขาย
  // </XButton>

  _renderExpireTime = (channel: IMKPChannelDetail) => {
    if (_.isNil(channel) || _.isNil(channel.expire_time) || channel.expire_time === '') {
      return null
    }
    return (
      <HStack w='full' px='2'>
        <HStack flex={1}>
          <XText variant='inactive' fontSize='xs'>
            เชื่อมต่อหมดอายุเมื่อ:
          </XText>
        </HStack>
        <HStack flex={1} justifyContent='flex-end'>
          <XText variant='inactive' fontSize='xs'>{`${channel.expire_time}`}</XText>
        </HStack>
      </HStack>
    )
  }

  _renderWebhookUrl = (channel: IMKPChannelDetail) => {
    const webhook = channel.webhook_url
    if (_.isNil(webhook)) {
      return null
    }

    const onPressBtn = () => {
      Clipboard.setString(webhook)
      p.op.showToast(`คัดลอก Webhook URL ${webhook} เรียบร้อยแล้ว`, 'success')
    }

    return (
      <HStack w='full' justifyContent='flex-end' pr='2'>
        <TouchableOpacity
          onPress={() => onPressBtn()}
          style={{
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderRightWidth: 1,
            borderLeftWidth: 1,
            borderTopColor: COLORS.APP_MAIN,
            borderBottomColor: COLORS.APP_MAIN,
            borderRightColor: COLORS.APP_MAIN,
            borderLeftColor: COLORS.APP_MAIN,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 7,
            backgroundColor: COLORS.BG_LIGHT_GREY,
          }}>
          <XText variant='primary' px='2'>
            คัดลอก Webhook URL
          </XText>
        </TouchableOpacity>
      </HStack>
    )
  }

  _renderChannelList = () => {
    const { channelList } = this.state
    if (_.isNil(channelList) || channelList.length < 1) {
      return null
    }
    return (
      <VStack w='full' space='2'>
        {channelList.map((channel: IMKPChannelDetail, index: number) => (
          // console.log('channel => ', channel)
          // const IS_ACTIVE = channel.is_active
          // eslint-disable-next-line react/no-array-index-key
          <XCard w='full' key={index}>
            {this._renderHeaderCard(channel)}
            {this._renderWraningAuthMKP(channel)}
            {this._renderWebhookUrl(channel)}
            {/* RENDER VIEW BODY */}
            {this._renderChannelBody(channel)}
            {/* {IS_ACTIVE ? this._renderChannelHasConnectionView(channel) : this._renderChannelNoConnectionView(channel)} */}
            {this._renderExpireTime(channel)}
            {this._renderFooter(channel)}
          </XCard>
        ))}
      </VStack>
    )
  }

  _renderOverlayInputApiKey = () => {
    const { isShowOverlayInputApiKey, inputLineApiKeyText } = this.state
    // if (_.isNil(multiParcel) || _.isNil(amountQty)) {
    //   return null
    // }

    const titleOverlay = 'ระบุ LINE SHOPPING API KEY'

    const _onChangeInputQtyText = (newText: string) => {
      // setInputQtyText(newText)
      // const newSelectedProduct = _.cloneDeep(selectedProduct)
      // newSelectedProduct.qty = +newText
      this.setState({
        // @ts-ignore
        inputLineApiKeyText: newText,
      })
    }
    // const inPutValue = `${inputDivideParcels}`

    return (
      <XOverlay
        // contentStyle={{ padding: 8 }}
        contentStyle={{
          width: 300,
          minHeight: 220,
          height: p.op.isWeb() ? 220 : undefined,
          // avoid keyboard style
          ...Platform.select({
            ios: {
              marginBottom: 200,
            },
            // android: {
            //   marginBottom: 35,
            // },
          }),
        }}
        visible={isShowOverlayInputApiKey}
        onRequestClose={() => this._closeOverlayInputApiKey()}>
        <XCustomHeader
          headerRightProps={{ closeIcon: true, onPressItem: () => this._closeOverlayInputApiKey() }}
          title='เชื่อมต่ออีกครั้ง'
        />
        <VStack justifyContent='center' alignItems='center' mt='2'>
          <XText variant='inactive' textAlign='center' px='2'>
            {titleOverlay}
          </XText>
          <HStack h='12' mt='1' px='2' w='full'>
            <XInput
              placeholder='ระบุ LINE SHOPPING API Key...'
              autoFocus
              multiline
              textAlign='center'
              style={{ flex: 1 }}
              value={inputLineApiKeyText}
              onChangeText={_onChangeInputQtyText}
              // onSubmitEditing={_onSubmitInputQty}
            />
          </HStack>
          {/* <XText variant='inactive' mt='1'>
            {`(ระบุจำนวนระหว่าง 0-${maxSelectedProduct})`}
          </XText> */}
          <HStack mt='2'>
            <TouchableOpacity
              onPress={() => {
                this._closeOverlayInputApiKey()
                this._callAuthChannel(this.state.selectedChannel)
              }}
              style={{
                backgroundColor: COLORS.APP_MAIN,
                borderRadius: 7,
                height: 34,
                width: 80,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <XText variant='activeDark' bold>
                ยืนยัน
              </XText>
            </TouchableOpacity>
          </HStack>
        </VStack>
      </XOverlay>
    )
  }

  _renderbody = () => {
    const { channelList } = this.state
    if (!_.isNil(channelList) && channelList.length > 0) {
      return (
        <Box w='full'>
          {this._renderOverlayInputApiKey()}
          {this._renderChannelList()}
        </Box>
      )
    }
    return (
      <XText
        textAlign='center'
        variant='inactive'
        mt='10'>{`คุณยังไม่มีช่องทางขาย หากยังต้องการสร้าง\nกรุณากดปุ่ม '+' มุมขวาบน เพื่อสร้างช่องทางขาย `}</XText>
    )
  }

  _loading = () => {
    const { isLoading, loadingWidth } = this.state
    if (!isLoading) {
      return null
    }
    const screenHeight = Math.round(Dimensions.get('window').height)
    return (
      <View
        style={{
          // zIndex: 999,
          position: 'absolute',
          width: loadingWidth,
          height: screenHeight,
          backgroundColor: 'rgba(52, 52, 52, 0.7)',
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.APP_MAIN }}>กำลังโหลด...</Text>
        <Button
          // title="Loading button"
          loading
          disabled
          type='clear'
        />
      </View>
    )
  }

  render() {
    return this._renderMain()
  }
}
