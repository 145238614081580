/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react'
import { IPaymentAccount, ISelectedStoreMap, IXScreenProps } from 'x/types'
import * as util from 'x/utils/util'
import _ from 'lodash'
import api from 'x/utils/api'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'

// const ICON_MY_STORE = require('../../../../../xui/x/img/tb/tb_my0.png')
// const PULL_VOLUMN_DISCOUNT_ICON_IMAGE = require('../../../../../xui/x/img/volumn_discount.png')

const defaultBankAccount: IPaymentAccount = {
  name: '',
  account_number: '',
  bank_id: 0,
  settings_json: {
    peak: {
      main_acct_num: '',
      sub_acct_num: '',
    },
  },
  account_type: null,
}

export interface BaseBankEditViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  resetEditingStore: () => void
}

export interface BaseBankEditViewState {
  bankAccount: IPaymentAccount
  isPeakAccountSwitch: boolean
  isHideW4C: boolean

  mode: 'EDIT' | 'CREATE'

  bankAccountType: 'CurrentAccount' | 'SavingAccount' | 'FixedDeposit'

  isEdit: boolean
}

export default abstract class BaseBankEditView extends React.Component<BaseBankEditViewProps, BaseBankEditViewState> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  // helperProfileId: number

  protected constructor(props) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    super(props)
    this.state = {
      bankAccount: null,
      isPeakAccountSwitch: false,
      isHideW4C: false,
      mode: 'CREATE',
      bankAccountType: null,
      isEdit: false,
    }
    // this._tabRef = React.createRef()
  }

  async componentDidMount() {
    // const { navigation } = this.props
    // const { state } = navigation
    // const { account } = state.params
    const account = util.getNavParam(this.props, 'account')
    // console.log('// account // ', account)
    if (_.isNil(account)) {
      await util.setStatePromise(this, {
        bankAccount: defaultBankAccount,
      })
    } else {
      const newAccount: IPaymentAccount = _.clone(account)
      // console.log('newAccount => ', newAccount)
      const isPeakAccountSwitch = newAccount.settings_json && !_.isNil(newAccount.settings_json.peak)
      const isHideW4C = newAccount.hide_from_w4c ? newAccount.hide_from_w4c : false
      const newBankAccountType = newAccount.account_type
      await util.setStatePromise(this, {
        bankAccount: newAccount,
        isPeakAccountSwitch,
        isHideW4C,
        mode: 'EDIT',
        bankAccountType: newBankAccountType,
      })
    }
  }

  _setEdit = () => {
    this.setState({ isEdit: true })
  }

  _valueOnChange = async (key: string, newValue: string) => {
    const { bankAccount } = this.state
    const newBankAccount = _.clone(bankAccount)
    newBankAccount[key] = newValue
    this._setEdit()
    await util.setStatePromise(this, {
      bankAccount: newBankAccount,
    })
  }

  _bankIdOnChange = async (newBankId: number) => {
    const { bankAccount } = this.state
    const newBankAccount = _.clone(bankAccount)
    newBankAccount.bank_id = newBankId
    this._setEdit()
    await util.setStatePromise(this, {
      bankAccount: newBankAccount,
    })
  }

  _valuePeakOnChange = async (key: string, newValue: string) => {
    const { bankAccount } = this.state
    const newBankAccount = _.clone(bankAccount)
    if (newBankAccount.settings_json) {
      if (!_.isNil(newBankAccount.settings_json.peak)) {
        newBankAccount.settings_json.peak[key] = newValue
      } else {
        const peak = {
          main_acct_num: '',
          sub_acct_num: '',
        }
        newBankAccount.settings_json.peak = peak
        newBankAccount.settings_json.peak[key] = newValue
      }
    } else {
      const settings_json = {
        peak: {
          main_acct_num: '',
          sub_acct_num: '',
        },
      }
      newBankAccount.settings_json = settings_json
      newBankAccount.settings_json.peak[key] = newValue
    }
    this._setEdit()
    // console.log('newBankAccount => ', newBankAccount)
    await util.setStatePromise(this, {
      bankAccount: newBankAccount,
    })
  }

  _isBankHaveBankAccountType = (bankId: number) => {
    let hasAccountype = false
    CONS.BANK_HAVE_ACCOUNT_TYPE.forEach((bank_id) => {
      if (bankId === bank_id) {
        hasAccountype = true
      }
    })
    return hasAccountype
    // console.log('bankAccount => ', bankAccount)
    // if (!hasAccountype) {
    //   return null
    // }
  }

  _saveAction = async () => {
    const { bankAccount, isPeakAccountSwitch, isHideW4C, bankAccountType } = this.state
    const { selectedStore } = this.props
    const isVerifySlipBankAccountOnly = util.getNavParam(this.props, 'isVerifySlipBankAccountOnly', false)

    if (bankAccount.name.length < 1) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุชื่อบัญชี')
      return
    }
    if (bankAccount.account_number && bankAccount.account_number.length < 1) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุเลขที่บัญชี')
      return
    }
    if (bankAccount.bank_id === 0 || _.isNil(bankAccount.bank_id)) {
      p.op.showConfirmationOkOnly('', 'กรุณาเลือกรูปแบบบัญชี')
      return
    }
    const textMainPeakAccount =
      bankAccount.settings_json && bankAccount.settings_json.peak && bankAccount.settings_json.peak.main_acct_num
        ? bankAccount.settings_json.peak.main_acct_num
        : null
    if (isPeakAccountSwitch && _.isNil(textMainPeakAccount)) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุรหัสบัญชี')
      return
    }

    // console.log('bankAccount => ', bankAccount)
    const body = {
      store_id: selectedStore.get('id'),
      name: bankAccount.name,
      account_number: bankAccount.account_number,
      bank_id: bankAccount.bank_id,
      hide_from_w4c: isHideW4C,
      settings_json: bankAccount.settings_json,
    }
    // console.log('bankAccountType => ', bankAccountType)

    const hasAccountype = this._isBankHaveBankAccountType(bankAccount.bank_id)
    if (hasAccountype && _.isNil(bankAccountType)) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุประเภทบัญชี')
      return
    }
    if (hasAccountype) {
      // @ts-ignore
      body.account_type = bankAccountType
    }

    if (isVerifySlipBankAccountOnly) {
      // @ts-ignore
      body.is_for_verify_slip = true
    }

    const apiOptions = {
      showSpinner: true,
    }
    const API_TO_GO = bankAccount.id ? api.patch : api.put

    if (bankAccount.id) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      body.payment_account_id = bankAccount.id
    }
    if (!isPeakAccountSwitch) {
      body.settings_json = null
    }

    const res: { payment_accounts: IPaymentAccount[] } = await API_TO_GO(api.PUT_BANKACCOUNTS, body, apiOptions)
    // console.log('res => // ', res)
    if (res.payment_accounts) {
      const msg = bankAccount.id ? 'แก้ไขเรียบร้อยแล้ว' : 'สร้างบัญชีรับชำระเรียบร้อย'
      p.op.showToast(msg, 'success')
      util.navGoBack(this.props)
      const succeedCallback = util.getNavParam(this.props, 'succeedCallback')
      if (_.isFunction(succeedCallback)) {
        const paymentAccountsBySort = util.sortPaymentAccounts(res.payment_accounts)
        succeedCallback(paymentAccountsBySort)
      }
    } else {
      p.op.showToast('เกิดข้อผิดพลาด', 'warning')
    }
  }

  _deleteAccount = async () => {
    const { bankAccount } = this.state
    const { selectedStore } = this.props
    const body = {
      store_id: selectedStore.get('id'),
      payment_account_id: bankAccount.id,
    }
    const apiOptions = {
      showSpinner: true,
    }
    const res: { payment_accounts: IPaymentAccount[] } = await api.del(api.PUT_BANKACCOUNTS, body, apiOptions)
    // console.log('res del => // ', res)
    if (res.payment_accounts) {
      p.op.showToast(`ลบบัญชี ${bankAccount.name} เรียบร้อยแล้ว`, 'success')
      util.navGoBack(this.props)
      const succeedCallback = util.getNavParam(this.props, 'succeedCallback')
      if (_.isFunction(succeedCallback)) {
        succeedCallback(res.payment_accounts)
      }
    }
  }
}
