// import PropTypes from 'prop-types'
import React from 'react'

import { btnOnMode, textOnBtnMode, COLORS } from 'x/config/styles'
import XBlankButton from 'xui/components/XBlankButton'
import CONS from 'x/config/constants'
// import QRCodeModal from '../../components/QRCodeModal'
// import ForwardIcon from '../../components/ForwardIcon'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import _ from 'lodash'
import { canDoAtSelectedStore } from 'x/utils/acl'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import BaseProductGroupView from 'x/modules/productGroup/BaseProductGroupView'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import XInput from 'xui/components/XInput'

import HStack from 'xui/components/HStack'

const { ADD, VIEW, EDIT } = CONS.PRODUCT_GROUP_VIEW_MODE

export default abstract class BaseUIProductGroupView extends BaseProductGroupView {
  _renderCustomHeader = () => {
    const { submitting } = this.state
    const { navigation } = this.props
    let title = 'เพิ่มรายการราคา'
    const mode = util.getNavParam(this.props, 'mode', VIEW)
    let leftProps = {
      backIcon: true,
      onPressItem: () => this._goBack(),
      submitting,
    }

    let rightProps = {
      // @ts-ignore
      label: 'บันทึก',
      submitting,
      onPressItem: () => this._submitCreateProductGroup(),
    }

    if (mode === VIEW) {
      title = 'ข้อมูลรายการราคา'
      rightProps = {
        // @ts-ignore
        label: 'แก้ไข',
        submitting,
        // @ts-ignore
        onPressItem: () => {
          if (!canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PG_EDIT)) {
            p.op.alert('คุณไม่มีสิทธิ์ในการแก้ไขรายการราคา')
            return
          }
          navigation.setParams({ mode: EDIT })
        },
      }
    } else if (mode === EDIT) {
      title = 'แก้ไขรายการราคา'
      leftProps = {
        // @ts-ignore
        label: 'บันทึก',
        onPressItem: () => this._submitEditProductGroup(),
        submitting,
      }
      rightProps = {
        // @ts-ignore
        label: 'ยกเลิก',
        submitting,
        // @ts-ignore
        onPressItem: () => this._cancelEditProductGroup(),
      }
    }

    return (
      <XCustomHeader
        title={title}
        headerLeftProps={leftProps}
        headerRightProps={rightProps}
        // renderHeaderLeft={() => this.renderLeftBtn()}
        // renderHeaderRight={() => this.renderRightBtn()}
      />
    )
  }

  _renderName = (isEditable) => {
    const { name = '' } = this.state
    const txtName = name ? name.toString() : ''
    return (
      <VStack w='full'>
        <HStack w='full'>
          <XText variant='active'>ชื่อรายการราคา</XText>
        </HStack>
        <HStack w='full'>
          {isEditable ? (
            <XInput
              w='full'
              placeholder='ระบุชื่อของรายการราคา'
              disabled={!isEditable}
              editable={isEditable}
              onChangeText={this._handleChangeName}
              value={txtName}
            />
          ) : (
            <XText variant='active'>{txtName}</XText>
          )}
        </HStack>
      </VStack>
    )
  }

  _renderDesc = (isEditable) => {
    const { description = '' } = this.state
    let txtDesc = description ? description.toString() : ''
    if (!isEditable && txtDesc.length === 0) {
      txtDesc = '(ไม่มีคำอธิบายกลุ่ม)'
    }

    return (
      <VStack w='full' mt={isEditable ? '0' : '4'}>
        <HStack w='full'>
          <XText variant='active'>คำอธิบายของกลุ่ม</XText>
        </HStack>
        <HStack w='full'>
          {isEditable ? (
            <XInput
              w='full'
              placeholder='ระบุคำอธิบายของรายการราคา'
              disabled={!isEditable}
              editable={isEditable}
              style={{ minHeight: 150 }}
              multiline
              // numberOfLines={6}
              onChangeText={this._handleChangeDesc}
              value={txtDesc}
            />
          ) : (
            <XText variant='active' w='full'>
              {txtDesc}
            </XText>
          )}
        </HStack>
      </VStack>
    )
  }

  _renderDeleteProductGroupButton(mode: string) {
    if (mode !== EDIT) {
      return null
    }

    const btnStyle = {
      ...btnOnMode(CONS.BTN_MODE.ACTIVE_SECONDARY),
      borderColor: COLORS.BRAND_Danger,
      borderRadius: 4,
    }

    const txtStyle = {
      ...textOnBtnMode(CONS.BTN_MODE.ACTIVE_SECONDARY),
      color: COLORS.BRAND_Danger,
    }

    return (
      <HStack style={{ marginVertical: 10 }}>
        <VStack style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <XBlankButton onPress={() => this._deleteProductGroup()} style={btnStyle}>
            <XText style={txtStyle}>ลบรายการราคา</XText>
          </XBlankButton>
        </VStack>
      </HStack>
    )
  }

  render() {
    const params = util.getNavParams(this.props)
    if (!params || _.isEmpty(params) || !params.mode) {
      return null
    }

    const { mode } = params
    const isEditable = _.includes([ADD, EDIT], mode)
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent p='2'>
          <XCard p='2'>
            {this._renderName(isEditable)}
            {this._renderDesc(isEditable)}
          </XCard>
          {this._renderDeleteProductGroupButton(mode)}
        </XContent>
      </XContainer>
    )
  }
}
