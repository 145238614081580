import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as StoreState from 'x/modules/store/StoreState'
import { getSelectedStore, getMyStores } from 'x/redux/selectors'
import * as XShippingActions from 'x/modules/xshipping/XShippingState'
import StoreMyView from './StoreMyView'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    myStores: getMyStores(state),
  }),
  (dispatch) => {
    return {
      // onBarcodeRead: bindActionCreators(BarcodeListenerActions.onBarcodeRead, dispatch),
      fetchInitializeData: bindActionCreators(StoreState.fetchInitializeData, dispatch),
      fetchMyStore: bindActionCreators(StoreState.fetchMyStore, dispatch),
      setXShippingConfig: bindActionCreators(XShippingActions.setXShippingConfig, dispatch),
      dispatch,
    }
  }
)(StoreMyView)
