import React from 'react'

import _ from 'lodash'

import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import * as xUtil from 'x/utils/util'
import { fromJS } from 'immutable'
import * as xAcl from 'x/utils/acl'
import {
  IChannel,
  IVariants,
  IXSegmentOption,
  ISelectedStoreMap,
  IMemberHelperList,
  IProfile,
  UserInUserGroups,
  IXScreenProps,
} from 'x/index'
import dayjs, { Dayjs } from 'dayjs'

// const {
//   // PRODUCT_STOCK,
//   REPORT_VIEW,
//   PRODUCT_STOCK_VIEW,
//   PRODUCT_STOCK_EDIT,
//   PRODUCT_COST,
//   PRODUCT_PRICE,
//   PRODUCT_WEIGHT,
//   PRODUCT_SHIPPING_QTY_RATE,
//   PRODUCT_CODE_EDIT_SKU,
//   PRODUCT_CODE_EDIT_UPC,
// } = CONS.PERM_STORE_HELPER

interface XReportProductDailySalesProps extends IXScreenProps {
  // visible: boolean
  // onRequestClose: Function
  // allowOnlyDaysFromToday: number
  // variants?: Array<{ [key: string]: IVariants }>
  // store_id: number | string
  // useVariant?: boolean
  selectedStore: ISelectedStoreMap
  subscription: any
  getPermissionMemberList: () => void
}

interface XReportProductDailySalesState {
  selectedDateRange: { begin: Dayjs; end: Dayjs }
  selectedOptSegment: number
  thisVariants: IVariants[]
  showUPC: boolean
  showSKU: boolean
  showCategory: boolean
  showPrice: boolean
  showProfit: boolean
  showOrderIDS: boolean
  warningDateRangeText: string
  downloadingReport: boolean
  channelData: IChannel
  selectedChannelOptionIndex: number
  showSeletedChannel: boolean

  // สถานะการยืนยันรับชำระ
  payment_states?: number[] // undefined or array of status_id
  shipping_states?: number[] // undefined or array of status_id
  latestOrderPaymentStatusOptionIndex: number
  latestOrderShippingStatusOptionIndex: number

  isShowWraningSelectedVariants: boolean

  variants?: Array<{ [key: string]: IVariants }>

  createAtSegmentIndex: number

  hlepers: IMemberHelperList[]

  visibleOnlyProfileId: boolean
  profile: IProfile

  reseller: UserInUserGroups
  userGroupName?: string

  selectedChannelIds: number[]
}

const startNewDate = dayjs().format('YYYY-MM-DD 00:00:00')
const endNewDate = dayjs().format('YYYY-MM-DD 23:59:59')

export default abstract class BaseReportProductDailySales extends React.Component<
  XReportProductDailySalesProps,
  XReportProductDailySalesState
> {
  DATE_RANGE_REPORT_PRODUCT_DAILY_SALES: string[]

  inProcess: boolean

  CHANNEL_OTP: string[]

  backUpSelectedChannelIds: number[]

  abstract _handleOnDownloadFile(url: string, fileName: string): Promise<void>

  constructor(props: XReportProductDailySalesProps) {
    super(props)
    this.state = {
      selectedDateRange: { begin: dayjs(startNewDate), end: dayjs(endNewDate) },
      selectedOptSegment: 0,
      thisVariants: null,
      showUPC: false,
      showSKU: false,
      showCategory: false,
      showPrice: false,
      showProfit: false,
      showOrderIDS: false,
      warningDateRangeText: '',
      downloadingReport: true,
      channelData: null,
      selectedChannelOptionIndex: 0,
      showSeletedChannel: false,
      latestOrderPaymentStatusOptionIndex: 0,
      latestOrderShippingStatusOptionIndex: 0,

      isShowWraningSelectedVariants: false,

      variants: null,
      createAtSegmentIndex: 0,
      hlepers: null,
      visibleOnlyProfileId: false,
      profile: null,

      reseller: null,
      userGroupName: null,
      selectedChannelIds: [],
    }
    this.DATE_RANGE_REPORT_PRODUCT_DAILY_SALES = ['ถูกสร้าง', 'เสร็จสิ้น', 'พร้อมส่ง', 'กำหนดส่ง']
    this.inProcess = false
    this.CHANNEL_OTP = ['ทุกช่องทาง', 'ระบุเอง']
    this.backUpSelectedChannelIds = []
  }

  async componentDidMount() {
    // // const { variants } = this.state
    // const { navigation } = this.props
    // const { state } = navigation
    // const { params } = state
    // console.log(`params `, params)
    const params = xUtil.getNavParams(this.props)
    const variants = _.isNil(params.variants) ? null : params.variants
    const variantsFromJS = _.isNil(variants) ? null : fromJS(variants)
    const rVariants = _.isNil(variantsFromJS) || variantsFromJS.size === 0 ? [] : variantsFromJS.toJS()
    const { reseller, userGroupName } = params

    // let selectedOptSegment = 0
    let showPrice = false
    let showProfit = false
    let showUPC = false
    let showSKU = false
    let showCategory = false
    let showOrderIDS = false
    let latestOrderPaymentStatusOptionIndex = 0
    let latestOrderShippingStatusOptionIndex = 0
    const warningDateRangeText = this._reportwarningTextSetup()
    await p.op.storageGet(CONS.STORAGE_KEYS.REPORT_PRODUCT_DAILY_SALES).then((val: any) => {
      if (!_.isNil(val)) {
        // selectedOptSegment = _.isNil(val.selectedOptSegment) ? selectedOptSegment : val.selectedOptSegment
        showPrice = _.isNil(val.showPrice) ? showPrice : val.showPrice
        showProfit = _.isNil(val.showProfit) ? showProfit : val.showProfit
        showUPC = _.isNil(val.showUPC) ? showUPC : val.showUPC
        showSKU = _.isNil(val.showSKU) ? showSKU : val.showSKU
        showCategory = _.isNil(val.showCategory) ? showCategory : val.showCategory
        showOrderIDS = _.isNil(val.showOrderIDS) ? showOrderIDS : val.showOrderIDS
        latestOrderPaymentStatusOptionIndex = _.isNil(val.latestOrderPaymentStatusOptionIndex)
          ? latestOrderPaymentStatusOptionIndex
          : val.latestOrderPaymentStatusOptionIndex
        latestOrderShippingStatusOptionIndex = _.isNil(val.latestOrderShippingStatusOptionIndex)
          ? latestOrderShippingStatusOptionIndex
          : val.latestOrderShippingStatusOptionIndex
      }
    })

    xUtil.setStatePromise(this, {
      thisVariants: rVariants,
      // selectedOptSegment,
      showPrice,
      showProfit,
      showUPC,
      showSKU,
      showCategory,
      warningDateRangeText,
      downloadingReport: false,
      showOrderIDS,
      latestOrderPaymentStatusOptionIndex,
      latestOrderShippingStatusOptionIndex,
      reseller,
      userGroupName,
    })

    this._setVisibleOnlyProfileId()
  }

  _setVisibleOnlyProfileId = () => {
    let visibleOnlyProfileId = xAcl.isSelectedStoreOwner()
    if (visibleOnlyProfileId) {
      // console.log('getProfile => ', xUtil.getProfile())
      xUtil.setStatePromise(this, {
        visibleOnlyProfileId: false,
        profile: xUtil.getProfile(),
      })
    } else {
      visibleOnlyProfileId = xAcl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_VISIBLE_ONLY_PROFILE_ID)
      xUtil.setStatePromise(this, {
        visibleOnlyProfileId,
        profile: xUtil.getProfile(),
      })
    }
  }

  _hideSeletedChannel = () => {
    xUtil.setStatePromise(this, { showSeletedChannel: false })
  }

  _onChangeChannel = (channel: IChannel) => {
    xUtil.setStatePromise(this, { channelData: channel, showSeletedChannel: false })
  }

  _showSeletedChannel = (index: number) => {
    if (index === 0) {
      xUtil.setStatePromise(this, { showSeletedChannel: false, selectedChannelOptionIndex: index, channelData: null })
    } else {
      xUtil.setStatePromise(this, { showSeletedChannel: true, selectedChannelOptionIndex: index })
      this.backUpSelectedChannelIds = this.state.selectedChannelIds
    }
  }

  _reportwarningTextSetup = () => {
    const { subscription } = this.props
    const owner = xAcl.isSelectedStoreOwner()
    const r_shpg_day = subscription.get('r_shpg_day')
    const howDay = `แพ็กเกจของคุณดูย้อนหลังได้ ${r_shpg_day} วัน\nโดยสามารถดาวน์โหลดรายงานได้สูงสุด 31 วันต่อหนึ่งครั้ง`
    let text = 'แพ็กเกจของคุณไม่สามารถใช้งานฟีเจอร์นี้ได้'
    if (owner) {
      if (!xAcl.packFree()) {
        text = howDay
      }
    } else {
      if (xUtil.isStoreOwner() && !xAcl.packFree()) {
        text = `คุณไม่ได้รับสิทธิในการใช้งานฟีเจอร์นี้`
        return text
      }
      const canDo = xAcl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.REPORT_ORDER, CONS.REPORT_SET_BIT_BINARY.report_product_daily_sales)
      if (canDo) {
        text = howDay
      } else {
        text = `คุณไม่ได้รับสิทธิในการใช้งานฟีเจอร์นี้`
      }
    }

    return text
  }

  _onChangeDateRange = async (newDates: { begin: Dayjs; end: Dayjs }) => {
    xUtil.setStatePromise(this, { selectedDateRange: newDates })
  }

  _onChangeSegmentReportProductDailySales = async (newIndex) => {
    xUtil.setStatePromise(this, {
      selectedOptSegment: newIndex,
    })
  }

  _seleteChooseOtp = (key: string, value: boolean) => {
    xUtil.setStatePromise(this, {
      [key]: !value,
    })
  }

  _seleteCheckBoxBtn = (data: IVariants) => {
    const { thisVariants } = this.state
    const newVariants = []
    const { pp_id } = data
    if (!_.isNil(thisVariants)) {
      // @ts-ignore val ?
      // eslint-disable-next-line array-callback-return
      thisVariants.map((val: IVariants) => {
        if (val.pp_id === pp_id) {
          const newVal = val
          if (_.isNil(val.value)) {
            newVal.value = true
          } else {
            newVal.value = !val.value
          }
          newVariants.push(newVal)
        } else {
          newVariants.push(val)
        }
      })
    }
    xUtil.setStatePromise(this, {
      thisVariants: newVariants,
      isShowWraningSelectedVariants: false,
    })
  }

  _downloadHistoryReport = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    await xUtil.setStatePromise(this, { downloadingReport: true })

    const {
      thisVariants,
      selectedOptSegment,
      selectedDateRange,
      showPrice,
      showProfit,
      showOrderIDS,
      showUPC,
      showSKU,
      showCategory,
      channelData,
      selectedChannelOptionIndex,
      payment_states,
      shipping_states,
      latestOrderShippingStatusOptionIndex,
      latestOrderPaymentStatusOptionIndex,

      reseller,
      createAtSegmentIndex,
      hlepers,
      selectedChannelIds,
    } = this.state
    // const { useVariant } = this.state
    const { selectedStore } = this.props
    const canDo = xAcl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.REPORT_INVENTORY, CONS.REPORT_SET_BIT_BINARY.report_product_daily_sales)
    if (!canDo) {
      p.op.showConfirmationOkOnly('', 'คุณไม่ได้รับสิทธิในการใช้งานฟีเจอร์นี้')
      this.inProcess = false
      await xUtil.setStatePromise(this, { downloadingReport: false })
      return
    }
    const arr = []

    // // O: นำไปประกาศด้านล่างแทนครับ
    // let created_at_from = null
    // let created_at_to = null
    // let completed_at_from = null
    // let completed_at_to = null
    // const isHlper = xUtil.isHelper()
    // if (!isHlper && xUtil.isStoreOwner() && this.props.subscription.get('type') === CONS.SUBSCRIPTION_PACKAGE.FREE) {
    //   p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
    //   this.inProcess = false
    //   await xUtil.setStatePromise(this, { downloadingReport: false })
    //   return
    // }

    // if (xAcl.packFree()) {
    //   p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
    //   this.inProcess = false
    //   await xUtil.setStatePromise(this, { downloadingReport: false })
    //   return
    // }

    if (!_.isNil(thisVariants)) {
      // @ts-ignore val ?
      // eslint-disable-next-line array-callback-return
      thisVariants.map((data: IVariants) => {
        if (data.value) {
          arr.push(data.pp_id)
        }
      })
    }
    // console.log('useVariant => ', useVariant)
    // console.log('thisVariants => ', thisVariants)
    // console.log('arr => ', arr)
    // ต้องส่ง useVariant จาก props มา และ thisVariants ต้องมีตัวเลือกมากว่า 1 ตัว (ถ้ามี 1 ตัวเราจะเลือกให้เลย) และ ถ้ามีมากว่า 1 ตัว ต้องเลือกอย่างน้อย 1 ตัว
    if (thisVariants.length > 1 && arr.length < 1) {
      // p.op.showConfirmationOkOnly('', 'กรุณาเลือกตัวเลือกสินค้าอย่างน้อย 1 รายการ')
      this.inProcess = false
      await xUtil.setStatePromise(this, { downloadingReport: false, isShowWraningSelectedVariants: true })
      return
    }

    // ถ้ามี Variant ตัวเดียวก็เลือกให้เลย
    if (thisVariants.length === 1) {
      arr.push(thisVariants[0].pp_id)
    }

    const startDate = selectedDateRange.begin.format('YYYY-MM-DD%20HH:mm:00')
    const endDate = selectedDateRange.end.format('YYYY-MM-DD%20HH:mm:59')

    let created_at_with_time_from
    let created_at_with_time_to
    let completed_at_with_time_from
    let completed_at_with_time_to
    let date_delivery_from
    let date_delivery_to
    let ready_to_ship_date_from
    let ready_to_ship_date_to

    switch (selectedOptSegment) {
      // 'ถูกสร้าง'
      case 0:
        created_at_with_time_from = startDate
        created_at_with_time_to = endDate
        break
      // 'เสร็จสิ้น'
      case 1:
        completed_at_with_time_from = startDate
        completed_at_with_time_to = endDate
        break
      // 'พร้อมส่ง'
      case 2:
        ready_to_ship_date_from = startDate
        ready_to_ship_date_to = endDate
        break
      // 'กำหนดส่ง'
      case 3:
        date_delivery_from = startDate
        date_delivery_to = endDate
        break

      default:
        // do nothing
        break
    }

    // // O: เขียน logic เหล่านี้ใหม่ที่ด้านบนครับ
    // let startDate = null
    // let endDate = null
    // if (selectedOptSegment === 0) {
    //   created_at_from = selectedDateRange.begin.format('YYYY-MM-DD%20HH:mm:00')
    //   created_at_to = selectedDateRange.end.format('YYYY-MM-DD%20HH:mm:59')

    //   let stime = xUtil.changeSecondTime(selectedDateRange.begin, 0)
    //   stime = xUtil.changeMillisecondsTime(stime, 0)
    //   let etime = xUtil.changeSecondTime(selectedDateRange.end, 59)
    //   etime = xUtil.changeMillisecondsTime(etime, 999)

    //   // created_at_from = stime.format(CONS.PDF_REPORT_DATE_FORMAT)
    //   // created_at_to = etime.format(CONS.PDF_REPORT_DATE_FORMAT)

    //   startDate = created_at_from
    //   endDate = created_at_to
    // } else {
    //   completed_at_from = selectedDateRange.begin.format('YYYY-MM-DD%20HH:mm:00')
    //   completed_at_to = selectedDateRange.end.format('YYYY-MM-DD%20HH:mm:59')

    //   let stime = xUtil.changeSecondTime(selectedDateRange.begin, 0)
    //   stime = xUtil.changeMillisecondsTime(stime, 0)
    //   let etime = xUtil.changeSecondTime(selectedDateRange.end, 59)
    //   etime = xUtil.changeMillisecondsTime(etime, 999)
    //   // completed_at_from = stime.format(CONS.PDF_REPORT_DATE_FORMAT)
    //   // completed_at_to = etime.format(CONS.PDF_REPORT_DATE_FORMAT)

    //   startDate = completed_at_from
    //   endDate = completed_at_to
    // }

    let channel_ids = null
    if (!_.isNil(selectedChannelIds) && selectedChannelOptionIndex === 1 && selectedChannelIds.length > 0) {
      channel_ids = selectedChannelIds
    }
    if (_.isNil(channel_ids) && selectedChannelOptionIndex !== 0) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุช่องทางขาย')
      this.inProcess = false
      await xUtil.setStatePromise(this, { downloadingReport: false, isShowWraningSelectedVariants: true })
      return
    }
    // selectedChannelIds
    // console.log('reseller //*/ => ', reseller)
    let created_by_role = 'all'
    let created_by_profile_id = null
    if (!_.isNil(reseller)) {
      created_by_role = 'reseller'
      created_by_profile_id = reseller.i
    } else {
      if (createAtSegmentIndex === 1) {
        created_by_role = 'owner'
      }
      if (createAtSegmentIndex === 2) {
        created_by_role = 'helper'
        const selectedHleper = hlepers.find((hleper) => hleper.seleted)
        // console.log('selectedHleper => ', selectedHleper)
        if (_.isNil(selectedHleper)) {
          p.op.showConfirmationOkOnly('', 'กรุณาเลือกผู้ช่วย')
          this.inProcess = false
          await xUtil.setStatePromise(this, { downloadingReport: false, isShowWraningSelectedVariants: true })
          return
        }
        // @ts-ignore
        created_by_profile_id = selectedHleper.i
      }
      if (createAtSegmentIndex === 3) {
        created_by_role = 'reseller'
      }
    }
    // return
    // console.log('channelData => // ', channelData)
    // console.log('channel_id => ', channel_id)
    // console.log('startDate => ', startDate)
    // console.log('endDate => ', endDate)
    // const url = `https://superdevgo.xselly.com/api/report/product_daily_sales?completed_at_from=2019-07-01&completed_at_to=2019-09-02&store_id=3&pp_ids=336053&showUPC=true&showSKU=true&showPrice=true&showProfit=true&pp_ids=336213`
    const store_id = selectedStore.get('id')
    let url = xUtil.getReportProductDailySalesExcelUrl({
      store_id,

      // // O: ใช้การ serialize ด้านล่างแทนครับ
      // created_at_from,
      // completed_at_from,
      // completed_at_to,
      // created_at_to,

      pp_ids: arr,
      showPrice,
      showProfit,
      showOrderIDS,
      showSKU,
      showUPC,
      showCategory,
      channel_ids,

      created_by_role,
      created_by_profile_id,
    })
    // console.log('url ?? => ', url)
    // extra params
    const serialzedQueryParams = xUtil.serializeObjectToQueryParamsString(
      {
        created_at_with_time_from,
        created_at_with_time_to,
        completed_at_with_time_from,
        completed_at_with_time_to,
        date_delivery_from,
        date_delivery_to,
        ready_to_ship_date_from,
        ready_to_ship_date_to,

        payment_states,
        shipping_states,
      },
      { removeArraySquareBrackets: true }
    )
    if (serialzedQueryParams) {
      url = `${url}&${serialzedQueryParams}`
    }

    // console.log('URL : ', url)
    let fileName = `product daily sales ${startDate}-${endDate}`
    // console.log('fileName : ', fileName)
    fileName = fileName.replace(/%20/g, ' ') // เปลี่ยน %20 จาก time เป็น space เพื่อให้ user อ่านชื่อไฟล์ได้ง่ายขึ้น

    const keepStore = {
      // selectedOptSegment,
      showPrice,
      showProfit,
      showOrderIDS,
      showUPC,
      showSKU,
      showCategory,
      latestOrderPaymentStatusOptionIndex,
      latestOrderShippingStatusOptionIndex,
    }
    // console.log('URL 02 : ', url)
    fileName = `${fileName}.xlsx`
    await this._handleOnDownloadFile(url, fileName)
    if (_.isNil(thisVariants)) {
      p.op.aLogEvent(CONS.EVENT_NAME.REPORT_PRODUCT_DAILY_SALES, { s: store_id, m: 'p' })
    } else {
      p.op.aLogEvent(CONS.EVENT_NAME.REPORT_PRODUCT_DAILY_SALES, { s: store_id, m: 'r' })
    }
    await p.op.storageSet(CONS.STORAGE_KEYS.REPORT_PRODUCT_DAILY_SALES, keepStore)
    await setTimeout(() => {
      this.inProcess = false
    }, 500)
    // await xUtil.delay(3000)
    await xUtil.setStatePromise(this, { downloadingReport: false })
  }

  onSegmentOrderPaymentStatusChange = (option: IXSegmentOption<number[] | undefined>, latestOrderPaymentStatusOptionIndex: number) => {
    this.setState({ payment_states: option.value, latestOrderPaymentStatusOptionIndex })
  }

  onSegmentOrderShippingStatusChange = (option: IXSegmentOption<number[] | undefined>, latestOrderShippingStatusOptionIndex: number) => {
    this.setState({ shipping_states: option.value, latestOrderShippingStatusOptionIndex })
  }

  _onChangeCreateAtSegmentIndex = async (newIndex: number) => {
    if (newIndex === 2) {
      this._fetchHelpers()
    }
    if (newIndex === 3) {
      this.setState({
        selectedChannelOptionIndex: 0,
        selectedChannelIds: [],
      })
    }
    await xUtil.setStatePromise(this, {
      createAtSegmentIndex: newIndex,
    })
  }

  _fetchHelpers = async () => {
    const { getPermissionMemberList, selectedStore } = this.props
    const { hlepers } = this.state
    if (!_.isNil(hlepers)) {
      return
    }
    if (!_.isFunction(getPermissionMemberList)) {
      return
    }
    // await xUtil.setStatePromise(this, { loading: true })
    let respone = null
    await new Promise((resolve) => {
      getPermissionMemberList({
        body: {
          store_id: selectedStore.get('id'),
        },
        successCallback: (res) => {
          respone = res
          resolve(null)
        },
        failedCallback: resolve,
      })
    })
    const newHlepers = respone.helpers
    await xUtil.setStatePromise(this, { hlepers: newHlepers })
  }

  _onSeletedHelper = (data: IMemberHelperList) => {
    const { hlepers } = this.state
    const newHelper = []
    hlepers.forEach((acc: IMemberHelperList) => {
      if (data.i === acc.i) {
        if (_.isNil(acc.seleted) || !acc.seleted) {
          acc.seleted = true
        } else {
          acc.seleted = false
        }
      } else {
        acc.seleted = false
      }
      newHelper.push(acc)
    })
    xUtil.setStatePromise(this, { hlepers: newHelper })
  }

  _selectedChannelIds = (idx: number) => {
    const newIds = _.clone(this.state.selectedChannelIds)
    const selected = _.includes(newIds, idx)
    if (selected) {
      const updatedIds = newIds.filter((id) => id !== idx)
      this.setState({
        selectedChannelIds: updatedIds,
      })
    } else {
      newIds.push(idx)
      this.setState({
        selectedChannelIds: newIds,
      })
    }
  }
}
