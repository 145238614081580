import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as StoreActions from 'x/modules/store/StoreState'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import HStack from 'xui/components/HStack'
import {
  getSelectedStore,
  getEditingStore,
  getSubscription,
  getSelectedStoreOrdersDates,
  getSelectedStoreOrdersDatesOptionKey,
} from 'x/redux/selectors'
import {
  ActionApiParams,
  IApiOptions,
  IPaymentAccount,
  ISelectedStoreMap,
  IStoreSettingVerifySlipNavParams,
  IXDateRangeSelectorOptionKey,
  IXScreenProps,
  IDateRange,
} from 'x/types'
import * as util from 'x/utils/util'
import api from 'x/utils/api'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import * as NavActions from 'x/utils/navigation'

import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import XSwitch from 'xui/components/XSwitch'
import XNumericInput from 'xui/components/XNumericInput'
import XButton from 'xui/components/XButton'
import XCard from 'xui/components/XCard'
import BankIcon from 'xui/components/BankIcon'
import XIcon from 'xui/components/XIcon'
import HelpButton from 'xui/components/HelpButton'
import XSpinner from 'xui/components/XSpinner'
import { RefreshControl } from 'react-native'
import VerifySlipIcon from 'xui/components/VerifySlipIcon'
import _ from 'lodash'

interface IStoreSettingVerifySlipViewProps extends IXScreenProps<IStoreSettingVerifySlipNavParams> {
  selectedStore: ISelectedStoreMap
  submitEditing: ActionApiParams
  // subscription: any

  selectedStoreOrdersDates: IDateRange
  selectedStoreOrdersDatesOptionKey: IXDateRangeSelectorOptionKey
}

interface IStoreSettingVerifySlipViewState {
  isInitialized?: boolean
  isOnboardMenuShow?: boolean

  paymentAccounts: IPaymentAccount[]

  verifySlipExpireHourLimit?: number
}

class StoreSettingVerifySlipView extends React.Component<IStoreSettingVerifySlipViewProps, IStoreSettingVerifySlipViewState> {
  static displayName = 'StoreSettingVerifySlipView'

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,
      isOnboardMenuShow: false,

      paymentAccounts: [],

      verifySlipExpireHourLimit: 0,
    }
  }

  async componentDidMount() {
    await this._initialize()
    await this._onRefresh()
  }

  _initialize = async () => {
    const isOnboarding = util.getNavParam(this.props, 'isOnboarding', false)

    if (isOnboarding) {
      await util.setStatePromise(this, { isOnboardMenuShow: true })
    }
  }

  _onRefresh = async () => {
    await this._initSettings()
    await this._initBankAccounts()
  }

  _initSettings = async () => {
    const { selectedStore } = this.props
    const s_verify_slip_expire_hour_limit: boolean = selectedStore.get('s_verify_slip_expire_hour_limit') || 0

    await util.setStatePromise(this, { verifySlipExpireHourLimit: s_verify_slip_expire_hour_limit })
  }

  fetchPaymentAccounts = async () => {
    const store_id = util.getNavParam(this.props, 'store_id')

    const apiOptions: IApiOptions = {
      showSpinner: true,
      // @ts-ignore :: goback แบบไม่สนใน error object
      onUserCloseAlert: this.goBack,
    }

    const reqBody = {
      store_id,
    }

    try {
      const res: { payment_accounts: IPaymentAccount[] } = await api.post(api.POST_BANKACCOUNTS, reqBody, apiOptions)
      console.log('fetchPaymentAccounts res =>', res)

      if (res.payment_accounts) {
        await util.setStatePromise(this, { paymentAccounts: res.payment_accounts })
      }
    } catch (err) {
      console.log('err => ', err)
    }
  }

  patchPaymentAccount = async (pa: IPaymentAccount, field: string, value: any) => {
    const store_id = util.getNavParam(this.props, 'store_id')

    const reqBody = {
      store_id,
      payment_account_id: pa.id,
    }

    reqBody[field] = value

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res = await api.patch('account', reqBody, apiOptions)
    console.log('patchPaymentAccount res =>', res)
    if (res.payment_accounts) {
      p.op.showToast('แก้ไขเรียบร้อยแล้ว', 'success')
      await util.setStatePromise(this, { paymentAccounts: res.payment_accounts })
    } else {
      p.op.showToast('เกิดข้อผิดพลาด', 'warning')
    }
  }

  _initBankAccounts = async () => {
    await this.fetchPaymentAccounts()
    await util.setStatePromise(this, { isInitialized: true })
  }

  submitStoreChange = async (settingKey: string, newValue: any) => {
    const { selectedStoreOrdersDates, submitEditing } = this.props
    const store_id = util.getNavParam(this.props, 'store_id')
    const reqBody = {
      store_id,
      created_at_from: selectedStoreOrdersDates.begin.format(CONS.SERVER_DATETIME_FORMAT),
      created_at_to: selectedStoreOrdersDates.end.format(CONS.SERVER_DATETIME_FORMAT),
    }
    reqBody[settingKey] = newValue

    if (settingKey === 's_use_verify_slip' && newValue === true) {
      // @ts-ignore
      reqBody.s_verify_slip_show_menu = true
    }

    if (settingKey === 's_use_verify_slip' && newValue === false) {
      const pavCount = this.getVerifySlipPaymentAccountCount()

      if (pavCount > 0) {
        const isUserConfirm = await p.op.isUserConfirm(
          'ปิดใช้งานตรวจสลิปอัตโนมัติ',
          `มี ${pavCount} บัญชีที่เปิดใช้งานตรวจสลิปอัตโนมัติอยู่ ต้องการปิดใช้งานหรือไม่?`
        )

        if (!isUserConfirm) {
          return
        }

        // await this.deactivateVerifySlipPaymentAccounts()
      }
    }

    const response = await new Promise((resolve) => {
      // @ts-ignore
      submitEditing({
        body: reqBody,
        successCallback: resolve,
        failedCallback: () => resolve(null),
      })
    })
    console.log('response => ', response)
  }

  setVerifySlipExpireHourLimit = async (value: number) => {
    await util.setStatePromise(this, { verifySlipExpireHourLimit: value })
  }

  getVerifySlipPaymentAccounts = () => {
    const pas = this.getAvailableVerifySlipPaymentAccounts()
    return pas.filter((pa) => pa.is_for_verify_slip)
  }

  // deactivateVerifySlipPaymentAccounts = async () => {
  //   const verifySlipAccounts = this.getVerifySlipPaymentAccounts()

  //   for (let i = 0; i < verifySlipAccounts.length; i++) {
  //     const pa = verifySlipAccounts[i]
  //     await this.patchPaymentAccount(pa, 'is_for_verify_slip', false)
  //     await util.delay(50)
  //   }
  // }

  getAvailableVerifySlipPaymentAccounts = () => {
    const { paymentAccounts } = this.state
    return paymentAccounts.filter((pa) => !util.isSystemBankID(pa.bank_id)).filter((pa) => util.isVerifySlipBankID(pa.bank_id))
  }

  getAvailableVerifySlipPaymentAccountCount = () => {
    const pas = this.getAvailableVerifySlipPaymentAccounts()
    return pas.length
  }

  getVerifySlipPaymentAccountCount = () => {
    const pas = this.getAvailableVerifySlipPaymentAccounts()
    return pas.filter((pa) => pa.is_for_verify_slip).length
  }

  isOnboardConditionOneDone = () => {
    const { selectedStore } = this.props
    const s_use_verify_slip: boolean = selectedStore.get('s_use_verify_slip') || false
    return s_use_verify_slip
  }

  isOnboardConditionTwoDone = () => {
    const pavCount = this.getAvailableVerifySlipPaymentAccountCount()
    return pavCount > 0
  }

  isOnboardConditionThreeDone = () => {
    const verifySlipAccounts = this.getVerifySlipPaymentAccounts()
    return verifySlipAccounts.length > 0
  }

  renderOnboardDialog = () => {
    const { selectedStore } = this.props
    const { isOnboardMenuShow, isInitialized } = this.state
    const s_use_verify_slip: boolean = selectedStore.get('s_use_verify_slip') || false

    // if (!isOnboardMenuShow) {
    //   return null
    // }

    if (!isInitialized) {
      return null
    }

    // const pavCount = this.getVerifySlipPaymentAccountCount()
    // if (isOnboardMenuShow && s_use_verify_slip && pavCount > 0) {
    if (this.isOnboardConditionOneDone() && this.isOnboardConditionTwoDone() && this.isOnboardConditionThreeDone()) {
      return (
        <VStack w='full' py='1' px='2' space='2' bg='white'>
          <VStack w='full' p='2' borderRadius='lg' bg='success.200'>
            <HStack w='full' alignItems='center' justifyContent='center'>
              <XText fontSize='md' bold>
                ฟีเจอร์พร้อมใช้งานแล้ว
              </XText>
            </HStack>
            {/* <HStack w='full' alignItems='center' justifyContent='center'>
              <XText fontSize='md'>คุณสามารถออกจากเพื่อใช้งานแอปได้ตามปกติ</XText>
            </HStack> */}
          </VStack>
        </VStack>
      )
    }

    return (
      <VStack w='full' py='1' px='2' space='2' bg='white'>
        <VStack w='full' p='2' borderRadius='lg' bg='muted.100'>
          <HStack w='full' pb='3' alignItems='center' justifyContent='center'>
            <XText fontSize='md' bold>
              แนะนำวิธีเปิดใช้งานฟีเจอร์ตรวจสลิปอัตโนมัติ
            </XText>
          </HStack>
          <VStack w='full' px='2' flexWrap='wrap'>
            <HStack w='full'>
              <XIcon w='28px' family='FontAwesome' name={this.isOnboardConditionOneDone() ? 'check-square-o' : 'square-o'} />
              <XText flex={1} fontSize='md'>
                1. เปิดสวิทต์ "ใช้งานฟีเจอร์ตรวจสลิปอัตโนมัติ"
              </XText>
            </HStack>

            <HStack w='full'>
              <XIcon w='28px' family='FontAwesome' name={this.isOnboardConditionTwoDone() ? 'check-square-o' : 'square-o'} />
              <XText flex={1} fontSize='md'>
                2. กดปุ่ม "เพิ่มบัญชี" หากไม่เห็นบัญชีธนาคารที่ใช้ในการรับชำระ
              </XText>
            </HStack>

            <HStack w='full'>
              <XIcon w='28px' family='FontAwesome' name={this.isOnboardConditionThreeDone() ? 'check-square-o' : 'square-o'} />
              <XText flex={1} fontSize='md'>
                3. เปิดสวิทต์ของแต่ละบัญชีธนาคาร(ถ้าปิดอยู่) ที่ต้องการใช้งานกับฟีเจอร์นี้
              </XText>
            </HStack>

            {/* <HStack w='full' pt='4' alignItems='center' justifyContent='center'>
              <XText fontSize='md'>ง่ายๆ เพียงเท่านี้ เราก็ไม่ต้องคอยตรวจสอบสลิปเองทั้งหมดอีกต่อไป</XText>
            </HStack> */}
          </VStack>
        </VStack>
      </VStack>
    )
  }

  renderVerifySlipSettings = () => {
    const { selectedStore } = this.props
    const { verifySlipExpireHourLimit, isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    const s_use_verify_slip: boolean = selectedStore.get('s_use_verify_slip') || false
    const s_verify_slip_show_menu: boolean = _.isBoolean(selectedStore.get('s_verify_slip_show_menu'))
      ? selectedStore.get('s_verify_slip_show_menu')
      : true
    const s_verify_slip_expire_hour_limit: boolean = selectedStore.get('s_verify_slip_expire_hour_limit') || 0

    // @ts-ignore
    const isSubmitValueShow = parseInt(verifySlipExpireHourLimit) !== parseInt(s_verify_slip_expire_hour_limit)
    const apaCount = this.getAvailableVerifySlipPaymentAccountCount()
    const pavCount = this.getVerifySlipPaymentAccountCount()

    return (
      <VStack w='full' py='2' px='3' space='2' bg='white'>
        <HStack w='full'>
          <HStack flex={1} alignItems='center'>
            <XText>ใช้งานฟีเจอร์ตรวจสลิปอัตโนมัติ </XText>
            <HelpButton
              onPress={() =>
                p.op.alert(
                  'ใช้งานฟีเจอร์ตรวจสลิปอัตโนมัติ',
                  'เมื่อเปิดใช้งาน บัญชีรับชำระที่ถูกเปิดใช้งานอยู่ทางด้านล่างนี้ ระบบจะทำการตรวจสอบสลิปให้โดยอัตโนมัติ และหากตรวจสอบผ่านระบบก็สามารถยืนยันการชำระให้โดยอัตโนมัติได้ทันที'
                )
              }
            />
            {/* <XText variant='inactive'>
              เมื่อเปิดใช้งาน บัญชีรับชำระที่ถูกเปิดใช้งานอยู่ทางด้านล่างนี้ ระบบจะทำการตรวจสอบสลิปให้โดยอัตโนมัติ และหากตรวจสอบผ่าน
              ระบบก็สามารถยืนยันการชำระให้โดยอัตโนมัติได้ทันที
            </XText> */}
          </HStack>
          <HStack w='50px' justifyContent='flex-end'>
            <XSwitch
              value={s_use_verify_slip}
              onValueChange={(newValue: boolean) => this.submitStoreChange('s_use_verify_slip', newValue)}
            />
          </HStack>
        </HStack>

        {
          // hide this menu if the s_use_verify_slip is true
          selectedStore.get('s_use_verify_slip') ? null : (
            <HStack w='full'>
              <HStack flex={1} alignItems='center'>
                <XText>แสดงเมนูตรวจสอบสลิปอัตโนมัติ</XText>
                <HelpButton
                  onPress={() =>
                    p.op.alert(
                      'แสดงเมนูตรวจสอบสลิปอัตโนมัติ',
                      'แสดงเมนูตรวจสอบสลิปอัตโนมัติในหน้าหลัก แม้ปิดการใช้งานฟีเจอร์ตรวจสลิปอัตโนมัติ เพื่อคุณสามารถยังคงดูยอดเครดิตคงเหลือ และประวัติการใช้งานเครดิตได้'
                    )
                  }
                />
                {/* <XText variant='inactive'>
                เมื่อไม่ได้เปิดใช้งานฟีเจอร์นี้ สามารถเแสดงหรือซ่อนสิ่งที่เกี่ยวข้องกับการตรวจสอบสลิปอัตโนมัติได้
              </XText> */}
              </HStack>
              <HStack w='50px' justifyContent='flex-end'>
                <XSwitch
                  value={s_verify_slip_show_menu}
                  onValueChange={(newValue: boolean) => this.submitStoreChange('s_verify_slip_show_menu', newValue)}
                />
              </HStack>
            </HStack>
          )
        }

        <HStack w='full' space='1'>
          <HStack flex={1} alignItems='flex-start'>
            <XText>จำนวนชั่วโมงย้อนหลังที่อนุญาตให้ใช้สลิปได้</XText>
            <HelpButton
              onPress={() =>
                p.op.alert(
                  'จำนวนชั่วโมงย้อนหลังที่อนุญาตให้ใช้สลิปได้',
                  'การตั้งค่านี้มีไว้ป้องกันผู้ซื้อนำสลิปเก่าที่เคยโอนให้ทางร้านจริง ยอดตรง บัญชีถูกต้อง ' +
                    'แต่ยังไม่เคยถูกนำมาตรวจสอบใน XSelly มาแอบอ้างว่าเป็นการโอนให้สำหรับออเดอร์ใหม่ ' +
                    'โดยหากสลิปนั้นเป็นการชำระนานเกินกำหนดจำนวนชั่วโมงที่ระบุไว้ ระบบจะถือว่าสลิปนั้นไม่ผ่านการตรวจสอบ ตัวอย่างเช่น\n' +
                    '-------\n' +
                    'จำนวนชั่วโมงถูกระบุไว้ 1 ชั่วโมงและสลิปถูกโอนเมื่อ 1PM หากสลิปนี้ถูกนำมาตรวจสอบในระบบ ' +
                    'ณ เวลา 3 PM ระบบจะปฏิเสธการชำระนี้\n' +
                    '-------\n' +
                    'หมายเหตุ: คุณสามารถระบุ 0 หากต้องการให้อนุญาตให้ใช้สลิปที่ชำระมานานแล้วแค่ไหนก็ได้ในการตรวจสอบ'
                )
              }
            />
            {/* <XText variant='inactive'>หากเกินกำหนดเวลา ระบบจะถือว่าการตรวจนั้นไม่ผ่าน</XText> */}
            {/* <XText variant='inactive'>ถ้าไม่จำกัดเวลา ให้กำหนดเป็น 0</XText> */}
          </HStack>
          <VStack w='110px' space='1'>
            <XNumericInput
              minValue={0}
              maxValue={9999}
              value={verifySlipExpireHourLimit}
              onChangeText={this.setVerifySlipExpireHourLimit}
            />
            <XText w='110px' variant='inactive' textAlign='center'>
              (ชั่วโมง)
            </XText>

            {isSubmitValueShow ? (
              <XButton
                // @ts-ignore
                onPress={() => this.submitStoreChange('s_verify_slip_expire_hour_limit', parseInt(verifySlipExpireHourLimit))}>
                ยืนยัน
              </XButton>
            ) : null}
          </VStack>
        </HStack>

        <HStack w='full' alignItems='center'>
          <XText variant='active'>บัญชีรับชำระสำหรับการตรวจสลิปอัตโนมัติ</XText>
          <HelpButton
            onPress={() =>
              p.op.alert(
                'บัญชีรับชำระสำหรับการตรวจสลิปอัตโนมัติ',
                'รายการบัญชีรับชำระของธนาคารที่*รองรับ*การตรวจสลิปอัตโนมัติ โดย...\n' +
                  '- บัญชีที่ *เปิด* สวิทต์การใช้งานไว้ จะได้รับการยืนยันโดยอัตโนมัติหาก' +
                  'ยอดโอนถูกต้องและฟีเจอร์ตรวจสลิปอัตโนมัติถูกเปิดการใช้งานอยู่\n' +
                  '- บัญชีที่ *ปิด* สวิทต์การใช้งานไว้ จะไม่มีการตรวจสอบสลิปอัตโนมัติ และทางร้านสามารถยืนยันได้ด้วยตัวเอง'
              )
            }
          />
        </HStack>

        <HStack w='full' space='1'>
          <HStack flex={1} alignItems='center'>
            <XText variant='inactive'>
              <XText variant='active' bold>
                {pavCount}
              </XText>{' '}
              จาก <XText variant='active'>{apaCount}</XText> บัญชีที่เปิดใช้งานอยู่
            </XText>
            {/* <XText variant='inactive' fontSize='xs'>
              หมายเหตุ: แสดงเฉพาะบัญชีที่มีธนาคารสามารถใช้ใช้งานร่วมกับการตรวจสลิปอัตโนมัติได้เท่านั้น
            </XText> */}
          </HStack>
          <VStack w='114px' justifyContent='flex-end'>
            <XButton variant='outline' onPress={this.navToAddBankAccount} leftIcon={<XIcon name='plus' family='FontAwesome' />}>
              เพิ่มบัญชี
            </XButton>
          </VStack>
        </HStack>
      </VStack>
    )
  }

  showAlertSettingWasDisabled = () => {
    p.op.alert('กรุณาเปิดใช้งานฟีเจอร์ตรวจสลิปอัตโนมัติ')
  }

  renderPaymentAccountCardItem = (account: IPaymentAccount, index: number) => {
    if (util.isSystemBankID(account.bank_id)) {
      return null
    }
    if (!util.isVerifySlipBankID(account.bank_id)) {
      return null
    }

    const { selectedStore } = this.props
    const s_use_verify_slip = selectedStore.get('s_use_verify_slip') || false

    const isSettingDisabled = !s_use_verify_slip

    let bankName = null
    let bankNameKey
    const foundBankIndex = CONS.BANK_INFO.findIndex((sBank) => sBank.id === account.bank_id)
    if (foundBankIndex > -1) {
      bankNameKey = CONS.BANK_INFO[foundBankIndex].key
    } else {
      bankNameKey = CONS.BANK_INFO[0].key
    }
    bankName = p.op.t(`Bank.${bankNameKey}`)

    return (
      <XCard
        key={`${account.id}_${index}`}
        bg={isSettingDisabled ? 'gray.100' : 'white'}
        onPress={isSettingDisabled ? this.showAlertSettingWasDisabled : undefined}>
        <VStack w='full' p='2'>
          <HStack w='full' space='1' alignItems='center'>
            <BankIcon bankId={account.bank_id} />

            {/* // ธนาคาร // */}
            <XText _web={{ maxW: '90px' }} variant='inactive'>
              {bankName}
            </XText>

            <HStack flex={1} px='1' space='1' alignItems='center' justifyContent='flex-end' flexWrap='wrap'>
              <VStack minH='9' space='1' alignItems='flex-end' justifyContent='center' flexWrap='wrap'>
                <HStack minW='100px' maxW='140px' justifyContent='flex-end'>
                  <XText>{account.name}</XText>
                </HStack>

                {account.account_number ? <XText>{account.account_number}</XText> : <XText>-</XText>}
              </VStack>
            </HStack>

            <VStack w='48px' p='1.5' space='1.5' alignItems='center' justifyContent='center' borderLeftWidth='1' borderLeftColor='gray.200'>
              <VerifySlipIcon blue={account.is_for_verify_slip && s_use_verify_slip} inactive={!s_use_verify_slip} />
              <XSwitch
                disabled={isSettingDisabled}
                value={account.is_for_verify_slip || false}
                // backgroundActive={s_use_verify_slip ? '#60BA60' : COLORS.TEXT_INACTIVE}
                onValueChange={(newValue: boolean) => this.patchPaymentAccount(account, 'is_for_verify_slip', newValue)}
              />
            </VStack>
          </HStack>

          {/* {account.is_for_verify_slip && !s_use_verify_slip ? (
            <HStack w='full' space='1' alignItems='center'>
              <XText variant='inactive'>รองรับการตรวจสอบสลิปอัตโนมัติ แต่ยังไม่ได้เปิดใช้งานฟีเจอร์ตรวจสลิปอัตโนมัติ</XText>
            </HStack>
          ) : null} */}
        </VStack>
      </XCard>
    )
  }

  renderPaymentAccountCardItems = () => {
    const { paymentAccounts = [], isInitialized = false } = this.state
    if (!isInitialized) {
      return <XSpinner />
    }

    if (!paymentAccounts || paymentAccounts.length === 0) {
      return (
        <XText flex={1} mt='2' textAlign='center' variant='inactive'>
          {'ยังไม่มีบัญชีรับชำระที่สำหรับการตรวจสลิปอัตโนมัติ\nกรุณาเพิ่มบัญชีรับชำระโดยกดปุ่ม "+ เพิ่มบัญชี"'}
        </XText>
      )
    }

    return paymentAccounts.map(this.renderPaymentAccountCardItem)
  }

  navToAddBankAccount = () => {
    const { navigation } = this.props
    const store_id = util.getNavParam(this.props, 'store_id')

    navigation.dispatch(
      NavActions.navToBankEditView({
        store_id,
        account: null,
        succeedCallback: this._initBankAccounts,
        isVerifySlipBankAccountOnly: true,
      })
    )
  }

  goBack = async () => {
    const { selectedStore } = this.props
    const s_use_verify_slip: boolean = selectedStore.get('s_use_verify_slip') || false
    const pavCount = this.getVerifySlipPaymentAccountCount()

    let isExitConfirmed = true

    if (s_use_verify_slip && pavCount === 0) {
      isExitConfirmed = await new Promise((resolve) => {
        p.op.showConfirmation(
          'คุณยังไม่ได้เปิดใช้งานบัญชีรับชำระ',
          'คุณได้*เปิด*การใช้งานฟีเจอร์ตรวจสลิปอัตโนมัติ แต่บัญชีรับชำระยังไม่ได้ถูกเปิดใช้งาน\n\n' +
            '**กรุณาเปิดใช้งานอย่างน้อย 1 บัญชีรับชำระ** เพื่อให้ระบบใช้อ้างอิงในการตรวจสอบ' +
            'ว่าบัญชีปลายทางที่สลิปโอนไปนั้นเป็นของคุณ\n\n' +
            'หากต้องการ*ปิด*การใช้งานฟีเจอร์ตรวจสลิปอัตโนมัติ ให้กดปุ่ม "ออกจากหน้านี้"',
          () => resolve(false),
          () => resolve(true),
          'ปิดแจ้งเตือนนี้',
          'ออกจากหน้านี้'
        )
      })

      if (isExitConfirmed) {
        await this.submitStoreChange('s_use_verify_slip', false)
      }
    }

    if (!isExitConfirmed) {
      return
    }

    util.navGoBack(this.props)
  }

  // @ts-ignore
  renderRefreshControl = () => (p.op.isWeb() ? undefined : <RefreshControl refreshing={false} onRefresh={this._onRefresh} />)

  render() {
    return (
      <XContainer>
        <XCustomHeader title='ตรวจสลิปอัตโนมัติ' headerLeftProps={{ backIcon: true, onPressItem: this.goBack }} />
        <XContent refreshControl={this.renderRefreshControl()}>
          {this.renderOnboardDialog()}
          {this.renderVerifySlipSettings()}
          <VStack w='full' p='2' space='1.5'>
            {this.renderPaymentAccountCardItems()}
            {/* {isLoading ? VIEW_LOADING(windowWidth, windowHeight) : null} */}
          </VStack>
        </XContent>
      </XContainer>
    )
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    editingStore: getEditingStore(state),
    subscription: getSubscription(state),

    selectedStoreOrdersDates: getSelectedStoreOrdersDates(state),
    selectedStoreOrdersDatesOptionKey: getSelectedStoreOrdersDatesOptionKey(state),
  }),
  (dispatch) => ({
    submitEditing: bindActionCreators(StoreActions.submitEditingStore, dispatch),
    resetEditingStore: bindActionCreators(StoreActions.resetEditingStore, dispatch),
    // storeActions: bindActionCreators(StoreState, dispatch),
  })
)(StoreSettingVerifySlipView)
