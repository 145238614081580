import { Component } from 'react'

import * as util from 'x/utils/util'

import { ISelectedStoreMap } from 'x/index'

import { IPaymentAccount, IXScreenProps } from 'x/types'
// import axios from 'axios'

export interface IFileUpload {
  name: string
  lastModified: number
  webkitRelativePath: string
  size: number
  type: string
  path: string
}

interface iPostDateSameValueForAll {
  mode: 'same-value-for-al'
  value: string
}

interface iPostDateSameCellForAll {
  mode: 'same-cell-for-all'
  col: string
  row: string
}

export interface iSendHTTPPostToUploadFile {
  path: string
  formData: any
  authToken: string | any
  handleResponse: (res: { uuid: string } | string) => void
}

export interface iCustomDataShippingTypeSelector {
  index: number
  name: string
  logo?: any
  example: string
  starting_data_row: string
  tracking_code_col: string
  cod_amount_col: string
  template_validation_col: string
  template_validation_row: string
  template_validation_val: string
  post_date?: string // iPostDateSameValueForAll | iPostDateSameCellForAll | iPostDateByRow
  sheet_idx: string
}

export interface BaseImportAddProductViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  subscription: Map<string, any>
}

export interface BaseImportAddProductViewState {
  fileUpload: IFileUpload
  isLoading: boolean
  successAddProduct: number
  importErrorColumn: string
  importErrorRow: number
  importErrorCode: string
  disabledDownloadTemplate: boolean

  indexSelectorShippingType: number
  selectedPaymentAccount: IPaymentAccount | null
  selectedShippingType: iCustomDataShippingTypeSelector | null
  selectedDateTime: Date

  openDatePicker: boolean
  openTimePicker: boolean

  selectedDate: Date
  selectedTime: Date
}

export default abstract class BaseImportAddProductView extends Component<BaseImportAddProductViewProps, BaseImportAddProductViewState> {
  // abstract sendHTTPPostToUploadFile(data: iSendHTTPPostToUploadFile): () => void
  inProcess: boolean

  COSTOM_DATA_SHIPPING_TYPE_SELECTOR: iCustomDataShippingTypeSelector[]

  constructor(props) {
    super(props)
    this.state = {
      fileUpload: null,
      isLoading: false,
      successAddProduct: null,
      importErrorColumn: null,
      importErrorRow: null,
      importErrorCode: null,
      disabledDownloadTemplate: false,
      indexSelectorShippingType: -1,
      selectedPaymentAccount: null,
      selectedShippingType: null,
      selectedDateTime: new Date(),

      openDatePicker: false,
      openTimePicker: false,
      selectedDate: new Date(),
      selectedTime: new Date(),
    }
    this.inProcess = false
    this.COSTOM_DATA_SHIPPING_TYPE_SELECTOR = [
      {
        index: 0,
        name: 'ไปรษณีย์ไทย V.1',
        example: 'https://xsf.sgp1.digitaloceanspaces.com/x/samples/tp/cod_ems_v1.xls',
        cod_amount_col: 'C',
        starting_data_row: '6',
        template_validation_col: 'B',
        template_validation_row: '5',
        template_validation_val: 'หมายเลขพัสดุ COD',
        tracking_code_col: 'B',
        post_date: `{"mode":"by-row","col":"J","row":6,"time_format":"02/01/2006"}`,
        sheet_idx: '1',
      },
      {
        index: 1,
        name: 'ไปรษณีย์ไทย V.2',
        example: 'https://xsf.sgp1.digitaloceanspaces.com/x/samples/tp/cod_ems_v2.xlsx',
        cod_amount_col: 'D',
        starting_data_row: '6',
        template_validation_col: 'C',
        template_validation_row: '5',
        template_validation_val: 'หมายเลขพัสดุCOD',
        tracking_code_col: 'C',
        sheet_idx: '1',
      },
      {
        index: 2,
        name: 'Flash',
        example: 'https://xsf.sgp1.digitaloceanspaces.com/x/samples/tp/cod_flash.xlsx',
        cod_amount_col: 'H',
        starting_data_row: '2',
        template_validation_col: 'C',
        template_validation_row: '1',
        template_validation_val: 'Tracking no.',
        tracking_code_col: 'C',
        post_date: `{"mode":"by-row","col":"G","row":2,"time_format":"02-01-06 15:04"}`,
        sheet_idx: '2',
      },
      {
        index: 3,
        name: 'J&T',
        example: 'https://xsf.sgp1.digitaloceanspaces.com/x/samples/tp/cod_jt.xls',
        cod_amount_col: 'G',
        starting_data_row: '17',
        template_validation_col: 'C',
        template_validation_row: '16',
        template_validation_val: 'Tracking no.',
        tracking_code_col: 'C',
        post_date: `{"mode":"same-cell-for-all","col":"G","row":7,"time_format":"2006-01-02"}`,
        sheet_idx: '1',
      },
      {
        index: 4,
        name: 'Kerry V.1',
        example: 'https://xsf.sgp1.digitaloceanspaces.com/x/samples/tp/cod_kerry.xlsx',
        cod_amount_col: 'H',
        starting_data_row: '6',
        template_validation_col: 'B',
        template_validation_row: '5',
        template_validation_val: 'Consignment No',
        tracking_code_col: 'B',
        post_date: `{"mode":"by-row","col":"M","row":6,"time_format":"02-01-2006"}`,
        sheet_idx: '1',
      },
      {
        index: 5,
        name: 'Kerry V.2 (แปลงมาจาก PDF)',
        example: 'https://xsf.sgp1.digitaloceanspaces.com/x/samples/tp/cod_kerry_v2_from_pdf.xlsx',
        cod_amount_col: 'K',
        starting_data_row: '3',
        template_validation_col: 'A',
        template_validation_row: '2',
        template_validation_val: 'Con No.',
        tracking_code_col: 'A',
        post_date: `{"mode":"by-row","col":"H","row":3,"time_format":"02/01/2006"}`,
        sheet_idx: '1',
      },
      {
        index: 6,
        name: 'Kerry V.3 (แปลงมาจาก PDF)',
        example: 'https://xsf.sgp1.digitaloceanspaces.com/x/samples/tp/cod_kerry_v3_from_pdf.xlsx',
        cod_amount_col: 'K',
        starting_data_row: '2',
        template_validation_col: 'A',
        template_validation_row: '1',
        template_validation_val: 'Con No.',
        tracking_code_col: 'A',
        post_date: `{"mode":"by-row","col":"H","row":2,"time_format":"02/01/2006"}`,
        sheet_idx: '3',
      },
    ]
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _onChangeDateTime = (newDates: Date) => {
    util.setStatePromise(this, { selectedDateTime: newDates })
    // if (p.op.isIOS()) {
    //   util.setStatePromise(this, { openDatePicker: false })
    // }
  }

  _setFileUpload = (acceptedFiles: IFileUpload[]) => {
    // console.log(acceptedFiles)
    util.setStatePromise(this, {
      fileUpload: acceptedFiles[0],
    })
  }

  _onPressSelectorShippingType = (shippingType: iCustomDataShippingTypeSelector) => {
    util.setStatePromise(this, {
      selectedShippingType: shippingType,
      indexSelectorShippingType: shippingType.index,
    })
  }

  _startLoading = async () => {
    this.inProcess = true
    await util.setStatePromise(this, {
      isLoading: true,
    })
  }

  _stopLoading = async () => {
    this.inProcess = false
    await util.setStatePromise(this, {
      isLoading: false,
    })
  }

  // onPressUpload = async () => {
  //   const { selectedStore, subscription } = this.props
  //   if (subscription.get('type') === CONS.SUBSCRIPTION_PACKAGE.FREE) {
  //     p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
  //     return
  //   }
  //   const { fileUpload, selectedPaymentAccount, selectedShippingType, selectedDateTime } = this.state
  //   if (this.inProcess) {
  //     return
  //   }
  //   this.inProcess = true
  //   await util.setStatePromise(this, {
  //     isLoading: true,
  //   })

  //   if (_.isNil(selectedPaymentAccount)) {
  //     p.op.showConfirmationOkOnly('', 'กรุณาเลือกบัญชีรับชำระ')
  //     await this._stopLoading()
  //     return
  //   }

  //   if (_.isNil(selectedShippingType)) {
  //     p.op.showConfirmationOkOnly('', 'กรุณาเลือกรูปแบบนำเข้า Excel COD')
  //     await this._stopLoading()
  //     return
  //   }

  //   const formData = new FormData()

  //   // const xFileUpload =
  //   // @ts-ignore
  //   // formData.append('file', fileUpload)
  //   formData.append('file', {
  //     // @ts-ignore
  //     uri: fileUpload.path,
  //     type: fileUpload.type,
  //     name: fileUpload.name,
  //   })
  //   formData.append('store_id', `${selectedStore.get('id')}`)
  //   // formData.append('store_id', '11111111')
  //   formData.append('payment_account_id', `${selectedPaymentAccount.id}`)
  //   formData.append('sheet_idx', `${selectedShippingType.sheet_idx}`)
  //   formData.append('starting_data_row', `${selectedShippingType.starting_data_row}`)
  //   formData.append('tracking_code_col', `${selectedShippingType.tracking_code_col}`)
  //   formData.append('cod_amount_col', `${selectedShippingType.cod_amount_col}`)
  //   formData.append('template_validation_col', `${selectedShippingType.template_validation_col}`)
  //   formData.append('template_validation_row', `${selectedShippingType.template_validation_row}`)
  //   formData.append('template_validation_val', `${selectedShippingType.template_validation_val}`)

  //   if (_.isNil(selectedShippingType.post_date)) {
  //     const dateTime = util.getTimeFormatYYYYMMDDhhmmss(selectedDateTime)
  //     const postDate = ` {"mode":"same-value-for-all","value":"${dateTime}","time_format":"2006-01-02 15:04:05"}`
  //     formData.append('post_date', `${postDate}`)
  //   } else {
  //     formData.append('post_date', `${selectedShippingType.post_date}`)
  //   }
  //   const path = `excel/import/payment_cod`
  //   const url = getConfig()
  //   const token = await p.op.storageGet(CONS.AUTHENTICATION_STORAGE_KEY, false)

  //   const data = {
  //     path: `${url.app_goApiRoot}/${path}`,
  //     formData: p.op.isWeb() ? fileUpload : formData,
  //     authToken: token,
  //     handleResponse: (res: {uuid: string} | string) => {console.log('res => ', res)}
  //   }
  //   this.sendHTTPPostToUploadFile(data)
  //   return
  //   try {
  //     // console.log('_pickImages upload request formData => ', formData)
  //     const path = `excel/import/payment_cod`
  //     const url = getConfig()
  //     const token = await p.op.storageGet(CONS.AUTHENTICATION_STORAGE_KEY, false)
  //     const res = await fetch(`${url.app_goApiRoot}/${path}`, {
  //       method: 'POST',
  //       //@ts-ignore
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'multipart/form-data',
  //         // Authorization: auth.getAuthenticationToken(),
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: formData,
  //     })
  //     const resJson: { bgjob_uuid : string } = await res.json()
  //     // console.log('_pickImages upload resJson => ', resJson)
  //     if (resJson.bgjob_uuid) {
  //       await util.alertWithNavToBgJobListView({
  //         title: 'สร้าง "คิวคำสั่งงาน" สำเร็จ',
  //         message:
  //           'คิวคำสั่งงาน สำหรับการ "กระทบยอด COD" ได้ถูกสร้างแล้ว ระบบกำลังดำเนินการอยู่เบื้องหลัง และอาจใช้เวลาซักครู่จนกว่าจะครบทุกออเดอร์',
  //         dispatch: this.props.navigation.dispatch,
  //         selectedStoreId: this.props.selectedStore.get('id'),
  //         onUserConfirm: () => this._goBack(),
  //         initBgJobUUID: resJson.bgjob_uuid,
  //       })
  //       // this.inProcess = false
  //       await this._stopLoading()
  //       return
  //     } else {
  //       // this.inProcess = false
  //       // p.op.showConfirmationOkOnly('', `เกิดข้อผิดพลาด ${resJson}`)
  //       // @ts-ignore
  //       const errorCode = !_.isNil(resJson.error.error_code) ? resJson.error.error_code : null
  //       if (!_.isNil(errorCode)) {
  //         const textErrorCode = util.getBgTaskErrorText(errorCode)
  //         p.op.showConfirmationOkOnly('', textErrorCode)
  //         await this._stopLoading()
  //         return
  //       }
  //     }
  //     await this._stopLoading()
  //   } catch (err) {
  //     // console.log('_pickImages upload err => ', err)
  //     const errorCode = !_.isNil(err.error.error_code) ? err.error.error_code : null
  //     if (!_.isNil(errorCode)) {
  //       const textErrorCode = util.getBgTaskErrorText(errorCode)
  //       p.op.showConfirmationOkOnly('', textErrorCode)
  //       await this._stopLoading()
  //       return
  //     }
  //     await this._stopLoading()
  //   }
  // }
}
