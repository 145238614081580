import React, { Component } from 'react'
import { COLORS, STYLES } from 'x/config/styles'
// import { log, setStatePromise } from 'x/utils/util'
// import { Storage } from 'aws-amplify'
import _ from 'lodash'
// import settings from 'x/config/settings'
// import { Map, List, fromJS } from 'immutable'
// import { getTimeString, getRandomString } from 'x/utils/file'

import BaseSegment from 'x/components/BaseSegment'

import { StyleSheet, Platform, TextStyle, StyleProp, LayoutChangeEvent, TouchableOpacity } from 'react-native'

// import { Grid } from 'native-base'
import HStack from 'xui/components/HStack'
import XText from './XText'
// import DatePicker from 'react-native-datepicker'
// import p from 'x/config/platform-specific'

const { FONT_SIZE_NORMAL } = STYLES

const { APP_MAIN } = COLORS

export default class Segment extends BaseSegment {
  static displayName = 'Segment'

  _getOptionCount = () => {
    const { options = [] } = this.props
    return options.length
  }

  _getOptionWidthPercent = () => {
    const optCount = this._getOptionCount()
    if (!optCount) {
      return 100
    }
    return Math.floor(100 / optCount)
  }

  _renderSegmentItem = (opt: string, idx: number, options: string[]): JSX.Element => {
    // const { containerWidth = 0 } = this.state
    const { disabled = false, textStyle = {} } = this.props
    // const percent = 100 / options.length
    const percent = this._getOptionWidthPercent()

    // let itemWidth
    // if (containerWidth) {
    //   itemWidth = (percent / 100) * containerWidth
    // }

    const selectedIndex = this.getSelectedIndex()
    return (
      <SegmentButton
        disabled={disabled}
        key={`SegmentButton_${idx.toString()}`}
        index={idx}
        option={opt}
        isActive={selectedIndex === idx}
        isFirst={idx === 0}
        isLast={idx === options.length - 1}
        widthPercent={percent}
        // itemWidth={itemWidth}
        onPressItem={this.onSegmentChange}
        textStyle={textStyle}
      />
    )
  }

  _renderSegmentItems = (): JSX.Element[] | null => {
    const { options = [] } = this.props
    // if (!options || !_.isArray(options)) {
    //   return null
    // }

    return options.map(this._renderSegmentItem)
  }

  _onContainerLayout = (evt: LayoutChangeEvent) => {
    const { width } = evt.nativeEvent.layout
    this.setState({ containerWidth: width })
  }

  _renderContainer = () => {
    const { disabled, containerStyle = {}, options } = this.props

    if (!options || !_.isArray(options) || options.length === 0) {
      return null
    }

    return (
      <HStack
        w='full'
        onLayout={this._onContainerLayout}
        // style={[
        //   S.ROW_MIDDLE_START,
        //   S.WIDTH_FULL,
        //   S.MIN_HEIGHT_24,
        //   disabled ? s.GRID_CONTAINER_DISABLED : s.GRID_CONTAINER,
        //   StyleSheet.flatten(containerStyle),
        // ]}
        minH='8'
        borderWidth='1'
        borderRadius='lg'
        flexWrap='wrap'
        overflow='hidden'
        borderColor={disabled ? 'muted.500' : 'primary.300'}
        // borderColor={disabled ? 'muted.500' : 'muted.400'}
        // borderColor={disabled ? 'muted.500' : 'primary.500'}
        // borderColor={disabled ? 'muted.500' : 'gray.500'}
        // _light={{
        //   bg: NB.C.L.BG.CLEAR,
        // }}
        // _dark={{
        //   bg: NB.C.D.BG.CLEAR,
        // }}
        style={StyleSheet.flatten(containerStyle)}>
        {this._renderSegmentItems()}
      </HStack>
    )
  }

  render() {
    return this._renderContainer()
    // const { disabled, options } = this.props
    // if (_.isNil(options) || options.length === 0) {
    //   return null
    // }

    // return (
    //   <View
    //     onLayout={this._onContainerLayout}
    //     style={[
    //       S.ROW_MIDDLE_START,
    //       S.WIDTH_FULL,
    //       S.MIN_HEIGHT_24,
    //       disabled ? s.GRID_CONTAINER_DISABLED : s.GRID_CONTAINER,
    //       StyleSheet.flatten(this.props.containerStyle),
    //     ]}>
    //     {this._renderSegmentItems()}
    //   </View>
    // )
  }
}

interface ISegmentButtonProps {
  disabled?: boolean
  index: number
  option: string
  isFirst: boolean
  isLast: boolean
  isActive: boolean
  widthPercent: number
  onPressItem?: (index: number) => Promise<void>
  textStyle?: StyleProp<TextStyle>
  itemWidth?: number
}

class SegmentButton extends Component<ISegmentButtonProps> {
  isPressing?: boolean

  _onPressItem = async () => {
    if (this.isPressing) {
      return
    }
    this.isPressing = true

    const { index, onPressItem } = this.props
    if (_.isFunction(onPressItem)) {
      await onPressItem(index)
    }
    this.isPressing = false
  }

  render() {
    const { disabled = false, option, isActive, isFirst, isLast, widthPercent, textStyle = {} } = this.props
    // const { disabled, option, isActive, isFirst, isLast, textStyle = {}, itemWidth } = this.props

    // let txtStyle = isActive ? s.TXT_SEGMENT_ACTIVE : s.TXT_SEGMENT_INACTIVE
    // let btnActiveStyle = isActive ? s.BTN_SEGMENT_ACTIVE : s.BTN_SEGMENT_INACTIVE

    // if (disabled) {
    //   txtStyle = s.TXT_SEGMENT_DISABLED
    //   btnActiveStyle = s.BTN_SEGMENT_DISABLED
    // }

    // const btnDividerStyle = !isLast ? s.BTN_SEGMENT_DIVIDER : {}

    // let btnBorderStyle = {}
    // if (isFirst) {
    //   btnBorderStyle = s.BTN_SEGMENT_FIRST
    // } else if (isLast) {
    //   btnBorderStyle = s.BTN_SEGMENT_LAST
    // }

    const opacity = 1
    // let bg = isActive ? 'primary.500' : 'transparent'
    let bg = isActive ? 'primary.50' : 'transparent'
    // if (isHovered) {
    //   opacity = 0.9
    //   bg = isActive ? 'primary.500' : 'primary.50'
    // }
    // if (isPressed) {
    //   opacity = 0.7
    //   bg = isActive ? 'primary.500' : 'primary.100'
    // }
    if (disabled && isActive) {
      // bg = 'muted.500'
      bg = 'muted.300'
    }

    let textVariant = 'inactive'

    if (isActive && !disabled) {
      textVariant = 'primary'
    } else if (isActive && disabled) {
      textVariant = 'active'
    }

    return (
      <TouchableOpacity
        disabled={disabled}
        onPress={disabled ? undefined : this._onPressItem}
        // flex={1}
        // flexDirection
        // borderRadius='0'
        // w={`${widthPercent}%`}
        style={{ flex: 1 }}>
        {/* {({ isHovered, isPressed }) => {
          let opacity = 1
          let bg = isActive ? 'primary.500' : 'transparent'
          if (isHovered) {
            opacity = 0.9
            bg = isActive ? 'primary.500' : 'primary.50'
          }
          if (isPressed) {
            opacity = 0.7
            bg = isActive ? 'primary.500' : 'primary.100'
          }
          if (disabled && isActive) {
            bg = 'muted.500'
          }

          return ( */}
        <HStack
          flex={1}
          borderLeftWidth={isFirst ? '0' : '1'}
          borderRadius='none'
          bg={bg}
          // bg={isActive ? 'primary.500' : 'transparent'}
          // bg={isActive ? 'gray.500' : 'transparent'}
          borderLeftColor={disabled ? 'muted.300' : 'primary.300'}
          // borderLeftColor={disabled ? 'muted.300' : 'muted.400'}
          // borderLeftColor={disabled ? 'muted.300' : 'primary.500'}
          // borderLeftColor={disabled ? 'muted.500' : 'gray.500'}
          py='1'
          px='1'
          justifyContent='center'
          alignContent='center'
          alignItems='center'
          flexWrap='wrap'
          opacity={opacity}>
          <XText
            // variant={isActive ? 'activeDark' : disabled ? 'inactive' : 'primary'}
            // variant={isActive ? 'primary' : disabled ? 'active' : 'inactive'}
            // variant={disabled ? 'active' : isActive ? 'primary' : 'inactive'}
            // @ts-ignore
            variant={textVariant}
            // textDecoration={isActive ? 'underline' : 'none'}
            // bold={isActive}
            style={textStyle}>
            {/* <XText textAlign='center' textVerticalAlign='center' variant={isActive ? 'activeDark' : 'inactive'} bold={isActive}> */}
            {option}
          </XText>
        </HStack>
        {/* ) */}
        {/* }} */}
      </TouchableOpacity>
    )
    // return (
    //   <TouchableOpacity
    //     disabled={disabled}
    //     // first={isFirst}
    //     // last={isLast}
    //     // active={isActive}
    //     // style={[s.BTN_SEGMENT_COMMON, btnActiveStyle, btnBorderStyle, { width: `${widthPercent}%` }]}
    //     onPress={disabled ? undefined : this._onPressItem}
    //     style={StyleSheet.flatten([
    //       S.PADDING_VERTICAL_6,
    //       // S.PADDING_HORIZONTAL_4,
    //       S.COLUMN_CENTER,
    //       // S.HEIGHT_FULL,
    //       S.FLEX,
    //       S.FLEX_WRAP,
    //       itemWidth ? { width: itemWidth } : {},
    //       { alignSelf: 'stretch' },
    //       // { width: widthPercent },
    //       btnDividerStyle,
    //       btnActiveStyle,
    //       btnBorderStyle,
    //     ])}>
    //     <XText
    //       allowFontScaling={false}
    //       // adjustsFontSizeToFit={true}
    //       style={[
    //         S.WIDTH_FULL,
    //         s.TXT_SEGMENT_COMMON,
    //         txtStyle,
    //         S.TEXT_ALIGN_CENTER,
    //         { textAlignVertical: 'center' },
    //         StyleSheet.flatten(textStyle),
    //       ]}>
    //       {option}
    //     </XText>
    //   </TouchableOpacity>
    // )
  }
}

const s = StyleSheet.create({
  GRID_CONTAINER: {
    // flex: 1,
    // minHeight: 24,
    // flexDirection: 'row',
    // justifyContent: 'center',
    // alignItems: 'center',
    borderWidth: 1,
    borderColor: APP_MAIN,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    // paddingLeft: 4,
    // paddingRight: 4,
    ...Platform.select({
      web: {
        // flexBasis: 'auto',
        // width: '100%',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopColor: APP_MAIN,
        borderBottomColor: APP_MAIN,
        borderLeftColor: APP_MAIN,
        borderRightColor: APP_MAIN,
      },
    }),
  },
  GRID_CONTAINER_DISABLED: {
    // flex: 1,
    // minHeight: 24,
    // flexDirection: 'row',
    // justifyContent: 'center',
    // alignItems: 'center',
    borderWidth: 1,
    borderColor: COLORS.TEXT_INACTIVE,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    // paddingLeft: 4,
    // paddingRight: 4,
    ...Platform.select({
      web: {
        // flexBasis: 'auto',
        // width: '100%',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopColor: COLORS.TEXT_INACTIVE,
        borderBottomColor: COLORS.TEXT_INACTIVE,
        borderLeftColor: COLORS.TEXT_INACTIVE,
        borderRightColor: COLORS.TEXT_INACTIVE,
      },
    }),
  },
  BTN_SEGMENT_COMMON: {
    flex: 1,
    ...Platform.select({
      web: {
        flexBasis: 'auto',
        minHeight: 24,
      },
    }),
    // minHeight: 24,
    // paddingLeft:4,
    // paddingRight:4,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 0,
    paddingRight: 0,
    shadowColor: null,
    shadowOffset: null,
    shadowOpacity: null,
    shadowRadius: null,
    // flexWrap: 'wrap',
  },
  BTN_SEGMENT_ACTIVE: {
    backgroundColor: APP_MAIN,
  },
  BTN_SEGMENT_INACTIVE: {
    // backgroundColor: 'white',
    backgroundColor: 'transparent',
  },
  BTN_SEGMENT_DISABLED: {
    backgroundColor: COLORS.TEXT_INACTIVE,
  },
  BTN_SEGMENT_FIRST: {
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2,
  },
  BTN_SEGMENT_LAST: {
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    marginRight: -1,
    marginLeft: -1,
  },
  TXT_SEGMENT_COMMON: {
    // paddingVertical: 2,
    // paddingHorizontal: 4,
    // flex: 1,
    textAlign: 'center',
    fontSize: FONT_SIZE_NORMAL,
  },
  BTN_SEGMENT_DIVIDER: {
    borderRightWidth: 1,
    borderRightColor: APP_MAIN,
    // ...Platform.select({
    //   web: {
    //     flexBasis: 'auto',
    //     minHeight: 24,
    //   },
    // }),
  },
  TXT_SEGMENT_ACTIVE: {
    color: 'white',
    fontWeight: 'bold',
  },
  TXT_SEGMENT_INACTIVE: {
    color: APP_MAIN,
    fontWeight: 'normal',
  },
  TXT_SEGMENT_DISABLED: {
    color: COLORS.TEXT_ACTIVE,
    fontWeight: 'normal',
  },
})
