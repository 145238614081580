import OpInterface, { E_MSG_TYPE } from 'x/utils/op-interface'
import { log } from 'x/utils/util'
import { fromJS, isImmutable } from 'immutable'
import _ from 'lodash'
// const STATE_STORAGE_KEY = 'XSellyWebState::Latest'
// import { message, Modal } from 'antd'
// import { translationMessages, appLocales } from '../i18n'
import p from 'x/config/platform-specific'
import xCONS from 'x/config/constants'
// import { AppLinks } from '../modules/App/routes'
// import { appLocales, translationMessages } from '../i18n'
import I18n from 'x/utils/i18n'
import { Scope, TranslateOptions } from 'i18n-js'
import { toast } from 'react-toastify'
import { IActionSheetConfiguration, I18nObjectType } from 'x/index'
import ActionSheet from 'xui/components/singleton/ActionSheet'
import Alert from 'xui/components/singleton/Alert'
import Reactotron from './reactotronConfig'
import store from '../redux/store'
// import { xActionSheetRef } from '../components/XActionSheet/ActionSheetProvider'
import * as utilW from './util-web'

const { APP_PROPERTY } = xCONS.STORAGE_KEYS
const { AUTHENTICATION_STORAGE_KEY, FACEBOOK_AUTH_STORAGE_KEY } = xCONS
// import { put, call } from 'redux-saga/effects'
// import actions from 'x/config/actions'

export default class OpImplWeb implements OpInterface {
  getTranslatorObj = (locale = 'th'): I18nObjectType =>
    // const targetLocale = locale && _.includes(appLocales, locale) ? locale : p.l // <---- fallback
    // // log('translationMessages[targetLocale] => ', translationMessages[targetLocale])
    // return translationMessages[targetLocale]
    I18n.translations[locale] as I18nObjectType

  // t(msgKey: string, locale: string = null): string {
  t = (msgKey: Scope, options?: TranslateOptions) =>
    // const targetLocale = locale && _.includes(appLocales, locale) ? locale : p.l // <---- fallback
    // let translatedOutput = translationMessages[targetLocale]

    // if (msgKey) {
    //   const keyPath = msgKey.split('.')
    //   for (let i = 0; i < keyPath.length; ++i) {
    //     if (translatedOutput[keyPath[i]] == undefined) {
    //       // return undefined
    //       return `Missing: ${targetLocale} translation for ${msgKey}`
    //     } else {
    //       translatedOutput = translatedOutput[keyPath[i]]
    //     }
    //   }
    //   return translatedOutput
    // }

    // // return undefined
    // return `Missing: ${targetLocale} translation for ${msgKey}`
    I18n.t(msgKey, options)

  changeLocale = (localeKey: 'en' | 'th') => {
    I18n.locale = localeKey
  }

  // TODO
  // signOut(dispatch: any): void {
  async signOut(): Promise<void> {
    console.log('In op-impl-web.signOut')

    // const { dispatch } = store
    await this.storageClear(AUTHENTICATION_STORAGE_KEY)
    await this.storageClear(FACEBOOK_AUTH_STORAGE_KEY)
    await this.storageSet(xCONS.STORAGE_KEYS.USER_PROFILE, null)
    // dispatch({ type: 'ProfileState/CLEAR' })
    // dispatch({ type: 'profile/FB_ACCESS_TOKEN_INVALIDATED' })

    // setTimeout(() => {
    //   history.replace(AppLinks.Root)
    // }, 1000)
  }

  /**
   * Reads the app's state tree
   *
   * @returns The current state tree of application.
   */
  getAppState(): any {
    return store.getState()
  }

  getDispatch(): (action: { type: string; [key: string]: any }) => void {
    return store.dispatch
  }

  getCurrentUserId = async (): Promise<number | null> => {
    // Step 1: get from redux
    // @ts-ignore
    const profileUserId = store.getState().getIn(['profile', 'user_id']) || null
    if (profileUserId) {
      return profileUserId
    }

    // Step 2: get from async storageS
    // @ts-ignore
    const profile: { user_id: number } = await this.storageGet(xCONS.STORAGE_KEYS.USER_PROFILE, true)
    if (_.isObject(profile) && _.has(profile, 'user_id') && _.isNumber(profile.user_id)) {
      return profile.user_id
    }

    return null
  }

  showActionSheet = (configuration: IActionSheetConfiguration, onSelect: (index: number) => void): void => {
    // console.log('showActionSheet configuration => ', configuration)
    // @ts-ignore
    // xActionSheetRef.current.showActionSheetWithOptions(configuration, onSelect)

    ActionSheet.show(configuration, onSelect)
  }

  alert = (title: string, message?: string, callback?: () => void): void => {
    // confirmAlert({
    //   title,
    //   message,
    //   buttons: [
    //     {
    //       label: this.t('done'),
    //       onClick: callback,
    //     },
    //   ],
    //   closeOnClickOutside: false,
    //   closeOnEscape: false,
    // })

    Alert.alert(title, message, [
      {
        text: this.t('done'),
        onPress: callback,
      },
    ])
  }

  // alertPromise(title: string, msg?: string): Promise<void> {
  //   console.log('To be implemented: alertPromise')
  //   return new Promise(resolve => resolve)
  // }
  alertPromise = async (title: string, message?: string): Promise<void> =>
    new Promise<void>((resolve) => {
      // confirmAlert({
      //   title,
      //   message,
      //   buttons: [
      //     {
      //       label: this.t('done'),
      //       onClick: resolve,
      //     },
      //   ],
      //   closeOnClickOutside: false,
      //   closeOnEscape: false,
      //   willUnmount: resolve,
      // })
      Alert.alert(
        title,
        message,
        [
          {
            text: this.t('done'),
            onPress: resolve,
          },
        ],
        { cancelable: false }
      )
    })

  showLoadingIndicator(): void {
    console.log('TO BE IMPLEMENTED: op-impl-web.showLoadingIndicator')
  }

  hideLoadingIndicator(): void {
    console.log('TO BE IMPLEMENTED: op-impl-web.hideLoadingIndicator')
  }

  showToast = (msg: string, type: E_MSG_TYPE, duration = 1500): void => {
    let toastType
    if (type === 'danger') {
      toastType = 'error'
    } else {
      toastType = type
    }

    toast(msg, {
      position: 'top-right',
      autoClose: duration,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toastType,
    })
    // ทำให้เว้นบรรทัดได้เหมือนในแอป (ปล. message ยััด ReactNode ได้)
    // console.log('OpImplWeb.showToast.msg: ' + msg)
    // Toast.show({
    //   text: msg,
    //   duration,
    //   type,
    //   position: 'bottom',
    // })

    // let toastFunc = null
    // switch (type) {
    //   case xCONS.TOAST_TYPE.DANGER:
    //     toastFunc = message.error
    //     break
    //   case xCONS.TOAST_TYPE.SUCCESS:
    //     toastFunc = message.success
    //     break
    //   case xCONS.TOAST_TYPE.WARNING:
    //     toastFunc = message.warn
    //     break
    //   default:
    //     toastFunc = message.info
    // }
    // if (_.isFunction(toastFunc) && msg) {
    //   toastFunc(msg, duration)
    // }
  }

  async storageGetImmutable(key: string): Promise<any> {
    const state = await this.storageGet(key)
    if (state) {
      return fromJS(state)
    }
    return null
  }

  async storageGet(key: string, isJSON = true): Promise<string | null> {
    return new Promise<string>((resolve) => {
      try {
        const state = localStorage.getItem(key)
        if (isJSON) {
          resolve(state ? JSON.parse(state) : null)
        } else {
          resolve(state)
        }
      } catch (e) {
        log('Error reading persisted application state', e)
        resolve(null)
      }
    })
  }

  async storageSet(key: string, state: any, isJSON = true): Promise<void> {
    const newState = isImmutable(state) ? state.toJS() : state
    const data = isJSON ? JSON.stringify(newState) : newState
    return await new Promise<void>((resolve) => {
      try {
        if (data) {
          localStorage.setItem(key, data)
        } else {
          // delete if null
          localStorage.removeItem(key)
        }
      } catch (e) {
        log('Error persisting application state', e)
      }
      resolve(null)
    })
  }

  /**
   * (Async) Clear provided state object to async storage
   *
   * @returns {Promise}
   */
  async storageClear(key: string): Promise<void> {
    return await new Promise<void>((resolve) => {
      try {
        localStorage.removeItem(key)
      } catch (e) {
        console.error('Error clearing peristed application state', e)
      }
      resolve(null)
    })
  }

  async setAppDefaultValue(key: string, value: any): Promise<void> {
    return await this.storageGet(APP_PROPERTY).then((propertyObj) => {
      const defaultKey = `DEFAULT_${key}`
      const newProperty = _.isObject(propertyObj) ? _.cloneDeep(propertyObj) : {}
      newProperty[defaultKey] = value
      return this.storageSet(APP_PROPERTY, newProperty)
    })
  }

  async getAppDefaultValue(key: string): Promise<any> {
    return await this.storageGet(APP_PROPERTY).then((propertyObj: any) => {
      const defaultKey = `DEFAULT_${key}`
      // const pObj  = pro
      if (propertyObj && _.isObject(propertyObj) && defaultKey in propertyObj) {
        return propertyObj[defaultKey]
      }
      return null
    })
  }

  async getAppDefaultObject(): Promise<{ [key: string]: any }> {
    return await this.storageGet(APP_PROPERTY).then((propertyObj: any) => {
      if (propertyObj && _.isObject(propertyObj)) {
        const defaultKeys = Object.keys(propertyObj)
        const prefixKey = 'DEFAULT_'
        const outputDefaultObj = {}
        for (let i = 0; i < defaultKeys.length; i++) {
          const defaultKey = defaultKeys[i]
          if (defaultKey.includes(prefixKey)) {
            const defaultValue = propertyObj[defaultKey]
            outputDefaultObj[defaultKey.replace(prefixKey, '')] = defaultValue
          }
        }
        return outputDefaultObj
      }
      return {}
    })
  }

  async setAppDefaultObject(obj: Object): Promise<void> {
    return await this.storageGet(APP_PROPERTY).then((propertyObj) => {
      const newProperty: Object = _.isObject(propertyObj) ? _.cloneDeep(propertyObj) : {}
      const keys = Object.keys(obj)
      if (keys && keys.length > 0) {
        keys.forEach((key) => {
          const defaultKey = `DEFAULT_${key}`
          const defaultValue = obj[key]
          newProperty[defaultKey] = defaultValue
        })
        return p.op.storageSet(APP_PROPERTY, newProperty, true)
      }
    })
  }

  initAnalytics(): void {
    console.log('TODO: No-op from op-impl-web.initAnalytics')
  }

  aLogCurrentView(screenName: string, screenClassOverride?: string): void {
    console.log('TODO: No-op from op-impl-web.aLogCurrentView', screenName, screenClassOverride)
  }

  aLogEvent = (name: string, params?: { [key: string]: any }): void => {
    try {
      console.log('TODO: No-op from op-impl-web.aLogEvent', name, params)
      // fLogEvent(name, params)
    } catch (e) {
      throw new Error('Error::op-impl-native::aLogEvent')
    }
  }

  aLogEventWithUserId = async (name: string, params: { [key: string]: any } = {}): Promise<void> => {
    try {
      const uid = await this.getCurrentUserId()
      // fLogEvent(name, { uid, ...params })
      console.log('TODO: No-op from op-impl-web.aLogEventWithUserId', name, { uid, ...params })
    } catch (e) {
      throw new Error('Error::op-impl-native::aLogEventWithUserId')
    }
  }

  isIOS(): boolean {
    return false
  }

  isAndroid(): boolean {
    return false
  }

  isWeb(): boolean {
    return true
  }

  isUserConfirm = (title: string, message?: string, txtOK = 'ตกลง', txtCancel = 'ยกเลิก', cancelable = false): Promise<boolean> =>
    new Promise<boolean>((resolve) => {
      // confirmAlert({
      //   title,
      //   message,
      //   buttons: [
      //     {
      //       label: txtOK,
      //       onClick: () => resolve(true),
      //     },
      //     {
      //       label: txtCancel,
      //       onClick: () => resolve(false),
      //     },
      //   ],
      //   closeOnClickOutside: cancelable,
      //   closeOnEscape: false,
      // })

      Alert.alert(
        title,
        message,
        [
          {
            text: txtOK,
            onPress: () => resolve(true),
          },
          {
            text: txtCancel,
            onPress: () => resolve(false),
            style: 'cancel',
          },
        ],
        { cancelable }
      )
    })

  showConfirmation = (
    title: string,
    message: string,
    okCallback: () => void,
    cancelCallback?: () => void,
    txtOK = 'ตกลง',
    txtCancel = 'ยกเลิก'
  ): void => {
    // confirmAlert({
    //   title,
    //   message,
    //   buttons: [
    //     {
    //       label: txtOK,
    //       onClick: okCallback,
    //     },
    //     {
    //       label: txtCancel,
    //       onClick: cancelCallback,
    //     },
    //   ],
    //   closeOnClickOutside: false,
    //   closeOnEscape: false,
    // })
    // Modal.confirm({
    //   title,
    //   content: message,
    //   okText: txtOK,
    //   cancelText: txtCancel,
    //   onOk() {
    //     if (_.isFunction(okCallback)) {
    //       okCallback()
    //     }
    //   },
    //   onCancel() {
    //     if (_.isFunction(cancelCallback)) {
    //       cancelCallback()
    //     }
    //   },
    // })
    Alert.alert(
      title,
      message,
      [
        {
          text: txtOK,
          onPress: okCallback,
        },
        {
          text: txtCancel,
          onPress: cancelCallback,
          style: 'cancel',
        },
      ],
      { cancelable: false }
    )
  }

  showConfirmationPromise = async (
    title: string,
    message: string,
    okCallback: () => void,
    cancelCallback?: () => void,
    txtOK = 'ตกลง',
    txtCancel = 'ยกเลิก'
  ): Promise<void> => {
    Alert.alert(
      title,
      message,
      [
        {
          text: txtOK,
          onPress: okCallback,
        },
        {
          text: txtCancel,
          onPress: cancelCallback,
          style: 'cancel',
        },
      ],
      { cancelable: false }
    )
  }

  showConfirmationOkOnly = async (title: string, message: string, okCallback?: () => void, txtOK?: string): Promise<void> =>
    new Promise<void>((resolve) => {
      // confirmAlert({
      //   title,
      //   message,
      //   buttons: [
      //     {
      //       label: _.isString(txtOK) ? txtOK : 'ตกลง',
      //       onClick: () => {
      //         if (_.isFunction(okCallback)) {
      //           okCallback()
      //         }
      //         resolve(null)
      //       },
      //     },
      //   ],
      //   willUnmount: resolve,
      //   closeOnClickOutside: false,
      //   closeOnEscape: false,
      // })
      Alert.alert(
        title,
        message,
        [
          {
            text: _.isString(txtOK) ? txtOK : 'ตกลง',
            onPress: () => {
              if (_.isFunction(okCallback)) {
                okCallback()
              }
              resolve(null)
            },
          },
        ],
        { cancelable: false }
      )
    })

  showConfirmationThreeButtons = (
    title: string,
    message: string,
    btn1Label: string,
    btn1Callback: () => void,
    btn2Label: string,
    btn2Callback: () => void,
    btnCancelLabel = 'ยกเลิก',
    btnCancelCallback?: () => void
  ): void => {
    // confirmAlert({
    //   title,
    //   message,
    //   buttons: [
    //     {
    //       label: btn1Label,
    //       onClick: btn1Callback,
    //     },
    //     {
    //       label: btn2Label,
    //       onClick: btn2Callback,
    //     },
    //     {
    //       label: btnCancelLabel,
    //       onClick: btnCancelCallback,
    //     },
    //   ],
    //   closeOnClickOutside: false,
    //   closeOnEscape: false,
    // })

    Alert.alert(
      title,
      message,
      [
        {
          text: btn1Label,
          onPress: btn1Callback,
        },
        {
          text: btn2Label,
          onPress: btn2Callback,
        },
        {
          text: btnCancelLabel,
          onPress: btnCancelCallback,
        },
      ],
      { cancelable: false }
    )
  }

  getReactotron = () => Reactotron

  getBuildTimestamp = (): string => 'web_build_timpstamp'

  // // Saga's Operations
  // *handleAuthResponseFromSaga(res: Response) {
  //   log('handleAuthResponse.res', res)
  //   if (res && res.token) {
  //     const authRegister = yield call(p.op.storageSet, AUTHENTICATION_STORAGE_KEY, res.token, false)
  //     log('In handleAuthResponse authRegister: ', authRegister)
  //   }
  //
  //   if (res && res.missing && res.default) {
  //     // yield put(OnboardActions.nextMissingField, res.missing)
  //     log('res has default => ', res)
  //     // yield put(OnboardActions.nextMissingField(res.missing, res.default))
  //     p.op.alert('ตั้งค่าบัญชียังไม่สมบูรณ์', 'กรุณาตั้งค่าบัญชีผู้ใช้ในแอปให้เสร็จสิ้นก่อน')
  //     yield call(this.signOut)
  //   } else if (res && res.missing) {
  //     log('res no default => ', res)
  //     // yield put(OnboardActions.nextMissingField(res.missing))
  //     p.op.alert('ตั้งค่าบัญชียังไม่สมบูรณ์', 'กรุณาตั้งค่าบัญชีผู้ใช้ในแอปให้เสร็จสิ้นก่อน')
  //     yield call(this.signOut)
  //   } else {
  //     if (res && !res.token) {
  //       // ถ้ามาถึงนี่แล้วไม่มี token จริงๆ ให้ clear token should be impossible case
  //       yield call(p.op.storageClear, FACEBOOK_AUTH_STORAGE_KEY)
  //       yield call(p.op.storageClear, AUTHENTICATION_STORAGE_KEY)
  //       yield call(p.op.signOut)
  //       // p.op.alert('การเชื่อมต่อล้มเหลว', 'กรุณาเข้าสู่ระบบใหม่อีกครั้ง')
  //     } else {
  //       // initialize User Profile (Move to Saga)
  //       yield put({ type: actions.USER_PROFILE_FETCH, })
  //
  //       yield call(p.nav.resetToHome)
  //       yield call(p.op.initAnalytics)
  //     }
  //   }
  // }

  // *handleAuthErrorFromSaga(err: Error) {
  //   log('handleAuthErrorFromSaga.err', err)
  //   if ('code' in err) {
  //     log('In handleAuthErrorFromSaga err: ', err)
  //     if (err.code === 401) {
  //       // Go to E-mail onboarding without default email
  //       // yield put(OnboardActions.nextMissingField('email'))
  //       p.op.alert('ตั้งค่าบัญชียังไม่สมบูรณ์', 'กรุณาตั้งค่าบัญชีผู้ใช้ในแอปให้เสร็จสิ้นก่อน')
  //       yield call(this.signOut)
  //     } else {
  //       yield put({type: actions.LOGIN_WITH_ACCOUNT_INVALID})
  //     }
  //   } else {
  //     log('handleAuthErrorFromSaga catch error => ', err)
  //     yield put({type: actions.LOGIN_WITH_ACCOUNT_INVALID})
  //   }
  // }
  //
  // *handleAfterGetNewProfile(profile: { [key: string]: any }) {
  //   if (profile.id) {
  //     yield call(log, 'handleAfterGetNewProfile profile => ', profile)
  //   }
  // }
  handleAfterGetNewProfile(profile: { [key: string]: any }) {
    // console.log('handleAfterGetNewProfile profile => ', profile)
    // console.log('No.Op.Impl handleAfterGetNewProfile')
  }

  openFileFromUrl = async ({ url, fileName, fileType }) => {
    await utilW.downloadFileFromUrl({ url, fileName })
  }

  openPdfFromUrl = async ({ url, fileName }) => {
    await utilW.downloadFileFromUrl({ url, fileName })
  }
}
