import React from 'react'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import _ from 'lodash'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
// import SRowItem from '../../components/renderRowLeftAndRight'
import BaseSalePageSettingWebView from 'x/modules/storeWebsite/BaseSalePageSettingWebView'
import { COLORS } from 'x/config/styles'
import XText from 'xui/components/XText'
import HStack from 'xui/components/HStack'
import { ImageBackground, TouchableOpacity, View } from 'react-native'
import XIcon from 'xui/components/XIcon'
// import ReactQuill from 'react-quill'
import XTextEditor from 'xui/components/XTextEditor'
import VStack from 'xui/components/VStack'
import XWebView from 'xui/components/XWebView'
import { IStoreWebsite, IXWebViewReceivedMessage } from 'x/index'
import XButton from 'xui/components/XButton'
import ImgItemSalePage from 'xui/modules/storeWebsite/ImgItemSalePage'
import dayjs from 'dayjs'
import * as api from 'x/utils/api'
import CONS from 'x/config/constants'
import NavHeaderButton from 'xui/components/NavHeaderButton'
import { position } from 'styled-system'
import Box from 'xui/components/Box'
import XSpinner from 'xui/components/XSpinner'

// const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

const VERTICAL_LINE = <HStack h='6' mx='1' borderLeftWidth='1' borderLeftColor='#D4D4D4' />

export default abstract class BaseUISalePageSettingWebView extends BaseSalePageSettingWebView {
  isLoading?: boolean

  _renderHeader = () => {
    const title = 'ตั้งค่าเว็บไซต์'
    return (
      <XCustomHeader
        title={title}
        // headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(this.props) }}
        renderHeaderLeft={this._renderHeaderLeft}
        renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  _doEdit = () => {
    try {
      this.webviewRef.current.postMessage({ action: 'do_edit' })
    } catch (error) {
      console.log('_doEdit error => ', error)
    }
  }

  _cancelEdit = () => {
    try {
      this.webviewRef.current.postMessage({ action: 'cancel_edit' })
    } catch (error) {
      console.log('_cancelEdit error => ', error)
    }
  }

  _comfirmCancelEdit = () => {
    try {
      this.webviewRef.current.postMessage({ action: 'confirm_cancel_edit' })
    } catch (error) {
      console.log('_comfirmCancelEdit error => ', error)
    }
  }

  _doSave = () => {
    try {
      this.webviewRef.current.postMessage({ action: 'do_save' })
    } catch (error) {
      console.log('_doSave error => ', error)
    }
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _renderHeaderLeft = () => {
    const { isEditable, isLoading } = this.state
    if (isEditable) {
      return <NavHeaderButton label='บันทึก' onPressItem={this._doSave} submitting={isLoading} />
    }

    return <NavHeaderButton backIcon onPressItem={this._goBack} submitting={isLoading} />
  }

  _renderHeaderRight = () => {
    const { isEditable, isLoading } = this.state
    if (isEditable) {
      return <NavHeaderButton label='ยกเลิก' onPressItem={this._cancelEdit} submitting={isLoading} />
    }

    return <NavHeaderButton label='แก้ไข' onPressItem={this._doEdit} submitting={isLoading} />
  }

  _renderAddItemBtn = () => (
    <HStack w='full' minHeight='10' alignItems='center' justifyContent='flex-end'>
      <TouchableOpacity
        style={{
          width: 110,
          height: 32,
          backgroundColor: COLORS.BG_LIGHT_GREY,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 7,
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderLeftWidth: 1,
          borderRightWidth: 1,
          borderTopColor: COLORS.APP_MAIN,
          borderBottomColor: COLORS.APP_MAIN,
          borderLeftColor: COLORS.APP_MAIN,
          borderRightColor: COLORS.APP_MAIN,
          // marginRight: 8,
          // marginLeft: 6,
          flexDirection: 'row',
        }}
        onPress={() => null}>
        <XIcon name='add-circle' family='Ionicons' color={COLORS.APP_MAIN} />
        <XText ml='2' color={COLORS.APP_MAIN}>
          เพิ่มไอเทม
        </XText>
      </TouchableOpacity>
    </HStack>
  )

  _renderAddInsertItem = () => (
    <HStack w='full' minHeight='10' alignItems='center' justifyContent='flex-end'>
      <TouchableOpacity style={{ height: 42, alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row' }}>
        <XText variant='inactive'>เพิ่มไอเทม</XText>
        <XIcon ml='2' name='add-circle' family='Ionicons' color={COLORS.TEXT_INACTIVE} />
      </TouchableOpacity>
    </HStack>
  )

  _renderTextEditor = (html: string, index: number) => {
    const { indexShowToolBar } = this.state
    if (_.isNil(html) || html === '') {
      return null
    }
    return (
      <VStack key={`renderTextEditor${index}`}>
        <VStack bgColor={COLORS.WHITE}>
          <XTextEditor
            id={`${index}html`}
            htmlValue={html}
            // onChange={(newText) => this._onChangeHtmlText(newText)}
            readOnly
            h={p.op.isWeb() ? '24' : '64'}
          />
          {this._renderToolBar(html, 'ตัวหนังสือ', indexShowToolBar === index, index, () => this._navToEditorView(html, index))}
        </VStack>
        {/* {this._renderAddInsertItem()} */}
      </VStack>
    )
  }

  _renderCountdownTimerInMinute = (timeMinute: number, index: number) => {
    const { indexShowToolBar } = this.state
    return (
      // console.log('timeMinute => ', timeMinute)
      <VStack w='full' key={`renderCountdownTimerInMinute${index}`}>
        <VStack w='full' bgColor={COLORS.WHITE}>
          <VStack w='full' minH='12' py='2' alignItems='center' justifyContent='center'>
            <XText pr='2'>โปรโมทชั่นจะหมดภายใน</XText>
            <XText pr='2' bold>
              {`${timeMinute}`}
            </XText>
            <XText pr='2'>นาที</XText>
            {this._renderToolBar(
              timeMinute,
              'นับเวลาถอยหลัง',
              indexShowToolBar === index,
              index,
              (time: number, newIndex: number, popViewNumber: number) => this._navToEditCountdownTimeView(time, newIndex, popViewNumber)
            )}
          </VStack>
        </VStack>
        {/* {this._renderAddInsertItem()} */}
      </VStack>
    )
  }

  _renderImg = (imgData: { url: string; link?: string; w: number; h: number }, index: number) => {
    const { indexShowToolBar } = this.state
    // console.log('imgData => ', imgData.url)

    // const img = new Image()
    // img.src = imgData.url

    // img.onload = () => {
    //   console.log(img.height)
    //   console.log(img.width)
    // }
    // img max size = 768 kb
    let imgHeight = imgData.h
    const imgWidth = imgData.w
    const miniaturize = imgData.w - this.state.widthImg

    // imgWidth = imgData.w - miniaturize
    imgHeight = imgData.h - miniaturize
    // console.log('imgWidth => ', imgWidth)
    // console.log('imgHeight => ', imgHeight)
    return (
      <VStack w='full' bgColor={COLORS.RED} key={`renderImg${index}`}>
        <HStack
          w='full'
          // position='relative'
          onLayout={(event) => {
            const { x, y, width, height } = event.nativeEvent.layout
            // console.log('width BG => ', width)
            // util.setStatePromise(this, { loadingWidth: width, loadingHeight: height })
            // if (!this.state.isStopSetWidthImg) {
            this.setState({ widthImg: width, isStopSetWidthImg: true })
            // }
          }}>
          {this._renderToolBar(imgData, 'รูป', indexShowToolBar === index, index, null)}
          <ImageBackground source={{ uri: imgData.url }} style={{ width: this.state.widthImg, height: imgHeight }} />
          {/* <ImageBackground source={{ uri: imgData.url }} style={{ width: '100%', height: this.state.heightImg }} /> */}
          {/* <XImage style={{ width: this.state.heightImg, height: this.state.heightImg }} source={{ uri: imgData.url }} /> */}
        </HStack>
      </VStack>
    )
  }

  _renderToolBar = (
    item: any,
    title: string,
    onShow: boolean,
    index: number,
    onPressEdit: (item: any, newIndex: number, popViewNumber: number) => void
  ) => {
    if (!onShow) {
      return (
        <HStack
          zIndex={990}
          minHeight='20'
          // bgColor={COLORS.BG_LIGHT_GREY}
          position='absolute'
          top='0'
          // bottom='0'
          left='0'
          right='0'>
          <HStack w='full' h='10' alignItems='center' justifyContent='flex-end'>
            <TouchableOpacity
              onPress={() => this.setState({ indexShowToolBar: index })}
              style={{
                width: 32,
                height: 32,
                alignItems: 'center',
                justifyContent: 'center',
                // backgroundColor: COLORS.WHITE,
                borderRadius: 100,
                marginLeft: 2,
                marginRight: 8,
              }}>
              <XIcon name='settings-outline' family='Ionicons' color={COLORS.TEXT_ACTIVE} />
              <HStack
                position='absolute'
                top='0'
                left='0'
                right='0'
                bottom='0'
                opacity={30}
                bgColor={COLORS.TEXT_INACTIVE}
                borderRadius='full'
              />
            </TouchableOpacity>
          </HStack>
        </HStack>
      )
    }
    return (
      <HStack
        zIndex={990}
        minHeight='20'
        // bgColor={COLORS.BG_LIGHT_GREY}
        position='absolute'
        top='0'
        // bottom='0'
        left='0'
        right='0'>
        <HStack position='absolute' top='0' left='0' right='0' bottom='0' opacity={30} bgColor={COLORS.BLACK} />
        <VStack w='full'>
          <HStack w='full' h='10' alignItems='center' justifyContent='flex-end'>
            <HStack flex={1}>
              <XText ml='2' textAlign='left' color={COLORS.WHITE}>
                {title}
              </XText>
            </HStack>
            {/* {VERTICAL_LINE} */}
            <TouchableOpacity
              onPress={() => this.setState({ indexShowToolBar: -1 })}
              style={{
                width: 32,
                height: 32,
                alignItems: 'center',
                justifyContent: 'center',
                // backgroundColor: COLORS.WHITE,
                borderRadius: 100,
                marginLeft: 2,
                marginRight: 8,
              }}>
              <XIcon name='close-outline' family='Ionicons' color={COLORS.TEXT_ACTIVE} />
              <HStack position='absolute' top='0' left='0' right='0' bottom='0' opacity={40} bgColor={COLORS.WHITE} borderRadius='full' />
            </TouchableOpacity>
          </HStack>
          <HStack h='10' w='full' alignItems='center' justifyContent='flex-end'>
            <TouchableOpacity
              style={{
                width: 32,
                height: 32,
                alignItems: 'center',
                justifyContent: 'center',
                // backgroundColor: COLORS.WHITE,
                borderRadius: 100,
                marginRight: 4,
              }}>
              <XIcon zIndex={999} name='chevron-up' style={{ fontWeight: 'bold' }} family='Ionicons' color={COLORS.BLACK} />
              <HStack position='absolute' top='0' left='0' right='0' bottom='0' opacity={90} bgColor={COLORS.WHITE} borderRadius='full' />
            </TouchableOpacity>
            {/* {VERTICAL_LINE} */}
            <TouchableOpacity
              style={{
                width: 32,
                height: 32,
                alignItems: 'center',
                justifyContent: 'center',
                // backgroundColor: COLORS.WHITE,
                borderRadius: 100,
                marginRight: 2,
              }}>
              <XIcon zIndex={999} name='chevron-down' style={{ fontWeight: 'bold' }} family='Ionicons' color={COLORS.BLACK} />
              <HStack position='absolute' top='0' left='0' right='0' bottom='0' opacity={90} bgColor={COLORS.WHITE} borderRadius='full' />
            </TouchableOpacity>
            {VERTICAL_LINE}
            <TouchableOpacity
              style={{
                // width: 52,
                height: 32,
                // backgroundColor: COLORS.BG_LIGHT_GREY,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 100,
                marginRight: 2,
                marginLeft: 2,
                flexDirection: 'row',
                paddingLeft: 4,
                paddingRight: 8,
              }}
              onPress={() => {
                if (_.isFunction(onPressEdit)) {
                  onPressEdit(null, index - 1, 1)
                }
              }}>
              <XIcon zIndex={999} name='add' family='Ionicons' color={COLORS.BLACK} />
              <XText zIndex={999} color={COLORS.BLACK}>
                บน
              </XText>
              <HStack position='absolute' top='0' left='0' right='0' bottom='0' opacity={90} bgColor={COLORS.WHITE} borderRadius='full' />
            </TouchableOpacity>
            {/* {VERTICAL_LINE} */}
            <TouchableOpacity
              style={{
                // width: 52,
                height: 32,
                // backgroundColor: COLORS.BG_LIGHT_GREY,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 100,
                marginRight: 2,
                marginLeft: 2,
                flexDirection: 'row',
                paddingLeft: 4,
                paddingRight: 8,
              }}
              onPress={() => {
                if (_.isFunction(onPressEdit)) {
                  onPressEdit(null, index + 1, 1)
                }
              }}>
              <XIcon zIndex={999} name='add' family='Ionicons' color={COLORS.BLACK} />
              <XText zIndex={999} color={COLORS.BLACK}>
                ล่าง
              </XText>
              <HStack position='absolute' top='0' left='0' right='0' bottom='0' opacity={90} bgColor={COLORS.WHITE} borderRadius='full' />
            </TouchableOpacity>
            {VERTICAL_LINE}
            <TouchableOpacity
              style={{
                width: 52,
                height: 32,
                // backgroundColor: COLORS.BG_LIGHT_GREY,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 100,
                marginRight: 8,
                marginLeft: 2,
              }}
              onPress={() => {
                if (_.isFunction(onPressEdit)) {
                  onPressEdit(item, index, 1)
                }
              }}>
              {/* <XText color='#F5C2AF'>แก้ไข</XText> */}
              <XText zIndex={999} color={COLORS.APP_MAIN}>
                แก้ไข
              </XText>
              <HStack position='absolute' top='0' left='0' right='0' bottom='0' opacity={90} bgColor={COLORS.WHITE} borderRadius='full' />
            </TouchableOpacity>
          </HStack>
        </VStack>
      </HStack>
    )
  }

  //   <TouchableOpacity style={{ width: 42, height: 42, alignItems: 'center', justifyContent: 'center' }}>
  //   <XIcon name='more-vert' family='MaterialIcons' color='#C2C0C0' />
  // </TouchableOpacity>

  _renderItems = (item, idx) => {
    const { indexShowToolBar } = this.state
    if (item.countdown_timer_in_minute || item.countdown_timer_in_minute === 0) {
      return this._renderCountdownTimerInMinute(item.countdown_timer_in_minute, idx)
    }
    if (item.img) {
      // return null
      return (
        <ImgItemSalePage
          imgData={item.img}
          index={idx}
          indexShowToolBar={indexShowToolBar}
          indexShowToolBarChange={(Idx: number) => this.setState({ indexShowToolBar: Idx })}
          key={`IMG_ITEM_${idx}`}
        />
      )
      // return this._renderImg(item.img, idx)
    }
    if (item.html) {
      return this._renderTextEditor(item.html, idx)
    }

    return null
  }

  _onNavigationStateChange = (webViewState) => {
    console.log(webViewState.url)
  }

  _renderListItem = () => {
    const { webItems, indexShowToolBar } = this.state
    // console.log('webItems => ', webItems)
    if (_.isNil(webItems)) {
      return null
    }
    // const htmlRender = webItems.html
    return (
      <VStack w='full'>
        {this._renderAddInsertItem()}
        {/* <WebView
          source={{ uri: 'https://google.com/' }}
          style={{ flex: 1, height: 900 }}
          onNavigationStateChange={this._onNavigationStateChange}
        /> */}
        {webItems.map(this._renderItems)}
      </VStack>
    )
  }

  _renderAddItemView = () => {
    const { webItems } = this.state
    return (
      <TouchableOpacity
        onPress={() => this._navToSalePageAddItemWebView(webItems.length - 1)}
        style={{ width: `100%`, paddingLeft: 4, paddingRight: 4, paddingTop: 4, paddingBottom: 4 }}>
        <View style={{ backgroundColor: COLORS.BG_LIGHT_GREY_ALTERNATIVE, borderRadius: 10 }}>
          <View
            style={{
              height: 150,
              width: '100%',
              borderWidth: 0.8,
              borderColor: COLORS.APP_MAIN,
              borderStyle: 'dashed',
              borderRadius: 10,
              justifyContent: 'center',
              alignItems: 'center',
              paddingLeft: 6,
              paddingRight: 6,
              flexDirection: 'row',
            }}>
            <XIcon fontSize='lg' name='add' family='Ionicons' color={COLORS.BLACK} />
            <XText fontSize='lg' variant='active' textAlign='center'>
              เพิ่มไอเทม
            </XText>
          </View>
        </View>
      </TouchableOpacity>
    )
  }

  // render() {
  //   return (
  //     <XContainer>
  //       {this._renderHeader()}
  //       <XContent style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}>
  //         <VStack w='full'>
  //           {this._renderListItem()}
  //           {this._renderAddItemView()}
  //         </VStack>
  //       </XContent>
  //     </XContainer>
  //   )
  // }

  _onMessageReceived = async (evt: IXWebViewReceivedMessage) => {
    // console.log('onMessageReceived evt => ', evt)

    if (evt.action === 'save_data') {
      if (this.isLoading) {
        return
      }
      this.isLoading = true

      const { selectedStore } = this.props
      const storeWebsite: IStoreWebsite = util.getNavParam(this.props, 'storeWebsite')
      const { draft_config_json = {} } = storeWebsite

      const newCfg = _.cloneDeep(draft_config_json)
      newCfg['web_components'] = {
        web_items: evt.data,
        updated_at: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
        // console.log('newCfg => ', newCfg)
      }

      await util.setStatePromise(this, { isLoading: true })
      await util.delay(200)

      try {
        const res = await api.updateStoreWebsite({
          store_id: selectedStore.get('id'),
          website_id: storeWebsite.id,
          config_json: newCfg,
        })
        // console.log('res => ', res)
        this._comfirmCancelEdit()
      } catch (error) {
        console.log('error => ', error)
      }

      await util.delay(200)
      await util.setStatePromise(this, { isLoading: false })
      this.isLoading = false
    }

    if (evt.action === 'do_edit') {
      await util.setStatePromise(this, { isEditable: true })
    }

    if (evt.action === 'cancel_edit') {
      const isUserConfirm = await p.op.isUserConfirm('ยกเลิกการแก้ไข', 'กรุณายืนยันว่าฉันต้องการยกเลิกการแก้ไขทั้งหมด')

      if (isUserConfirm) {
        this._comfirmCancelEdit()
      }
      // await util.setStatePromise(this, { isEditable: false })
    }

    if (evt.action === 'confirm_cancel_edit') {
      await util.setStatePromise(this, { isEditable: false })
    }
  }

  // _onNavigationStateChange = (evt: any) => {
  //   console.log('_onNavigationStateChange evt => ', evt)
  // }

  _onLoad = (evt: any) => {
    // console.log('_onLoad evt => ', evt)
    this.setState({ isWebLoading: false })
  }

  // _onLoadStart = (evt: any) => {
  //   // console.log('_onLoadStart evt => ', evt)
  //   this.setState({ isWebLoading: true })
  // }

  _onHandshaked = () => {
    const storeWebsite: IStoreWebsite = util.getNavParam(this.props, 'storeWebsite')
    const { draft_config_json = {} } = storeWebsite

    let data
    try {
      // @ts-ignore
      data = draft_config_json.web_components.web_items || []
    } catch (error) {
      data = []
    }

    this.webviewRef.current.postMessage({ action: 'init', data })
  }

  render() {
    const { isLoading, isWebLoading = true } = this.state

    return (
      <XContainer>
        {this._renderHeader()}
        <XWebView
          ref={this.webviewRef}
          // uri='http://192.168.1.2:3000/edit'
          uri='https://dws.xselly.com/edit'
          // @ts-ignore
          onNavigationStateChange={this._onNavigationStateChange}
          // style={{ flex: 1 }}
          onMessageReceived={this._onMessageReceived}
          // onLoadStart={this._onLoadStart}
          onLoad={this._onLoad}
          onHandshaked={this._onHandshaked}
        />

        {(isLoading || isWebLoading) && (
          <Box
            alignItems='center'
            justifyContent='center'
            bg={isWebLoading ? 'white' : 'gray.400:alpha.50'}
            style={{ position: 'absolute', top: 44, bottom: 0, left: 0, right: 0 }}>
            <XSpinner />
          </Box>
        )}
      </XContainer>
    )
  }
}
