import React from 'react'

// import EntypoIcon from 'react-native-vector-icons/Entypo'

// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'

// import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
// import SRowItem from '../../components/renderRowLeftAndRight'
import BaseSelectPackageView from 'x/modules/subscription/BaseSelectPackageView'
import { COLORS } from 'x/config/styles'
import XCard from 'xui/components/XCard'
import Segment from 'xui/components/Segment'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import { TouchableOpacity } from 'react-native'
import XButton from 'xui/components/XButton'
import { SubscriptionPackageListItems } from 'x/index'
import _ from 'lodash'
import XIcon from 'xui/components/XIcon'

export default abstract class BaseUISelectPackageView extends BaseSelectPackageView {
  _renderHeader = () => {
    const title = 'เลือกแพ็กเกจ'
    return (
      <XCustomHeader
        title={title}
        headerLeftProps={{ backIcon: true, onPressItem: () => this.goBack() }}
        // renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  _renderAddonView = (item: SubscriptionPackageListItems, title: string, key: string) => {
    const dataJson = item.data_json
    if (_.isNil(dataJson)) {
      return null
    }
    const count = _.has(dataJson, key) ? dataJson[key] : 0
    const addOnList = this._getAddOnTskusByKey(item, key)
    // const selectedCount = _.has(item, 'ui_selected_count') ? item.ui_selected_count : 0
    const countAddOnCount = this._getAddOnSumTotalCount(addOnList)
    return (
      <VStack w='full' borderBottomWidth='1' borderBottomColor={COLORS.TEXT_INACTIVE} py='2'>
        <HStack w='full' alignItems='center'>
          <HStack flex={1}>
            <XText>{title}</XText>
          </HStack>
          <HStack>
            {/* <TouchableOpacity>
                <XIcon name='minuscircle' family='AntDesign' color={COLORS.RED} />
              </TouchableOpacity> */}
            <HStack pr='2' justifyContent='flex-end' alignItems='center'>
              <XText mx='1'>{count}</XText>
              {countAddOnCount > 0 ? <XText mx='2'>+</XText> : null}
              {countAddOnCount > 0 ? (
                <XText mx='1' color={COLORS.GREEN}>
                  {countAddOnCount}
                </XText>
              ) : null}
            </HStack>
            {/* <TouchableOpacity onPress={() => this._navToAddOnPackageView(addOnList)}>
              <XIcon
                name='pluscircle'
                family='AntDesign'
                opacity={addOnList.length > 0 ? 1 : 0.3}
                color={addOnList.length > 0 ? COLORS.APP_SECONDARY : COLORS.TEXT_INACTIVE}
              />
            </TouchableOpacity> */}
          </HStack>
        </HStack>
        {countAddOnCount > 0 ? (
          <HStack w='full' mt='2'>
            <VStack flex={1} alignItems='flex-end'>
              <XText variant='inactive'>จำนวนรอบบิล 1 รอบบิน (ข้อมูลยังไม่ตรง)</XText>
              <XText variant='inactive'>(2023-05-22 ถึง 2024-05-15)</XText>
            </VStack>
          </HStack>
        ) : null}
      </VStack>
    )
  }

  _renderMonthOrderCount = (itam: SubscriptionPackageListItems) => {
    const {} = this.state
    const dataJson = itam.data_json
    const monthOrderCount = _.isNil(dataJson) || dataJson.m_o_count === 0 ? null : dataJson.m_o_count
    return (
      <VStack w='full' borderBottomWidth='1' borderBottomColor={COLORS.TEXT_INACTIVE} pb='2'>
        <HStack w='full' alignItems='center'>
          <HStack flex={1}>
            <XText>ออเดอร์ต่อเดือน</XText>
          </HStack>
          <HStack>
            {/* <TouchableOpacity>
              <XIcon name='minuscircle' family='AntDesign' color={COLORS.RED} />
            </TouchableOpacity> */}
            <HStack pr='2' justifyContent='flex-end' alignItems='center'>
              <XText mx='2'>{monthOrderCount}</XText>
            </HStack>
            <TouchableOpacity onPress={() => null}>
              <XIcon name='pluscircle' family='AntDesign' color={COLORS.APP_SECONDARY} />
            </TouchableOpacity>
          </HStack>
        </HStack>
        <HStack w='full' mt='2'>
          <VStack flex={1} alignItems='flex-end'>
            <XText variant='inactive'>จำนวนรอบบิล 2 รอบบิน</XText>
            <XText variant='inactive'>(2023-05-22 ถึง 2024-05-15)</XText>
          </VStack>
          {/* <HStack>
            <TouchableOpacity>
              <XIcon name='minuscircle' family='AntDesign' color={COLORS.RED} />
            </TouchableOpacity>
            <HStack w='20' justifyContent='center'>
              <XText mx='2'>2</XText>
            </HStack>
            <TouchableOpacity>
              <XIcon name='pluscircle' family='AntDesign' color={COLORS.GREEN} />
            </TouchableOpacity>
          </HStack> */}
        </HStack>
      </VStack>
    )
  }

  _renderPackageListView = () => {
    const { packageList, subscriptionPackageList } = this.state
    if (_.isNil(packageList)) {
      return null
    }
    const addonTskuBySubscriptionType = subscriptionPackageList.addon_tsku_by_subscription_type
    return (
      <VStack>
        {packageList.map((pack: SubscriptionPackageListItems) => {
          // console.log('pack => ', pack)
          const itemSubtype = pack.item_subtype
          const itemSubtypeeMap = {
            free: 1,
            bronze: 2,
            silver: 3,
            gold: 4,
            platinum: 5,
          }
          const packType = itemSubtypeeMap[itemSubtype]
          const typeTskus = addonTskuBySubscriptionType.find((oj) => oj.type === packType)
          // console.log('typeTskus => ', typeTskus)
          const packageName = pack.name
          const price = pack.price_with_vat
          const dataJson = pack.data_json
          const footerLable = _.isNil(dataJson) ? 'อัพเกรดแพ็กเกจ' : `เลือก ${packageName}`
          const monthOrderCount = _.isNil(dataJson) || dataJson.m_o_count === 0 ? null : dataJson.m_o_count
          const helperOrderCount = _.isNil(dataJson) || dataJson.helper_count === 0 ? null : dataJson.helper_count
          return (
            <XCard mt='2' p='2' key={packageName}>
              <HStack w='full'>
                <HStack flex={2}>
                  <XText variant='active' fontSize='lg'>
                    {packageName}
                  </XText>
                </HStack>
                <HStack flex={1} justifyContent='flex-end'>
                  <XText variant='primary' bold fontSize='lg'>
                    {`${price} บาท`}
                  </XText>
                </HStack>
              </HStack>
              {/* {selectedMonthIndex > 0 ? (
                <HStack justifyContent='flex-end'>
                  <XText color={COLORS.RED}>ประหยัด 12% เฉลี่ย 229/เดือน</XText>
                </HStack>
              ) : null} */}
              {this._renderAddonView(pack, 'ออเดอร์ต่อเดือน', 'm_o_count')}
              {this._renderAddonView(pack, 'ช่องทางขาย', 'mkp_quota')}
              {this._renderAddonView(pack, 'กลุ่มสมาชิก', 'ug_quota')}
              {this._renderAddonView(pack, 'รายการราคา', 'pg_quota')}
              {this._renderAddonView(pack, 'ผู้ช่วยร้าน', 'helper_count')}
              {this._renderAddonView(pack, 'ร้าน', 'store_count')}
              {this._renderAddonView(pack, 'ลำดับขั้นส่วนลดขายส่ง', 'vd_quota')}
              {this._renderAddonView(pack, 'คลัง', 'wh_quota')}
              {/* {this._renderMonthOrderCount(pack)} */}
              {/* <VStack w='full' borderBottomWidth='1' borderBottomColor={COLORS.TEXT_INACTIVE} pb='2' mt='2'>
                <HStack w='full'>
                  <HStack flex={1}>
                    <XText>ผู้ช่วยร้าน</XText>
                  </HStack>
                  <HStack>
                    <TouchableOpacity>
                      <XIcon name='minuscircle' family='AntDesign' color={COLORS.RED} />
                    </TouchableOpacity>
                    <XText mx='2'>2</XText>
                    <TouchableOpacity>
                      <XIcon name='pluscircle' family='AntDesign' color={COLORS.GREEN} />
                    </TouchableOpacity>
                  </HStack>
                </HStack>
                <HStack w='full' mt='2'>
                  <HStack flex={1} justifyContent='flex-end' pr='2'>
                    <XText variant='inactive'>จำนวนรอบบิล</XText>
                  </HStack>
                  <HStack>
                    <TouchableOpacity>
                      <XIcon name='minuscircle' family='AntDesign' color={COLORS.RED} />
                    </TouchableOpacity>
                    <XText mx='2'>2</XText>
                    <TouchableOpacity>
                      <XIcon name='pluscircle' family='AntDesign' color={COLORS.GREEN} />
                    </TouchableOpacity>
                  </HStack>
                </HStack>
              </VStack> */}
              {/* {!_.isNil(dataJson) ? (
                <VStack>
                  {!_.isNil(monthOrderCount) ? <XText>{`- ${monthOrderCount} ออเดอร์ ต่อเดือน`}</XText> : null}
                  {!_.isNil(helperOrderCount) ? <XText>{`- ผู้ช่วยร้าน ${helperOrderCount} คน`}</XText> : null}
                  <XText>- ไม่จำกัดรายการสินค้า</XText>
                  <XText>- ไม่จำกัดตัวแทนจำหน่าย</XText>
                  <XText>- ออกเลขพัสดุ EMS Flash J&T และอื้นๆ</XText>
                  <XText>- ดูรายงานได้ทุกตัว</XText>
                </VStack>
              ) : null} */}

              {/* <TouchableOpacity style={{ alignItems: 'center' }}>
                <XText variant='inactive' textDecorationLine='underline'>
                  ดูรายละเอียด
                </XText>
              </TouchableOpacity> */}
              <XButton mt='2' onPress={() => this._onPressSelectedPackage(pack)}>
                <XText color={COLORS.WHITE} fontSize='lg' mt='1'>
                  {footerLable}
                </XText>
              </XButton>
            </XCard>
          )
        })}
      </VStack>
    )
  }

  render() {
    const { selectedMonthIndex } = this.state
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent p='1' style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}>
          <XCard p='2'>
            <Segment
              selectedIndex={selectedMonthIndex}
              onSegmentChange={(index) => this._onSegmentSelectedMonthChange(index)}
              options={['โปร\n1 เดือน', 'โปร\n3 เดือน', 'โปร\n6 เดือน', 'โปร\n12 เดือน']}
            />
            {this._renderPackageListView()}
            {/* <XCard mt='2' p='2'>
              <HStack w='full'>
                <HStack flex={1}>
                  <XText variant='active' fontSize='lg'>
                    Bronze
                  </XText>
                </HStack>
                <HStack flex={1} justifyContent='flex-end'>
                  <XText variant='primary' bold fontSize='lg'>
                    687 บาท
                  </XText>
                </HStack>
              </HStack>
              {selectedMonthIndex > 0 ? (
                <HStack justifyContent='flex-end'>
                  <XText color={COLORS.RED}>ประหยัด 12% เฉลี่ย 229/เดือน</XText>
                </HStack>
              ) : null}
              <VStack>
                <XText>- 200 ออเดอร์ ต่อเดือน</XText>
                <XText>- ไม่จำกัดรายการสินค้า</XText>
                <XText>- ไม่จำกัดตัวแทนจำหน่าย</XText>
                <XText>- ออกเลขพัสดุ EMS Flash J&T และอื้นๆ</XText>
                <XText>- ดูรายงานได้ทุกตัว</XText>
              </VStack>
              <TouchableOpacity style={{ alignItems: 'center' }}>
                <XText variant='inactive' textDecorationLine='underline'>
                  ดูรายละเอียด
                </XText>
              </TouchableOpacity>
              <XButton mt='2' onPress={() => this._navToPaymentSubscriptionPackageView()}>
                <XText color={COLORS.WHITE} fontSize='lg' mt='1'>
                  เลือกแพ็กเกจ Bronze
                </XText>
              </XButton>
            </XCard>

            <XCard mt='2' p='2'>
              <HStack w='full'>
                <HStack flex={1}>
                  <XText variant='active' fontSize='lg'>
                    Silver
                  </XText>
                </HStack>
                <HStack flex={1} justifyContent='flex-end'>
                  <XText variant='primary' bold fontSize='lg'>
                    1707 บาท
                  </XText>
                </HStack>
              </HStack>
              {selectedMonthIndex > 0 ? (
                <HStack justifyContent='flex-end'>
                  <XText color={COLORS.RED}>ประหยัด 12% เฉลี่ย 549/เดือน</XText>
                </HStack>
              ) : null}
              <VStack>
                <XText>- 800 ออเดอร์ ต่อเดือน</XText>
                <XText>- ทุกอย่างที่แพ็ก Bronze ทำได้</XText>
                <XText>- 2 ร้านค้า</XText>
                <XText>- 2 กลุ่มสมาชิก</XText>
                <XText>- 3 ราคาขาย เช่น ปลีก ส่ง VIP</XText>
                <XText>- บาร์โค้ดสินค้า SKU / UPC</XText>
              </VStack>
              <TouchableOpacity style={{ alignItems: 'center' }}>
                <XText variant='inactive' textDecorationLine='underline'>
                  ดูรายละเอียด
                </XText>
              </TouchableOpacity>
              <XButton mt='2'>
                <XText color={COLORS.WHITE} fontSize='lg' mt='1'>
                  เลือกแพ็กเกจ Silver
                </XText>
              </XButton>
            </XCard>

            <XCard mt='2' p='2'>
              <HStack w='full'>
                <HStack flex={1}>
                  <XText variant='active' fontSize='lg'>
                    Gold
                  </XText>
                </HStack>
                <HStack flex={1} justifyContent='flex-end'>
                  <XText variant='primary' bold fontSize='lg'>
                    2545 บาท
                  </XText>
                </HStack>
              </HStack>
              {selectedMonthIndex > 0 ? (
                <HStack justifyContent='flex-end'>
                  <XText color={COLORS.RED}>ประหยัด 12% เฉลี่ย 888/เดือน</XText>
                </HStack>
              ) : null}
              <VStack>
                <XText>- 800 ออเดอร์ ต่อเดือน</XText>
                <XText>- ทุกอย่างที่แพ็ก Bronze ทำได้</XText>
                <XText>- 2 ร้านค้า</XText>
                <XText>- 2 กลุ่มสมาชิก</XText>
                <XText>- 3 ราคาขาย เช่น ปลีก ส่ง VIP</XText>
                <XText>- บาร์โค้ดสินค้า SKU / UPC</XText>
              </VStack>
              <TouchableOpacity style={{ alignItems: 'center' }}>
                <XText variant='inactive' textDecorationLine='underline'>
                  ดูรายละเอียด
                </XText>
              </TouchableOpacity>
              <XButton mt='2'>
                <XText color={COLORS.WHITE} fontSize='lg' mt='1'>
                  เลือกแพ็กเกจ Gold
                </XText>
              </XButton>
            </XCard>

            <XCard mt='2' p='2'>
              <HStack w='full'>
                <HStack flex={1}>
                  <XText variant='active' fontSize='lg'>
                    Platinum
                  </XText>
                </HStack>
                <HStack flex={1} justifyContent='flex-end'>
                  <XText variant='primary' bold fontSize='lg'>
                    6000 บาท
                  </XText>
                </HStack>
              </HStack>
              {selectedMonthIndex > 0 ? (
                <HStack justifyContent='flex-end'>
                  <XText color={COLORS.RED}>ประหยัด 12% เฉลี่ย 888/เดือน</XText>
                </HStack>
              ) : null}
              <VStack>
                <XText>- 800 ออเดอร์ ต่อเดือน</XText>
                <XText>- ทุกอย่างที่แพ็ก Bronze ทำได้</XText>
                <XText>- 2 ร้านค้า</XText>
                <XText>- 2 กลุ่มสมาชิก</XText>
                <XText>- 3 ราคาขาย เช่น ปลีก ส่ง VIP</XText>
                <XText>- บาร์โค้ดสินค้า SKU / UPC</XText>
              </VStack>
              <TouchableOpacity style={{ alignItems: 'center' }}>
                <XText variant='inactive' textDecorationLine='underline'>
                  ดูรายละเอียด
                </XText>
              </TouchableOpacity>
              <XButton mt='2'>
                <XText color={COLORS.WHITE} fontSize='lg' mt='1'>
                  เลือกแพ็กเกจ Platinum
                </XText>
              </XButton>
            </XCard> */}
          </XCard>
        </XContent>
      </XContainer>
    )
  }
}
